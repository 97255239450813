.best-of-listing {
	
	position: relative;
	padding: 1em 0 0.5em;

	.number {
		@include absolute(1);
		margin: 0.25em 0 0 px-vw(16);
	}

	figure {
		display: inline-block;
		margin-bottom: 1em;
		picture {
			@include image-fit();
			width: px-vw(447);
			height: px-vw(290);
		}
	}

	.content {
		width: px-vw(447);
		margin: auto;
		text-align: left;
		vertical-align: top;
	}

	footer {
		@include body-text();
		margin-bottom: 1rem;

		.btn {
			color: $color-white;
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(sm) {
		.number {
			@include absolute(1);
			margin-left: 30px;
		}

		figure {
			picture {
				width: 447px;
				height: 290px;
			}
		}

		.content {
			width: 447px;
		}
	}

	@include media-breakpoint-up(md) {

		padding: 1.25em px-vw(50, 'lg');
		text-align: left;

		.number {
			margin-left: px-vw(65, 'lg');
		}

		figure {
			display: inline-block;
			margin: 0 0 0 px-vw(70, 'lg');

			picture {
				width: px-vw(389, 'lg');
				height: px-vw(252, 'lg');
				padding-right: px-vw(15, 'lg');
			}	
		}

		.content {
			display: inline-block;
			width: 35%;
			margin: 0.25em 0 0 1.5em;
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 1.25em 50px 2.5em;

		.number {
			margin-left: 65px;
		}

		figure {
			margin-left: 70px;

			picture {
				width: 389px;
				height: 252px;
			}	
		}
	}
	
}
