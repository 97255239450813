.experiences-detail-overview {

	.btn-transparent {
		display: inline-block;
		margin-bottom: px-vw(20);
		color: $color-black-fake;
		font-weight: 500;
		text-transform: none;

		.icon-svg {
			width: px-vw(24);
			height: px-vw(24);
			margin: 0 px-vw(10) 0.1em 0;
			vertical-align: text-bottom;

			svg { fill: $color-black-fake; }
		}

		a { text-decoration: none; }
	}

	figure {
		display: inline-block;
		width: 100%;
		margin-bottom: px-vw(30);

		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
			margin: 0;
			padding-bottom: 100%;
		}
	}
	
	.content {
		position: relative;
		vertical-align: top;

		p { margin-bottom: px-vw(30); }

		.location,
		time {
			@include body-text();
			@include text(30, 42, 0.6);
			display: block;
			margin: 0;
			font-weight: 500;
		}

		time {
			margin-bottom: px-vw(25);
			display: inline;
		}

		footer {
			@include body-text();
			margin: 1em 0 0 0;

			.btn-transparent {
				text-decoration: none;
				color: $color-black-fake;

				.icon-svg {
					width: 32px;
					height: 32px;
					margin: 0 0.25em 0.05em 0;
					vertical-align: text-bottom;

					svg { fill: $color-black-fake; }
				}

				&:hover, &:focus {
					text-decoration: none;
					.icon-svg {
						svg { fill: $color-aqua-dark; }
					}
				}
			}
		}
	}


	@include media-breakpoint-up(sm) {

		.btn-transparent {
			.icon-svg {
				width: 24px;
				height: 24px;
			}
		}

		figure { margin-bottom: 30px; }
	}

	@include media-breakpoint-up(md) {

		.btn-transparent {
			margin: 0;
			padding-left: 0;

			.icon-svg {
				width: 12px;
				height: 12px;
				margin-right: 5px;
			}
		}

		figure { margin-bottom: 0; }

		.content {

			text-align: left;

			p { margin-bottom: 30px; }

			.location,
			time {
				@include text(14, 24, 0.6);
				display: inline;
				margin: 0;
				font-weight: 500;
			}

			footer {
				a { margin: 0 0.35em; }
			}
		}
	}

	@include media-breakpoint-up(lg) {

		margin-bottom: 60px;

	}
}
