.feature {

	@include featureHeight(650);
	position: relative;

	&.feature-xxl { @include featureHeight(931); }
	&.feature-xl { @include featureHeight(650); }
	&.feature-lg { @include featureHeight(558); }
	&.feature-md { @include featureHeight(433); }
	&.feature-sm { @include featureHeight(351); }
	&.feature-xs { @include featureHeight(263); }
	&.feature-xxs { @include featureHeight(192); }

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: initial;
		min-height: 100%;
		overflow: hidden;

		a:focus::after {
			content: "";
			position: absolute;
			top: 3px;
			left: 3px;
			right: 3px;
			bottom: 3px;
			/* border: 2px solid red; */
			pointer-events: none;
			outline: 5px auto -webkit-focus-ring-color !important;
		}

		.gradient-overlay {
			background: #fff;
			background: -webkit-gradient(linear,left top,left bottom,color-stop(45%,rgba(0,0,0,0)),color-stop(100%,rgb(0,0,0)),to(rgb(0,0,0)));
			background: -o-linear-gradient(top,rgba(0,0,0,0) 45%,rgb(0,0,0) 100%,rgb(0,0,0) 100%);
			background: linear-gradient(180deg,rgba(0,0,0,0) 45%,rgb(0,0,0) 100%,rgb(0,0,0) 100%);
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 3;
			pointer-events: none;
		}

		picture img, video, iframe {
			@include absolute(5);
			//width: initial;
			min-width: 100%;
			height: 100%;
			max-width: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover; object-position: center;'; // object-fit-images polyfill
		}

		img {
			max-width: unset;
			width: auto;
			height: 100%;
			// transform: translateX(-50%);
		}

		picture {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;

			// img {  }
		}

		video, iframe {
			display: block;
		}

		iframe {
			height: 167%;
		}

		@include media-breakpoint-down(md) {
			iframe {
				min-width: 236%;
			}
		}

		// ie11 + edge video fix
		@media screen and (min-width: 0\0) {
			video {
				height: auto;
			}
		}
		@supports (-ms-ime-align: auto) { // edge
			video {
				height: auto;
			}
		}
	}


	.feature-container {
		.content {
			position: absolute;
			right: auto;
			bottom: auto;
			top: auto !important;
			left: 0;
			bottom: 2rem !important;
			width: 100%;
			z-index: 4;



			.container {
				position: relative;
				height: 100%;
				& > .row {
					display: flex;
					align-items: center;
					.headline-text {
						&, * {
							pointer-events: none;
							color: #fff;
							margin: 0;
							font-size: 1.375rem;
							line-height: 32px;
							letter-spacing: 2px;
						}
					}
					.btn {
						font-size: .875rem;
						line-height: 24px;
						letter-spacing: .5px;
						margin-bottom: 0;
					}
				}
			}

			&.slider-content {
				.container {
					@include media-breakpoint-down(md) {
						padding: 0 5rem;
					}
					@include media-breakpoint-down(sm) {
						padding: 0 6px;
					}
				}
			}

			@include media-breakpoint-up(md) {
				@include absolute(5);
			}
		}
	} 

	@include media-breakpoint-down(sm) {
		.feature-container {
			.content {
				bottom: 6rem;
				&.slider-content {
					bottom: 2rem;
				}
				.container {
					text-align: center;
					margin: 0 !important;
					.btn {
						float: inherit !important;
						padding: 0.5rem;
						font-size: 1.5rem;
						margin-top: 1rem;
					}
				}
			}
		}
	}
	

	.badge-weather {
		z-index: 1;
		top: 0;
	}
	
	@include media-breakpoint-down(md) {
		.badge-weather {
			top: 10%;	
		}
	}
	
	@include media-breakpoint-down(xs) {
		.badge-weather {
			top: 20%;	
		}
	}

	.property-landing-slider {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		z-index: inherit;
		padding: 0;

		.carousel,
		.carousel-factory,
		.slick-slider .slick-list,
		.slick-slider .slick-track,
		.carousel .row {
			height: 100%;
		}

		.carousel {
			padding: 0;
		}

		.carousel-factory {
			padding: 0;
		}

		.carousel-indicators {
			bottom: 30%;

			li {
				opacity: 1;
				background-color: transparent;
			}
		}

		.slick-dots {
			li button:before {
				border-color: white;
			}
			.slick-active button:before {
				background-color: white;
			}
		}

		.carousel-factory {
			&[data-arrows-mobile="bottom"] .slick-arrow {
				position: absolute;
				bottom: 7%;
				top: auto;

				&.slick-prev {
					left: 3.125vw;
				}

				&.slick-next {
					right: 3.125vw;
				}
			}
		}

		@include media-breakpoint-up(md) {
			.carousel-factory {
				&[data-arrows-mobile="bottom"] .slick-arrow {
					bottom: 0;

					&.slick-prev {
						left: 2%;
						bottom: 12%;
						
						right: auto;
						transform: none;
						margin: 0;
						// left: 50%;
						// margin-left: -45px;
					}

					&.slick-next {
						right: 2%;
						bottom: 12%;
						
						left: auto;
						transform: none;
						margin: 0;
						// left: 50%;
						// margin-left: 45px;
					}
				}
			}
		}

		// @include media-breakpoint-up(lg) {
		// 	.carousel-factory {
		// 		&[data-arrows-mobile="bottom"] .slick-arrow {
		// 			&.slick-prev,
		// 			&.slick-next {
		// 				// margin-bottom: 60px;
		// 			}
		// 		}
		// 	}
		// }
	}

	&.feature-property {
		height: px-vw(950);
		.feature-container {
			.content {
				top: auto !important;
				bottom: 4rem !important;

				picture img {
					filter: drop-shadow(2px 2px 2px #363436);
				}
			}
		}
	}
	&.feature-property-sub {
		height: px-vw(500);
		.container {
			.content {
				top: 25% !important;
			}
		}
	}
	&.feature-gallery {

		height: px-vw(325);
		margin-bottom: px-vw(120);
		padding-bottom: px-vw(75);

		.container {
			&.container-inset {
				@include absolute(8);
				bottom: px-vw(-80);
				max-width: px-vw(420);
				height: auto;
				padding-top: px-vw(26);

				&:before {
					border-width: px-vw(8);
				}
			}
		}

		.icon-svg {
			width: px-vw(23);
			height: px-vw(23);
			vertical-align: baseline;
			margin-left: 5px;
		}
	}
	&.feature-blog {
		height: px-vw(250);
	}
	&.property-sub-nav {
		height: px-vw(150);
	}
	&.feature-blog-post {
		height: px-vw(330);
	}

	.btn-container {
		margin: px-vw(25) px-vw(19);
		z-index: 1;
		//top: 50px;
	}

	@include media-breakpoint-down(sm) {

		&.feature-drop-sm-down {

			position: relative;
			margin-bottom: px-vw(120);
			height: auto;

			.background {
				position: relative;
				height: px-vw(500);
				&.has-video {
					height: px-vw(850);
				}
			}

			.content {
				bottom: 5.5rem;
			}

			// .content {
			// 	position: relative;
			// 	left: 0;
			// 	width: auto;
			// 	margin: px-vw(-35) auto px-vw(60) auto;
			// 	padding: 0;
			// 	transform: none;
			// }

			@include media-breakpoint-only(sm) {
				.background {
					height: 500px;
				}

				.content {
					margin: -250px auto 75px auto;
				}
			}
		}

	}


	@include media-breakpoint-up(sm) {

		&.feature-property {
			height: 845px;
		}
		&.feature-property-sub {
			height: 500px;
		}
		&.feature-gallery {
			padding-bottom: 75px;
			.container {
				&.container-inset {
					bottom: -80px;
				}
			}
		}
		&.feature-blog {
			height: 250px;
		}
		&.property-sub-nav {
			height: 150px;
		}
		&.feature-blog-post {
			height: 330px;
		}
	}


	@include media-breakpoint-up(md) {
		&.feature-property {
			height: 550px; // need fixed height to accommodate calendar flyout
			.feature-container {
				.content {
					top: auto;
					bottom: 2rem !important;
					// &.slider-content {
					// 	bottom: 8rem;
					// }
				}
			}
		}
		&.feature-property-sub {
			height: px-vw(350, lg);
			.feature-container {
				.content {
					top: auto;
					bottom: 2rem !important;
					// &.slider-content {
					// 	bottom: 8rem;
					// }
				}
			}
		}
		&.feature-gallery {
			height: px-vw(520, lg);
			margin-bottom: px-vw(120, lg);
			padding-bottom: px-vw(75, lg);

			.container {
				&.container-inset {
					bottom: px-vw(-40, lg);
					max-width: 469px;
					padding-top: 20px;

					&:before {
						border-width: 6px;
					}
				}
			}

			.icon-svg {
				width: 19px;
				height: 19px;
			}
		}
		&.feature-blog {
			height: px-vw(200, lg);
		}
		&.property-sub-nav {
			height: px-vw(150, lg);
		}
		&.feature-blog-post {
			max-width: 920px;
			height: px-vw(306, lg);
			margin: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		&.feature-property {
			height: 600px;
		}
		&.feature-property-sub {
			height: 350px;
		}
		&.feature-gallery {
			height: 520px;
			margin-bottom: 120px;
			padding-bottom: 75px;
			.container {
				&.container-inset {
					bottom: -40px;
				}
			}
		}
		&.feature-blog {
			height: 200px;
		}
		&.property-sub-nav {
			height: 150px;
		}
		&.feature-blog-post {
			height: 306px;
		}
	}

	@include media-breakpoint-down(sm) {
		.btn-container {
			top: 13vw;
		}
	}
}