.form-control,
select.form-control {
	@include body-text();
	height: px-vw(88);
	margin-bottom: 1rem;
	padding-left: px-vw(34);

	&.form-control-sm {
		@include body-text("small");
		padding-left: px-vw(30);
	}

	/* &[aria-disabled="true"] {
		color: $custom-select-disabled-color;
    	background-color: $custom-select-disabled-bg;
	} */
}

.user-is-tabbing .custom-control-input:focus ~ .custom-control-indicator {
	outline-style: solid !important;
	outline-color: Highlight !important;
	outline: 5px auto -webkit-focus-ring-color !important;
	outline-offset: 3px;
}

textarea.form-control {
	height: px-vw(390);
}

.custom-select-label {
	@include labelAnimation();
	position: relative;
	display: block;
	margin: 0;

	.icon-svg {
		@include absolute(6);
		top: 44%;
		right: px-vw(27);
		width: px-vw(16);
		height: px-vw(16);
		pointer-events: none;
	}

	&.position-absolute {
		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;

		.icon-svg {
			top: 43%;
			right: 33px;
		}
	}
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {  display: none; width: 0; height: 0; }
input[type="search"]::-ms-reveal {  display: none; width: 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

.form-group {
	@include labelAnimation();
}


select.form-control {
	margin-bottom: px-vw(14);
	color: $gray-light;

	&:not([size]):not([multiple]) {
		height: px-vw(88);
	}
}

.input-group {
	height: px-vw(88);

	.label {
		z-index: 4;

		&.active ~ .form-control {
			font-weight: 400;
		}
	}

	.btn {
		@include text(30, 32, 0.5);
		margin: 0;
	}

	&.input-group-sm {
		height: px-vw(72);

		.form-control {
			@include body-text("small");
			margin-bottom: 1rem;
			padding-left: px-vw(30);
		}
	}
}

.form-check {
	padding-left: 0;
	margin-bottom: .5rem;
}

.custom-control-indicator {
	display: block;
	position: absolute;
	left: 0;
}

.custom-checkbox {
	@include body-text();
	margin: 0;
	padding: px-vw(2) 0 0 px-vw(54);

	&:hover, &:focus {
		color: $color-aqua-dark;
	}

	.custom-control-indicator {
		top: px-vw(2);
		width: px-vw(42);
		height: px-vw(42);
		border: 1px solid $color-black;

		.icon-svg {
			display: none;
			vertical-align: top;

			svg {
				fill: $color-aqua-dark;
			}
		}
	}

	.custom-control-input:checked {
		~ .custom-control-indicator {
			border: 0;
			background-image: none;
			background-color: $color-white;

			.icon-svg {
				display: inline-block;
			}
		}
	}

	.custom-control-description {
		margin-left: 0.25em;
	}

	&.checkbox-sm {
		.custom-control-indicator {
			width: px-vw(42);
			height: px-vw(42);
		}
	}

	.custom-control-input[disabled] {
		~ .custom-control-indicator,
		~ .custom-control-description {
			opacity: 0.3;
		}
	}
}

.custom-radio {
	.custom-control-indicator {
		top: px-vw(2);
		width: px-vw(42);
		height: px-vw(42);
		background-color: $color-white;
		border: 1px solid $color-black;
		border-radius: 50%;
	}
	.custom-control-input:checked {
		~.custom-control-indicator {
			border: 0;
			background-image: none;
			background-color: $color-aqua-dark;
		}
	}

}

.label-textbox {
	position: relative;
	height: px-vw(88);
	padding: 3px 0 0 15px;
	background-color: $color-white;
	font-size: 12px;
	font-weight: 500;
	text-transform: lowercase;

	input {
		position: absolute;
		top: 0;
		left: 0;
		padding: 14px 15px 0 15px;
		background-color: transparent;
		text-transform: none;

		&:focus {
			background-color: transparent;
		}
	}
}

.intl-label {
	padding-left: 96px;

	.intl-tel-input.allow-dropdown {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.flag-container {
			border-right: 1px solid $color-black-fake;
		}

		.selected-flag {
			width: 77px;

			.iti-flag {
				top: 50%;
				bottom: auto;
				left: 47%;
				transform: translate(-50%, -50%);
			}
		}

		input[type="tel"] {
			padding-left: 95px;
		}
	}
}

.iti-flag {
	background-image: url('#{$aah-asset-path}/img/flags.png') !important; // sass-lint:disable-line no-important
}

.form-group-number {
	padding: 30px 0;
	text-align: center;
	border-right: 1px solid $color-grey-border;

	label { font-weight: 300; }

	.input-group-number {

		@include make-row();
		justify-content: center;

		button {
			width: 40px;
			height: 40px;
			margin: 0;
			vertical-align: middle;
			align-self: center;
			opacity: 1;
			background: white;

			.icon-svg {
				width: 32px;
				height: 32px;
			}

			&[aria-disabled="true"], &:disabled {
				opacity: 0.25;
			}
		}

		input[type="number"] {
			@include headline(1);
			display: inline-block;
			//width: px-vw(100);
			margin: 0;
			padding-right: 0;
			padding-left: 0.15em;
			font-size: 40px;
			text-align: center;
			border: 0;
			background: transparent;
			pointer-events: none;
			vertical-align: middle;
			width: auto;
			-moz-appearance: textfield;

			&:disabled {
				color: $color-black;
			}

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}

	&:first-child {
		margin-bottom: 20px;
		> * {
			width: 50%;
		}
		border-right: 0;
		border-bottom: 1px solid $color-grey-border;
	}

	&:last-child {
		border: 0;
	}
}


@include media-breakpoint-up(sm) {

	.form-control,
	select.form-control {
		height: 88px;
		padding-left: 34px;
		&.form-control-sm {
			padding-left: 30px;
		}
	}

	textarea.form-control {
		height: 390px;
	}

	select.form-control {
		&:not([size]):not([multiple]) {
			height: 88px;
		}
	}

	.label-textbox {
		height: 88px;
	}

	.input-group {
		height: 88px;

		.btn {
			@include text(30, 32, 0.5);
			margin: 0;
		}

		&.input-group-sm {
			height: 72px;

			.form-control {
				padding-left: 30px;
			}
		}
	}

	.custom-checkbox {
		padding: 6px 0 0 55px;

		.custom-control-indicator {
			top: 2px;
			width: 42px;
			height: 42px;
		}

		&.checkbox-sm {
			.custom-control-indicator {
				top: 4px;
				width: 42px;
				height: 42px;
			}
		}
	}

	.custom-radio {
		.custom-control-indicator {
			top: 2px;
			width: 42px;
			height: 42px;
		}
	}

	.form-group-number {
		.input-group-number {
			input[type="number"] {
				font-size: 100px;
			}
		}
	}
}

.password-help {
	font-size: .75em;
	line-height: 18px;
}

@include media-breakpoint-up(md) {

	.form-control,
	select.form-control {
		@include text(14, 30);
		height: 48px;
		margin-bottom: 0.7rem;
		padding-left: 18px;

		&.form-control-sm {
			padding-left: 16px;
		}
	}

	textarea.form-control {
		height: 160px;
	}

	.custom-select-label {
		.icon-svg {
			top: 50%;
			right: 13px;
			width: 12px;
			height: 12px;
		}

		.label {
			&.active {
				~ .form-control {
					padding-top: 14px;
				}
			}
		}
	}

	select.form-control {
		&:not([size]):not([multiple]) {
			display: block;
			height: 48px;
		}
	}

	.label-textbox {
		height: 48px;
	}

	.intl-label {
		padding-left: px-vw(96, lg);

		.intl-tel-input.allow-dropdown {
			.selected-flag {
				width: px-vw(77, lg);
			}

			input[type="tel"] {
				padding-left: px-vw(95, lg);
			}
		}
	}

	.input-group {
		height: 48px;

		.btn {
			@include text(12, 18, 0.5);
		}

		.label {
			font-weight: 400;

			&.active ~ .form-control {
				font-weight: 300;
			}
		}

		&.input-group-sm {
			height: 40px;

			.form-control {
				padding-left: 16px;
			}

			.btn {
				@include text(12, 18, 0.5);
			}
		}
	}

	.custom-checkbox {
		padding: 0 0 0 26px;

		.custom-control-indicator {
			width: 18px;
			height: 18px;
		}

		&.checkbox-sm {
			.custom-control-indicator {
				top: 4px;
				width: 15px;
				height: 15px;
			}
		}
	}

	.custom-radio {
		.custom-control-indicator {
			top: 4px;
			width: 14px;
			height: 14px;
		}
	}

	.form-group-number {
		padding: 0;

		.input-group-number {
			button {
				.icon-svg {
					width: 16px;
					height: 16px;
				}

			}

			input[type="number"] {
				width: 70px;
				font-size: 50px;
			}
		}

		&:first-child {
			margin-bottom: 0;
			> * {
				width: auto;
			}
			border-right: 1px solid $color-grey-border;
			border-bottom: 0;

		}
	}
}

@include media-breakpoint-up(lg) {
	.intl-label {
		padding-left: 96px;

		.intl-tel-input.allow-dropdown {
			.selected-flag {
				width: 77px;
			}

			input[type="tel"] {
				padding-left: 95px;
			}
		}
	}
}

// IE11 + edge fix for clear button
input::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

.zoom-enabled {
	.form-group-number {
		@include media-breakpoint-down(md) {
			.input-group-number {
				input[type="number"] {
					font-size: 32px;
					line-height: 32px;
					max-width: 30%;
					width: 30%;
				}
			}
		}
	}
}
