.numbered-article {
	ol.numbered {
		counter-reset: items;
		list-style: none;

		> li {
			margin-bottom: 30px;

			.number {
				display: block;
				counter-increment: items;

				&:before {
					float: right;
					font-size: px-vw(60);
					line-height: 1;
					content: '0' counter(items);
				}
			}

			&:nth-child(n+10) {
				.number:before {
					content: counter(items);
				}
			}
		}
	}

	h2 {
		margin: 70px 0 26px;
		font-size: px-vw(54);
		text-align: center;

		&:first-of-type {
			margin-top: 30px;
		}
	}

	img {
		width: 100%;
	}

	.content {
		margin-top: 15px;
	}

	.title {
		font-weight: 500;
		font-size: px-vw(32);
	}

	p {
		font-size: px-vw(28);
		font-weight: 300;
		line-height: 1.43;
	}

	@include media-breakpoint-up(sm) {
		h2 {
			font-size: rem-calc(54);
		}


		.title {
			font-size: rem-calc(32);
		}

		p {
			font-size: rem-calc(28);
		}
	}

	@include media-breakpoint-up(md) {
		h2 {
			font-size: rem-calc(36);
		}


		.title {
			font-size: rem-calc(16);
		}

		p {
			font-size: rem-calc(14);
			line-height: 1.71;
		}

		img {
			max-width: 389px;
		}

		.content {
			margin: 0;
			padding-left: 28px;
		}
	}
}
