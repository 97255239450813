.feature ~ .travel-log-details {
	margin-top: px-vw(-52);

	@include media-breakpoint-up(md) {
		margin-top: -34px;
	}
}


.travel-log-details {
	margin-top: px-vw(52);
	text-align: center;

	&.completed {
		.form-hint {
			height: 0;
			opacity: 0;
			overflow: hidden;
			transition: all 0.3s;
		}

		.form-thank-you {
			height: px-vw(76);
			padding-bottom: 10px;
			opacity: 1;
			animation: reveal 2s 5s;
			animation-fill-mode: forwards;
		}
	} 

	.form-thank-you {
		height: 0;
		margin: 0;
		opacity: 0;
	}

	@keyframes reveal {
		0% {
			opacity: 1;
		}

		100% {
			height: 0;
			padding: 0;
			overflow: hidden;
			opacity: 0;
		}
	}

	.container.container-inset {
		padding: px-vw(30) px-vw(15);
	}

	.edit-menu {
		[data-toggle='collapse'] {
			@include font-size(100);
			position: relative;
			top: 7px;
			width: 60px;
			height: 30px;
			margin: 0;
			padding: 0;
			float: right;
			line-height: 0;
			letter-spacing: -9px;
			transform: rotate(-90deg);
			transition: transform 0.6s;

			&:focus,
			&:hover {
				color: $color-black-fake;
			}

			span {
				position: absolute;
				top: -17px;
				display: block;
				width: 20px;
				height: 20px;
			}

			&.collapsed {
				transform: rotate(0);
			}
		}

		.shareButton {
			right: 40px;
		}

		.collapse,
		.collapsing {
			button {
				margin-bottom: 5px;
			}
		}

		.collapse,
		.collapsing {
			position: relative;
			top: 28px;
			right: 14px;
			margin-bottom: 8px;
			clear: both;
		}
	}

	select.form-control {
		padding-top: px-vw(38);
		font-weight: 400;
		color: $color-black-fake;
	}

	#travel-log-rename-form { // sass-lint:disable-line no-ids
		display: none;

		button[data-cancel] {
			margin-top: 20px;
			font-weight: 400;
		}
	}

	.calendar-collapse {
		position: absolute;
		top: px-vw(85);
		left: px-vw(-33);
		width: px-vw(600);

		&.show,
		&.collapsing {
			z-index: 1;
		}
	}

	.check-in-toggler,
	.check-out-toggler {
		@include font-size(28);
		display: block;
		margin-bottom: px-vw(14);
		padding: px-vw(42) px-vw(33);
		height: px-vw(88);
		line-height: px-vw(30);
		letter-spacing: 0;
		border: 1px solid $color-black-fake;
		text-align: left;

		.date {
			display: block;
			font-weight: 400;
		}

		.label {
			top: px-vw(28);

			&.active {
				top: px-vw(13);
			}
		}

		.icon-svg {
			top: 50%;
			transform: rotate(-180deg) translateY(50%);
			transition: transform 0.4s;
		}

		&.collapsed {
			.icon-svg {
				transform: rotate(0deg) translateY(-50%);
			}
		}

		&:not(.collapsed) {
			position: relative;
			z-index: 2;
			border-bottom: 1px solid $color-white;
		}
	}

	.flatpickr-calendar.inline {
		padding: px-vw(32);
		border: 1px solid $color-black-fake;
	}

	.flatpickr-day {
		margin: 0;
		padding: px-vw(10);
		height: auto;

		&.selected {
			border: 1px solid $color-black-fake;
		}
	}

	@include media-breakpoint-up(sm) {
		.check-in-toggler,
		.check-out-toggler {
			height: 88px;
		}
	}

	@include media-breakpoint-up(md) {
		margin-top: 34px;

		.container.container-inset {
			padding: 24px 30px;
		}

		&.completed {
			.form-thank-you {
				height: 30px;
			}
		} 

		.edit-menu {
			[data-toggle='collapse'] {
				right: 16px;
				width: auto;
				height: auto;
				font-size: 46px;
				letter-spacing: -4px;

				span {
					top: -6px;
					right: 0;
				}

			}

			.collapse,
			.collapsing {
				position: absolute;
				top: 43px;
				right: 0;
			}
		}

		#travel-log-rename-form { // sass-lint:disable-line no-ids
			button[data-cancel] {
				margin-top: 0;
			}
		}

		.custom-select-label {
			.label {
				@include font-size(14);
				position: absolute;
				top: 14px;
				left: 19px;
				transition: all 0.4s;

				&.active {
					font-size: 12px;
					top: 5px;
					font-weight: 500;
				}
			}
		}

		select.form-control {
			padding-top: 14px;
			font-weight: 300;
		}

		.check-in-toggler,
		.check-out-toggler {
			@include font-size(14);
			padding: 0.375rem 1.75rem 0.375rem 18px;
			height: 48px;
			line-height: 30px;

			.label {
				top: 15px;
				line-height: 17px;
			}

			.icon-svg {
				top: 24px;
			}

			.date {
				margin-top: 8px;
				font-weight: 300;
			}

			&:not(.collapsed) {
				height: 60px;
				margin-bottom: -16px;
				transition: height 0.4s;
			}
		}

		.flatpickr-calendar.inline {
			padding: 0;
		}

		.flatpickr-day {
			padding: 0;
		}

		.calendar-collapse {
			top: 57px;
			width: 350px;

			&#check-out-calendar { // sass-lint:disable-line no-ids
				right: 10px;
				left: auto;
			}
		}
	}

	.headline-1 {
		overflow-wrap: break-word;
		word-break: break-word;
		-ms-word-break: break-all;
		word-wrap: break-word;
	}
}
