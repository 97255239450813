ul,
ol {
	&.list-bulleted {
		margin-bottom: 1.5em;
		padding-left: 0;
		list-style: none;
		line-height: px-vw(44);

		li {
			position: relative;
			padding-left: px-vw(36);

			&:before {
				position: absolute;
				top: px-vw(16);
				left: 0;
				width: px-vw(12);
				height: px-vw(12);
				content: '';
				background-color: $color-black;
				border-radius: 50%;
			}
		}
	}

	&.list-centered {
		list-style: none;

		li {
			text-align: center;

			&:before {
				display: inline-block;
				position: relative;
				margin-right: 10px;
				width: 6px;
				height: 6px;
				content: '';
				background-color: $color-black;
				border-radius: 50%;
				vertical-align: middle;
			}
		}
	}

	&.list-inline {

		line-height: 1.75em;

		li {
			display: inline-block;
			border: 0;
			vertical-align: middle;
		}

		&.list-col-4 {
			li {
				display: block;
			}
		}
	}

	&.list-separated {
		list-style: none;

		li {
			border-bottom: 1px solid $color-grey-border;
		}

		&.list-inline {
			li {
				border-bottom: 0;
				& + li:before {
					display: inline-block;
					width: 1px;
					height: 1.25em;
					margin: 0 1em 0 0.75em;
					content: '';
					background-color: $color-grey-border;
					vertical-align: middle;
				}
			}
		}
	}

	&.numbered {
		padding: 0;
		counter-reset: items;
		list-style: none;

		> li {
			counter-increment: items;
			&:nth-child(n+10) {
				.number:empty {
					&:before {
						content: counter(items);
					}
				}
			}
			&:nth-child(-n+9) {
				.number:empty {
					&:before {
						content: '0' counter(items);
					}
				}
			}
		}

		.border-left {
			@include body-text();
			margin-bottom: 1rem;
			padding-left: px-vw(36);
			border-left: px-vw(10) solid $color-aqua;
		}

		.listicle-numbered-header {
			font-size: .875rem;
			line-height: 24px;
		}
	}

	&.list-icons {
		li {
			margin: 0 0 px-vw(30) 0;
			padding: 0 px-vw(10);
			text-align: center;
			font-weight: 500;

			.icon-svg {
				display: block;
				width: px-vw(84);
				height: px-vw(84);
				margin: 0 auto px-vw(15) auto;
			}
		}
	}


	@include media-breakpoint-up(sm) {

		&.list-bulleted {
			line-height: 44px;

			li {
				padding-left: 36px;

				&:before {
					top: 16px;
					width: 12px;
					height: 12px;
				}
			}
		}

		&.list-icons {
			li {
				margin: 0 0 30px 0;
				padding: 0 10px;

				.icon-svg {
					width: 84px;
					height: 84px;
					margin: 0 auto 15px auto;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		&.list-bulleted {
			line-height: 24px;

			li {
				padding-left: 26px;

				&:before {
					top: 10px;
					width: 6px;
					height: 6px;
				}
			}
		}

		&.list-separated {
			&.list-inline {
				line-height: inherit;
			}
		}

		&.numbered {
			.border-left {
				padding-left: 15px;
				border-width: 6px;
			}
		}


		&.list-icons {
			li {
				margin: 0 0 px-vw(20, lg) 0;
				.icon-svg {
					width: px-vw(32, lg);
					height: px-vw(32, lg);
				}
			}
		}

	}

	@include media-breakpoint-up(lg) {
		&.list-icons {
			li {
				margin: 0 0 10px 0;
				.icon-svg {
					display: inline-block;
					width: 32px;
					height: 32px;
					margin: 0 10px 0 0;
					vertical-align: middle;
					text-align: left;
				}
			}
		}
	}

}

dl {
	&.list-inline {
		dt {
			float: left;
			margin-right: 0.4em;
			font-weight: inherit;
		}

		dd {
			margin-bottom: 0;
		}
	}
}

[data-filter="other"] {
	@include media-breakpoint-up(md) {
		.col-12 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}



[data-filter="destination"] .list-group {

	.list-group-item.parent {
		border-top: 0;
		padding-top: 2em;

		&:first-of-type {
			padding-top: 0;
		}

		.headline-6 {
			padding: 0;
			margin: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		display: block;
		column-count: 2;
		column-rule-style: solid;
		column-gap: 70px;
		column-rule-width: 1px;
		column-rule-color: $color-grey-border;
	}
}
