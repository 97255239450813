.personality-focus {
	figure {
		float: left;
		width: 51%;
		padding: 0 20px;
	}

	@include media-breakpoint-up(md) {
		figure {
			width: 42%;
			margin-right: 24px;
		}
	}
}