.search {
	transition: none !important;

	form {
		width: 100%;

		.form-group {
			position: relative;
			margin: px-vw(50) px-vw(72) px-vw(50) px-vw(36);
			background-color: transparent;
			z-index: $aah-zindex-search;
		}

		.form-actions {
			@include absolute(6);
			display: inline-block;
			margin-right: px-vw(30);
			z-index: $aah-zindex-search;

			.icon-svg {
				width: 1.5em;
			}

			.btn-search, .btn-close {
				position: relative;
				margin: 1em;
				vertical-align: middle;
				flex-shrink: 0;

				.icon-svg {
					@include absolute(5);
				}
			}

			.btn-search {
				.icon-svg {
					width: 85%;
					height: 85%;
					fill: $color-black;
				}
			}

			.btn-close {
				padding: 1em;
				border-radius: 100%;
				background-color: $color-aqua-dark;

				.icon-svg {
					width: 40%;
					height: 40%;
					fill: $color-white;
				}
			}
		}
	}

	.search-input {
		width: 100%;
		padding: 0 1.75em;
		font-size: px-vw(28);
		font-weight: 300;
		border: none;
	}

	ul.search-results {
		margin: 20px 30px;
		right: 0;
		left: 0;
		border: 0;

		li {
			padding: 0;

			.dropdown-item {
				@include body-text();
				display: inline-block;
				width: auto;
				padding: 1em;
				margin: 0;
				color: $color-black-fake;

				&:hover, &:focus {
					text-decoration: underline;
					background-color: transparent;
				}
			}
		}
	}

	.search-backdrop {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $color-white;
		opacity: 1;
		z-index: 0;
	}

	&.active {
		top: 0;
		width: 100%;
		height: 100vh;

		form {
			.form-group {
				margin: px-vw(50) px-vw(96) px-vw(50) px-vw(46);
			}
			.form-actions {
				.btn-close {
					display: inline-block;
				}
				.btn-search {
					margin-right: 0;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {

		form {
			.form-group {
				margin: 50px 72px 0 72px;
			}
		}

		.search-input {
			height: 88px;
			font-size: 28px;
		}

	}

	@include media-breakpoint-up(lg) {

		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% + 0);
		background: $color-white;
		z-index: $aah-zindex-modal;

		form {
			@include absolute(1);
			width: 100%;
			height: 100%;
			margin: 0;
			background-color: $color-white;
			z-index: 1;

			.form-group {
				width: 100%;
				height: 100%;
				margin: 0;
			}

			.form-actions {
				@include absolute(6);
				display: inline-block;
				margin-right: 10%;

				.btn-close, .btn-search {
					width: 42px;
					height: 42px;
				}
			}
		}

		.search-input {
			@include headline(2);
			position: absolute;
			top: 0;
			left: 0;
			width: 75%;
			height: 100%;
			padding: 0 0 0 10%;
			font-size: 44px;
			text-transform: uppercase;
			border: 0;
			z-index: 1000;
		}

		ul.search-results {
			width: 100%;
			margin: 0;
			padding: 0 0 55px 9%;
			background-color: $color-white;

			li {
				.dropdown-item {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}

		.search-backdrop {
			position: fixed;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $color-black;
			opacity: 0.5;
			z-index: 0;
		}
	}
}
