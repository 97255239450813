.vacations-in-progress {

	text-align: center;
	margin: px-vw(8) 0;

	.figure-group {
		padding: px-vw(12) px-vw(6);
	}

	figure {
		position: relative;
		display: block;
		float: left;
		margin: px-vw(6);


		picture {
			@include image-fit();
			display: block;
			width: px-vw(294);
			height: px-vw(294);
			margin: 0;
		}

		figcaption {
			[data-profile-photo] {
				display: flex;
				width: 48px;
				height: 48px;
			}
		}

		.icon-overlay {
			@include absolute(9);
			width: px-vw(28);
			height: px-vw(28);
			margin-right: px-vw(12);
			margin-bottom: px-vw(12);
			z-index: $aah-zindex-button-overlay;

			svg {
				fill: $color-white;
			}
		}

	}

	.carousel-indicators {
		margin-top: px-vw(35);
	}

	.slick-dots li {
		display: inline-block;

		button {
			display: block;
		}
	}

	.slick-prev, .slick-next {
		top: 2.5vw;
		left: 0;
		visibility: hidden;
	}

	footer {

		.headline {
			display: inline-block;
			width: 100%;
			vertical-align: top;
			margin: 1em 0;
		}

		.btn {
			display: block;
			width: 60%;
			margin-right: auto;
			margin-left: auto;
		}

		.olapic-widget-rendered {
			margin-left: auto;
			margin-top: 0.75em;

			a.btn.btn-default {
				@include button("stroke");
				background: $color-white;
				border-radius: 0;
				padding-left: 10px;
				padding-right: 10px;
				text-shadow: none;

				&:hover {
					color: $color-white;
					background: $color-aqua-dark;
				}
			}
		}

		.olapic:not(.olapic-widget-rendered) {
			@include body-text("small");
			margin-top: 0.5em;

			.icon-svg {
				width: px-vw(96);
				height: px-vw(96);
				margin-left: 0.5em;

				svg { fill: $color-black; }
			}

			a {
				text-decoration: none;

				&:hover, &:focus {
					color: inherit;
				}
			}

		}
	}


	@include media-breakpoint-up(sm) {

		figure {
			.icon-overlay {
				width: 28px;
				height: 28px;
				margin-right: 12px;
				margin-bottom: 12px;
			}
		}

		footer {
			.olapic:not(.olapic-widget-rendered) {
				.icon-svg {
					width: 96px;
					height: 96px;
				}
			}
		}

	}

	@include media-breakpoint-up(md) {
		margin-bottom: 100px;

		.figure-group {
			display: block;
			padding: 0;

			&:nth-child(odd) picture {
				width: px-vw(197.5);
				height: px-vw(197.5);
			}

			&:nth-child(even) picture {
				width: px-vw(94);
				height: px-vw(94);
			}
		}

		figure {
			float: none;
			margin: 0 px-vw(7.5, lg) px-vw(15, lg);

			picture {
				display: block;
			}
		}

		.carousel-indicators {
			display: none;
			margin-top: 14px;
		}

		.slick-dots li {
			display: none;
		}

		.slick-prev, .slick-next {
			position: relative;
			display: inline-block;
			top: 0;
			margin: 27px 22px;
			visibility: visible;
		}

		footer {
			position: relative;

			.headline {
				display: inline-block;
				width: auto;
				margin: 0 1em 0 0;
				line-height: 48px;
			}

			.btn {
				display: inline-block;
				width: auto;
				& + .btn {
					margin-top: 0;
					margin-left: 0.75em;
				}
			}

			.olapic-widget-rendered {
				display: inline-block;
				margin-top: 0;
				margin-left: 0.75em;
				
				a.btn.btn-default {
					padding-left: 32px;
					padding-right: 32px;
				}
			}

			.olapic:not(.olapic-widget-rendered) {
				position: absolute;
				top: px-vw(-130, lg);
				right: px-vw(20, lg);

				.icon-svg {
					width: px-vw(54, lg);
					height: px-vw(54, lg);
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {

		footer {
			.olapic:not(.olapic-widget-rendered) {
				top: -130px;
				right: 20px;

				.icon-svg {
					width: 54px;
					height: 54px;
				}
			}
		}

	}
}
