.insider {
	position: relative;
	padding: 0;
	background-color: $color-white;
	color: $color-white;
	border-bottom: 1px solid $color-grey-border;

	.background {
		position: relative;
		width: 100%;
		height: px-vw(310);
		margin: 0 0 px-vw(55) 0;

		picture {
			@include image-fit();
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.logo {
		width: px-vw(324);
		margin-bottom: px-vw(60);
	}

	ul.list-inline {

		margin: 0 0 px-vw(48) 0;

		li {
			
			display: block;
			margin: 0 0 1.5em 0;
			font-size: px-vw(32);
			font-weight: 500;
			line-height: 24px;
			color: $color-black;

			.icon-svg {
				width: px-vw(70);
				height: px-vw(70);
				margin-right: 1em;
				vertical-align: middle;

				svg {
					fill: $color-black;
				}
			}
			
		}
	}

	.form-group {
		margin: 0 px-vw(25) px-vw(55) px-vw(25);

	}

	

	@include media-breakpoint-up(sm) {
		//fix-size any scaling font/spacing sizes here

		.background {
			height: 310px;
			margin-bottom: 55px;
			overflow: hidden;

		}

		.logo {
			width: 324px;
			margin-bottom: 53px;
		}

		ul.list-inline {
			margin: 0 0 48px 0;
			li {

				font-size: 32px;

				.icon-svg {
					width: 70px;
					height: 70px;
				}
			}
		}

		.form-group {
			margin: 0 25px 55px 25px;

		}
	}

	@include media-breakpoint-up(md) {
		height: 310px;
		padding: 60px 0 30px 0;
		background-color: $color-aqua-dark;
		border: 0;

		.background {
			position: absolute;
			top: 0;
			height: 310px;
			overflow: hidden;

			img {
				position: absolute;
				min-width: 100%;
				max-width: none;
			}
		}

		.logo {
			width: 170px;
			margin-bottom: 28px;
		}

		ul.list-inline {

			margin: 0 0 20px 0;
			li {
				display: inline-block;
				margin: 0 1.25em 0.75em 0;
				font-size: 14px;
				color: $color-white;
				
				.icon-svg {
					width: 24px;
					height: 24px;
					margin-right: 0.75em;

					svg {
						fill: $color-white;
					}
				}
				
			}
		}

		.form-group {
			margin: 0 45px;
		}

	}

}
