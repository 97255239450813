.vacation-experience-list {

	padding: px-vw(40) 0 0 0;

	h2 {
		margin-bottom: px-vw(50);
		padding: 0 px-vw(20);
	}

	@include media-breakpoint-up(sm) {

		padding: 40px 0 0 0;

	}

	@include media-breakpoint-up(md) {

		h2 {
			margin-bottom: 1em;
			padding: 0;
		}

 	}

	@include media-breakpoint-up(lg) {

	}

}
