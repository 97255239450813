.our-favorites {
	.slick-track {
		display: flex;
		flex-flow: row;
	}

	/*
	.container {
		border-bottom: 13px solid $color-aqua;
	}
	*/

	article {
		@include make-col-ready();
		width: px-vw(512);
		max-width: px-vw(512);
		height: auto;
		margin: px-vw(110) px-vw(20) 0 0;
	}

	@include media-breakpoint-up(sm) {
		article {
			margin-top: 110px;
			width: 512px;
		}
	}

	@include media-breakpoint-up(md) {
		.slick-track {
			display: block;
		}

		article {
			@include make-col-ready();
			@include make-col(4);
			margin: 80px 0 0;
			
			.btn-overlay {
				margin: 0 0.25em;
			}
		}
	}
}
