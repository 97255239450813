.icon-svg {
	position: relative;
	display: inline-block;
	width: 100%;
	vertical-align: middle;
	background-color: transparent;

	&:after {
		display: block;
		padding-bottom: 100%;
		content: "";
		pointer-events: none;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.icon {
	&-xxs { width: 0.75em; height: 0.75em; }
	&-xs { width: 1em; height: 1em; }
	&-sm { width: 2em; height: 2em; }
	&-md { width: 3em; height: 3em; }
	&-lg { width: 4em; height: 4em; }
	&-xl { width: 5em; height: 5em; }
}

#icon-bundle { // sass-lint:disable-line no-ids
	position: absolute;
	width: 0;
	height: 0;
	z-index: -1;
	overflow: hidden;

	svg {
		display: none;
	}
}


