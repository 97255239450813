.timeline-item {

	position: relative;
	padding-top: px-vw(66);

	&:before,
	&:last-of-type:after {
		width: 1px;
		height: px-vw(55);
		background-color: #c1c1c1;
		content: '';
	}

	&:first-of-type,
	&:last-of-type {
		.headline-3:before {
			width: px-vw(25);
			height: px-vw(25);
			background-color: $color-white;
			border: 1.5px solid #c1c1c1;
			border-radius: 50%;
			content: '';
			z-index: 1;
		}
	}

	&:before {
		@include absolute(2);
	}

	&:first-of-type {
		margin-top: px-vw(20);

		.headline-3:before {
			@include absolute(2);
			top: px-vw(-22);
		}
	}

	&:last-of-type {
		padding-bottom: px-vw(56);

		.headline-3:before {
			@include absolute(8);
			bottom: px-vw(-22);
		}

		&:after {
			@include absolute(8);
		}
	}

	.collapsible-btn {
		@include body-text();
		margin-bottom: 1rem;
		text-decoration: underline;
	}


	@include media-breakpoint-up(md) {

		padding-top: 36px;

		&:before,
		&:last-of-type:after {
			height: 31px;
		}


		&:first-of-type,
		&:last-of-type {
			.headline-3:before {
				width: 13px;
				height: 13px;
			}
		}

		&:before {
			@include absolute(2);
		}

		&:first-of-type {
			margin-top: 0;

			.headline-3:before {
				top: -8px;
			}
		}

		&:last-of-type {
			margin-bottom: 20px;
			padding-bottom: 28px;

			.headline-3:before {
				bottom: -8px;
			}
		}

	}
}
