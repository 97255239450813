.carousel-factory {
	position: relative;

	.carousel-indicators {
		position: relative;
		height: 20px;

		.slick-dots:nth-child(even) {
			display: none !important; // sass-lint:disable-line no-important
		}
	}

	.slick-track {
		margin: 0 auto;
	}

	.slick-dots {
		bottom: 0;
		left: 0;
		white-space: nowrap;
		display: none !important;

		li {
			display: none;
			width: 16px;
			height: 16px;
			margin: 0 2px;

			button {
				display: none;
				width: 16px;
				height: 16px;

				&:before {
					top: 4px;
					left: 4px;
					width: 12px;
					height: 12px;
					content: '';
					border-radius: 50%;
					opacity: 1;
					border: 1px solid $color-black;
				}
			}
		}

		.slick-active button {
			&:before {
				opacity: 1;
				background: $color-black;
			}
		}
	}

	.carousel-tabs {
		display: none;
	}

	.slick-prev, .slick-next {
		top: px-vw(16);
		width: px-vw(46);
		height: px-vw(46);
		z-index: $zindex-dropdown-backdrop;
		background-color: $color-white;

		&:hover, &:focus {
			background-color: $color-white;
			svg {
				fill: $color-aqua-dark;
			}
		}

		&:before {
			content: '';
		}

		.icon-svg {
			@include absolute(5);
			width: px-vw(16);
			height: px-vw(16);
		}
	}

	&[data-arrows="bottom"],
	&[data-mobile-indicators] {
		.slick-dots {
			li {
				display: inline-block;

				button {
					display: block;
				}
			}
		}

		.carousel {
			margin: 0 auto;
		}
	}

	&[data-arrows-mobile="bottom"] {
		.slick-arrow {
			position: relative;
			display: inline-block;
			right: auto;
			left: auto;
			margin: 25px 10px 0;
		}
	}

	&[data-mobile-indicators="false"] {
		.slick-dots {
			li {
				display: none;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.slick-prev {
			left: 0;
		}
		
		.slick-next {
			right: 0;
		}
	}

	@include media-breakpoint-down(md) {

		&[data-arrows-mobile="none"] {
			.slick-prev, .slick-next {
				display: none !important; // sass-lint:disable-line no-important
			}
		}
		&[data-arrows-mobile="sides"] {
			//padding: 0 px-vw(50);
			.slick-prev {
				@include absolute(4);
				margin-left: 0;
			}
			.slick-next {
				@include absolute(6);
				margin-right: 0;
			}
		}
		&[data-arrows-mobile="sides-quarter-top"] {
			.slick-prev {
				@include absolute(10);
				margin-left: 0;
			}
			.slick-next {
				@include absolute(11);
				margin-right: 0;
			}
		}
		&[data-mobile-indicators="none"] {
			.slick-dots {
				display: none !important; // sass-lint:disable-line no-important
			}
		}
	}

	@include media-breakpoint-up(md) {
		.slick-prev, .slick-next {

			top: 16px;
			width: 46px;
			height: 46px;

			.icon-svg {
				width: 16px;
				height: 16px;
			}
		}
		&[data-arrows-desktop="none"] {
			.slick-prev, .slick-next {
				display: none !important; // sass-lint:disable-line no-important
			}
		}
	 }

	@include media-breakpoint-up(md) {

		.carousel-tabs {
			display: block;
			margin: 30px 0;

			.slick-track {
				display: flex;
    			justify-content: center;
			}
			
			.slick-slide {
				cursor: pointer;
				list-style-type: none;
				margin-top: 3px;
				padding: 13px 20px;
				color: $color-black-fake;
				font-size: 14px;
				font-weight: 300;
				border-top: 1px solid #c8c8c8;
				text-decoration: none;
				text-transform: uppercase;

				&.slick-current {
					margin-top: 0;
					padding: 11px 22px 13px 22px;
					font-weight: 500;
					border-top: 6px solid $color-aqua-dark;

					&:hover, &:focus {
						color: $color-black-fake;
					}
				}

				&:hover, &:focus {
					color: $color-aqua-dark-hover;
				}
			}
			

			// a {
			// 	margin-top: 3px;
			// 	padding: 13px 20px;
			// 	color: $color-black-fake;
			// 	font-size: 14px;
			// 	font-weight: 300;
			// 	border-top: 1px solid #c8c8c8;
			// 	text-decoration: none;
			// 	text-transform: uppercase;

			// 	&.slick-current {
			// 		margin-top: 0;
			// 		padding: 11px 22px 13px 22px;
			// 		font-weight: 500;
			// 		border-top: 6px solid $color-aqua;

			// 		&:hover, &:focus {
			// 			color: $color-black-fake;
			// 		}
			// 	}

			// 	&:hover, &:focus {
			// 		color: $color-aqua-dark;
			// 	}
			// }
		}

		.slick-dots {

			li {
				button {
					&:before {
						top: 4px;
						left: 4px;
						width: 8px;
						height: 8px;
					}
				}
			}
		}

		.slick-prev, .slick-next {
			width: px-vw(46, lg);
			height: px-vw(46, lg);

			.icon-svg {
				width: px-vw(16, lg);
				height: px-vw(16, lg);
			}
		}

		&[data-arrows="top"] {
			.slick-prev { @include absolute(1); }
			.slick-next { @include absolute(3); }
		}

		&[data-arrows="bottom"] {
			padding-bottom: 90px;

			.slick-prev {
				@include absolute(8);
				margin-bottom: 70px;
				margin-left: -45px;
			}

			.slick-next {
				@include absolute(8);
				margin-bottom: 70px;
				margin-left: 45px;
			}

			.slick-dots {
				li {
					display: none;
				}
			}
		}

		&[data-arrows="none"] {
			.slick-prev, .slick-next {
				display: none !important; // sass-lint:disable-line no-important
			}
		}

		&[data-mobile-indicators] {
			.slick-dots {
				li {
					display: none;
				}
			}
		}

		&[data-arrows="sides"] {

			width: 92.5%;
			margin: 0 auto;

			.carousel {
				width: 90.5%;
				margin: 0 auto;
			}

			.slick-prev {
				@include absolute(4);
				margin-top: px-vw(-23, lg);
				margin-left: px-vw(-23, lg);
				//margin-left: 0;
			}
			.slick-next {
				@include absolute(6);
				margin-top: px-vw(-23, lg);
				margin-right: px-vw(-23, lg);
				//margin-right: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {

		.slick-prev, .slick-next {
			width: 46px;
			height: 46px;

			.icon-svg {
				width: 16px;
				height: 16px;
			}

		}

		&[data-arrows="sides"] {

			width: 100%;
			.carousel {
				//width: 87.5%;
			}

			.slick-prev {
				@include absolute(4);
				margin-top: -23px;
				margin-left: -23px;
			}

			.slick-next {
				@include absolute(6);
				margin-top: -23px;
				margin-right: -23px;
			}
		}

	}

	.slick-slide {
		text-transform: none;
	}

	.carousel-tabs {
		.slick-slide {
			button {
				background: transparent;
				border: 0;
				border-radius: 0;
				cursor: pointer;
				color: #2b2b2b;
				font-size: 14px;
				font-weight: 300;
				text-decoration: none;
				text-transform: uppercase;
			}
			&.slick-current {
				button {	
					font-weight: 500;
				}
			}
		}
	}
}

// Jahia Activity - textOnly slider
.activity {
	.carousel-factory[data-slides-to-show='1'] {
		.slick-prev,
		.slick-next {
			top: 50%;
		}
	}
}
