.filter {

	.btn-filter {
		@include text(26, 35, 0.1);
		width: 100%;
		max-width: 503px;
		margin: 2rem 0;
		padding-right: 18px;
		padding-left: 18px;
		white-space: normal;
		background-color: $color-white;
		color: $color-black-fake;
		border: 1px solid $color-grey-border;

		span {
			font-weight: 300;
		}

		.icon-svg {
			float: right;
			width: px-vw(30);
			height: px-vw(30);

			svg {
				fill: $color-black-fake;
			}
		}
	}

	.filter-collapse {

		position: fixed;
		top: 0;
		width: 100%;
		margin-top: 0;
		background: $color-white;
		text-align: left;
		z-index: $aah-zindex-dropdown;

		&.show {
			height: 100%;
			padding-bottom: 25%;
		}

		.filter-wrapper {
			height: 100%;
			min-height: px-vw(773);
			padding-bottom: 12%;
			overflow: auto;

			.btn[aria-haspopup="true"] {
				position: relative;
				padding: px-vw(39) 0;
				background-color: $color-grey-light;
				color: $color-black;

				span {
					font-weight: 300;
				}

				.icon-svg {
					position: absolute;
					top: 50%;
					right: px-vw(39);
					fill: $color-black;
					transform: translateY(-50%);
				}
			}
		}

		.headline-5 {
			padding: 0 px-vw(40);
		}

		[data-toggle='collapse'] {
			width: 100%;
			margin-bottom: px-vw(30);
		}

		ul {
			&.list-unstyled {
				display: flex;
				margin: px-vw(20) 0 px-vw(40);
				padding: 0 px-vw(40) px-vw(26);
				flex-wrap: wrap;
				border-bottom: 1px solid $color-grey-border;

				li {
					width: 50%;

					label {
						margin: px-vw(14) 0;
					}
				}

				.form-check-input {
					margin-right: 8px;
				}
			}

			&.list-inline {
				column-count: 2;
				column-gap: 0.5em;

				li {
					margin-bottom: px-vw(20);
				}
			}

			&.type-ahead {
				margin-top: 0;

				li {
					width: 100%;
				}
			}
		}


		.property-search {
			.form-group {
				position: relative;
				max-width: 80%;
				margin: 0 px-vw(20) px-vw(30);
			}

			.btn-search {
				@include absolute(6);
				right: px-vw(18);
				width: px-vw(44);
				height: px-vw(44);
				vertical-align: middle;
				pointer-events: none;
				z-index: 1000;
			}

			.close {
				@include absolute(3);
				margin: px-vw(40);
				opacity: 1;
				padding: 2px;
				border-radius: 100%;
				background-color: $color-aqua-dark;

				.icon-svg {
					@include absolute(5);
					width: 40%;
					height: 40%;
					svg { fill: $color-white; }
				}
			}


			.btn-reset {
				@include absolute(6);
				right: px-vw(-60);
				width: px-vw(40);
				min-width: 0;
				height: px-vw(40);
				padding: 2px;
				border-radius: 50%;

				.icon-svg {
					@include absolute(5);
					width: 40%;
					height: 40%;
				}
			}
		}

		ul.property-search-results {
			display: block;
			height: px-vw(500);
			margin-left: 0.25em;
			overflow-y: scroll;
			transform: translate3d(0, 0, 0); //fixes scrollbar z-index issue
			column-count: auto;

			li {
				width: auto;
			}
		}

		.no-results {
			margin: 0 1em 2em 1em;
		}

		footer {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: px-vw(29) px-vw(15);
			border-top: solid 1px $color-grey-border;
			background-color: $color-grey-light;

			.results {
				@include body-text();
				margin-bottom: 1rem;
				padding-left: px-vw(20);
				font-weight: 500;
			}

			.btn {
				width: auto;
				margin: 0;
			}

			.btn-transparent {
				@include body-text();
				margin: 0 10px 0 0;
				text-decoration: underline;

				&:hover {
					color: $color-aqua-dark;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.filter-collapse {
			ul.property-search-results {
				max-width: 75%;
				height: 500px;
				column-count: auto;
			}
		}
	}

	@include media-breakpoint-up(md) {

		margin-bottom: 2em;

		.btn-filter {
			@include text(16, 24, 0.1);
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 125px;
			margin: 0;
			padding-bottom: 15px;
			text-align: left;
			transition: none;

			.icon-svg {
				width: 19px;
				height: 19px;
				margin-top: 2px;
			}

			&[aria-expanded="true"] {
				bottom: 0;
				padding-bottom: 25px;
				border-bottom: 1px solid $color-white;
				z-index: $aah-zindex-dropdown + 1;
			}
		}

		.filter-collapse {

			position: relative;
			margin-top: -1px;

			&.show,
			&.collapsing {
				&:after {
					position: absolute;
					top: 0;
					width: 100%;
					content: ' ';
					border-bottom: 1px solid $color-grey-border;
					z-index: 0;
				}
			}

			.filter-wrapper {
				height: auto;
				min-height: 0;
				padding-bottom: 16px;
			}

			.headline-5 {
				padding: 40px 0 0;
			}

			ul {
				&.list-unstyled {
					display: block;
					margin-top: 0;
					margin-bottom: 0;
					padding: 0;
					border-bottom: 0;
					column-count: 1;

					li {
						display: block;
						width: 100%;
						padding-right: 15px;

						label {
							margin: 0 0 0 4px;
						}
					}

					&.list-col-2 {
						column-count: 2;
						column-gap: 0.5em;
					}
				}


				&.list-inline {
					display: block;
					column-count: 4;

					li {
						width: auto;
						margin-bottom: 0;
						padding-right: 0;
					}
				}
			}

			.property-search {
				.form-group {
					max-width: none;
					margin: 0 auto 0;
				}

				.btn-search {
					right: 5px;
					width: 36px;
					height: 36px;
				}

				.btn-reset {
					width: 30px;
					height: 30px;
					right: -45px;

					.icon-svg {
						width: 14px;
						height: 14px;
						vertical-align: -2px;
					}
				}
			}
			ul.property-search-results {
				height: 190px;
				column-count: auto;
			}
			.no-results {
				margin: 0;
			}

			footer {
				position: relative;
				padding: 10px 0;
				border: 0;

				.results {
					padding-left: 0;
				}
			}

			&.show {
				padding-bottom: 2em;
			}
		}
	}
}

.filter-open {
	overflow: hidden;

	@include media-breakpoint-up(md) {
		overflow: visible;
	}
}
