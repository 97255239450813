.what-people-are-saying {

	height: px-vw(1050);
	overflow: hidden;

	.slick-track { display: flex; }
	.slick-slide { height: auto; }
	.col, .slick-initialized .slick-slide {
		display: flex; // needed for safari issue - fill height
	}

	.carousel-card {
		padding: px-vw(44) px-vw(60) px-vw(18);

		figure img {
			margin: auto;
			width: auto;
			max-width: 70%;
		}

		.flex-container {
			width: 100%;
		}
	}
	.center-and-pt {
		text-align: center;
		padding-top: 3em !important;
	}

	.icon-quote {
		position: relative;
		display: inline-block;
		width: px-vw(114);
		height: px-vw(114);
		background-color: $color-aqua-dark;
		border-radius: 50%;

		&:before {
			@include headline(1);
			@include absolute(5);
			@include font-size(90);
			padding: px-vw(40) 0 0 px-vw(6);
			color: $color-white;
			content: "“";
		}
	}

	.headline-5 {
		margin-bottom: px-vw(18);
		line-height: px-vw(43);
	}

	figure {
		margin: 0 0 px-vw(26) 0;

		picture, .icon-quote {
			margin: 0 auto;
			align-self: center;

			img {
				max-height: 125px;
			}
		}

		picture {
			width: 100%;
		}
	}

	footer {
		@include body-text();
		margin-bottom: 1rem;
	}

	.trip-advisor {

		.logo {
			display: inline-block;
		}

	}

	@include media-breakpoint-up(sm) {

		.icon-quote {
			width: 114px;
			height: 114px;
		}

		.headline-5 {
			margin-bottom: 18px;
			line-height: 43px;
		}
	}


	@include media-breakpoint-up(md) {

		height: px-vw(800, lg);
		overflow: visible;

		.carousel-card {
			padding: px-vw(50, lg) 30px px-vw(30, lg);
		}

		.icon-quote {
			width: 66px;
			height: 66px;

			&:before {
				@include font-size(60);
				padding: 25px 0 0 6px;
			}
		}

		.headline-5 {
			line-height: 27px;
		}

		figure {
			display: flex;
			margin: 0 0 26px 0;
			height: 75px;

			picture {
				img {
					max-height: 75px;
				}
			}
		}

		.trip-advisor {
			.logo {
				height: 22px;
			}

			.rating-count {
				@include font-size(12);
			}
		}
	}

	@include media-breakpoint-up(lg) {

		height: 650px;

		.carousel-card {
			padding: 50px 30px 37px;
		}
	}
}
