// Absolute helpers to place element within relative positioned parent
// Use anchor points:
// 	1	2	3
//		4	5	6
//		7	8	9
@mixin absolute($anchor: 1) {
	position: absolute;
	@if $anchor == 1 { top: 0; right: auto; bottom: auto; left: 0; }
	@else if $anchor == 2  { top: 0; right: auto; bottom: auto; left: 50%; transform: translateX(-50%); }
	@else if $anchor == 3 { top: 0; right: 0; bottom: auto; left: auto; }
	@else if $anchor == 4 { top: 50%; right: auto; bottom: auto; left: 0; transform: translateY(-50%); }
	@else if $anchor == 5 { top: 50%; right: auto; bottom: auto; left: 50%; transform: translate(-50%, -50%); }
	@else if $anchor == 6 { top: 50%; right: 0; bottom: auto; left: auto; transform: translateY(-50%); }
	@else if $anchor == 7 { top: auto; right: auto; bottom: 0; left: 0; }
	@else if $anchor == 8 { top: auto; right: 0; bottom: 0; left: 50%; transform: translateX(-50%); }
	@else if $anchor == 9 { top: auto; right: 0; bottom: 0; left: auto; }
	@else if $anchor == 10 { top: 25%; right: auto; bottom: auto; left: 0; transform: translateY(-50%); }
	@else if $anchor == 11 { top: 25%; right: 0; bottom: auto; left: auto; transform: translateY(-50%); }
	@else { }
}

@mixin absolute-grid($top: unset, $right: unset, $bottom: unset, $left: unset, $width: unset, $height: unset) {
	top: $top + px;
	right: $right + px;
	bottom: $bottom + px;
	left: $left + px;
	width: $width + px;
	height: $height + px;
}

@mixin color-support($color, $name) {
	.color-#{$name} {
		color: $color;
	}
	.back-color-#{$name} {
		background-color: $color;
	}
	.border-color-#{$name} {
		border-color: $color;
	}
	.icon-color-#{$name} {
		svg {
			fill: $color;
		}
	}
	hr {
		&.color-#{$name} {
			border-color: $color;
		}
	}
}

@mixin highlight($color: $color-blue-light) {
	padding: 0 0.25em 0 0.45em;
	background-image: linear-gradient(transparent, transparent 15%, $color 15%, $color 75%, transparent 75%);
	box-decoration-break: clone;

	@media screen and (min-width: 0\0) { // ie11
		padding: 0;
	}
	@supports (-ms-ime-align: auto) { // edge
		padding: 0;
	}
}

@mixin highlight-old($color: $color-blue-light) {
	display: inline;
	margin: 0;
	padding: 0 !important; // sass-lint:disable-line no-important
	line-height: 1.3em;
	letter-spacing: 0.07125em;
	box-decoration-break: clone;
	background-color: transparent !important; // sass-lint:disable-line no-important

	box-shadow: inset 0 1.1275em 0.1px -0.1em $color;

	/* only apply padding for non-IE/hi-res due to spacing issue */
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
		margin: 0 0.6em;
		box-shadow:
			inset 0 1.1275em 0.1px -0.1em $color, // sass-lint:disable-line indentation
			0.75em -0.1725em 0.1px -0.165em $color, // sass-lint:disable-line indentation // add padding at end
			-0.75em -0.1725em 0.1px -0.165em $color; // sass-lint:disable-line indentation // add padding at start
	}
	
	@media screen and (min-width: 0\0) { // ie11
		box-shadow: inset 0 1.1275em 0.1px -0.1em $color;
	}
	@supports (-ms-ime-align: auto) { // edge
		box-shadow: inset 0 1.1275em 0.1px -0.1em $color;
	}
}

@mixin font-size($size) {
	font-size: px-vw($size);
	@media (min-width: map-get($aah-grid-breakpoints, 'sm')) {
		font-size: $size + px; //Fallback in px
		font-size: rem-calc($size);
	}
}

/*
	@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
		font-size: px-vw($size);
	}
	@media (min-width: map-get($aah-grid-breakpoints, 'lg')) {
		font-size: $size + px; //Fallback in px
		font-size: rem-calc($size);
	}
	*/

@mixin text($size, $line, $spacing: 0) {
	font-size: px-vw($size);

	@if $line != unset {
		line-height: px-vw($line);
	}
	@if $spacing != unset {
		letter-spacing: px-vw($spacing);
	}
	@media (min-width: map-get($aah-grid-breakpoints, 'sm')) {
		font-size: $size + px; //Fallback in px
		font-size: rem-calc($size);

		@if $line != unset {
			line-height: $line + px;
		}
		@if $spacing != unset {
			letter-spacing: $spacing + px;
		}
	}
}


@mixin body-text($type: "default") {
	@include text(28, 40, 0);
	font-weight: 300;
	color: $color-black-fake;

	@if $type == "small" {
		@include text(26, 40);
		font-weight: 300;
	}

	@include media-breakpoint-up(lg) {
		@include text(14, 24);

		@if $type == "small" {
			@include text(12, 24);
		}
	}
}

@mixin headline($number) {
	display: block;
	font-weight: 500;
	text-transform: none;

	@if $number == 1 {
		@include text(56, 72, 5);
		margin-bottom: 0.75em;
		text-transform: uppercase;
		font-family: "Oswald", sans-serif;
		font-weight: 500;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(40, 56, 5.25);
		}
		mark {
			@include highlight();
		}
	}
	@else if $number == 2  {
		@include text(50, 63, 4.5);
		margin-bottom: 0.75em;
		text-transform: uppercase;
		font-family: "Oswald", sans-serif;
		font-weight: 500;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(32, 40, 5.25);
		}
		mark {
			@include highlight();
		}
	}
	@else if $number == 3 {
		@include text(36, 48, 4);
		margin-bottom: 0.5em;
		text-transform: uppercase;
		font-family: "Oswald", sans-serif;
		font-weight: 500;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(22, 32, 2);
		}
	}
	@else if $number == 4 {
		@include text(36, 37, 0.5);
		margin-bottom: 0.55em;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(20, 24, 0.5);
		}
	}
	@else if $number == 5 {
		@include text(32, 32, 0.5);
		margin-bottom: 0.5em;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(16, 24, 0.5);
		}
	}
	@else if $number == 6 {
		@include text(30, 32, 0.5);
		margin-bottom: 0.5em;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(14, 24, 0.5);
		}
	}
	@else if $number == 7 {
		@include text(26, 32, 0.55);
		margin-bottom: 0.5em;
		@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
			@include text(12, 24, 0.5);
		}
	}
	@else { }
}


@mixin button($type: "default", $size: "default") {
	@include headline(6);
	display: inline-block;
	white-space: nowrap;
	min-width: px-vw(180);
	margin-bottom: 1rem;
	padding: 5px;
	border-width: 2px;
	background-color: $color-aqua-dark;
	color: $color-white;
	line-height: 1.25em;
	text-transform: lowercase;
	cursor: default;

	@supports not (-ms-high-contrast: none) {
		padding: px-vw(22) px-vw(64) px-vw(25);
	}

	&.btn-sm {
		@include headline(7);
		display: inline-block;
		padding: px-vw(17) px-vw(64) px-vw(19);
		line-height: 1.25em;
		text-transform: lowercase;
	}

	&:hover, &:focus {
		color: $color-white;
		background-color: #031b21;
	}
	.icon-svg {
		fill: $color-white;
	}

	&.btn-outline {
		&:focus {
			background-color: #031b21;
			color: $color-white;
		}

		&.btn-primary {
			&:focus {
				background-color: $color-magenta;
				color: $color-white;
			}
		}
	}

	&.btn-secondary {
		border-color: transparent;
	}

	@if $type == "stroke" {
		background-color: $color-white;
		color: $color-aqua-dark;
		border-color: $color-aqua-dark;
		&:hover {
			background-color: $color-aqua-dark;
			color: $color-white;
		}

		&.btn-primary {
			border-color: $color-magenta;
			color: $color-magenta;

			&:hover {
				background-color: $color-magenta;
				color: $color-white;
			}
		}
	}
	@else if $type == "white" {
		background-color: $color-white;
		color: $color-black;
		&:hover, &:focus {
			background-color: $color-aqua-dark;
			color: $color-white;
		}
	}
	
	@else if $type == "disabled" {
		background-color: #c6c6c6;
		color: $color-black;
		opacity: 1;
		border-color: transparent;

		&:hover, &:focus {
			background-color: #c6c6c6;
		}

		&.btn-outline {
			background-color: $color-white;
			border-color: #c6c6c6;
			color: #949494;
		}
	}

	@media (min-width: map-get($aah-grid-breakpoints, 'sm')) {
		min-width: 180px;
		margin-bottom: 0;
		padding: 22px 64px 25px;
		line-height: 1.25em;

		&.btn-sm {
			padding: 16px 64px 20px;
			line-height: 1.25em;
		}
	}
	@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
		min-width: 100px;
		padding: 13px 32px 14px;
		line-height: 1.25em;
		//@include text(14, 17.5, 0.5);

		&.btn-sm {
			padding: 11px 32px 10px;
			line-height: 1.25em;
		}
	}
}

@mixin featureHeight($height) {

	height: px-vw(#{$height});

	&.feature-pull { 
		margin-bottom: px-vw(-#{$height/2}); 
	}
	
	@include media-breakpoint-up(md) {
		height: px-vw(#{$height}, lg); 

		&.feature-pull { 
			margin-bottom: px-vw(-#{$height/2}, lg); 
		}
	}

	@include media-breakpoint-up(lg) {
		height: #{$height}px; 

		&.feature-pull { 
			margin-bottom: -#{$height/2}px; 
		}
	}
}

@mixin image-fit() {
	position: relative;
	overflow: hidden;
	
	img {
		@include absolute(5);
		width: initial;
		min-width: 100%;
		height: inherit;
		object-fit: cover;
		font-family: 'object-fit: cover; object-position: center;' // object-fit-images polyfill
	}
}

@for $i from 25 through 200 {
	$step: 5;
	@if $i % $step == 0 {
		.#{img-aspect}-#{($i)} {
			@include image-fit();
			width: 100%;
			height: 100%;
			padding-bottom: #{$i + '%'};
		}
	}
}


@mixin background-shape($shape, $color: $color-grey-light) {
	position: relative;
	overflow: hidden;
	@if $shape == "none" {
		&:before,
		&:after {
			display: none;
			content: none;
		}
	}
	@else if $shape == "caret" {
		&:before,
		&:after {
			position: absolute;
			display: none;
			width: 140px;
			height: 8000px;
			content: '';
			background-color: $color;
			transform: rotate(22deg);
			z-index: -1;
		}
	
		&:after {
			transform: rotate(-22deg);
		}
	
		@include media-breakpoint-up(md) {
			&:before,
			&:after {
				display: block;
				top: -3960px;
				right: auto;
				left: 45.6%;
				width: px-vw(140, lg);
			}
		}

		@include media-breakpoint-up(lg) {
			&:before,
			&:after {
				width: 140px;
			}
		}
	}
	@else if $shape == "angle"  {
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			border-left: 180vw solid $color;
			border-bottom: 360px solid transparent;
			content: "";
			z-index: -1;
		}
	}
	@else if $shape == "rectangle"  {
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 50%;
			background-color: $color;
			content: "";
			z-index: -1;
		}
	}
}

@mixin labelAnimation() {
	.label {
		@include font-size(28);
		position: absolute;
		top: px-vw(22);
		left: px-vw(35);
		pointer-events: none;
		transition: all 0.4s;

		&.active {
			top: px-vw(8);
			font-weight: 300;

			~ .form-control {
				transition: all 0.4s;
				padding-top: px-vw(30);
			}
		}
	}

	@include media-breakpoint-up(md) {
		.label {
			@include font-size(14);
			top: 14px;
			left: 19px;

			&.active {
				top: 5px;
				font-size: 12px;
				font-weight: 500;

				~ .form-control {
					padding-top: 20px;
				}
			}
		}
	}
}

@mixin animated-circle($outlineColor: $color-aqua, $borderW: 10) {
	.clipped-container {
		width: 50%;
		height: 100%;
		right: 0%;
		position: absolute;
		overflow: hidden;
		transform-origin: left center;
	}

	.halfcircle {
		box-sizing: border-box;
		height: 100%;
		right: 0%;
		position: absolute;
		border: solid px-vw($borderW) transparent;
		border-radius: 50%;

		&.clipped {
			width: 200%;
			transform: rotate(-45deg);
		}

		&.fixed {
			width: 100%;
			transform: rotate(135deg);
			opacity: 0;
		}
	}

	@keyframes clippingrotate {
		0% {
			transform: rotate(0deg);
		}

		49.5% {
			transform: rotate(0deg);
		}

		50% {
			transform: rotate(180deg);
		}

		100% {
			transform: rotate(180deg);
		}
	}

	@keyframes rotation {
		0% {
			transform: rotate(-45deg);
		}

		100% {
			transform: rotate(135deg);
		}
	}

	@keyframes showfixed {
		0% {
			opacity: 0;
		}

		49.5% {
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 1;
		}
	}

	&.animated {
		.clipped-container {
			animation: clippingrotate 1s 1;
			transform: rotate(180deg);
		}

		.halfcircle {
			border-top-color: $outlineColor;
			border-left-color: $outlineColor;

			&.clipped {
				transform: rotate(135deg);
				animation: rotation 0.5s linear 2;
			}

			&.fixed {
				opacity: 1;
				animation: showfixed 1s 1;
			}
		}
	}
}
