.dedicated-gallery {

	$itemAspect: 54; // aspect percentage (h/w)

	.gallery-set {

		margin: 0 0 2em 0;

		.gallery-item {
			@include make-col-ready();
			@include make-col(6);
			margin-bottom: map_get($grid-gutter-widths, 'xs');
			align-self: self-start;
			cursor: pointer;

			picture {
				@include image-fit();
				display: block;
				width: 100%;
				height: 100%;
				padding-bottom: #{$itemAspect + '%'};

				img.lazyloading {
					opacity: 1;
				}
			}

			.btn-play {
				.icon-svg {
					width: px-vw(94);
					height: px-vw(94);
				}
			}
		}

		&[data-featured="true"] {
			&[data-filtered="false"] {
				.gallery-item {
					&:first-child {
						@include make-col(12);

						.btn-play {
							.icon-svg {
								width: px-vw(104);
								height: px-vw(104);
							}
						}
					}
				}
			}
		}

		&.gallery-set-stacked {
			.row {
				justify-content: center;
			}

			.gallery-item {
				@include make-col(12);

				.btn-play {
					.icon-svg {
						width: px-vw(104);
						height: px-vw(104);
					}
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {

		.gallery-set {

			margin: 0 0 2em 0;

			.gallery-item {
				.btn-play {
					.icon-svg {
						width: 94px;
						height: 94px;
					}
				}
			}

			&[data-featured="true"] {
				&[data-filtered="false"] {
					.gallery-item {
						&:first-child {
							.btn-play {
								.icon-svg {
									width: 104px;
									height: 104px;
								}
							}
						}
					}
				}
			}

			&.gallery-set-stacked {
				.gallery-item {
					.btn-play {
						.icon-svg {
							width: 104px;
							height: 104px;
						}
					}
				}
			}

		}

	}

	@include media-breakpoint-up(md) {

		.gallery-set {

			.gallery-item {
				@include make-col(4);
				margin-bottom: map_get($grid-gutter-widths, 'md');

				.btn-play {
					.icon-svg {
						width: px-vw(57, lg);
						height: px-vw(57, lg);
					}
				}
			}

			&[data-featured="true"] {
				&[data-filtered="false"] {
					.gallery-item {
						&:first-child {

							@include make-col(8);
							picture {
								// adjustment needed due to vertical spacing between items
								$adjustment: (1 - ($itemAspect/100)) * map_get($grid-gutter-widths, 'md');
								padding-bottom: calc(#{$itemAspect + '%'} + #{$adjustment});
							}
							.btn-play {
								.icon-svg {
									width: px-vw(87, lg);
									height: px-vw(87, lg);
								}
							}
						}

						&:nth-child(3) {
							position: absolute;
							right: 0;
							margin-top: map_get($grid-gutter-widths, 'md');
							transform: translateY(100%);
						}
					}
				}
			}

			&.gallery-set-stacked {
				.gallery-item {
					@include make-col(8);

					.btn-play {
						.icon-svg {
							width: px-vw(87, lg);
							height: px-vw(87, lg);
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {

		.gallery-set {

			.gallery-item {
				margin-bottom: map_get($grid-gutter-widths, 'lg');

				.btn-play {
					.icon-svg {
						width: 57px;
						height: 57px;
					}
				}
			}

			&[data-featured="true"] {
				&[data-filtered="false"] {
					.gallery-item {

						&:first-child {
							picture {
								$adjustment: (1 - ($itemAspect/100)) * map_get($grid-gutter-widths, 'lg');
								padding-bottom: calc(#{$itemAspect + '%'} + #{$adjustment});
							}
							.btn-play {
								.icon-svg {
									width: 87px;
									height: 87px;
								}
							}
						}

						&:nth-child(3) {
							margin-top: map_get($grid-gutter-widths, 'lg');
						}
					}
				}
			}

			&.gallery-set-stacked {
				.gallery-item {
					.btn-play {
						.icon-svg {
							width: 87px;
							height: 87px;
						}
					}
				}
			}
		}
	}
}
