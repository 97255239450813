#tab-map-view {
	.fee-disclaimer {
		text-align: center;
		font-weight: 300;
		margin-bottom: 15px;
	}
}

.map-view {

	.map {
		width: 100%;
		height: 400px;
	}

	.infobox-0 {
		display: none;
		background-color: $color-white;
		padding: 1.5em 2em;
		white-space: nowrap;

		.headline-7 {
			line-height: 1.25em;
		}
	}

	.infobox-1 {
		display: none;
		width: 300px;
		background-color: $color-white;
		padding: 0;
		text-align: left;
		z-index: $aah-zindex-modal;

		.close {
			top: 10px;
			right: 10px;
			width: 24px;
			height: 24px;
			background-color: $color-white;
			border-radius: 50%;
			opacity: 1;

			.icon-svg {
				width: 12px;
				height: 12px;
			}

			svg {
				fill: $color-black-fake;
			}
		}

		.row { 
			height: 100%;
			margin: 0;

			.col {
				padding: 0;
			}
		}

		.content {
			padding: 22px 20px 30px 20px;
			text-align: center;

			.headline {
				@include headline(4);
				display: inline-block;
				margin: 0;
				line-height: 1.25em;
			}

			p { margin: 5px 0 0 0; }
		}

		picture {
/*			@include image-fit();*/
			display: block;
			width: 100%;
			height: 100%;
/*			padding-bottom: 65%;*/

/*			@media screen and (min-width: 0\0) { // ie11
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
					font-family: inherit;
				}
			}
			@supports (-ms-ime-align: auto) { // edge
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
					font-family: inherit;
				}
			}*/

			img {
				width: 100%;
			}
		}
	}

	.infobox-2 {
		display: none;
		width: 300px;
		background-color: $color-white;
		padding: 0;
		text-align: left;
		z-index: $aah-zindex-modal;

		.row { 
			height: 100%; 
			margin-right: 0;
		}

		.close {
			width: 40px;
			height: 40px;
			opacity: 1;

			.icon-svg {
				width: 12px;
				height: 12px;
			}

			svg {
				fill: $color-black-fake;
			}
		}

		.content {

			padding: 20px 20px 20px 0;
			text-align: left;

			a { text-decoration: underline; }

			.headline {
				margin: 0 0 0.5em 0;
				line-height: 1.25em;
			}

			p { margin: 0; }
		}

		picture {
/*			@include image-fit();*/
			display: inline-block;
			width: 100%;
			height: 100%;
/*			padding-bottom: 100%;*/

/*			@media screen and (min-width: 0\0) { // ie11
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}
			@supports (-ms-ime-align: auto) { // edge
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}*/

			img {
				width: 100%;
			}
		}
	}

	.infobox-3 {
		display: none;
		width: 420px;
		background-color: transparent;
		padding: 0;
		text-align: left;
		z-index: $aah-zindex-modal;

		.content-box {
			padding: 24px;
			background-color: $color-white;
			width: 300px;
			margin-left: 60px;
		}

		.row { 
			height: 100%; 
			margin-right: 0;
		}

		.close {
			width: 40px;
			height: 40px;
			opacity: 1;
			margin: 0 60px 0 0;

			.icon-svg {
				width: 12px;
				height: 12px;
			}

			svg {
				fill: $color-black-fake;
			}
		}

		.content {
			text-align: left;

			.headline {
				margin: 0 0 0.5em 0;
				line-height: 1em;
				letter-spacing: 2px;

				a { text-decoration: none; }
			}

			.headline-5 {
				margin: 10px 0 0;
			}
		}

		.pinButton {
			background-color: $color-white;
			position: absolute;
			display: block;
			border-radius: 100%;
			line-height: 0;
			font-size: 0;
			cursor: pointer;
			padding: 0;
			border: 1px solid #c8c8c8;
			top: 2.5vw;
			svg {
				fill: black;
			}

			&:hover, &:focus {
				svg {
					fill: $color-aqua-dark;
				}
			}
		}

		.prevPin {
			left: -0.1vw;
			margin-left: 2px;
		}

		.nextPin {

		}

		picture {
/*			@include image-fit();*/
			display: inline-block;
			width: 100%;
			height: 100%;
/*			padding-bottom: 100%;*/

/*			@media screen and (min-width: 0\0) { // ie11
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}
			@supports (-ms-ime-align: auto) { // edge
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}*/

			img {
				width: 100%;
			}
		}
	}

	.infobox-overlay {
		position: fixed;
		display: none;
		right: 0;
		bottom: 0;
		left: 0;
		margin: 0;
		background-color: $color-white;
		z-index: $aah-zindex-modal;

		picture {
			display: block;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
			}
		}

		.status {
			position: relative;
			width: 100%;
			margin: 0;
			padding: 0.5em 3em;
			text-align: center;
			background-color: $color-grey-light;
		}

		.content {
			position: relative;
			height: 100%;
			padding: 1em 0 px-vw(84) 0;

			.headline {
				margin: 0;
			}

			.trip-advisor {
				position: absolute;
				bottom: 0.5em;
			}
		}

		.close {
			position: absolute;
			top: 0;
			left: 0;
			right: auto;
			opacity: 1;
			width: px-vw(40);
			height: px-vw(40);
			margin: 0 0.5em;
			background-color: $color-white;
			border-radius: 50%;
			border: 1px solid $color-grey;
			transform: translateY(-50%);

			.icon-svg {
				width: px-vw(16);
				height: px-vw(16);
				svg {
					fill: $color-black-fake;
				}
			}
		}

		footer {
			position: relative;
			background-color: $color-grey-light;

			.price {
				@include headline(4);
				display: inline-flex;
				height: 100%;
				margin: 0 0.5em 0 0;
				align-items: center;
			}

			.btn {
				margin: 0 auto;
			}
			.avail-closed-msg {
				padding: 5px 10px;
				font-size: 14px;
				background-color: #c6c6c6;
				text-align: center;
				margin-top: 2.5rem;
			}

			.avail-msg {
				font-weight: 500;
				padding: 5px 10px;
				font-size: 14px;
				background-color: #c6c6c6;
				text-align: center;
				margin-top: 2.5rem;
			}

			.avail-closed-msg {
				padding: 5px 10px;
				font-size: 14px;
				background-color: #c6c6c6;
				text-align: center;
				margin-top: 2.5rem;
			}
		}
	}

	.map {
		.infobox-0, .infobox-1, .infobox-2, .infobox-3, .infobox-overlay { display: inline-block; }
	}

	@include media-breakpoint-up(sm) {

	}

	@include media-breakpoint-down(sm) {

		.infobox-overlay {
			border: 0;
		}
	}

	@include media-breakpoint-up(md) {

		.infobox-overlay {
			position: absolute;
			min-height: 200px;
			margin: 45px 60px;

			.status {
				position: absolute;
				width: 100%;
				padding: 0.5em 1em;
				text-align: left;
				background-color: $color-grey-light;
				transform: translateY(-100%)
			}

			.badge-descriptive {
				display: table;
			}

			.content {
				padding: 0;
			}

			.close {
				position: absolute;
				top: 0;
				right: 0;
				left: auto;
				opacity: 1;
				width: 40px;
				height: 40px;
				margin: 0;
				background-color: transparent;
				border: 0;
				border-radius: 0;
				transform: none;

				.icon-svg {
					width: 12px;
					height: 12px;
					svg {
						fill: $color-black;
					}
				}
			}

			footer {
				position: relative;
				border-left: 1px solid $color-grey-border;
				padding-bottom: 120px;
				background-color: transparent;

				.price {
					@include headline(4);
					display: inline-block;
					margin: 0 0 1em 0;
					line-height: px-vw(68);
				}

				.trip-advisor {
					position: absolute;
					bottom: 54px;
					.headline-7 {
						line-height: 1.25em;
					}
				}

				.btn {
					position: absolute;
					top: auto;
					right: 0;
					bottom: 0;
					left: 0;
					width: calc(100% - #{map_get($grid-gutter-widths, 'md')});
					margin: 0 auto;
				}
			}

		}

	}

	@include media-breakpoint-up(lg) {
		.infobox-overlay {
			footer {
				padding-bottom: 120px;
			}
		}
	}
}

.infoBox {
	overflow: hidden !important;
	max-width: 80vw;

	.content-box {
		max-width: calc(80vw - 120px);
	}
}

.maplessFooter {
	text-align: center;
}
