$env: 'development' !default;
$aah-asset-path: '..' !default;

@if($env == 'production') {
	$aah-asset-path: '/modules/ilg-aah-jahia-assets';
}

$aah-grid-breakpoints: (
	xs: 0,
	sm: 640px,
	md: 768px,
	lg: 1024px,
	xl: 1500px
);

$desktop-sticky-height: 72;
$desktop-padding: 132;
$mobile-sticky-height: 91;
$mobile-padding: 96;

$aah-zindex-button-overlay:				50;
$aah-zindex-container-inset:				100;
$aah-zindex-collapse-container:			200;
$aah-zindex-container-inset-feature:	300;
$aah-zindex-search:							900;
$aah-zindex-parametric:						900;
$aah-zindex-loader:							1050;
//bootstrap z-index defaults
$aah-zindex-dropdown-backdrop:  			990;
$aah-zindex-navbar:             			1000;
$aah-zindex-dropdown:           			1000;
$aah-zindex-fixed:              			1030;
$aah-zindex-sticky:             			1030;
$aah-zindex-modal-backdrop:     			1040;
$aah-zindex-modal:              			1050;
$aah-zindex-popover:            			1060;
$aah-zindex-tooltip:            			1070;
