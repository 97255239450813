.search-results {

	form {
		position: relative;
	}

	label {
		@include body-text();
		display: block;
		margin: 0;
	}

	input[type="search"] {
		@include headline(2);
		position: relative;
		padding: 0 50px 0 0;
		text-transform: uppercase;
		border: 0;
		border-bottom: 1px solid $color-black-fake;

		&::placeholder {
			color: $color-black-fake;
		}
	}

	.btn-search {
		@include absolute(6);
		right: 4px;
		vertical-align: middle;
		pointer-events: none;

		.icon-svg {
			width: px-vw(38);
			height: px-vw(38);
		}
	}

	.search-results-item {
		padding: 20px 0 15px;
		border-bottom: 1px solid $color-grey-border;

		&:first-of-type {
			border-top: 1px solid $color-grey-border;
		}

		figure {
			margin-bottom: 0;
		}

		p {
			font-size: .875rem;
			line-height: 24px;
			letter-spacing: 0;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.blockquote {
			margin: px-vw(10) px-vw(28) px-vw(15);
		}

	}

	.no-results {
		border: 1px solid $color-grey-border;
		padding: 100px 50px;
		text-align: center;
	}


	@include media-breakpoint-up(md) {

		.btn-search {
			width: 36px;
			height: 36px;

			.icon-svg {
				width: 24px;
				height: 24px;
			}
		}

		.search-results-item {
			.blockquote {
				margin: 10px 0 15px;
			}
		}
	}
}

