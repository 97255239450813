.parametric-search {
	position: relative;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	margin-bottom: 3em;

	[data-view="applied"] {
		display: none;
	}

	.loader {
		svg {
			z-index: $aah-zindex-loader;
		}
	}
	.loader-bg {
		z-index: $aah-zindex-loader - 1;
	}

	form {
		position: relative;
		width: 100%;
	}

	.form-group-number {
		display: inline-block;
		width: 50%;
		float: left;

		label {
			@include headline(5);
			font-weight: 200;
		}
	}

	.filters {
		@include absolute();
		display: none;
		background-color: $color-white;
		z-index: $aah-zindex-dropdown;

		> .row {
			> .col {
				flex-basis: 100%;
			}
		}

		.btn-transparent {
			@include body-text("small");
			margin: 0;
			line-height: initial;
			padding-right: 1em;
			padding-left: 1em;
			text-decoration: underline;
			color: #616161;

			.icon-svg {
				fill: $color-black-fake;
			}
		}

		.card {

			&.level-1 {
				.card-header {
					button[data-toggle="collapse"] {
						text-align: left;
						font-weight: inherit;
						font-family: inherit;
						color: inherit;
						padding: 0;
					}
				}
			}

			.card-block {
				.custom-control {
					display: block;
					width: 100%;
					margin: 0;
					padding: 0;

					.logo {
						height: 2em;
					}

					> .icon-svg {
						width: 2em;
						height: 2em;
						margin: 0 2em 0 0;
						vertical-align: middle;
					}

					.custom-control-indicator {
						@include absolute(6);
						left: auto;
					}

					.custom-control-description {
						display: inline-block;
						width: 65%;
						line-height: 1.25em;
						margin-top: 0.25em;
						vertical-align: middle;

						small {
							line-height: inherit;
						}

					}
				}

				.fieldset-label {
					padding-top: .75rem;
					padding-bottom: .75rem;
					border-bottom: 1px solid rgba(0, 0, 0, .125);

					&:last-of-type {
						border-bottom: none;
					}
				}

				.list-group {
					margin: 0 1.5em 1em 1.5em;

					.list-group-item {
						padding-left: 0;
						padding-right: 0;
						border-left: 0;
						border-right: 0;
						page-break-inside: avoid;

						&:first-child {
							border-top: 0;
						}
						&:last-child {
							//border-bottom: 0;
						}
					}
				}
			}
			.card-footer {
				border: 0;
				background-color: transparent;
			}
		}
	}

	.card {

		.card-header {
			position: relative;
			padding-top: 1.5em;
			padding-bottom: 1.5em;
			background-color: $color-white;
			border: 0;

			> button {
				width: 100%;
				text-align: left;
				padding-left: 0;

				.rating {
					.rating-dots, .rating-circles {
						.icon-svg {
							margin-right: 0;
							svg {
								fill: $color-aqua-dark;
							}
						}
					}
				}

				.headline {
					.applied-value {
						font-weight: 200;
						padding-left: 0.5em;
						color: $color-black-fake;
					}
				}

				.icon-svg {
					transform: rotate(0deg);
					transition: transform 0.3s;
					width: px-vw(24);
					height: px-vw(24);
					margin-right: 1.5em;
				}

				&.collapsed {
					.icon-svg {
						transform: rotate(180deg);
					}
				}
			}

			.badge {
				width: px-vw(45);
				height: px-vw(45);
				margin-right: 1.1em;
				border-radius: 50%;
				line-height: 1.35em;
				vertical-align: middle;
				font-size: px-vw(24);
			}
		}

		&[aria-disabled="true"] {
			.card-header {
				opacity: 0.5;
			}
		}

	}

	.btn-transparent {
		min-width: 0;
		margin: 0;
		padding-right: 0.25em;
		padding-left: 0.25em;
		color: #616161;

		.icon-svg {
			svg {
				fill: lighten($color-grey, 20%);
			}
		}

		&.active {

			color: $color-black-fake;

			.icon-svg {
				svg {
					fill: $color-black-fake;
				}
			}
		}
	}

	ul.list-sort-options {
		margin: 0.5em 0 0 0;
		padding: 0 1.5em;
		background-color: $color-white;

		li {
			padding: 0.75em;
			border-left: 0;
			border-right: 0;

			.custom-control {
				display: block;
				width: 100%;
				margin: 0;
				padding: 0;

				> .icon-svg {
					width: 2em;
					height: 2em;
					margin: 0 2em 0 0;
					vertical-align: middle;
				}

				.custom-control-indicator {
					@include absolute(6);
					left: auto;
				}

				.custom-control-description {
					display: inline-block;
					width: 65%;
					line-height: 1.25em;
					margin-top: 0.25em;
					vertical-align: middle;

					small {
						line-height: inherit;
					}

				}
			}

			&:first-child {
				border-top: 0;
				padding-top: 0.5em;
			}
			&:last-child {
				border-bottom: 0;
				padding-bottom: 2em;
			}
		}

	}

	.noUi-horizontal {
		height: 3px;
		box-shadow: none;
		border: none;
	}

	.noUi-target {
		background: $color-grey-border;
	}

	.noUi-connect {
		background-color: $color-aqua-dark;
	}

	.noUi-handle {
		border: 3px solid $color-aqua-dark;
		background: $color-white;
		border-radius: 50%;
		box-shadow: none;
		width: px-vw(48);
		height: px-vw(48);
		top: px-vw(-20);

		&::before, &::after {
			content: none;
		}
	}

	.results {
		position: relative;

		.results-header {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			margin: 0.75em;
			z-index: $aah-zindex-button-overlay;

			.collapse {
				footer {
					.btn-transparent {
						@include body-text("small");
						margin: 0;
						line-height: initial;
						padding-right: 1em;
						padding-left: 1em;
						text-decoration: underline;
						color: $color-black-fake;
					}
				}
			}
		}

		.results-header-count {
			position: relative;
			width: 100%;
			margin: 0;
			padding: 1em;
			text-align: center;
			background-color: $color-white;
		}

		.grid-view {
			padding: 1em;

			label {
				@include body-text();
				margin-bottom: 1rem;
				font-weight: 500;
			}


			.trip-advisor {
				margin-bottom: 1em;
				.logo {
					max-height: 3em;
				}
			}

			.category, .phone {
				@include body-text();
				margin-bottom: 1rem;
			}

			.property-grid-item {
				@include make-col-ready();
				@include make-col(12);

				margin-bottom: px-vw(60);

				.list-badges {
					margin-top: 0.75em;

					.badge-descriptive {
						display: table;
					}
				}

				.headline-7 {
					line-height: 1.25em;
				}

				footer {
					position: relative;
					bottom: 0;
					width: 100%;
					height: auto;

					text-align: right;
					background-color: $color-grey-light;
					border-bottom: 2px solid darken($color-grey-light, 10%);

					.btn {
						margin: 0;
						padding-left: 0;
						padding-right: 0;
					}

					.price {
						@include headline(4);
						display: inline-flex;
						height: 100%;
						align-items: center;
						margin: 0 1em;
					}

					.avail-msg {
						font-weight: 500;
						padding: 5px 10px;
						font-size: 14px;
						background-color: #c6c6c6;
						text-align: center;
					}
					.avail-closed-msg {
						padding: 5px 10px;
						font-size: 14px;
						background-color: #c6c6c6;
						text-align: center;
					}
				}

				~ .row {
					display: block;
				}
			}
		}

		.map-view {
			display: block;
			position: relative;
			width: 100%;
			padding-bottom: 150%;

			.map {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			.legend {
				position: absolute;
				top: px-vw(100);
				right: 0;
				left: 0;
				height: auto;
				margin: 0.75em;
				padding: 0;
				background-color: $color-white;
				text-align: center;
				pointer-events: none;

				ul.list-inline {
					display: inline-block;
					margin: 0 auto;

					li {
						@include headline(7);
						display: inline-block;
						margin: 1em 0.25em 1em 0;
						white-space: nowrap;

						.icon-svg {
							display: inline-block !important;
							margin: 0 0.25em 0 0;
							width: px-vw(28);
							height: px-vw(28);
						}
					}
				}
			}

		}

		&[data-view="grid"] {
			padding-top: px-vw(120);
			.results-header-count {
				width: 90%;
				margin: 0 auto;
				border-top: 1px solid $color-grey-border;
			}
			.map-view {
				display: none;
			}
			.grid-view {
				display: block;
				.property-grid-item {
					ul.list-badges, .category, .phone {
						display: block;
					}
				}
			}
		}
		&[data-view="map"] {
			background-color: $color-grey-light;

			.results-header-count {
				position: absolute;
				bottom: 0;
				z-index: $aah-zindex-button-overlay;
				transform: translateY(100%);
			}
			.map-view {
				display: block;
			}
			.grid-view {
				display: none;
			}
		}

	}

	@include media-breakpoint-up(sm) {

		.card {
			.card-header {
				> button {
					.icon-svg {
						width: 24px;
						height: 24px;
					}
				}

				.badge {
					width: 45px;
					height: 45px;
					font-size: 24px;
				}
			}
		}

		.results {

			.map-view {
				.legend {
					top: 100px;
				}
			}

			&[data-view="grid"] {
				padding-top: 120px;
			}
		}

		.noUi-horizontal .noUi-handle {
			width: 48px;
			height: 48px;
			top: -20px;
		}
	}

	@include media-breakpoint-down(sm) {

		.collapse[class^="absolute-"], .collapsing[class^="absolute-"] {
			position: static;
		}

		.results {

			.results-header {

				.nav {
					padding: 0.75em 0;

					.nav-item {
						.btn-transparent {
							padding: 0 0.25em;
							.icon-svg {
								width: px-vw(28);
								height: px-vw(28);
							}
						}
					}

					&.nav-left {
						.nav-item {
							border-right: 1px solid $color-grey-border;
							&:last-child {
								border: 0;
							}
						}
					}
					&.nav-right {
						position: relative;
						.nav-item {
							&:first-child {
								display: none;
							}
							&:last-child {
								display: list-item;
							}
						}
						&:after {
							top: 100%;
							left: 50%;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-color: rgba(255, 255, 255, 0);
							border-top-color: #ffffff;
							border-width: px-vw(12);
							margin-left: px-vw(-12);
						}
					}

				}

			}

			.grid-view {
				.property-grid-item {
					.content {
						.headline {
							margin-right: 40px;
						}
						.btn-overlay {
							margin: 0px;
							width: 6.25vw;
							height: 6.25vw;
							.icon-svg {
								svg {
									fill: $color-black-fake;
								}
							}
						}
					}

					footer {
						.btn {
							padding: px-vw(22) px-vw(44) px-vw(25);
						}
					}
				}
			}

			&[data-view="grid"] {
				.results-header {
					.nav {
						&.nav-right {
							.nav-item {
								border-left: 1px solid $color-grey-border;
								&:first-child {
									display: list-item;
								}
								&:last-child {
									display: none;
								}
							}

						}
					}
				}
			}
		}

	}

	@include media-breakpoint-up(md) {

		.form-group-number {
			display: inline-block;
			width: auto;
			float: none;
			border-right: 0;
		}

		.filters {
			position: relative;
			display: block;
			width: 100%;
			z-index: $aah-zindex-parametric;

			> .row {
				> .col {
					flex-basis: auto;
					ms-flex-preferred-size: auto;
					width: auto;
				}
			}

			.card {
				border-left: 0;

				.collapsing, .collapse {
					position: absolute;
					top: 100%;
					min-width: 350px;
					background-color: $color-white;
					border: 1px solid $color-grey-border;
					z-index: $aah-zindex-parametric;

					&.multi-col-2 {
						min-width: 450%;
					}
					&.multi-col-3 {
/*						min-width: 600%*/
						width: 300%;
						max-width: 950px;
					}
				}

				.collapse {
					height: auto;
					overflow-y: scroll;
				}

				.card-header {
					white-space: nowrap;

					> button {

						.headline {
							.applied-value {
								padding-left: 0;
								font-weight: 500;
								color: inherit;
							}
						}

						.icon-svg {
							right: 12px;
							width: 12px;
							height: 12px;
							margin-right: 0.5em;
						}


					}

					.badge {
						width: 20px;
						height: 20px;
						font-size: 12px;
					}

					.rating {
						margin: 0;

						.icon-svg {
							right: auto;
						}
					}

					.headline {
						width: calc(100% - 30px);
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.card-block {
					padding: 2em 2em 1em 2em;

					[class^="col"] {
						padding: 0 1em;
					}

					.list-group {
						margin: 0 0 1em 0;

						.list-group-item {
							padding-left: 0;
							padding-right: 0;
							border-left: 0;
							border-right: 0;

							&:first-child {
								border-top: 0;
							}
							&:last-child {
								border-bottom: 0;
							}
						}
					}

					.custom-control {
						> .icon-svg {
							margin: 5px 15px 0 0;
						}

					}
				}

				.card-footer {
					padding: 0.5em 0 2.5em 0;
					text-align: center;
				}

				&.level-1 {
					border-bottom: 0;

					.collapsing, .collapse {
						min-width: 150%;
					}

					.card-header {
						padding: 2rem 1.25rem;
						background-color: $color-grey-light;

						.icon-svg {
							right: 20px;
							width: 20px;
							height: 20px;
						}

						.headline {
							font-weight: 300;
							strong, .applied-value {
								font-weight: 500;
							}
						}
					}
				}
			}
		}

		.noUi-horizontal .noUi-handle {
			width: 24px;
			height: 24px;
			top: -10px;
		}

		.results {
			position: relative;
			top: 0;
			bottom: 0;
			width: 100%;
			padding-bottom: 34%;
			overflow-y: scroll;

			.grid-view {
				position: absolute;
				display: inline-block;
				top: 0;
				bottom: 0;
				left: 0;
				width: 43.8%;
				padding: 2em;
				overflow-y: scroll;

				.property-grid-item {
					@include make-col-ready();
					@include make-col(12);

					margin-bottom: 22px;
					padding-bottom: 48px;

					> .row {
						&:first-child {
							height: 100%;
							> [class^="col"] {
								// override col to make full-width
								@include make-col(12);
							}
						}
					}

					ul.list-badges, .phone {
						display: none;
					}

					figure {
						picture {
							display: block;
							width: 100%;
							height: 100%;
						}
					}

					.content {
						@include make-col(12);

						.headline {
							display: inline-block;
							margin: 0 0 0.75em 0;
						}

						.btn-overlay {
							position: relative;
							float: right;
							margin-top: -0.75em;

							.icon-svg {
								svg {
									fill: $color-black-fake;
								}
							}
						}
					}

					.trip-advisor {
						position: relative;

						.logo {
							max-height: 27px;
						}
					}


					footer {
						position: absolute;
						top: auto;
						left: 9.5px;
						bottom: 0;
						width: calc(100% - #{map_get($grid-gutter-widths, 'md')});
						height: auto;

						text-align: right;
						background-color: $color-grey-light;
						border-bottom: 2px solid darken($color-grey-light, 10%);

						.btn {
							height: 46px;
						}

						.price {
							line-height: 44px;
						}

						small {
							font-weight: 300;
						}
					}
				}
			}

			.map-view {
				position: absolute;
				display: inline-block;
				float: right;
				right: 0;
				bottom: 0;
				width: 56.2%;
				height: 100%;
				padding-bottom: 0;

				.legend {
					@include absolute(3);
					display: inline-block;
					height: 50px;
					margin: 20px 35px 0 0;
					padding: 12px 20px 12px;
					background-color: $color-white;
					pointer-events: none;

					ul.list-inline {
						li {
							margin: 0 1em 0 0;

							.icon-svg {
								width: 24px;
								height: 24px;
							}
						}
					}
				}
			}

			&[data-view="map"] {
				background-color: $color-white;
				.grid-view {
					display: block;
				}
				.map-view {
					display: block;
				}
			}

			&[data-view="grid"] {

				.grid-view {
					display: block;
					width: 100%;

					.property-grid-item {
						@include make-col(6);
						margin-bottom: 40px;

						> .row {
							&:first-child {
								> [class^="col"] {
									@include make-col-ready();
									@include make-col(6);
								}
							}
						}

						.content {
							padding-bottom: px-vw(80, lg);

							.headline {
								margin: 0;
							}

							.trip-advisor {
								position: absolute;
								bottom: 0;
							}
						}

					}
				}
				.map-view {
					display: none;
				}
			}
		}

		.fee-disclaimer {
			font-weight: 300;
			font-size: 0.875rem;
		}

	}

	@include media-breakpoint-up(lg) {

		.filters {
			.card {
				&.level-1 {
					.collapsing, .collapse {

					}
				}

				.collapsing, .collapse {
					&.multi-col-3 {
						width: 400%;
					}
				}
			}
		}

		.results {
			&[data-view="map"] {
				.grid-view {
					.property-grid-item {
						@include make-col(6);
					}
				}
			}
			&[data-view="grid"] {
				.grid-view {
					.property-grid-item {
						@include make-col(4);

						.content {
							padding-bottom: 80px;
						}
					}
				}
			}
		}

	}

	@include media-breakpoint-up(xl) {

		.filters {
			.card {
				&.level-1 {
					.collapsing, .collapse {
						min-width: 100%;

						&.multi-col-3 {
							width: 400%;
						}
					}
				}
			}
		}

	}
}

.zoom-enabled {
	.parametric-search {
		@include media-breakpoint-down(sm) {
			.filters {
				overflow: hidden;
			}
		}
	}
}
