.collapsible {
	position: relative;
	display: block !important;
	margin-bottom: 0;
	overflow: hidden;

	&[data-ellipsis] {
		@include body-text();
		margin-bottom: 1rem;
	}

	&.collapsible-sm {
		&.collapsing {
			height: px-vw(90);
		}
	}

	&.collapsible-md {
		&.collapsing {
			height: px-vw(150);
		}
	}

	&.collapsible-lg {
		&.collapsing {
			height: px-vw(190);
		}
	}

	&.show {
		height: auto;

		&:after {
			box-shadow: none;
		}
	}

	&:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 0px 54px 25px $color-white;
		content: '';
	}

	&.no-fade {
		&:after {
			display: none;
		}
	}

	~ .collapsible-btn:not(.active) {
		display: none;
	}

	@include media-breakpoint-up(md) {
		&.mobile-collapse {
			height: auto;

			&:after {
				box-shadow: none;
			}

			~ .collapsible-btn {
				display: none;
			}
		}
	}
}

.collapsible-btn {
	display: block;
	margin: 5px auto;
	padding: 16px;
	font-size: px-vw(30);
	font-weight: 500;

	.less {
		display: block;
	}

	.more {
		display: none;
	}

	.icon-svg {
		display: block;
		margin: auto;
		fill: $color-aqua-dark;
		transform: rotate(-180deg);
		transition: transform 0.5s;
	}

	&.collapsed {
		.less {
			display: none;
		}

		.more {
			display: block;
		}

		.icon-svg {
			transform: rotate(0);
		}
	}

	@include media-breakpoint-up(md) {
		font-size: 14px;
	}
}
