.property-grid {

	.fee-disclaimer {
		text-align: center;
		font-weight: 300;
		margin-bottom: -30px;
	}

	.slick-dots {
		li {
			display: inline-block;

			button {
				display: block;
			}
		}
	}

	.property-grid-item {

		position: relative;
		display: block;
		width: 100%;
		margin: 60px 0 0;


		text-align: left;

		> .row {
			height: 100%;
		}

		picture {
			@include image-fit();
			display: inline-block;
			width: 100%;
			height: px-vw(200);
			margin-bottom: 1.5em;
			vertical-align: top;
		}

		.content {
			position: relative;
			display: inline-block;
			width: px-vw(325);
			margin-left: 15px;
			vertical-align: top;

			.headline-5 {
				width: calc(100% - 1.3em);
				margin: 0 0 0.25em 0;
			}

			.category {
				@include body-text();
				display: block;
				margin: 0 0 8px 0;
			}

			.list-unstyled {
				margin: 0 0 0.25em 0;
			}

			.trip-advisor {

				margin-bottom: 10px;

				h2 {
					margin-bottom: 0.5em;
				}

				.logo {
					display: inline-block;
					width: px-vw(50);
				}

				small {
					font-weight: 300;
				}
			}
		}

		.btn-overlay {
			@include absolute(3);
			top: 11px;
			right: 12px;
			width: px-vw(48);
			height: px-vw(48);
			margin: 0.25em;
			padding: 0;
			z-index: 100;

			.icon-svg {
				@include absolute(5);
				width: 36px;
				height: 36px;

				svg {
					fill: $color-black-fake;
				}

				&.heart-filled {
					svg {
						fill: $color-aqua-dark;
					}
				}
			}

			&:hover {
				svg {
					fill: $color-aqua;
				}
			}
		}

		footer {
			position: absolute;
			bottom: 0;
			width: 100%;
			height: px-vw(70);
			background-color: $color-grey-light;
			border-bottom: 2px solid darken($color-grey-light, 10%);


			.price {
				@include headline(4);
				display: inline-block;
				float: right;
				margin: 0 1em;
				line-height: px-vw(70);
			}

			.price-load {
				height: 40px;
				width: 40px;

				.loading {
					font-size: 4rem;
					line-height: 0px;
					letter-spacing: -7px;
				}

				.loading span {
					animation-name: blink;
					animation-duration: 0.9s;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
				}

				.loading span:nth-child(2) {
					animation-delay: .1s;
				}

				.loading span:nth-child(3) {
					animation-delay: .2s;
				}

				@keyframes blink {
					0% {
						opacity: .2;
					}

					80% {
						opacity: 1;
					}

					100% {
						opacity: .2;
					}
				}
			}

			small {
				font-weight: 300;
			}

			.avail-msg {
				font-size: .75rem;
				letter-spacing: .5px;
				font-weight: 500;
				text-align: center;
				padding: 10px;
				background-color: #c6c6c6;
			}

			.avail-closed-msg {
				font-size: .75rem;
				letter-spacing: .5px;
				text-align: center;
				padding: 10px;
				background-color: #c6c6c6;
			}
		}

		.property-box {
			display: flex;
			position: relative;
			height: 100%;
			margin: 0 20px;
			padding: 25px 25px 33px;
			border: solid 1px $color-grey-light-3;
			flex-wrap: nowrap;

			.btn.d-block {
				margin-top: -1px;

				&[disabled],
				&.disabled {
					background-color: #c6c6c6;
					color: #525252;
				}
			}

			picture {
				margin-bottom: 0;
			}

			.rating-count a {
				font-size: 12px;
			}

			.content {
				width: 50%;

				.trip-advisor {
					margin-top: 8px;
					align-self: flex-start;
					margin-left: 0;
				}
			}

			.category {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				flex-wrap: wrap;
				gap: 5px;

				@include media-breakpoint-up(md) {
					justify-content: left;
				}
			}

			.price-area {
				display: block;
				background: white;
				padding: 0 14px;

				.booking-text {
					display: inline-block;
					width: 33.0625vw;
					font-size: 4.0625vw;
					line-height: 5.0625vw;
					margin-top: 0.0625vw;
					font-weight: bold;
					text-align: center;
				}

				.original-price {
					text-decoration: line-through;
					color: $color-grey-strikethrough;
					font-size: 36px;
					line-height: 40px;
					vertical-align: middle;
					margin-right: 7px;
				}

				.offer-price {
					margin-right: 5px;
					font-size: 10.0625vw;
					line-height: 40px;
					vertical-align: middle;
					text-decoration: none;
				}

				.price-text {
					display: inline-block;
					width: 11.0625vw;
					margin-left: 0.0625vw;
					font-size: 4.0625vw;
					line-height: 4.0625vw;
					letter-spacing: .5px;
					word-break: break-word;
					text-align: left;
					vertical-align: middle;
				}
			}

			footer {
				width: auto;
				bottom: -5vw;
				right: 10px;
				border-bottom: 0;
				background: none;
			}

			@include media-breakpoint-up(md) {
				margin: 0;
				padding: 15px 15px 40px;

				.price-area {
					.booking-text {
						margin-top: 0;
						width: 144px;
						font-size: 14px;
						line-height: 16px;
					}

					.original-price {
						font-size: 22px;
						line-height: 24px;
					}

					.offer-price {
						font-size: 30px;
						line-height: 24px;
					}

					.price-text {
						width: 30px;
						font-size: 12px;
						line-height: 14px;
					}
				}

				footer {
					bottom: -28px;
				}
			}

			.slick-dots {
				li {
					display: none;

					button {
						display: none;
					}
				}
			}
		}
	}

	.slick-track,
	.slick-list {
		display: flex;
		flex: 1 0 auto;

		.carousel-slide > .row {
			height: 100%;
		}
	}

	.carousel-slide {
		padding: 0;

		.property-grid-item {
			margin-bottom: 30px;

			.rating-count {
				display: block;
			}
		}

		@include media-breakpoint-up(sm) {
			.property-grid-item {
				margin-bottom: 0;
			}

		}

		@include media-breakpoint-up(lg) {
			.travelLogRow {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}

	@include media-breakpoint-up(sm) {

		.property-grid-item {

			margin: 45px 20px 0 20px;

			picture {
				width: 100%;
				height: 200px;
			}

			.content {
				width: 325px;
				margin-left: px-vw(15);

				.trip-advisor {
					.logo {
						width: 50px;
					}
				}
			}

			.btn-overlay {
				width: 48px;
				height: 48px;
				margin: 0;
				padding: 0;

				.icon-svg {
					width: 28px;
					height: 28px;
				}
			}

			footer {
				height: 70px;

				.price {
					line-height: 70px;
				}
			}
		}

	}

	@include media-breakpoint-up(md) {

		.property-grid-item {

			display: inline-block;
			width: px-vw(470, 'lg');
			margin: px-vw(45, 'lg') px-vw(12, 'lg') 0;

			picture {
				width: px-vw(225, 'lg');
				height: px-vw(180, 'lg');
			}

			.content {
				position: relative;
				height: 100%;
				width: px-vw(220, 'lg');
				margin-left: px-vw(15, 'lg');
				vertical-align: top;

				display: inline-flex;
				flex-direction: column;

				.headline-5 {
					margin: 0;
				}

				.trip-advisor {

					margin-top: auto;
					margin-left: 0;
					align-self: flex-end;

					h2 {
						margin: 0;
					}

					.logo {
						width:  px-vw(35, 'lg');
						margin: 0;
					}
				}
			}

			.btn-overlay {
				top: 2px;
				right: 4px;
				margin: 0;
				padding: 0;

				.icon-svg {
					width: 36px;
					height: 36px;
				}
			}

			footer {
				height: 40px;

				.price {
					margin: 0 px-vw(15, 'lg');
					line-height: 40px;
				}
			}

			//&:nth-child(odd) {
			//	margin-right: auto;
			//}
		}

		.carousel-slide {
			//padding: 0 px-vw(70, 'lg')  px-vw(20, 'lg') px-vw(70, 'lg');
			padding: 0 0  px-vw(20, 'lg') 0;

			// property-grid-item needs to be more narrow when inside carousel to accommodate for prev/next buttons
			.property-grid-item {
				width: px-vw(400, 'lg');

				picture {
					width: px-vw(190, 'lg');
					height: px-vw(150, 'lg');
				}

				.content {
					width: px-vw(180, 'lg')
				}
			}
		}

	}

	@include media-breakpoint-up(lg) {

		.property-grid-item {

			display: inline-block;
			width: 470px;
			margin: 45px 5px 0;

			picture {
				width: 225px;
				height: 180px;
			}

			.content {
				height: 100%;
				width: 220px;
				margin-left: 15px;

				.headline-5 {
					margin: 0;
				}

				.trip-advisor {
					.logo {
						width:  35px;
					}
				}
			}

			.btn-overlay {
				margin: 0;
				padding: 0;

				.icon-svg {
					width: 28px;
					height: 28px;
				}
			}

			footer {
				height: 40px;

				.price {
					margin: 0 15px;
					line-height: 40px;
				}
			}

			//&:nth-child(odd) {
			//	margin-right: auto;
			//}
		}

		.carousel-slide {
			//padding: 0 70px 20px 70px;
			padding: 0 0 20px 0;

			// property-grid-item needs to be more narrow when inside carousel to accommodate for prev/next buttons
			.property-grid-item {
				width: 400px;

				picture {
					width: 190px;
					height: 150px;
				}

				.content {
					width: 180px;
				}
			}
		}

	}
}

.zoom-enabled {
	.property-grid {
		.property-grid-item {
			@include media-breakpoint-down(md) {
				.property-box {
					//padding-bottom: 70px;

					.content {
						position: static;

						.trip-advisor {
							position: absolute;
							bottom: 10px;
							width: 200px;
							margin-left: -120px;
							margin-bottom: 30px;
						}
					}
				}
			}
		}
	}
}
