.micro-destination {

	margin: 0 px-vw(18);
	width: 100%;
	max-width: px-vw(155);

	picture {
		@include image-fit();
		width: px-vw(170);
		height: px-vw(170);
		margin: 0 auto;
	}

	figcaption {
		@include headline(5);
		text-align: center;
	}

	@include media-breakpoint-up(sm) {

		margin: 0 18px;
		width: 100%;
		max-width: 180px;

		picture {
			width: 170px;
			height: 170px;
		}

	}

	@include media-breakpoint-up(md) {

		margin: 0 10px;
		width: auto;
		max-width: none;

		picture {
			width: 144px;
			height: 144px;
		}
	}

}
