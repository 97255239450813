.loader {
	z-index: $aah-zindex-loader;

	svg {
        @include absolute(5);
        display: block;
        margin: auto;
        max-width: 100px;
        fill: $color-aqua;

        .stop1 { // sass-lint:disable-line no-ids
             stop-color: $color-aqua;
        }

        .stop2 { // sass-lint:disable-line no-ids
             stop-color: $color-white;
        }

        &.v-fixed {
            position: fixed;
        }
	}

	.loader-text {
		position: absolute;
		top: 59%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		text-align: center;
	}
}

.loader-bg {
    position: absolute;
    top: 4px;
	 left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black-fake;
    opacity: 0.4;
    z-index: $aah-zindex-loader - 1;
}

[data-loader] {
    display: none;
}
