.experiences-detail-cards {

	.slick-track { display: flex; }
	.slick-slide { height: auto; }
	.col, .slick-initialized .slick-slide {
		display: flex; // needed for safari issue - fill height
	}

	.col {
		width: px-vw(396);
		max-width: px-vw(396);
		height: auto;
		margin: 0;
	}

	.experiences-card {
		@extend .carousel-card;
		@include body-text();
		margin-bottom: 1rem;
		padding: px-vw(80) 0;
		line-height: 1.75em;

		.headline {
			margin-bottom: px-vw(45);
		}

		p { line-height: inherit; }

		address { margin: 0; }
	}

	@include media-breakpoint-up(sm) {

		.experiences-card {
			.headline {
				margin-bottom: 45px;
			}
		}

	}

	@include media-breakpoint-up(md) {

		margin-top: px-vw(60, lg);

		.experiences-card {
			padding: 35px 0 55px;

			.headline {
				margin-bottom: 20px;
			}
		}

		.col {
			max-width: 33.3%;
		}

	}

	@include media-breakpoint-up(lg) {

		margin-top: 60px;

	}

}
