.mobile-accordion {
	padding-bottom: px-vw(15);

	a[data-toggle] {
		display: block;
		position: relative;
		padding-right: px-vw(50);

		&:hover {
			color: $color-black-fake;
		}

		&.collapsed {
			.icon-svg.hidden-md-up {
				transform: rotate(0) translateY(-50%);
			}
		}

		.icon-svg.hidden-md-up {
			@include absolute(6);
			right: px-vw(10);
			width: px-vw(24);
			height: px-vw(24);
			transform: rotate(-180deg) translateY(50%);
			transition: transform 0.5s;
		}
	}

	@include media-breakpoint-up(md) {
		padding-bottom: 0;

		.collapse,
		.collapsing {
			display: block;
			position: relative;
		}

		a[data-toggle] {
			pointer-events: none;
		}
	}
}
