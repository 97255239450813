.biography {

	position: relative;
	padding: px-vw(55) 0 px-vw(40) 0;
	border-top: 1px solid $color-grey-border;


	figure {
		max-width: 294px;
		margin: 0 auto px-vw(30);
	}

	.blockquote {
		margin: 40px 0 0 0;
	}

	.accent {
		@include absolute();
		width: px-vw(164);
		height: px-vw(164);
		z-index: $aah-zindex-button-overlay;
		filter: drop-shadow(-12px 3px 14px rgba(0,0,0,0.3));

		&.accent-top-right {
			@include absolute(3);
			transform: translate(0, -50%);
		}
		&.accent-top-left {
			@include absolute(1);
			transform: translate(0, -50%);
		}
		&.accent-bottom-right {
			@include absolute(9);
			transform: translate(0, 50%);
		}
		&.accent-bottom-left {
			@include absolute(7);
			transform: translate(0, 50%);
		}
	}

	.collapsible-btn {
		@include body-text();
		margin-bottom: 1rem;
		text-decoration: underline;

		&:hover {
			color: $color-aqua-dark;
		}
	}

	&:last-child {
		border-bottom: 1px solid $color-grey-border;
	}


	@include media-breakpoint-up(sm) {

		padding: 55px 0 40px 0;

	}


	@include media-breakpoint-up(md) {

		padding: 30px 0;
		border-top: 1px solid $color-grey-border;

		figure {
			margin-bottom: 0;
		}

		.blockquote {
			margin: 48px 0 0 0;
		}

		.accent {
			width: px-vw(166px, lg);
			height: px-vw(166px, lg);

			&.accent-top-right { transform: translate(50%, -50%); }
			&.accent-top-left { transform: translate(-50%, -50%); }
			&.accent-bottom-right { transform: translate(50%, 50%); }
			&.accent-bottom-left { transform: translate(-50%, 50%); }
		}

		.collapsible-btn {
			margin: 0;
		}

		.collapsible.collapsible-lg.collapsing {
			height: 162px;
		}
	}

	@include media-breakpoint-up(lg) {

		.accent {
			width: 166px;
			height: 166px;
		}
	}
}
