.amenities-collage {

	height: px-vw(580);
	margin: 55px auto;

	figure {
		position: absolute;
		margin: 0;

		picture {
			@include image-fit();
			display: block;
			height: 100%;
		}
	}

	.image-1 {
		top: px-vw(36);
		left: px-vw(68);
		width: px-vw(244);
		height: px-vw(244);
	}
	.image-2 {
		top: px-vw(292);
		left: px-vw(118);
		width: px-vw(192);
		height: px-vw(142);
	}
	.image-3 {
		top: 0;
		left: px-vw(324);
		width: px-vw(194);
		height: px-vw(194);
	}
	.image-4 {
		top: px-vw(205);
		left: px-vw(324);
		width: px-vw(248);
		height: px-vw(169);
	}
	.image-5 {
		top: px-vw(386);
		left: px-vw(324);
		width: px-vw(140);
		height: px-vw(140);
	}

	@include media-breakpoint-up(md) {

		height: px-vw(600, lg);

		.image-1 {
			top: px-vw(25, lg);
			left: px-vw(3, lg);
			width: px-vw(307, lg);
			height: px-vw(307, lg);
		}
		.image-2 {
			top: px-vw(355, lg);
			left: px-vw(82, lg);
			width: px-vw(228, lg);
			height: px-vw(169, lg);
		}
		.image-3 {
			top: 0;
			left: px-vw(327, lg);
			width: px-vw(226, lg);
			height: px-vw(226, lg);
		}
		.image-4 {
			top: px-vw(243, lg);
			left: px-vw(327, lg);
			width: px-vw(467, lg);
			height: px-vw(319, lg);
		}
		.image-5 {
			top: px-vw(81, lg);
			left: px-vw(571, lg);
			width: px-vw(145, lg);
			height: px-vw(145, lg);
		}

	}

	@include media-breakpoint-up(lg) {

		height: 600px;

		.image-1 {
			top: 25px;
			left: 3px;
			width: 307px;
			height: 307px;
		}
		.image-2 {
			top: 350px;
			left: 85px;
			width: 225px;
			height: 169px;
		}
		.image-3 {
			top: 0;
			left: 327px;
			width: 226px;
			height: 226px;
		}
		.image-4 {
			top: 243px;
			left: 327px;
			width: 467px;
			height: 319px;
		}
		.image-5 {
			top: 81px;
			left: 571px;
			width: 145px;
			height: 145px;
		}
	}
}
