.theme-intro {
	@extend .destination-intro;
	margin-top: px-vw(40);

	.image-1 {
		top: px-vw(138);
		left: px-vw(117);
		width: px-vw(142);
		height: px-vw(142);	
	}

	.image-2 {
		right: auto;
		left: px-vw(278);
	}

	.accent {
		top: px-vw(-38);
		left: px-vw(140);
	}

	@include media-breakpoint-up(sm) {
		
	}

	@include media-breakpoint-up(md) {

		margin-top: 40px;

		.image-1 {
			top: px-vw(122, lg);
			left: px-vw(88, lg);
			width: px-vw(144, lg);
			height: px-vw(144, lg);
		}

		.image-2 {
			top: 0;
			left: px-vw(245, lg);
		}

		.accent {
			top: px-vw(-44, lg);
			left: px-vw(120, lg);
		}

	}

	@include media-breakpoint-up(lg) {

		.image-1 {
			top: 122px;
			left: 88px;
			width: 144px;
			height: 144px;
		}

		.image-2 {
			left: 245px;
		}

		.accent {
			top: -44px;
			left: 120px;
		}
	}
}
