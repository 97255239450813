.price-card {
	max-width: 498px;
	width: 100%;
	margin: 40px auto 0;
	padding-bottom: 20px;
	border-color: #c7c7c7;
	border-bottom: 8px solid $color-aqua;
	flex-wrap: wrap;
	scroll-margin-top: 200px;

	.headline-1 {
		margin: px-vw(-40) auto 0;
		padding: 0 15px;
		background-color: $color-white;
		letter-spacing: 0;
	}

	.headline-3 {
		display: none;
		margin-top: 28px;
	}

	.headline-5 {
		display: none;
	}

	.headline-7 {
		margin-bottom: 15px;
	}

	a:not([data-action="book"]) {
		@include body-text();
		display: none;
		margin-bottom: px-vw(25);
		text-decoration: underline;

		&:hover {
			color: $color-aqua-dark;
		}
	}

	.avail-msg {
		font-weight: 500;
		padding: 10px;
		font-size: 14px;
		background-color: #c6c6c6;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 224px;
		margin: auto;
	}

	.avail-closed-msg {
		padding: 10px;
		font-size: 14px;
		background-color: #c6c6c6;
		text-align: center;
		max-width: 224px;
		margin: auto;
	}

	.js-avail-buttons {
		.btn {
			margin-top: 1rem;
		}
	}

	ul {
		margin: px-vw(20) auto;
		padding: 0 px-vw(20);

		li {
			margin-bottom: px-vw(15);

			&:last-child {
				margin: 4px;
			}
		}

		.icon-svg {
			margin-right: 5px;
		}
	}

	&.sold-out {
		.headline-1,
		.headline-7,
		.js-avail-closed-msg {
			display: none;
		}

		.headline-3,
		.headline-5,
		a {
			display: block;
		}

		ul {
			opacity: 0.25;
		}
	}


	@include media-breakpoint-up(md) {
		max-width: none;
		margin-top: 0;
		border-bottom: 6px solid $color-aqua;
		scroll-margin-top: 170px;

		.headline-1 {
			margin-top: -26px;
			min-width: 110px;
			line-height: 50px;

			@media screen and (min-width: 0\0) { // ie11
				width: 160px;
			}
			@supports (-ms-ime-align: auto) { // edge
				width: 160px;
			}
		}

		a:not([data-action="book"]) {
			margin-bottom: 25px;
		}

		ul {
			margin: 0 auto;
			padding: 0 20px;

			li {
				margin-bottom: 10px;
			}
		}

		&.sold-out {
			ul {
				li {
					margin-bottom: 4px;
				}
			}
		}

		.text-muted {
			line-height: 0.6;
		}
	}

	@include media-breakpoint-up(lg) {
		scroll-margin-top: 120px;
	}
}
