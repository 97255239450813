.reservation-information {

	.feature {
		height: px-vw(316);
	}

	.container-inset {
		max-width: 800px;
	}

	.headline-5 {
		margin-bottom: px-vw(40);

		span {
			font-weight: 300;
		}
	}

	.card {
		margin-bottom: px-vw(39);
		padding: px-vw(40) px-vw(70) px-vw(20);

		.icon-svg {
			width: px-vw(36);
			height: px-vw(36);
			margin: 0 auto 5px;
		}

		.info {
			@include body-text();
			margin: auto;
			padding: 25px 0;

			.divider {
				display: block;
			}
		}

		footer {
			@include body-text();
			margin-bottom: 1rem;
			padding-top: 12px;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: inline-block;
					margin-right: 2px;

					+ li:before {
						display: inline-block;
						width: 1px;
						height: 0.8em;
						margin: 0 px-vw(15);
						content: "";
						background-color: #797979;
						vertical-align: -1px;
					}
				}
			}

			p {
				margin-bottom: 0;
			}

			span {
				@include body-text();
				margin-bottom: 1rem;
			}

			.icon-svg {
				width: px-vw(24);
				height: px-vw(24);
				margin-bottom: 0;
				vertical-align: -1px;
			}
		}
	}

	a.headline-5 {
		text-decoration: underline;
	}

	.btn {
		margin-top: 20px;
	}


	@include media-breakpoint-up(sm) {

		.feature {
			height: 316px;
		}

	}


	@include media-breakpoint-up(md) {

		.feature {
			height: px-vw(300, lg);
		}

		.headline-5 {
			margin-bottom: 20px;
		}

		.container-inset {
			width: 100%;
			margin-top: -132px;
		}

		.info {
			padding: 0;
			
			span {
				display: block;
			}
		}

		.card {
			margin: 0;
			padding: 40px 15px 10px;
			height: 100%;

			.icon-svg {
				width: 20px;
				height: 20px;
			}

			footer {
				.icon-svg {
					width: 12px;
					height: 12px;
					margin-right: 5px;
				}

				ul {
					li {
						+ li:before {
							margin: 0 8px;
						}
					}
				}
			}
		}

		a.headline-5 {
			margin-top: 20px;
		}
	}

	@include media-breakpoint-up(lg) {

		.feature {
			height: 300px;
		}
		
	}
}
