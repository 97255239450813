.header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: $zindex-navbar;

	ul {
		padding: 0;
		list-style: none;

		li {
			a, .navbar-a {
				display: block;
				cursor: pointer;
			}

			&.dropdown {
				position: static;

				> a, .navbar-a {
					position: relative;

					> .icon-svg {
						@include absolute(6);
						display: inline-block;
						width: px-vw(24);
						height: px-vw(24);
						margin-right: 1.5em;
					}

					&:hover, &:focus {
						.icon-svg {
							svg {
								fill: $color-aqua-dark;
							}
						}
					}
				}
			}

			.view-all-btn a {
				text-decoration: underline;

				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
	}

	nav.navbar {
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: normal;
		width: 100%;
		height: auto;
		max-height: 100vh;
		padding: 0;
		background-color: $color-grey-body;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;

		.navbar-header {
			position: relative;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			height: px-vw(108);
			background-color: $color-white;

			.navbar-brand {
				display: flex;
				width: 12em;
				max-width: 14rem;
				max-height: 75%;
				margin: 0 auto;
				padding: 0.25em;
				text-align: center;
				transition: right 0.1s, height 0.1s;

				picture, img {
					display: flex;
					width: 100%;
				}

				img {
					object-fit: contain;
				}
			}

			.property-navbar-brand {
				max-height: 100%;
			}

			.navbar-action {
				display: none;
			}
		}

		.navbar-collapse {
			position: relative;
			height: 0;
			border-top: 1px solid $color-grey-border;
			transition: height 0.1s;
			overflow-x: hidden;
			overflow-y: auto;

			&.show {
				height: 100%;
				min-height: 100vh;
				padding-bottom: 100%;

				& ~ .btn-property-navbar {
					display: none !important;
				}
			}
		}

		.btn-back {
			display: flex;
			align-items: center;
			padding: 1.25em 1.5em;
			font-weight: 300;

			.icon-svg {
				width: 1.25em;
				height: 1.25em;
				margin-right: 0.125em;
				pointer-events: none;
			}

			&:hover, &:focus {
				color: $color-aqua-dark;

				.icon-svg {
					svg {
						fill: $color-aqua-dark;
					}
				}
			}
		}

		ul.navbar-nav {
			position: relative;
			height: 100%;
			background-color: $color-white;

			> li {
				border-bottom: 1px solid $color-grey-border;

				a:not(.btn), .navbar-a {
					width: 100%;
					padding: 1em 1.5em;
					font-size: px-vw(32);
					font-weight: 500;
					line-height: 1.25em;
					text-align: left;
					color: $color-black-fake;

					&:hover, &:focus {
						color: $color-aqua-dark;
					}
				}
			}
		}

		.navbar-panel {
			position: absolute;
			top: 0;
			left: 100%;
			width: 100%;
			height: 100%;
			transition: all 0.2s, height 1ms;
			background-color: $color-white;
			z-index: $zindex-dropdown;

			&.show {
				left: 0;
			}

			.container {
				position: relative;
				width: 100%;
				padding: 0;
			}

			ul.navbar-panel-nav,
			ul.navbar-panel-detail-nav {
				border-top: 1px solid $color-grey-body;
			}

			ul.navbar-panel-nav {
				height: auto;
				margin: 0;
				padding: 0;
				overflow: visible;

				li {
					border-bottom: 1px solid $color-grey-body;

					@include media-breakpoint-up(md) {
						border-bottom-width: 1px;
					}

					a {
						display: block;
					}
				}
			}

			.navbar-panel-action {
				margin: 0;
				padding: px-vw(55) px-vw(70) px-vw(50);
				background-color: $color-white;

				.btn {
					width: 100%;
					line-height: 1.25em;
				}
			}

			.navbar-panel-detail {
				position: absolute;
				//position: fixed;
				overflow-y: auto;
				top: 0;
				left: 100%;
				width: 100%;
				height: 100%;
				transition: left 0.1s;
				background: $white;
				z-index: 3;

				&.layout-1 {
					display: none;
				}

				&.layout-2 {

					.feature {
						position: relative;
						width: 100vw;
						height: 100vw;

						.content {
							@include absolute(8);
							width: 80%;
							margin: 1em 0;
							text-align: center;

							&.content-top {
								@include absolute(2);
							}
							&.content-middle {
								@include absolute(5);
							}
							&.content-bottom {
								@include absolute(8);
							}
						}
					}
				}

				&.layout-3 {

					.feature {
						position: relative;
						width: 100vw;
						height: 150vw;

						.content {
							@include absolute(5);
							width: 80%;
							text-align: center;

							.icon-svg {
								width: 50%;
							}
						}
					}
				}

				&.collapse {
					height: auto;

					&.show {
						left: 0;
						padding-bottom: 150%;

					}
				}
			}

			&.collapse {
				height: auto;
				overflow-y: visible;
			}
		}

		.dropdown {
			> a, .navbar-a {
				position: relative;

				.icon-svg {
					@include absolute(6);
					width: 1em;
					height: 1em;
					margin-right: px-vw(40);
				}
			}

			&.active {
				.navbar-panel-detail {
					display: block;
					left: 0;
					z-index: 1;
				}
			}
		}

		.navbar-action {
			position: relative;
			margin: 1.5em 3em;
			//	background-color: $color-white;

			.btn {
				width: 100%;
				background-color: $color-magenta;
				margin: 0;

				&:hover {
					background-color: #560530;
				}
			}
		}

		/*&.expanded {
			position: relative;
			top: 0;
			bottom: 0;
			height: 100vh;
			flex-wrap: nowrap;
			overflow-x: hidden;
			overflow-y: scroll;

			.btn-property-navbar, .property-navbar {
				display: none;
			}

			//&.open {
			//	.navbar-brand {
			//		right: 100%;
			//	}
			//	.btn-back {
			//		position: initial;
			//		bottom: auto;
			//		left: 0;
			//	}
			//}
		}*/

		.navbar-header {
			&.zoomed {
				height: 13vh;
			}
		}

		&.is-stuck {

			.navbar-header {
				display: flex;

				&.zoomed {
					height: 13vh;

					.navbar-action .btn {
						padding: 0;
					}
				}

				.navbar-brand {
					display: none;
				}

				.navbar-action {
					display: flex;
					width: 100%;
					height: 100%;
					margin: 0;
					padding: 0;
				}
			}

			&.expanded {
				.navbar-header {
					.navbar-action {
						display: none;
					}
				}
				.navbar-brand {
					display: block;
				}
			}

		}

	}

	@include media-breakpoint-up(sm) {

		nav.navbar {
			.navbar-header {
				.navbar-brand.main {
					.icon-svg, img {
						width: 213px;
						height: 44px;
						margin: 32px;
					}
				}

				.navbar-action {
					margin-right: 92px;
				}
			}

			ul.navbar-nav {
				> li {
					a:not(.btn), .navbar-a {
						font-size: 32px;
					}
				}
			}

			.btn-back {
				font-size: 32px;
			}

			.navbar-action {
				.btn {
					@include text(30, 32, 0.5);
					padding: 24px 64px 28px;
				}
			}

			.navbar-panel {
				.navbar-panel-action {
					padding: 55px 70px 50px;

					.btn {
						@include text(30, 32, 0.5);
						padding: 16px 64px 20px;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		nav.navbar {
			.navbar-header {
				height: 108px;

				.navbar-brand {
					max-height: 80%;
				}
			}
		}

	}

	@include media-breakpoint-up(lg) {

		position: fixed;
		right: 0;
		left: auto;
		width: 100%;
		height: auto;
		margin: auto;
		background-color: $color-white;

			ul {
				li {
					a, .navbar-a {
						display: inline-block;
					}
				}
			}

			nav.navbar {
				position: relative;
				max-height: $desktop-padding * 1px;
				max-width: #{map-get($grid-breakpoints, 'xl')};
				margin: 0 auto;
				background-color: $color-white;
				overflow-x: visible;
				overflow-y: visible;
				flex-direction: row;

				.navbar-header {
					display: flex;
					align-items: center;
					height: auto;
					overflow: visible;

					.navbar-brand {
						max-width: 10.25em;
						max-height: 50%;
						margin: auto 1em auto 2em;
						overflow: visible;

						.icon-svg, img {
							transition: all 0.1s ease-out;
						}
					}
				}

				.navbar-collapse {
					position: static;
					height: auto;
					border: 0;
					z-index: 3;
					overflow: visible;
				}

				ul.navbar-nav {
					position: static;
					margin: 0 14px 0 auto;
					align-items: center;

					> li {
						border: 0;

						&.relative {
							position: relative;

							.navbar-panel {
								width: auto;
								min-width: 15em;
								padding: 0.5em;

								.container {
									width: auto;
									padding-bottom: 0;
								}

								.navbar-panel-nav {
									padding: 0;
									margin-bottom: 0;

									> li {
										width: auto;
										white-space: nowrap;

										&:last-child {
											margin-bottom: 0;
										}

										a {
											white-space: normal;
										}
									}
								}
							}
						}

						&:first-child {
							display: flex;
							align-items: center;
							justify-content: space-between;
						}

						> a:not(.btn), .navbar-a {
							text-align: center;
						}

						a:not(.btn), .navbar-a {
							padding: 14px 20px;
							font-size: 14px;
							line-height: 1.25em;

							&.navbar-dropdown-toggle {
								display: flex;
								align-items: center;

								.icon-svg {
									position: relative;
									flex-shrink: 0;
									margin: 0 0 0 0.5em;
									transform: rotate(90deg);
									overflow: visible;
									transition: transform 0.1s;
								}

								&:not(.collapsed) {
									color: $color-aqua-dark;

									.icon-svg {
										transform: rotate(-90deg);

										svg {
											fill: $color-aqua-dark;
										}
									}
								}
							}
						}
					}
				}

				.dropdown {
					> a, .navbar-a {
						position: relative;
						display: flex;
						align-items: center;

						.icon-svg {
							position: relative;
							top: auto;
							transform: none;
							margin: 0 0 0 0.5em;
							width: 0.75em !important;
							height: 0.75em !important;
						}
					}

					&.show {
						.icon-svg {
							transform: rotate(-180deg);

							svg {
								fill: $color-aqua-dark;
							}
						}
					}
				}

				.navbar-action {
					position: relative;
					height: 140px;
					margin: 0 0 -10px;
					padding: 0;
					z-index: $aah-zindex-dropdown + 1;
					transition: all 0.1s ease-in-out;

					.btn {
						@include text(16, 18, unset);
						width: 8rem;
						padding: 54px 20px;
						white-space: normal;
						transition: padding 0.1s ease-in-out;
						
						.two-line-book-now {
							max-width: 40px;
							margin: auto;
							text-align: center;
						}
					}

				}

				.navbar-panel-container {
					width: 100%;
					max-height: 75vh;
					margin: 0;
					padding: 30px 40px;
					overflow-y: auto;
				}

				.navbar-panel {
					top: 100%;
					left: 0;
					right: 0;
					margin: 0 auto;
					height: auto;
					overflow-y: hidden;
					transition: $transition-collapse;
					// TODO: increase max-width as needed ~1200px once preview column is in place
					max-width: #{map-get($container-max-widths, 'lg')};

					.container {
						width: #{map-get($container-max-widths, 'lg')};
						padding: inherit;
					}

					&.collapsing {
						height: 0;
						&.show {
							height: auto;
						}
					}

					ul.navbar-panel-nav,
					ul.navbar-panel-detail-nav {
						border-top: 0;
					}

					ul.navbar-panel-nav {
						position: relative;
						height: auto;
						margin: 10px auto 20px auto;
						padding-bottom: 150px;
						z-index: 0;
						overflow: visible;

						li {
							border: 0;

							a {
								padding: 0.75em 1em;
								text-align: left;
								line-height: 1.2em;
								font-weight: 300;

								.icon-svg {
									display: none;
								}

								&.active {
									color: $color-aqua-dark;
								}
							}
						}

						> li {
							width: 150px;

							&:first-of-type {
								a:not(.ga-menu-element) {
									font-size: 16px;

									&:before,
									&:after {
										display: none;
									}
								}
							}

							a {
								&.active {
									&::before,
									&::after {
										position: absolute;
										top: 0.9em;
										right: -18px;
										width: 0;
										height: 0;
										border: 0 solid transparent;
										content: '';
										pointer-events: none;
									}

									&:before {
										margin-top: -7px;
										border-right-color: $color-grey;
										border-width: 7px;
									}

									&:after {
										margin-top: -6px;
										border-right-color: $color-white;
										border-width: 6px;
									}

									.navbar-panel-detail {
										display: block;
										left: 0;
										z-index: -1;
									}
								}
							}
						}

						ul.navbar-panel-detail-nav {
							width: 100%;
							height: 90%;
							min-height: 300px;
							padding-left: 18px;
							column-count: 3;
							-moz-column-fill: balance;
							border-left: 1px solid $color-grey;
							font-weight: 300;

							li {
								display: inline-block;
								width: 100%;
							}
						}

					}

					// TODO: fix this selector
					div.navbar-panel-nav {
						column-count: 3;
						column-gap: 1.5em;

						ul {
							width: 100%;
							break-inside: avoid;
							// padding used (instead of margin) due to Safari wrapping issue
							padding-bottom: 1.5em;

							li {
								a {
									padding: 0.25em;
									font-weight: 300;

									&.property-heading {
										@include headline(3);
									}
								}
							}
						}
					}

					.navbar-panel-action {
						.btn {
							@include text(12, unset, 0.5);
							padding: 11px 32px 10px;
							line-height: 1.25em;
						}
					}

					.navbar-panel-detail {
						display: none;
						left: 0;
						height: 100%;
						padding-left: 167px;
						background-color: $color-white;
						z-index: -1;

						.row { height: 100%; }

						ul.navbar-panel-detail-nav {
							li {
								a { font-weight: 300; }
							}
						}

						&.layout-1 {
							position: absolute;
							width: 780px;
							height: 342px;
							margin-left: 150px;
							padding: 0;

							.content {
								@include absolute(5);
							}

							figure {
								position: absolute;
								display: inline-block;

								picture {
									@include image-fit();
									position: absolute;
									width: 100%;
									height: 100%;
								}

								&.image-1 {
									top: 47px;
									left: 18px;
									width: 226px;
									height: 228px;
								}

								&.image-2 {
									top: 0;
									left: 262px;
									width: 144px;
									height: 144px;
								}

								&.image-3 {
									top: 165px;
									left: 262px;
									width: 144px;
									height: 144px;
								}

								&.image-4 {
									top: 0;
									left: 424px;
									width: 338px;
									height: 248px;
								}
							}
						}

						&.layout-2 {
							position: absolute;
							min-height: 300px;

							ul.navbar-panel-detail-nav {
								column-count: 2;
							}

							.feature {
								position: relative;
								width: 275px;
								height: 280px;

								.content {
									@include absolute(8);
									width: 100%;
									margin-bottom: 1em;
									text-align: center;

									.headline-1 {
										@include text(28, 32, 3);
									}
								}
							}
						}

						&.layout-3 {
							position: absolute;
							min-height: 440px;

							ul.navbar-panel-detail-nav {
								column-count: 2;
							}

							.feature {
								position: relative;
								width: 275px;
								height: 420px;

								.content {
									@include absolute(5);
									width: 80%;
									text-align: center;

									.headline-1 {
										@include text(28, 32, 3);
									}

									.icon-svg {
										width: 50%;
									}
								}
							}
						}

						&.collapse {
							display: none;
							&.show { display: block; }
						}
					}

					&.collapse {
						display: none;
						&.show { display: block; }
					}

					.navbar-panel-preview {
						p {
							@include body-text;
							background-color: $color-aqua-dark;
							color: $color-white;
						}

						img {
							width: 100%;
						}
					}
				}

				/*&.expanded {
					height: auto;
				}*/

				// Sticky Navigation
				&.is-stuck {
					.navbar-header {
						height: 72px;

						.navbar-brand {
							display: flex;
							top: 0;
							max-height: 65%;
						}

						.property-navbar-brand {
							max-height: 100%;
						}

						.navbar-action {
							display: none;
						}
					}

					ul.utility-nav {
						opacity: 0;

						li {
							display: none;
						}
					}

					.navbar-action {
						height: 100%;
						margin: 0;

						> .btn {
							height: 100%;
							margin: 0;
							padding: 0;
						}
					}
				}

				&.property-navbar {

					.navbar-header {
						.navbar-brand {
							max-width: 7.5em;
							max-height: 80%;
						}
					}

					.navbar-header, .property-navbar-nav {
						margin-top: 1.5em;
					}

					&.is-stuck {
						.navbar-header, .property-navbar-nav {
							margin-top: 0;
						}

						.navbar-brand {
							max-height: 100%;
							padding: 0.125em;
						}
					}
				}
			}
	}

	// touch-device
	@media not all and (hover: hover) {
		.navbar-panel-preview {
			display: none !important;
		}
	}
}



.search-toggle-container {
	position: relative;
	display: flex;
	align-items: center;
	height: 22vw;
	background-color: $color-white;
	border-bottom: 1px solid $color-grey-border;
	padding: 1em;
	z-index: $zindex-navbar + 1;

	.navbar-action {
		flex-grow: 1;
		margin: 0 0.5em !important;
	}

	.btn-search-toggle {
		position: relative;
		margin: 0;
		padding: 1em;
		border: 0;

		.icon-svg {
			width: 1.75em;
			height: 1.75em;

			svg {
				fill: $color-black-fake;
			}
		}

		&:not(.collapsed) {
			display: none;

			& + .navbar-action {
				display: none;
			}
		}
	}

	#search-collapse {
		transition: none;
		flex-grow: 1;
		white-space: nowrap;
		z-index: 1;

		&.show {
			display: flex;
			width: 100%;
			align-items: center;

			.search {
				display: flex;

				form {
					display: flex;
					align-items: center;
				}

				.form-actions {
					margin: 0 !important;
					position: relative;
					top: auto;
					left: auto;
					transform: none;

					button {
						margin: 0.75em;
						padding: 1em;
					}
				}

				.search-results {
					margin: 1em;

					.dropdown-item {
						white-space: normal !important;
					}
				}
			}
		}
	}

	.search {
		position: static;
		flex-grow: 1;

		.form-group {
			flex-grow: 1;
			margin: 0 0.5em 0 0 !important;
		}

		.search-input {
			margin: 0;
			padding: 1em;
		}
	}

	.search-backdrop {
		position: absolute !important;
		top: 100% !important;
		min-height: 100vh;
	}


	@include media-breakpoint-up(sm) {

		height: 10em;
		padding: 1em 1.5em;

		.navbar-action {
			margin: 0 1em !important;
		}

		.btn-search-toggle {
			.icon-svg {
				width: 2.5em;
				height: 2.5em;
			}
		}

		.search {
			button {
				padding: 1.25em !important;
			}
		}

	}

	@include media-breakpoint-up(lg) {

		display: none;

	}
}
