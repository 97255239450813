.account-modal {

	.modal-content {
		padding: px-vw(25) px-vw(50) px-vw(150);
	}

	.modal-header {
		padding-bottom: 0;
		text-align: center;
	}

	.modal-body {
		button[type='submit'] {
			width: 100%;
			margin-top: 2rem;
		}

		a {
			@include body-text();
			margin-bottom: 1rem;
			text-decoration: underline;

			&:hover, &:focus {
				color: $color-aqua-dark;
			}
		}
	}

	.modal-footer {
		position: relative;
		display: block;
		margin: px-vw(35) px-vw(15) 0;
		padding: px-vw(50) 0 0;
		border-top: 1px solid $color-black;
		text-align: center;
		justify-content: unset;

		.text-divider {
			@include body-text();
			position: absolute;
			top: 0;
			margin-bottom: 1rem;
			padding: 0 14px;
			transform: translate(-50%, -54%);
			background-color: $color-white;
		}

		.btn {
			padding-right: 0;
			padding-left: px-vw(83);
			flex: 1;
			text-align: center;
			border: 0;

			&:last-child {
				margin-left: 15px;
			}

			.icon-svg {
				position: absolute;
				top: 0;
				left: 0;
				width: px-vw(83);
				height: 100%;
				
				svg {
					padding: 8px;
				}
			}

			&.btn-facebook {
				.icon-svg {
					background-color: darken($color-facebook, 10%);
				}
			}

			&.btn-google {
				.icon-svg {
					background-color: darken($color-google, 10%);
				}
			}
		}

		p {
			margin-bottom: 0;
		}
	}

	.d-flex {
		.form-group {
			margin-bottom: 0;

			&:last-child {
				margin-left: 15px;
			}
		}
	}

	@include media-breakpoint-up(md) {

		.modal-content {
			padding: 15px 50px 25px;
		}

		.modal-body {
			a {
				padding-top: 0;
			}

			button[type='submit'] {
				margin-top: 0.7rem;
			}
		}

		.modal-footer {
			margin: 5px 15px 0;
			padding-top: 26px;

			.btn {
				padding-left: 44px;

				.icon-svg {
					width: 44px;
				}
			}
		}
	}

}
