.press-navbar {
	margin-top: px-vw(50);

	ul {
		border-top: 1px solid $color-grey-border;
	}

	li a {
		position: relative;
		margin-bottom: 0;
		padding: px-vw(30);
	}

	.icon-svg {
		@include absolute(6);
	}

	@include media-breakpoint-up(md) {
		margin-top: 25px;

		li a {
			padding: 15px 30px 15px 0;
		}
	}
}
