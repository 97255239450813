.personal-guidance {

	position: relative;
	height: px-vw(994);
	color: $color-white;

	p { color: $color-white; }

	.feature {
		position: absolute;
		top: 0;

		left: auto;
		right: auto;
		bottom: 0;
		width: 100%;
		height: 100%;

		.container {
			> .content {
				@include absolute(2);
				padding: 0 px-vw(20);
			}
		}

		.background {
			img {
				left: px-vw(-32);
				min-width: 116%;
				transform: translateY(-50%);
				transition: left 5s linear;
			}

			img.pan {
				left: 0;
			}
		}
	}

	header {
		@include absolute(2);
		display: none;
		margin: px-vw(115) auto 0;

		.progress {
			width: calc(100% - 2px);
			height: px-vw(4);
			border: 0;
			margin: px-vw(-10) auto 0;

			.progress-bar {
				background-color: $color-aqua;
			}
		}

		.nav-tabs {
			position: relative;
			display: flex;
			width: px-vw(240);
			margin: 0 auto;
			padding: 0;
			border: 0;
			justify-content: space-between;
			overflow: visible;

			.nav-item {
				width: px-vw(16);
				height: px-vw(16);
				padding: 0;
				flex: 0;

				.nav-link {
					padding: 0;
					margin: 0;
					display: block;
					width: px-vw(16);
					height: px-vw(16);
					background-color: $color-white;
					border-radius: 50%;
					pointer-events: none;

					&.active {
						background-color: $color-aqua;
						pointer-events: auto;
					}
				}

				&.completed .nav-link {
					background-color: $color-aqua;
					pointer-events: auto;
				}
			}
		}
	}

	.close {
		display: none;

		.icon-svg {
			width: px-vw(32);
			height: px-vw(32);

			svg { fill: $color-white; }
		}
	}

	.container-circle {
		@include animated-circle;
		position: relative;
		width: px-vw(480);
		height: px-vw(480);
		margin: px-vw(65) auto px-vw(45);
		overflow: hidden;

		&:after {
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			border-radius: 50%;
			border: px-vw(10) solid $color-white;
			z-index: -1;
		}

		.content {
			padding: px-vw(40);
		}
	}

	fieldset {
		padding: 40px 0;
	}

	.form-check-inline {
		max-width: 30%;
		margin: 0 px-vw(10) px-vw(40) px-vw(10);
		vertical-align: top;

		.form-check-label {
			@include headline(5);
			padding: 0;
			animation: fadein 2s;

			picture {
				@include image-fit();
				display: block;
				width: px-vw(160);
				height: px-vw(160);
				margin: 0 px-vw(5) px-vw(20);
				pointer-events: none;
			}

			span {
				display: inline-block;
				width: 95%;
			}
		}

		.form-check-input {
			opacity: 0;

			&:checked {
				& ~ picture {
					&:before {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: 0;
						border: px-vw(10) solid $color-white;
						border-radius: 50%;
						content: ' ';
						z-index: 1;
					}
				}
			}
		}
	}

	.range {
		position: relative;
		margin-bottom: px-vw(180);
		padding: 0;

		.range-container {
			position: relative;
			display: inline-block;
			width: 100%;
			margin: px-vw(90) 0 0 0;

			input[type='range'] {
				width: 100%;
				margin: px-vw(250) 0 px-vw(50) 0;
				padding: 0;
				border: 0;
				-webkit-appearance: none;

				// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
				&::-webkit-slider-runnable-track {
					width: 100%;
					height: px-vw(10);
					box-shadow: none;
					background: $color-white;
					border: 0;
					border-radius: 0;
					cursor: pointer;
				}
				&::-webkit-slider-thumb {
					width: px-vw(48);
					height: px-vw(48);
					margin-top: px-vw(-20);
					box-shadow: none;
					border: 0;
					border-radius: 50%;
					background: $color-aqua;
					cursor: pointer;
					-webkit-appearance: none;
				}
				&:focus::-webkit-slider-runnable-track {
					background: $color-white;
				}
				&::-moz-range-track {
					width: 100%;
					height: px-vw(10);
					cursor: pointer;
					box-shadow: none;
					background: $color-white;
					border-radius: 0;
					border: 0 solid $color-black;
				}
				&::-moz-range-thumb {
					width: px-vw(48);
					height: px-vw(48);
					box-shadow: none;
					border: 0;
					border-radius: 50%;
					background: $color-aqua;
					cursor: pointer;
				}
				&::-moz-focus-outer { border: 0; }
				&::-ms-track {
					width: 100%;
					height: px-vw(10);
					cursor: pointer;
					background: transparent;
					border-color: transparent;
					color: transparent;
					border-width: px-vw(19) 0;
				}
				&::-ms-fill-lower { background: $color-white; }
				&::-ms-fill-upper { background: $color-white; }
				&::-ms-thumb {
					width: px-vw(48);
					height: px-vw(48);
					margin: 0;
					border-radius: 50%;
					background: $color-aqua;
					box-shadow: none;
					border: 0;
					cursor: pointer;
				}
				&:focus::-ms-fill-lower { background: $color-white; }
				&:focus::-ms-fill-upper { background: $color-white; }
			}

			output {
				@include absolute(2);
				display: inline-block;
				width: px-vw(160);
				height: px-vw(160);
				line-height: px-vw(140);
				font-family: "Oswald", sans-serif;
				font-size: px-vw(50);
				color: $color-white;
				text-align: center;
				border: px-vw(10) solid $color-white;
				border-radius: 50%;
				pointer-events: none;
				letter-spacing: 0.1em;
				text-indent: 0.1em;
			}
		}

		.btn-increase, .btn-decrease {
			@include headline(1);
			position: absolute;
			bottom: px-vw(-50);
			display: inline-block;
			margin: 0;
			padding: 0 0.25em;
			min-width: 0;
			width: 25%;
		}
		.btn-decrease {
			left: 0;
			text-align: left;
		}
		.btn-increase {
			right: 0;
			text-align: right;
		}
	}

	footer {
		margin-bottom: 0;
		padding: 0 px-vw(30);
	}


	@include media-breakpoint-up(sm) {

		height: 994px;

		.feature {
			.container {
				> .content {
					padding: 0 20px;
				}
			}
		}

		header {

			margin: 115px auto 0;

			.nav-tabs {
				position: relative;
				width: 245px;

				.nav-item {
					width: 16px;
					height: 16px;

					.nav-link {
						width: 16px;
						height: 16px;
					}
				}
			}

			.progress {
				width: 240px;
				height: 4px;
				margin: -10px auto 0;
			}
		}

		.close {
			.icon-svg {
				width: 32px;
				height: 32px;
			}
		}

		.container-circle {
			width: 480px;
			height: 480px;
			margin: 65px auto 45px;

			&:after {
				border-width: 10px;
			}

			.content {
				padding: 40px;
			}

			.halfcircle {
				border-width: 10px;
			}
		}

		.form-check-inline {

			margin: 0 10px 40px 10px;

			.form-check-input {

				.form-check-label {
					picture {
						width: 160px;
						height: 160px;
						margin: 0 5px 20px;
					}
				}

				&:checked {
					& ~ picture {
						&:before {
							border-width: 10px;
						}
					}
				}
			}
		}

		.range {
			margin-bottom: 180px;

			.range-container {
				margin: 90px 0 0 0;

				input[type="range"] {

					margin: 250px 0 50px 0;

					&::-webkit-slider-runnable-track {
						height: 10px;
					}
					&::-webkit-slider-thumb {
						width: 48px;
						height: 48px;
						margin-top: -20px;
					}
					&::-moz-range-track {
						height: 10px;
					}
					&::-moz-range-thumb {
						width: 48px;
						height: 48px;
					}
					&::-ms-track {
						height: 10px;
						border-width: 19px 0;
					}
					&::-ms-thumb {
						width: 48px;
						height: 48px;
					}
				}

				output {
					width: 160px;
					height: 160px;
					line-height: 140px;
					border-width: 10px;
					font-size: 50px;
				}
			}
		}

		footer {
			padding: 0 30px;
		}

	}

	@include media-breakpoint-down(sm) {

		&.in-progress {
			position: fixed;
			width: 100%;
			height: 100%;
			z-index: $aah-zindex-modal;

			.feature {
				position: absolute;
				top: 0;
				right: auto;
				bottom: 0;
				left: auto;
				height: 100%;
				overflow-y: scroll;

				.container {
					overflow-y: scroll;

					> .content {
						margin-top: px-vw(172);
					}
				}

				.close {
					position: absolute;
					display: block;
					top: 36px;
					right: 36px;
					fill: $color-white;
					opacity: 1;
					z-index: 999;
				}
			}
			footer {
				margin-bottom: px-vw(36);
			}
		}

		footer {
			.btn {
				width: 100%;
				display: block;

				&[aria-disabled="true"] {
					//background: $color-white;
					//color: $color-aqua-dark;
				}

				& + .btn {
					margin-left: 0;
				}
			}
		}

	}

	@include media-breakpoint-up(md) {

		height: px-vw(930, lg);

		.feature {
			.container {
				> .content {
					@include absolute(2);
					margin-top: px-vw(240, lg);
					padding: 0;
				}
			}

			.background {
				img {
					left: -122px;
					transition: left 22s linear;
				}
			}
		}

		header {
			margin: px-vw(180, lg) auto 0;

			.nav-tabs {
				width: 180px;
				margin: 0 auto;
				height: 12px;

				.nav-item {
					.nav-link {
						display: block;
						width: 12px;
						height: 12px;
						background-color: $color-white;
						border-radius: 50%;
					}
				}
			}

			.progress {
				width: calc(100% - 2px);
				height: 2px;
				margin-top: -6px;
				border: 0;
			}
		}

		.container-circle {
			margin-top: px-vw(-70, lg);
		}

		.form-check-inline {
			margin: 0 8px 40px 8px;

			.form-check-label {
				picture {
					width: px-vw(170, lg);
					height: px-vw(170, lg);
					margin: 0 auto 20px;
				}

				span {
					width: auto;
				}
			}
		}

		.range {

			margin-bottom: 0;

			.range-container {
				max-width: 480px;
				margin: px-vw(100, lg) 0 px-vw(140, lg);
				vertical-align: middle;

				input[type="range"] {
					margin: 0;

					&::-webkit-slider-runnable-track {
						height: px-vw(10, lg);
					}
					&::-webkit-slider-thumb {
						width: px-vw(80, lg);
						height: px-vw(80, lg);
						margin-top: px-vw(-35, lg);
					}
					&::-moz-range-track {
						height: px-vw(10, lg);
					}
					&::-moz-range-thumb {
						width: px-vw(80, lg);
						height: px-vw(80, lg);
					}
					&::-ms-track {
						height: px-vw(10, lg);
						border-width: 40px;
					}
					&::-ms-thumb {
						width: 80px;
						height: 80px;
						margin-top: 5px;
					}
				}

				output {
					@include absolute(5);
					width: px-vw(80, lg);
					height: px-vw(80, lg);
					font-size: px-vw(28, lg);
					line-height: px-vw(80, lg);
					border-width: 0;

					&.one {
						@include absolute(4);
					}
					&.three {
						@include absolute(6);
					}
				}
			}

			.btn-increase, .btn-decrease {
				position: relative;
				top: 0;
				width: 15%;
				margin: px-vw(100, lg) 0 px-vw(140, lg);
				padding: 0.5em 0.75em;
				vertical-align: middle;
			}
			.btn-decrease {
				text-align: right;

			}
			.btn-increase { text-align: left; }
		}

	}

	@include media-breakpoint-up(lg) {

		height: 930px;

		header {
			margin: 180px auto 0;
		}

		.feature {
			.container {
				> .content {
					margin-top: 240px;
				}
			}
		}

		.container-circle {
			margin-top: -70px;
		}

		.form-check-inline {
			.form-check-label {
				picture {
					width: 170px;
					height: 170px;
				}
			}
		}

		.range {
			.range-container {
				max-width: 640px;
				margin: 100px 0 140px 0;

				input[type="range"] {
					&::-webkit-slider-runnable-track {
						height: 10px;
					}
					&::-webkit-slider-thumb {
						width: 80px;
						height: 80px;
						margin-top: -35px;
					}
					&::-webkit-slider-runnable-track {
						height: 10px;
					}
					&::-moz-range-track {
						height: 10px;
					}
					&::-moz-range-thumb {
						width: 80px;
						height: 80px;
					}
					&::-ms-track {
						height: 10px;
						border-width: 40px 0;
					}
					&::-ms-thumb {
						width: 80px;
						height: 80px;
						margin-top: 5px;
					}
				}

				output {
					width: 80px;
					height: 80px;
					font-size: 28px;
					line-height: 80px;
				}
			}

			.btn-increase, .btn-decrease {
				margin: 100px 0 148px;
			}
		}
	}

	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

}
