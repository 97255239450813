.promo-colored {
	padding: px-vw(30);
	border: 3px solid $color-aqua-dark;
	background-color: rgba($color-blue-light, 0.25);

	h1 {
		margin-bottom: px-vw(40);
	}

	figure {
		margin-bottom: px-vw(25);
	}

	img {
		max-width: px-vw(235);
	}

	.btn {
		width: 100%;
		margin-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		padding: 72px 15px;

		h1 {
			margin-bottom: 20px;
		}

		figure {
			margin-bottom: 10px;
		}

		img {
			max-width: 155px;
		}

		.btn {
			width: auto;
			min-width: 200px;
		}
	}
}

// Promo with Background Image
// todo: refactor references to use 'feature' component (same)
.promo-guidance {
	position: relative;
	padding: px-vw(165) px-vw(30);
	overflow: hidden;
	border: 0;
	background: none;

	&.small {
		padding: px-vw(45) px-vw(30);
	}

	.headline-2 {
		position: relative;
		padding: 0 px-vw(40);
		margin-bottom: px-vw(20);
	}

	h1 {
		position: relative;
		padding: 0 px-vw(40);
		margin-bottom: px-vw(20);
	}

	figure {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	picture {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;

		img { 
			@include absolute(5);
			min-width: 101%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover; object-position: center;'; // object-fit-images polyfill
		}
	}

	.btn {
		position: relative;
	}

	@include media-breakpoint-up(md) {
		padding: 82px 15px;

		&.small {
			padding: 52px 15px;
		}

		h1 {
			padding: 0;
			margin-bottom: 10px;
		}

		.headline-2 {
			padding: 0;
			margin-bottom: 10px;
		}
	}
}

.promo-press {
	@extend .promo-guidance;
	 overflow: visible;
    margin: 2rem auto;
    background-color: lighten($color-aqua, 45%);

    &.small {
    	padding: 0 15px;
    }

	.content {
		position: relative;
		padding: 40px 0;
		z-index: 1;
	}
}

// Plain Promo - no background image or color. Name TBD
.promo-simple {
	margin: px-vw(35) px-vw(48) px-vw(60);
	padding: 0 px-vw(15) px-vw(63);
	border: 1px solid darken($color-grey-body, 5%);

	a {
		@include body-text();
		margin-bottom: 1rem;
		text-decoration: underline;

		&:hover, &:focus {
			color: $color-aqua-dark;
			text-decoration: underline;
		}
	}

	h1,
	p {
		margin-bottom: px-vw(15);
	}

	figure {
		margin: px-vw(-22) 0 px-vw(25);
	}

	picture {
		position: relative;
		display: block;
		width: px-vw(250);
		height: px-vw(250);
	}

	img {
		@include absolute(5);
		min-width: 101%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover; object-position: center;'; // object-fit-images polyfill
	}

	@include media-breakpoint-up(md) {

		margin: 0 0 2em 0;
		padding: 0 15px 20px;

		h1,
		p {
			margin-bottom: 5px;
		}

		figure {
			margin: -6px 0 10px;
		}

		picture {
			width: 128px;
			height: 128px;
		}
	}
}

.promo-border {
	padding: px-vw(36) px-vw(75);
	border: 1px solid #c7c7c7;
	text-align: center;

	.icon-svg {
		display: block;
		width: px-vw(64);
		height: px-vw(64);
		margin: 0 auto px-vw(29);
		vertical-align: -6px;
	}

	.headline-3 {
		margin: px-vw(7) 0 px-vw(29);
	}

	@include media-breakpoint-up(md) {
		padding: 36px 38px;
		text-align: left;

		.headline-3 {
			margin: 7px 0 0;
		}

		.icon-svg {
			float: left;
			width: 34px;
			height: 34px;
			margin: 0 25px 0 0;
		}
	}
}

.promo-assist {
	.icon-svg {
		@include absolute(2);
		width: px-vw(59);
		height: px-vw(59);
		margin-top: px-vw(-30);
	}

	@include media-breakpoint-up(sm) {
		.icon-svg {
			width: 59px;
			height: 59px;
			margin-top: -30px;
		}
	}

	@include media-breakpoint-up(md) {
		.icon-svg {
			width: 39px;
			height: 39px;
			margin-top: -20px;
		}
	}
}

.promo-price {
	max-width: 498px;
	width: 100%;
	margin: px-vw(100) auto 0;
	padding: 0 px-vw(35) px-vw(40);
	border: 1px solid #c7c7c7;
	border-bottom: 8px solid $color-aqua;
	text-align: center;

	.icon-svg {
		width: px-vw(202);
		height: px-vw(202);
		margin: px-vw(-100) auto px-vw(20);
		padding: 0 px-vw(15);
		background-color: $color-white;

		svg {
			padding: 0 18px;
		}
	}

	.headline-5 {
		margin-bottom: px-vw(50);

		&:last-child {
			margin-bottom: px-vw(15);
		}
	}

	@include media-breakpoint-up(md) {
		max-width: none;
		margin-top: 0;
		padding: 0 40px 20px;
		border-bottom: 6px solid $color-aqua;

		.icon-svg {
			width: 145px;
			height: 145px;
			margin: -72px auto 15px;
			padding: 0 15px;

			svg {
				padding: 0 18px;
			}
		}

		.headline-5 {
			margin-bottom: 33px;

			&:last-child {
				margin-bottom: 20px;
			}
		}
	}
}

.promo-grid {
	position: relative;
	margin: px-vw(110) 0 px-vw(100);
	padding: px-vw(252) px-vw(46) px-vw(62);
	border: 1px solid $color-grey-border;
	text-align: center;

	.col {
		position: static;
	}

	.image-1 {
		position: absolute;
		top: px-vw(-90);
		left: px-vw(120);

		picture {
			@include image-fit();
			width: px-vw(184);
			height: px-vw(184);
		}
	}

	.image-2 {
		position: absolute;
		top: px-vw(-53);
		left: px-vw(267);

		picture {
			@include image-fit();
			width: px-vw(248);
			height: px-vw(248);
		}
	}

	.headline-3 {
		figure {
			display: inline-block;
			max-width: px-vw(77);
			vertical-align: px-vw(4);
			margin-bottom: 0;
		}
	}


	p {
		margin-bottom: px-vw(30);
	}

	&.no-border {
		border: 0;
	}

	&.reversed {
		.image-1 {
			top: px-vw(-90);
			left: px-vw(120);
		}

		.image-2 {
			top: px-vw(-53);
			left: px-vw(267);
		}

		.content {
			margin-top: px-vw(204);
		}
	}

	@include media-breakpoint-up(md) {

		margin: 0 0 80px;
		padding: 35px 10px 15px;

		.image-1 {
			top: 100px;
			bottom: auto;
			left: 22px;

			picture {
				width: 105px;
				height: 105px;
			}
		}

		.image-2 {
			top: -26px;
			left: 65px;

			picture {
				width: 141px;
				height: 141px;
			}
		}

		.headline-3 {
			figure {
				max-width: 57px;
				vertical-align: 3px;
			}
		}

		p {
			margin-bottom: 4px;
		}

		.content {
			padding-left: 30px;
		}

		&.reversed {
			.image-1 {
				top: -34px;
				bottom: auto;
				left: 23px;
			}

			.image-2 {
				top: -16px;
				left: 85px;
			}

			.content {
				margin-top: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.image-1 {
			left: 42px;
		}
		.image-2 {
			left: 85px;
		}
	}

}
