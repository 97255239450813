.user-travel-log-listing {
	@extend .favorites-listing;
	border: 0;

	&:after {
		display: none;
	}

	.btn-overlay {
		margin: 0.25em;

		.icon-svg {
			svg {
				fill: $color-white;
			}
		}
	}

	figure {
		position: relative;
		width: 100%;
	}

	.content {
		padding: 0;
		flex-direction: row;
	}

	.headline-5 {
		width: 65%;
		padding-right: 20px;
	}

	.category {
		margin-top: 0;
	}

	@include media-breakpoint-down(sm) {
		.content {
			width: 100%;
		}
		.headline-5 {
			width: 100%;
		}
	}
}
