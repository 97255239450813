.rooms-listing {
	padding: px-vw(40px) px-vw(20px) px-vw(30px);
	border-bottom: 1px solid $color-grey-border;
	text-align: center;

	// todo: pull this out if carousel type continues
	.carousel-factory {
		margin: 0 auto 20px;
		width: 100%;

		&[data-arrows="sides"] .carousel {
			width: 100%;
		}

		.slick-prev,
		.slick-next {
			width: 30px;
			height: 30px;

			.icon-svg {
				width: 50%;
				height: 50%;
			}
		}
		.slick-prev {
			margin-left: 12px;
		}
		.slick-next {
			margin-right: 12px;
		}
		.carousel-indicators {
			margin-top: 30px;
		}
	}

	figure {
		position: relative;
		padding: 4px;
		margin: 0;
		overflow: hidden;
	}

	.content {
		position: relative;
    	padding: 0;
		vertical-align: top;

		ul.list-separated {
			margin: 25px 0 28px 0;
			font-weight: 500;
		}

		.lg-text {
			font-size: 32px;
		}

		.icon-svg {
			width: px-vw(25);
			height: px-vw(25);
			margin-right: px-vw(7);
			vertical-align: -3px;
		}
	}

	&:last-child {
		border: 0;
	}

	@include media-breakpoint-up(sm) {
		padding: 40px 20px 30px;
	}

	@include media-breakpoint-down(md) {

		.content {
			ul.list-separated {
				li {
					&:first-child {
						display: block;
					}
					&:nth-child(2) {
						&:before {
							content: none;
						}
					}
				}
			}
		}

	}

	@include media-breakpoint-up(md) {

		padding: px-vw(40, lg) 0 px-vw(30, lg);
		text-align: left;

		.carousel-factory {
			margin-bottom: 0;

			.carousel-indicators {
				margin-top: 0;
				height: 0;
			}

			.slick-prev,
			.slick-next {
				margin-top: 0;
			}
		}

		.content {
			padding: px-vw(20, lg) 0 0 px-vw(10, lg);

			.icon-svg {
				width: 12px;
				height: 12px;
				margin-right: 7px;
				vertical-align: -2px;
			}
		}

		&:last-child {
			margin-bottom: px-vw(30, lg);
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 40px 0 30px;

		.content {
			padding: 20px 0 0 10px;
		}

		&:last-child {
			margin-bottom: 30px;
		}
	}

	.roomRateFees {
		line-height: 1.25;
		margin-top: .75rem;
		font-size: 12px;
		color: #666;
	}
}
