
div .promocode-title {
	font-weight: 500 !important;
	font-size: .9rem;
}

div .promocode-code {
	border: 2px dashed #017294 !important;
	background-color: #f5fcfd;
	text-align: center;
}

div .promocode-terms-link {
	font-weight: 400 !important;
	font-size: .825rem;
}

div .promocode-date-text {
	font-weight: 400 !important;
	font-size: .825rem;
}

div .promocode-terms-link a {
	text-decoration: underline;
}