.zoom-enabled {
	.cc-window {
		@include media-breakpoint-down(md) {
			font-size: 50%;
		}
	}
}

.cc-window {
	&.cc-floating {
		max-width: none !important;
	}

	&.cc-bottom {
		bottom: 0 !important;
	}
}
