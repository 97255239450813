.calendar-flyout {
	position: relative;
	text-align: center;
	z-index: 9;

	.container-inset {
		padding-top: 0;
		z-index: auto;
	}

	//.calendar-footer {
	//	background: #f7f7f7;
	//	padding: 10px;
	//	margin: 0 -15px -28px;
	//}
	//
	//.display-date-range {
	//	font-weight: 300;
	//	margin-right: 20px;
	//
	//	strong {
	//		padding-right: 5px;
	//	}
	//}

	.container {
		position: static;
		padding-top: px-vw(33);
		padding-bottom: px-vw(40);

		.reverse-collapse {
			position: absolute;
			bottom: 10%;
			width: 100%;
			max-width: none;
			box-shadow: none;

			.collapse-header {
				.icon-svg {
					margin-right: 0;
				}
			}
		}

		.enter-dates-result {
			a {
				text-decoration: none;

				small {
					text-align: center;
				}
			}
		}

		.dates-unavailable-container {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: $aah-zindex-modal + 1;
			display: flex;
			align-items: center;
			justify-content: center;

			.dates-unavailable-dialog {
				position: relative;
				width: 250px;
				background: white;
				border: 1px solid $color-black-fake;
				text-align: center;
				padding: 24px;
				font-size: 14px;

				.dates-unavailable-msg {
					font-weight: bold;
					padding-bottom: 6px;
				}

				.btn-close {
					position: absolute;
					top: 0;
					right: 0;

					.icon-svg {
						height: 12px;
						width: 12px;
					}
				}
			}
		}

		&.bt-0 {
			border-top: 0;
		}
	}

	.headline-4 {
		margin-top: px-vw(20);
	}

	.icon-svg {
		width: px-vw(36);
		height: px-vw(36);
	}

	p {
		margin-bottom: px-vw(25);
	}

	.btn:not(.btn-round) {
		width: 85%;
		max-width: 498px;
		margin-left: 0;
	}

	.flatpickr-calendar {
		.rate {
			font-size: px-vw(14);
			color: $color-black-fake;
			letter-spacing: 0.2px;
			font-weight: 300;
			pointer-events: none;
		}

		.slash {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			border-bottom: 1px solid $color-black-fake;
			transform: rotate(-40deg);
			right: 0;
			margin: auto;
			height: 2px; // IE11 needs height or does not display.
		}
	}

	.avail-msg {
		font-weight: 500;
		padding: 10px;
		font-size: 14px;
		background-color: #c6c6c6;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.avail-closed-msg {
		padding: 10px;
		font-size: 14px;
		background-color: #c6c6c6;
		text-align: center;
	}

	@include media-breakpoint-down(sm) {
		.container {
			&.container-inset:before {
				left: 50%;
				width: 94%;
				transform: translateX(-50%);
			}

			.reverse-collapse {
				left: 0;
				transform: none;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		margin-bottom: 40px;
	}

	@include media-breakpoint-up(md) {

		.container {
			position: relative;
			margin-top: -22px;
			padding-top: 0;
			padding-bottom: 22px;

			.reverse-collapse {
				position: absolute;
				bottom: 90px;
				padding-top: 44px;
				padding-bottom: 44px;
				border-bottom: 1px solid $color-grey-border;
			}

			.left-picker + .flatpickr-calendar {
				float: right;
			}

			.right-picker + .flatpickr-calendar .flatpickr-prev-month,
			.left-picker + .flatpickr-calendar .flatpickr-next-month {
				visibility: hidden;
			}

			.flatpickr-prev-month,
			.flatpickr-next-month {
				svg {
					height: 14px;
				}
			}

			.enter-dates-result {
				a {
					display: inline-block;
					vertical-align: top;
					text-align: left;
				}
			}

		}

		.container-inset {
			padding-top: 33px;
			border-top: 0;
		}

		.icon-svg {
			width: 19px;
			height: 19px;
		}

		p {
			@include font-size(16);
			margin: 12px 0 0;
		}

		.btn:not(.btn-round) {
			width: auto;
		}

		.flatpickr-calendar {
			.rate {
				width: 100%;
				font-size: 10px;
				text-align: center;
			}
		}

		.flatpickr-key {
			column-count: 4;
		}

		.dayContainer, .flatpickr-weekdaycontainer {
			margin: 0 1em;
		}

	}
}
