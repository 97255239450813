.package-listing {
	
	width: px-vw(512);
	margin: 0 0 2.5em 0;
	text-align: left;
	
	figure {
		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
			padding-bottom: 105%;

			img {
				border-bottom: px-vw(10) solid $color-aqua;
			}
		}

	}

	@include media-breakpoint-up(sm) { 

		width: 512px;
		
		figure {
			picture {
				img {
					border-width: 10px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		width: auto;

		.btn-overlay {
			margin: 0 0.25em;
		}
	
		figure {
			picture {
				img {
					border-width: 6px;
				}
			}
		}
	}
}

