/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@function rem-calc($size) {
	$rem-size: $size / 16;
	@return #{$rem-size}rem;
}

@function headings($from: 1, $to: 6) {
	@if $from == $to {
		@return 'h#{$from}';
	} @else {
		@return 'h#{$from},' + headings($from+1, $to);
	}
}

// Converts 'px' to scaling 'vw'. Use for font/spacing, etc.
// @param {Number} $px - pixel unit value to be converted
// @param $breakpoint (optional) - used to determine amount
@function px-vw($px, $breakpoint: 'sm') {
	@return str-replace(calc((#{$px} / #{map-get($aah-grid-breakpoints, #{$breakpoint})} ) * 100), 'px', 'vw');
}

