.page-helpful {

	text-align: center;

	.container {
		padding: px-vw(42) 0 px-vw(40) 0;
		background-color: $color-grey-light;
	}

	.btn-tooltip {
		display: inline-block;
		margin-right: px-vw(14);
		padding: 0;
		vertical-align: text-bottom;

		.icon-svg {
			width: px-vw(26);
			height: px-vw(26);
		}

		&:hover {
			.icon-svg {
				fill: $color-aqua-dark;
			}
		}
	}

	label {
		display: inline-block;
		margin-bottom: 0.75em;
		line-height: 1.19em;
		font-weight: 500;
		font-size: px-vw(32);
	}

	.btn-container {
		display: block;

		.icon-svg {
			width: px-vw(26);
			height: px-vw(26);
			margin-right: 10px;
			vertical-align: middle;

			&:last-child {
				display: none;
			}
		}

		.btn {
			&.selected {
				.icon-svg:first-of-type {
					display: none;
				}

				.icon-svg:last-of-type {
					display: inline-block;
				}
			}
		}
	}

	
	@include media-breakpoint-up(sm) {

		.container {
			padding: 42px 0 40px 0;
		}

		.btn-tooltip {
			margin-right: 14px;

			.icon-svg {
				width: 26px;
				height: 26px;
			}
		}

		.btn-container {
			.icon-svg {
				width: 19px;
				height: 19px;
			}
		}

		label {
			font-size: rem-calc(32);
		}

	}

	@include media-breakpoint-up(md) {

		.container {
			padding: 13px 0 11px 0;
		}


		label {
			margin-right: 10px;
			line-height: 1.5em;
			font-size: rem-calc(16);
		}

		.btn-tooltip {
			margin-right: 10px;
			vertical-align: middle;

			.icon-svg {
				width: 25px;
				height: 25px;
			}
		}

		.btn-container {
			display: inline-block;

			.btn {
				//padding: 10px;
			}
		}
	}
}
