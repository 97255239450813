.grid-view {
	.hotel-card {
		@include clearfix;
		width: 100%;
		min-height: 100%;
		padding: 30px 0;
		border-bottom: 1px solid $color-grey-body;
		font-size: px-vw(26px);

		.image {
			display: block;
			float: left;
			width: 40%;
			padding-right: 20px;
		}

		h3 {
			padding: 0 30px 0 0;
			background-image: url('/img/icons/icon-heart.svg');
			background-repeat: no-repeat;
			background-position: top right;
			background-size: 20px;
			font-size: px-vw(32);

			&.favorite {
				background-image: url('/img/icons/icon-heart-filled.svg');
			}
		}

		p {
			margin-bottom: 10px;
		}

		.details {
			float: left;
			width: 60%;
		}

		.tag {
			display: block;
			clear: both;
			float: left;
			margin: 0 10px 10px 0;
			padding: 3px 10px;
			border: 2px solid $color-grey-light;
			border-radius: 2em;

			&.stylish {
				border-color: $color-teal;
			}

			&.family-friendly {
				border-color: $color-yellow;
			}

			&.distinctive {
				border-color: $color-green;
			}

			&.budget {
				border-color: $color-lilac;
			}
		}

		footer {
			clear: both;
		}

		.trip-advisor {
			@include clearfix;
			clear: left;
			float: left;

			img {
				float: left;
				width: 40px;
				margin-right: 10px;
			}

			.rating {
				float: left;
			}
		}

		.price {
			float: right;
		}

		.rating {
			position: relative;
			width: 90px;
			height: 18px;
			overflow: hidden;

			.circles,
			.dots {
				position: absolute;
				width: 90px;
				height: 18px;
			}

			.circles {

			}

			.dots {
				margin-left: 5px;
			}

			.circle {
				display: block;
				float: left;
				width: 18px;
				height: 18px;
				border: 2px solid $color-teal;
				border-radius: 100%;
			}

			.dot {
				display: block;
				float: left;
				width: 8px;
				height: 8px;
				margin: 5px 10px 0 0;
				border-radius: 100%;
				background-color: $color-teal;
			}

			.slider {
				display: block;
				right: 0;
				width: 0;
				height: 18px;


				background-color: $color-white;
			}

			&.stars-05 .slider {
				width: 81px;
			}

			&.stars-1 .slider {
				width: 72px;
			}

			&.stars-15 .slider {
				width: 63px;
			}

			&.stars-2 .slider {
				width: 54px;
			}

			&.stars-25 .slider {
				width: 45px;
			}

			&.stars-3 .slider {
				width: 36px;
			}

			&.stars-35 .slider {
				width: 27px;
			}

			&.stars-4 .slider {
				width: 18px;
			}

			&.stars-45 .slider {
				width: 9px;
			}

			&.stars-5 .slider {
				width: 0;
			}
		}

	}
	@include media-breakpoint-up(sm) {
		.hotel-card {
			font-size: 26px;

			h3 {
				min-height: 20px;
				font-size: 32px;
			}
		}
	}
	@include media-breakpoint-up(md) {

		.hotel-card {
			margin-bottom: 20px;
			font-size: 14px;

			.image {
				width: 50%;
			}

			.details {
				width: 50%;
			}

			h3 {
				font-size: 16px;
			}

			.tag {
				font-size: 12px;
			}

			footer {
				position: absolute;
				bottom: 20px;
				width: 50%;
			}
		}
	}
}
