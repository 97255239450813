.vacation-inspiration-list {

	padding: 0 0 px-vw(40) 0;
	text-align: center;

	.btn-more {
		margin-top: px-vw(60);
	}

	//todo: not ideal
	.container {
		.row {
			.col-md-4:last-child {
				.vacation-inspiration-listing {
					border: 0;
				}

			}
		}
	}


	@include media-breakpoint-up(sm) {

		padding: 0 0 40px 0;

		.btn-more {
			margin-top: 60px;
		}

	}

	@include media-breakpoint-up(md) {

		.btn-more {
			margin-top: 40px;
		}

	}

}
