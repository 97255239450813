.flatpickr-calendar {
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	visibility: hidden;
	text-align: center;
	padding: 0;
	animation: none;
	direction: ltr;
	font-size: 14px;
	line-height: 24px;
	border-radius: 0;
	position: absolute;
	width: 100%;
	box-sizing: border-box;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	background-color: $color-white;
	//box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0,0,0,0.08);
}
.flatpickr-months {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
	opacity: 1;
	visibility: visible;
	overflow: visible;
	//max-height: 640px;
	max-height: none;
}
.flatpickr-calendar.open {
	display: inline-block;
	z-index: 99999;
}
.flatpickr-calendar.animate.open {
	animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
	display: block;
	position: relative;
	top: 2px;
}
.flatpickr-calendar.static {
	position: absolute;
	top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
	z-index: 999;
	display: block;
}
.flatpickr-calendar.hasWeeks {
	width: auto;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
	border-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
	border-left: 0;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
	height: 40px;
	border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
	height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
	position: absolute;
	display: block;
	pointer-events: none;
	border: solid transparent;
	content: '';
	height: 0;
	width: 0;
	left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
	left: auto;
	right: 22px;
}
//.flatpickr-calendar:before {
//	border-width: 5px;
//	margin: 0 -5px;
//}
//.flatpickr-calendar:after {
//	border-width: 4px;
//	margin: 0 -4px;
//}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
	//bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
	//border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
	//border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
	//top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
	//border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
	//border-top-color: #fff;
}
.flatpickr-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
}
.flatpickr-month {
	margin: 0 0 5px 0;
	height: 40px;
	line-height: 1;
	text-align: center;
	position: relative;
	user-select: none;
	overflow: hidden;
}
.flatpickr-prev-month,
.flatpickr-next-month {
	text-decoration: none;
	cursor: pointer;
	position: absolute;
	top: 0;
	line-height: 16px;
	height: 35px;
	padding: 10px calc(3.57% - 1.5px);
	z-index: 3;
}
.flatpickr-prev-month i,
.flatpickr-next-month i {
	position: relative;
}
.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
	/*
           /*rtl:begin:ignore*/
	/*
           */
	left: 0;
	/*
           /*rtl:end:ignore*/
	/*
           */
}
/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
	/*
           /*rtl:begin:ignore*/
	/*
           */
	right: 0;
	/*
           /*rtl:end:ignore*/
	/*
           */
}
/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
	color: #959ea9;
}
.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
	fill: $color-aqua-dark;
}
.flatpickr-prev-month svg,
.flatpickr-next-month svg {
	width: 20px;
	margin: 8px 0 0 0;
}
.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
	transition: fill 0.1s;
	fill: inherit;
}
.flatpickr-prev-month {
	position: relative;
	top: -1px;
	left: 0;
	right: auto;

	~ .flatpickr-month {
		width: 100%;
	}
}
.flatpickr-next-month {
	position: relative;
	top: -1px;
	left: auto;
	right: 0;
}
.flatpickr-next-month, .flatpickr-prev-month {
	padding: 11px 19px;

	svg {
		margin: 0;
	}

	&.flatpickr-disabled {
		visibility: hidden;
	}
}
.numInputWrapper {
	position: relative;
	height: auto;

	input, span {
		display: inline-block;
	}
	input {
		width: 100%;
	}
	span {
		position: absolute;
		right: 0;
		width: 14px;
		padding: 0 4px 0 2px;
		height: 10px;
		line-height: 50%;
		cursor: pointer;
		border: 1px solid rgba(57,57,57,0.05);
		box-sizing: border-box;

		&:hover {
			background: rgba(0,0,0,0.1);
		}
		&:active {
			background: rgba(0,0,0,0.2);
		}
		&:after {
			display: block;
			content: "";
			position: absolute;
			top: 33%;
		}
		&.arrowUp {
			top: 5px;
			border-bottom: 0;

			&:after {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 4px solid rgba(57,57,57,0.6);
			}
		}
		&.arrowDown {
			top: 12px;
			&:after {
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid rgba(57,57,57,0.6);
			}
		}
		svg {
			width: inherit;
			height: auto;
			path { fill: rgba(0,0,0,0.5); }
		}
	}

	&:hover {
		background: rgba(0,0,0,0.05);
		span {
			opacity: 1;
			background: rgb(227, 227, 227);
		}
	}
}

.flatpickr-current-month {
	@include text(28, 48, 0.3);
	position: absolute;
	top: 50%;
	left: 12.5%;
	display: flex;
	font-weight: 500;
	margin: 0;
	color: inherit;
	width: 75%;
	padding: 0;
	height: 30px;
	text-align: center;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);
}
.flatpickr-current-month.slideLeft {
	transform: translate3d(-100%, 0px, 0px);
	animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideLeftNew {
	transform: translate3d(100%, 0px, 0px);
	animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideRight {
	transform: translate3d(100%, 0px, 0px);
	animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month.slideRightNew {
	transform: translate3d(0, 0, 0px);
	animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-current-month span.cur-month {
	font-family: inherit;
	line-height: 42px;
	color: inherit;
	display: inline-block;
	padding: 0;
}
.flatpickr-current-month .numInputWrapper {
	width: 6ch;
	width: 7ch\0;
	display: inline-block;
	margin-left: 7px;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
	border-bottom-color: rgba(0,0,0,0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
	border-top-color: rgba(0,0,0,0.9);
}
.flatpickr-current-month input.cur-year {
	background: transparent;
	box-sizing: border-box;
	color: inherit;
	cursor: default;
	padding: 0;
	display: inline-block;
	font-size: 14px;
	font-family: inherit;
	//font-weight: 300;
	line-height: inherit;
	height: 24px;
	border: 0;
	border-radius: 0;
	vertical-align: initial;
	-moz-appearance:textfield;
	-webkit-appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
	//outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
	font-size: 100%;
	color: rgba(0,0,0,0.5);
	background: transparent;
	pointer-events: none;
}
.flatpickr-weekdaycontainer {
	width: 100%;
	display: flex;
}
.flatpickr-weekdays {
	background: transparent;
	text-align: center;
	overflow: hidden;
	width: 100%;
	height: 56px;
	margin-bottom: 4px;
	padding: 0;
	display: flex;
	align-items: center;

	//display: none; // hides weekdays
}
span.flatpickr-weekday {
	font-size: px-vw(26);
	font-weight: 500;
	margin: 0;
	cursor: default;
	background: transparent;
	line-height: 1;
	text-align: center;
	display: block;
	flex: 1;
}
.dayContainer,
.flatpickr-weeks {
	padding: 1px 0 0 0;
}
.flatpickr-days {
	position: relative;
	overflow: hidden;
	display: flex;
	width: 100%;
}
.flatpickr-days:focus {
	//outline: 0;
}
.dayContainer {
	padding: 1px;
	outline: 0;
	text-align: left;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	opacity: 1;

	.min-stay,
	.flatpickr-day.prevMonthDay.min-stay,
	.flatpickr-day.nextMonthDay.min-stay {
		background-color: #0080A3;
		color: $color-white !important;

		.rate {
			color: inherit;
		}
	}

	.unavailable,
	.flatpickr-day.prevMonthDay.min-stay.unavailable,
	.flatpickr-day.nextMonthDay.min-stay.unavailable {
		background-color: #e7e7e7;
		color: $color-black-fake !important;
	}
}
.flatpickr-calendar.animate .dayContainer.slideLeft {
	animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
	transform: translate3d(-100%, 0px, 0px);
}
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
	animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.animate .dayContainer.slideRight {
	animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
	transform: translate3d(100%, 0px, 0px);
}
.flatpickr-calendar.animate .dayContainer.slideRightNew {
	animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-day {
	// @include text(28, 32, 0.7);
	font-weight: 500;
	line-height: normal;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1;
	margin: 0.125em 0;
	padding: 0;
	background: none;
	border: 0;
	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
	width: 14.2857143%;
	flex-basis: 14.2857143%;
	max-width: none;
	height: auto;
	text-align: center;
	z-index: 1;

	/*&.nextMonthDay.hidden,
	&.prevMonthDay.hidden {
		visibility: hidden;
	}*/

	&.nextMonthDay,
	&.prevMonthDay {
		visibility: hidden;
	}
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
	cursor: pointer;
	//outline: 0;
	//background: #e6e6e6;
	//border-color: #e6e6e6;
	//border: 0;
}
.flatpickr-day.today {
	// styling for 'today' if needed
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {

}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.inRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
	background-color: $color-blue-light;
	color: $color-black-fake !important;
	box-shadow: none;
	border-color: transparent;
}
.flatpickr-day.inRange {
	background-color: transparentize($color-blue-light, 0.5);
	border-radius: 0;
}
.flatpickr-day.startRange + .flatpickr-day.inRange,
.flatpickr-day.startRange + .flatpickr-day.endRange,
.flatpickr-day.endRange {
	&:before {
		content: '';
		background-color: transparentize($color-blue-light, 0.5);
		position: absolute;
		width: 50%;
		height: 100%;
		z-index: -1;
	}
}

.flatpickr-day.endRange {
	&:before {
		left: 0;
	}
}

.flatpickr-day.startRange + .flatpickr-day.inRange {
	&:before {
		left: -50%;
	}
}

.flatpickr-day.startRange, .flatpickr-day.endRange {
	z-index: 2;
}

// 2-day range
.flatpickr-day.startRange + .flatpickr-day.endRange {
	z-index: 1;

	&:before {
		width: 100%;
		left: -50%;
	}
}

// single day selected
.flatpickr-day.startRange.flatpickr-day.endRange {
	&:before {
		content: none;
	}
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover/*,
.flatpickr-day[tabindex="-1"]*/ {
	pointer-events: none;
}
.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.flatpickr-disabled/*,
.flatpickr-day[tabindex="-1"]*/ {
	color: #707070 !important;
	background: transparent;
	border-color: transparent;
	cursor: default;
}
.flatpickr-disabled.unavailable,
.flatpickr-day.flatpickr-disabled {
	.slash,
	.rate {
		display: none;
	}
	&.min-stay {
		background-color: white;
		color: rgba(57,57,57,.3);
	}
}
.flatpickr-day.week.selected {
	border-radius: 0;
	box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.rangeMode .flatpickr-day {
	//margin-top: 1px;
}
.flatpickr-weekwrapper {
	display: inline-block;
	float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
	padding: 0 12px;
	box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
	float: none;
	width: 100%;
	line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day {
	display: block;
	width: 100%;
	max-width: none;
}
.flatpickr-innerContainer {
	display: flex;
	box-sizing: border-box;
	overflow: hidden;
}
.flatpickr-rContainer {
	display: inline-block;
	padding: 0;
	box-sizing: border-box;
	width: 100%;
}
.flatpickr-time {
	display: flex;
	text-align: center;
	//outline: 0;
	height: 0;
	line-height: 40px;
	max-height: 40px;
	box-sizing: border-box;
	overflow: hidden;
}
.flatpickr-time:after {
	content: "";
	display: table;
	clear: both;
}
.flatpickr-time .numInputWrapper {
	flex: 1;
	width: 40%;
	height: 40px;
	float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
	border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
	border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
	width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
	width: 49%;
}
.flatpickr-time input {
	background: transparent;
	box-shadow: none;
	border: 0;
	border-radius: 0;
	text-align: center;
	margin: 0;
	padding: 0;
	height: inherit;
	line-height: inherit;
	cursor: pointer;
	color: #393939;
	font-size: 14px;
	position: relative;
	box-sizing: border-box;
}
.flatpickr-time input.flatpickr-hour {
	font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
	font-weight: 400;
}
.flatpickr-time input:focus {
	//outline: 0;
	border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
	height: inherit;
	display: inline-block;
	float: left;
	line-height: inherit;
	color: #393939;
	font-weight: bold;
	width: 2%;
	user-select: none;
	align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
	//outline: 0;
	width: 18%;
	cursor: pointer;
	text-align: center;
	font-weight: 400;
}
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
	background: #f0f0f0;
}

.flatpickr-input[readonly] {
	cursor: pointer;
	opacity: 0;
	height: 0;
	width: 0;
	left: -99999px;
	position: absolute;
}

.flatpickr-monthDropdown-months {
	background: none;
	font-size: 14px;
	border: 0;
	margin-top: 0;
}

ul.flatpickr-key {
	column-count: 2;
	list-style: none;
	margin: 0.5em 1em;
	padding: 0;

	li {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		margin: 0 0.5em 0.5em 0.5em;

		.key-marker {
			width: 1em;
			height: 1em;
			margin: 0.25em;
			display: inline-block;
			border-radius: 50%;
			flex-shrink: 0;
			aspect-ratio: 1;
		}

		.key-text {
			font-size: 0.75em;
			line-height: 1.25em;
			margin: 0.25em;
		}

		&.key-selected {
			.key-marker {
				background-color: $color-blue-light;
			}
		}

		&.key-available {
			.key-marker {
				border: 1px solid #707070;
			}
		}

		&.key-not-available {
			.key-marker {
				background-color: #e7e7e7;
				border: 1px solid #e7e7e7;
				position: relative;
			}
		}

		&.key-minstay {
			.key-marker {
				background-color: #0080a2;
			}
		}
	}
}



@include media-breakpoint-up(md) {

	ul.flatpickr-key {
		margin: 0.5em 3.5em;

		li {
			.key-text {
				font-size: 0.85em;
			}
		}
	}

	.flatpickr-calendar {
		min-width: 400px;
		padding: 1em 1.5em;
	}
	.flatpickr-calendar.open,
	.flatpickr-calendar.inline {
		max-height: 640px;
	}
	.flatpickr-month {
		height: 32px;
	}

	.flatpickr-current-month {
		padding: 0;
		font-size: 14px;
		line-height: 1.25em;
	}
	.flatpickr-current-month span.cur-month {
		line-height: 34px;
	}
	.flatpickr-prev-month svg,
	.flatpickr-next-month svg {
		width: 14px;
		margin: 0;
	}
	.flatpickr-weekdays {
		height: 30px;
	}
	span.flatpickr-weekday {
		font-size: 12px;
	}

	.flatpickr-day {
		//max-width: 48px;
		//max-height: 48px;
		font-size: 14px;
	}

	.flatpickr-day.selected.startRange,
	.flatpickr-day.startRange.startRange,
	.flatpickr-day.endRange.startRange {
		&:after {
			font-size: 8px;
		}
	}
	.flatpickr-day.selected.endRange,
	.flatpickr-day.startRange.endRange,
	.flatpickr-day.endRange.endRange {
		&:after {
			font-size: 8px;
		}
	}

	.numInputWrapper {
		span {
			&.arrowUp {
				top: 3px;
			}
			&.arrowDown {
				top: 10px;
			}
		}
	}

}

.user-is-tabbing {
	.flatpickr-day {
		&:focus,
		&:active {
			outline: 1px dotted #212121;
			outline: auto 5px -webkit-focus-ring-color !important;
		}
	}
}


@keyframes fpFadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fpSlideLeft {
	from {
		transform: translate3d(0px, 0px, 0px);
	}
	to {
		transform: translate3d(-100%, 0px, 0px);
	}
}

@keyframes fpSlideLeftNew {
	from {
		transform: translate3d(100%, 0px, 0px);
	}
	to {
		transform: translate3d(0px, 0px, 0px);
	}
}

@keyframes fpSlideRight {
	from {
		transform: translate3d(0, 0, 0px);
	}
	to {
		transform: translate3d(100%, 0px, 0px);
	}
}

@keyframes fpSlideRightNew {
	from {
		transform: translate3d(-100%, 0, 0px);
	}
	to {
		transform: translate3d(0, 0, 0px);
	}
}

@keyframes fpFadeOut {
	from { opacity: 1; } to { opacity: 0; }
}

@keyframes fpFadeIn {
	from { opacity: 0; } to { opacity: 1; }
}


//Updated FLatpickr
.modal .modal-dialog.calendar-flyout {
	.container .left-picker+.flatpickr-calendar .flatpickr-next-month {
		visibility: visible !important;
	}
	.flatpickr-calendar {
		width: 100% !important;
	}
	.flatpickr-wrapper {
		width: 100%;
		padding-top: 20px;
	}
	.flatpickr-month {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.flatpickr-current-month {
		padding: 0;
	}
	.flatpickr-weekdays {
		width: 100%;
		display: flex;
		justify-content: space-between;
		max-width: none;
		border: 0;
	}
	.flatpickr-weekdaycontainer {
		max-width: none;
		border-bottom: 1px solid #e1e1e1;
		padding: 11px 0;
	}
	.flatpickr-days {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		width: 100% !important;
		margin-bottom: 30px;
		padding: 0;
	}
	.dayContainer {
		max-width: none;
	}

	.flatpickr-prev-month {
		~ .flatpickr-month {
			+ .flatpickr-month {
				left: auto;
				right: 0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.flatpickr-wrapper {
			padding: 20px 14px 14px;
		}
	}
}
