.faq {

	.faq-search {
		@extend .search-results;

		.search-label {
			@include body-text();
			display: block;
			margin: 0;
			text-align: center;

			span:nth-child(2) {
				display: none;
			}
		}

		.form-group {
			position: relative;
			.btn-search {
				top: auto;
				bottom: px-vw(-4);
			}
		}

		.form-actions {
			@include absolute(9);
			display: inline-block;
			width: 100px;
			height: 100%;
			z-index: 2200;

			button {
				@include absolute(9);
				bottom: px-vw(22);
				width: px-vw(48);
				height: px-vw(48);
				vertical-align: middle;

				&.btn-search {
					right: 0;
					pointer-events: none;

					.icon-svg {
						svg { fill: $color-black; }
					}
				}

				&.btn-close {
					display: none;
					right: 0;
					padding: 2px;
					border-radius: 100%;
					background-color: $color-aqua-dark;

					.icon-svg {
						@include absolute(5);
						width: 40%;
						height: 40%;
						svg { fill: $color-white; }
					}
				}
			}

		}

	}

	.faq-results {
		border-top: 1px solid $color-grey-border;

		.nav {
			&.fixed-top {
				position: relative;
				top: 0;
				z-index: $aah-zindex-search;
			}

			.nav-item {
				a {
					&.active {
						color: $color-aqua-dark;
						font-weight: 500;
					}
				}
			}
		}

		.nav-tabs {
			margin-bottom: px-vw(50);
			border-top: 0;
		}

		.faq-accordion {
			margin-bottom: 60px;

			.headline-4 {
				margin-bottom: px-vw(30);

				&:not(:first-of-type) {
					margin-top: px-vw(45);
				}
			}
		}

		.card {
			margin-top: -1px;
			margin-bottom: 0;
			border-left-width: 0;
			border-right-width: 0;
			text-align: left;

			.card-header {
				padding: 20px 90px 20px 0;
				background-color: transparent;
				border: 0;

				.headline {
					margin: 0;
				}
			}

			.card-controls {
				@include absolute(3);
				margin: 10px 0;

				button {
					@include body-text();
					position: relative;
					width: 48px;
					height: 48px;
					margin-bottom: 1rem;
					padding-right: 0;
					vertical-align: middle;

					.icon-svg {
						width: px-vw(24);
						height: px-vw(24);
						margin: -2px 8px 0 0;
						transform: rotate(0deg);
						transition: transform 0.3s;

						svg {
							fill: $color-black;
						}
					}

					&.collapsed {
						.icon-svg {
							transform: rotate(180deg);
						}
					}
				}
			}

			.card-block {
				@include body-text();
				margin-bottom: 1rem;
				padding: 0;
				text-align: left;
			}
		}

		.no-results {
			display: none;
			padding: 100px 50px;
			border: 1px solid $color-grey-border;
			text-align: center;
		}

		footer {
			@include body-text();
			margin-bottom: 1rem;
		}

	}

	@include media-breakpoint-up(sm) {

		.faq-search {
			.form-actions {

				button {
					bottom: 22px;
					width: 48px;
					height: 48px;
				}
			}
		}

	}


	@include media-breakpoint-up(md) {

		.faq-search {

			.search-label {
				text-align: left;
			}

			.form-group {
				.btn-search {
					bottom: -8px;
				}
			}

			.form-actions {
				button {
					bottom: 10px;
					width: 32px;
					height: 32px;
				}
			}

		}

		.faq-results {
			padding-top: 40px;

			.nav {
				&.fixed-top {
					position: fixed;
					top: 72px;
					right: auto;
					left: auto;
				}
			}

			.nav-tabs {
				display: block;
				max-height: none;
				margin-bottom: 0;
				border: 0;

				.nav-item {
					text-align: left;
				}

				.nav-link {
					padding: 5px 1em;
				}

				.active .nav-link {
					color: $color-aqua-dark;

					&:before {
						display: none;
					}
				}
			}

			.faq-accordion {
				.headline-4 {
					margin-bottom: 15px;

					&:not(:first-of-type) {
						margin-top: 40px;
					}
				}
			}

			.card {
				.card-block {
					padding-right: 118px;
				}

				.card-header {
					.headline {
						@include headline(6);
					}
				}

				.card-controls {
					button {
						text-align: right;

						.icon-svg {
							width: 16px;
							height: 16px;
						}
					}
				}
			}
		}

	}

}
