.property-map-slider {
	article {
		position: relative;
		padding-bottom: px-vw(35);
		text-align: center;
		overflow: hidden;

		figure {
			@include image-fit();
			@include absolute();
			width: 100%;
			height: 100%;
			overflow: hidden;

			picture {
				height: 100%;
			}
		}

		.content {
			width: 100%;
			text-align: center;

			.headline-3 {
				width: 80%;
				margin: 0 auto;
				text-align: center;
			}
		}
	}

	.layout-1 {
		.image-1 {
			display: none;
			top: px-vw(40);
			left: px-vw(31);
			width: px-vw(296);
			height: px-vw(296);
		}

		.image-2 {
			display: none;
			top: px-vw(10);
			left: px-vw(342);
			width: px-vw(192);
			height: px-vw(192);
		}

		.image-3 {
			display: none;
			top: px-vw(218);
			left: px-vw(342);
			width: px-vw(192);
			height: px-vw(192);
		}
	}

	.figure-container {
		position: relative;
		height: px-vw(410);
		margin-top: 1rem;

		.image-1,
		.image-2,
		.image-3 {
			display: block;
		}
	}

	.slick-prev {
		left: 0;
	}

	.slick-next {
		right: 0;
	}

	.slick-prev,
	.slick-next {
		top: px-vw(78);
	}

	@include media-breakpoint-up(md) {
		
		.carousel-factory  {
			padding: 0;
		}

		h1 {
			margin-bottom: 0.2rem;
		}

		.figure-container {
			display: block;
		}

		.layout-1 {
			height: px-vw(480, lg);
			padding: 0 60px;


			.content {
				position: absolute;
				top: px-vw(20px, lg);
				left: px-vw(378px, lg);
				width: px-vw(307px, lg);
				height: px-vw(186px, lg);
				margin-top: 0;
				padding-top: 20px;
			}

			.image-1 {
				display: block;
				top: px-vw(32px, lg);
				left: px-vw(55px, lg);
				width: px-vw(306px, lg);
				height: px-vw(306px, lg);
			}

			.image-2 {
				display: block;
				top: px-vw(0, lg);
				left: px-vw(0, lg);
				width: px-vw(143px, lg);
				height: px-vw(143px, lg);
			}

			.image-3 {
				display: block;
				top: px-vw(0, lg);
				left: px-vw(161px, lg);
				width: px-vw(143px, lg);
				height: px-vw(143px, lg);
			}

			.image-2,
			.image-3 {
				display: none;
			}

			.figure-container {
				.image-1 {
					display: none;
				}

				.image-2,
				.image-3 {
					display: block;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		
		.layout-1 {
			height: 470px;

			.content {
				top: 20px;
				left: 378px;
				width: 307px;
				height: 186px;
			}

			.image-1 {
				top: 32px;
				left: 55px;
				width: 306px;
				height: 306px;
			}

			.image-2 {
				top: 0;
				left: 0;
				width: 143px;
				height: 143px;
			}

			.image-3 {
				top: 0;
				left: 161px;
				width: 143px;
				height: 143px;
			}
		}
	}
}
