.reservation-promos {
	.card {
		display: block;
		margin-bottom: px-vw(20);
		padding: 20px 34px;
		min-height: px-vw(160);
		border: 0;
		background-color: $color-grey-light;
	}

	.collapse,
	.collapsing {
		position: absolute;
		left: 0;
		z-index: 1;
	}

	.card-header {
		padding: 0;
		text-align: left;
		background: none;
		border-bottom: 0;

		a[data-toggle] {
			display: block;
			position: relative;
			padding-right: px-vw(50);

			&:hover {
				color: $color-black;
			}

			&.collapsed {
				.icon-svg.hidden-md-up {
					transform: rotate(0) translateY(-50%);
				}
			}

			&:not(.collapsed) {
				.sub-headline span {
					display: none;
				}
			}

			.icon-svg.hidden-md-up {
				@include absolute(6);
				width: px-vw(24);
				height: px-vw(24);
				transform: rotate(-180deg) translateY(50%);
				transition: transform 0.5s;
			}
		}
	}

	.card-block {
		margin: px-vw(20) 0;
		padding: 0 34px 40px;
		background-color: $color-grey-light;
	}


	.headline-5 {
		margin-bottom: 0;

		.icon-svg {
			margin-right: 10px;
		}
	}

	.sub-headline {
		@include body-text();
		margin-bottom: 1rem;
		&.hidden-md-up {
			display: block;
			margin-bottom: px-vw(-20);
		}
	}

	p {
		margin-bottom: px-vw(30);
	}

	@include media-breakpoint-up(sm) {
		.card {
			min-height: 160px;
		}
	}

	@include media-breakpoint-up(md) {
		.card {
			height: 100%;
			padding: 42px 50px;
			margin-bottom: 0;
		}

		.collapse,
		.collapsing {
			display: block;
			position: relative;
		}

		.card-header {
			pointer-events: none;
			text-align: center;

			a[data-toggle] {
				padding-right: 0;
			}
		}

		.card-block {
			margin: 20px 0 0;
			padding: 0;
		}

		.sub-headline {
			@include headline(6);
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 20px;
		}
	}
}
