.hotel-selector {

	position: relative;
	margin-bottom: px-vw(30);

	.icon-svg {
		display: inline-block;
		width: 1em;
		height: 1em;

		&.icon-badge {
			display: block;
			width: px-vw(158);
			height: px-vw(158);
			margin: 0 auto 1em;
			background-color: $color-white;
			border-radius: 100%;
		}
	}

	.form-group {
		display: inline-block;
		margin: px-vw(64) 0;

		label {
			display: inline-block;
			vertical-align: -1px;
			font-size: px-vw(32);
			font-weight: 500;
		}

		.dropdown-toggle {
			position: relative;
			display: block;
			width: px-vw(360);
			margin: 0 auto 1em;
			padding: 0;
			box-shadow: none;
			font-size: px-vw(32);
			font-weight: 300;
			border-bottom: px-vw(2) solid transparent;
			text-align: center;

			.icon-svg {
				position: absolute;
				top: 0.5em;
				right: 0;
				width: 0.75em;
				height: 0.75em;
				transform: rotate(180deg);
				transition: transform 0.3s;
			}

			&.collapsed {
				border-bottom: px-vw(2) solid $color-grey-light;

				.icon-svg {
					transform: rotate(0deg);
				}
			}

			&:after {
				display: none;
			}
		}

		.btn {
			
			background-color: $color-magenta;

			&.disabled, &:disabled, &[aria-disabled="true"] {
				@include button("disabled");
			}
		}
	}

	.dropdown-panel {
		position: relative;
		width: 100%;
		margin-top: px-vw(-75);
		padding: px-vw(20) px-vw(40) px-vw(40) px-vw(40);
		background-color: $color-white;
		border-width: 0;
		z-index: 101;

		.dropdown-panel-group {
			padding-top: px-vw(20);
			padding-bottom: px-vw(15);
			border-bottom: 1px solid $color-grey-body;

			picture {
				@include image-fit();
				display: inline-block;
				width: px-vw(133);
				height: px-vw(133);

				img {
					opacity: 1;
				}
			}

			.dropdown-panel-group-options {

				padding-left: 1em;

				h2 {
					@include headline(5);
					margin: 0.5em 0;
				}

				ul {
					margin: 0;
					padding: 0;
					list-style: none;
					font-weight: 300;

					li {
						position: relative;
						display: inline-block;
						margin-right: 8px;
						padding: 5px 12px 5px 0;
						line-height: 1.2em;

						&:after {
							position: absolute;
							display: block;
							top: 10px;
							right: 0;
							width: 1px;
							height: 14px;
							content: '';
							background-color: $color-grey-body;
						}

						&:last-child {
							margin-right: 0;
							padding-right: 0;

							&:after {
								display: none;
							}
						}
					}
				}
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}


	@include media-breakpoint-up(sm) {

		margin-bottom: 30px;

		.icon-svg {
			&.icon-badge {
				width: 158px;
				height: 158px;
			}
		}

		.form-group {

			margin: 64px 0;

			label {
				font-size: 32px;
			}

			.dropdown-toggle {
				min-width: 260px;
				font-size: 32px;
				border-width: 2px;

				&.collapsed {
					border-width: 2px;
				}
			}
		}

		.dropdown-panel {
			margin-top: -75px;
			padding: 20px 30px 40px 30px;

			.dropdown-panel-group {
				padding-top: 20px;
				padding-bottom: 15px;
			}
		}
	}

	@include media-breakpoint-up(md) {


		.icon-svg {
			display: inline-block;

			&.icon-badge {
				display: inline-block;
				width: px-vw(96, 'lg');
				height: px-vw(96, 'lg');
				margin: 0 px-vw(32, 'lg') 0 0;
			}
		}

		.form-group {
			min-width: 540px;
			margin: 0;

			label {
				font-size: 20px;
			}

			.dropdown-toggle {
				display: inline-block;
				width: 100%;
				max-width: 260px;
				margin: 0 0.75em 0 0.75em;
				font-size: 20px;
				text-align: left;
			}

			.btn {
				width: auto;
				margin-left: 15px;
			}
		}

		.dropdown-panel {

			margin: 0;
			padding: 0 40px 0;

			border-top: 1px solid $color-grey-body;

			.container {
				padding-top: 20px;
				padding-bottom: 20px;
			}

			.dropdown-panel-group {
				border: 0;

				picture {
					width: px-vw(136, 'lg');
					height: px-vw(136, 'lg');
				}

				.dropdown-panel-group-options {

					padding-left: 0.5em;

					ul {
						li {
							display: block;
							margin: 4px 0;

							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {

		.icon-svg {
			&.icon-badge {
				display: inline-block;
				width: 96px;
				height: 96px;
				margin: 0 32px 0 0;
			}
		}

		.dropdown-panel {
			.dropdown-panel-group {
				picture {
					width: 136px;
					height: 136px;
				}
			}
		}
	}
}
