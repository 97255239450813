.property-toggle {
	
	text-align: center;

	ul.list-inline {
		li {
			a {
				text-transform: lowercase;

				.icon-svg svg {
					fill: darken($color-grey-border, 5%);
				}

				picture {
					opacity: 0.3;
				}

				&.active {
					font-weight: 500;
					color: $color-black;

					.icon-svg svg {
						fill: $color-black-fake;
					}

					picture {
						opacity: 1;
					}
				}
			}
		}
	}
}
