ul.utility-nav {

	margin: px-vw(40) 0;
	text-align: center;
	z-index: 3;

	li {
		display: inline-block;
		border: 0;

		&:not(.d-none) + li:before {
			display: inline-block;
			width: 1px;
			height: 1.5em;
			margin: 0 1em 0 0.25em;
			content: '';
			background-color: $color-black-fake;
			vertical-align: text-bottom;
			opacity: 0.46;
		}

		a {
			display: inline-block;
			margin: 0.125rem;
			color: $color-black-fake;
			letter-spacing: 0.1px;
			font-weight: 300;
			font-size: px-vw(28);

			&:hover, &:focus {
				text-decoration: none;
				color: $color-aqua-dark;

				.icon-svg {
					svg {
						fill: $color-aqua-dark;
					}
				}
			}

			.icon-svg {
				display: inline-block;
				width: 0.9em;
				height: 0.9em;
				margin: 0 0.25em;
			}
		}
	}

	.property-top-nav.is-stuck & {
		opacity: 0;
	}

	@include media-breakpoint-up(sm) {

		margin: 40px 0;

		li {
			a {
				font-size: 28px;
			}
		}

	}

	@include media-breakpoint-up(lg) {

		position: absolute;
		top: 0;
		right: 150px;
		margin: 0;
		padding: 0;
		overflow: hidden;
		opacity: 1;
		transition: all 0.3s ease-out;
		z-index: 1;

		li {
			display: inline-block;

			&:not(.d-none) + li:before {
				display: none;
			}

			a {
				padding: 0.25em 0.5em;
				font-size: 12px;
				font-weight: 300;
				line-height: 2.25em;

				.icon-svg {
					margin-top: -2px;
					width: 12px;
					height: 12px;
				}
			}
		}
	}
}

div.utility-nav {
	@include media-breakpoint-up(lg) {
		position: absolute;
		top: 0;
		right: 150px;
		margin: 0;
		padding: 0;
		overflow: hidden;
		opacity: 1;
		transition: all 0.3s ease-out;
		z-index: 1;

		a {
			padding: 0.25em 0.5em;
			font-size: 12px;
			font-weight: 300;
			line-height: 2.25em;
			margin: .125rem;
			display: inline-block;

			.icon-svg {
				margin-top: -2px;
				width: 12px;
				height: 12px;
			}
		}
	}
}
