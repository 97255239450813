.nav-tabs {
	max-width: 560px;
	max-height: px-vw(98);
	padding: px-vw(16) 0;
	border-top: 1px solid #c8c8c8;
	border-bottom: 1px solid #c8c8c8;
	transition: max-height 0.5s;
	overflow: hidden;

	font-size: .875rem;
	line-height: 24px;
	letter-spacing: 0;

	&.open {
		max-height: px-vw(310);
		overflow: auto;

		.nav-item {
			.icon-svg {
				transform: rotate(-180deg) translateY(50%);
			}

			&:not(.nav-item-active) {
				display: block;
			}
		}
	}

	&.disabled {
		pointer-events: none;
	}

	.nav-item {
		position: relative;
		width: 100%;
		margin-bottom: 0;

		label {
			display: block;
			width: 80%;
			margin: auto;
		}

		.icon-svg {
			position: absolute;
			top: 50%;
			right: 15px;
			width: px-vw(24);
			height: px-vw(24);
			transform: rotate(0) translateY(-50%);
			transition: transform 0.5s;
			z-index: 2;
		}

		&:not(.nav-item-active) {
			.icon-svg {
				display: none;
			}
		}
	}

	.nav-link {
		position: relative;
		display: inline-block;
		padding: px-vw(12) 1em;
		border: 0;
		font-weight: 400;
		cursor: pointer;

		&.active {
			color: inherit;
		}

		span {
			font-weight: 300;
		}
	}

	@include media-breakpoint-down(sm) {
		margin: 0 auto;

		.nav-item-active {
			order: -1;
		}
	}

	@include media-breakpoint-up(sm) {
		max-height: 98px;
		padding: 16px 0;

		.nav-link {
			padding: 12px 1em;
			width: 100%;
		}
	}

	@include media-breakpoint-up(md) {
		max-width: none;
		padding: 0;

		.nav-item-active {
			.nav-link {
				font-weight: 500;

				&:before {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 6px;
					content: '';
					background: $color-aqua;
				}
			}
		}

		.nav-item {
			width: auto;
			flex: 1;
			
			label {
				width: auto
			}
		}

		.nav-link {
			display: block;
			padding: 12px 1em;
			font-weight: 300;
			width: 100%;
		}
	}
}
