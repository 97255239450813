.offer-property-search {
	.fee-disclaimer {
		text-align: center;
		font-weight: 300;

		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

	.js-property-load-container {
		@include media-breakpoint-up(md) {
			justify-content: center;
		}
	}

	label {
		font-size: .875rem;
		font-weight: 500;
		margin-bottom: 0;
	}

	.filters {

		[aria-disabled="true"],
		[disabled] {
			color: $custom-select-disabled-color;
			background-color: $custom-select-disabled-bg;
		}

		.js-search-resorts {
			.form-actions {
				@include absolute(6);
				right: px-vw(16);
				pointer-events: none;
				background-color: white;
				top: 40%;

				.icon-svg {
					width: px-vw(34);
					height: px-vw(34);
				}
			}

			[disabled] + .form-actions {
				color: $custom-select-disabled-color;
				background-color: $custom-select-disabled-bg;
			}

			@include media-breakpoint-up(md) {
				.form-actions {
					right: 20px;
					top: 40%;

					.icon-svg {
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		// .js-select-rooms {
		// 	font-weight: 400;
		// 	margin-bottom: 16px;
		// 	color: #2b2b2b;
		// }

		// .js-select-destination {
		// 	font-weight: 400;
		// 	color: #2b2b2b;
		// }

		option {
			font-weight: 500;
		}

		.calendar-flyout {
			margin-bottom: 16px;

			.enter-dates-results {
				border: 1px solid black;
				width: 100%;
				height: 13.75vw;

				@media (min-width: 640px) {
					height: 88px;
				}

				@media (min-width: 768px) {
					height: 48px;
				}

				.enter-dates-result {
					display: flex;
					align-items: center;
					height: 100%;
					cursor: pointer;
					margin: 0;

					&:focus {
						border-color: #0078d7;
						border-width: 2px;
						border-style: solid;
					}

					.cf-button {
						display: inline-block;
						width: 75%;
						text-align: left;
						padding-left: 18px;
						//line-height: 29px;

						.result-dates {
							font-size: 4.375vw;

							@include media-breakpoint-up(md) {
								font-size: .875rem;
							}
						}
					}

					.cf-svg {
						display: inline-block;
						width: 20%;
						margin-right: 0px;
						text-align: right;
					}
				}
			}
		}

		div .js-select-amenities,
		div .js-select-destinations,
		div .js-select-rooms {
			background-color: #ffffff;
			font-family: Roboto, sans-serif;
			font-weight: 500;
			color: #636c72;
			line-height: 27px;
			height: 13.75vw;
			padding: 0;

			& + div {
				border: {
					right: 1px solid #000;
					left: 1px solid #000;
					bottom: 1px solid #000;
				}
				box-shadow: 4px 4px 10px -4px rgba(0,0,0,0.5);
			}

			@media (min-width: 640px) {
				height: 88px;
			}

			@include media-breakpoint-up(md) {
				height: 48px;
			}

			button {
				width: 100%;
				height: 100%;
				text-align: left;
				font-size: 4.375vw;
				color: black;
				overflow-x: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				padding: .375rem 1.75rem .375rem .75rem;

				@media (min-width: 640px) {
					font-size: 1.75rem;
				}

				@include media-breakpoint-up(md) {
					font-size: .875rem;
					padding: 0 1.25rem;
				}
			}
		}



		.card {
			margin-bottom: 16px;

			@include media-breakpoint-up(md) {
				margin-bottom: 0px;
			}

			.collapse,
			.collapsing {
				top: 100%;
				position: absolute;
				z-index: 900;
				background-color: #fff;
				width: 100%;

			}

			.card-header {

				button {
					.icon-svg {
						transform: rotate(0deg);
						right: 20px;
						height: 2.5vw;
						width: 2.5vw;

						@include media-breakpoint-up(md) {
							right: 14px;
							height: 12px;
							width: 12px;
						}
					}

					&.collapsed {
						.icon-svg {
							transform: rotate(180deg);
						}
					}
				}

				.badge {
					width: 20px;
					height: 20px;
					font-size: 12px;
					border-radius: 50%;
					margin: 0 2em;
				}

			}

			.card-block {
				padding: 1em 1em 0em 1em;
				border: none;

				.list-group {
					margin: 0;

					@include media-breakpoint-up(md) {
						margin-bottom: 1em;
					}

					.list-group-item {
						padding-left: 0;
						padding-right: 0;
						border-left: 0;
						border-right: 0;

						&:first-child {
							border-top: 0;
						}

						&:last-child {
							@include media-breakpoint-up(md) {
								border-bottom: 0;
							}
						}
					}
				}

				.custom-control {
					display: block;
					width: 100%;
					margin: 0;
					padding: 0;

					.logo {
						height: 2em;
					}

					>.icon-svg {
						width: 2em;
						height: 2em;
						margin: 0 .5em 0 0;
						vertical-align: middle;
					}

					.custom-control-indicator {
						@include absolute(6);
						left: auto;
					}

					.custom-control-description {
						display: inline-block;
						width: 65%;
						line-height: 1.25em;
						margin-top: 0.25em;
						vertical-align: middle;

						small {
							line-height: inherit;
						}
					}
				}
			}

			#filter-amenities {
				position: absolute;

				.collapsing .collapse {
					width: 400%;
					max-width: 950px;
				}

				.card-footer {
					text-align: center;
					background-color: #f7f7f9;
					padding-bottom: 12px;
					padding-top: 12px !important;
					border-top: none;

					@include media-breakpoint-up(md) {
						padding-top: 0px;
					}

				}
			}
		}

		.js-rooms-card {
			.card-footer {
				display: flex;
				align-items: center;
				flex-direction: column;
			}

			.btn-transparent {
				padding: inherit !important;
				background-color: transparent;
			}

			.btn {
				margin-left: 0;
			}
		}
	}

	// this is styling for the mobile price sort
	.ops-select-sort-dropdown {
		color: #2b2b2b;
		font-weight: 400;
		margin-bottom: 0px;
		padding-left: 15px;
		font-size: 4.375vw;

		@media (min-width: 640px) {
			font-size: 1.75rem;
		}

		option {
			font-family: Roboto, sans-serif;
			padding-left: 15px;

		}
	}

	// General styling for dropdown arrows
	.form-control,
	.custom-select,
	.js-select-amenities,
	.js-select-destinations,
	.js-select-rooms {
		background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eicon-arrow-down%3C/title%3E%3Cpath d='M0.133 9.067c0-0.533 0.267-1.067 0.667-1.467 0.8-0.8 2.133-0.8 2.933 0l12.267 12.4 12.267-12.4c0.8-0.8 2.133-0.8 2.933 0s0.8 2.133 0 2.933l-13.733 13.867c-0.4 0.4-0.933 0.667-1.467 0.667s-1.067-0.267-1.467-0.667l-13.733-13.867c-0.4-0.4-0.667-0.933-0.667-1.467z'%3E%3C/path%3E%3C/svg%3E");
		background-size: 12px 12px;
		background-repeat: no-repeat;
		background-position: center right 10px;
	}

	.js-select-amenities,
	.js-select-destinations,
	.js-select-rooms {
		border: 1px solid #000;
	}

	.js-amenities-card,
	.js-destinations-card,
	.js-rooms-card {
		border: none;
	}

	// this is styling for the desktop price sort
	.sort-btn-row .sort-btn-col {
		label {
			font-weight: 400;
		}

		.btn .icon-svg {
			color: #616161;
			fill: #616161;
			margin-left: 10px;
		}
	}

	.btn-transparent {
		background-color: transparent;
		color: #2b2b2b;
		min-width: 0px;
		margin: 0px;
		padding: 0px 0px 0px 5px;
	}

	.card-header {
		padding: 0 1.25rem;
	}

	.property-grid .property-grid-item {
		text-align: center;

		@include media-breakpoint-up(md) {
			display: inline-block;
			width: 60%;
			margin: 4.39453vw 1.17188vw 0;
		}

		@include media-breakpoint-up(lg) {
			display: inline-block;
			width: 48%;
			margin: 10px auto 5px auto;
		}

		.property-box {
			padding: 15px;
			display: block;
			height: auto;

			@include media-breakpoint-up(md) {
				display: flex
			}

			.property-col {
				@include media-breakpoint-up(md) {}
			}
		}

		.picture-box {
			overflow: hidden;

			@include media-breakpoint-up(md) {
				width: 225px;
				height: 180px;
			}
		}

		.picture-box picture {
			width: 100%;
			height: auto;

			img {
				top: auto;
				left: auto;
				position: relative;
				transform: none;

				@include media-breakpoint-up(md) {
					width: 225px;
					height: 180px;
				}
			}
		}
	}

	.property-grid .property-grid-item .property-box .property-info {
		bottom: 0px;
		width: 100%;
		margin: 16px 0 0 0;
		position: relative;
		text-align: center;

		@include media-breakpoint-up(md) {
			margin: 8px 0px 8px 16px;
			height: auto;
			text-align: left;
		}

		.neighborhood {
			font-weight: 300;
		}

		.category {
			text-align: center;
			margin-bottom: 10px;

			@include media-breakpoint-up(md) {
				text-align: left;
			}
		}

		.price-area {
			padding: 0px;
			margin-bottom: 20px;
			margin-top: 10px;

			@include media-breakpoint-up(md) {
				position: absolute;
				margin-bottom: 0;
				bottom: 0;
				line-height: normal;
			}

			.price-container {
				text-align: center;

				@include media-breakpoint-up(md) {
					text-align: left;
					display: inline-block;
				}
			}

			.price-load {
				height: 40px;
				width: 40px;

				.loading {
					font-size: 4rem;
					line-height: 0px;
					letter-spacing: -7px;
				}

				.loading span {
					animation-name: blink;
					animation-duration: 0.9s;
					animation-iteration-count: infinite;
					animation-fill-mode: both;
				}

				.loading span:nth-child(2) {
					animation-delay: .1s;
				}

				.loading span:nth-child(3) {
					animation-delay: .2s;
				}
			}
		}
	}
}

.zoom-enabled .property-grid .property-grid-item .grid-view .property-box {
	padding-bottom: 10px;
}
