.tabbed-accordion {
	padding: 0 20px; // Added only until correct mobile container padding is added

	.nav-tabs {
		display: block;
		max-height: none;
		border: 0;
		overflow: visible;

		.nav-link {
			width: 95%;
			margin: 0;
			padding: 20px 1em;
			background-color: $color-white;
			border-bottom: 1px solid $color-grey-border;
			border-left: 7px solid $color-white;
			color: $color-black;

			&:hover {
				border-bottom: 1px solid $color-grey-border;
			}
		}

		.tab.active .nav-link,
		.tab.is-active .nav-link {
			width: auto;
			margin: -1px -1px 0 0;
			border-top: 1px solid #c7c7c7;
			border-bottom: 1px solid #c7c7c7;
			border-left: 7px solid $color-aqua;

			&:hover {
				border-bottom: 1px solid #c7c7c7;
			}
		}
	}

	.tab-content {
		position: relative;
		margin-top: -1px;
		min-height: 100%;

		.list-bulleted {
			padding: 0 30px 26px 12px;
		}

		> .active.collapse {
			display: none;

			&.show {
				display: block;
			}
		}

		> .tab-pane.show,
		.tab-pane.collapsing {
			display: block;
		}

		.fade {
			opacity: 1;
		}

		.headline-3 {
			position: relative;
			padding: 35px 50px 18px 0;
			border-top: 8px solid $color-aqua;

			&.collapsed {
				.icon-svg {
					transform: rotate(0) translateY(-50%);
				}
			}

			.icon-svg {
				position: absolute;
				top: 60%;
				right: 15px;
				width: px-vw(24);
				height: px-vw(24);
				transform: rotate(-180deg) translateY(50%);
				transition: transform 0.5s;
				z-index: 2;
			}
		}
	}

	footer {
		padding-bottom: 26px;

		ul {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 40px;
			padding: 0 20px;
			font-weight: 500;
			text-align: center;

			span {
				display: block;
				margin: 0 auto 20px;
				width: px-vw(71);
				height: px-vw(71);
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding: 0;

		.nav-tabs {
			position: relative;
			z-index: 1;
		}

		.tab-content > .active.collapse {
			display: none;
		}

		.tab-content {
			padding: 30px 30px 15px;
			border: 1px solid #c7c7c7;

			.list-bulleted {
				padding-bottom: 0;
				line-height: 30px;
			}

			> .active.collapse {
				display: block;
			}

			> .tab-pane:not(.in) {
				display: none;
			}
		}

		footer {
			padding-bottom: 0;

			li {
				margin-bottom: 14px;
				padding-right: 6px;
				padding-left: 6px;

				span {
					display: inline-block;
					margin: 0 10px 0 0;
					width: 32px;
					height: 32px;
				}
			}
		}
	}
}
