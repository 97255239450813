.must-do-list {
	margin-bottom: 5em;
	z-index: 1;

	.headline-2 {
		margin-bottom: 0;
		padding-bottom: 2.5rem;
		background: $color-white;
	}

	@include media-breakpoint-up(md) {
		@include background-shape("caret");
	}
}

