.itinerary-list {

	.itinerary-day {
		@include make-col-ready();
		@include make-col(11);
		margin-bottom: 25px;

		&.half {
			@include make-col(11);
		}

		ol {
			li {
				margin-bottom: 1em;
			}
		}

		.headline-3 {
			margin-bottom: 20px;
			padding-bottom: 17px;
			border-bottom: 8px solid $color-aqua;
		}

		figure {
			margin: 12px 0 0
		}

		.headline-1 {
			margin-bottom: 0;
		}

		&:not(.half) {
			.headline-3 {
				display: none;
			}
		}
	}

	@include media-breakpoint-up(md) {

		.itinerary-day {

			ol {
				li {
					margin-bottom: 0;
				}
			}

			@include make-col(12);

			&.half {
				@include make-col(6);
			}

			&:not(.half) {
				ol {
					li {
						display: inline-block;
						width: 48%;
					}
				}
			}
		}
	}
}
