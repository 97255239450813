.favorites-list {

	margin-bottom: 5em;
	text-align: center;

	.btn-more {
		width: 100%;
		max-width: 498px;
	}

	.no-results {
		margin: 0 4.5rem 3rem;
		padding-bottom: 3rem;
		border-bottom: 1px solid lighten($color-grey, 40%);
		text-align: center;

		p {
			margin-bottom: 0;
		}
	}

	.browse-list {

		margin: 4em 0;
		text-align: center;

		.headline {
			margin-bottom: 1.5em;
		}

		.browse-list-item {
			display: block;
			margin-bottom: 3em;

			picture {
				@include image-fit();
				display: block;
				width: 100%;
				height: 100%;
				margin-bottom: 1em;
				padding-bottom: 42.55%
			}
		}
	}

	@include media-breakpoint-up(md) {

		.btn-more {
			width: auto;
		}

		.no-results {
			margin: 0 0 3rem;
		}

		.browse-list {
			.browse-list-item {
				margin-bottom: 1.5em;
			}
		}

	}

}
