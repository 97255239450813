@charset "UTF-8";
/*
	@media (min-width: map-get($aah-grid-breakpoints, 'md')) {
		font-size: px-vw($size);
	}
	@media (min-width: map-get($aah-grid-breakpoints, 'lg')) {
		font-size: $size + px; //Fallback in px
		font-size: rem-calc($size);
	}
	*/
.img-aspect-25 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 25%; }
  .img-aspect-25 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-30 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 30%; }
  .img-aspect-30 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-35 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 35%; }
  .img-aspect-35 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-40 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 40%; }
  .img-aspect-40 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-45 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 45%; }
  .img-aspect-45 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-50 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 50%; }
  .img-aspect-50 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-55 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 55%; }
  .img-aspect-55 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-60 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 60%; }
  .img-aspect-60 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-65 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 65%; }
  .img-aspect-65 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-70 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 70%; }
  .img-aspect-70 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-75 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 75%; }
  .img-aspect-75 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-80 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 80%; }
  .img-aspect-80 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-85 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 85%; }
  .img-aspect-85 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-90 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 90%; }
  .img-aspect-90 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-95 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 95%; }
  .img-aspect-95 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-100 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 100%; }
  .img-aspect-100 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-105 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 105%; }
  .img-aspect-105 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-110 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 110%; }
  .img-aspect-110 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-115 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 115%; }
  .img-aspect-115 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-120 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 120%; }
  .img-aspect-120 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-125 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 125%; }
  .img-aspect-125 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-130 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 130%; }
  .img-aspect-130 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-135 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 135%; }
  .img-aspect-135 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-140 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 140%; }
  .img-aspect-140 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-145 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 145%; }
  .img-aspect-145 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-150 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 150%; }
  .img-aspect-150 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-155 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 155%; }
  .img-aspect-155 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-160 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 160%; }
  .img-aspect-160 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-165 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 165%; }
  .img-aspect-165 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-170 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 170%; }
  .img-aspect-170 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-175 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 175%; }
  .img-aspect-175 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-180 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 180%; }
  .img-aspect-180 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-185 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 185%; }
  .img-aspect-185 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-190 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 190%; }
  .img-aspect-190 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-195 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 195%; }
  .img-aspect-195 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.img-aspect-200 {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 200%; }
  .img-aspect-200 img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: initial;
    min-width: 100%;
    height: inherit;
    object-fit: cover;
    font-family: "object-fit: cover; object-position: center;"; }

.icon-svg {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  background-color: transparent; }
  .icon-svg:after {
    display: block;
    padding-bottom: 100%;
    content: "";
    pointer-events: none; }
  .icon-svg svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.icon-xxs {
  width: 0.75em;
  height: 0.75em; }

.icon-xs {
  width: 1em;
  height: 1em; }

.icon-sm {
  width: 2em;
  height: 2em; }

.icon-md {
  width: 3em;
  height: 3em; }

.icon-lg {
  width: 4em;
  height: 4em; }

.icon-xl {
  width: 5em;
  height: 5em; }

#icon-bundle {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -1;
  overflow: hidden; }
  #icon-bundle svg {
    display: none; }

/* Primary Colors */
.color-aqua {
  color: #3cc1cc; }

.back-color-aqua {
  background-color: #3cc1cc; }

.border-color-aqua {
  border-color: #3cc1cc; }

.icon-color-aqua svg {
  fill: #3cc1cc; }

hr.color-aqua {
  border-color: #3cc1cc; }

.color-aqua-dark {
  color: #007697; }

.back-color-aqua-dark {
  background-color: #007697; }

.border-color-aqua-dark {
  border-color: #007697; }

.icon-color-aqua-dark svg {
  fill: #007697; }

hr.color-aqua-dark {
  border-color: #007697; }

.color-black {
  color: #000000; }

.back-color-black {
  background-color: #000000; }

.border-color-black {
  border-color: #000000; }

.icon-color-black svg {
  fill: #000000; }

hr.color-black {
  border-color: #000000; }

.color-black-fake {
  color: #2b2b2b; }

.back-color-black-fake {
  background-color: #2b2b2b; }

.border-color-black-fake {
  border-color: #2b2b2b; }

.icon-color-black-fake svg {
  fill: #2b2b2b; }

hr.color-black-fake {
  border-color: #2b2b2b; }

.color-grey-light {
  color: #f7f7f7; }

.back-color-grey-light {
  background-color: #f7f7f7; }

.border-color-grey-light {
  border-color: #f7f7f7; }

.icon-color-grey-light svg {
  fill: #f7f7f7; }

hr.color-grey-light {
  border-color: #f7f7f7; }

.color-white {
  color: #ffffff; }

.back-color-white {
  background-color: #ffffff; }

.border-color-white {
  border-color: #ffffff; }

.icon-color-white svg {
  fill: #ffffff; }

hr.color-white {
  border-color: #ffffff; }

.color-grey-light-2 {
  color: #bababa; }

.back-color-grey-light-2 {
  background-color: #bababa; }

.border-color-grey-light-2 {
  border-color: #bababa; }

.icon-color-grey-light-2 svg {
  fill: #bababa; }

hr.color-grey-light-2 {
  border-color: #bababa; }

.color-grey-light-3 {
  color: #c7c7c7; }

.back-color-grey-light-3 {
  background-color: #c7c7c7; }

.border-color-grey-light-3 {
  border-color: #c7c7c7; }

.icon-color-grey-light-3 svg {
  fill: #c7c7c7; }

hr.color-grey-light-3 {
  border-color: #c7c7c7; }

.color-color-blue-button {
  color: #f0f7f9; }

.back-color-color-blue-button {
  background-color: #f0f7f9; }

.border-color-color-blue-button {
  border-color: #f0f7f9; }

.icon-color-color-blue-button svg {
  fill: #f0f7f9; }

hr.color-color-blue-button {
  border-color: #f0f7f9; }

.color-color-blue-text {
  color: #005a75; }

.back-color-color-blue-text {
  background-color: #005a75; }

.border-color-color-blue-text {
  border-color: #005a75; }

.icon-color-color-blue-text svg {
  fill: #005a75; }

hr.color-color-blue-text {
  border-color: #005a75; }

/* Secondary Colors */
.color-red {
  color: #d45358; }

.back-color-red {
  background-color: #d45358; }

.border-color-red {
  border-color: #d45358; }

.icon-color-red svg {
  fill: #d45358; }

hr.color-red {
  border-color: #d45358; }

/* Additional Colors */
/* Category Colors */
/* Generate Shades/Tints */
.back-color-grey-500 {
  background-color: #7c7c7c; }

.back-color-grey-500 {
  background-color: #7c7c7c; }

.back-color-grey-510 {
  background-color: #7f7f7f; }

.back-color-grey-490 {
  background-color: #7a7a7a; }

.back-color-grey-520 {
  background-color: #818181; }

.back-color-grey-480 {
  background-color: #777777; }

.back-color-grey-530 {
  background-color: #848484; }

.back-color-grey-470 {
  background-color: #757575; }

.back-color-grey-540 {
  background-color: #868686; }

.back-color-grey-460 {
  background-color: #727272; }

.back-color-grey-550 {
  background-color: #898989; }

.back-color-grey-450 {
  background-color: #707070; }

.back-color-grey-560 {
  background-color: #8c8c8c; }

.back-color-grey-440 {
  background-color: #6d6d6d; }

.back-color-grey-570 {
  background-color: #8e8e8e; }

.back-color-grey-430 {
  background-color: #6b6b6b; }

.back-color-grey-580 {
  background-color: #919191; }

.back-color-grey-420 {
  background-color: #686868; }

.back-color-grey-590 {
  background-color: #949494; }

.back-color-grey-410 {
  background-color: #666666; }

.back-color-grey-600 {
  background-color: #969696; }

.back-color-grey-400 {
  background-color: #636363; }

.back-color-grey-610 {
  background-color: #999999; }

.back-color-grey-390 {
  background-color: #616161; }

.back-color-grey-620 {
  background-color: #9b9b9b; }

.back-color-grey-380 {
  background-color: #5e5e5e; }

.back-color-grey-630 {
  background-color: #9e9e9e; }

.back-color-grey-370 {
  background-color: #5c5c5c; }

.back-color-grey-640 {
  background-color: #a1a1a1; }

.back-color-grey-360 {
  background-color: #595959; }

.back-color-grey-650 {
  background-color: #a3a3a3; }

.back-color-grey-350 {
  background-color: #575757; }

.back-color-grey-660 {
  background-color: #a6a6a6; }

.back-color-grey-340 {
  background-color: #545454; }

.back-color-grey-670 {
  background-color: darkgray; }

.back-color-grey-330 {
  background-color: #525252; }

.back-color-grey-680 {
  background-color: #ababab; }

.back-color-grey-320 {
  background-color: #4f4f4f; }

.back-color-grey-690 {
  background-color: #aeaeae; }

.back-color-grey-310 {
  background-color: #4d4d4d; }

.back-color-grey-700 {
  background-color: #b0b0b0; }

.back-color-grey-300 {
  background-color: #4a4a4a; }

.back-color-grey-710 {
  background-color: #b3b3b3; }

.back-color-grey-290 {
  background-color: #484848; }

.back-color-grey-720 {
  background-color: #b6b6b6; }

.back-color-grey-280 {
  background-color: #454545; }

.back-color-grey-730 {
  background-color: #b8b8b8; }

.back-color-grey-270 {
  background-color: #434343; }

.back-color-grey-740 {
  background-color: #bbbbbb; }

.back-color-grey-260 {
  background-color: #404040; }

.back-color-grey-750 {
  background-color: #bebebe; }

.back-color-grey-250 {
  background-color: #3e3e3e; }

.back-color-grey-760 {
  background-color: silver; }

.back-color-grey-240 {
  background-color: #3c3c3c; }

.back-color-grey-770 {
  background-color: #c3c3c3; }

.back-color-grey-230 {
  background-color: #393939; }

.back-color-grey-780 {
  background-color: #c5c5c5; }

.back-color-grey-220 {
  background-color: #373737; }

.back-color-grey-790 {
  background-color: #c8c8c8; }

.back-color-grey-210 {
  background-color: #343434; }

.back-color-grey-800 {
  background-color: #cbcbcb; }

.back-color-grey-200 {
  background-color: #323232; }

.back-color-grey-810 {
  background-color: #cdcdcd; }

.back-color-grey-190 {
  background-color: #2f2f2f; }

.back-color-grey-820 {
  background-color: #d0d0d0; }

.back-color-grey-180 {
  background-color: #2d2d2d; }

.back-color-grey-830 {
  background-color: #d2d2d2; }

.back-color-grey-170 {
  background-color: #2a2a2a; }

.back-color-grey-840 {
  background-color: #d5d5d5; }

.back-color-grey-160 {
  background-color: #282828; }

.back-color-grey-850 {
  background-color: #d8d8d8; }

.back-color-grey-150 {
  background-color: #252525; }

.back-color-grey-860 {
  background-color: #dadada; }

.back-color-grey-140 {
  background-color: #232323; }

.back-color-grey-870 {
  background-color: #dddddd; }

.back-color-grey-130 {
  background-color: #202020; }

.back-color-grey-880 {
  background-color: #e0e0e0; }

.back-color-grey-120 {
  background-color: #1e1e1e; }

.back-color-grey-890 {
  background-color: #e2e2e2; }

.back-color-grey-110 {
  background-color: #1b1b1b; }

.back-color-grey-900 {
  background-color: #e5e5e5; }

.back-color-grey-100 {
  background-color: #191919; }

.back-color-grey-910 {
  background-color: #e7e7e7; }

.back-color-grey-90 {
  background-color: #161616; }

.back-color-grey-920 {
  background-color: #eaeaea; }

.back-color-grey-80 {
  background-color: #141414; }

.back-color-grey-930 {
  background-color: #ededed; }

.back-color-grey-70 {
  background-color: #111111; }

.back-color-grey-940 {
  background-color: #efefef; }

.back-color-grey-60 {
  background-color: #0f0f0f; }

.back-color-grey-950 {
  background-color: #f2f2f2; }

.back-color-grey-50 {
  background-color: #0c0c0c; }

.back-color-grey-960 {
  background-color: whitesmoke; }

.back-color-grey-40 {
  background-color: #0a0a0a; }

.back-color-grey-970 {
  background-color: #f7f7f7; }

.back-color-grey-30 {
  background-color: #070707; }

.back-color-grey-980 {
  background-color: #fafafa; }

.back-color-grey-20 {
  background-color: #050505; }

.back-color-grey-990 {
  background-color: #fcfcfc; }

.back-color-grey-10 {
  background-color: #020202; }

.back-color-grey-1000 {
  background-color: white; }

.back-color-grey-0 {
  background-color: black; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0275d8;
  --indigo: #6610f2;
  --purple: #613d7c;
  --pink: #ff5b77;
  --red: #d9534f;
  --orange: #f0ad4e;
  --yellow: #ffd500;
  --green: #5cb85c;
  --teal: #5bc0de;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0275d8;
  --secondary: #6c757d;
  --success: #5cb85c;
  --info: #17a2b8;
  --warning: #ffd500;
  --danger: #d9534f;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 640px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1500px;
  --font-family-sans-serif: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #2b2b2b;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #007697;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #bff1ff; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #636c72; }

code {
  font-size: 90%;
  color: #bd4147;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #292b2c; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  font-size: 90%;
  color: #292b2c; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container, .form-factory {
  width: 100%;
  padding-right: 9.5px;
  padding-left: 9.5px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 640px) {
    .container, .form-factory {
      max-width: 740px; } }
  @media (min-width: 768px) {
    .container, .form-factory {
      max-width: 960px; } }
  @media (min-width: 1024px) {
    .container, .form-factory {
      max-width: 960.0px; } }
  @media (min-width: 1500px) {
    .container, .form-factory {
      max-width: 960.0px; } }

.container-fluid {
  width: 100%;
  padding-right: 9.5px;
  padding-left: 9.5px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9.5px;
  margin-left: -9.5px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 9.5px;
  padding-left: 9.5px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 640px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1500px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2b2b2b;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef; }
  .table tbody + tbody {
    border-top: 2px solid #eceeef; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #eceeef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eceeef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #2b2b2b;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d8f4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7bb7eb; }

.table-hover .table-primary:hover {
  background-color: #a2ccf1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a2ccf1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d1ebd1; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #aadaaa; }

.table-hover .table-success:hover {
  background-color: #bfe3bf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bfe3bf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff3b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe97a; }

.table-hover .table-warning:hover {
  background-color: #ffef9f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffef9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3; }

.table-hover .table-danger:hover {
  background-color: #efbbb9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #efbbb9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #464a4c;
  background-color: #eceeef;
  border-color: #eceeef; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 639.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1499.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: 2.25rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #2b2b2b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #000000;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #2b2b2b;
    background-color: #fff;
    border-color: #18ccff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 118, 151, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #2b2b2b;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25; }

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.25rem;
  line-height: 1.333333; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  color: #2b2b2b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: 1.8125rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-lg {
  height: 3.166667rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.333333; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 0.7rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #636c72; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5cb85c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(92, 184, 92, 0.9); }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #5cb85c;
  padding-right: calc(1.25em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.3125em + 0.25rem);
  background-size: calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #5cb85c;
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.25em + 1rem);
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5cb85c;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=' ' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5cb85c;
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5cb85c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5cb85c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5cb85c; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #80c780;
  background-color: #80c780; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5cb85c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5cb85c; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9534f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(217, 83, 79, 0.9); }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.25em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.3125em + 0.25rem);
  background-size: calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.25em + 1rem);
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=' ' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d9534f; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d9534f; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 640px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #2b2b2b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.815;
  border-radius: 0;
  transition: all 0.1s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #2b2b2b;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 118, 151, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0260b2;
    border-color: #025aa5; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 138, 222, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #025aa5;
    border-color: #015399; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 138, 222, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c; }
  .btn-success:hover {
    color: #fff;
    background-color: #48a648;
    border-color: #449d44; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(116, 195, 116, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    border-color: #409440; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 195, 116, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffd500;
  border-color: #ffd500; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d9b500;
    border-color: #ccaa00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffd500;
    border-color: #ffd500; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ccaa00;
    border-color: #bfa000; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d23430;
    border-color: #c9302c; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #bf2e29; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #0275d8;
  border-color: #0275d8; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 117, 216, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0275d8;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0275d8;
    border-color: #0275d8; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(2, 117, 216, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5cb85c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffd500;
  border-color: #ffd500; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffd500;
    border-color: #ffd500; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 213, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffd500;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffd500;
    border-color: #ffd500; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 213, 0, 0.5); }

.btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d9534f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: normal;
  color: inherit;
  text-decoration: none; }
  .btn-link:hover {
    color: #007697;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #636c72;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.333333;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.1s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2b2b2b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 640px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1500px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eceeef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1d1e1f;
    text-decoration: none;
    background-color: #f7f7f9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007697; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #636c72;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #636c72;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #292b2c; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #2b2b2b;
  text-align: center;
  white-space: nowrap;
  background-color: #eceeef;
  border: 1px solid #000000; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: 3.166667rem; }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.333333; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: 1.8125rem; }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007697;
    background-color: #007697; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 118, 151, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #18ccff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #4bd8ff;
    border-color: #4bd8ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007697;
  background-color: #007697; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(2, 117, 216, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(2, 117, 216, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(2, 117, 216, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(2, 117, 216, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #2b2b2b;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=' ' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #007697;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(0, 118, 151, 0.5); }
    .custom-select:focus::-ms-value {
      color: #2b2b2b;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #636c72;
    background-color: #eceeef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: 1.8125rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: 3.166667rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #18ccff;
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #007697; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  font-weight: normal;
  line-height: 1.25;
  color: #2b2b2b;
  background-color: #fff;
  border: 1px solid #000000; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.25em + 1rem);
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #2b2b2b;
    content: "Browse";
    background-color: #eceeef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 118, 151, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 118, 151, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 118, 151, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007697;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #4bd8ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007697;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #4bd8ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007697;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #4bd8ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid transparent; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007697; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container, .navbar > .form-factory,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 639.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .form-factory,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 640px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, .navbar-expand-sm > .form-factory,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .form-factory,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, .navbar-expand-md > .form-factory,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .form-factory,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, .navbar-expand-lg > .form-factory,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1499.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .form-factory,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1500px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, .navbar-expand-xl > .form-factory,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .navbar-expand > .form-factory,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, .navbar-expand > .form-factory,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: #007697; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: #007697;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23007697' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #007697; }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f7f7f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f7f7f9;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 20px; }
  @media (min-width: 640px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -20px;
      margin-left: -20px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 9.5px; }
  @media (min-width: 640px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 640px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eceeef; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #636c72;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #636c72; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: inherit;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:hover {
    z-index: 2;
    color: #007697;
    text-decoration: none;
    background-color: #eceeef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 118, 151, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007697;
  border-color: #007697; }

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.333333; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: all 0.1s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #fff;
  background-color: #0275d8; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #025aa5; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(2, 117, 216, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #5cb85c; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #449d44; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffd500; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ccaa00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 213, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #d9534f; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #c9302c; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef; }
  @media (min-width: 640px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #013d70;
  background-color: #cce3f7;
  border-color: #b8d8f4; }
  .alert-primary hr {
    border-top-color: #a2ccf1; }
  .alert-primary .alert-link {
    color: #01213d; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #306030;
  background-color: #def1de;
  border-color: #d1ebd1; }
  .alert-success hr {
    border-top-color: #bfe3bf; }
  .alert-success .alert-link {
    color: #1f3e1f; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856f00;
  background-color: #fff7cc;
  border-color: #fff3b8; }
  .alert-warning hr {
    border-top-color: #ffef9f; }
  .alert-warning .alert-link {
    color: #524400; }

.alert-danger {
  color: #712b29;
  background-color: #f7dddc;
  border-color: #f4cfce; }
  .alert-danger hr {
    border-top-color: #efbbb9; }
  .alert-danger .alert-link {
    color: #4c1d1b; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #eceeef; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007697;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f7f7f9; }
  .list-group-item-action:active {
    color: #2b2b2b;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  color: #2b2b2b;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #636c72;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007697;
    border-color: #007697; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0; }

@media (min-width: 640px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1500px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #013d70;
  background-color: #b8d8f4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #013d70;
    background-color: #a2ccf1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #013d70;
    border-color: #013d70; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #306030;
  background-color: #d1ebd1; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #306030;
    background-color: #bfe3bf; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #306030;
    border-color: #306030; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856f00;
  background-color: #fff3b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856f00;
    background-color: #ffef9f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856f00;
    border-color: #856f00; }

.list-group-item-danger {
  color: #712b29;
  background-color: #f4cfce; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #712b29;
    background-color: #efbbb9; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #712b29;
    border-color: #712b29; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 0); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 0);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 0); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 0);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.4; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: calc((26 / 640vw ) * 100);
  border-bottom: 0 solid #eceeef; }
  .modal-header .close {
    padding: calc((26 / 640vw ) * 100);
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: calc((15 / 640vw ) * 100); }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: calc((15 / 640vw ) * 100);
  border-top: 0 solid #eceeef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 640px) {
  .modal-dialog {
    max-width: 671px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 471px; } }

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1500px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 3px;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 2.5px 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 5px; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 2.5px 0.4rem 2.5px 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 2.5px 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 5px; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 2.5px 0 2.5px 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #2b2b2b; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 5px 0;
      border-top-color: #2b2b2b; }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 5px 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 10px;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 5px 0.5rem 5px 0;
      border-right-color: #2b2b2b; }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 5px 0.5rem 5px 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 5px 0.5rem 5px;
      border-bottom-color: #2b2b2b; }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 5px 0.5rem 5px;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 10px;
    margin-left: -5px;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 10px;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 5px 0 5px 0.5rem;
      border-left-color: #2b2b2b; }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 5px 0 5px 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2b2b2b; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0275d8 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #025aa5 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #5cb85c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #449d44 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffd500 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ccaa00 !important; }

.bg-danger {
  background-color: #d9534f !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0275d8 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #5cb85c !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffd500 !important; }

.border-danger {
  border-color: #d9534f !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 640px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1500px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 640px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1500px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 640px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1500px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1030; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-33 {
  width: 33.33333% !important; }

.w-66 {
  width: 66.66666% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-33 {
  height: 33.33333% !important; }

.h-66 {
  height: 66.66666% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 640px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1500px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 640px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1500px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: bold !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0275d8 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #014c8c !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #5cb85c !important; }

a.text-success:hover, a.text-success:focus {
  color: #3d8b3d !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffd500 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b39500 !important; }

.text-danger {
  color: #d9534f !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #2b2b2b !important; }

.text-muted {
  color: #636c72 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container, .form-factory {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #eceeef; }
  .table .thead-dark th {
    color: inherit;
    border-color: #eceeef; } }

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat; }

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  bottom: -17px; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px; }

.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px; }

html, body {
  position: relative;
  max-width: 100vw; }

body.modal-open {
  padding-right: 0 !important; }

#main {
  position: relative;
  top: 0;
  left: 0;
  padding-top: calc((108 / 640vw ) * 100); }

[data-page-jump], .page-jump {
  position: absolute;
  margin-top: calc((-187 / 640vw ) * 100); }

img {
  max-width: 100%;
  height: auto; }

picture img {
  margin: 0 auto; }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 1.5s; }

.section-feature {
  position: relative;
  text-align: center;
  transform: translateY(-50%); }

.lazyload,
.lazyloading {
  opacity: 0; }

.lazyloaded {
  opacity: 1;
  transition: opacity 1.5s; }
  @media screen and (min-width: 0\0) {
    .lazyloaded {
      transition: none; } }
  @supports (-ms-ime-align: auto) {
    .lazyloaded {
      transition: none; } }

.inset svg {
  fill: black; }

.row {
  margin-right: -6px;
  margin-left: -6px; }
  @media (min-width: 768px) {
    .row {
      margin-right: -9.5px;
      margin-left: -9.5px; } }

@media (max-width: 767.98px) {
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 6px;
    padding-left: 6px; } }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.row.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.container, .form-factory {
  position: relative;
  padding-right: 6px;
  padding-left: 6px; }
  @media (min-width: 768px) {
    .container, .form-factory {
      padding-right: 9.5px;
      padding-left: 9.5px; } }
  .container.container-inset, .container-inset.form-factory {
    position: relative;
    margin-top: calc((-50 / 640vw ) * 100);
    padding-top: calc((40 / 640vw ) * 100);
    padding-bottom: calc((20 / 640vw ) * 100);
    background-color: #ffffff;
    z-index: auto; }
    .container.container-inset:before, .container-inset.form-factory:before {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      content: '';
      border-top: calc((13 / 640vw ) * 100) solid #3cc1cc; }
    .container.container-inset .container-inset-feature, .container-inset.form-factory .container-inset-feature {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 6.5px;
      transform: translate(-50%, -50%);
      z-index: 300; }
  .container .collapse-container, .form-factory .collapse-container {
    position: absolute;
    top: calc((13 / 640vw ) * 100);
    left: 50%;
    width: 100%;
    padding-top: calc((70 / 640vw ) * 100);
    background-color: #ffffff;
    box-shadow: 0 4px 38px 15px rgba(0, 0, 0, 0.05);
    transform: translateX(-50%);
    z-index: 200; }
    .container .collapse-container .collapse-header .btn-close, .form-factory .collapse-container .collapse-header .btn-close {
      position: absolute;
      top: calc((20 / 640vw ) * 100);
      right: calc((20 / 640vw ) * 100); }
      .container .collapse-container .collapse-header .btn-close .icon-svg, .form-factory .collapse-container .collapse-header .btn-close .icon-svg {
        width: calc((35 / 640vw ) * 100);
        height: calc((35 / 640vw ) * 100); }
    .container .collapse-container .collapse-header .collapse-title, .form-factory .collapse-container .collapse-header .collapse-title {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((36 / 640vw ) * 100);
      line-height: calc((48 / 640vw ) * 100);
      letter-spacing: calc((4 / 640vw ) * 100);
      margin-bottom: 0.5em;
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
      font-weight: 500;
      margin: calc((20 / 640vw ) * 100) 0 calc((50 / 640vw ) * 100) 0; }
      @media (min-width: 640px) {
        .container .collapse-container .collapse-header .collapse-title, .form-factory .collapse-container .collapse-header .collapse-title {
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 48px;
          letter-spacing: 4px; } }
      @media (min-width: 768px) {
        .container .collapse-container .collapse-header .collapse-title, .form-factory .collapse-container .collapse-header .collapse-title {
          font-size: calc((22 / 640vw ) * 100);
          line-height: calc((32 / 640vw ) * 100);
          letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .container .collapse-container .collapse-header .collapse-title, .form-factory .collapse-container .collapse-header .collapse-title {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }
    .container .collapse-container.reverse-collapse, .form-factory .collapse-container.reverse-collapse {
      top: auto;
      bottom: 100%; }

.logo-farm {
  padding-bottom: 30px; }
  .logo-farm .row {
    margin: auto;
    padding: 0 0 20px; }
    .logo-farm .row .col-md-12, .logo-farm .row .col-4, .logo-farm .row .col-md-2 {
      margin: auto; }
  .logo-farm ul {
    list-style: none; }
  .logo-farm .primary-logo img, .logo-farm .primary-logo span {
    max-height: 80px; }
  .logo-farm img, .logo-farm span {
    margin: auto;
    display: block;
    max-height: 60px;
    width: auto;
    height: auto; }

ul.nav-list {
  overflow-x: scroll;
  flex-wrap: nowrap; }
  ul.nav-list li {
    white-space: nowrap; }
    ul.nav-list li a {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      display: inline-block;
      margin-bottom: 1rem;
      text-transform: uppercase;
      color: #2b2b2b; }
      @media (min-width: 640px) {
        ul.nav-list li a {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        ul.nav-list li a {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    ul.nav-list li a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      ul.nav-list li a:hover, ul.nav-list li a:focus {
        color: #007697; }
    ul.nav-list li + li:before {
      display: inline-block;
      width: 1px;
      height: 1em;
      margin: 0 0.4em;
      content: '';
      background-color: #efefef;
      vertical-align: text-bottom; }

@media (min-width: 640px) {
  [data-page-jump], .page-jump {
    position: absolute;
    margin-top: -187px; }
  .container.container-inset, .container-inset.form-factory {
    margin-top: -50px;
    padding-top: 40px;
    padding-bottom: 20px; }
    .container.container-inset:before, .container-inset.form-factory:before {
      border-top: 13px solid #3cc1cc; }
  .container .collapse-container .collapse-header .btn-close, .form-factory .collapse-container .collapse-header .btn-close {
    top: 20px;
    right: 20px; }
    .container .collapse-container .collapse-header .btn-close .icon-svg, .form-factory .collapse-container .collapse-header .btn-close .icon-svg {
      width: 35px;
      height: 35px; }
  .container .collapse-container .collapse-header .collapse-title, .form-factory .collapse-container .collapse-header .collapse-title {
    margin: 0 0 calc((30 / 1024vw ) * 100) 0; } }

@media (max-width: 767.98px) {
  .container.container-inset, .container-inset.form-factory {
    margin-top: -20px;
    z-index: 9; } }

@media (min-width: 768px) {
  html, body {
    height: auto; }
  #main {
    padding-top: 108px; }
  ul.nav-list {
    overflow-x: visible; }
  .container.container-inset, .container-inset.form-factory {
    margin-top: -34px;
    padding-top: 40px;
    padding-bottom: 20px;
    z-index: 9; }
    .container.container-inset .container-inset-feature, .container-inset.form-factory .container-inset-feature {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 6.5px;
      transform: translate(-50%, -50%); }
  .container .collapse-container, .form-factory .collapse-container {
    top: 13px;
    width: 70%;
    max-width: 673px;
    padding-top: 60px; }
    .container .collapse-container .collapse-header .btn-close, .form-factory .collapse-container .collapse-header .btn-close {
      top: 15px;
      right: 15px; }
      .container .collapse-container .collapse-header .btn-close .icon-svg, .form-factory .collapse-container .collapse-header .btn-close .icon-svg {
        width: 1.3em;
        height: 1.3em; }
    .container .collapse-container .collapse-header .collapse-title, .form-factory .collapse-container .collapse-header .collapse-title {
      margin: 0 0 30px 0; } }

@media (min-width: 1024px) {
  .header {
    background-color: #ffffff; }
  #main {
    padding-top: 132px;
    background-color: #ffffff; }
    .has-property-nav #main {
      padding-top: 138px; }
    .header.has-old-nav.position-static + #main {
      padding-top: 0; }
  [data-page-jump], .page-jump {
    position: absolute;
    margin-top: -204px; } }

/* Absolute placement classes */
.absolute-1 {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0; }

.absolute-2 {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%); }

.absolute-3 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto; }

.absolute-4 {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

.absolute-5 {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%); }

.absolute-6 {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

.absolute-7 {
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0; }

.absolute-8 {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%); }

.absolute-9 {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto; }

hr.hr-1 {
  border-top-width: 1px; }

hr.hr-2 {
  border-top-width: 2px; }

hr.hr-3 {
  border-top-width: 3px; }

hr.hr-4 {
  border-top-width: 4px; }

hr.hr-5 {
  border-top-width: 5px; }

@media (min-width: 768px) {
  .multi-col-2-md-up {
    column-count: 2;
    column-gap: 2em; } }

.LPMcontainer {
  z-index: 1039 !important; }

.position-relative {
  position: relative; }

.OneLinkShow {
  display: none; }

[lang="ja"] .OneLinkShow {
  display: block; }

.fee-disclaimer {
  font-weight: 300;
  font-size: 0.875rem; }
  .fee-disclaimer a {
    text-decoration: underline; }

.room-detail-footnote {
  scroll-margin-top: 180px; }

@media (min-width: 768px) {
  .room-detail-footnote {
    scroll-margin-top: 150px; } }

@media (min-width: 1024px) {
  .room-detail-footnote {
    scroll-margin-top: 100px; } }

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Roboto Light"), local("Roboto-Light"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-300.woff2") format("woff2"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-300.woff") format("woff"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-300.ttf") format("truetype"); }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-regular.woff2") format("woff2"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-regular.woff") format("woff"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-regular.ttf") format("truetype"); }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-500.woff2") format("woff2"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-500.woff") format("woff"), url("/modules/ilg-aah-jahia-assets/fonts/roboto-v18-latin-500.ttf") format("truetype"); }

/* oswald-500 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Oswald Medium"), local("Oswald-Medium"), url("/modules/ilg-aah-jahia-assets/fonts/Oswald-Medium.woff2") format("woff2"), url("/modules/ilg-aah-jahia-assets/fonts/Oswald-Medium.ttf") format("truetype"); }

.font-oswald {
  font-family: "Oswald", sans-serif;
  font-weight: 500; }

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) .btn:focus,
body:not(.user-is-tabbing) button:active:focus,
body:not(.user-is-tabbing) .btn:active:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) figure:focus,
body a:focus-within {
  outline: none;
  box-shadow: none !important; }

body.user-is-tabbing button:focus,
body.user-is-tabbing .btn:focus,
body.user-is-tabbing button:active:focus,
body.user-is-tabbing .btn:active:focus,
body.user-is-tabbing input:focus,
body.user-is-tabbing select:focus,
body.user-is-tabbing textarea:focus,
body.user-is-tabbing .flatpickr-day:focus,
body.user-is-tabbing a:not(.custom-focus):focus,
body.user-is-tabbing .custom-focus:focus .focus-el,
body.user-is-tabbing figure:focus {
  outline-width: 2px !important;
  outline-style: solid !important;
  outline-color: Highlight !important;
  outline: auto 5px -webkit-focus-ring-color !important; }

body.user-is-tabbing .nav-link:focus {
  outline: auto 5px #1c4f81 !important; }

button, input, optgroup, select, textarea {
  font-family: 'Roboto', sans-serif; }

a[href^="tel"] {
  color: inherit;
  text-decoration: underline; }

p, ul, dl, .body {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b; }
  @media (min-width: 640px) {
    p, ul, dl, .body {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  p strong, ul strong, dl strong, .body strong {
    font-weight: 400;
    letter-spacing: 0.5px; }
  p.small, p small, ul.small, ul small, dl.small, dl small, .body.small, .body small {
    font-size: calc((26 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300; }
    @media (min-width: 640px) {
      p.small, p small, ul.small, ul small, dl.small, dl small, .body.small, .body small {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 40px;
        letter-spacing: 0px; } }

p a {
  text-decoration: underline;
  color: inherit; }
  p a:hover, p a:focus {
    color: #007697;
    text-decoration: underline; }
  p a[href^="tel"] {
    text-decoration: none; }
    p a[href^="tel"]:hover, p a[href^="tel"]:focus {
      color: inherit; }

a[target="_blank"]:after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: currentColor;
  background-size: contain;
  mask-image: url("/modules/ilg-aah-jahia-assets/img/icon-external-tab.svg");
  mask-size: cover;
  margin-left: 5px;
  vertical-align: middle; }

figure picture {
  display: block;
  margin: 0 auto; }

figure figcaption {
  margin-top: 0.5em; }

mark {
  padding: 0 0.25em 0 0.45em;
  background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
  box-decoration-break: clone;
  background-color: transparent !important; }
  @media screen and (min-width: 0\0) {
    mark {
      padding: 0; } }
  @supports (-ms-ime-align: auto) {
    mark {
      padding: 0; } }

.headline-1 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((56 / 640vw ) * 100);
  line-height: calc((72 / 640vw ) * 100);
  letter-spacing: calc((5 / 640vw ) * 100);
  margin-bottom: 0.75em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 500; }
  @media (min-width: 640px) {
    .headline-1 {
      font-size: 56px;
      font-size: 3.5rem;
      line-height: 72px;
      letter-spacing: 5px; } }
  @media (min-width: 768px) {
    .headline-1 {
      font-size: calc((40 / 640vw ) * 100);
      line-height: calc((56 / 640vw ) * 100);
      letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-1 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 56px;
      letter-spacing: 5.25px; } }
  .headline-1 mark {
    padding: 0 0.25em 0 0.45em;
    background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
    box-decoration-break: clone; }
    @media screen and (min-width: 0\0) {
      .headline-1 mark {
        padding: 0; } }
    @supports (-ms-ime-align: auto) {
      .headline-1 mark {
        padding: 0; } }

.headline-2 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((50 / 640vw ) * 100);
  line-height: calc((63 / 640vw ) * 100);
  letter-spacing: calc((4.5 / 640vw ) * 100);
  margin-bottom: 0.75em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 500; }
  @media (min-width: 640px) {
    .headline-2 {
      font-size: 50px;
      font-size: 3.125rem;
      line-height: 63px;
      letter-spacing: 4.5px; } }
  @media (min-width: 768px) {
    .headline-2 {
      font-size: calc((32 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-2 {
      font-size: 32px;
      font-size: 2rem;
      line-height: 40px;
      letter-spacing: 5.25px; } }
  .headline-2 mark {
    padding: 0 0.25em 0 0.45em;
    background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
    box-decoration-break: clone; }
    @media screen and (min-width: 0\0) {
      .headline-2 mark {
        padding: 0; } }
    @supports (-ms-ime-align: auto) {
      .headline-2 mark {
        padding: 0; } }

.headline-3 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((36 / 640vw ) * 100);
  line-height: calc((48 / 640vw ) * 100);
  letter-spacing: calc((4 / 640vw ) * 100);
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 500; }
  @media (min-width: 640px) {
    .headline-3 {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 48px;
      letter-spacing: 4px; } }
  @media (min-width: 768px) {
    .headline-3 {
      font-size: calc((22 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-3 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }

.headline-4 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((36 / 640vw ) * 100);
  line-height: calc((37 / 640vw ) * 100);
  letter-spacing: calc((0.5 / 640vw ) * 100);
  margin-bottom: 0.55em; }
  @media (min-width: 640px) {
    .headline-4 {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 37px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    .headline-4 {
      font-size: calc((20 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-4 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

.headline-5 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((32 / 640vw ) * 100);
  line-height: calc((32 / 640vw ) * 100);
  letter-spacing: calc((0.5 / 640vw ) * 100);
  margin-bottom: 0.5em; }
  @media (min-width: 640px) {
    .headline-5 {
      font-size: 32px;
      font-size: 2rem;
      line-height: 32px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    .headline-5 {
      font-size: calc((16 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-5 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

.headline-6 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((30 / 640vw ) * 100);
  line-height: calc((32 / 640vw ) * 100);
  letter-spacing: calc((0.5 / 640vw ) * 100);
  margin-bottom: 0.5em; }
  @media (min-width: 640px) {
    .headline-6 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 32px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    .headline-6 {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-6 {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

.headline-7 {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((26 / 640vw ) * 100);
  line-height: calc((32 / 640vw ) * 100);
  letter-spacing: calc((0.55 / 640vw ) * 100);
  margin-bottom: 0.5em; }
  @media (min-width: 640px) {
    .headline-7 {
      font-size: 26px;
      font-size: 1.625rem;
      line-height: 32px;
      letter-spacing: 0.55px; } }
  @media (min-width: 768px) {
    .headline-7 {
      font-size: calc((12 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .headline-7 {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

.number {
  display: inline-block;
  letter-spacing: 0 !important; }

.blockquote {
  position: relative;
  padding-left: 22px;
  border-left: calc((18 / 640vw ) * 100) solid #3cc1cc; }
  .blockquote.border-top {
    padding: calc((18 / 640vw ) * 100) 0;
    border-left: 0; }
  .blockquote.double-quote {
    padding: calc((40 / 640vw ) * 100) calc((60 / 640vw ) * 100) calc((22 / 640vw ) * 100); }
    .blockquote.double-quote:before {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((56 / 640vw ) * 100);
      line-height: calc((72 / 640vw ) * 100);
      letter-spacing: calc((5 / 640vw ) * 100);
      margin-bottom: 0.75em;
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
      font-weight: 500;
      top: calc((84 / 640vw ) * 100);
      margin-bottom: 0;
      font-size: calc((100 / 640vw ) * 100);
      content: "“"; }
      @media (min-width: 640px) {
        .blockquote.double-quote:before {
          font-size: 56px;
          font-size: 3.5rem;
          line-height: 72px;
          letter-spacing: 5px; } }
      @media (min-width: 768px) {
        .blockquote.double-quote:before {
          font-size: calc((40 / 640vw ) * 100);
          line-height: calc((56 / 640vw ) * 100);
          letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .blockquote.double-quote:before {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 56px;
      letter-spacing: 5.25px; } }
      .blockquote.double-quote:before mark {
        padding: 0 0.25em 0 0.45em;
        background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
        box-decoration-break: clone; }
        @media screen and (min-width: 0\0) {
          .blockquote.double-quote:before mark {
            padding: 0; } }
        @supports (-ms-ime-align: auto) {
          .blockquote.double-quote:before mark {
            padding: 0; } }
  @media (min-width: 640px) {
    .blockquote.double-quote:before {
      font-size: 100px; } }
  @media (min-width: 768px) {
    .blockquote {
      border-width: 8px; }
      .blockquote.border-top {
        padding: 25px 0 35px; }
      .blockquote.double-quote {
        padding: 22px 0 30px 90px; }
        .blockquote.double-quote:before {
          top: 90px;
          font-size: 158px; } }
  .blockquote p {
    font-size: 1.375rem;
    line-height: 32px;
    letter-spacing: 2px; }

a.support-number {
  color: #007697;
  text-decoration: underline !important; }

@media (min-width: 768px) {
  a[href^="tel"] {
    text-decoration: none; }
  p, ul, dl, .body {
    font-size: calc((14 / 640vw ) * 100);
    line-height: calc((24 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    p, ul, dl, .body {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }

@media (min-width: 768px) {
    p strong, ul strong, dl strong, .body strong {
      font-weight: 500; }
    p.small, p small, ul.small, ul small, dl.small, dl small, .body.small, .body small {
      font-size: calc((12 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
    @media (min-width: 768px) and (min-width: 640px) {
      p.small, p small, ul.small, ul small, dl.small, dl small, .body.small, .body small {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 24px;
        letter-spacing: 0px; } }

@media (min-width: 768px) {
  figure figcaption {
    margin-top: 0.75em; } }

dl.qa dt {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((32 / 640vw ) * 100);
  line-height: calc((32 / 640vw ) * 100);
  letter-spacing: calc((0.5 / 640vw ) * 100);
  margin-bottom: 0.5em; }
  @media (min-width: 640px) {
    dl.qa dt {
      font-size: 32px;
      font-size: 2rem;
      line-height: 32px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    dl.qa dt {
      font-size: calc((16 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    dl.qa dt {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

dl.qa dt,
dl.qa dd {
  display: table;
  width: 100%; }
  dl.qa dt .qa-label,
  dl.qa dd .qa-label {
    display: table-cell;
    width: calc((42 / 640vw ) * 100);
    padding-right: 10px; }

@media (min-width: 768px) {
  dl.qa dt .qa-label,
  dl.qa dd .qa-label {
    width: 22px;
    max-width: 22px; } }

.line-height-75 {
  line-height: 75%; }

.line-height-80 {
  line-height: 80%; }

.line-height-85 {
  line-height: 85%; }

.line-height-90 {
  line-height: 90%; }

.line-height-95 {
  line-height: 95%; }

.line-height-100 {
  line-height: 100%; }

.line-height-105 {
  line-height: 105%; }

.line-height-110 {
  line-height: 110%; }

.line-height-115 {
  line-height: 115%; }

.line-height-120 {
  line-height: 120%; }

.line-height-125 {
  line-height: 125%; }

.line-height-130 {
  line-height: 130%; }

.line-height-135 {
  line-height: 135%; }

.line-height-140 {
  line-height: 140%; }

.line-height-145 {
  line-height: 145%; }

.line-height-150 {
  line-height: 150%; }

.line-height-155 {
  line-height: 155%; }

.line-height-160 {
  line-height: 160%; }

.line-height-165 {
  line-height: 165%; }

.line-height-170 {
  line-height: 170%; }

.line-height-175 {
  line-height: 175%; }

.line-height-180 {
  line-height: 180%; }

.line-height-185 {
  line-height: 185%; }

.line-height-190 {
  line-height: 190%; }

.line-height-195 {
  line-height: 195%; }

.line-height-200 {
  line-height: 200%; }

.letter-spacing-0 {
  letter-spacing: 0; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    @media (max-width: 1023.98px) {
      .zoom-enabled,
      .zoom-enabled * {
        font-size: 1vw !important;
        line-height: 4vw !important; } } } }

.back-shape-none {
  position: relative;
  overflow: hidden; }
  .back-shape-none:before, .back-shape-none:after {
    display: none;
    content: none; }

.back-shape-caret {
  position: relative;
  overflow: hidden; }
  .back-shape-caret:before, .back-shape-caret:after {
    position: absolute;
    display: none;
    width: 140px;
    height: 8000px;
    content: '';
    background-color: #f7f7f7;
    transform: rotate(22deg);
    z-index: -1; }
  .back-shape-caret:after {
    transform: rotate(-22deg); }
  @media (min-width: 768px) {
    .back-shape-caret:before, .back-shape-caret:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .back-shape-caret:before, .back-shape-caret:after {
      width: 140px; } }

.back-shape-angle {
  position: relative;
  overflow: hidden; }
  .back-shape-angle:after {
    position: absolute;
    top: 0;
    left: 0;
    border-left: 180vw solid #f7f7f7;
    border-bottom: 360px solid transparent;
    content: "";
    z-index: -1; }

.back-shape-rectangle {
  position: relative;
  overflow: hidden; }
  .back-shape-rectangle:after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%;
    background-color: #f7f7f7;
    content: "";
    z-index: -1; }

.back-shape-xs-up-caret {
  position: relative;
  overflow: hidden; }
  .back-shape-xs-up-caret:before, .back-shape-xs-up-caret:after {
    position: absolute;
    display: none;
    width: 140px;
    height: 8000px;
    content: '';
    background-color: #f7f7f7;
    transform: rotate(22deg);
    z-index: -1; }
  .back-shape-xs-up-caret:after {
    transform: rotate(-22deg); }
  @media (min-width: 768px) {
    .back-shape-xs-up-caret:before, .back-shape-xs-up-caret:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .back-shape-xs-up-caret:before, .back-shape-xs-up-caret:after {
      width: 140px; } }

.back-shape-xs-up-angle {
  position: relative;
  overflow: hidden; }
  .back-shape-xs-up-angle:after {
    position: absolute;
    top: 0;
    left: 0;
    border-left: 180vw solid #f7f7f7;
    border-bottom: 360px solid transparent;
    content: "";
    z-index: -1; }

.back-shape-xs-up-rectangle {
  position: relative;
  overflow: hidden; }
  .back-shape-xs-up-rectangle:after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50%;
    background-color: #f7f7f7;
    content: "";
    z-index: -1; }

/* not currently used
	.back-shape-xs-down-caret {
		@include media-breakpoint-down($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-xs-down-angle {
		@include media-breakpoint-down($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-xs-down-rectangle {
		@include media-breakpoint-down($bp) {
			@include background-shape("rectangle");
		}
	}
	*/
@media (min-width: 640px) {
  .back-shape-sm-up-caret {
    position: relative;
    overflow: hidden; }
    .back-shape-sm-up-caret:before, .back-shape-sm-up-caret:after {
      position: absolute;
      display: none;
      width: 140px;
      height: 8000px;
      content: '';
      background-color: #f7f7f7;
      transform: rotate(22deg);
      z-index: -1; }
    .back-shape-sm-up-caret:after {
      transform: rotate(-22deg); } }
  @media (min-width: 640px) and (min-width: 768px) {
    .back-shape-sm-up-caret:before, .back-shape-sm-up-caret:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 640px) and (min-width: 1024px) {
    .back-shape-sm-up-caret:before, .back-shape-sm-up-caret:after {
      width: 140px; } }

@media (min-width: 640px) {
  .back-shape-sm-up-angle {
    position: relative;
    overflow: hidden; }
    .back-shape-sm-up-angle:after {
      position: absolute;
      top: 0;
      left: 0;
      border-left: 180vw solid #f7f7f7;
      border-bottom: 360px solid transparent;
      content: "";
      z-index: -1; } }

@media (min-width: 640px) {
  .back-shape-sm-up-rectangle {
    position: relative;
    overflow: hidden; }
    .back-shape-sm-up-rectangle:after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 50%;
      background-color: #f7f7f7;
      content: "";
      z-index: -1; } }

/* not currently used
	.back-shape-sm-down-caret {
		@include media-breakpoint-down($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-sm-down-angle {
		@include media-breakpoint-down($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-sm-down-rectangle {
		@include media-breakpoint-down($bp) {
			@include background-shape("rectangle");
		}
	}
	*/
@media (min-width: 768px) {
  .back-shape-md-up-caret {
    position: relative;
    overflow: hidden; }
    .back-shape-md-up-caret:before, .back-shape-md-up-caret:after {
      position: absolute;
      display: none;
      width: 140px;
      height: 8000px;
      content: '';
      background-color: #f7f7f7;
      transform: rotate(22deg);
      z-index: -1; }
    .back-shape-md-up-caret:after {
      transform: rotate(-22deg); } }
  @media (min-width: 768px) and (min-width: 768px) {
    .back-shape-md-up-caret:before, .back-shape-md-up-caret:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 1024px) {
    .back-shape-md-up-caret:before, .back-shape-md-up-caret:after {
      width: 140px; } }

@media (min-width: 768px) {
  .back-shape-md-up-angle {
    position: relative;
    overflow: hidden; }
    .back-shape-md-up-angle:after {
      position: absolute;
      top: 0;
      left: 0;
      border-left: 180vw solid #f7f7f7;
      border-bottom: 360px solid transparent;
      content: "";
      z-index: -1; } }

@media (min-width: 768px) {
  .back-shape-md-up-rectangle {
    position: relative;
    overflow: hidden; }
    .back-shape-md-up-rectangle:after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 50%;
      background-color: #f7f7f7;
      content: "";
      z-index: -1; } }

/* not currently used
	.back-shape-md-down-caret {
		@include media-breakpoint-down($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-md-down-angle {
		@include media-breakpoint-down($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-md-down-rectangle {
		@include media-breakpoint-down($bp) {
			@include background-shape("rectangle");
		}
	}
	*/
@media (min-width: 1024px) {
  .back-shape-lg-up-caret {
    position: relative;
    overflow: hidden; }
    .back-shape-lg-up-caret:before, .back-shape-lg-up-caret:after {
      position: absolute;
      display: none;
      width: 140px;
      height: 8000px;
      content: '';
      background-color: #f7f7f7;
      transform: rotate(22deg);
      z-index: -1; }
    .back-shape-lg-up-caret:after {
      transform: rotate(-22deg); } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .back-shape-lg-up-caret:before, .back-shape-lg-up-caret:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .back-shape-lg-up-caret:before, .back-shape-lg-up-caret:after {
      width: 140px; } }

@media (min-width: 1024px) {
  .back-shape-lg-up-angle {
    position: relative;
    overflow: hidden; }
    .back-shape-lg-up-angle:after {
      position: absolute;
      top: 0;
      left: 0;
      border-left: 180vw solid #f7f7f7;
      border-bottom: 360px solid transparent;
      content: "";
      z-index: -1; } }

@media (min-width: 1024px) {
  .back-shape-lg-up-rectangle {
    position: relative;
    overflow: hidden; }
    .back-shape-lg-up-rectangle:after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 50%;
      background-color: #f7f7f7;
      content: "";
      z-index: -1; } }

/* not currently used
	.back-shape-lg-down-caret {
		@include media-breakpoint-down($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-lg-down-angle {
		@include media-breakpoint-down($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-lg-down-rectangle {
		@include media-breakpoint-down($bp) {
			@include background-shape("rectangle");
		}
	}
	*/
@media (min-width: 1500px) {
  .back-shape-xl-up-caret {
    position: relative;
    overflow: hidden; }
    .back-shape-xl-up-caret:before, .back-shape-xl-up-caret:after {
      position: absolute;
      display: none;
      width: 140px;
      height: 8000px;
      content: '';
      background-color: #f7f7f7;
      transform: rotate(22deg);
      z-index: -1; }
    .back-shape-xl-up-caret:after {
      transform: rotate(-22deg); } }
  @media (min-width: 1500px) and (min-width: 768px) {
    .back-shape-xl-up-caret:before, .back-shape-xl-up-caret:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 1500px) and (min-width: 1024px) {
    .back-shape-xl-up-caret:before, .back-shape-xl-up-caret:after {
      width: 140px; } }

@media (min-width: 1500px) {
  .back-shape-xl-up-angle {
    position: relative;
    overflow: hidden; }
    .back-shape-xl-up-angle:after {
      position: absolute;
      top: 0;
      left: 0;
      border-left: 180vw solid #f7f7f7;
      border-bottom: 360px solid transparent;
      content: "";
      z-index: -1; } }

@media (min-width: 1500px) {
  .back-shape-xl-up-rectangle {
    position: relative;
    overflow: hidden; }
    .back-shape-xl-up-rectangle:after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 50%;
      background-color: #f7f7f7;
      content: "";
      z-index: -1; } }

/* not currently used
	.back-shape-xl-down-caret {
		@include media-breakpoint-down($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-xl-down-angle {
		@include media-breakpoint-down($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-xl-down-rectangle {
		@include media-breakpoint-down($bp) {
			@include background-shape("rectangle");
		}
	}
	*/
.b-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-width: 1px; }

.bt-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-top-width: 1px; }

.bb-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-bottom-width: 1px; }

.bl-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-left-width: 1px; }

.br-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-right-width: 1px; }

.by-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-top-width: 1px;
  border-bottom-width: 1px; }

.bx-1 {
  border-width: 0;
  border-style: solid;
  border-color: #e1e1e1;
  border-right-width: 1px;
  border-left-width: 1px; }

@media (min-width: 768px) {
  .b-md-0 {
    border-width: 0; }
  .bt-md-0 {
    border-top-width: 0; }
  .bb-md-0 {
    border-bottom-width: 0; }
  .bl-md-0 {
    border-left-width: 0; }
  .br-md-0 {
    border-right-width: 0; }
  .by-md-0 {
    border-top-width: 0;
    border-bottom-width: 0; }
  .bx-md-0 {
    border-right-width: 0;
    border-left-width: 0; } }

.border-primary {
  border-color: #3cc1cc !important; }

.border-top {
  border-top-style: solid;
  border-top-color: #3cc1cc;
  border-top-width: calc((8 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .border-top {
      border-top-width: 8px; } }
  @media (min-width: 768px) {
    .border-top {
      border-top-width: calc((6 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .border-top {
      border-top-width: 6px; } }

.border-bottom {
  border-bottom-style: solid;
  border-bottom-color: #3cc1cc;
  border-bottom-width: calc((8 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .border-bottom {
      border-bottom-width: 8px; } }
  @media (min-width: 768px) {
    .border-bottom {
      border-bottom-width: calc((6 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .border-bottom {
      border-bottom-width: 6px; } }

button {
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer; }
  button svg {
    pointer-events: none; }
  button:disabled {
    cursor: default; }

.btn {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((30 / 640vw ) * 100);
  line-height: calc((32 / 640vw ) * 100);
  letter-spacing: calc((0.5 / 640vw ) * 100);
  margin-bottom: 0.5em;
  display: inline-block;
  white-space: nowrap;
  min-width: calc((180 / 640vw ) * 100);
  margin-bottom: 1rem;
  padding: 5px;
  border-width: 2px;
  background-color: #007697;
  color: #ffffff;
  line-height: 1.25em;
  text-transform: lowercase;
  cursor: default; }
  @media (min-width: 640px) {
    .btn {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 32px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    .btn {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  @supports not (-ms-high-contrast: none) {
    .btn {
      padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
  .btn.btn-sm, .btn-group-sm > .btn {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((26 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.55 / 640vw ) * 100);
    margin-bottom: 0.5em;
    display: inline-block;
    padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
    line-height: 1.25em;
    text-transform: lowercase; }
    @media (min-width: 640px) {
      .btn.btn-sm, .btn-group-sm > .btn {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 32px;
        letter-spacing: 0.55px; } }
    @media (min-width: 768px) {
      .btn.btn-sm, .btn-group-sm > .btn {
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-sm, .btn-group-sm > .btn {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  .btn:hover, .btn:focus {
    color: #ffffff;
    background-color: #031b21; }
  .btn .icon-svg {
    fill: #ffffff; }
  .btn.btn-outline:focus {
    background-color: #031b21;
    color: #ffffff; }
  .btn.btn-outline.btn-primary:focus {
    background-color: #b8146a;
    color: #ffffff; }
  .btn.btn-secondary {
    border-color: transparent; }
  @media (min-width: 640px) {
    .btn {
      min-width: 180px;
      margin-bottom: 0;
      padding: 22px 64px 25px;
      line-height: 1.25em; }
      .btn.btn-sm, .btn-group-sm > .btn {
        padding: 16px 64px 20px;
        line-height: 1.25em; } }
  @media (min-width: 768px) {
    .btn {
      min-width: 100px;
      padding: 13px 32px 14px;
      line-height: 1.25em; }
      .btn.btn-sm, .btn-group-sm > .btn {
        padding: 11px 32px 10px;
        line-height: 1.25em; } }
  .btn.btn-secondary {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    display: inline-block;
    white-space: nowrap;
    min-width: calc((180 / 640vw ) * 100);
    margin-bottom: 1rem;
    padding: 5px;
    border-width: 2px;
    background-color: #007697;
    color: #ffffff;
    line-height: 1.25em;
    text-transform: lowercase;
    cursor: default;
    background-color: #ffffff;
    color: #000000; }
    @media (min-width: 640px) {
      .btn.btn-secondary {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .btn.btn-secondary {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-secondary {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    @supports not (-ms-high-contrast: none) {
      .btn.btn-secondary {
        padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
    .btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((26 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.55 / 640vw ) * 100);
      margin-bottom: 0.5em;
      display: inline-block;
      padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
      line-height: 1.25em;
      text-transform: lowercase; }
      @media (min-width: 640px) {
        .btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary {
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 32px;
          letter-spacing: 0.55px; } }
      @media (min-width: 768px) {
        .btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary {
          font-size: calc((12 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .btn.btn-secondary:hover, .btn.btn-secondary:focus {
      color: #ffffff;
      background-color: #031b21; }
    .btn.btn-secondary .icon-svg {
      fill: #ffffff; }
    .btn.btn-secondary.btn-outline:focus {
      background-color: #031b21;
      color: #ffffff; }
    .btn.btn-secondary.btn-outline.btn-primary:focus {
      background-color: #b8146a;
      color: #ffffff; }
    .btn.btn-secondary.btn-secondary {
      border-color: transparent; }
    .btn.btn-secondary:hover, .btn.btn-secondary:focus {
      background-color: #007697;
      color: #ffffff; }
    @media (min-width: 640px) {
      .btn.btn-secondary {
        min-width: 180px;
        margin-bottom: 0;
        padding: 22px 64px 25px;
        line-height: 1.25em; }
        .btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary {
          padding: 16px 64px 20px;
          line-height: 1.25em; } }
    @media (min-width: 768px) {
      .btn.btn-secondary {
        min-width: 100px;
        padding: 13px 32px 14px;
        line-height: 1.25em; }
        .btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary {
          padding: 11px 32px 10px;
          line-height: 1.25em; } }
  .btn.btn-outline, .btn.btn-outline-primary {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    display: inline-block;
    white-space: nowrap;
    min-width: calc((180 / 640vw ) * 100);
    margin-bottom: 1rem;
    padding: 5px;
    border-width: 2px;
    background-color: #007697;
    color: #ffffff;
    line-height: 1.25em;
    text-transform: lowercase;
    cursor: default;
    background-color: #ffffff;
    color: #007697;
    border-color: #007697; }
    @media (min-width: 640px) {
      .btn.btn-outline, .btn.btn-outline-primary {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .btn.btn-outline, .btn.btn-outline-primary {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-outline, .btn.btn-outline-primary {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    @supports not (-ms-high-contrast: none) {
      .btn.btn-outline, .btn.btn-outline-primary {
        padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
    .btn.btn-outline.btn-sm, .btn-group-sm > .btn.btn-outline, .btn.btn-outline-primary.btn-sm, .btn-group-sm > .btn.btn-outline-primary {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((26 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.55 / 640vw ) * 100);
      margin-bottom: 0.5em;
      display: inline-block;
      padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
      line-height: 1.25em;
      text-transform: lowercase; }
      @media (min-width: 640px) {
        .btn.btn-outline.btn-sm, .btn-group-sm > .btn.btn-outline, .btn.btn-outline-primary.btn-sm, .btn-group-sm > .btn.btn-outline-primary {
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 32px;
          letter-spacing: 0.55px; } }
      @media (min-width: 768px) {
        .btn.btn-outline.btn-sm, .btn-group-sm > .btn.btn-outline, .btn.btn-outline-primary.btn-sm, .btn-group-sm > .btn.btn-outline-primary {
          font-size: calc((12 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-outline.btn-sm, .btn-group-sm > .btn.btn-outline, .btn.btn-outline-primary.btn-sm, .btn-group-sm > .btn.btn-outline-primary {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .btn.btn-outline:hover, .btn.btn-outline:focus, .btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus {
      color: #ffffff;
      background-color: #031b21; }
    .btn.btn-outline .icon-svg, .btn.btn-outline-primary .icon-svg {
      fill: #ffffff; }
    .btn.btn-outline.btn-outline:focus, .btn.btn-outline-primary.btn-outline:focus {
      background-color: #031b21;
      color: #ffffff; }
    .btn.btn-outline.btn-outline.btn-primary:focus, .btn.btn-outline-primary.btn-outline.btn-primary:focus {
      background-color: #b8146a;
      color: #ffffff; }
    .btn.btn-outline.btn-secondary, .btn.btn-outline-primary.btn-secondary {
      border-color: transparent; }
    .btn.btn-outline:hover, .btn.btn-outline-primary:hover {
      background-color: #007697;
      color: #ffffff; }
    .btn.btn-outline.btn-primary, .btn.btn-outline-primary.btn-primary {
      border-color: #b8146a;
      color: #b8146a; }
      .btn.btn-outline.btn-primary:hover, .btn.btn-outline-primary.btn-primary:hover {
        background-color: #b8146a;
        color: #ffffff; }
    @media (min-width: 640px) {
      .btn.btn-outline, .btn.btn-outline-primary {
        min-width: 180px;
        margin-bottom: 0;
        padding: 22px 64px 25px;
        line-height: 1.25em; }
        .btn.btn-outline.btn-sm, .btn-group-sm > .btn.btn-outline, .btn.btn-outline-primary.btn-sm, .btn-group-sm > .btn.btn-outline-primary {
          padding: 16px 64px 20px;
          line-height: 1.25em; } }
    @media (min-width: 768px) {
      .btn.btn-outline, .btn.btn-outline-primary {
        min-width: 100px;
        padding: 13px 32px 14px;
        line-height: 1.25em; }
        .btn.btn-outline.btn-sm, .btn-group-sm > .btn.btn-outline, .btn.btn-outline-primary.btn-sm, .btn-group-sm > .btn.btn-outline-primary {
          padding: 11px 32px 10px;
          line-height: 1.25em; } }
  .btn.btn-transparent {
    background-color: transparent; }
    .btn.btn-transparent:hover {
      background-color: transparent; }
  .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    display: inline-block;
    white-space: nowrap;
    min-width: calc((180 / 640vw ) * 100);
    margin-bottom: 1rem;
    padding: 5px;
    border-width: 2px;
    background-color: #007697;
    color: #ffffff;
    line-height: 1.25em;
    text-transform: lowercase;
    cursor: default;
    background-color: #c6c6c6;
    color: #000000;
    opacity: 1;
    border-color: transparent; }
    @media (min-width: 640px) {
      .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    @supports not (-ms-high-contrast: none) {
      .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
        padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
    .btn.disabled.btn-sm, .btn-group-sm > .btn.disabled, .btn:disabled.btn-sm, .btn-group-sm > .btn:disabled, .btn[aria-disabled="true"].btn-sm, .btn-group-sm > .btn[aria-disabled="true"] {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((26 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.55 / 640vw ) * 100);
      margin-bottom: 0.5em;
      display: inline-block;
      padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
      line-height: 1.25em;
      text-transform: lowercase; }
      @media (min-width: 640px) {
        .btn.disabled.btn-sm, .btn-group-sm > .btn.disabled, .btn:disabled.btn-sm, .btn-group-sm > .btn:disabled, .btn[aria-disabled="true"].btn-sm, .btn-group-sm > .btn[aria-disabled="true"] {
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 32px;
          letter-spacing: 0.55px; } }
      @media (min-width: 768px) {
        .btn.disabled.btn-sm, .btn-group-sm > .btn.disabled, .btn:disabled.btn-sm, .btn-group-sm > .btn:disabled, .btn[aria-disabled="true"].btn-sm, .btn-group-sm > .btn[aria-disabled="true"] {
          font-size: calc((12 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.disabled.btn-sm, .btn-group-sm > .btn.disabled, .btn:disabled.btn-sm, .btn-group-sm > .btn:disabled, .btn[aria-disabled="true"].btn-sm, .btn-group-sm > .btn[aria-disabled="true"] {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .btn.disabled:hover, .btn.disabled:focus, .btn:disabled:hover, .btn:disabled:focus, .btn[aria-disabled="true"]:hover, .btn[aria-disabled="true"]:focus {
      color: #ffffff;
      background-color: #031b21; }
    .btn.disabled .icon-svg, .btn:disabled .icon-svg, .btn[aria-disabled="true"] .icon-svg {
      fill: #ffffff; }
    .btn.disabled.btn-outline:focus, .btn:disabled.btn-outline:focus, .btn[aria-disabled="true"].btn-outline:focus {
      background-color: #031b21;
      color: #ffffff; }
    .btn.disabled.btn-outline.btn-primary:focus, .btn:disabled.btn-outline.btn-primary:focus, .btn[aria-disabled="true"].btn-outline.btn-primary:focus {
      background-color: #b8146a;
      color: #ffffff; }
    .btn.disabled.btn-secondary, .btn:disabled.btn-secondary, .btn[aria-disabled="true"].btn-secondary {
      border-color: transparent; }
    .btn.disabled:hover, .btn.disabled:focus, .btn:disabled:hover, .btn:disabled:focus, .btn[aria-disabled="true"]:hover, .btn[aria-disabled="true"]:focus {
      background-color: #c6c6c6; }
    .btn.disabled.btn-outline, .btn:disabled.btn-outline, .btn[aria-disabled="true"].btn-outline {
      background-color: #ffffff;
      border-color: #c6c6c6;
      color: #949494; }
    @media (min-width: 640px) {
      .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
        min-width: 180px;
        margin-bottom: 0;
        padding: 22px 64px 25px;
        line-height: 1.25em; }
        .btn.disabled.btn-sm, .btn-group-sm > .btn.disabled, .btn:disabled.btn-sm, .btn-group-sm > .btn:disabled, .btn[aria-disabled="true"].btn-sm, .btn-group-sm > .btn[aria-disabled="true"] {
          padding: 16px 64px 20px;
          line-height: 1.25em; } }
    @media (min-width: 768px) {
      .btn.disabled, .btn:disabled, .btn[aria-disabled="true"] {
        min-width: 100px;
        padding: 13px 32px 14px;
        line-height: 1.25em; }
        .btn.disabled.btn-sm, .btn-group-sm > .btn.disabled, .btn:disabled.btn-sm, .btn-group-sm > .btn:disabled, .btn[aria-disabled="true"].btn-sm, .btn-group-sm > .btn[aria-disabled="true"] {
          padding: 11px 32px 10px;
          line-height: 1.25em; } }
  .btn.btn-round {
    position: relative;
    width: calc((76 / 640vw ) * 100);
    min-width: 0;
    height: calc((76 / 640vw ) * 100);
    padding: 0.75em;
    border-radius: 50%;
    transition: all 0.2s ease;
    margin-bottom: 0; }
    .btn.btn-round .icon-svg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc((36 / 640vw ) * 100);
      height: calc((36 / 640vw ) * 100); }
    .btn.btn-round:hover {
      transform: scale(1.1); }
  .btn.btn-play {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent; }
    .btn.btn-play .icon-svg {
      width: calc((117 / 640vw ) * 100);
      height: calc((117 / 640vw ) * 100); }
    .btn.btn-play:hover {
      background-color: transparent; }
    @media (min-width: 768px) {
      .btn.btn-play .icon-svg {
        width: 7.5em;
        height: 7.5em; } }
  .btn + .btn {
    margin-left: 0.75em; }
  .btn:focus {
    box-shadow: none; }
  .btn.btn-accent {
    background-color: #b8146a; }
    .btn.btn-accent:hover, .btn.btn-accent:focus {
      color: #8a0f50;
      background-color: #ffffff;
      border-color: #8a0f50; }
    .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((30 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.5em;
      display: inline-block;
      white-space: nowrap;
      min-width: calc((180 / 640vw ) * 100);
      margin-bottom: 1rem;
      padding: 5px;
      border-width: 2px;
      background-color: #007697;
      color: #ffffff;
      line-height: 1.25em;
      text-transform: lowercase;
      cursor: default;
      background-color: #c6c6c6;
      color: #000000;
      opacity: 1;
      border-color: transparent; }
      @media (min-width: 640px) {
        .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 32px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
      @supports not (-ms-high-contrast: none) {
        .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
          padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
      .btn.btn-accent.disabled.btn-sm, .btn-group-sm > .btn.btn-accent.disabled, .btn.btn-accent:disabled.btn-sm, .btn-group-sm > .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"].btn-sm, .btn-group-sm > .btn.btn-accent[aria-disabled="true"] {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((26 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.55 / 640vw ) * 100);
        margin-bottom: 0.5em;
        display: inline-block;
        padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
        line-height: 1.25em;
        text-transform: lowercase; }
        @media (min-width: 640px) {
          .btn.btn-accent.disabled.btn-sm, .btn-group-sm > .btn.btn-accent.disabled, .btn.btn-accent:disabled.btn-sm, .btn-group-sm > .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"].btn-sm, .btn-group-sm > .btn.btn-accent[aria-disabled="true"] {
            font-size: 26px;
            font-size: 1.625rem;
            line-height: 32px;
            letter-spacing: 0.55px; } }
        @media (min-width: 768px) {
          .btn.btn-accent.disabled.btn-sm, .btn-group-sm > .btn.btn-accent.disabled, .btn.btn-accent:disabled.btn-sm, .btn-group-sm > .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"].btn-sm, .btn-group-sm > .btn.btn-accent[aria-disabled="true"] {
            font-size: calc((12 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .btn.btn-accent.disabled.btn-sm, .btn-group-sm > .btn.btn-accent.disabled, .btn.btn-accent:disabled.btn-sm, .btn-group-sm > .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"].btn-sm, .btn-group-sm > .btn.btn-accent[aria-disabled="true"] {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
      .btn.btn-accent.disabled:hover, .btn.btn-accent.disabled:focus, .btn.btn-accent:disabled:hover, .btn.btn-accent:disabled:focus, .btn.btn-accent[aria-disabled="true"]:hover, .btn.btn-accent[aria-disabled="true"]:focus {
        color: #ffffff;
        background-color: #031b21; }
      .btn.btn-accent.disabled .icon-svg, .btn.btn-accent:disabled .icon-svg, .btn.btn-accent[aria-disabled="true"] .icon-svg {
        fill: #ffffff; }
      .btn.btn-accent.disabled.btn-outline:focus, .btn.btn-accent:disabled.btn-outline:focus, .btn.btn-accent[aria-disabled="true"].btn-outline:focus {
        background-color: #031b21;
        color: #ffffff; }
      .btn.btn-accent.disabled.btn-outline.btn-primary:focus, .btn.btn-accent:disabled.btn-outline.btn-primary:focus, .btn.btn-accent[aria-disabled="true"].btn-outline.btn-primary:focus {
        background-color: #b8146a;
        color: #ffffff; }
      .btn.btn-accent.disabled.btn-secondary, .btn.btn-accent:disabled.btn-secondary, .btn.btn-accent[aria-disabled="true"].btn-secondary {
        border-color: transparent; }
      .btn.btn-accent.disabled:hover, .btn.btn-accent.disabled:focus, .btn.btn-accent:disabled:hover, .btn.btn-accent:disabled:focus, .btn.btn-accent[aria-disabled="true"]:hover, .btn.btn-accent[aria-disabled="true"]:focus {
        background-color: #c6c6c6; }
      .btn.btn-accent.disabled.btn-outline, .btn.btn-accent:disabled.btn-outline, .btn.btn-accent[aria-disabled="true"].btn-outline {
        background-color: #ffffff;
        border-color: #c6c6c6;
        color: #949494; }
      @media (min-width: 640px) {
        .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
          min-width: 180px;
          margin-bottom: 0;
          padding: 22px 64px 25px;
          line-height: 1.25em; }
          .btn.btn-accent.disabled.btn-sm, .btn-group-sm > .btn.btn-accent.disabled, .btn.btn-accent:disabled.btn-sm, .btn-group-sm > .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"].btn-sm, .btn-group-sm > .btn.btn-accent[aria-disabled="true"] {
            padding: 16px 64px 20px;
            line-height: 1.25em; } }
      @media (min-width: 768px) {
        .btn.btn-accent.disabled, .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"] {
          min-width: 100px;
          padding: 13px 32px 14px;
          line-height: 1.25em; }
          .btn.btn-accent.disabled.btn-sm, .btn-group-sm > .btn.btn-accent.disabled, .btn.btn-accent:disabled.btn-sm, .btn-group-sm > .btn.btn-accent:disabled, .btn.btn-accent[aria-disabled="true"].btn-sm, .btn-group-sm > .btn.btn-accent[aria-disabled="true"] {
            padding: 11px 32px 10px;
            line-height: 1.25em; } }
  .btn.btn-facebook {
    background-color: #39579a; }
    .btn.btn-facebook:hover, .btn.btn-facebook:focus {
      background-color: #2b4275;
      color: #ffffff; }
  .btn.btn-google {
    background-color: #bd3418; }
    .btn.btn-google:hover, .btn.btn-google:focus {
      background-color: #902812;
      color: #ffffff; }
  .btn.btn-grey {
    background-color: #bababa; }
    .btn.btn-grey:hover, .btn.btn-grey:focus {
      background-color: #a1a1a1;
      color: #000000; }

.btn-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  top: 0;
  width: calc((48 / 640vw ) * 100);
  min-width: 0;
  height: calc((48 / 640vw ) * 100);
  margin: 0.25em 0.5em;
  padding: 0;
  background-color: transparent;
  z-index: 50; }
  .btn-overlay .icon-svg {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc((40 / 640vw ) * 100);
    height: calc((40 / 640vw ) * 100); }
    .btn-overlay .icon-svg svg {
      fill: #ffffff; }
  .btn-overlay:hover, .btn-overlay:focus {
    background-color: transparent; }
    .btn-overlay:hover svg, .btn-overlay:focus svg {
      fill: #3cc1cc; }
  .btn-overlay .form-check-input {
    opacity: 0;
    pointer-events: none; }
    .btn-overlay .form-check-input ~ .icon-svg svg:first-child {
      display: block; }
    .btn-overlay .form-check-input ~ .icon-svg svg:last-child {
      display: none; }
    .btn-overlay .form-check-input:checked ~ .icon-svg svg:first-child {
      display: none; }
    .btn-overlay .form-check-input:checked ~ .icon-svg svg:last-child {
      display: block; }

.skip-link:focus {
  position: fixed;
  padding: 1em;
  background-color: #ffffff;
  font-size: 0.875rem;
  z-index: 1060; }

@media (min-width: 640px) {
  .btn.btn-round {
    width: 76px;
    height: 76px; }
    .btn.btn-round .icon-svg {
      width: 36px;
      height: 36px; }
  .btn.btn-dropdown {
    font-size: 28px; }
    .btn.btn-dropdown + .dropdown-menu {
      font-size: 28px; }
  .btn-overlay {
    width: 48px;
    min-width: 0;
    height: 48px;
    padding: 0; }
    .btn-overlay .icon-svg {
      width: 40px;
      height: 40px; } }

@media (min-width: 768px) {
  .btn.btn-round {
    width: 44px;
    height: 44px;
    padding: 0.75em;
    border-radius: 50%; }
    .btn.btn-round .icon-svg {
      width: 22px;
      height: 22px; }
  .btn.btn-dropdown {
    background-color: transparent;
    border: 0;
    font-size: 14px;
    font-weight: 500; }
    .btn.btn-dropdown + .dropdown-menu .dropdown-item {
      font-size: 14px;
      font-weight: 500; }
  .btn-overlay {
    top: 0;
    min-width: 0;
    margin: 0;
    padding: 0; }
    .btn-overlay .icon-svg {
      width: 24px;
      height: 24px; } }

.form-control,
select.form-control {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  height: calc((88 / 640vw ) * 100);
  margin-bottom: 1rem;
  padding-left: calc((34 / 640vw ) * 100);
  /* &[aria-disabled="true"] {
		color: $custom-select-disabled-color;
    	background-color: $custom-select-disabled-bg;
	} */ }
  @media (min-width: 640px) {
    .form-control,
    select.form-control {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .form-control,
    select.form-control {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .form-control,
    select.form-control {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .form-control.form-control-sm,
  select.form-control.form-control-sm {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    font-size: calc((26 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    padding-left: calc((30 / 640vw ) * 100); }
    @media (min-width: 640px) {
      .form-control.form-control-sm,
      select.form-control.form-control-sm {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 640px) {
      .form-control.form-control-sm,
      select.form-control.form-control-sm {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .form-control.form-control-sm,
      select.form-control.form-control-sm {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .form-control.form-control-sm,
    select.form-control.form-control-sm {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .form-control.form-control-sm,
    select.form-control.form-control-sm {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }

.user-is-tabbing .custom-control-input:focus ~ .custom-control-indicator {
  outline-style: solid !important;
  outline-color: Highlight !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: 3px; }

textarea.form-control {
  height: calc((390 / 640vw ) * 100); }

.custom-select-label {
  position: relative;
  display: block;
  margin: 0; }
  .custom-select-label .label {
    font-size: 1.75rem;
    position: absolute;
    top: calc((22 / 640vw ) * 100);
    left: calc((35 / 640vw ) * 100);
    pointer-events: none;
    transition: all 0.4s; }
    .custom-select-label .label.active {
      top: calc((8 / 640vw ) * 100);
      font-weight: 300; }
      .custom-select-label .label.active ~ .form-control {
        transition: all 0.4s;
        padding-top: calc((30 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .custom-select-label .label {
      font-size: 0.875rem;
      top: 14px;
      left: 19px; }
      .custom-select-label .label.active {
        top: 5px;
        font-size: 12px;
        font-weight: 500; }
        .custom-select-label .label.active ~ .form-control {
          padding-top: 20px; } }
  .custom-select-label .icon-svg {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
    top: 44%;
    right: calc((27 / 640vw ) * 100);
    width: calc((16 / 640vw ) * 100);
    height: calc((16 / 640vw ) * 100);
    pointer-events: none; }
  .custom-select-label.position-absolute {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .custom-select-label.position-absolute .icon-svg {
      top: 43%;
      right: 33px; }

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

.form-group .label {
  font-size: 1.75rem;
  position: absolute;
  top: calc((22 / 640vw ) * 100);
  left: calc((35 / 640vw ) * 100);
  pointer-events: none;
  transition: all 0.4s; }
  .form-group .label.active {
    top: calc((8 / 640vw ) * 100);
    font-weight: 300; }
    .form-group .label.active ~ .form-control {
      transition: all 0.4s;
      padding-top: calc((30 / 640vw ) * 100); }

@media (min-width: 768px) {
  .form-group .label {
    font-size: 0.875rem;
    top: 14px;
    left: 19px; }
    .form-group .label.active {
      top: 5px;
      font-size: 12px;
      font-weight: 500; }
      .form-group .label.active ~ .form-control {
        padding-top: 20px; } }

select.form-control {
  margin-bottom: calc((14 / 640vw ) * 100);
  color: #636c72; }
  select.form-control:not([size]):not([multiple]) {
    height: calc((88 / 640vw ) * 100); }

.input-group {
  height: calc((88 / 640vw ) * 100); }
  .input-group .label {
    z-index: 4; }
    .input-group .label.active ~ .form-control {
      font-weight: 400; }
  .input-group .btn {
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin: 0; }
    @media (min-width: 640px) {
      .input-group .btn {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
  .input-group.input-group-sm {
    height: calc((72 / 640vw ) * 100); }
    .input-group.input-group-sm .form-control {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      font-size: calc((26 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      margin-bottom: 1rem;
      padding-left: calc((30 / 640vw ) * 100); }
      @media (min-width: 640px) {
        .input-group.input-group-sm .form-control {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 640px) {
        .input-group.input-group-sm .form-control {
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .input-group.input-group-sm .form-control {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100);
          font-size: calc((12 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .input-group.input-group-sm .form-control {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .input-group.input-group-sm .form-control {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }

.form-check {
  padding-left: 0;
  margin-bottom: .5rem; }

.custom-control-indicator {
  display: block;
  position: absolute;
  left: 0; }

.custom-checkbox {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  margin: 0;
  padding: calc((2 / 640vw ) * 100) 0 0 calc((54 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .custom-checkbox {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .custom-checkbox {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .custom-checkbox {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .custom-checkbox:hover, .custom-checkbox:focus {
    color: #007697; }
  .custom-checkbox .custom-control-indicator {
    top: calc((2 / 640vw ) * 100);
    width: calc((42 / 640vw ) * 100);
    height: calc((42 / 640vw ) * 100);
    border: 1px solid #000000; }
    .custom-checkbox .custom-control-indicator .icon-svg {
      display: none;
      vertical-align: top; }
      .custom-checkbox .custom-control-indicator .icon-svg svg {
        fill: #007697; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
    border: 0;
    background-image: none;
    background-color: #ffffff; }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-indicator .icon-svg {
      display: inline-block; }
  .custom-checkbox .custom-control-description {
    margin-left: 0.25em; }
  .custom-checkbox.checkbox-sm .custom-control-indicator {
    width: calc((42 / 640vw ) * 100);
    height: calc((42 / 640vw ) * 100); }
  .custom-checkbox .custom-control-input[disabled] ~ .custom-control-indicator,
  .custom-checkbox .custom-control-input[disabled] ~ .custom-control-description {
    opacity: 0.3; }

.custom-radio .custom-control-indicator {
  top: calc((2 / 640vw ) * 100);
  width: calc((42 / 640vw ) * 100);
  height: calc((42 / 640vw ) * 100);
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  border: 0;
  background-image: none;
  background-color: #007697; }

.label-textbox {
  position: relative;
  height: calc((88 / 640vw ) * 100);
  padding: 3px 0 0 15px;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-transform: lowercase; }
  .label-textbox input {
    position: absolute;
    top: 0;
    left: 0;
    padding: 14px 15px 0 15px;
    background-color: transparent;
    text-transform: none; }
    .label-textbox input:focus {
      background-color: transparent; }

.intl-label {
  padding-left: 96px; }
  .intl-label .intl-tel-input.allow-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .intl-label .intl-tel-input.allow-dropdown .flag-container {
      border-right: 1px solid #2b2b2b; }
    .intl-label .intl-tel-input.allow-dropdown .selected-flag {
      width: 77px; }
      .intl-label .intl-tel-input.allow-dropdown .selected-flag .iti-flag {
        top: 50%;
        bottom: auto;
        left: 47%;
        transform: translate(-50%, -50%); }
    .intl-label .intl-tel-input.allow-dropdown input[type="tel"] {
      padding-left: 95px; }

.iti-flag {
  background-image: url("/modules/ilg-aah-jahia-assets/img/flags.png") !important; }

.form-group-number {
  padding: 30px 0;
  text-align: center;
  border-right: 1px solid #e1e1e1; }
  .form-group-number label {
    font-weight: 300; }
  .form-group-number .input-group-number {
    display: flex;
    flex-wrap: wrap;
    margin-right: -9.5px;
    margin-left: -9.5px;
    justify-content: center; }
    .form-group-number .input-group-number button {
      width: 40px;
      height: 40px;
      margin: 0;
      vertical-align: middle;
      align-self: center;
      opacity: 1;
      background: white; }
      .form-group-number .input-group-number button .icon-svg {
        width: 32px;
        height: 32px; }
      .form-group-number .input-group-number button[aria-disabled="true"], .form-group-number .input-group-number button:disabled {
        opacity: 0.25; }
    .form-group-number .input-group-number input[type="number"] {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((56 / 640vw ) * 100);
      line-height: calc((72 / 640vw ) * 100);
      letter-spacing: calc((5 / 640vw ) * 100);
      margin-bottom: 0.75em;
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
      font-weight: 500;
      display: inline-block;
      margin: 0;
      padding-right: 0;
      padding-left: 0.15em;
      font-size: 40px;
      text-align: center;
      border: 0;
      background: transparent;
      pointer-events: none;
      vertical-align: middle;
      width: auto;
      -moz-appearance: textfield; }
      @media (min-width: 640px) {
        .form-group-number .input-group-number input[type="number"] {
          font-size: 56px;
          font-size: 3.5rem;
          line-height: 72px;
          letter-spacing: 5px; } }
      @media (min-width: 768px) {
        .form-group-number .input-group-number input[type="number"] {
          font-size: calc((40 / 640vw ) * 100);
          line-height: calc((56 / 640vw ) * 100);
          letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-group-number .input-group-number input[type="number"] {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 56px;
      letter-spacing: 5.25px; } }
      .form-group-number .input-group-number input[type="number"] mark {
        padding: 0 0.25em 0 0.45em;
        background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
        box-decoration-break: clone; }
        @media screen and (min-width: 0\0) {
          .form-group-number .input-group-number input[type="number"] mark {
            padding: 0; } }
        @supports (-ms-ime-align: auto) {
          .form-group-number .input-group-number input[type="number"] mark {
            padding: 0; } }
      .form-group-number .input-group-number input[type="number"]:disabled {
        color: #000000; }
      .form-group-number .input-group-number input[type="number"]::-webkit-inner-spin-button, .form-group-number .input-group-number input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
  .form-group-number:first-child {
    margin-bottom: 20px;
    border-right: 0;
    border-bottom: 1px solid #e1e1e1; }
    .form-group-number:first-child > * {
      width: 50%; }
  .form-group-number:last-child {
    border: 0; }

@media (min-width: 640px) {
  .form-control,
  select.form-control {
    height: 88px;
    padding-left: 34px; }
    .form-control.form-control-sm,
    select.form-control.form-control-sm {
      padding-left: 30px; }
  textarea.form-control {
    height: 390px; }
  select.form-control:not([size]):not([multiple]) {
    height: 88px; }
  .label-textbox {
    height: 88px; }
  .input-group {
    height: 88px; }
    .input-group .btn {
      font-size: calc((30 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin: 0; } }
    @media (min-width: 640px) and (min-width: 640px) {
      .input-group .btn {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }

@media (min-width: 640px) {
    .input-group.input-group-sm {
      height: 72px; }
      .input-group.input-group-sm .form-control {
        padding-left: 30px; }
  .custom-checkbox {
    padding: 6px 0 0 55px; }
    .custom-checkbox .custom-control-indicator {
      top: 2px;
      width: 42px;
      height: 42px; }
    .custom-checkbox.checkbox-sm .custom-control-indicator {
      top: 4px;
      width: 42px;
      height: 42px; }
  .custom-radio .custom-control-indicator {
    top: 2px;
    width: 42px;
    height: 42px; }
  .form-group-number .input-group-number input[type="number"] {
    font-size: 100px; } }

.password-help {
  font-size: .75em;
  line-height: 18px; }

@media (min-width: 768px) {
  .form-control,
  select.form-control {
    font-size: calc((14 / 640vw ) * 100);
    line-height: calc((30 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    height: 48px;
    margin-bottom: 0.7rem;
    padding-left: 18px; } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-control,
    select.form-control {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 30px;
      letter-spacing: 0px; } }

@media (min-width: 768px) {
    .form-control.form-control-sm,
    select.form-control.form-control-sm {
      padding-left: 16px; }
  textarea.form-control {
    height: 160px; }
  .custom-select-label .icon-svg {
    top: 50%;
    right: 13px;
    width: 12px;
    height: 12px; }
  .custom-select-label .label.active ~ .form-control {
    padding-top: 14px; }
  select.form-control:not([size]):not([multiple]) {
    display: block;
    height: 48px; }
  .label-textbox {
    height: 48px; }
  .intl-label {
    padding-left: calc((96 / 1024vw ) * 100); }
    .intl-label .intl-tel-input.allow-dropdown .selected-flag {
      width: calc((77 / 1024vw ) * 100); }
    .intl-label .intl-tel-input.allow-dropdown input[type="tel"] {
      padding-left: calc((95 / 1024vw ) * 100); }
  .input-group {
    height: 48px; }
    .input-group .btn {
      font-size: calc((12 / 640vw ) * 100);
      line-height: calc((18 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
    @media (min-width: 768px) and (min-width: 640px) {
      .input-group .btn {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 18px;
        letter-spacing: 0.5px; } }

@media (min-width: 768px) {
    .input-group .label {
      font-weight: 400; }
      .input-group .label.active ~ .form-control {
        font-weight: 300; }
    .input-group.input-group-sm {
      height: 40px; }
      .input-group.input-group-sm .form-control {
        padding-left: 16px; }
      .input-group.input-group-sm .btn {
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((18 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
      @media (min-width: 768px) and (min-width: 640px) {
        .input-group.input-group-sm .btn {
          font-size: 12px;
          font-size: 0.75rem;
          line-height: 18px;
          letter-spacing: 0.5px; } }

@media (min-width: 768px) {
  .custom-checkbox {
    padding: 0 0 0 26px; }
    .custom-checkbox .custom-control-indicator {
      width: 18px;
      height: 18px; }
    .custom-checkbox.checkbox-sm .custom-control-indicator {
      top: 4px;
      width: 15px;
      height: 15px; }
  .custom-radio .custom-control-indicator {
    top: 4px;
    width: 14px;
    height: 14px; }
  .form-group-number {
    padding: 0; }
    .form-group-number .input-group-number button .icon-svg {
      width: 16px;
      height: 16px; }
    .form-group-number .input-group-number input[type="number"] {
      width: 70px;
      font-size: 50px; }
    .form-group-number:first-child {
      margin-bottom: 0;
      border-right: 1px solid #e1e1e1;
      border-bottom: 0; }
      .form-group-number:first-child > * {
        width: auto; } }

@media (min-width: 1024px) {
  .intl-label {
    padding-left: 96px; }
    .intl-label .intl-tel-input.allow-dropdown .selected-flag {
      width: 77px; }
    .intl-label .intl-tel-input.allow-dropdown input[type="tel"] {
      padding-left: 95px; } }

input::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

@media (max-width: 1023.98px) {
  .zoom-enabled .form-group-number .input-group-number input[type="number"] {
    font-size: 32px;
    line-height: 32px;
    max-width: 30%;
    width: 30%; } }

.form-factory {
  display: block;
  margin-bottom: 3rem; }
  .form-factory [vc-recaptcha] > div {
    margin: auto; }
  .form-factory .control-label {
    padding: 0;
    position: relative;
    display: block !important;
    width: auto;
    height: auto;
    text-align: left; }
  .form-factory .input-group {
    min-width: 18rem; }
  .form-factory .datepicker-popup-position {
    margin-bottom: 0; }
  .form-factory .btn {
    margin-left: 0; }
  .form-factory .left15 {
    margin-left: 0; }
  .form-factory ff-content-display {
    text-align: center; }
  .form-factory form {
    display: flex;
    flex-wrap: wrap;
    margin-right: -9.5px;
    margin-left: -9.5px;
    scroll-margin-top: 158px;
    justify-content: center; }
    .form-factory form > div {
      min-width: 50%; }
    .form-factory form > fieldset {
      position: relative;
      width: 100%;
      padding-right: 9.5px;
      padding-left: 9.5px;
      flex: 0 0 83.333333%;
      max-width: 83.333333%; }
      .form-factory form > fieldset > .row {
        justify-content: center; }
        .form-factory form > fieldset > .row > [class*="col-"] {
          padding-right: 0;
          padding-left: 0; }
        .form-factory form > fieldset > .row > .col-sm-4 {
          flex: 0 0 100%;
          max-width: 100%; }
        .form-factory form > fieldset > .row > .col-sm-12 .col-sm-6 {
          flex: 0 0 100%;
          max-width: 100%; }
  .form-factory select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none; }
    .form-factory select.form-control ~ div.arrow {
      font-size: 1.625rem;
      position: absolute;
      top: 10px;
      right: 10px;
      line-height: 1;
      color: #7c7c7c;
      pointer-events: none; }
  .form-factory ff-builder-input select.form-control ~ div.arrow {
    display: none; }
  .form-factory .fa-calendar:before {
    font-size: 1.6rem;
    position: absolute;
    top: calc((22 / 640vw ) * 100);
    right: calc((10 / 640vw ) * 100);
    content: '\01F4C5';
    font-style: normal;
    line-height: 1;
    color: #7c7c7c;
    pointer-events: none; }
  .form-factory .help-block.ng-scope {
    font-size: 0.875rem;
    display: block;
    margin: -5px 0 20px;
    color: #ce3a3f; }
  .form-factory .ng-invalid.ng-valid-parse {
    border-color: #ce3a3f; }
  .form-factory .input-group-btn {
    position: absolute;
    display: block;
    top: 1px;
    right: 12px;
    z-index: 10; }
    .form-factory .input-group-btn button {
      min-width: 1px;
      height: 30px;
      padding: 15px 18px;
      background-color: transparent; }
      .form-factory .input-group-btn button:hover {
        background-color: transparent; }
    .form-factory .input-group-btn .icon-svg {
      right: 3px;
      height: 20px;
      fill: #2b2b2b; }
      .form-factory .input-group-btn .icon-svg svg {
        width: 12px;
        height: 12px; }
  .form-factory .uib-datepicker-popup {
    margin-top: calc((40 / 640vw ) * 100);
    padding-top: 0; }
    .form-factory .uib-datepicker-popup .uib-daypicker {
      width: 100%; }
    .form-factory .uib-datepicker-popup th small {
      font-weight: 600; }
    .form-factory .uib-datepicker-popup .text-muted {
      opacity: 0.5; }
    .form-factory .uib-datepicker-popup em {
      font-weight: 300;
      opacity: 0.5; }
    .form-factory .uib-datepicker-popup .uib-button-bar {
      margin-top: 14px;
      text-align: center; }
      .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((30 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        margin-bottom: 0.5em;
        display: inline-block;
        white-space: nowrap;
        min-width: calc((180 / 640vw ) * 100);
        margin-bottom: 1rem;
        padding: 5px;
        border-width: 2px;
        background-color: #007697;
        color: #ffffff;
        line-height: 1.25em;
        text-transform: lowercase;
        cursor: default;
        width: auto;
        padding: 10px 20px;
        border-color: transparent; }
        @media (min-width: 640px) {
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 32px;
            letter-spacing: 0.5px; } }
        @media (min-width: 768px) {
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
        @supports not (-ms-high-contrast: none) {
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
            padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn {
          display: block;
          font-weight: 500;
          text-transform: none;
          font-size: calc((26 / 640vw ) * 100);
          line-height: calc((32 / 640vw ) * 100);
          letter-spacing: calc((0.55 / 640vw ) * 100);
          margin-bottom: 0.5em;
          display: inline-block;
          padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
          line-height: 1.25em;
          text-transform: lowercase; }
          @media (min-width: 640px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn {
              font-size: 26px;
              font-size: 1.625rem;
              line-height: 32px;
              letter-spacing: 0.55px; } }
          @media (min-width: 768px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn {
              font-size: calc((12 / 640vw ) * 100);
              line-height: calc((24 / 640vw ) * 100);
              letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding:hover, .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding:focus {
          color: #ffffff;
          background-color: #031b21; }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding .icon-svg {
          fill: #ffffff; }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-outline:focus {
          background-color: #031b21;
          color: #ffffff; }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-outline.btn-primary:focus {
          background-color: #b8146a;
          color: #ffffff; }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-secondary {
          border-color: transparent; }
        @media (min-width: 640px) {
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
            min-width: 180px;
            margin-bottom: 0;
            padding: 22px 64px 25px;
            line-height: 1.25em; }
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn {
              padding: 16px 64px 20px;
              line-height: 1.25em; } }
        @media (min-width: 768px) {
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding {
            min-width: 100px;
            padding: 13px 32px 14px;
            line-height: 1.25em; }
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn {
              padding: 11px 32px 10px;
              line-height: 1.25em; } }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
          display: block;
          font-weight: 500;
          text-transform: none;
          font-size: calc((30 / 640vw ) * 100);
          line-height: calc((32 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100);
          margin-bottom: 0.5em;
          display: inline-block;
          white-space: nowrap;
          min-width: calc((180 / 640vw ) * 100);
          margin-bottom: 1rem;
          padding: 5px;
          border-width: 2px;
          background-color: #007697;
          color: #ffffff;
          line-height: 1.25em;
          text-transform: lowercase;
          cursor: default;
          background-color: #ffffff;
          color: #007697;
          border-color: #007697;
          margin-right: 5px; }
          @media (min-width: 640px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
              font-size: 30px;
              font-size: 1.875rem;
              line-height: 32px;
              letter-spacing: 0.5px; } }
          @media (min-width: 768px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
              font-size: calc((14 / 640vw ) * 100);
              line-height: calc((24 / 640vw ) * 100);
              letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
          @supports not (-ms-high-contrast: none) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
              padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-info.btn {
            display: block;
            font-weight: 500;
            text-transform: none;
            font-size: calc((26 / 640vw ) * 100);
            line-height: calc((32 / 640vw ) * 100);
            letter-spacing: calc((0.55 / 640vw ) * 100);
            margin-bottom: 0.5em;
            display: inline-block;
            padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
            line-height: 1.25em;
            text-transform: lowercase; }
            @media (min-width: 640px) {
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-info.btn {
                font-size: 26px;
                font-size: 1.625rem;
                line-height: 32px;
                letter-spacing: 0.55px; } }
            @media (min-width: 768px) {
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-info.btn {
                font-size: calc((12 / 640vw ) * 100);
                line-height: calc((24 / 640vw ) * 100);
                letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-info.btn {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info:hover, .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info:focus {
            color: #ffffff;
            background-color: #031b21; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info .icon-svg {
            fill: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-outline:focus {
            background-color: #031b21;
            color: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-outline.btn-primary:focus {
            background-color: #b8146a;
            color: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-secondary {
            border-color: transparent; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info:hover {
            background-color: #007697;
            color: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-primary {
            border-color: #b8146a;
            color: #b8146a; }
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-primary:hover {
              background-color: #b8146a;
              color: #ffffff; }
          @media (min-width: 640px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
              min-width: 180px;
              margin-bottom: 0;
              padding: 22px 64px 25px;
              line-height: 1.25em; }
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-info.btn {
                padding: 16px 64px 20px;
                line-height: 1.25em; } }
          @media (min-width: 768px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info {
              min-width: 100px;
              padding: 13px 32px 14px;
              line-height: 1.25em; }
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-info.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-info.btn {
                padding: 11px 32px 10px;
                line-height: 1.25em; } }
        .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
          display: block;
          font-weight: 500;
          text-transform: none;
          font-size: calc((30 / 640vw ) * 100);
          line-height: calc((32 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100);
          margin-bottom: 0.5em;
          display: inline-block;
          white-space: nowrap;
          min-width: calc((180 / 640vw ) * 100);
          margin-bottom: 1rem;
          padding: 5px;
          border-width: 2px;
          background-color: #007697;
          color: #ffffff;
          line-height: 1.25em;
          text-transform: lowercase;
          cursor: default;
          background-color: #ffffff;
          color: #007697;
          border-color: #007697; }
          @media (min-width: 640px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
              font-size: 30px;
              font-size: 1.875rem;
              line-height: 32px;
              letter-spacing: 0.5px; } }
          @media (min-width: 768px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
              font-size: calc((14 / 640vw ) * 100);
              line-height: calc((24 / 640vw ) * 100);
              letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
          @supports not (-ms-high-contrast: none) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
              padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-danger.btn {
            display: block;
            font-weight: 500;
            text-transform: none;
            font-size: calc((26 / 640vw ) * 100);
            line-height: calc((32 / 640vw ) * 100);
            letter-spacing: calc((0.55 / 640vw ) * 100);
            margin-bottom: 0.5em;
            display: inline-block;
            padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
            line-height: 1.25em;
            text-transform: lowercase; }
            @media (min-width: 640px) {
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-danger.btn {
                font-size: 26px;
                font-size: 1.625rem;
                line-height: 32px;
                letter-spacing: 0.55px; } }
            @media (min-width: 768px) {
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-danger.btn {
                font-size: calc((12 / 640vw ) * 100);
                line-height: calc((24 / 640vw ) * 100);
                letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-danger.btn {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger:hover, .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger:focus {
            color: #ffffff;
            background-color: #031b21; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger .icon-svg {
            fill: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-outline:focus {
            background-color: #031b21;
            color: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-outline.btn-primary:focus {
            background-color: #b8146a;
            color: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-secondary {
            border-color: transparent; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger:hover {
            background-color: #007697;
            color: #ffffff; }
          .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-primary {
            border-color: #b8146a;
            color: #b8146a; }
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-primary:hover {
              background-color: #b8146a;
              color: #ffffff; }
          @media (min-width: 640px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
              min-width: 180px;
              margin-bottom: 0;
              padding: 22px 64px 25px;
              line-height: 1.25em; }
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-danger.btn {
                padding: 16px 64px 20px;
                line-height: 1.25em; } }
          @media (min-width: 768px) {
            .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger {
              min-width: 100px;
              padding: 13px 32px 14px;
              line-height: 1.25em; }
              .form-factory .uib-datepicker-popup .uib-button-bar .ng-binding.btn-danger.btn-sm, .form-factory .uib-datepicker-popup .uib-button-bar .btn-group-sm > .ng-binding.btn-danger.btn {
                padding: 11px 32px 10px;
                line-height: 1.25em; } }
    .form-factory .uib-datepicker-popup button.btn-sm, .form-factory .uib-datepicker-popup .btn-group-sm > button.btn {
      width: 42px;
      min-width: 1px;
      padding: 5px;
      background-color: transparent;
      color: #2b2b2b; }
      .form-factory .uib-datepicker-popup button.btn-sm:hover, .form-factory .uib-datepicker-popup .btn-group-sm > button.btn:hover {
        color: #3cc1cc;
        border-bottom: 1px solid #2b2b2b; }
      .form-factory .uib-datepicker-popup button.btn-sm.uib-left, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-left.btn, .form-factory .uib-datepicker-popup button.btn-sm.uib-right, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-right.btn {
        color: #2b2b2b; }
        .form-factory .uib-datepicker-popup button.btn-sm.uib-left i:before, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-left.btn i:before, .form-factory .uib-datepicker-popup button.btn-sm.uib-right i:before, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-right.btn i:before {
          font-style: normal; }
        .form-factory .uib-datepicker-popup button.btn-sm.uib-left:hover, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-left.btn:hover, .form-factory .uib-datepicker-popup button.btn-sm.uib-right:hover, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-right.btn:hover {
          border-color: transparent; }
      .form-factory .uib-datepicker-popup button.btn-sm.uib-left i:before, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-left.btn i:before {
        content: '<'; }
      .form-factory .uib-datepicker-popup button.btn-sm.uib-right i:before, .form-factory .uib-datepicker-popup .btn-group-sm > button.uib-right.btn i:before {
        content: '>'; }
    .form-factory .uib-datepicker-popup button.uib-title {
      width: 100%;
      background-color: #f7f7f7;
      text-transform: capitalize; }
      .form-factory .uib-datepicker-popup button.uib-title strong {
        font-weight: 600; }
      .form-factory .uib-datepicker-popup button.uib-title:hover {
        border-color: transparent;
        color: #2b2b2b; }
    .form-factory .uib-datepicker-popup button.active {
      border-bottom: 1px solid #2b2b2b; }
  .form-factory .datePickerMin .form-control {
    margin-bottom: 0.7rem; }
  .form-factory.group-travel-form strong[ng-bind-html="vm.currentForm.afterSubmissionText"] {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((36 / 640vw ) * 100);
    line-height: calc((37 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.55em; }
    @media (min-width: 640px) {
      .form-factory.group-travel-form strong[ng-bind-html="vm.currentForm.afterSubmissionText"] {
        font-size: 36px;
        font-size: 2.25rem;
        line-height: 37px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .form-factory.group-travel-form strong[ng-bind-html="vm.currentForm.afterSubmissionText"] {
        font-size: calc((20 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .form-factory.group-travel-form strong[ng-bind-html="vm.currentForm.afterSubmissionText"] {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  .form-factory.group-travel-form .control-label {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    margin-bottom: 0;
    padding-bottom: 6px; }
    @media (min-width: 640px) {
      .form-factory.group-travel-form .control-label {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .form-factory.group-travel-form .control-label {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .form-factory.group-travel-form .control-label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .form-factory.press-sign-up, .form-factory.a-list-sign-up {
    position: static; }
    .form-factory.press-sign-up fieldset, .form-factory.a-list-sign-up fieldset {
      flex: 0 0 100%;
      max-width: 100%;
      position: static; }
      .form-factory.press-sign-up fieldset ff-input, .form-factory.a-list-sign-up fieldset ff-input {
        width: 100%; }
        .form-factory.press-sign-up fieldset ff-input .col-sm-10, .form-factory.a-list-sign-up fieldset ff-input .col-sm-10 {
          padding: 0; }
        .form-factory.press-sign-up fieldset ff-input ~ .row, .form-factory.a-list-sign-up fieldset ff-input ~ .row {
          position: absolute;
          bottom: 21px;
          left: 0;
          width: 100%;
          margin: 0; }
          .form-factory.press-sign-up fieldset ff-input ~ .row [vc-recaptcha] > div, .form-factory.a-list-sign-up fieldset ff-input ~ .row [vc-recaptcha] > div {
            margin: auto; }
    .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"],
    .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p, .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"],
    .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p {
      font-weight: 300; }
      .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"], .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] samp, .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] .small,
      .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p,
      .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p samp,
      .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p .small, .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"], .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] samp, .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] .small,
      .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p,
      .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p samp,
      .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p .small {
        font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif;
        font-size: .875rem;
        line-height: 24px;
        letter-spacing: 0;
        text-transform: initial;
        font-weight: 300; }
      .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] strong,
      .form-factory.press-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p strong, .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] strong,
      .form-factory.a-list-sign-up strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p strong {
        font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif;
        font-size: .875rem;
        line-height: 24px;
        letter-spacing: 0;
        text-transform: initial;
        font-weight: 500; }
  @media (min-width: 640px) {
    .form-factory.press-sign-up fieldset ff-input {
      width: 65%; }
    .form-factory .form-control,
    .form-factory select.form-control {
      min-width: 18rem; } }
  @media (min-width: 768px) {
    .form-factory form {
      scroll-margin-top: 158px; }
      .form-factory form > fieldset {
        flex: 0 0 75%;
        max-width: 75%; }
        .form-factory form > fieldset > .row > .col-sm-4 {
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
          margin-bottom: 42px;
          padding-bottom: 30px;
          border-bottom: 1px solid #e1e1e1; }
    .form-factory .fa-calendar:before {
      top: 14px;
      right: 14px;
      font-size: 18px; }
    .form-factory .custom-select-label {
      margin-bottom: .7rem; }
    .form-factory .uib-datepicker-popup {
      margin-top: 0; }
    .form-factory.press-sign-up fieldset, .form-factory.a-list-sign-up fieldset {
      flex: 0 0 50%;
      max-width: 50%; }
      .form-factory.press-sign-up fieldset ff-input, .form-factory.a-list-sign-up fieldset ff-input {
        width: 100%; }
        .form-factory.press-sign-up fieldset ff-input ~ .row, .form-factory.a-list-sign-up fieldset ff-input ~ .row {
          transform: scale(0.8); } }
  @media (min-width: 1024px) {
    .form-factory form {
      scroll-margin-top: 182px; } }

.dropdown .btn-dropdown {
  margin-right: 2px;
  background-color: transparent;
  border: 0;
  font-size: calc((28 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b; }
  .dropdown .btn-dropdown + .dropdown-menu {
    width: 100%;
    min-width: 0;
    margin: 1px 0 0 -1px; }
    .dropdown .btn-dropdown + .dropdown-menu .dropdown-item {
      padding: 3px 1em 3px 0.5em;
      font-size: calc((28 / 640vw ) * 100);
      font-weight: 300; }

.dropdown .dropdown-for-carousel {
  top: auto !important; }

@media (min-width: 640px) {
  .dropdown .btn-dropdown {
    font-size: 28px; }
    .dropdown .btn-dropdown + .dropdown-menu .dropdown-item {
      font-size: 28px; } }

@media (min-width: 768px) {
  .dropdown .btn-dropdown {
    font-size: 14px;
    font-weight: 500; }
    .dropdown .btn-dropdown + .dropdown-menu .dropdown-item {
      font-size: 14px;
      font-weight: 500; } }

.carousel-factory {
  position: relative; }
  .carousel-factory .carousel-indicators {
    position: relative;
    height: 20px; }
    .carousel-factory .carousel-indicators .slick-dots:nth-child(even) {
      display: none !important; }
  .carousel-factory .slick-track {
    margin: 0 auto; }
  .carousel-factory .slick-dots {
    bottom: 0;
    left: 0;
    white-space: nowrap;
    display: none !important; }
    .carousel-factory .slick-dots li {
      display: none;
      width: 16px;
      height: 16px;
      margin: 0 2px; }
      .carousel-factory .slick-dots li button {
        display: none;
        width: 16px;
        height: 16px; }
        .carousel-factory .slick-dots li button:before {
          top: 4px;
          left: 4px;
          width: 12px;
          height: 12px;
          content: '';
          border-radius: 50%;
          opacity: 1;
          border: 1px solid #000000; }
    .carousel-factory .slick-dots .slick-active button:before {
      opacity: 1;
      background: #000000; }
  .carousel-factory .carousel-tabs {
    display: none; }
  .carousel-factory .slick-prev, .carousel-factory .slick-next {
    top: calc((16 / 640vw ) * 100);
    width: calc((46 / 640vw ) * 100);
    height: calc((46 / 640vw ) * 100);
    z-index: 990;
    background-color: #ffffff; }
    .carousel-factory .slick-prev:hover, .carousel-factory .slick-prev:focus, .carousel-factory .slick-next:hover, .carousel-factory .slick-next:focus {
      background-color: #ffffff; }
      .carousel-factory .slick-prev:hover svg, .carousel-factory .slick-prev:focus svg, .carousel-factory .slick-next:hover svg, .carousel-factory .slick-next:focus svg {
        fill: #007697; }
    .carousel-factory .slick-prev:before, .carousel-factory .slick-next:before {
      content: ''; }
    .carousel-factory .slick-prev .icon-svg, .carousel-factory .slick-next .icon-svg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc((16 / 640vw ) * 100);
      height: calc((16 / 640vw ) * 100); }
  .carousel-factory[data-arrows="bottom"] .slick-dots li, .carousel-factory[data-mobile-indicators] .slick-dots li {
    display: inline-block; }
    .carousel-factory[data-arrows="bottom"] .slick-dots li button, .carousel-factory[data-mobile-indicators] .slick-dots li button {
      display: block; }
  .carousel-factory[data-arrows="bottom"] .carousel, .carousel-factory[data-mobile-indicators] .carousel {
    margin: 0 auto; }
  .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow {
    position: relative;
    display: inline-block;
    right: auto;
    left: auto;
    margin: 25px 10px 0; }
  .carousel-factory[data-mobile-indicators="false"] .slick-dots li {
    display: none; }
  @media (max-width: 767.98px) {
    .carousel-factory .slick-prev {
      left: 0; }
    .carousel-factory .slick-next {
      right: 0; } }
  @media (max-width: 1023.98px) {
    .carousel-factory[data-arrows-mobile="none"] .slick-prev, .carousel-factory[data-arrows-mobile="none"] .slick-next {
      display: none !important; }
    .carousel-factory[data-arrows-mobile="sides"] .slick-prev {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
      margin-left: 0; }
    .carousel-factory[data-arrows-mobile="sides"] .slick-next {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
      margin-right: 0; }
    .carousel-factory[data-arrows-mobile="sides-quarter-top"] .slick-prev {
      position: absolute;
      top: 25%;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translateY(-50%);
      margin-left: 0; }
    .carousel-factory[data-arrows-mobile="sides-quarter-top"] .slick-next {
      position: absolute;
      top: 25%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
      margin-right: 0; }
    .carousel-factory[data-mobile-indicators="none"] .slick-dots {
      display: none !important; } }
  @media (min-width: 768px) {
    .carousel-factory .slick-prev, .carousel-factory .slick-next {
      top: 16px;
      width: 46px;
      height: 46px; }
      .carousel-factory .slick-prev .icon-svg, .carousel-factory .slick-next .icon-svg {
        width: 16px;
        height: 16px; }
    .carousel-factory[data-arrows-desktop="none"] .slick-prev, .carousel-factory[data-arrows-desktop="none"] .slick-next {
      display: none !important; } }
  @media (min-width: 768px) {
    .carousel-factory .carousel-tabs {
      display: block;
      margin: 30px 0; }
      .carousel-factory .carousel-tabs .slick-track {
        display: flex;
        justify-content: center; }
      .carousel-factory .carousel-tabs .slick-slide {
        cursor: pointer;
        list-style-type: none;
        margin-top: 3px;
        padding: 13px 20px;
        color: #2b2b2b;
        font-size: 14px;
        font-weight: 300;
        border-top: 1px solid #c8c8c8;
        text-decoration: none;
        text-transform: uppercase; }
        .carousel-factory .carousel-tabs .slick-slide.slick-current {
          margin-top: 0;
          padding: 11px 22px 13px 22px;
          font-weight: 500;
          border-top: 6px solid #007697; }
          .carousel-factory .carousel-tabs .slick-slide.slick-current:hover, .carousel-factory .carousel-tabs .slick-slide.slick-current:focus {
            color: #2b2b2b; }
        .carousel-factory .carousel-tabs .slick-slide:hover, .carousel-factory .carousel-tabs .slick-slide:focus {
          color: #063e4e; }
    .carousel-factory .slick-dots li button:before {
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px; }
    .carousel-factory .slick-prev, .carousel-factory .slick-next {
      width: calc((46 / 1024vw ) * 100);
      height: calc((46 / 1024vw ) * 100); }
      .carousel-factory .slick-prev .icon-svg, .carousel-factory .slick-next .icon-svg {
        width: calc((16 / 1024vw ) * 100);
        height: calc((16 / 1024vw ) * 100); }
    .carousel-factory[data-arrows="top"] .slick-prev {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0; }
    .carousel-factory[data-arrows="top"] .slick-next {
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto; }
    .carousel-factory[data-arrows="bottom"] {
      padding-bottom: 90px; }
      .carousel-factory[data-arrows="bottom"] .slick-prev {
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 70px;
        margin-left: -45px; }
      .carousel-factory[data-arrows="bottom"] .slick-next {
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 70px;
        margin-left: 45px; }
      .carousel-factory[data-arrows="bottom"] .slick-dots li {
        display: none; }
    .carousel-factory[data-arrows="none"] .slick-prev, .carousel-factory[data-arrows="none"] .slick-next {
      display: none !important; }
    .carousel-factory[data-mobile-indicators] .slick-dots li {
      display: none; }
    .carousel-factory[data-arrows="sides"] {
      width: 92.5%;
      margin: 0 auto; }
      .carousel-factory[data-arrows="sides"] .carousel {
        width: 90.5%;
        margin: 0 auto; }
      .carousel-factory[data-arrows="sides"] .slick-prev {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        transform: translateY(-50%);
        margin-top: calc((-23 / 1024vw ) * 100);
        margin-left: calc((-23 / 1024vw ) * 100); }
      .carousel-factory[data-arrows="sides"] .slick-next {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
        margin-top: calc((-23 / 1024vw ) * 100);
        margin-right: calc((-23 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .carousel-factory .slick-prev, .carousel-factory .slick-next {
      width: 46px;
      height: 46px; }
      .carousel-factory .slick-prev .icon-svg, .carousel-factory .slick-next .icon-svg {
        width: 16px;
        height: 16px; }
    .carousel-factory[data-arrows="sides"] {
      width: 100%; }
      .carousel-factory[data-arrows="sides"] .slick-prev {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        transform: translateY(-50%);
        margin-top: -23px;
        margin-left: -23px; }
      .carousel-factory[data-arrows="sides"] .slick-next {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
        margin-top: -23px;
        margin-right: -23px; } }
  .carousel-factory .slick-slide {
    text-transform: none; }
  .carousel-factory .carousel-tabs .slick-slide button {
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    color: #2b2b2b;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    text-transform: uppercase; }
  .carousel-factory .carousel-tabs .slick-slide.slick-current button {
    font-weight: 500; }

.activity .carousel-factory[data-slides-to-show='1'] .slick-prev,
.activity .carousel-factory[data-slides-to-show='1'] .slick-next {
  top: 50%; }

.badge {
  display: inline-block;
  margin: 0 0.5em; }

.badge-image {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -2px;
  padding: 3px 10px;
  font-size: calc((26 / 640vw ) * 100);
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #000000;
  background-color: #bff1ff;
  pointer-events: none;
  z-index: 50; }

.badge-descriptive {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((26 / 640vw ) * 100);
  line-height: calc((32 / 640vw ) * 100);
  letter-spacing: calc((0.55 / 640vw ) * 100);
  margin-bottom: 0.5em;
  display: inline-block;
  min-width: 72px;
  margin: 0 0.5em 0.5em 0;
  padding: 0.25em 0.85em;
  border: calc((1.5 / 640vw ) * 100) solid #7c7c7c;
  border-radius: 2em;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
  text-transform: lowercase;
  transition: all 0.1s ease; }
  @media (min-width: 640px) {
    .badge-descriptive {
      font-size: 26px;
      font-size: 1.625rem;
      line-height: 32px;
      letter-spacing: 0.55px; } }
  @media (min-width: 768px) {
    .badge-descriptive {
      font-size: calc((12 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .badge-descriptive {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  .badge-descriptive.color-stylish {
    color: #005a75;
    background-color: #f0f7f9;
    border-color: #fff; }
  .badge-descriptive.color-family {
    border-color: #f68d2d; }
  .badge-descriptive.color-budget {
    border-color: #7765a1; }
  .badge-descriptive.color-distinctive {
    border-color: #7a9d3e; }
  .badge-descriptive:hover {
    color: #000000; }

.badge-weather {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((36 / 640vw ) * 100);
  line-height: calc((48 / 640vw ) * 100);
  letter-spacing: calc((4 / 640vw ) * 100);
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: calc((unset / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  display: inline-block;
  max-width: 5em;
  margin: calc((28 / 640vw ) * 100) calc((19 / 640vw ) * 100);
  padding: 0.25em 1em 0.25em 1.2em;
  background-color: #ffffff;
  border-radius: 2em;
  top: 13vw; }
  @media (min-width: 640px) {
    .badge-weather {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 48px;
      letter-spacing: 4px; } }
  @media (min-width: 768px) {
    .badge-weather {
      font-size: calc((22 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .badge-weather {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }
  @media (min-width: 640px) {
    .badge-weather {
      font-size: unsetpx;
      font-size: unset/16rem;
      letter-spacing: 0px; } }
  .badge-weather:after {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    transform: translateY(-50%);
    display: inline-block;
    margin-left: 0.45em;
    content: '•';
    line-height: 1em;
    font-size: 25px;
    color: #3cc1cc;
    speak: none; }
  @media (min-width: 768px) {
    .badge-weather {
      margin: 25px 35px;
      top: 0; }
      .has-property-nav .badge-weather {
        top: 60px; } }

@media (min-width: 768px) {
  .has-property-nav .feature .btn-container {
    top: 60px; } }

@media (min-width: 640px) {
  .badge-image {
    font-size: 26px; }
  .badge-descriptive {
    border-width: 1.5px; } }

@media (min-width: 768px) {
  .badge-image {
    font-size: 12px; }
  .badge-descriptive {
    margin: 0;
    padding: 0 0.85em;
    border-width: 1px; } }

.rating {
  position: relative;
  display: inline-block;
  margin: 0;
  padding-right: 2px;
  overflow: hidden;
  vertical-align: middle; }
  .rating .rating-dots {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 50%;
    overflow: hidden; }
  .rating .rating-circles, .rating .rating-dots {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .rating .rating-circles .icon-svg, .rating .rating-dots .icon-svg {
      width: calc((24vw / 640vw ) * 100);
      height: calc((24vw / 640vw ) * 100);
      margin-right: -1px;
      flex-shrink: 0; }
      .rating .rating-circles .icon-svg svg, .rating .rating-dots .icon-svg svg {
        fill: #18b187; }
  .rating.rating-05 .rating-dots {
    width: 10%; }
  .rating.rating-10 .rating-dots {
    width: 20%; }
  .rating.rating-15 .rating-dots {
    width: 30%; }
  .rating.rating-20 .rating-dots {
    width: 40%; }
  .rating.rating-25 .rating-dots {
    width: 50%; }
  .rating.rating-30 .rating-dots {
    width: 60%; }
  .rating.rating-35 .rating-dots {
    width: 70%; }
  .rating.rating-40 .rating-dots {
    width: 80%; }
  .rating.rating-45 .rating-dots {
    width: 90%; }
  .rating.rating-50 .rating-dots {
    width: 100%; }
  @media (min-width: 640px) {
    .rating .rating-circles .icon-svg, .rating .rating-dots .icon-svg {
      width: 24px;
      height: 24px; } }
  @media (min-width: 768px) {
    .rating .rating-circles .icon-svg, .rating .rating-dots .icon-svg {
      width: calc((20vw / 1024vw ) * 100);
      height: calc((20vw / 1024vw ) * 100); }
    .rating.rating-sm .rating-circles .icon-svg, .rating.rating-sm .rating-dots .icon-svg {
      width: calc((16vw / 1024vw ) * 100);
      height: calc((16vw / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .rating .rating-circles .icon-svg, .rating .rating-dots .icon-svg {
      width: 20px;
      height: 20px; }
    .rating.rating-sm .rating-circles .icon-svg, .rating.rating-sm .rating-dots .icon-svg {
      width: 16px;
      height: 16px; } }

.rating-count {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  display: inline-block;
  margin: 0;
  vertical-align: middle; }
  @media (min-width: 640px) {
    .rating-count {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .rating-count {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .rating-count {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .rating-count a {
    text-decoration: underline;
    white-space: nowrap; }

ul.list-bulleted,
ol.list-bulleted {
  margin-bottom: 1.5em;
  padding-left: 0;
  list-style: none;
  line-height: calc((44 / 640vw ) * 100); }
  ul.list-bulleted li,
  ol.list-bulleted li {
    position: relative;
    padding-left: calc((36 / 640vw ) * 100); }
    ul.list-bulleted li:before,
    ol.list-bulleted li:before {
      position: absolute;
      top: calc((16 / 640vw ) * 100);
      left: 0;
      width: calc((12 / 640vw ) * 100);
      height: calc((12 / 640vw ) * 100);
      content: '';
      background-color: #000000;
      border-radius: 50%; }

ul.list-centered,
ol.list-centered {
  list-style: none; }
  ul.list-centered li,
  ol.list-centered li {
    text-align: center; }
    ul.list-centered li:before,
    ol.list-centered li:before {
      display: inline-block;
      position: relative;
      margin-right: 10px;
      width: 6px;
      height: 6px;
      content: '';
      background-color: #000000;
      border-radius: 50%;
      vertical-align: middle; }

ul.list-inline,
ol.list-inline {
  line-height: 1.75em; }
  ul.list-inline li,
  ol.list-inline li {
    display: inline-block;
    border: 0;
    vertical-align: middle; }
  ul.list-inline.list-col-4 li,
  ol.list-inline.list-col-4 li {
    display: block; }

ul.list-separated,
ol.list-separated {
  list-style: none; }
  ul.list-separated li,
  ol.list-separated li {
    border-bottom: 1px solid #e1e1e1; }
  ul.list-separated.list-inline li,
  ol.list-separated.list-inline li {
    border-bottom: 0; }
    ul.list-separated.list-inline li + li:before,
    ol.list-separated.list-inline li + li:before {
      display: inline-block;
      width: 1px;
      height: 1.25em;
      margin: 0 1em 0 0.75em;
      content: '';
      background-color: #e1e1e1;
      vertical-align: middle; }

ul.numbered,
ol.numbered {
  padding: 0;
  counter-reset: items;
  list-style: none; }
  ul.numbered > li,
  ol.numbered > li {
    counter-increment: items; }
    ul.numbered > li:nth-child(n+10) .number:empty:before,
    ol.numbered > li:nth-child(n+10) .number:empty:before {
      content: counter(items); }
    ul.numbered > li:nth-child(-n+9) .number:empty:before,
    ol.numbered > li:nth-child(-n+9) .number:empty:before {
      content: "0" counter(items); }
  ul.numbered .border-left,
  ol.numbered .border-left {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    padding-left: calc((36 / 640vw ) * 100);
    border-left: calc((10 / 640vw ) * 100) solid #3cc1cc; }
    @media (min-width: 640px) {
      ul.numbered .border-left,
      ol.numbered .border-left {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      ul.numbered .border-left,
      ol.numbered .border-left {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    ul.numbered .border-left,
    ol.numbered .border-left {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  ul.numbered .listicle-numbered-header,
  ol.numbered .listicle-numbered-header {
    font-size: .875rem;
    line-height: 24px; }

ul.list-icons li,
ol.list-icons li {
  margin: 0 0 calc((30 / 640vw ) * 100) 0;
  padding: 0 calc((10 / 640vw ) * 100);
  text-align: center;
  font-weight: 500; }
  ul.list-icons li .icon-svg,
  ol.list-icons li .icon-svg {
    display: block;
    width: calc((84 / 640vw ) * 100);
    height: calc((84 / 640vw ) * 100);
    margin: 0 auto calc((15 / 640vw ) * 100) auto; }

@media (min-width: 640px) {
  ul.list-bulleted,
  ol.list-bulleted {
    line-height: 44px; }
    ul.list-bulleted li,
    ol.list-bulleted li {
      padding-left: 36px; }
      ul.list-bulleted li:before,
      ol.list-bulleted li:before {
        top: 16px;
        width: 12px;
        height: 12px; }
  ul.list-icons li,
  ol.list-icons li {
    margin: 0 0 30px 0;
    padding: 0 10px; }
    ul.list-icons li .icon-svg,
    ol.list-icons li .icon-svg {
      width: 84px;
      height: 84px;
      margin: 0 auto 15px auto; } }

@media (min-width: 768px) {
  ul.list-bulleted,
  ol.list-bulleted {
    line-height: 24px; }
    ul.list-bulleted li,
    ol.list-bulleted li {
      padding-left: 26px; }
      ul.list-bulleted li:before,
      ol.list-bulleted li:before {
        top: 10px;
        width: 6px;
        height: 6px; }
  ul.list-separated.list-inline,
  ol.list-separated.list-inline {
    line-height: inherit; }
  ul.numbered .border-left,
  ol.numbered .border-left {
    padding-left: 15px;
    border-width: 6px; }
  ul.list-icons li,
  ol.list-icons li {
    margin: 0 0 calc((20 / 1024vw ) * 100) 0; }
    ul.list-icons li .icon-svg,
    ol.list-icons li .icon-svg {
      width: calc((32 / 1024vw ) * 100);
      height: calc((32 / 1024vw ) * 100); } }

@media (min-width: 1024px) {
  ul.list-icons li,
  ol.list-icons li {
    margin: 0 0 10px 0; }
    ul.list-icons li .icon-svg,
    ol.list-icons li .icon-svg {
      display: inline-block;
      width: 32px;
      height: 32px;
      margin: 0 10px 0 0;
      vertical-align: middle;
      text-align: left; } }

dl.list-inline dt {
  float: left;
  margin-right: 0.4em;
  font-weight: inherit; }

dl.list-inline dd {
  margin-bottom: 0; }

@media (min-width: 768px) {
  [data-filter="other"] .col-12 {
    flex: 0 0 50%;
    max-width: 50%; } }

[data-filter="destination"] .list-group .list-group-item.parent {
  border-top: 0;
  padding-top: 2em; }
  [data-filter="destination"] .list-group .list-group-item.parent:first-of-type {
    padding-top: 0; }
  [data-filter="destination"] .list-group .list-group-item.parent .headline-6 {
    padding: 0;
    margin: 0; }

@media (min-width: 1024px) {
  [data-filter="destination"] .list-group {
    display: block;
    column-count: 2;
    column-rule-style: solid;
    column-gap: 70px;
    column-rule-width: 1px;
    column-rule-color: #e1e1e1; } }

.modal .modal-dialog {
  margin: 0 auto; }
  .modal .modal-dialog.calendar-flyout {
    max-width: 900px; }
    .modal .modal-dialog.calendar-flyout .modal-header {
      position: relative;
      z-index: 1; }
    .modal .modal-dialog.calendar-flyout .modal-content {
      min-height: 100vh; }

.modal#hotel-flight-modal .modal-dialog,
.modal#hotel-flight-modal .modal-content,
.modal#hotel-flight-modal .modal-body {
  height: 100%; }

.modal .hotel-flight {
  border: 0;
  height: 87%; }

.modal .modal-header {
  display: block;
  margin-top: calc((40 / 640vw ) * 100); }
  .modal .modal-header .close {
    padding: 0.25em;
    margin: 0; }

.modal .modal-title {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((36 / 640vw ) * 100);
  line-height: calc((37 / 640vw ) * 100);
  letter-spacing: calc((0.5 / 640vw ) * 100);
  margin-bottom: 0.55em;
  padding: 20px 0px; }
  @media (min-width: 640px) {
    .modal .modal-title {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 37px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    .modal .modal-title {
      font-size: calc((20 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .modal .modal-title {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

.modal .close {
  position: absolute;
  top: calc((34 / 640vw ) * 100);
  right: calc((40 / 640vw ) * 100);
  opacity: 1;
  font-size: 1.3rem; }
  .modal .close .icon-svg {
    width: calc((33 / 640vw ) * 100);
    height: calc((33 / 640vw ) * 100); }

.modal video {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: center;'; }

.modal.modal-video .modal-body {
  margin-top: 15%;
  padding: 0; }

.modal.modal-video .modal-content {
  background: none; }

.modal.modal-video .close {
  top: 18px;
  right: 15px; }
  .modal.modal-video .close .icon-svg {
    width: 1.7em;
    height: 1.7em;
    fill: #ffffff;
    background-color: #007697;
    border-radius: 50%;
    transition: background-color 0.3s; }
  .modal.modal-video .close svg {
    padding: 10px; }
  .modal.modal-video .close:hover .icon-svg {
    background-color: #004e64; }

.modal.modal-olapic .modal-content, .modal.modal-olapic .modal-header, .modal.modal-olapic .modal-body, .modal.modal-olapic .modal-footer, .modal.modal-black .modal-content, .modal.modal-black .modal-header, .modal.modal-black .modal-body, .modal.modal-black .modal-footer {
  background-color: #000000; }

.modal.modal-olapic figure, .modal.modal-black figure {
  float: none;
  width: calc((400 / 640vw ) * 100);
  margin: 0 auto; }
  .modal.modal-olapic figure picture, .modal.modal-black figure picture {
    position: relative;
    overflow: hidden;
    width: calc((400 / 640vw ) * 100);
    height: calc((400 / 640vw ) * 100); }
    .modal.modal-olapic figure picture img, .modal.modal-black figure picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }
  .modal.modal-olapic figure figcaption, .modal.modal-black figure figcaption {
    margin-top: 1em;
    text-align: left;
    color: #ffffff; }
    .modal.modal-olapic figure figcaption p, .modal.modal-black figure figcaption p {
      color: #ffffff; }
    .modal.modal-olapic figure figcaption [data-profile-photo], .modal.modal-black figure figcaption [data-profile-photo] {
      display: flex;
      width: 48px;
      height: 48px; }
  .modal.modal-olapic figure .olapic, .modal.modal-black figure .olapic {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    font-size: calc((26 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .modal.modal-olapic figure .olapic, .modal.modal-black figure .olapic {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 640px) {
      .modal.modal-olapic figure .olapic, .modal.modal-black figure .olapic {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .modal.modal-olapic figure .olapic, .modal.modal-black figure .olapic {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .modal.modal-olapic figure .olapic, .modal.modal-black figure .olapic {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .modal.modal-olapic figure .olapic, .modal.modal-black figure .olapic {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .modal.modal-olapic figure .olapic .icon-svg, .modal.modal-black figure .olapic .icon-svg {
      width: 48px;
      height: 48px;
      margin-left: 0.5em; }
      .modal.modal-olapic figure .olapic .icon-svg svg, .modal.modal-black figure .olapic .icon-svg svg {
        fill: #ffffff; }
    .modal.modal-olapic figure .olapic a, .modal.modal-black figure .olapic a {
      text-decoration: none; }
      .modal.modal-olapic figure .olapic a:hover, .modal.modal-olapic figure .olapic a:focus, .modal.modal-black figure .olapic a:hover, .modal.modal-black figure .olapic a:focus {
        color: inherit; }

.modal.modal-olapic .slick-prev, .modal.modal-black .slick-prev {
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%); }

.modal.modal-olapic .slick-next, .modal.modal-black .slick-next {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%); }

.modal.modal-olapic .slick-prev, .modal.modal-olapic .slick-next, .modal.modal-black .slick-prev, .modal.modal-black .slick-next {
  background-color: transparent;
  border: 0; }
  .modal.modal-olapic .slick-prev .icon-svg, .modal.modal-olapic .slick-next .icon-svg, .modal.modal-black .slick-prev .icon-svg, .modal.modal-black .slick-next .icon-svg {
    width: 48px;
    height: 48px; }
  .modal.modal-olapic .slick-prev svg, .modal.modal-olapic .slick-next svg, .modal.modal-black .slick-prev svg, .modal.modal-black .slick-next svg {
    fill: #ffffff; }
  .modal.modal-olapic .slick-prev:hover, .modal.modal-olapic .slick-prev:focus, .modal.modal-olapic .slick-next:hover, .modal.modal-olapic .slick-next:focus, .modal.modal-black .slick-prev:hover, .modal.modal-black .slick-prev:focus, .modal.modal-black .slick-next:hover, .modal.modal-black .slick-next:focus {
    background-color: transparent;
    border: 0; }
    .modal.modal-olapic .slick-prev:hover svg, .modal.modal-olapic .slick-prev:focus svg, .modal.modal-olapic .slick-next:hover svg, .modal.modal-olapic .slick-next:focus svg, .modal.modal-black .slick-prev:hover svg, .modal.modal-black .slick-prev:focus svg, .modal.modal-black .slick-next:hover svg, .modal.modal-black .slick-next:focus svg {
      fill: #f7f7f7; }

.modal.modal-olapic .close, .modal.modal-black .close {
  color: #ffffff; }
  .modal.modal-olapic .close .icon-svg, .modal.modal-black .close .icon-svg {
    width: 24px;
    height: 24px;
    margin: 12px;
    fill: #ffffff; }

.modal.modal-travel-log .modal-title {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((36 / 640vw ) * 100);
  line-height: calc((48 / 640vw ) * 100);
  letter-spacing: calc((4 / 640vw ) * 100);
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 500; }
  @media (min-width: 640px) {
    .modal.modal-travel-log .modal-title {
      font-size: 36px;
      font-size: 2.25rem;
      line-height: 48px;
      letter-spacing: 4px; } }
  @media (min-width: 768px) {
    .modal.modal-travel-log .modal-title {
      font-size: calc((22 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .modal.modal-travel-log .modal-title {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }

.modal.modal-upload .btn-upload {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden; }
  .modal.modal-upload .btn-upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    opacity: 0; }

.modal.modal-carousel-gallery {
  text-align: center; }
  .modal.modal-carousel-gallery .modal-body {
    padding: calc((15 / 640vw ) * 100) 0 0; }
  .modal.modal-carousel-gallery .d-block-track {
    width: 100% !important; }
    .modal.modal-carousel-gallery .d-block-track .slick-slide {
      display: block !important;
      width: 100% !important; }
  .modal.modal-carousel-gallery .slick-initialized .slick-slide {
    display: table-cell; }
  .modal.modal-carousel-gallery .slick-slide {
    position: relative;
    float: none;
    vertical-align: middle; }
  .modal.modal-carousel-gallery .carousel-factory[data-arrows="bottom"] {
    padding: 0; }
    .modal.modal-carousel-gallery .carousel-factory[data-arrows="bottom"] .carousel {
      margin: 0 auto calc((30 / 640vw ) * 100); }
    .modal.modal-carousel-gallery .carousel-factory[data-arrows="bottom"] .slick-arrow {
      margin-top: 0;
      margin-bottom: 0; }
  .modal.modal-carousel-gallery .carousel-indicators {
    opacity: 0; }
  .modal.modal-carousel-gallery.wide img {
    width: 100%;
    max-width: 100%; }
  .modal.modal-carousel-gallery .slick-arrow.slick-hidden {
    display: none; }

.modal.modal-social .at-resp-share-element.at-mobile .at-label {
  display: inline-block; }

.modal .additional-info {
  line-height: 1.1;
  font-size: 0.8rem;
  font-style: italic;
  margin-top: 25px; }

.modal .sign-up-success-close {
  text-align: center;
  margin-top: 35px; }
  .modal .sign-up-success-close .btn {
    font-weight: bold;
    cursor: pointer; }

@media (max-width: 767.98px) {
  .modal .modal-dialog {
    max-width: none; } }

@media (min-width: 768px) {
  .modal#hotel-flight-modal .modal-dialog {
    height: auto; }
  .modal .hotel-flight {
    height: 476px; }
  .modal .modal-dialog {
    margin: 30px auto; }
    .modal .modal-dialog.calendar-flyout {
      transform: translateY(-50%);
      top: 50%;
      margin: 0 auto; }
      .modal .modal-dialog.calendar-flyout .modal-content {
        min-height: 0; }
      .modal .modal-dialog.calendar-flyout .loader-bg {
        top: 0; }
  .modal .modal-header {
    margin-top: 0;
    padding: 26px; }
  .modal .modal-body {
    padding: 15px; }
  .modal .close {
    top: 17px;
    right: 17px; }
    .modal .close .icon-svg {
      width: 1em;
      height: 1em; }
  .modal.modal-video .modal-body {
    margin-top: 0; }
  .modal.modal-video .close {
    top: 19px;
    right: -37px; }
  .modal.modal-olapic .slick-prev, .modal.modal-black .slick-prev {
    margin-left: -15px; }
  .modal.modal-olapic .slick-next, .modal.modal-black .slick-next {
    margin-right: -15px; }
  .modal.modal-olapic figure, .modal.modal-black figure {
    width: 500px;
    margin: 0 auto; }
    .modal.modal-olapic figure picture, .modal.modal-black figure picture {
      width: 500px;
      height: 500px;
      margin: 0 auto; }
  .modal.modal-carousel-gallery .modal-body {
    padding: 15px 0 50px; }
  .modal.modal-carousel-gallery .carousel-factory[data-arrows="bottom"] .carousel {
    margin: 0 auto 50px; }
  .modal.modal-carousel-gallery .carousel-factory[data-arrows="bottom"] .slick-arrow {
    bottom: -20px;
    margin-top: 25px; } }

@media (min-width: 1024px) {
  .modal.modal-olapic .slick-prev, .modal.modal-black .slick-prev {
    margin-left: 20px; }
  .modal.modal-olapic .slick-next, .modal.modal-black .slick-next {
    margin-right: 20px; } }

.modal-backdrop.show {
  opacity: 0.6; }

[data-toggle="modal"] img.lazyloading {
  opacity: 1; }

.tooltip.show {
  opacity: 1; }

.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
  margin-top: -3px; }
  .tooltip.bs-tooltip-right .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before {
    border-width: 7.5px 0.4rem 7.5px 0; }

.tooltip-inner {
  color: black;
  text-align: center;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 276px;
  padding: .5rem .75rem; }
  @media (min-width: 768px) {
    .tooltip-inner {
      z-index: 900; } }

.accordion .card {
  border-width: 1px 0 1px 0; }
  .accordion .card .card-header {
    position: relative;
    background-color: transparent;
    border: 0; }
    .accordion .card .card-header .collapsed .icon-svg {
      transform: rotate(0) translateY(-50%); }
    .accordion .card .card-header .icon-svg {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
      margin: 0 1em 0 0;
      transform: rotate(-180deg) translateY(50%);
      transition: transform 0.5s; }
      .accordion .card .card-header .icon-svg svg {
        fill: #2b2b2b; }
  .accordion .card .card-block {
    border: 0; }
  .accordion .card + .card {
    border-top: 0; }

.accordion #terms-header button[data-toggle="collapse"] {
  font-family: Roboto;
  font-weight: inherit;
  letter-spacing: inherit;
  color: inherit;
  width: 100%; }

.activity .carousel figure {
  height: 100%; }

.a-list-landing-details .slick-track {
  display: flex; }

.a-list-landing-details .slick-slide {
  height: auto; }
  .a-list-landing-details .slick-slide img {
    display: inline; }

.a-list-landing-details .col, .a-list-landing-details .slick-initialized .slick-slide {
  display: flex; }

.a-list-landing-details .a-list-status-item .stars {
  margin: calc((40 / 640vw ) * 100) 0; }
  .a-list-landing-details .a-list-status-item .stars .icon-svg {
    width: calc((24 / 640vw ) * 100);
    height: calc((24 / 640vw ) * 100);
    margin: auto; }
    .a-list-landing-details .a-list-status-item .stars .icon-svg svg {
      fill: #000000; }

.a-list-landing-details .a-list-status-item ul.list-unstyled {
  margin-top: calc((10 / 640vw ) * 100); }
  .a-list-landing-details .a-list-status-item ul.list-unstyled li {
    margin: 0 0 calc((30 / 640vw ) * 100) 0; }

.a-list-landing-details footer .headline {
  margin: calc((60 / 640vw ) * 100) 0 calc((40 / 640vw ) * 100) 0; }

.a-list-landing-details footer .btn {
  width: 100%; }

@media (min-width: 640px) {
  .a-list-landing-details .a-list-status-item .stars {
    margin: 40px 0; }
    .a-list-landing-details .a-list-status-item .stars .icon-svg {
      width: 24px;
      height: 24px;
      margin: 0 8px; }
  .a-list-landing-details .a-list-status-item ul.list-unstyled {
    margin-top: calc((10 / 640vw ) * 100); }
    .a-list-landing-details .a-list-status-item ul.list-unstyled li {
      margin: 0 0 30px 0; } }

@media (min-width: 768px) {
  .a-list-landing-details .a-list-status-item .stars {
    margin: 20px 0; }
    .a-list-landing-details .a-list-status-item .stars .icon-svg {
      width: 14px;
      height: 14px;
      margin: 0; }
  .a-list-landing-details .a-list-status-item ul.list-unstyled {
    margin-top: 10px; }
    .a-list-landing-details .a-list-status-item ul.list-unstyled li {
      margin: 0 0 20px 0; }
  .a-list-landing-details footer .headline {
    margin: 20px 0 40px 0; }
  .a-list-landing-details footer .btn {
    width: auto; } }

.a-list-landing-intro {
  padding-bottom: calc((80 / 640vw ) * 100); }
  .a-list-landing-intro ul.list-unstyled {
    display: inline-block;
    margin: calc((30 / 640vw ) * 100) auto calc((50vw / 640vw ) * 100) auto;
    text-align: left; }
    .a-list-landing-intro ul.list-unstyled li {
      display: flex;
      margin-bottom: calc((40 / 640vw ) * 100); }
      .a-list-landing-intro ul.list-unstyled li .headline {
        display: inline-block;
        padding-top: calc((16 / 640vw ) * 100); }
      .a-list-landing-intro ul.list-unstyled li .icon {
        display: flex;
        width: calc((64 / 640vw ) * 100);
        height: calc((64 / 640vw ) * 100);
        margin-right: calc((36 / 640vw ) * 100); }
  .a-list-landing-intro .btn {
    width: 100%; }
  @media (min-width: 640px) {
    .a-list-landing-intro {
      padding-bottom: 80px; }
      .a-list-landing-intro ul.list-unstyled {
        margin: 30px auto 50px auto; }
        .a-list-landing-intro ul.list-unstyled li {
          margin-bottom: 40px; }
          .a-list-landing-intro ul.list-unstyled li .icon {
            width: 64px;
            height: 64px;
            margin-right: 36px; }
          .a-list-landing-intro ul.list-unstyled li .headline {
            padding-top: 16px; } }
  @media (min-width: 768px) {
    .a-list-landing-intro {
      padding-bottom: calc((68 / 1024vw ) * 100); }
      .a-list-landing-intro ul.list-unstyled {
        display: block;
        margin: calc((40 / 1024vw ) * 100) 0;
        text-align: center; }
        .a-list-landing-intro ul.list-unstyled li {
          display: inline-block;
          margin: 0 calc((20 / 1024vw ) * 100);
          text-align: center; }
          .a-list-landing-intro ul.list-unstyled li .headline {
            display: block; }
          .a-list-landing-intro ul.list-unstyled li .icon {
            display: block;
            width: calc((42 / 1024vw ) * 100);
            height: calc((42 / 1024vw ) * 100);
            margin: 0 auto; }
      .a-list-landing-intro .btn {
        width: auto; } }
  @media (min-width: 1024px) {
    .a-list-landing-intro {
      padding-bottom: 68px; }
      .a-list-landing-intro ul.list-unstyled {
        margin: 40px 0; }
        .a-list-landing-intro ul.list-unstyled li {
          margin: 0 40px; }
          .a-list-landing-intro ul.list-unstyled li .icon {
            width: 42px;
            height: 42px; } }

.a-list-status-item .stars {
  margin: calc((40 / 640vw ) * 100) 0; }
  .a-list-status-item .stars .icon-svg {
    width: calc((24 / 640vw ) * 100);
    height: calc((24 / 640vw ) * 100);
    margin: 0 calc((8 / 640vw ) * 100); }
    .a-list-status-item .stars .icon-svg svg {
      fill: #000000; }

.a-list-status-item ul.list-unstyled {
  margin-top: calc((10 / 640vw ) * 100); }
  .a-list-status-item ul.list-unstyled li {
    margin: 0 0 calc((30 / 640vw ) * 100) 0; }

@media (min-width: 640px) {
  .a-list-status-item {
    padding: 80px 0 30px 0;
    border-bottom-width: 12px; }
    .a-list-status-item .stars {
      margin: 40px 0; }
      .a-list-status-item .stars .icon-svg {
        width: 24px;
        height: 24px;
        margin: 0 8px; }
    .a-list-status-item ul.list-unstyled {
      margin-top: calc((10 / 640vw ) * 100); }
      .a-list-status-item ul.list-unstyled li {
        margin: 0 0 30px 0; } }

@media (min-width: 768px) {
  .a-list-status-item .stars {
    margin: 20px 0; }
    .a-list-status-item .stars .icon-svg {
      width: 14px;
      height: 14px;
      margin: 0; }
  .a-list-status-item ul.list-unstyled {
    margin-top: 10px; }
    .a-list-status-item ul.list-unstyled li {
      margin: 0 0 20px 0; } }

.account-modal .modal-content {
  padding: calc((25 / 640vw ) * 100) calc((50 / 640vw ) * 100) calc((150 / 640vw ) * 100); }

.account-modal .modal-header {
  padding-bottom: 0;
  text-align: center; }

.account-modal .modal-body button[type='submit'] {
  width: 100%;
  margin-top: 2rem; }

.account-modal .modal-body a {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  margin-bottom: 1rem;
  text-decoration: underline; }
  @media (min-width: 640px) {
    .account-modal .modal-body a {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .account-modal .modal-body a {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .account-modal .modal-body a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .account-modal .modal-body a:hover, .account-modal .modal-body a:focus {
    color: #007697; }

.account-modal .modal-footer {
  position: relative;
  display: block;
  margin: calc((35 / 640vw ) * 100) calc((15 / 640vw ) * 100) 0;
  padding: calc((50 / 640vw ) * 100) 0 0;
  border-top: 1px solid #000000;
  text-align: center;
  justify-content: unset; }
  .account-modal .modal-footer .text-divider {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    position: absolute;
    top: 0;
    margin-bottom: 1rem;
    padding: 0 14px;
    transform: translate(-50%, -54%);
    background-color: #ffffff; }
    @media (min-width: 640px) {
      .account-modal .modal-footer .text-divider {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .account-modal .modal-footer .text-divider {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .account-modal .modal-footer .text-divider {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .account-modal .modal-footer .btn {
    padding-right: 0;
    padding-left: calc((83 / 640vw ) * 100);
    flex: 1;
    text-align: center;
    border: 0; }
    .account-modal .modal-footer .btn:last-child {
      margin-left: 15px; }
    .account-modal .modal-footer .btn .icon-svg {
      position: absolute;
      top: 0;
      left: 0;
      width: calc((83 / 640vw ) * 100);
      height: 100%; }
      .account-modal .modal-footer .btn .icon-svg svg {
        padding: 8px; }
    .account-modal .modal-footer .btn.btn-facebook .icon-svg {
      background-color: #2b4275; }
    .account-modal .modal-footer .btn.btn-google .icon-svg {
      background-color: #902812; }
  .account-modal .modal-footer p {
    margin-bottom: 0; }

.account-modal .d-flex .form-group {
  margin-bottom: 0; }
  .account-modal .d-flex .form-group:last-child {
    margin-left: 15px; }

@media (min-width: 768px) {
  .account-modal .modal-content {
    padding: 15px 50px 25px; }
  .account-modal .modal-body a {
    padding-top: 0; }
  .account-modal .modal-body button[type='submit'] {
    margin-top: 0.7rem; }
  .account-modal .modal-footer {
    margin: 5px 15px 0;
    padding-top: 26px; }
    .account-modal .modal-footer .btn {
      padding-left: 44px; }
      .account-modal .modal-footer .btn .icon-svg {
        width: 44px; } }

.amenities-collage {
  height: calc((580 / 640vw ) * 100);
  margin: 55px auto; }
  .amenities-collage figure {
    position: absolute;
    margin: 0; }
    .amenities-collage figure picture {
      position: relative;
      overflow: hidden;
      display: block;
      height: 100%; }
      .amenities-collage figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .amenities-collage .image-1 {
    top: calc((36 / 640vw ) * 100);
    left: calc((68 / 640vw ) * 100);
    width: calc((244 / 640vw ) * 100);
    height: calc((244 / 640vw ) * 100); }
  .amenities-collage .image-2 {
    top: calc((292 / 640vw ) * 100);
    left: calc((118 / 640vw ) * 100);
    width: calc((192 / 640vw ) * 100);
    height: calc((142 / 640vw ) * 100); }
  .amenities-collage .image-3 {
    top: 0;
    left: calc((324 / 640vw ) * 100);
    width: calc((194 / 640vw ) * 100);
    height: calc((194 / 640vw ) * 100); }
  .amenities-collage .image-4 {
    top: calc((205 / 640vw ) * 100);
    left: calc((324 / 640vw ) * 100);
    width: calc((248 / 640vw ) * 100);
    height: calc((169 / 640vw ) * 100); }
  .amenities-collage .image-5 {
    top: calc((386 / 640vw ) * 100);
    left: calc((324 / 640vw ) * 100);
    width: calc((140 / 640vw ) * 100);
    height: calc((140 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .amenities-collage {
      height: calc((600 / 1024vw ) * 100); }
      .amenities-collage .image-1 {
        top: calc((25 / 1024vw ) * 100);
        left: calc((3 / 1024vw ) * 100);
        width: calc((307 / 1024vw ) * 100);
        height: calc((307 / 1024vw ) * 100); }
      .amenities-collage .image-2 {
        top: calc((355 / 1024vw ) * 100);
        left: calc((82 / 1024vw ) * 100);
        width: calc((228 / 1024vw ) * 100);
        height: calc((169 / 1024vw ) * 100); }
      .amenities-collage .image-3 {
        top: 0;
        left: calc((327 / 1024vw ) * 100);
        width: calc((226 / 1024vw ) * 100);
        height: calc((226 / 1024vw ) * 100); }
      .amenities-collage .image-4 {
        top: calc((243 / 1024vw ) * 100);
        left: calc((327 / 1024vw ) * 100);
        width: calc((467 / 1024vw ) * 100);
        height: calc((319 / 1024vw ) * 100); }
      .amenities-collage .image-5 {
        top: calc((81 / 1024vw ) * 100);
        left: calc((571 / 1024vw ) * 100);
        width: calc((145 / 1024vw ) * 100);
        height: calc((145 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .amenities-collage {
      height: 600px; }
      .amenities-collage .image-1 {
        top: 25px;
        left: 3px;
        width: 307px;
        height: 307px; }
      .amenities-collage .image-2 {
        top: 350px;
        left: 85px;
        width: 225px;
        height: 169px; }
      .amenities-collage .image-3 {
        top: 0;
        left: 327px;
        width: 226px;
        height: 226px; }
      .amenities-collage .image-4 {
        top: 243px;
        left: 327px;
        width: 467px;
        height: 319px; }
      .amenities-collage .image-5 {
        top: 81px;
        left: 571px;
        width: 145px;
        height: 145px; } }

.amenities-listing {
  padding: 0 12px; }
  .amenities-listing figure picture {
    max-width: calc((306 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .amenities-listing figure picture {
      max-width: calc((306 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .amenities-listing figure picture {
      max-width: 306px; } }

.featured-activity {
  padding: 1em 0.75em 1em;
  text-align: center; }
  .featured-activity .number {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    left: 10px;
    margin: 0.25em 0 0 0.25em; }
  .featured-activity figure {
    margin: 0 auto calc((80 / 640vw ) * 100) auto;
    text-align: center; }
    .featured-activity figure picture {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: calc((300 / 640vw ) * 100);
      height: calc((300 / 640vw ) * 100);
      margin: 0; }
      .featured-activity figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .featured-activity .category {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    font-size: calc((unset / 640vw ) * 100);
    letter-spacing: calc((0.5vw / 640vw ) * 100);
    margin-bottom: 1rem;
    font-weight: 300; }
    @media (min-width: 640px) {
      .featured-activity .category {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .featured-activity .category {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .featured-activity .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    @media (min-width: 640px) {
      .featured-activity .category {
        font-size: unsetpx;
        font-size: unset/16rem;
        letter-spacing: 0.5pxpx; } }
  @media (min-width: 768px) {
    .featured-activity {
      text-align: left; }
      .featured-activity .number {
        left: 0; }
      .featured-activity figure {
        margin: 0 auto calc((20 / 1024vw ) * 100) auto; }
        .featured-activity figure picture {
          width: calc((192 / 1024vw ) * 100);
          height: calc((192 / 1024vw ) * 100); }
      .featured-activity .category {
        margin-bottom: 0.25em; } }
  @media (min-width: 1024px) {
    .featured-activity figure {
      margin: 0 auto 20px auto; }
      .featured-activity figure picture {
        width: 192px;
        height: 192px; } }

.destination-grouping {
  padding: 44px 0;
  text-align: center;
  border-top: 1px solid #efefef; }
  .destination-grouping figure {
    padding: 0 35px; }
  .destination-grouping .collapsible p {
    padding: 0 35px; }
  .destination-grouping .collapsible figure {
    padding: 0; }
  .destination-grouping .collapsing {
    height: calc((110 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .destination-grouping {
      padding: 75px 0;
      text-align: left; }
      .destination-grouping figure {
        padding: 0; }
      .destination-grouping .collapsible p {
        padding: 0; } }

.best-of-listing {
  position: relative;
  padding: 1em 0 0.5em; }
  .best-of-listing .number {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    margin: 0.25em 0 0 calc((16 / 640vw ) * 100); }
  .best-of-listing figure {
    display: inline-block;
    margin-bottom: 1em; }
    .best-of-listing figure picture {
      position: relative;
      overflow: hidden;
      width: calc((447 / 640vw ) * 100);
      height: calc((290 / 640vw ) * 100); }
      .best-of-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .best-of-listing .content {
    width: calc((447 / 640vw ) * 100);
    margin: auto;
    text-align: left;
    vertical-align: top; }
  .best-of-listing footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .best-of-listing footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .best-of-listing footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .best-of-listing footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .best-of-listing footer .btn {
      color: #ffffff;
      text-decoration: none; }
  @media (min-width: 640px) {
    .best-of-listing .number {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      margin-left: 30px; }
    .best-of-listing figure picture {
      width: 447px;
      height: 290px; }
    .best-of-listing .content {
      width: 447px; } }
  @media (min-width: 768px) {
    .best-of-listing {
      padding: 1.25em calc((50 / 1024vw ) * 100);
      text-align: left; }
      .best-of-listing .number {
        margin-left: calc((65 / 1024vw ) * 100); }
      .best-of-listing figure {
        display: inline-block;
        margin: 0 0 0 calc((70 / 1024vw ) * 100); }
        .best-of-listing figure picture {
          width: calc((389 / 1024vw ) * 100);
          height: calc((252 / 1024vw ) * 100);
          padding-right: calc((15 / 1024vw ) * 100); }
      .best-of-listing .content {
        display: inline-block;
        width: 35%;
        margin: 0.25em 0 0 1.5em; } }
  @media (min-width: 1024px) {
    .best-of-listing {
      padding: 1.25em 50px 2.5em; }
      .best-of-listing .number {
        margin-left: 65px; }
      .best-of-listing figure {
        margin-left: 70px; }
        .best-of-listing figure picture {
          width: 389px;
          height: 252px; } }

.biography {
  position: relative;
  padding: calc((55 / 640vw ) * 100) 0 calc((40 / 640vw ) * 100) 0;
  border-top: 1px solid #e1e1e1; }
  .biography figure {
    max-width: 294px;
    margin: 0 auto calc((30 / 640vw ) * 100); }
  .biography .blockquote {
    margin: 40px 0 0 0; }
  .biography .accent {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: calc((164 / 640vw ) * 100);
    height: calc((164 / 640vw ) * 100);
    z-index: 50;
    filter: drop-shadow(-12px 3px 14px rgba(0, 0, 0, 0.3)); }
    .biography .accent.accent-top-right {
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translate(0, -50%); }
    .biography .accent.accent-top-left {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      transform: translate(0, -50%); }
    .biography .accent.accent-bottom-right {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      transform: translate(0, 50%); }
    .biography .accent.accent-bottom-left {
      position: absolute;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform: translate(0, 50%); }
  .biography .collapsible-btn {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    text-decoration: underline; }
    @media (min-width: 640px) {
      .biography .collapsible-btn {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .biography .collapsible-btn {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .biography .collapsible-btn {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .biography .collapsible-btn:hover {
      color: #007697; }
  .biography:last-child {
    border-bottom: 1px solid #e1e1e1; }
  @media (min-width: 640px) {
    .biography {
      padding: 55px 0 40px 0; } }
  @media (min-width: 768px) {
    .biography {
      padding: 30px 0;
      border-top: 1px solid #e1e1e1; }
      .biography figure {
        margin-bottom: 0; }
      .biography .blockquote {
        margin: 48px 0 0 0; }
      .biography .accent {
        width: calc((166vw / 1024vw ) * 100);
        height: calc((166vw / 1024vw ) * 100); }
        .biography .accent.accent-top-right {
          transform: translate(50%, -50%); }
        .biography .accent.accent-top-left {
          transform: translate(-50%, -50%); }
        .biography .accent.accent-bottom-right {
          transform: translate(50%, 50%); }
        .biography .accent.accent-bottom-left {
          transform: translate(-50%, 50%); }
      .biography .collapsible-btn {
        margin: 0; }
      .biography .collapsible.collapsible-lg.collapsing {
        height: 162px; } }
  @media (min-width: 1024px) {
    .biography .accent {
      width: 166px;
      height: 166px; } }

.booking-slider .carousel {
  margin-right: 0;
  margin-left: 0; }

.booking-slider article {
  position: relative;
  text-align: center; }
  .booking-slider article figure {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    overflow: hidden; }
    .booking-slider article figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%; }
      .booking-slider article figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .booking-slider article .accent {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    pointer-events: none; }
  .booking-slider article .content {
    width: 100%;
    text-align: center;
    margin-bottom: 900px; }
    .booking-slider article .content .headline-3 {
      padding: 0 calc((78 / 640vw ) * 100); }
    .booking-slider article .content .content-footer {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem;
      text-decoration: underline; }
      @media (min-width: 640px) {
        .booking-slider article .content .content-footer {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .booking-slider article .content .content-footer {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .booking-slider article .content .content-footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @supports not (-ms-high-contrast: none) {
    .booking-slider article .content {
      margin-bottom: calc((645 / 640vw ) * 100); } }

.booking-slider .layout-1 .image-1 {
  top: calc((206 / 640vw ) * 100);
  left: calc((70 / 640vw ) * 100);
  width: calc((294 / 640vw ) * 100);
  height: calc((294 / 640vw ) * 100); }

.booking-slider .layout-1 .image-2 {
  top: calc((179 / 640vw ) * 100);
  left: calc((376 / 640vw ) * 100);
  width: calc((192 / 640vw ) * 100);
  height: calc((192 / 640vw ) * 100); }

.booking-slider .layout-1 .image-3 {
  top: calc((383 / 640vw ) * 100);
  left: calc((376 / 640vw ) * 100);
  width: calc((192 / 640vw ) * 100);
  height: calc((192 / 640vw ) * 100); }

.booking-slider .layout-1 .image-4 {
  top: calc((512 / 640vw ) * 100);
  left: calc((70 / 640vw ) * 100);
  width: calc((295 / 640vw ) * 100);
  height: calc((202 / 640vw ) * 100); }

.booking-slider .layout-1 .accent {
  top: calc((599 / 640vw ) * 100);
  left: calc((388 / 640vw ) * 100);
  width: calc((164 / 640vw ) * 100);
  height: calc((164 / 640vw ) * 100); }

.booking-slider .slick-arrow {
  top: calc((25 / 640vw ) * 100); }

.booking-slider .slick-prev {
  left: calc((20 / 640vw ) * 100); }

.booking-slider .slick-next {
  right: calc((20 / 640vw ) * 100); }

.booking-slider .carousel-indicators {
  display: none; }

@media (min-width: 768px) {
  .booking-slider .carousel-indicators {
    position: relative;
    display: block;
    height: 20px;
    margin: 20px 0; }
  .booking-slider .slick-dots li {
    display: inline-block; }
    .booking-slider .slick-dots li button {
      display: block; }
  .booking-slider article {
    overflow: hidden; }
    .booking-slider article .content .headline-3 {
      padding: 0; }
  .booking-slider .layout-1 {
    height: calc((500 / 1024vw ) * 100); }
    .booking-slider .layout-1 .image-1 {
      top: 0;
      left: calc((15vw / 1024vw ) * 100);
      width: calc((470vw / 1024vw ) * 100);
      height: calc((470vw / 1024vw ) * 100); }
    .booking-slider .layout-1 .image-2 {
      top: calc((348vw / 1024vw ) * 100);
      left: calc((505vw / 1024vw ) * 100);
      width: calc((144vw / 1024vw ) * 100);
      height: calc((144vw / 1024vw ) * 100); }
    .booking-slider .layout-1 .image-3 {
      top: calc((348vw / 1024vw ) * 100);
      left: calc((665vw / 1024vw ) * 100);
      width: calc((144vw / 1024vw ) * 100);
      height: calc((144vw / 1024vw ) * 100); }
    .booking-slider .layout-1 .image-4 {
      top: calc((120vw / 1024vw ) * 100);
      left: calc((505vw / 1024vw ) * 100);
      width: calc((307vw / 1024vw ) * 100);
      height: calc((210vw / 1024vw ) * 100); }
    .booking-slider .layout-1 .accent {
      top: calc((90vw / 1024vw ) * 100);
      left: auto;
      right: 0;
      width: calc((96vw / 1024vw ) * 100);
      height: calc((96vw / 1024vw ) * 100); }
    .booking-slider .layout-1 .content {
      position: absolute;
      left: calc((495vw / 1024vw ) * 100);
      width: calc((307vw / 1024vw ) * 100);
      height: calc((250vw / 1024vw ) * 100);
      margin: 0;
      padding-top: calc((25vw / 1024vw ) * 100); }
      .booking-slider .layout-1 .content .headline-3 {
        margin: 0; } }

@media (min-width: 1024px) {
  .booking-slider .layout-1 {
    height: 500px; }
    .booking-slider .layout-1 .image-1 {
      left: 30px;
      width: 470px;
      height: 470px; }
    .booking-slider .layout-1 .image-2 {
      top: 348px;
      left: 520px;
      width: 144px;
      height: 144px; }
    .booking-slider .layout-1 .image-3 {
      top: 348px;
      left: 680px;
      width: 144px;
      height: 144px; }
    .booking-slider .layout-1 .image-4 {
      top: 120px;
      left: 520px;
      width: 305px;
      height: 210px; }
    .booking-slider .layout-1 .accent {
      top: 90px;
      left: 755px;
      width: 96px;
      height: 96px; }
    .booking-slider .layout-1 .content {
      left: 520px;
      width: 305px;
      height: 250px;
      padding-top: 25px; } }

.calendar-flyout {
  position: relative;
  text-align: center;
  z-index: 9; }
  .calendar-flyout .container-inset {
    padding-top: 0;
    z-index: auto; }
  .calendar-flyout .container, .calendar-flyout .form-factory {
    position: static;
    padding-top: calc((33 / 640vw ) * 100);
    padding-bottom: calc((40 / 640vw ) * 100); }
    .calendar-flyout .container .reverse-collapse, .calendar-flyout .form-factory .reverse-collapse {
      position: absolute;
      bottom: 10%;
      width: 100%;
      max-width: none;
      box-shadow: none; }
      .calendar-flyout .container .reverse-collapse .collapse-header .icon-svg, .calendar-flyout .form-factory .reverse-collapse .collapse-header .icon-svg {
        margin-right: 0; }
    .calendar-flyout .container .enter-dates-result a, .calendar-flyout .form-factory .enter-dates-result a {
      text-decoration: none; }
      .calendar-flyout .container .enter-dates-result a small, .calendar-flyout .form-factory .enter-dates-result a small {
        text-align: center; }
    .calendar-flyout .container .dates-unavailable-container, .calendar-flyout .form-factory .dates-unavailable-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1051;
      display: flex;
      align-items: center;
      justify-content: center; }
      .calendar-flyout .container .dates-unavailable-container .dates-unavailable-dialog, .calendar-flyout .form-factory .dates-unavailable-container .dates-unavailable-dialog {
        position: relative;
        width: 250px;
        background: white;
        border: 1px solid #2b2b2b;
        text-align: center;
        padding: 24px;
        font-size: 14px; }
        .calendar-flyout .container .dates-unavailable-container .dates-unavailable-dialog .dates-unavailable-msg, .calendar-flyout .form-factory .dates-unavailable-container .dates-unavailable-dialog .dates-unavailable-msg {
          font-weight: bold;
          padding-bottom: 6px; }
        .calendar-flyout .container .dates-unavailable-container .dates-unavailable-dialog .btn-close, .calendar-flyout .form-factory .dates-unavailable-container .dates-unavailable-dialog .btn-close {
          position: absolute;
          top: 0;
          right: 0; }
          .calendar-flyout .container .dates-unavailable-container .dates-unavailable-dialog .btn-close .icon-svg, .calendar-flyout .form-factory .dates-unavailable-container .dates-unavailable-dialog .btn-close .icon-svg {
            height: 12px;
            width: 12px; }
    .calendar-flyout .container.bt-0, .calendar-flyout .bt-0.form-factory {
      border-top: 0; }
  .calendar-flyout .headline-4 {
    margin-top: calc((20 / 640vw ) * 100); }
  .calendar-flyout .icon-svg {
    width: calc((36 / 640vw ) * 100);
    height: calc((36 / 640vw ) * 100); }
  .calendar-flyout p {
    margin-bottom: calc((25 / 640vw ) * 100); }
  .calendar-flyout .btn:not(.btn-round) {
    width: 85%;
    max-width: 498px;
    margin-left: 0; }
  .calendar-flyout .flatpickr-calendar .rate {
    font-size: calc((14 / 640vw ) * 100);
    color: #2b2b2b;
    letter-spacing: 0.2px;
    font-weight: 300;
    pointer-events: none; }
  .calendar-flyout .flatpickr-calendar .slash {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #2b2b2b;
    transform: rotate(-40deg);
    right: 0;
    margin: auto;
    height: 2px; }
  .calendar-flyout .avail-msg {
    font-weight: 500;
    padding: 10px;
    font-size: 14px;
    background-color: #c6c6c6;
    display: flex;
    align-items: center;
    justify-content: center; }
  .calendar-flyout .avail-closed-msg {
    padding: 10px;
    font-size: 14px;
    background-color: #c6c6c6;
    text-align: center; }
  @media (max-width: 767.98px) {
    .calendar-flyout .container.container-inset:before, .calendar-flyout .container-inset.form-factory:before {
      left: 50%;
      width: 94%;
      transform: translateX(-50%); }
    .calendar-flyout .container .reverse-collapse, .calendar-flyout .form-factory .reverse-collapse {
      left: 0;
      transform: none; } }
  @media (min-width: 640px) {
    .calendar-flyout {
      margin-bottom: 40px; } }
  @media (min-width: 768px) {
    .calendar-flyout .container, .calendar-flyout .form-factory {
      position: relative;
      margin-top: -22px;
      padding-top: 0;
      padding-bottom: 22px; }
      .calendar-flyout .container .reverse-collapse, .calendar-flyout .form-factory .reverse-collapse {
        position: absolute;
        bottom: 90px;
        padding-top: 44px;
        padding-bottom: 44px;
        border-bottom: 1px solid #e1e1e1; }
      .calendar-flyout .container .left-picker + .flatpickr-calendar, .calendar-flyout .form-factory .left-picker + .flatpickr-calendar {
        float: right; }
      .calendar-flyout .container .right-picker + .flatpickr-calendar .flatpickr-prev-month, .calendar-flyout .form-factory .right-picker + .flatpickr-calendar .flatpickr-prev-month,
      .calendar-flyout .container .left-picker + .flatpickr-calendar .flatpickr-next-month,
      .calendar-flyout .form-factory .left-picker + .flatpickr-calendar .flatpickr-next-month {
        visibility: hidden; }
      .calendar-flyout .container .flatpickr-prev-month svg, .calendar-flyout .form-factory .flatpickr-prev-month svg,
      .calendar-flyout .container .flatpickr-next-month svg,
      .calendar-flyout .form-factory .flatpickr-next-month svg {
        height: 14px; }
      .calendar-flyout .container .enter-dates-result a, .calendar-flyout .form-factory .enter-dates-result a {
        display: inline-block;
        vertical-align: top;
        text-align: left; }
    .calendar-flyout .container-inset {
      padding-top: 33px;
      border-top: 0; }
    .calendar-flyout .icon-svg {
      width: 19px;
      height: 19px; }
    .calendar-flyout p {
      font-size: 1rem;
      margin: 12px 0 0; }
    .calendar-flyout .btn:not(.btn-round) {
      width: auto; }
    .calendar-flyout .flatpickr-calendar .rate {
      width: 100%;
      font-size: 10px;
      text-align: center; }
    .calendar-flyout .flatpickr-key {
      column-count: 4; }
    .calendar-flyout .dayContainer, .calendar-flyout .flatpickr-weekdaycontainer {
      margin: 0 1em; } }

.carousel-card, .a-list-landing-details .a-list-status-item, .a-list-status-item, .experiences-detail-cards .experiences-card {
  width: 100%;
  padding: calc((80 / 640vw ) * 100) 0 calc((30 / 640vw ) * 100) 0;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-bottom: calc((14 / 640vw ) * 100) solid #3cc1cc; }
  @media (min-width: 640px) {
    .carousel-card, .a-list-landing-details .a-list-status-item, .a-list-status-item, .experiences-detail-cards .experiences-card {
      padding: 80px 0 30px 0;
      border-bottom-width: 14px; } }
  @media (min-width: 768px) {
    .carousel-card, .a-list-landing-details .a-list-status-item, .a-list-status-item, .experiences-detail-cards .experiences-card {
      margin-bottom: 1em;
      padding: calc((40 / 1024vw ) * 100) 0 calc((20 / 1024vw ) * 100) 0;
      border-bottom-width: 8px; } }
  @media (min-width: 1024px) {
    .carousel-card, .a-list-landing-details .a-list-status-item, .a-list-status-item, .experiences-detail-cards .experiences-card {
      padding: 40px 0 20px 0; } }

.collapsible {
  position: relative;
  display: block !important;
  margin-bottom: 0;
  overflow: hidden; }
  .collapsible[data-ellipsis] {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .collapsible[data-ellipsis] {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .collapsible[data-ellipsis] {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .collapsible[data-ellipsis] {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .collapsible.collapsible-sm.collapsing {
    height: calc((90 / 640vw ) * 100); }
  .collapsible.collapsible-md.collapsing {
    height: calc((150 / 640vw ) * 100); }
  .collapsible.collapsible-lg.collapsing {
    height: calc((190 / 640vw ) * 100); }
  .collapsible.show {
    height: auto; }
    .collapsible.show:after {
      box-shadow: none; }
  .collapsible:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0px 54px 25px #ffffff;
    content: ''; }
  .collapsible.no-fade:after {
    display: none; }
  .collapsible ~ .collapsible-btn:not(.active) {
    display: none; }
  @media (min-width: 768px) {
    .collapsible.mobile-collapse {
      height: auto; }
      .collapsible.mobile-collapse:after {
        box-shadow: none; }
      .collapsible.mobile-collapse ~ .collapsible-btn {
        display: none; } }

.collapsible-btn {
  display: block;
  margin: 5px auto;
  padding: 16px;
  font-size: calc((30 / 640vw ) * 100);
  font-weight: 500; }
  .collapsible-btn .less {
    display: block; }
  .collapsible-btn .more {
    display: none; }
  .collapsible-btn .icon-svg {
    display: block;
    margin: auto;
    fill: #007697;
    transform: rotate(-180deg);
    transition: transform 0.5s; }
  .collapsible-btn.collapsed .less {
    display: none; }
  .collapsible-btn.collapsed .more {
    display: block; }
  .collapsible-btn.collapsed .icon-svg {
    transform: rotate(0); }
  @media (min-width: 768px) {
    .collapsible-btn {
      font-size: 14px; } }

.content-grid {
  min-height: calc((300 / 640vw ) * 100); }
  .content-grid figure {
    position: absolute;
    margin: 0; }
    .content-grid figure picture {
      position: relative;
      overflow: hidden;
      display: block;
      height: 100%; }
      .content-grid figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
    .content-grid figure .btn {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%); }
  .content-grid.layout-1 .image-1 {
    top: 0;
    left: 0;
    width: calc((345 / 640vw ) * 100);
    height: calc((232 / 640vw ) * 100); }
  .content-grid.layout-1 .image-2 {
    top: 0;
    left: calc((358 / 640vw ) * 100);
    width: calc((167 / 640vw ) * 100);
    height: calc((108 / 640vw ) * 100); }
  .content-grid.layout-1 .image-3 {
    top: calc((120 / 640vw ) * 100);
    left: calc((358 / 640vw ) * 100);
    width: calc((233 / 640vw ) * 100);
    height: calc((156 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .content-grid {
      min-height: calc((400 / 1024vw ) * 100); }
      .content-grid.layout-1 .image-1 {
        width: calc((471 / 1024vw ) * 100);
        height: calc((307 / 1024vw ) * 100); }
      .content-grid.layout-1 .image-2 {
        left: calc((490 / 1024vw ) * 100);
        width: calc((208 / 1024vw ) * 100);
        height: calc((131 / 1024vw ) * 100); }
      .content-grid.layout-1 .image-3 {
        top: calc((151 / 1024vw ) * 100);
        left: calc((490 / 1024vw ) * 100);
        width: calc((307 / 1024vw ) * 100);
        height: calc((204 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .content-grid {
      min-height: 400px; }
      .content-grid.layout-1 .image-1 {
        width: 471px;
        height: 307px; }
      .content-grid.layout-1 .image-2 {
        left: 490px;
        width: 208px;
        height: 131px; }
      .content-grid.layout-1 .image-3 {
        top: 151px;
        left: 490px;
        width: 307px;
        height: 204px; } }

@media (max-width: 1023.98px) {
  .zoom-enabled .cc-window {
    font-size: 50%; } }

.cc-window.cc-floating {
  max-width: none !important; }

.cc-window.cc-bottom {
  bottom: 0 !important; }

.dedicated-gallery .gallery-set {
  margin: 0 0 2em 0; }
  .dedicated-gallery .gallery-set .gallery-item {
    position: relative;
    width: 100%;
    padding-right: 9.5px;
    padding-left: 9.5px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 12px;
    align-self: self-start;
    cursor: pointer; }
    .dedicated-gallery .gallery-set .gallery-item picture {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      padding-bottom: 54%; }
      .dedicated-gallery .gallery-set .gallery-item picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
      .dedicated-gallery .gallery-set .gallery-item picture img.lazyloading {
        opacity: 1; }
    .dedicated-gallery .gallery-set .gallery-item .btn-play .icon-svg {
      width: calc((94 / 640vw ) * 100);
      height: calc((94 / 640vw ) * 100); }
  .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child {
    flex: 0 0 100%;
    max-width: 100%; }
    .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child .btn-play .icon-svg {
      width: calc((104 / 640vw ) * 100);
      height: calc((104 / 640vw ) * 100); }
  .dedicated-gallery .gallery-set.gallery-set-stacked .row {
    justify-content: center; }
  .dedicated-gallery .gallery-set.gallery-set-stacked .gallery-item {
    flex: 0 0 100%;
    max-width: 100%; }
    .dedicated-gallery .gallery-set.gallery-set-stacked .gallery-item .btn-play .icon-svg {
      width: calc((104 / 640vw ) * 100);
      height: calc((104 / 640vw ) * 100); }

@media (min-width: 640px) {
  .dedicated-gallery .gallery-set {
    margin: 0 0 2em 0; }
    .dedicated-gallery .gallery-set .gallery-item .btn-play .icon-svg {
      width: 94px;
      height: 94px; }
    .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child .btn-play .icon-svg {
      width: 104px;
      height: 104px; }
    .dedicated-gallery .gallery-set.gallery-set-stacked .gallery-item .btn-play .icon-svg {
      width: 104px;
      height: 104px; } }

@media (min-width: 768px) {
  .dedicated-gallery .gallery-set .gallery-item {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-bottom: 19px; }
    .dedicated-gallery .gallery-set .gallery-item .btn-play .icon-svg {
      width: calc((57 / 1024vw ) * 100);
      height: calc((57 / 1024vw ) * 100); }
  .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
    .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child picture {
      padding-bottom: calc(54% + 8.74px); }
    .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child .btn-play .icon-svg {
      width: calc((87 / 1024vw ) * 100);
      height: calc((87 / 1024vw ) * 100); }
  .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:nth-child(3) {
    position: absolute;
    right: 0;
    margin-top: 19px;
    transform: translateY(100%); }
  .dedicated-gallery .gallery-set.gallery-set-stacked .gallery-item {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
    .dedicated-gallery .gallery-set.gallery-set-stacked .gallery-item .btn-play .icon-svg {
      width: calc((87 / 1024vw ) * 100);
      height: calc((87 / 1024vw ) * 100); } }

@media (min-width: 1024px) {
  .dedicated-gallery .gallery-set .gallery-item {
    margin-bottom: 19px; }
    .dedicated-gallery .gallery-set .gallery-item .btn-play .icon-svg {
      width: 57px;
      height: 57px; }
  .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child picture {
    padding-bottom: calc(54% + 8.74px); }
  .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:first-child .btn-play .icon-svg {
    width: 87px;
    height: 87px; }
  .dedicated-gallery .gallery-set[data-featured="true"][data-filtered="false"] .gallery-item:nth-child(3) {
    margin-top: 19px; }
  .dedicated-gallery .gallery-set.gallery-set-stacked .gallery-item .btn-play .icon-svg {
    width: 87px;
    height: 87px; } }

.destination-intro .collapsible.show, .theme-intro .collapsible.show {
  height: auto; }

.destination-intro .collapsible.collapsing, .theme-intro .collapsible.collapsing {
  height: calc((198 / 640vw ) * 100); }

.destination-intro .collapsible-btn, .theme-intro .collapsible-btn {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  margin-bottom: 1rem; }
  @media (min-width: 640px) {
    .destination-intro .collapsible-btn, .theme-intro .collapsible-btn {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .destination-intro .collapsible-btn, .theme-intro .collapsible-btn {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .destination-intro .collapsible-btn, .theme-intro .collapsible-btn {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }

.destination-intro .collage, .theme-intro .collage {
  position: relative;
  padding-bottom: calc((400 / 640vw ) * 100); }

.destination-intro figure, .theme-intro figure {
  position: absolute;
  margin: 0; }
  .destination-intro figure .btn, .theme-intro figure .btn {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%); }
  .destination-intro figure picture, .theme-intro figure picture {
    position: relative;
    overflow: hidden;
    display: block;
    height: 100%; }
    .destination-intro figure picture img, .theme-intro figure picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }

.destination-intro .image-1, .theme-intro .image-1 {
  top: calc((50 / 640vw ) * 100);
  left: calc((70 / 640vw ) * 100);
  width: calc((242 / 640vw ) * 100);
  height: calc((174 / 640vw ) * 100); }

.destination-intro .image-2, .theme-intro .image-2 {
  right: calc((70 / 640vw ) * 100);
  left: auto;
  width: calc((244 / 640vw ) * 100);
  height: calc((341 / 640vw ) * 100); }

.destination-intro .image-3, .theme-intro .image-3 {
  display: block;
  clear: right;
  float: right;
  width: calc((142 / 640vw ) * 100);
  height: calc((142 / 640vw ) * 100);
  margin: 0 0 calc((10 / 640vw ) * 100) calc((10 / 640vw ) * 100); }

.destination-intro .accent, .theme-intro .accent {
  top: calc((220 / 640vw ) * 100);
  left: calc((180 / 640vw ) * 100);
  width: calc((160 / 640vw ) * 100);
  height: calc((160 / 640vw ) * 100);
  filter: drop-shadow(-12px 3px 14px rgba(0, 0, 0, 0.3)); }

.destination-intro .content, .theme-intro .content {
  padding: 0 35px;
  text-align: center; }
  .destination-intro .content figure, .theme-intro .content figure {
    position: relative; }

@media (min-width: 768px) {
  .destination-intro, .theme-intro {
    min-height: 416px;
    /*
		.img-spacer {
			margin-top: 152px;
		}
		*/ }
    .destination-intro .collapsible:after, .theme-intro .collapsible:after {
      display: none; }
    .destination-intro .collapsible.collapsing, .theme-intro .collapsible.collapsing {
      height: 334px; }
    .destination-intro .collapsible-btn, .theme-intro .collapsible-btn {
      margin: 10px 0 0;
      padding: 0;
      text-decoration: underline; }
    .destination-intro .collage, .theme-intro .collage {
      padding-bottom: 0; }
    .destination-intro .btn .icon-svg, .theme-intro .btn .icon-svg {
      width: 5em;
      height: 5em; }
    .destination-intro .image-1, .theme-intro .image-1 {
      top: calc((130 / 1024vw ) * 100);
      left: calc((25 / 1024vw ) * 100);
      width: calc((200 / 1024vw ) * 100);
      height: calc((144 / 1024vw ) * 100); }
    .destination-intro .image-2, .theme-intro .image-2 {
      top: calc((15 / 1024vw ) * 100);
      right: auto;
      left: calc((245 / 1024vw ) * 100);
      width: calc((223 / 1024vw ) * 100);
      height: calc((307 / 1024vw ) * 100); }
    .destination-intro .image-3, .theme-intro .image-3 {
      width: calc((142 / 1024vw ) * 100);
      height: calc((142 / 1024vw ) * 100); }
    .destination-intro .accent, .theme-intro .accent {
      top: calc((275 / 1024vw ) * 100);
      left: calc((120 / 1024vw ) * 100);
      width: calc((140 / 1024vw ) * 100);
      height: calc((140 / 1024vw ) * 100); }
    .destination-intro .content, .theme-intro .content {
      padding: 0 80px 0 0;
      text-align: left; } }

@media (min-width: 1024px) {
  .destination-intro .image-1, .theme-intro .image-1 {
    top: 130px;
    left: 25px;
    width: 200px;
    height: 144px; }
  .destination-intro .image-2, .theme-intro .image-2 {
    top: 15px;
    left: 245px;
    width: 223px;
    height: 307px; }
  .destination-intro .image-3, .theme-intro .image-3 {
    width: 142px;
    height: 142px; }
  .destination-intro .accent, .theme-intro .accent {
    top: 275px;
    left: 120px;
    width: 140px;
    height: 140px; } }

div .promocode-title {
  font-weight: 500 !important;
  font-size: .9rem; }

div .promocode-code {
  border: 2px dashed #017294 !important;
  background-color: #f5fcfd;
  text-align: center; }

div .promocode-terms-link {
  font-weight: 400 !important;
  font-size: .825rem; }

div .promocode-date-text {
  font-weight: 400 !important;
  font-size: .825rem; }

div .promocode-terms-link a {
  text-decoration: underline; }

.theme-intro {
  margin-top: calc((40 / 640vw ) * 100); }
  .theme-intro .image-1 {
    top: calc((138 / 640vw ) * 100);
    left: calc((117 / 640vw ) * 100);
    width: calc((142 / 640vw ) * 100);
    height: calc((142 / 640vw ) * 100); }
  .theme-intro .image-2 {
    right: auto;
    left: calc((278 / 640vw ) * 100); }
  .theme-intro .accent {
    top: calc((-38 / 640vw ) * 100);
    left: calc((140 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .theme-intro {
      margin-top: 40px; }
      .theme-intro .image-1 {
        top: calc((122 / 1024vw ) * 100);
        left: calc((88 / 1024vw ) * 100);
        width: calc((144 / 1024vw ) * 100);
        height: calc((144 / 1024vw ) * 100); }
      .theme-intro .image-2 {
        top: 0;
        left: calc((245 / 1024vw ) * 100); }
      .theme-intro .accent {
        top: calc((-44 / 1024vw ) * 100);
        left: calc((120 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .theme-intro .image-1 {
      top: 122px;
      left: 88px;
      width: 144px;
      height: 144px; }
    .theme-intro .image-2 {
      left: 245px; }
    .theme-intro .accent {
      top: -44px;
      left: 120px; } }

.destination-slider {
  position: relative;
  padding-top: calc((20 / 640vw ) * 100); }
  .destination-slider .figure-container {
    position: relative;
    display: none; }
  .destination-slider article {
    position: relative;
    width: 100%;
    text-align: center; }
    .destination-slider article figure {
      position: absolute;
      margin: 0; }
      .destination-slider article figure a {
        display: block;
        height: 100%;
        margin-bottom: 0.5em; }
      .destination-slider article figure picture {
        position: relative;
        overflow: hidden;
        display: block;
        height: 100%; }
        .destination-slider article figure picture img {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          width: initial;
          min-width: 100%;
          height: inherit;
          object-fit: cover;
          font-family: "object-fit: cover; object-position: center;"; }
      .destination-slider article figure figcaption {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((30 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        margin-bottom: 0.5em;
        text-align: left; }
        @media (min-width: 640px) {
          .destination-slider article figure figcaption {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 32px;
            letter-spacing: 0.5px; } }
        @media (min-width: 768px) {
          .destination-slider article figure figcaption {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .destination-slider article figure figcaption {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .destination-slider article .content {
      padding: 0 calc((20 / 640vw ) * 100); }
      .destination-slider article .content .category {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        margin-bottom: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        color: inherit; }
        @media (min-width: 640px) {
          .destination-slider article .content .category {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .destination-slider article .content .category {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .destination-slider article .content .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .destination-slider article .content .headline-3 {
        margin-bottom: calc((10 / 640vw ) * 100); }
    .destination-slider article .accent {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      pointer-events: none;
      z-index: 1;
      filter: drop-shadow(-12px 3px 14px rgba(0, 0, 0, 0.3)); }
      .destination-slider article .accent img {
        max-width: 100%;
        height: auto; }
    .destination-slider article.layout-1 .content, .destination-slider article.layout-2 .content, .destination-slider article.layout-3 .content {
      width: 100%;
      height: 100%;
      margin-top: calc((675 / 640vw ) * 100); }
    .destination-slider article.layout-1 .image-1, .destination-slider article.layout-2 .image-1, .destination-slider article.layout-3 .image-1 {
      top: calc((64 / 640vw ) * 100);
      left: calc((20 / 640vw ) * 100);
      width: calc((396 / 640vw ) * 100);
      height: calc((282 / 640vw ) * 100); }
    .destination-slider article.layout-1 .image-2, .destination-slider article.layout-2 .image-2, .destination-slider article.layout-3 .image-2 {
      top: calc((160 / 640vw ) * 100);
      left: calc((428 / 640vw ) * 100);
      width: calc((192 / 640vw ) * 100);
      height: calc((192 / 640vw ) * 100); }
    .destination-slider article.layout-1 .image-3, .destination-slider article.layout-2 .image-3, .destination-slider article.layout-3 .image-3 {
      top: calc((356 / 640vw ) * 100);
      left: calc((223 / 640vw ) * 100);
      width: calc((192 / 640vw ) * 100);
      height: calc((192 / 640vw ) * 100); }
    .destination-slider article.layout-1 .image-4, .destination-slider article.layout-2 .image-4, .destination-slider article.layout-3 .image-4 {
      top: calc((356 / 640vw ) * 100);
      left: calc((70 / 640vw ) * 100);
      width: calc((141 / 640vw ) * 100);
      height: calc((141 / 640vw ) * 100); }
    .destination-slider article.layout-1 .accent, .destination-slider article.layout-2 .accent, .destination-slider article.layout-3 .accent {
      top: calc((438 / 640vw ) * 100);
      left: calc((412 / 640vw ) * 100);
      width: calc((228 / 640vw ) * 100);
      height: calc((236 / 640vw ) * 100); }
  .destination-slider .slick-arrow {
    top: calc((120 / 640vw ) * 100); }
  .destination-slider .slick-prev {
    left: calc((120 / 640vw ) * 100); }
  .destination-slider .slick-next {
    right: calc((120 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .destination-slider article .content {
      padding: 0 20px; }
    .destination-slider .slick-arrow {
      top: 10%; }
    .destination-slider .slick-prev {
      left: 120px; }
    .destination-slider .slick-next {
      right: 120px; } }
  @media (min-width: 768px) {
    .destination-slider {
      padding-top: 20px; }
      .destination-slider .figure-container {
        display: block; }
      .destination-slider .carousel-tabs {
        margin-top: 20px; }
        .destination-slider .carousel-tabs a {
          margin-top: 3px;
          color: #2b2b2b;
          font-size: 14px;
          font-weight: 300;
          text-decoration: none;
          text-transform: uppercase; }
          .destination-slider .carousel-tabs a.slick-current {
            margin-top: 0;
            padding: 11px 22px 13px 22px;
            font-weight: 500;
            border-top: 6px solid #3cc1cc; }
      .destination-slider .carousel-tabs .slick-list.draggable {
        position: relative; }
        .destination-slider .carousel-tabs .slick-list.draggable:after {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 54px;
          content: '';
          box-shadow: 28px 0 25px -10px white inset, -28px 0 25px -10px white inset;
          pointer-events: none; }
      .destination-slider .slick-prev {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        transform: translateY(-50%); }
      .destination-slider .slick-next {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%); }
      .destination-slider article {
        height: calc((700 / 1024vw ) * 100);
        text-align: left; }
        .destination-slider article .content {
          position: absolute;
          margin-top: 0;
          padding: calc((20 / 1024vw ) * 100) 0 0 0;
          text-align: left; }
          .destination-slider article .content .category {
            position: relative;
            left: auto;
            margin: 0 0 0.5em 0;
            transform: none;
            font-weight: 300; }
          .destination-slider article .content .headline-3 {
            margin-bottom: 0; }
        .destination-slider article.layout-1 .content {
          left: calc((442 / 1024vw ) * 100);
          width: calc((390 / 1024vw ) * 100);
          height: calc((250 / 1024vw ) * 100);
          margin-top: 0; }
        .destination-slider article.layout-1 .image-1 {
          top: 0;
          left: calc((35 / 1024vw ) * 100);
          width: calc((389 / 1024vw ) * 100);
          height: calc((276 / 1024vw ) * 100); }
        .destination-slider article.layout-1 .image-2 {
          top: calc((293 / 1024vw ) * 100);
          left: calc((198 / 1024vw ) * 100);
          width: calc((226 / 1024vw ) * 100);
          height: calc((226 / 1024vw ) * 100); }
        .destination-slider article.layout-1 .image-3 {
          top: calc((55 / 1024vw ) * 100);
          left: calc((0 / 1024vw ) * 100);
          width: calc((226 / 1024vw ) * 100);
          height: calc((226 / 1024vw ) * 100); }
        .destination-slider article.layout-1 .image-4 {
          top: calc((9 / 1024vw ) * 100);
          left: calc((245 / 1024vw ) * 100);
          width: calc((144 / 1024vw ) * 100);
          height: calc((144 / 1024vw ) * 100); }
        .destination-slider article.layout-1 .accent {
          top: calc((247 / 1024vw ) * 100);
          left: 0;
          width: calc((200 / 1024vw ) * 100);
          height: calc((200 / 1024vw ) * 100); }
        .destination-slider article.layout-1 .image-3,
        .destination-slider article.layout-1 .image-4 {
          display: none; }
        .destination-slider article.layout-1 .figure-container .image-1,
        .destination-slider article.layout-1 .figure-container .image-2 {
          display: none; }
        .destination-slider article.layout-1 .figure-container .image-3,
        .destination-slider article.layout-1 .figure-container .image-4 {
          display: block; }
        .destination-slider article.layout-2 .content {
          left: calc((35 / 1024vw ) * 100);
          width: calc((370 / 1024vw ) * 100);
          height: calc((250 / 1024vw ) * 100);
          margin-top: 0; }
        .destination-slider article.layout-2 .image-1 {
          top: 0;
          left: calc((444 / 1024vw ) * 100);
          width: calc((389 / 1024vw ) * 100);
          height: calc((276 / 1024vw ) * 100); }
        .destination-slider article.layout-2 .image-2 {
          top: calc((79 / 1024vw ) * 100);
          left: calc((0 / 1024vw ) * 100);
          width: calc((226 / 1024vw ) * 100);
          height: calc((226 / 1024vw ) * 100); }
        .destination-slider article.layout-2 .image-3 {
          top: calc((79 / 1024vw ) * 100);
          left: calc((246 / 1024vw ) * 100);
          width: calc((226 / 1024vw ) * 100);
          height: calc((226 / 1024vw ) * 100); }
        .destination-slider article.layout-2 .image-4 {
          top: calc((293 / 1024vw ) * 100);
          left: calc((527 / 1024vw ) * 100);
          width: calc((144 / 1024vw ) * 100);
          height: calc((144 / 1024vw ) * 100); }
        .destination-slider article.layout-2 .accent {
          top: calc((190 / 1024vw ) * 100);
          left: calc((255 / 1024vw ) * 100);
          width: calc((200 / 1024vw ) * 100);
          height: calc((200 / 1024vw ) * 100); }
        .destination-slider article.layout-2 .image-2,
        .destination-slider article.layout-2 .image-3 {
          display: none; }
        .destination-slider article.layout-2 .figure-container .image-1,
        .destination-slider article.layout-2 .figure-container .image-4 {
          display: none; }
        .destination-slider article.layout-2 .figure-container .image-2,
        .destination-slider article.layout-2 .figure-container .image-3 {
          display: block; }
        .destination-slider article.layout-3 .content {
          top: calc((284vw / 1024vw ) * 100);
          left: calc((444vw / 1024vw ) * 100);
          width: calc((390vw / 1024vw ) * 100);
          height: calc((250vw / 1024vw ) * 100);
          margin-top: 0; }
        .destination-slider article.layout-3 .image-1 {
          top: 0;
          left: calc((35vw / 1024vw ) * 100);
          width: calc((389vw / 1024vw ) * 100);
          height: calc((276vw / 1024vw ) * 100); }
        .destination-slider article.layout-3 .image-2 {
          top: calc((293vw / 1024vw ) * 100);
          left: calc((198vw / 1024vw ) * 100);
          width: calc((226vw / 1024vw ) * 100);
          height: calc((226vw / 1024vw ) * 100); }
        .destination-slider article.layout-3 .image-3 {
          top: calc((116vw / 1024vw ) * 100);
          left: calc((689vw / 1024vw ) * 100);
          width: calc((144vw / 1024vw ) * 100);
          height: calc((144vw / 1024vw ) * 100); }
        .destination-slider article.layout-3 .image-4 {
          top: 0;
          left: calc((444vw / 1024vw ) * 100);
          width: calc((226vw / 1024vw ) * 100);
          height: calc((226vw / 1024vw ) * 100); }
        .destination-slider article.layout-3 .accent {
          top: calc((325vw / 1024vw ) * 100);
          left: calc((65vw / 1024vw ) * 100);
          width: calc((200vw / 1024vw ) * 100);
          height: calc((200vw / 1024vw ) * 100); }
        .destination-slider article.layout-3 .figure-container {
          display: none; } }
  @media (min-width: 1024px) {
    .destination-slider article {
      width: 100%;
      height: 620px; }
      .destination-slider article .content {
        padding-top: 15px; }
      .destination-slider article.layout-1 .content {
        left: 442px;
        width: 390px;
        height: 250px; }
      .destination-slider article.layout-1 .image-1 {
        left: 35px;
        width: 389px;
        height: 276px; }
      .destination-slider article.layout-1 .image-2 {
        top: 293px;
        left: 198px;
        width: 226px;
        height: 226px; }
      .destination-slider article.layout-1 .image-3 {
        top: 55px;
        left: 0;
        width: 226px;
        height: 226px; }
      .destination-slider article.layout-1 .image-4 {
        top: 9px;
        left: 245px;
        width: 144px;
        height: 144px; }
      .destination-slider article.layout-1 .accent {
        top: 247px;
        left: 0;
        width: 200px;
        height: 200px; }
      .destination-slider article.layout-2 .content {
        left: 35px;
        width: 370px;
        height: 250px; }
      .destination-slider article.layout-2 .image-1 {
        top: 0;
        left: 444px;
        width: 389px;
        height: 276px; }
      .destination-slider article.layout-2 .image-2 {
        top: 79px;
        left: 0;
        width: 226px;
        height: 226px; }
      .destination-slider article.layout-2 .image-3 {
        top: 79px;
        left: 246px;
        width: 226px;
        height: 226px; }
      .destination-slider article.layout-2 .image-4 {
        top: 293px;
        left: 527px;
        width: 144px;
        height: 144px; }
      .destination-slider article.layout-2 .accent {
        top: 190px;
        left: 255px;
        width: 200px;
        height: 200px; }
      .destination-slider article.layout-3 .content {
        top: 284px;
        left: 444px;
        width: 390px;
        height: 250px;
        margin-top: 0; }
      .destination-slider article.layout-3 .image-1 {
        left: 35px;
        width: 389px;
        height: 276px; }
      .destination-slider article.layout-3 .image-2 {
        top: 293px;
        left: 198px;
        width: 226px;
        height: 226px; }
      .destination-slider article.layout-3 .image-3 {
        top: 116px;
        left: 689px;
        width: 144px;
        height: 144px; }
      .destination-slider article.layout-3 .image-4 {
        top: 0;
        left: 444px;
        width: 226px;
        height: 226px; }
      .destination-slider article.layout-3 .accent {
        top: 325px;
        left: 65px;
        width: 200px;
        height: 200px; } }

.contact-footer {
  margin-bottom: calc((60 / 640vw ) * 100);
  padding-top: calc((80 / 640vw ) * 100);
  border-top: 1px solid #c7c7c7; }
  .contact-footer .row {
    margin-right: 0;
    margin-left: 0; }
  .contact-footer figure {
    margin: calc((89 / 640vw ) * 100) 0 0;
    line-height: 0; }
    .contact-footer figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: calc((400 / 640vw ) * 100);
      margin: 0; }
      .contact-footer figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .contact-footer .headline-2 {
    margin-bottom: calc((18 / 640vw ) * 100); }
  .contact-footer .headline-5 {
    font-size: 2rem;
    margin: 15px 0 30px;
    line-height: 1.9; }
  .contact-footer .headline-6 {
    font-weight: 300; }
  @media (min-width: 640px) {
    .contact-footer {
      margin-bottom: 60px; }
      .contact-footer figure picture {
        height: 400px; } }
  @media (min-width: 768px) {
    .contact-footer {
      padding-top: 0;
      border-bottom: 1px solid #c7c7c7; }
      .contact-footer figure {
        margin: -1px 0; }
        .contact-footer figure picture {
          height: 433px; }
      .contact-footer .headline-2 {
        margin-bottom: 0; }
      .contact-footer .headline-5 {
        font-size: 1rem;
        margin-bottom: 20px; } }

.experiences-detail-cards .slick-track {
  display: flex; }

.experiences-detail-cards .slick-slide {
  height: auto; }

.experiences-detail-cards .col, .experiences-detail-cards .slick-initialized .slick-slide {
  display: flex; }

.experiences-detail-cards .col {
  width: calc((396 / 640vw ) * 100);
  max-width: calc((396 / 640vw ) * 100);
  height: auto;
  margin: 0; }

.experiences-detail-cards .experiences-card {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  margin-bottom: 1rem;
  padding: calc((80 / 640vw ) * 100) 0;
  line-height: 1.75em; }
  @media (min-width: 640px) {
    .experiences-detail-cards .experiences-card {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .experiences-detail-cards .experiences-card {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .experiences-detail-cards .experiences-card {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .experiences-detail-cards .experiences-card .headline {
    margin-bottom: calc((45 / 640vw ) * 100); }
  .experiences-detail-cards .experiences-card p {
    line-height: inherit; }
  .experiences-detail-cards .experiences-card address {
    margin: 0; }

@media (min-width: 640px) {
  .experiences-detail-cards .experiences-card .headline {
    margin-bottom: 45px; } }

@media (min-width: 768px) {
  .experiences-detail-cards {
    margin-top: calc((60 / 1024vw ) * 100); }
    .experiences-detail-cards .experiences-card {
      padding: 35px 0 55px; }
      .experiences-detail-cards .experiences-card .headline {
        margin-bottom: 20px; }
    .experiences-detail-cards .col {
      max-width: 33.3%; } }

@media (min-width: 1024px) {
  .experiences-detail-cards {
    margin-top: 60px; } }

.experiences-listing {
  position: relative;
  left: 0;
  margin-bottom: calc((80 / 640vw ) * 100);
  text-align: left;
  opacity: 1;
  transition: left .3s ease-in-out, opacity .3s ease-in-out; }
  .experiences-listing.hidden {
    display: none;
    left: 100%;
    height: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden; }
  .experiences-listing figure {
    display: inline-block;
    width: 100%;
    margin-right: calc((23 / 640vw ) * 100); }
    .experiences-listing figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      margin: 0; }
      .experiences-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .experiences-listing .headline-3 {
    margin-bottom: calc((40 / 640vw ) * 100);
    padding: 0 0 calc((15 / 640vw ) * 100) calc((26 / 640vw ) * 100);
    border-bottom: calc((15 / 640vw ) * 100) solid #3cc1cc; }
  .experiences-listing .content {
    position: relative;
    display: inline-block;
    padding: 0 0 0 calc((20 / 640vw ) * 100);
    vertical-align: top; }
    .experiences-listing .content .headline-5 {
      margin-bottom: calc((15 / 640vw ) * 100);
      padding-right: 48px; }
    .experiences-listing .content .btn-overlay {
      top: -10px;
      margin: 0; }
      .experiences-listing .content .btn-overlay .icon-svg svg {
        fill: #000000; }
    .experiences-listing .content p {
      margin: calc((15 / 640vw ) * 100) 0; }
    .experiences-listing .content .location,
    .experiences-listing .content time {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      display: block;
      margin: 0;
      font-weight: 400; }
      @media (min-width: 640px) {
        .experiences-listing .content .location,
        .experiences-listing .content time {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .experiences-listing .content .location,
        .experiences-listing .content time {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .experiences-listing .content .location,
    .experiences-listing .content time {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .experiences-listing .content a {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem;
      text-decoration: underline; }
      @media (min-width: 640px) {
        .experiences-listing .content a {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .experiences-listing .content a {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .experiences-listing .content a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .experiences-listing .content a:hover {
        color: #007697; }
  @media (min-width: 640px) {
    .experiences-listing {
      margin-bottom: 80px; }
      .experiences-listing .headline-3 {
        border-width: 15px; }
      .experiences-listing .content {
        padding: 0 0 0 20px; } }
  @media (min-width: 768px) {
    .experiences-listing {
      margin-bottom: calc((80 / 1024vw ) * 100); }
      .experiences-listing.hidden {
        display: block; }
      .experiences-listing figure {
        margin: 0 calc((32 / 1024vw ) * 100) 0 0; }
      .experiences-listing .headline-3 {
        margin-bottom: 22px;
        padding: 0 0 4px 0;
        border-width: calc((6 / 1024vw ) * 100); }
      .experiences-listing .content {
        padding: 0 0 0 calc((20 / 1024vw ) * 100); }
        .experiences-listing .content .headline-5 {
          display: block;
          font-weight: 500;
          text-transform: none;
          font-size: calc((36 / 640vw ) * 100);
          line-height: calc((37 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100);
          margin-bottom: 0.55em;
          margin-bottom: 8px; } }
      @media (min-width: 768px) and (min-width: 640px) {
        .experiences-listing .content .headline-5 {
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 37px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) and (min-width: 768px) {
        .experiences-listing .content .headline-5 {
          font-size: calc((20 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 768px) and (min-width: 640px) {
    .experiences-listing .content .headline-5 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
        .experiences-listing .content .btn-overlay {
          top: 52px;
          right: -15px; }
        .experiences-listing .content p {
          margin: 5px 0; }
        .experiences-listing .content .location,
        .experiences-listing .content time {
          display: inline-block;
          margin: 0; } }
  @media (min-width: 1024px) {
    .experiences-listing {
      margin-bottom: 80px; }
      .experiences-listing figure {
        margin: 0 32px 0 0; }
      .experiences-listing .headline-3 {
        border-width: 6px; }
      .experiences-listing .content {
        padding: 0 0 0 20px; } }

.experience-list {
  overflow: hidden; }
  @media (min-width: 768px) {
    .experience-list {
      overflow: hidden; } }

.experiences-detail-overview .btn-transparent {
  display: inline-block;
  margin-bottom: calc((20 / 640vw ) * 100);
  color: #2b2b2b;
  font-weight: 500;
  text-transform: none; }
  .experiences-detail-overview .btn-transparent .icon-svg {
    width: calc((24 / 640vw ) * 100);
    height: calc((24 / 640vw ) * 100);
    margin: 0 calc((10 / 640vw ) * 100) 0.1em 0;
    vertical-align: text-bottom; }
    .experiences-detail-overview .btn-transparent .icon-svg svg {
      fill: #2b2b2b; }
  .experiences-detail-overview .btn-transparent a {
    text-decoration: none; }

.experiences-detail-overview figure {
  display: inline-block;
  width: 100%;
  margin-bottom: calc((30 / 640vw ) * 100); }
  .experiences-detail-overview figure picture {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: 100%; }
    .experiences-detail-overview figure picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }

.experiences-detail-overview .content {
  position: relative;
  vertical-align: top; }
  .experiences-detail-overview .content p {
    margin-bottom: calc((30 / 640vw ) * 100); }
  .experiences-detail-overview .content .location,
  .experiences-detail-overview .content time {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((42 / 640vw ) * 100);
    letter-spacing: calc((0.6 / 640vw ) * 100);
    display: block;
    margin: 0;
    font-weight: 500; }
    @media (min-width: 640px) {
      .experiences-detail-overview .content .location,
      .experiences-detail-overview .content time {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .experiences-detail-overview .content .location,
      .experiences-detail-overview .content time {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .experiences-detail-overview .content .location,
    .experiences-detail-overview .content time {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    @media (min-width: 640px) {
      .experiences-detail-overview .content .location,
      .experiences-detail-overview .content time {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 42px;
        letter-spacing: 0.6px; } }
  .experiences-detail-overview .content time {
    margin-bottom: calc((25 / 640vw ) * 100);
    display: inline; }
  .experiences-detail-overview .content footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin: 1em 0 0 0; }
    @media (min-width: 640px) {
      .experiences-detail-overview .content footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .experiences-detail-overview .content footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .experiences-detail-overview .content footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .experiences-detail-overview .content footer .btn-transparent {
      text-decoration: none;
      color: #2b2b2b; }
      .experiences-detail-overview .content footer .btn-transparent .icon-svg {
        width: 32px;
        height: 32px;
        margin: 0 0.25em 0.05em 0;
        vertical-align: text-bottom; }
        .experiences-detail-overview .content footer .btn-transparent .icon-svg svg {
          fill: #2b2b2b; }
      .experiences-detail-overview .content footer .btn-transparent:hover, .experiences-detail-overview .content footer .btn-transparent:focus {
        text-decoration: none; }
        .experiences-detail-overview .content footer .btn-transparent:hover .icon-svg svg, .experiences-detail-overview .content footer .btn-transparent:focus .icon-svg svg {
          fill: #007697; }

@media (min-width: 640px) {
  .experiences-detail-overview .btn-transparent .icon-svg {
    width: 24px;
    height: 24px; }
  .experiences-detail-overview figure {
    margin-bottom: 30px; } }

@media (min-width: 768px) {
  .experiences-detail-overview .btn-transparent {
    margin: 0;
    padding-left: 0; }
    .experiences-detail-overview .btn-transparent .icon-svg {
      width: 12px;
      height: 12px;
      margin-right: 5px; }
  .experiences-detail-overview figure {
    margin-bottom: 0; }
  .experiences-detail-overview .content {
    text-align: left; }
    .experiences-detail-overview .content p {
      margin-bottom: 30px; }
    .experiences-detail-overview .content .location,
    .experiences-detail-overview .content time {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.6 / 640vw ) * 100);
      display: inline;
      margin: 0;
      font-weight: 500; } }
    @media (min-width: 768px) and (min-width: 640px) {
      .experiences-detail-overview .content .location,
      .experiences-detail-overview .content time {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 24px;
        letter-spacing: 0.6px; } }

@media (min-width: 768px) {
    .experiences-detail-overview .content footer a {
      margin: 0 0.35em; } }

@media (min-width: 1024px) {
  .experiences-detail-overview {
    margin-bottom: 60px; } }

.error-page img {
  display: block;
  width: 33%;
  margin: auto;
  margin-top: 35px; }
  @media (max-width: 1023.98px) {
    .error-page img {
      width: 75%;
      margin-top: 70px; } }

.error-page .login-header {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 35px; }
  @media (max-width: 1023.98px) {
    .error-page .login-header {
      display: none; } }

.error-page .bodycontainer .modal-content {
  margin-top: 70px; }
  @media (max-width: 1023.98px) {
    .error-page .bodycontainer .modal-content {
      margin-top: 50px; } }

.error-page .bodycontainer .modal-body {
  display: block;
  margin: auto; }
  @media (min-width: 768px) {
    .error-page .bodycontainer .modal-body {
      border: 1px solid #0a0a0a;
      width: 40%; } }
  .error-page .bodycontainer .modal-body button {
    margin-top: 25px; }

@media (max-width: 1023.98px) {
  .error-page .bodycontainer .custom-control-description {
    white-space: nowrap;
    font-size: 3.75vw; } }

.error-page #sign-in-label {
  text-align: center;
  margin-bottom: 40px; }

.error-page button.btn.mb-2 {
  width: 100%; }

.error-page .forgot-password {
  font-size: small; }
  @media (max-width: 1023.98px) {
    .error-page .forgot-password {
      font-size: 3vw;
      padding-top: 4px; } }

.error-page .account-modal .modal-footer {
  display: none; }

.faq .faq-search .search-label {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  display: block;
  margin: 0;
  text-align: center; }
  @media (min-width: 640px) {
    .faq .faq-search .search-label {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .faq .faq-search .search-label {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .faq .faq-search .search-label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .faq .faq-search .search-label span:nth-child(2) {
    display: none; }

.faq .faq-search .form-group {
  position: relative; }
  .faq .faq-search .form-group .btn-search {
    top: auto;
    bottom: calc((-4 / 640vw ) * 100); }

.faq .faq-search .form-actions {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  display: inline-block;
  width: 100px;
  height: 100%;
  z-index: 2200; }
  .faq .faq-search .form-actions button {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    bottom: calc((22 / 640vw ) * 100);
    width: calc((48 / 640vw ) * 100);
    height: calc((48 / 640vw ) * 100);
    vertical-align: middle; }
    .faq .faq-search .form-actions button.btn-search {
      right: 0;
      pointer-events: none; }
      .faq .faq-search .form-actions button.btn-search .icon-svg svg {
        fill: #000000; }
    .faq .faq-search .form-actions button.btn-close {
      display: none;
      right: 0;
      padding: 2px;
      border-radius: 100%;
      background-color: #007697; }
      .faq .faq-search .form-actions button.btn-close .icon-svg {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        height: 40%; }
        .faq .faq-search .form-actions button.btn-close .icon-svg svg {
          fill: #ffffff; }

.faq .faq-results {
  border-top: 1px solid #e1e1e1; }
  .faq .faq-results .nav.fixed-top {
    position: relative;
    top: 0;
    z-index: 900; }
  .faq .faq-results .nav .nav-item a.active {
    color: #007697;
    font-weight: 500; }
  .faq .faq-results .nav-tabs {
    margin-bottom: calc((50 / 640vw ) * 100);
    border-top: 0; }
  .faq .faq-results .faq-accordion {
    margin-bottom: 60px; }
    .faq .faq-results .faq-accordion .headline-4 {
      margin-bottom: calc((30 / 640vw ) * 100); }
      .faq .faq-results .faq-accordion .headline-4:not(:first-of-type) {
        margin-top: calc((45 / 640vw ) * 100); }
  .faq .faq-results .card {
    margin-top: -1px;
    margin-bottom: 0;
    border-left-width: 0;
    border-right-width: 0;
    text-align: left; }
    .faq .faq-results .card .card-header {
      padding: 20px 90px 20px 0;
      background-color: transparent;
      border: 0; }
      .faq .faq-results .card .card-header .headline {
        margin: 0; }
    .faq .faq-results .card .card-controls {
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      margin: 10px 0; }
      .faq .faq-results .card .card-controls button {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        position: relative;
        width: 48px;
        height: 48px;
        margin-bottom: 1rem;
        padding-right: 0;
        vertical-align: middle; }
        @media (min-width: 640px) {
          .faq .faq-results .card .card-controls button {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .faq .faq-results .card .card-controls button {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .faq .faq-results .card .card-controls button {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
        .faq .faq-results .card .card-controls button .icon-svg {
          width: calc((24 / 640vw ) * 100);
          height: calc((24 / 640vw ) * 100);
          margin: -2px 8px 0 0;
          transform: rotate(0deg);
          transition: transform 0.3s; }
          .faq .faq-results .card .card-controls button .icon-svg svg {
            fill: #000000; }
        .faq .faq-results .card .card-controls button.collapsed .icon-svg {
          transform: rotate(180deg); }
    .faq .faq-results .card .card-block {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem;
      padding: 0;
      text-align: left; }
      @media (min-width: 640px) {
        .faq .faq-results .card .card-block {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .faq .faq-results .card .card-block {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .faq .faq-results .card .card-block {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .faq .faq-results .no-results {
    display: none;
    padding: 100px 50px;
    border: 1px solid #e1e1e1;
    text-align: center; }
  .faq .faq-results footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .faq .faq-results footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .faq .faq-results footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .faq .faq-results footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }

@media (min-width: 640px) {
  .faq .faq-search .form-actions button {
    bottom: 22px;
    width: 48px;
    height: 48px; } }

@media (min-width: 768px) {
  .faq .faq-search .search-label {
    text-align: left; }
  .faq .faq-search .form-group .btn-search {
    bottom: -8px; }
  .faq .faq-search .form-actions button {
    bottom: 10px;
    width: 32px;
    height: 32px; }
  .faq .faq-results {
    padding-top: 40px; }
    .faq .faq-results .nav.fixed-top {
      position: fixed;
      top: 72px;
      right: auto;
      left: auto; }
    .faq .faq-results .nav-tabs {
      display: block;
      max-height: none;
      margin-bottom: 0;
      border: 0; }
      .faq .faq-results .nav-tabs .nav-item {
        text-align: left; }
      .faq .faq-results .nav-tabs .nav-link {
        padding: 5px 1em; }
      .faq .faq-results .nav-tabs .active .nav-link {
        color: #007697; }
        .faq .faq-results .nav-tabs .active .nav-link:before {
          display: none; }
    .faq .faq-results .faq-accordion .headline-4 {
      margin-bottom: 15px; }
      .faq .faq-results .faq-accordion .headline-4:not(:first-of-type) {
        margin-top: 40px; }
    .faq .faq-results .card .card-block {
      padding-right: 118px; }
    .faq .faq-results .card .card-header .headline {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((30 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.5em; } }
    @media (min-width: 768px) and (min-width: 640px) {
      .faq .faq-results .card .card-header .headline {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .faq .faq-results .card .card-header .headline {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 768px) and (min-width: 640px) {
    .faq .faq-results .card .card-header .headline {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

@media (min-width: 768px) {
    .faq .faq-results .card .card-controls button {
      text-align: right; }
      .faq .faq-results .card .card-controls button .icon-svg {
        width: 16px;
        height: 16px; } }

.favorites-list {
  margin-bottom: 5em;
  text-align: center; }
  .favorites-list .btn-more {
    width: 100%;
    max-width: 498px; }
  .favorites-list .no-results {
    margin: 0 4.5rem 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #e2e2e2;
    text-align: center; }
    .favorites-list .no-results p {
      margin-bottom: 0; }
  .favorites-list .browse-list {
    margin: 4em 0;
    text-align: center; }
    .favorites-list .browse-list .headline {
      margin-bottom: 1.5em; }
    .favorites-list .browse-list .browse-list-item {
      display: block;
      margin-bottom: 3em; }
      .favorites-list .browse-list .browse-list-item picture {
        position: relative;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: 1em;
        padding-bottom: 42.55%; }
        .favorites-list .browse-list .browse-list-item picture img {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          width: initial;
          min-width: 100%;
          height: inherit;
          object-fit: cover;
          font-family: "object-fit: cover; object-position: center;"; }
  @media (min-width: 768px) {
    .favorites-list .btn-more {
      width: auto; }
    .favorites-list .no-results {
      margin: 0 0 3rem; }
    .favorites-list .browse-list .browse-list-item {
      margin-bottom: 1.5em; } }

.favorites-listing, .other-activities-listing, .user-travel-log-listing {
  position: relative;
  display: flex;
  margin: 0 0 1.8em;
  padding-bottom: 0.8em;
  flex-direction: row;
  border-bottom: 2px solid #e1e1e1; }
  .favorites-listing .btn-overlay .icon-svg svg, .other-activities-listing .btn-overlay .icon-svg svg, .user-travel-log-listing .btn-overlay .icon-svg svg {
    fill: #007697; }
  .favorites-listing figure, .other-activities-listing figure, .user-travel-log-listing figure {
    display: inline-block;
    width: 40%; }
    .favorites-listing figure picture, .other-activities-listing figure picture, .user-travel-log-listing figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding-bottom: 66.6667%; }
      .favorites-listing figure picture img, .other-activities-listing figure picture img, .user-travel-log-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .favorites-listing .content, .other-activities-listing .content, .user-travel-log-listing .content {
    display: flex;
    padding: 0 50px 0 14px;
    flex-direction: column;
    text-align: left; }
  .favorites-listing .category, .other-activities-listing .category, .user-travel-log-listing .category {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-top: auto;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .favorites-listing .category, .other-activities-listing .category, .user-travel-log-listing .category {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .favorites-listing .category, .other-activities-listing .category, .user-travel-log-listing .category {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .favorites-listing .category, .other-activities-listing .category, .user-travel-log-listing .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (max-width: 767.98px) {
    .favorites-listing .content, .other-activities-listing .content, .user-travel-log-listing .content {
      width: 56%; } }
  @media (max-width: 1023.98px) {
    .favorites-listing .btn-overlay, .other-activities-listing .btn-overlay, .user-travel-log-listing .btn-overlay {
      margin: 0; } }
  @media (min-width: 768px) {
    .favorites-listing, .other-activities-listing, .user-travel-log-listing {
      display: block;
      margin-bottom: 2em;
      border: 0; }
      .favorites-listing .btn-overlay .icon-svg svg, .other-activities-listing .btn-overlay .icon-svg svg, .user-travel-log-listing .btn-overlay .icon-svg svg {
        fill: #ffffff; }
      .favorites-listing figure, .other-activities-listing figure, .user-travel-log-listing figure {
        width: 100%;
        margin-bottom: 10px; }
        .favorites-listing figure picture, .other-activities-listing figure picture, .user-travel-log-listing figure picture {
          width: 100%;
          height: 100%;
          padding-bottom: 66.45%; }
      .favorites-listing .content, .other-activities-listing .content, .user-travel-log-listing .content {
        padding: 0;
        flex-direction: row; }
      .favorites-listing .headline-5, .other-activities-listing .headline-5, .user-travel-log-listing .headline-5 {
        width: 70%;
        padding-right: 20px; }
      .favorites-listing .category, .other-activities-listing .category, .user-travel-log-listing .category {
        margin: 0 0 0 auto;
        text-align: right; } }

.filter .btn-filter {
  font-size: calc((26 / 640vw ) * 100);
  line-height: calc((35 / 640vw ) * 100);
  letter-spacing: calc((0.1 / 640vw ) * 100);
  width: 100%;
  max-width: 503px;
  margin: 2rem 0;
  padding-right: 18px;
  padding-left: 18px;
  white-space: normal;
  background-color: #ffffff;
  color: #2b2b2b;
  border: 1px solid #e1e1e1; }
  @media (min-width: 640px) {
    .filter .btn-filter {
      font-size: 26px;
      font-size: 1.625rem;
      line-height: 35px;
      letter-spacing: 0.1px; } }
  .filter .btn-filter span {
    font-weight: 300; }
  .filter .btn-filter .icon-svg {
    float: right;
    width: calc((30 / 640vw ) * 100);
    height: calc((30 / 640vw ) * 100); }
    .filter .btn-filter .icon-svg svg {
      fill: #2b2b2b; }

.filter .filter-collapse {
  position: fixed;
  top: 0;
  width: 100%;
  margin-top: 0;
  background: #ffffff;
  text-align: left;
  z-index: 1000; }
  .filter .filter-collapse.show {
    height: 100%;
    padding-bottom: 25%; }
  .filter .filter-collapse .filter-wrapper {
    height: 100%;
    min-height: calc((773 / 640vw ) * 100);
    padding-bottom: 12%;
    overflow: auto; }
    .filter .filter-collapse .filter-wrapper .btn[aria-haspopup="true"] {
      position: relative;
      padding: calc((39 / 640vw ) * 100) 0;
      background-color: #f7f7f7;
      color: #000000; }
      .filter .filter-collapse .filter-wrapper .btn[aria-haspopup="true"] span {
        font-weight: 300; }
      .filter .filter-collapse .filter-wrapper .btn[aria-haspopup="true"] .icon-svg {
        position: absolute;
        top: 50%;
        right: calc((39 / 640vw ) * 100);
        fill: #000000;
        transform: translateY(-50%); }
  .filter .filter-collapse .headline-5 {
    padding: 0 calc((40 / 640vw ) * 100); }
  .filter .filter-collapse [data-toggle='collapse'] {
    width: 100%;
    margin-bottom: calc((30 / 640vw ) * 100); }
  .filter .filter-collapse ul.list-unstyled {
    display: flex;
    margin: calc((20 / 640vw ) * 100) 0 calc((40 / 640vw ) * 100);
    padding: 0 calc((40 / 640vw ) * 100) calc((26 / 640vw ) * 100);
    flex-wrap: wrap;
    border-bottom: 1px solid #e1e1e1; }
    .filter .filter-collapse ul.list-unstyled li {
      width: 50%; }
      .filter .filter-collapse ul.list-unstyled li label {
        margin: calc((14 / 640vw ) * 100) 0; }
    .filter .filter-collapse ul.list-unstyled .form-check-input {
      margin-right: 8px; }
  .filter .filter-collapse ul.list-inline {
    column-count: 2;
    column-gap: 0.5em; }
    .filter .filter-collapse ul.list-inline li {
      margin-bottom: calc((20 / 640vw ) * 100); }
  .filter .filter-collapse ul.type-ahead {
    margin-top: 0; }
    .filter .filter-collapse ul.type-ahead li {
      width: 100%; }
  .filter .filter-collapse .property-search .form-group {
    position: relative;
    max-width: 80%;
    margin: 0 calc((20 / 640vw ) * 100) calc((30 / 640vw ) * 100); }
  .filter .filter-collapse .property-search .btn-search {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
    right: calc((18 / 640vw ) * 100);
    width: calc((44 / 640vw ) * 100);
    height: calc((44 / 640vw ) * 100);
    vertical-align: middle;
    pointer-events: none;
    z-index: 1000; }
  .filter .filter-collapse .property-search .close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    margin: calc((40 / 640vw ) * 100);
    opacity: 1;
    padding: 2px;
    border-radius: 100%;
    background-color: #007697; }
    .filter .filter-collapse .property-search .close .icon-svg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
      height: 40%; }
      .filter .filter-collapse .property-search .close .icon-svg svg {
        fill: #ffffff; }
  .filter .filter-collapse .property-search .btn-reset {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
    right: calc((-60 / 640vw ) * 100);
    width: calc((40 / 640vw ) * 100);
    min-width: 0;
    height: calc((40 / 640vw ) * 100);
    padding: 2px;
    border-radius: 50%; }
    .filter .filter-collapse .property-search .btn-reset .icon-svg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
      height: 40%; }
  .filter .filter-collapse ul.property-search-results {
    display: block;
    height: calc((500 / 640vw ) * 100);
    margin-left: 0.25em;
    overflow-y: scroll;
    transform: translate3d(0, 0, 0);
    column-count: auto; }
    .filter .filter-collapse ul.property-search-results li {
      width: auto; }
  .filter .filter-collapse .no-results {
    margin: 0 1em 2em 1em; }
  .filter .filter-collapse footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: calc((29 / 640vw ) * 100) calc((15 / 640vw ) * 100);
    border-top: solid 1px #e1e1e1;
    background-color: #f7f7f7; }
    .filter .filter-collapse footer .results {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem;
      padding-left: calc((20 / 640vw ) * 100);
      font-weight: 500; }
      @media (min-width: 640px) {
        .filter .filter-collapse footer .results {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .filter .filter-collapse footer .results {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .filter .filter-collapse footer .results {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .filter .filter-collapse footer .btn {
      width: auto;
      margin: 0; }
    .filter .filter-collapse footer .btn-transparent {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin: 0 10px 0 0;
      text-decoration: underline; }
      @media (min-width: 640px) {
        .filter .filter-collapse footer .btn-transparent {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .filter .filter-collapse footer .btn-transparent {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .filter .filter-collapse footer .btn-transparent {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .filter .filter-collapse footer .btn-transparent:hover {
        color: #007697; }

@media (min-width: 640px) {
  .filter .filter-collapse ul.property-search-results {
    max-width: 75%;
    height: 500px;
    column-count: auto; } }

@media (min-width: 768px) {
  .filter {
    margin-bottom: 2em; }
    .filter .btn-filter {
      font-size: calc((16 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.1 / 640vw ) * 100);
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 125px;
      margin: 0;
      padding-bottom: 15px;
      text-align: left;
      transition: none; } }
    @media (min-width: 768px) and (min-width: 640px) {
      .filter .btn-filter {
        font-size: 16px;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.1px; } }

@media (min-width: 768px) {
      .filter .btn-filter .icon-svg {
        width: 19px;
        height: 19px;
        margin-top: 2px; }
      .filter .btn-filter[aria-expanded="true"] {
        bottom: 0;
        padding-bottom: 25px;
        border-bottom: 1px solid #ffffff;
        z-index: 1001; }
    .filter .filter-collapse {
      position: relative;
      margin-top: -1px; }
      .filter .filter-collapse.show:after, .filter .filter-collapse.collapsing:after {
        position: absolute;
        top: 0;
        width: 100%;
        content: ' ';
        border-bottom: 1px solid #e1e1e1;
        z-index: 0; }
      .filter .filter-collapse .filter-wrapper {
        height: auto;
        min-height: 0;
        padding-bottom: 16px; }
      .filter .filter-collapse .headline-5 {
        padding: 40px 0 0; }
      .filter .filter-collapse ul.list-unstyled {
        display: block;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        border-bottom: 0;
        column-count: 1; }
        .filter .filter-collapse ul.list-unstyled li {
          display: block;
          width: 100%;
          padding-right: 15px; }
          .filter .filter-collapse ul.list-unstyled li label {
            margin: 0 0 0 4px; }
        .filter .filter-collapse ul.list-unstyled.list-col-2 {
          column-count: 2;
          column-gap: 0.5em; }
      .filter .filter-collapse ul.list-inline {
        display: block;
        column-count: 4; }
        .filter .filter-collapse ul.list-inline li {
          width: auto;
          margin-bottom: 0;
          padding-right: 0; }
      .filter .filter-collapse .property-search .form-group {
        max-width: none;
        margin: 0 auto 0; }
      .filter .filter-collapse .property-search .btn-search {
        right: 5px;
        width: 36px;
        height: 36px; }
      .filter .filter-collapse .property-search .btn-reset {
        width: 30px;
        height: 30px;
        right: -45px; }
        .filter .filter-collapse .property-search .btn-reset .icon-svg {
          width: 14px;
          height: 14px;
          vertical-align: -2px; }
      .filter .filter-collapse ul.property-search-results {
        height: 190px;
        column-count: auto; }
      .filter .filter-collapse .no-results {
        margin: 0; }
      .filter .filter-collapse footer {
        position: relative;
        padding: 10px 0;
        border: 0; }
        .filter .filter-collapse footer .results {
          padding-left: 0; }
      .filter .filter-collapse.show {
        padding-bottom: 2em; } }

.filter-open {
  overflow: hidden; }
  @media (min-width: 768px) {
    .filter-open {
      overflow: visible; } }

.feature {
  height: calc((650 / 640vw ) * 100);
  position: relative; }
  .feature.feature-pull {
    margin-bottom: calc((-325 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .feature {
      height: calc((650 / 1024vw ) * 100); }
      .feature.feature-pull {
        margin-bottom: calc((-325 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .feature {
      height: 650px; }
      .feature.feature-pull {
        margin-bottom: -325px; } }
  .feature.feature-xxl {
    height: calc((931 / 640vw ) * 100); }
    .feature.feature-xxl.feature-pull {
      margin-bottom: calc((-465.5 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-xxl {
        height: calc((931 / 1024vw ) * 100); }
        .feature.feature-xxl.feature-pull {
          margin-bottom: calc((-465.5 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-xxl {
        height: 931px; }
        .feature.feature-xxl.feature-pull {
          margin-bottom: -465.5px; } }
  .feature.feature-xl {
    height: calc((650 / 640vw ) * 100); }
    .feature.feature-xl.feature-pull {
      margin-bottom: calc((-325 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-xl {
        height: calc((650 / 1024vw ) * 100); }
        .feature.feature-xl.feature-pull {
          margin-bottom: calc((-325 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-xl {
        height: 650px; }
        .feature.feature-xl.feature-pull {
          margin-bottom: -325px; } }
  .feature.feature-lg {
    height: calc((558 / 640vw ) * 100); }
    .feature.feature-lg.feature-pull {
      margin-bottom: calc((-279 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-lg {
        height: calc((558 / 1024vw ) * 100); }
        .feature.feature-lg.feature-pull {
          margin-bottom: calc((-279 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-lg {
        height: 558px; }
        .feature.feature-lg.feature-pull {
          margin-bottom: -279px; } }
  .feature.feature-md {
    height: calc((433 / 640vw ) * 100); }
    .feature.feature-md.feature-pull {
      margin-bottom: calc((-216.5 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-md {
        height: calc((433 / 1024vw ) * 100); }
        .feature.feature-md.feature-pull {
          margin-bottom: calc((-216.5 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-md {
        height: 433px; }
        .feature.feature-md.feature-pull {
          margin-bottom: -216.5px; } }
  .feature.feature-sm {
    height: calc((351 / 640vw ) * 100); }
    .feature.feature-sm.feature-pull {
      margin-bottom: calc((-175.5 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-sm {
        height: calc((351 / 1024vw ) * 100); }
        .feature.feature-sm.feature-pull {
          margin-bottom: calc((-175.5 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-sm {
        height: 351px; }
        .feature.feature-sm.feature-pull {
          margin-bottom: -175.5px; } }
  .feature.feature-xs {
    height: calc((263 / 640vw ) * 100); }
    .feature.feature-xs.feature-pull {
      margin-bottom: calc((-131.5 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-xs {
        height: calc((263 / 1024vw ) * 100); }
        .feature.feature-xs.feature-pull {
          margin-bottom: calc((-131.5 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-xs {
        height: 263px; }
        .feature.feature-xs.feature-pull {
          margin-bottom: -131.5px; } }
  .feature.feature-xxs {
    height: calc((192 / 640vw ) * 100); }
    .feature.feature-xxs.feature-pull {
      margin-bottom: calc((-96 / 640vw ) * 100); }
    @media (min-width: 768px) {
      .feature.feature-xxs {
        height: calc((192 / 1024vw ) * 100); }
        .feature.feature-xxs.feature-pull {
          margin-bottom: calc((-96 / 1024vw ) * 100); } }
    @media (min-width: 1024px) {
      .feature.feature-xxs {
        height: 192px; }
        .feature.feature-xxs.feature-pull {
          margin-bottom: -96px; } }
  .feature .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    min-height: 100%;
    overflow: hidden; }
    .feature .background a:focus::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      /* border: 2px solid red; */
      pointer-events: none;
      outline: 5px auto -webkit-focus-ring-color !important; }
    .feature .background .gradient-overlay {
      background: #fff;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(45%, rgba(0, 0, 0, 0)), color-stop(100%, black), to(black));
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 45%, black 100%, black 100%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, black 100%, black 100%);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      pointer-events: none; }
    .feature .background picture img, .feature .background video, .feature .background iframe {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center;'; }
    .feature .background img {
      max-width: unset;
      width: auto;
      height: 100%; }
    .feature .background picture {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%; }
    .feature .background video, .feature .background iframe {
      display: block; }
    .feature .background iframe {
      height: 167%; }
    @media (max-width: 1023.98px) {
      .feature .background iframe {
        min-width: 236%; } }
    @media screen and (min-width: 0\0) {
      .feature .background video {
        height: auto; } }
    @supports (-ms-ime-align: auto) {
      .feature .background video {
        height: auto; } }
  .feature .feature-container .content {
    position: absolute;
    right: auto;
    bottom: auto;
    top: auto !important;
    left: 0;
    bottom: 2rem !important;
    width: 100%;
    z-index: 4; }
    .feature .feature-container .content .container, .feature .feature-container .content .form-factory {
      position: relative;
      height: 100%; }
      .feature .feature-container .content .container > .row, .feature .feature-container .content .form-factory > .row {
        display: flex;
        align-items: center; }
        .feature .feature-container .content .container > .row .headline-text, .feature .feature-container .content .form-factory > .row .headline-text, .feature .feature-container .content .container > .row .headline-text *, .feature .feature-container .content .form-factory > .row .headline-text * {
          pointer-events: none;
          color: #fff;
          margin: 0;
          font-size: 1.375rem;
          line-height: 32px;
          letter-spacing: 2px; }
        .feature .feature-container .content .container > .row .btn, .feature .feature-container .content .form-factory > .row .btn {
          font-size: .875rem;
          line-height: 24px;
          letter-spacing: .5px;
          margin-bottom: 0; }
    @media (max-width: 1023.98px) {
      .feature .feature-container .content.slider-content .container, .feature .feature-container .content.slider-content .form-factory {
        padding: 0 5rem; } }
    @media (max-width: 767.98px) {
      .feature .feature-container .content.slider-content .container, .feature .feature-container .content.slider-content .form-factory {
        padding: 0 6px; } }
    @media (min-width: 768px) {
      .feature .feature-container .content {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%); } }
  @media (max-width: 767.98px) {
    .feature .feature-container .content {
      bottom: 6rem; }
      .feature .feature-container .content.slider-content {
        bottom: 2rem; }
      .feature .feature-container .content .container, .feature .feature-container .content .form-factory {
        text-align: center;
        margin: 0 !important; }
        .feature .feature-container .content .container .btn, .feature .feature-container .content .form-factory .btn {
          float: inherit !important;
          padding: 0.5rem;
          font-size: 1.5rem;
          margin-top: 1rem; } }
  .feature .badge-weather {
    z-index: 1;
    top: 0; }
  @media (max-width: 1023.98px) {
    .feature .badge-weather {
      top: 10%; } }
  @media (max-width: 639.98px) {
    .feature .badge-weather {
      top: 20%; } }
  .feature .property-landing-slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: inherit;
    padding: 0; }
    .feature .property-landing-slider .carousel,
    .feature .property-landing-slider .carousel-factory,
    .feature .property-landing-slider .slick-slider .slick-list,
    .feature .property-landing-slider .slick-slider .slick-track,
    .feature .property-landing-slider .carousel .row {
      height: 100%; }
    .feature .property-landing-slider .carousel {
      padding: 0; }
    .feature .property-landing-slider .carousel-factory {
      padding: 0; }
    .feature .property-landing-slider .carousel-indicators {
      bottom: 30%; }
      .feature .property-landing-slider .carousel-indicators li {
        opacity: 1;
        background-color: transparent; }
    .feature .property-landing-slider .slick-dots li button:before {
      border-color: white; }
    .feature .property-landing-slider .slick-dots .slick-active button:before {
      background-color: white; }
    .feature .property-landing-slider .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow {
      position: absolute;
      bottom: 7%;
      top: auto; }
      .feature .property-landing-slider .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow.slick-prev {
        left: 3.125vw; }
      .feature .property-landing-slider .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow.slick-next {
        right: 3.125vw; }
    @media (min-width: 768px) {
      .feature .property-landing-slider .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow {
        bottom: 0; }
        .feature .property-landing-slider .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow.slick-prev {
          left: 2%;
          bottom: 12%;
          right: auto;
          transform: none;
          margin: 0; }
        .feature .property-landing-slider .carousel-factory[data-arrows-mobile="bottom"] .slick-arrow.slick-next {
          right: 2%;
          bottom: 12%;
          left: auto;
          transform: none;
          margin: 0; } }
  .feature.feature-property {
    height: calc((950 / 640vw ) * 100); }
    .feature.feature-property .feature-container .content {
      top: auto !important;
      bottom: 4rem !important; }
      .feature.feature-property .feature-container .content picture img {
        filter: drop-shadow(2px 2px 2px #363436); }
  .feature.feature-property-sub {
    height: calc((500 / 640vw ) * 100); }
    .feature.feature-property-sub .container .content, .feature.feature-property-sub .form-factory .content {
      top: 25% !important; }
  .feature.feature-gallery {
    height: calc((325 / 640vw ) * 100);
    margin-bottom: calc((120 / 640vw ) * 100);
    padding-bottom: calc((75 / 640vw ) * 100); }
    .feature.feature-gallery .container.container-inset, .feature.feature-gallery .container-inset.form-factory {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc((-80 / 640vw ) * 100);
      max-width: calc((420 / 640vw ) * 100);
      height: auto;
      padding-top: calc((26 / 640vw ) * 100); }
      .feature.feature-gallery .container.container-inset:before, .feature.feature-gallery .container-inset.form-factory:before {
        border-width: calc((8 / 640vw ) * 100); }
    .feature.feature-gallery .icon-svg {
      width: calc((23 / 640vw ) * 100);
      height: calc((23 / 640vw ) * 100);
      vertical-align: baseline;
      margin-left: 5px; }
  .feature.feature-blog {
    height: calc((250 / 640vw ) * 100); }
  .feature.property-sub-nav {
    height: calc((150 / 640vw ) * 100); }
  .feature.feature-blog-post {
    height: calc((330 / 640vw ) * 100); }
  .feature .btn-container {
    margin: calc((25 / 640vw ) * 100) calc((19 / 640vw ) * 100);
    z-index: 1; }
  @media (max-width: 767.98px) {
    .feature.feature-drop-sm-down {
      position: relative;
      margin-bottom: calc((120 / 640vw ) * 100);
      height: auto; }
      .feature.feature-drop-sm-down .background {
        position: relative;
        height: calc((500 / 640vw ) * 100); }
        .feature.feature-drop-sm-down .background.has-video {
          height: calc((850 / 640vw ) * 100); }
      .feature.feature-drop-sm-down .content {
        bottom: 5.5rem; } }
  @media (max-width: 767.98px) and (min-width: 640px) and (max-width: 767.98px) {
    .feature.feature-drop-sm-down .background {
      height: 500px; }
    .feature.feature-drop-sm-down .content {
      margin: -250px auto 75px auto; } }
  @media (min-width: 640px) {
    .feature.feature-property {
      height: 845px; }
    .feature.feature-property-sub {
      height: 500px; }
    .feature.feature-gallery {
      padding-bottom: 75px; }
      .feature.feature-gallery .container.container-inset, .feature.feature-gallery .container-inset.form-factory {
        bottom: -80px; }
    .feature.feature-blog {
      height: 250px; }
    .feature.property-sub-nav {
      height: 150px; }
    .feature.feature-blog-post {
      height: 330px; } }
  @media (min-width: 768px) {
    .feature.feature-property {
      height: 550px; }
      .feature.feature-property .feature-container .content {
        top: auto;
        bottom: 2rem !important; }
    .feature.feature-property-sub {
      height: calc((350 / 1024vw ) * 100); }
      .feature.feature-property-sub .feature-container .content {
        top: auto;
        bottom: 2rem !important; }
    .feature.feature-gallery {
      height: calc((520 / 1024vw ) * 100);
      margin-bottom: calc((120 / 1024vw ) * 100);
      padding-bottom: calc((75 / 1024vw ) * 100); }
      .feature.feature-gallery .container.container-inset, .feature.feature-gallery .container-inset.form-factory {
        bottom: calc((-40 / 1024vw ) * 100);
        max-width: 469px;
        padding-top: 20px; }
        .feature.feature-gallery .container.container-inset:before, .feature.feature-gallery .container-inset.form-factory:before {
          border-width: 6px; }
      .feature.feature-gallery .icon-svg {
        width: 19px;
        height: 19px; }
    .feature.feature-blog {
      height: calc((200 / 1024vw ) * 100); }
    .feature.property-sub-nav {
      height: calc((150 / 1024vw ) * 100); }
    .feature.feature-blog-post {
      max-width: 920px;
      height: calc((306 / 1024vw ) * 100);
      margin: auto; } }
  @media (min-width: 1024px) {
    .feature.feature-property {
      height: 600px; }
    .feature.feature-property-sub {
      height: 350px; }
    .feature.feature-gallery {
      height: 520px;
      margin-bottom: 120px;
      padding-bottom: 75px; }
      .feature.feature-gallery .container.container-inset, .feature.feature-gallery .container-inset.form-factory {
        bottom: -40px; }
    .feature.feature-blog {
      height: 200px; }
    .feature.property-sub-nav {
      height: 150px; }
    .feature.feature-blog-post {
      height: 306px; } }
  @media (max-width: 767.98px) {
    .feature .btn-container {
      top: 13vw; } }

.footer {
  padding: calc((65 / 640vw ) * 100) 0 calc((75 / 640vw ) * 100) 0;
  background-image: url("/modules/ilg-aah-jahia-assets/img/pattern-footer.svg");
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 325%;
  border-top: 1px solid #efefef;
  overflow: hidden; }
  .footer a {
    color: #292b2c;
    text-decoration: none; }
    .footer a:hover, .footer a:focus {
      color: #007697; }
  .footer nav ul {
    margin: 0 0 30px 0;
    padding: 0;
    text-align: center;
    list-style: none; }
    .footer nav ul li {
      float: left;
      margin: 0 0 3em 0;
      width: 100%; }
      .footer nav ul li a {
        font-size: calc((32 / 640vw ) * 100);
        font-weight: 500;
        line-height: 1em; }
        .footer nav ul li a:hover, .footer nav ul li a:focus {
          color: #007697; }
  .footer p {
    margin: 0 0 0.5em 0;
    padding: 0 2.5em;
    font-size: calc((28 / 640vw ) * 100);
    text-align: center;
    color: #2b2b2b;
    line-height: 1.46em;
    font-weight: 300; }
    .footer p strong {
      font-size: calc((32 / 640vw ) * 100);
      font-weight: 500;
      color: #000000; }
    .footer p a {
      text-decoration: none; }
      .footer p a:hover, .footer p a:focus {
        color: #007697; }
  .footer .copyright {
    margin: 2.5em 2em 1em 2em;
    font-size: calc((28 / 640vw ) * 100);
    font-weight: 300;
    text-align: center; }
  .footer .country-selector {
    display: inline-block;
    margin: 0 auto 30px auto; }
    .footer .country-selector .icon-flag {
      margin-right: 0.5em; }
  .footer .social-icons {
    margin: calc((50 / 640vw ) * 100) 0 calc((45 / 640vw ) * 100) 0;
    text-align: center; }
    .footer .social-icons ul.list-social {
      margin: 0;
      padding: 0;
      list-style: none; }
      .footer .social-icons ul.list-social li {
        display: inline-block;
        margin: 0 calc((20 / 640vw ) * 100) 0 0;
        vertical-align: top; }
        .footer .social-icons ul.list-social li a {
          display: block;
          width: calc((65 / 640vw ) * 100);
          height: calc((65 / 640vw ) * 100);
          padding: calc((12 / 640vw ) * 100);
          background-color: #455565;
          border-radius: 100%; }
          .footer .social-icons ul.list-social li a .icon-svg {
            fill: #ffffff;
            vertical-align: top; }
            .footer .social-icons ul.list-social li a .icon-svg svg {
              pointer-events: none; }
          .footer .social-icons ul.list-social li a:hover {
            background-color: #007697; }
          .footer .social-icons ul.list-social li a::after {
            content: none; }
  @media (min-width: 640px) {
    .footer {
      padding: 65px 0 75px 0; }
      .footer nav ul li a {
        font-size: 32px; }
      .footer p {
        font-size: 28px; }
        .footer p strong {
          font-size: 32px; }
      .footer .social-icons {
        margin: 50px 0 45px 0; }
        .footer .social-icons ul.list-social li {
          margin: 0 20px 0 0; }
          .footer .social-icons ul.list-social li a {
            width: 65px;
            height: 65px;
            padding: 12px; }
      .footer .copyright {
        margin: 2.5em 0 1em 0;
        font-size: 28px; } }
  @media (min-width: 768px) {
    .footer {
      padding: 45px 0 75px 0;
      background-size: auto; }
      .footer nav ul::after {
        display: block;
        clear: both;
        content: ""; }
      .footer nav ul li {
        float: left;
        margin: 0 0 1em 0;
        padding: 0 1em;
        line-height: 1.1em; }
        .footer nav ul li a {
          font-size: 14px; }
      .footer p {
        font-size: 14px; }
        .footer p strong {
          font-size: 14px; }
      .footer .social-icons ul.list-social li a {
        width: 36px;
        height: 36px;
        padding: 8px; }
      .footer .copyright {
        margin: 0;
        font-size: 12px; } }
  @media (min-width: 1024px) {
    .footer nav ul {
      display: flex;
      flex-wrap: wrap;
      text-align: left; }
      .footer nav ul li {
        flex: 1 1 25%; }
        .footer nav ul li:nth-last-child(1):nth-child(odd) {
          text-align: left; }
    .footer p {
      padding: 0;
      text-align: left; }
    .footer .social-icons {
      margin: 0;
      text-align: left; }
      .footer .social-icons ul.list-social {
        margin: 0 0 30px 0;
        padding: 0;
        list-style: none; }
        .footer .social-icons ul.list-social li {
          display: inline-block;
          margin: 0 10px 0 0;
          vertical-align: top; }
    .footer .country-selector {
      margin: 0 0 0 -8px; }
    .footer .copyright {
      margin: 0;
      padding-top: 0.75em;
      text-align: left; } }

@media (max-width: 1023.98px) {
  .maplessFooter .headline-5 {
    line-height: 9vw; } }

.global-breadcrumb {
  font-size: calc((14 / 640vw ) * 100);
  line-height: calc((24 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  display: none;
  position: absolute;
  top: 0;
  font-weight: 300;
  color: #2b2b2b;
  z-index: 1031; }
  @media (min-width: 640px) {
    .global-breadcrumb {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .global-breadcrumb .menu-wrapper {
    position: relative;
    margin: 0 auto 0 50px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px; }
    .global-breadcrumb .menu-wrapper > ul > li {
      display: inline-block; }
    .global-breadcrumb .menu-wrapper ul li > button, .global-breadcrumb .menu-wrapper ul li > a {
      padding: 0.25em 0.5em; }
    .global-breadcrumb .menu-wrapper ul li > button {
      display: flex;
      align-items: center;
      font-weight: 500; }
      .global-breadcrumb .menu-wrapper ul li > button .icon-svg {
        width: 0.65em;
        height: 0.65em;
        margin-left: 0.5em;
        transition: transform 0.2s; }
      .global-breadcrumb .menu-wrapper ul li > button[aria-expanded="true"] .icon-svg {
        transform: rotate(-180deg); }
  .global-breadcrumb .collapse, .global-breadcrumb .collapsing {
    transition: all 0.1s;
    position: absolute;
    top: 4vh; }
  .global-breadcrumb .global-breadcrumb-nav {
    bottom: 0;
    left: 0;
    width: 25vw;
    max-width: 27em;
    min-height: 50vh;
    padding: 1.25rem;
    background-color: #ffffff;
    z-index: 1050;
    box-shadow: 0.25rem 0.25rem 1rem rgba(43, 43, 43, 0.05);
    overflow: visible; }
    .global-breadcrumb .global-breadcrumb-nav .close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0.25em;
      padding: 0.75em; }
      .global-breadcrumb .global-breadcrumb-nav .close .icon-svg {
        width: 0.75em;
        height: 0.75em; }
    .global-breadcrumb .global-breadcrumb-nav .hotel-logo {
      max-width: 20em;
      margin: 1em auto;
      padding: 2em; }
    .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav {
      display: flex;
      flex-direction: column;
      margin: 0 0 2em 0 !important; }
      .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li {
        width: 100%;
        flex-direction: column; }
        .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li a:not(.btn), .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li .navbar-a {
          text-align: left !important; }
        .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li .navbar-dropdown-toggle {
          justify-content: space-between; }
          .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li .navbar-dropdown-toggle .icon-svg {
            width: 0.85em !important;
            height: 0.85em !important;
            transform: none !important; }
        .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li.dropdown {
          position: static !important; }
        .global-breadcrumb .global-breadcrumb-nav ul.navbar-nav > li:nth-child(2) {
          display: none; }
    .global-breadcrumb .global-breadcrumb-nav .navbar-panel {
      padding: 1rem 1.5em !important;
      transition: none !important;
      display: none !important;
      top: 0 !important;
      margin-top: 7em !important; }
      .global-breadcrumb .global-breadcrumb-nav .navbar-panel.show {
        display: flex !important;
        position: absolute !important;
        left: 100% !important;
        width: 24vw !important; }
        .global-breadcrumb .global-breadcrumb-nav .navbar-panel.show.navbar-panel-full {
          width: 68vw !important; }
      .global-breadcrumb .global-breadcrumb-nav .navbar-panel .navbar-panel-nav {
        width: 100%;
        margin: 0 !important; }
    .global-breadcrumb .global-breadcrumb-nav .navbar-panel-container {
      max-height: none !important;
      padding: 1em 0.75em !important; }
    .global-breadcrumb .global-breadcrumb-nav .btn-back {
      display: none !important; }
    .global-breadcrumb .global-breadcrumb-nav .navbar-panel-action {
      display: none; }
    .global-breadcrumb .global-breadcrumb-nav .navbar-action {
      height: auto !important;
      margin: 1em !important; }
      .global-breadcrumb .global-breadcrumb-nav .navbar-action .btn {
        padding: 1em !important;
        width: 100% !important; }
  .is-stuck .global-breadcrumb .menu-wrapper {
    display: none; }
  @media (min-width: 1024px) {
    .global-breadcrumb {
      display: block; } }

.grid-view .hotel-card {
  width: 100%;
  min-height: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #efefef;
  font-size: calc((26vw / 640vw ) * 100); }
  .grid-view .hotel-card::after {
    display: block;
    clear: both;
    content: ""; }
  .grid-view .hotel-card .image {
    display: block;
    float: left;
    width: 40%;
    padding-right: 20px; }
  .grid-view .hotel-card h3 {
    padding: 0 30px 0 0;
    background-image: url("/img/icons/icon-heart.svg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 20px;
    font-size: calc((32 / 640vw ) * 100); }
    .grid-view .hotel-card h3.favorite {
      background-image: url("/img/icons/icon-heart-filled.svg"); }
  .grid-view .hotel-card p {
    margin-bottom: 10px; }
  .grid-view .hotel-card .details {
    float: left;
    width: 60%; }
  .grid-view .hotel-card .tag {
    display: block;
    clear: both;
    float: left;
    margin: 0 10px 10px 0;
    padding: 3px 10px;
    border: 2px solid #f7f7f7;
    border-radius: 2em; }
    .grid-view .hotel-card .tag.stylish {
      border-color: #209a8b; }
    .grid-view .hotel-card .tag.family-friendly {
      border-color: #e89d45; }
    .grid-view .hotel-card .tag.distinctive {
      border-color: #87cca1; }
    .grid-view .hotel-card .tag.budget {
      border-color: #bcabe4; }
  .grid-view .hotel-card footer {
    clear: both; }
  .grid-view .hotel-card .trip-advisor {
    clear: left;
    float: left; }
    .grid-view .hotel-card .trip-advisor::after {
      display: block;
      clear: both;
      content: ""; }
    .grid-view .hotel-card .trip-advisor img {
      float: left;
      width: 40px;
      margin-right: 10px; }
    .grid-view .hotel-card .trip-advisor .rating {
      float: left; }
  .grid-view .hotel-card .price {
    float: right; }
  .grid-view .hotel-card .rating {
    position: relative;
    width: 90px;
    height: 18px;
    overflow: hidden; }
    .grid-view .hotel-card .rating .circles,
    .grid-view .hotel-card .rating .dots {
      position: absolute;
      width: 90px;
      height: 18px; }
    .grid-view .hotel-card .rating .dots {
      margin-left: 5px; }
    .grid-view .hotel-card .rating .circle {
      display: block;
      float: left;
      width: 18px;
      height: 18px;
      border: 2px solid #209a8b;
      border-radius: 100%; }
    .grid-view .hotel-card .rating .dot {
      display: block;
      float: left;
      width: 8px;
      height: 8px;
      margin: 5px 10px 0 0;
      border-radius: 100%;
      background-color: #209a8b; }
    .grid-view .hotel-card .rating .slider {
      display: block;
      right: 0;
      width: 0;
      height: 18px;
      background-color: #ffffff; }
    .grid-view .hotel-card .rating.stars-05 .slider {
      width: 81px; }
    .grid-view .hotel-card .rating.stars-1 .slider {
      width: 72px; }
    .grid-view .hotel-card .rating.stars-15 .slider {
      width: 63px; }
    .grid-view .hotel-card .rating.stars-2 .slider {
      width: 54px; }
    .grid-view .hotel-card .rating.stars-25 .slider {
      width: 45px; }
    .grid-view .hotel-card .rating.stars-3 .slider {
      width: 36px; }
    .grid-view .hotel-card .rating.stars-35 .slider {
      width: 27px; }
    .grid-view .hotel-card .rating.stars-4 .slider {
      width: 18px; }
    .grid-view .hotel-card .rating.stars-45 .slider {
      width: 9px; }
    .grid-view .hotel-card .rating.stars-5 .slider {
      width: 0; }

@media (min-width: 640px) {
  .grid-view .hotel-card {
    font-size: 26px; }
    .grid-view .hotel-card h3 {
      min-height: 20px;
      font-size: 32px; } }

@media (min-width: 768px) {
  .grid-view .hotel-card {
    margin-bottom: 20px;
    font-size: 14px; }
    .grid-view .hotel-card .image {
      width: 50%; }
    .grid-view .hotel-card .details {
      width: 50%; }
    .grid-view .hotel-card h3 {
      font-size: 16px; }
    .grid-view .hotel-card .tag {
      font-size: 12px; }
    .grid-view .hotel-card footer {
      position: absolute;
      bottom: 20px;
      width: 50%; } }

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000; }
  .header ul {
    padding: 0;
    list-style: none; }
    .header ul li a, .header ul li .navbar-a {
      display: block;
      cursor: pointer; }
    .header ul li.dropdown {
      position: static; }
      .header ul li.dropdown > a, .header ul li.dropdown .navbar-a {
        position: relative; }
        .header ul li.dropdown > a > .icon-svg, .header ul li.dropdown .navbar-a > .icon-svg {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
          display: inline-block;
          width: calc((24 / 640vw ) * 100);
          height: calc((24 / 640vw ) * 100);
          margin-right: 1.5em; }
        .header ul li.dropdown > a:hover .icon-svg svg, .header ul li.dropdown > a:focus .icon-svg svg, .header ul li.dropdown .navbar-a:hover .icon-svg svg, .header ul li.dropdown .navbar-a:focus .icon-svg svg {
          fill: #007697; }
    .header ul li .view-all-btn a {
      text-decoration: underline; }
      .header ul li .view-all-btn a:hover, .header ul li .view-all-btn a:focus {
        text-decoration: underline; }
  .header nav.navbar {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: normal;
    width: 100%;
    height: auto;
    max-height: 100vh;
    padding: 0;
    background-color: #efefef;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    /*&.expanded {
			position: relative;
			top: 0;
			bottom: 0;
			height: 100vh;
			flex-wrap: nowrap;
			overflow-x: hidden;
			overflow-y: scroll;

			.btn-property-navbar, .property-navbar {
				display: none;
			}

			//&.open {
			//	.navbar-brand {
			//		right: 100%;
			//	}
			//	.btn-back {
			//		position: initial;
			//		bottom: auto;
			//		left: 0;
			//	}
			//}
		}*/ }
    .header nav.navbar .navbar-header {
      position: relative;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      height: calc((108 / 640vw ) * 100);
      background-color: #ffffff; }
      .header nav.navbar .navbar-header .navbar-brand {
        display: flex;
        width: 12em;
        max-width: 14rem;
        max-height: 75%;
        margin: 0 auto;
        padding: 0.25em;
        text-align: center;
        transition: right 0.1s, height 0.1s; }
        .header nav.navbar .navbar-header .navbar-brand picture, .header nav.navbar .navbar-header .navbar-brand img {
          display: flex;
          width: 100%; }
        .header nav.navbar .navbar-header .navbar-brand img {
          object-fit: contain; }
      .header nav.navbar .navbar-header .property-navbar-brand {
        max-height: 100%; }
      .header nav.navbar .navbar-header .navbar-action {
        display: none; }
    .header nav.navbar .navbar-collapse {
      position: relative;
      height: 0;
      border-top: 1px solid #e1e1e1;
      transition: height 0.1s;
      overflow-x: hidden;
      overflow-y: auto; }
      .header nav.navbar .navbar-collapse.show {
        height: 100%;
        min-height: 100vh;
        padding-bottom: 100%; }
        .header nav.navbar .navbar-collapse.show ~ .btn-property-navbar {
          display: none !important; }
    .header nav.navbar .btn-back {
      display: flex;
      align-items: center;
      padding: 1.25em 1.5em;
      font-weight: 300; }
      .header nav.navbar .btn-back .icon-svg {
        width: 1.25em;
        height: 1.25em;
        margin-right: 0.125em;
        pointer-events: none; }
      .header nav.navbar .btn-back:hover, .header nav.navbar .btn-back:focus {
        color: #007697; }
        .header nav.navbar .btn-back:hover .icon-svg svg, .header nav.navbar .btn-back:focus .icon-svg svg {
          fill: #007697; }
    .header nav.navbar ul.navbar-nav {
      position: relative;
      height: 100%;
      background-color: #ffffff; }
      .header nav.navbar ul.navbar-nav > li {
        border-bottom: 1px solid #e1e1e1; }
        .header nav.navbar ul.navbar-nav > li a:not(.btn), .header nav.navbar ul.navbar-nav > li .navbar-a {
          width: 100%;
          padding: 1em 1.5em;
          font-size: calc((32 / 640vw ) * 100);
          font-weight: 500;
          line-height: 1.25em;
          text-align: left;
          color: #2b2b2b; }
          .header nav.navbar ul.navbar-nav > li a:not(.btn):hover, .header nav.navbar ul.navbar-nav > li a:not(.btn):focus, .header nav.navbar ul.navbar-nav > li .navbar-a:hover, .header nav.navbar ul.navbar-nav > li .navbar-a:focus {
            color: #007697; }
    .header nav.navbar .navbar-panel {
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      height: 100%;
      transition: all 0.2s, height 1ms;
      background-color: #ffffff;
      z-index: 1000; }
      .header nav.navbar .navbar-panel.show {
        left: 0; }
      .header nav.navbar .navbar-panel .container, .header nav.navbar .navbar-panel .form-factory {
        position: relative;
        width: 100%;
        padding: 0; }
      .header nav.navbar .navbar-panel ul.navbar-panel-nav,
      .header nav.navbar .navbar-panel ul.navbar-panel-detail-nav {
        border-top: 1px solid #efefef; }
      .header nav.navbar .navbar-panel ul.navbar-panel-nav {
        height: auto;
        margin: 0;
        padding: 0;
        overflow: visible; }
        .header nav.navbar .navbar-panel ul.navbar-panel-nav li {
          border-bottom: 1px solid #efefef; }
          @media (min-width: 768px) {
            .header nav.navbar .navbar-panel ul.navbar-panel-nav li {
              border-bottom-width: 1px; } }
          .header nav.navbar .navbar-panel ul.navbar-panel-nav li a {
            display: block; }
      .header nav.navbar .navbar-panel .navbar-panel-action {
        margin: 0;
        padding: calc((55 / 640vw ) * 100) calc((70 / 640vw ) * 100) calc((50 / 640vw ) * 100);
        background-color: #ffffff; }
        .header nav.navbar .navbar-panel .navbar-panel-action .btn {
          width: 100%;
          line-height: 1.25em; }
      .header nav.navbar .navbar-panel .navbar-panel-detail {
        position: absolute;
        overflow-y: auto;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        transition: left 0.1s;
        background: #fff;
        z-index: 3; }
        .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 {
          display: none; }
        .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature {
          position: relative;
          width: 100vw;
          height: 100vw; }
          .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content {
            position: absolute;
            top: auto;
            right: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            margin: 1em 0;
            text-align: center; }
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content.content-top {
              position: absolute;
              top: 0;
              right: auto;
              bottom: auto;
              left: 50%;
              transform: translateX(-50%); }
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content.content-middle {
              position: absolute;
              top: 50%;
              right: auto;
              bottom: auto;
              left: 50%;
              transform: translate(-50%, -50%); }
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content.content-bottom {
              position: absolute;
              top: auto;
              right: 0;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%); }
        .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature {
          position: relative;
          width: 100vw;
          height: 150vw; }
          .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature .content {
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            text-align: center; }
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature .content .icon-svg {
              width: 50%; }
        .header nav.navbar .navbar-panel .navbar-panel-detail.collapse {
          height: auto; }
          .header nav.navbar .navbar-panel .navbar-panel-detail.collapse.show {
            left: 0;
            padding-bottom: 150%; }
      .header nav.navbar .navbar-panel.collapse {
        height: auto;
        overflow-y: visible; }
    .header nav.navbar .dropdown > a, .header nav.navbar .dropdown .navbar-a {
      position: relative; }
      .header nav.navbar .dropdown > a .icon-svg, .header nav.navbar .dropdown .navbar-a .icon-svg {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
        width: 1em;
        height: 1em;
        margin-right: calc((40 / 640vw ) * 100); }
    .header nav.navbar .dropdown.active .navbar-panel-detail {
      display: block;
      left: 0;
      z-index: 1; }
    .header nav.navbar .navbar-action {
      position: relative;
      margin: 1.5em 3em; }
      .header nav.navbar .navbar-action .btn {
        width: 100%;
        background-color: #b8146a;
        margin: 0; }
        .header nav.navbar .navbar-action .btn:hover {
          background-color: #560530; }
    .header nav.navbar .navbar-header.zoomed {
      height: 13vh; }
    .header nav.navbar.is-stuck .navbar-header {
      display: flex; }
      .header nav.navbar.is-stuck .navbar-header.zoomed {
        height: 13vh; }
        .header nav.navbar.is-stuck .navbar-header.zoomed .navbar-action .btn {
          padding: 0; }
      .header nav.navbar.is-stuck .navbar-header .navbar-brand {
        display: none; }
      .header nav.navbar.is-stuck .navbar-header .navbar-action {
        display: flex;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0; }
    .header nav.navbar.is-stuck.expanded .navbar-header .navbar-action {
      display: none; }
    .header nav.navbar.is-stuck.expanded .navbar-brand {
      display: block; }
  @media (min-width: 640px) {
    .header nav.navbar .navbar-header .navbar-brand.main .icon-svg, .header nav.navbar .navbar-header .navbar-brand.main img {
      width: 213px;
      height: 44px;
      margin: 32px; }
    .header nav.navbar .navbar-header .navbar-action {
      margin-right: 92px; }
    .header nav.navbar ul.navbar-nav > li a:not(.btn), .header nav.navbar ul.navbar-nav > li .navbar-a {
      font-size: 32px; }
    .header nav.navbar .btn-back {
      font-size: 32px; }
    .header nav.navbar .navbar-action .btn {
      font-size: calc((30 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      padding: 24px 64px 28px; } }
  @media (min-width: 640px) and (min-width: 640px) {
    .header nav.navbar .navbar-action .btn {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 32px;
      letter-spacing: 0.5px; } }
  @media (min-width: 640px) {
    .header nav.navbar .navbar-panel .navbar-panel-action {
      padding: 55px 70px 50px; }
      .header nav.navbar .navbar-panel .navbar-panel-action .btn {
        font-size: calc((30 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        padding: 16px 64px 20px; } }
    @media (min-width: 640px) and (min-width: 640px) {
      .header nav.navbar .navbar-panel .navbar-panel-action .btn {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
  @media (min-width: 768px) {
    .header nav.navbar .navbar-header {
      height: 108px; }
      .header nav.navbar .navbar-header .navbar-brand {
        max-height: 80%; } }
  @media (min-width: 1024px) {
    .header {
      position: fixed;
      right: 0;
      left: auto;
      width: 100%;
      height: auto;
      margin: auto;
      background-color: #ffffff; }
      .header ul li a, .header ul li .navbar-a {
        display: inline-block; }
      .header nav.navbar {
        position: relative;
        max-height: 132px;
        max-width: 1500px;
        margin: 0 auto;
        background-color: #ffffff;
        overflow-x: visible;
        overflow-y: visible;
        flex-direction: row;
        /*&.expanded {
					height: auto;
				}*/ }
        .header nav.navbar .navbar-header {
          display: flex;
          align-items: center;
          height: auto;
          overflow: visible; }
          .header nav.navbar .navbar-header .navbar-brand {
            max-width: 10.25em;
            max-height: 50%;
            margin: auto 1em auto 2em;
            overflow: visible; }
            .header nav.navbar .navbar-header .navbar-brand .icon-svg, .header nav.navbar .navbar-header .navbar-brand img {
              transition: all 0.1s ease-out; }
        .header nav.navbar .navbar-collapse {
          position: static;
          height: auto;
          border: 0;
          z-index: 3;
          overflow: visible; }
        .header nav.navbar ul.navbar-nav {
          position: static;
          margin: 0 14px 0 auto;
          align-items: center; }
          .header nav.navbar ul.navbar-nav > li {
            border: 0; }
            .header nav.navbar ul.navbar-nav > li.relative {
              position: relative; }
              .header nav.navbar ul.navbar-nav > li.relative .navbar-panel {
                width: auto;
                min-width: 15em;
                padding: 0.5em; }
                .header nav.navbar ul.navbar-nav > li.relative .navbar-panel .container, .header nav.navbar ul.navbar-nav > li.relative .navbar-panel .form-factory {
                  width: auto;
                  padding-bottom: 0; }
                .header nav.navbar ul.navbar-nav > li.relative .navbar-panel .navbar-panel-nav {
                  padding: 0;
                  margin-bottom: 0; }
                  .header nav.navbar ul.navbar-nav > li.relative .navbar-panel .navbar-panel-nav > li {
                    width: auto;
                    white-space: nowrap; }
                    .header nav.navbar ul.navbar-nav > li.relative .navbar-panel .navbar-panel-nav > li:last-child {
                      margin-bottom: 0; }
                    .header nav.navbar ul.navbar-nav > li.relative .navbar-panel .navbar-panel-nav > li a {
                      white-space: normal; }
            .header nav.navbar ul.navbar-nav > li:first-child {
              display: flex;
              align-items: center;
              justify-content: space-between; }
            .header nav.navbar ul.navbar-nav > li > a:not(.btn), .header nav.navbar ul.navbar-nav > li .navbar-a {
              text-align: center; }
            .header nav.navbar ul.navbar-nav > li a:not(.btn), .header nav.navbar ul.navbar-nav > li .navbar-a {
              padding: 14px 20px;
              font-size: 14px;
              line-height: 1.25em; }
              .header nav.navbar ul.navbar-nav > li a:not(.btn).navbar-dropdown-toggle, .header nav.navbar ul.navbar-nav > li .navbar-a.navbar-dropdown-toggle {
                display: flex;
                align-items: center; }
                .header nav.navbar ul.navbar-nav > li a:not(.btn).navbar-dropdown-toggle .icon-svg, .header nav.navbar ul.navbar-nav > li .navbar-a.navbar-dropdown-toggle .icon-svg {
                  position: relative;
                  flex-shrink: 0;
                  margin: 0 0 0 0.5em;
                  transform: rotate(90deg);
                  overflow: visible;
                  transition: transform 0.1s; }
                .header nav.navbar ul.navbar-nav > li a:not(.btn).navbar-dropdown-toggle:not(.collapsed), .header nav.navbar ul.navbar-nav > li .navbar-a.navbar-dropdown-toggle:not(.collapsed) {
                  color: #007697; }
                  .header nav.navbar ul.navbar-nav > li a:not(.btn).navbar-dropdown-toggle:not(.collapsed) .icon-svg, .header nav.navbar ul.navbar-nav > li .navbar-a.navbar-dropdown-toggle:not(.collapsed) .icon-svg {
                    transform: rotate(-90deg); }
                    .header nav.navbar ul.navbar-nav > li a:not(.btn).navbar-dropdown-toggle:not(.collapsed) .icon-svg svg, .header nav.navbar ul.navbar-nav > li .navbar-a.navbar-dropdown-toggle:not(.collapsed) .icon-svg svg {
                      fill: #007697; }
        .header nav.navbar .dropdown > a, .header nav.navbar .dropdown .navbar-a {
          position: relative;
          display: flex;
          align-items: center; }
          .header nav.navbar .dropdown > a .icon-svg, .header nav.navbar .dropdown .navbar-a .icon-svg {
            position: relative;
            top: auto;
            transform: none;
            margin: 0 0 0 0.5em;
            width: 0.75em !important;
            height: 0.75em !important; }
        .header nav.navbar .dropdown.show .icon-svg {
          transform: rotate(-180deg); }
          .header nav.navbar .dropdown.show .icon-svg svg {
            fill: #007697; }
        .header nav.navbar .navbar-action {
          position: relative;
          height: 140px;
          margin: 0 0 -10px;
          padding: 0;
          z-index: 1001;
          transition: all 0.1s ease-in-out; }
          .header nav.navbar .navbar-action .btn {
            font-size: calc((16 / 640vw ) * 100);
            line-height: calc((18 / 640vw ) * 100);
            width: 8rem;
            padding: 54px 20px;
            white-space: normal;
            transition: padding 0.1s ease-in-out; } }
        @media (min-width: 1024px) and (min-width: 640px) {
          .header nav.navbar .navbar-action .btn {
            font-size: 16px;
            font-size: 1rem;
            line-height: 18px; } }
  @media (min-width: 1024px) {
            .header nav.navbar .navbar-action .btn .two-line-book-now {
              max-width: 40px;
              margin: auto;
              text-align: center; }
        .header nav.navbar .navbar-panel-container {
          width: 100%;
          max-height: 75vh;
          margin: 0;
          padding: 30px 40px;
          overflow-y: auto; }
        .header nav.navbar .navbar-panel {
          top: 100%;
          left: 0;
          right: 0;
          margin: 0 auto;
          height: auto;
          overflow-y: hidden;
          transition: height 0.1s ease;
          max-width: 960.0px; }
          .header nav.navbar .navbar-panel .container, .header nav.navbar .navbar-panel .form-factory {
            width: 960.0px;
            padding: inherit; }
          .header nav.navbar .navbar-panel.collapsing {
            height: 0; }
            .header nav.navbar .navbar-panel.collapsing.show {
              height: auto; }
          .header nav.navbar .navbar-panel ul.navbar-panel-nav,
          .header nav.navbar .navbar-panel ul.navbar-panel-detail-nav {
            border-top: 0; }
          .header nav.navbar .navbar-panel ul.navbar-panel-nav {
            position: relative;
            height: auto;
            margin: 10px auto 20px auto;
            padding-bottom: 150px;
            z-index: 0;
            overflow: visible; }
            .header nav.navbar .navbar-panel ul.navbar-panel-nav li {
              border: 0; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav li a {
                padding: 0.75em 1em;
                text-align: left;
                line-height: 1.2em;
                font-weight: 300; }
                .header nav.navbar .navbar-panel ul.navbar-panel-nav li a .icon-svg {
                  display: none; }
                .header nav.navbar .navbar-panel ul.navbar-panel-nav li a.active {
                  color: #007697; }
            .header nav.navbar .navbar-panel ul.navbar-panel-nav > li {
              width: 150px; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav > li:first-of-type a:not(.ga-menu-element) {
                font-size: 16px; }
                .header nav.navbar .navbar-panel ul.navbar-panel-nav > li:first-of-type a:not(.ga-menu-element):before, .header nav.navbar .navbar-panel ul.navbar-panel-nav > li:first-of-type a:not(.ga-menu-element):after {
                  display: none; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav > li a.active::before, .header nav.navbar .navbar-panel ul.navbar-panel-nav > li a.active::after {
                position: absolute;
                top: 0.9em;
                right: -18px;
                width: 0;
                height: 0;
                border: 0 solid transparent;
                content: '';
                pointer-events: none; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav > li a.active:before {
                margin-top: -7px;
                border-right-color: #7c7c7c;
                border-width: 7px; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav > li a.active:after {
                margin-top: -6px;
                border-right-color: #ffffff;
                border-width: 6px; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav > li a.active .navbar-panel-detail {
                display: block;
                left: 0;
                z-index: -1; }
            .header nav.navbar .navbar-panel ul.navbar-panel-nav ul.navbar-panel-detail-nav {
              width: 100%;
              height: 90%;
              min-height: 300px;
              padding-left: 18px;
              column-count: 3;
              -moz-column-fill: balance;
              border-left: 1px solid #7c7c7c;
              font-weight: 300; }
              .header nav.navbar .navbar-panel ul.navbar-panel-nav ul.navbar-panel-detail-nav li {
                display: inline-block;
                width: 100%; }
          .header nav.navbar .navbar-panel div.navbar-panel-nav {
            column-count: 3;
            column-gap: 1.5em; }
            .header nav.navbar .navbar-panel div.navbar-panel-nav ul {
              width: 100%;
              break-inside: avoid;
              padding-bottom: 1.5em; }
              .header nav.navbar .navbar-panel div.navbar-panel-nav ul li a {
                padding: 0.25em;
                font-weight: 300; }
                .header nav.navbar .navbar-panel div.navbar-panel-nav ul li a.property-heading {
                  display: block;
                  font-weight: 500;
                  text-transform: none;
                  font-size: calc((36 / 640vw ) * 100);
                  line-height: calc((48 / 640vw ) * 100);
                  letter-spacing: calc((4 / 640vw ) * 100);
                  margin-bottom: 0.5em;
                  text-transform: uppercase;
                  font-family: "Oswald", sans-serif;
                  font-weight: 500; } }
              @media (min-width: 1024px) and (min-width: 640px) {
                .header nav.navbar .navbar-panel div.navbar-panel-nav ul li a.property-heading {
                  font-size: 36px;
                  font-size: 2.25rem;
                  line-height: 48px;
                  letter-spacing: 4px; } }
              @media (min-width: 1024px) and (min-width: 768px) {
                .header nav.navbar .navbar-panel div.navbar-panel-nav ul li a.property-heading {
                  font-size: calc((22 / 640vw ) * 100);
                  line-height: calc((32 / 640vw ) * 100);
                  letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 768px) and (min-width: 640px) {
    .header nav.navbar .navbar-panel div.navbar-panel-nav ul li a.property-heading {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }
  @media (min-width: 1024px) {
          .header nav.navbar .navbar-panel .navbar-panel-action .btn {
            font-size: calc((12 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100);
            padding: 11px 32px 10px;
            line-height: 1.25em; } }
        @media (min-width: 1024px) and (min-width: 640px) {
          .header nav.navbar .navbar-panel .navbar-panel-action .btn {
            font-size: 12px;
            font-size: 0.75rem;
            letter-spacing: 0.5px; } }
  @media (min-width: 1024px) {
          .header nav.navbar .navbar-panel .navbar-panel-detail {
            display: none;
            left: 0;
            height: 100%;
            padding-left: 167px;
            background-color: #ffffff;
            z-index: -1; }
            .header nav.navbar .navbar-panel .navbar-panel-detail .row {
              height: 100%; }
            .header nav.navbar .navbar-panel .navbar-panel-detail ul.navbar-panel-detail-nav li a {
              font-weight: 300; }
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 {
              position: absolute;
              width: 780px;
              height: 342px;
              margin-left: 150px;
              padding: 0; }
              .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 .content {
                position: absolute;
                top: 50%;
                right: auto;
                bottom: auto;
                left: 50%;
                transform: translate(-50%, -50%); }
              .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure {
                position: absolute;
                display: inline-block; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure picture {
                  position: relative;
                  overflow: hidden;
                  position: absolute;
                  width: 100%;
                  height: 100%; }
                  .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure picture img {
                    position: absolute;
                    top: 50%;
                    right: auto;
                    bottom: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: initial;
                    min-width: 100%;
                    height: inherit;
                    object-fit: cover;
                    font-family: "object-fit: cover; object-position: center;"; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure.image-1 {
                  top: 47px;
                  left: 18px;
                  width: 226px;
                  height: 228px; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure.image-2 {
                  top: 0;
                  left: 262px;
                  width: 144px;
                  height: 144px; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure.image-3 {
                  top: 165px;
                  left: 262px;
                  width: 144px;
                  height: 144px; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-1 figure.image-4 {
                  top: 0;
                  left: 424px;
                  width: 338px;
                  height: 248px; }
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 {
              position: absolute;
              min-height: 300px; }
              .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 ul.navbar-panel-detail-nav {
                column-count: 2; }
              .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature {
                position: relative;
                width: 275px;
                height: 280px; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content {
                  position: absolute;
                  top: auto;
                  right: 0;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 100%;
                  margin-bottom: 1em;
                  text-align: center; }
                  .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content .headline-1 {
                    font-size: calc((28 / 640vw ) * 100);
                    line-height: calc((32 / 640vw ) * 100);
                    letter-spacing: calc((3 / 640vw ) * 100); } }
                @media (min-width: 1024px) and (min-width: 640px) {
                  .header nav.navbar .navbar-panel .navbar-panel-detail.layout-2 .feature .content .headline-1 {
                    font-size: 28px;
                    font-size: 1.75rem;
                    line-height: 32px;
                    letter-spacing: 3px; } }
  @media (min-width: 1024px) {
            .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 {
              position: absolute;
              min-height: 440px; }
              .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 ul.navbar-panel-detail-nav {
                column-count: 2; }
              .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature {
                position: relative;
                width: 275px;
                height: 420px; }
                .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature .content {
                  position: absolute;
                  top: 50%;
                  right: auto;
                  bottom: auto;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 80%;
                  text-align: center; }
                  .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature .content .headline-1 {
                    font-size: calc((28 / 640vw ) * 100);
                    line-height: calc((32 / 640vw ) * 100);
                    letter-spacing: calc((3 / 640vw ) * 100); } }
                @media (min-width: 1024px) and (min-width: 640px) {
                  .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature .content .headline-1 {
                    font-size: 28px;
                    font-size: 1.75rem;
                    line-height: 32px;
                    letter-spacing: 3px; } }
  @media (min-width: 1024px) {
                  .header nav.navbar .navbar-panel .navbar-panel-detail.layout-3 .feature .content .icon-svg {
                    width: 50%; }
            .header nav.navbar .navbar-panel .navbar-panel-detail.collapse {
              display: none; }
              .header nav.navbar .navbar-panel .navbar-panel-detail.collapse.show {
                display: block; }
          .header nav.navbar .navbar-panel.collapse {
            display: none; }
            .header nav.navbar .navbar-panel.collapse.show {
              display: block; }
          .header nav.navbar .navbar-panel .navbar-panel-preview p {
            font-size: calc((28 / 640vw ) * 100);
            line-height: calc((40 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100);
            font-weight: 300;
            color: #2b2b2b;
            background-color: #007697;
            color: #ffffff; } }
        @media (min-width: 1024px) and (min-width: 640px) {
          .header nav.navbar .navbar-panel .navbar-panel-preview p {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) and (min-width: 1024px) {
          .header nav.navbar .navbar-panel .navbar-panel-preview p {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 1024px) and (min-width: 640px) {
    .header nav.navbar .navbar-panel .navbar-panel-preview p {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
          .header nav.navbar .navbar-panel .navbar-panel-preview img {
            width: 100%; }
        .header nav.navbar.is-stuck .navbar-header {
          height: 72px; }
          .header nav.navbar.is-stuck .navbar-header .navbar-brand {
            display: flex;
            top: 0;
            max-height: 65%; }
          .header nav.navbar.is-stuck .navbar-header .property-navbar-brand {
            max-height: 100%; }
          .header nav.navbar.is-stuck .navbar-header .navbar-action {
            display: none; }
        .header nav.navbar.is-stuck ul.utility-nav {
          opacity: 0; }
          .header nav.navbar.is-stuck ul.utility-nav li {
            display: none; }
        .header nav.navbar.is-stuck .navbar-action {
          height: 100%;
          margin: 0; }
          .header nav.navbar.is-stuck .navbar-action > .btn {
            height: 100%;
            margin: 0;
            padding: 0; }
        .header nav.navbar.property-navbar .navbar-header .navbar-brand {
          max-width: 7.5em;
          max-height: 80%; }
        .header nav.navbar.property-navbar .navbar-header, .header nav.navbar.property-navbar .property-navbar-nav {
          margin-top: 1.5em; }
        .header nav.navbar.property-navbar.is-stuck .navbar-header, .header nav.navbar.property-navbar.is-stuck .property-navbar-nav {
          margin-top: 0; }
        .header nav.navbar.property-navbar.is-stuck .navbar-brand {
          max-height: 100%;
          padding: 0.125em; } }
  @media not all and (hover: hover) {
    .header .navbar-panel-preview {
      display: none !important; } }

.search-toggle-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 22vw;
  background-color: #ffffff;
  border-bottom: 1px solid #e1e1e1;
  padding: 1em;
  z-index: 1001; }
  .search-toggle-container .navbar-action {
    flex-grow: 1;
    margin: 0 0.5em !important; }
  .search-toggle-container .btn-search-toggle {
    position: relative;
    margin: 0;
    padding: 1em;
    border: 0; }
    .search-toggle-container .btn-search-toggle .icon-svg {
      width: 1.75em;
      height: 1.75em; }
      .search-toggle-container .btn-search-toggle .icon-svg svg {
        fill: #2b2b2b; }
    .search-toggle-container .btn-search-toggle:not(.collapsed) {
      display: none; }
      .search-toggle-container .btn-search-toggle:not(.collapsed) + .navbar-action {
        display: none; }
  .search-toggle-container #search-collapse {
    transition: none;
    flex-grow: 1;
    white-space: nowrap;
    z-index: 1; }
    .search-toggle-container #search-collapse.show {
      display: flex;
      width: 100%;
      align-items: center; }
      .search-toggle-container #search-collapse.show .search {
        display: flex; }
        .search-toggle-container #search-collapse.show .search form {
          display: flex;
          align-items: center; }
        .search-toggle-container #search-collapse.show .search .form-actions {
          margin: 0 !important;
          position: relative;
          top: auto;
          left: auto;
          transform: none; }
          .search-toggle-container #search-collapse.show .search .form-actions button {
            margin: 0.75em;
            padding: 1em; }
        .search-toggle-container #search-collapse.show .search .search-results, .search-toggle-container #search-collapse.show .search .faq .faq-search, .faq .search-toggle-container #search-collapse.show .search .faq-search {
          margin: 1em; }
          .search-toggle-container #search-collapse.show .search .search-results .dropdown-item, .search-toggle-container #search-collapse.show .search .faq .faq-search .dropdown-item, .faq .search-toggle-container #search-collapse.show .search .faq-search .dropdown-item {
            white-space: normal !important; }
  .search-toggle-container .search {
    position: static;
    flex-grow: 1; }
    .search-toggle-container .search .form-group {
      flex-grow: 1;
      margin: 0 0.5em 0 0 !important; }
    .search-toggle-container .search .search-input {
      margin: 0;
      padding: 1em; }
  .search-toggle-container .search-backdrop {
    position: absolute !important;
    top: 100% !important;
    min-height: 100vh; }
  @media (min-width: 640px) {
    .search-toggle-container {
      height: 10em;
      padding: 1em 1.5em; }
      .search-toggle-container .navbar-action {
        margin: 0 1em !important; }
      .search-toggle-container .btn-search-toggle .icon-svg {
        width: 2.5em;
        height: 2.5em; }
      .search-toggle-container .search button {
        padding: 1.25em !important; } }
  @media (min-width: 1024px) {
    .search-toggle-container {
      display: none; } }

.hotel-selector {
  position: relative;
  margin-bottom: calc((30 / 640vw ) * 100); }
  .hotel-selector .icon-svg {
    display: inline-block;
    width: 1em;
    height: 1em; }
    .hotel-selector .icon-svg.icon-badge {
      display: block;
      width: calc((158 / 640vw ) * 100);
      height: calc((158 / 640vw ) * 100);
      margin: 0 auto 1em;
      background-color: #ffffff;
      border-radius: 100%; }
  .hotel-selector .form-group {
    display: inline-block;
    margin: calc((64 / 640vw ) * 100) 0; }
    .hotel-selector .form-group label {
      display: inline-block;
      vertical-align: -1px;
      font-size: calc((32 / 640vw ) * 100);
      font-weight: 500; }
    .hotel-selector .form-group .dropdown-toggle {
      position: relative;
      display: block;
      width: calc((360 / 640vw ) * 100);
      margin: 0 auto 1em;
      padding: 0;
      box-shadow: none;
      font-size: calc((32 / 640vw ) * 100);
      font-weight: 300;
      border-bottom: calc((2 / 640vw ) * 100) solid transparent;
      text-align: center; }
      .hotel-selector .form-group .dropdown-toggle .icon-svg {
        position: absolute;
        top: 0.5em;
        right: 0;
        width: 0.75em;
        height: 0.75em;
        transform: rotate(180deg);
        transition: transform 0.3s; }
      .hotel-selector .form-group .dropdown-toggle.collapsed {
        border-bottom: calc((2 / 640vw ) * 100) solid #f7f7f7; }
        .hotel-selector .form-group .dropdown-toggle.collapsed .icon-svg {
          transform: rotate(0deg); }
      .hotel-selector .form-group .dropdown-toggle:after {
        display: none; }
    .hotel-selector .form-group .btn {
      background-color: #b8146a; }
      .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((30 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        margin-bottom: 0.5em;
        display: inline-block;
        white-space: nowrap;
        min-width: calc((180 / 640vw ) * 100);
        margin-bottom: 1rem;
        padding: 5px;
        border-width: 2px;
        background-color: #007697;
        color: #ffffff;
        line-height: 1.25em;
        text-transform: lowercase;
        cursor: default;
        background-color: #c6c6c6;
        color: #000000;
        opacity: 1;
        border-color: transparent; }
        @media (min-width: 640px) {
          .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 32px;
            letter-spacing: 0.5px; } }
        @media (min-width: 768px) {
          .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
        @supports not (-ms-high-contrast: none) {
          .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
            padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
        .hotel-selector .form-group .btn.disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn.disabled, .hotel-selector .form-group .btn:disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"].btn-sm, .hotel-selector .form-group .btn-group-sm > .btn[aria-disabled="true"] {
          display: block;
          font-weight: 500;
          text-transform: none;
          font-size: calc((26 / 640vw ) * 100);
          line-height: calc((32 / 640vw ) * 100);
          letter-spacing: calc((0.55 / 640vw ) * 100);
          margin-bottom: 0.5em;
          display: inline-block;
          padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
          line-height: 1.25em;
          text-transform: lowercase; }
          @media (min-width: 640px) {
            .hotel-selector .form-group .btn.disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn.disabled, .hotel-selector .form-group .btn:disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"].btn-sm, .hotel-selector .form-group .btn-group-sm > .btn[aria-disabled="true"] {
              font-size: 26px;
              font-size: 1.625rem;
              line-height: 32px;
              letter-spacing: 0.55px; } }
          @media (min-width: 768px) {
            .hotel-selector .form-group .btn.disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn.disabled, .hotel-selector .form-group .btn:disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"].btn-sm, .hotel-selector .form-group .btn-group-sm > .btn[aria-disabled="true"] {
              font-size: calc((12 / 640vw ) * 100);
              line-height: calc((24 / 640vw ) * 100);
              letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .hotel-selector .form-group .btn.disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn.disabled, .hotel-selector .form-group .btn:disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"].btn-sm, .hotel-selector .form-group .btn-group-sm > .btn[aria-disabled="true"] {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
        .hotel-selector .form-group .btn.disabled:hover, .hotel-selector .form-group .btn.disabled:focus, .hotel-selector .form-group .btn:disabled:hover, .hotel-selector .form-group .btn:disabled:focus, .hotel-selector .form-group .btn[aria-disabled="true"]:hover, .hotel-selector .form-group .btn[aria-disabled="true"]:focus {
          color: #ffffff;
          background-color: #031b21; }
        .hotel-selector .form-group .btn.disabled .icon-svg, .hotel-selector .form-group .btn:disabled .icon-svg, .hotel-selector .form-group .btn[aria-disabled="true"] .icon-svg {
          fill: #ffffff; }
        .hotel-selector .form-group .btn.disabled.btn-outline:focus, .hotel-selector .form-group .btn:disabled.btn-outline:focus, .hotel-selector .form-group .btn[aria-disabled="true"].btn-outline:focus {
          background-color: #031b21;
          color: #ffffff; }
        .hotel-selector .form-group .btn.disabled.btn-outline.btn-primary:focus, .hotel-selector .form-group .btn:disabled.btn-outline.btn-primary:focus, .hotel-selector .form-group .btn[aria-disabled="true"].btn-outline.btn-primary:focus {
          background-color: #b8146a;
          color: #ffffff; }
        .hotel-selector .form-group .btn.disabled.btn-secondary, .hotel-selector .form-group .btn:disabled.btn-secondary, .hotel-selector .form-group .btn[aria-disabled="true"].btn-secondary {
          border-color: transparent; }
        .hotel-selector .form-group .btn.disabled:hover, .hotel-selector .form-group .btn.disabled:focus, .hotel-selector .form-group .btn:disabled:hover, .hotel-selector .form-group .btn:disabled:focus, .hotel-selector .form-group .btn[aria-disabled="true"]:hover, .hotel-selector .form-group .btn[aria-disabled="true"]:focus {
          background-color: #c6c6c6; }
        .hotel-selector .form-group .btn.disabled.btn-outline, .hotel-selector .form-group .btn:disabled.btn-outline, .hotel-selector .form-group .btn[aria-disabled="true"].btn-outline {
          background-color: #ffffff;
          border-color: #c6c6c6;
          color: #949494; }
        @media (min-width: 640px) {
          .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
            min-width: 180px;
            margin-bottom: 0;
            padding: 22px 64px 25px;
            line-height: 1.25em; }
            .hotel-selector .form-group .btn.disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn.disabled, .hotel-selector .form-group .btn:disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"].btn-sm, .hotel-selector .form-group .btn-group-sm > .btn[aria-disabled="true"] {
              padding: 16px 64px 20px;
              line-height: 1.25em; } }
        @media (min-width: 768px) {
          .hotel-selector .form-group .btn.disabled, .hotel-selector .form-group .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"] {
            min-width: 100px;
            padding: 13px 32px 14px;
            line-height: 1.25em; }
            .hotel-selector .form-group .btn.disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn.disabled, .hotel-selector .form-group .btn:disabled.btn-sm, .hotel-selector .form-group .btn-group-sm > .btn:disabled, .hotel-selector .form-group .btn[aria-disabled="true"].btn-sm, .hotel-selector .form-group .btn-group-sm > .btn[aria-disabled="true"] {
              padding: 11px 32px 10px;
              line-height: 1.25em; } }
  .hotel-selector .dropdown-panel {
    position: relative;
    width: 100%;
    margin-top: calc((-75 / 640vw ) * 100);
    padding: calc((20 / 640vw ) * 100) calc((40 / 640vw ) * 100) calc((40 / 640vw ) * 100) calc((40 / 640vw ) * 100);
    background-color: #ffffff;
    border-width: 0;
    z-index: 101; }
    .hotel-selector .dropdown-panel .dropdown-panel-group {
      padding-top: calc((20 / 640vw ) * 100);
      padding-bottom: calc((15 / 640vw ) * 100);
      border-bottom: 1px solid #efefef; }
      .hotel-selector .dropdown-panel .dropdown-panel-group picture {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: calc((133 / 640vw ) * 100);
        height: calc((133 / 640vw ) * 100); }
        .hotel-selector .dropdown-panel .dropdown-panel-group picture img {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          width: initial;
          min-width: 100%;
          height: inherit;
          object-fit: cover;
          font-family: "object-fit: cover; object-position: center;"; }
        .hotel-selector .dropdown-panel .dropdown-panel-group picture img {
          opacity: 1; }
      .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options {
        padding-left: 1em; }
        .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options h2 {
          display: block;
          font-weight: 500;
          text-transform: none;
          font-size: calc((32 / 640vw ) * 100);
          line-height: calc((32 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100);
          margin-bottom: 0.5em;
          margin: 0.5em 0; }
          @media (min-width: 640px) {
            .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options h2 {
              font-size: 32px;
              font-size: 2rem;
              line-height: 32px;
              letter-spacing: 0.5px; } }
          @media (min-width: 768px) {
            .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options h2 {
              font-size: calc((16 / 640vw ) * 100);
              line-height: calc((24 / 640vw ) * 100);
              letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options h2 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
        .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul {
          margin: 0;
          padding: 0;
          list-style: none;
          font-weight: 300; }
          .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul li {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            padding: 5px 12px 5px 0;
            line-height: 1.2em; }
            .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul li:after {
              position: absolute;
              display: block;
              top: 10px;
              right: 0;
              width: 1px;
              height: 14px;
              content: '';
              background-color: #efefef; }
            .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul li:last-child {
              margin-right: 0;
              padding-right: 0; }
              .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul li:last-child:after {
                display: none; }
      .hotel-selector .dropdown-panel .dropdown-panel-group:last-child {
        border-bottom: 0; }
  @media (min-width: 640px) {
    .hotel-selector {
      margin-bottom: 30px; }
      .hotel-selector .icon-svg.icon-badge {
        width: 158px;
        height: 158px; }
      .hotel-selector .form-group {
        margin: 64px 0; }
        .hotel-selector .form-group label {
          font-size: 32px; }
        .hotel-selector .form-group .dropdown-toggle {
          min-width: 260px;
          font-size: 32px;
          border-width: 2px; }
          .hotel-selector .form-group .dropdown-toggle.collapsed {
            border-width: 2px; }
      .hotel-selector .dropdown-panel {
        margin-top: -75px;
        padding: 20px 30px 40px 30px; }
        .hotel-selector .dropdown-panel .dropdown-panel-group {
          padding-top: 20px;
          padding-bottom: 15px; } }
  @media (min-width: 768px) {
    .hotel-selector .icon-svg {
      display: inline-block; }
      .hotel-selector .icon-svg.icon-badge {
        display: inline-block;
        width: calc((96 / 1024vw ) * 100);
        height: calc((96 / 1024vw ) * 100);
        margin: 0 calc((32 / 1024vw ) * 100) 0 0; }
    .hotel-selector .form-group {
      min-width: 540px;
      margin: 0; }
      .hotel-selector .form-group label {
        font-size: 20px; }
      .hotel-selector .form-group .dropdown-toggle {
        display: inline-block;
        width: 100%;
        max-width: 260px;
        margin: 0 0.75em 0 0.75em;
        font-size: 20px;
        text-align: left; }
      .hotel-selector .form-group .btn {
        width: auto;
        margin-left: 15px; }
    .hotel-selector .dropdown-panel {
      margin: 0;
      padding: 0 40px 0;
      border-top: 1px solid #efefef; }
      .hotel-selector .dropdown-panel .container, .hotel-selector .dropdown-panel .form-factory {
        padding-top: 20px;
        padding-bottom: 20px; }
      .hotel-selector .dropdown-panel .dropdown-panel-group {
        border: 0; }
        .hotel-selector .dropdown-panel .dropdown-panel-group picture {
          width: calc((136 / 1024vw ) * 100);
          height: calc((136 / 1024vw ) * 100); }
        .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options {
          padding-left: 0.5em; }
          .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul li {
            display: block;
            margin: 4px 0; }
            .hotel-selector .dropdown-panel .dropdown-panel-group .dropdown-panel-group-options ul li:after {
              display: none; } }
  @media (min-width: 1024px) {
    .hotel-selector .icon-svg.icon-badge {
      display: inline-block;
      width: 96px;
      height: 96px;
      margin: 0 32px 0 0; }
    .hotel-selector .dropdown-panel .dropdown-panel-group picture {
      width: 136px;
      height: 136px; } }

.insider {
  position: relative;
  padding: 0;
  background-color: #ffffff;
  color: #ffffff;
  border-bottom: 1px solid #e1e1e1; }
  .insider .background {
    position: relative;
    width: 100%;
    height: calc((310 / 640vw ) * 100);
    margin: 0 0 calc((55 / 640vw ) * 100) 0; }
    .insider .background picture {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%; }
      .insider .background picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .insider .logo {
    width: calc((324 / 640vw ) * 100);
    margin-bottom: calc((60 / 640vw ) * 100); }
  .insider ul.list-inline {
    margin: 0 0 calc((48 / 640vw ) * 100) 0; }
    .insider ul.list-inline li {
      display: block;
      margin: 0 0 1.5em 0;
      font-size: calc((32 / 640vw ) * 100);
      font-weight: 500;
      line-height: 24px;
      color: #000000; }
      .insider ul.list-inline li .icon-svg {
        width: calc((70 / 640vw ) * 100);
        height: calc((70 / 640vw ) * 100);
        margin-right: 1em;
        vertical-align: middle; }
        .insider ul.list-inline li .icon-svg svg {
          fill: #000000; }
  .insider .form-group {
    margin: 0 calc((25 / 640vw ) * 100) calc((55 / 640vw ) * 100) calc((25 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .insider .background {
      height: 310px;
      margin-bottom: 55px;
      overflow: hidden; }
    .insider .logo {
      width: 324px;
      margin-bottom: 53px; }
    .insider ul.list-inline {
      margin: 0 0 48px 0; }
      .insider ul.list-inline li {
        font-size: 32px; }
        .insider ul.list-inline li .icon-svg {
          width: 70px;
          height: 70px; }
    .insider .form-group {
      margin: 0 25px 55px 25px; } }
  @media (min-width: 768px) {
    .insider {
      height: 310px;
      padding: 60px 0 30px 0;
      background-color: #007697;
      border: 0; }
      .insider .background {
        position: absolute;
        top: 0;
        height: 310px;
        overflow: hidden; }
        .insider .background img {
          position: absolute;
          min-width: 100%;
          max-width: none; }
      .insider .logo {
        width: 170px;
        margin-bottom: 28px; }
      .insider ul.list-inline {
        margin: 0 0 20px 0; }
        .insider ul.list-inline li {
          display: inline-block;
          margin: 0 1.25em 0.75em 0;
          font-size: 14px;
          color: #ffffff; }
          .insider ul.list-inline li .icon-svg {
            width: 24px;
            height: 24px;
            margin-right: 0.75em; }
            .insider ul.list-inline li .icon-svg svg {
              fill: #ffffff; }
      .insider .form-group {
        margin: 0 45px; } }

.island-slider {
  position: relative; }
  .island-slider .container-inset {
    padding-right: 0;
    padding-left: 0; }
  .island-slider .carousel-factory {
    width: 100%;
    margin: 0 auto; }
    .island-slider .carousel-factory .carousel {
      margin: 0 auto; }
  .island-slider article.layout-1 .content, .island-slider article.layout-2 .content {
    left: 0;
    margin-top: calc((500 / 640vw ) * 100);
    margin-left: 0; }
  .island-slider article.layout-1 .image-1, .island-slider article.layout-2 .image-1 {
    top: calc((84 / 640vw ) * 100);
    left: 0;
    width: calc((296 / 640vw ) * 100);
    height: calc((366 / 640vw ) * 100); }
  .island-slider article.layout-1 .image-2, .island-slider article.layout-2 .image-2 {
    top: calc((174 / 640vw ) * 100);
    left: calc((308 / 640vw ) * 100);
    width: calc((295 / 640vw ) * 100);
    height: calc((202 / 640vw ) * 100); }
  .island-slider article.layout-1 .accent, .island-slider article.layout-2 .accent {
    top: calc((304 / 640vw ) * 100);
    left: calc((348 / 640vw ) * 100);
    width: calc((140 / 640vw ) * 100);
    height: calc((140 / 640vw ) * 100);
    filter: drop-shadow(-12px 3px 14px rgba(0, 0, 0, 0.3)); }
  .island-slider article {
    position: relative;
    width: 100%;
    text-align: center;
    overflow: hidden; }
    .island-slider article figure {
      position: absolute;
      margin: 0; }
      .island-slider article figure a {
        display: block;
        height: 100%;
        margin-bottom: 0.5em; }
      .island-slider article figure picture {
        position: relative;
        overflow: hidden;
        display: block;
        height: 100%; }
        .island-slider article figure picture img {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          width: initial;
          min-width: 100%;
          height: inherit;
          object-fit: cover;
          font-family: "object-fit: cover; object-position: center;"; }
        .island-slider article figure picture img.lazyloading {
          opacity: 1; }
      .island-slider article figure figcaption {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((30 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        margin-bottom: 0.5em;
        text-align: left; }
        @media (min-width: 640px) {
          .island-slider article figure figcaption {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 32px;
            letter-spacing: 0.5px; } }
        @media (min-width: 768px) {
          .island-slider article figure figcaption {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .island-slider article figure figcaption {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .island-slider article .content {
      padding: 0 calc((20 / 640vw ) * 100); }
      .island-slider article .content h1 {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((36 / 640vw ) * 100);
        line-height: calc((48 / 640vw ) * 100);
        letter-spacing: calc((4 / 640vw ) * 100);
        margin-bottom: 0.5em;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        font-weight: 500; }
        @media (min-width: 640px) {
          .island-slider article .content h1 {
            font-size: 36px;
            font-size: 2.25rem;
            line-height: 48px;
            letter-spacing: 4px; } }
        @media (min-width: 768px) {
          .island-slider article .content h1 {
            font-size: calc((22 / 640vw ) * 100);
            line-height: calc((32 / 640vw ) * 100);
            letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .island-slider article .content h1 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }
      .island-slider article .content .category {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        margin-bottom: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        color: inherit; }
        @media (min-width: 640px) {
          .island-slider article .content .category {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .island-slider article .content .category {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .island-slider article .content .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .island-slider article .accent {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      pointer-events: none; }
      .island-slider article .accent img {
        max-width: 100%;
        height: auto; }
  @media (max-width: 767.98px) {
    .island-slider .slick-prev {
      left: calc((120 / 640vw ) * 100); }
    .island-slider .slick-next {
      right: calc((120 / 640vw ) * 100); } }
  @media (min-width: 768px) {
    .island-slider article.layout-1 .image-1, .island-slider article.layout-2 .image-1 {
      top: calc((34 / 1024vw ) * 100);
      left: calc((25 / 1024vw ) * 100);
      width: calc((307 / 1024vw ) * 100);
      height: calc((322 / 1024vw ) * 100); }
    .island-slider article.layout-1 .image-2, .island-slider article.layout-2 .image-2 {
      top: calc((34 / 1024vw ) * 100);
      left: calc((350 / 1024vw ) * 100);
      width: calc((307 / 1024vw ) * 100);
      height: calc((210 / 1024vw ) * 100); }
    .island-slider article.layout-1 .content, .island-slider article.layout-2 .content {
      top: calc((234 / 1024vw ) * 100);
      left: calc((350 / 1024vw ) * 100);
      width: calc((320 / 1024vw ) * 100);
      margin: 0;
      padding: 0;
      text-align: left; }
    .island-slider article.layout-1 .accent, .island-slider article.layout-2 .accent {
      top: 0;
      right: 0;
      left: auto;
      width: calc((110 / 1024vw ) * 100);
      height: calc((110 / 1024vw ) * 100); }
    .island-slider .carousel-factory {
      width: calc((755 / 1024vw ) * 100); }
      .island-slider .carousel-factory .carousel-tabs {
        margin: 30px 0 20px 0; }
    .island-slider article {
      min-height: 500px;
      margin: 0 auto; }
      .island-slider article .content {
        position: absolute;
        margin: 0; }
      .island-slider article.layout-1 .content {
        top: calc((260 / 1024vw ) * 100); }
      .island-slider article.layout-1 .image-2 {
        top: calc((34 / 1024vw ) * 100); }
      .island-slider article.layout-2 .content {
        top: calc((234 / 1024vw ) * 100); }
      .island-slider article.layout-2 .image-2 {
        top: 0; } }
  @media (min-width: 1024px) {
    .island-slider article.layout-1 .image-1, .island-slider article.layout-2 .image-1 {
      top: 34px;
      left: 25px;
      width: 307px;
      height: 322px; }
    .island-slider article.layout-1 .image-2, .island-slider article.layout-2 .image-2 {
      top: 34px;
      left: 350px;
      width: 307px;
      height: 210px; }
    .island-slider article.layout-1 .content, .island-slider article.layout-2 .content {
      top: 0;
      left: 350px;
      width: 320px; }
    .island-slider article.layout-1 .accent, .island-slider article.layout-2 .accent {
      top: 0;
      width: 110px;
      height: 110px; }
    .island-slider .carousel-factory {
      width: 755px; }
    .island-slider article.layout-1 .content {
      top: 260px; }
    .island-slider article.layout-2 .content {
      top: 234px; }
    .island-slider article.layout-2 .image-2 {
      top: 0; } }

.itinerary-detail-slider {
  margin-bottom: calc((40 / 640vw ) * 100); }
  .itinerary-detail-slider .infobox-2 {
    display: none !important; }
  .itinerary-detail-slider .headline-3 .icon-svg {
    width: calc((20 / 640vw ) * 100);
    height: calc((20 / 640vw ) * 100);
    margin-right: calc((6 / 640vw ) * 100);
    vertical-align: calc((3 / 640vw ) * 100); }
  .itinerary-detail-slider h3 {
    letter-spacing: 1px; }
  .itinerary-detail-slider .headline-5 {
    margin-top: calc((35 / 640vw ) * 100);
    padding-left: 6px; }
  .itinerary-detail-slider .btn,
  .itinerary-detail-slider .btn.btn-outline {
    padding-left: calc((30 / 640vw ) * 100);
    padding-right: calc((30 / 640vw ) * 100); }
  .itinerary-detail-slider .content {
    text-align: left; }
  @media (min-width: 768px) {
    .itinerary-detail-slider {
      margin-bottom: 0; }
      .itinerary-detail-slider .headline-3 .icon-svg {
        width: 12px;
        height: 12px;
        margin-right: 6px;
        vertical-align: 2px; }
      .itinerary-detail-slider .headline-5 {
        margin-top: 30px; }
      .itinerary-detail-slider .btn,
      .itinerary-detail-slider .btn.btn-outline {
        padding-left: 8px;
        padding-right: 8px; } }
  @media (min-width: 1024px) {
    .itinerary-detail-slider .btn,
    .itinerary-detail-slider .btn.btn-outline {
      padding-left: 26px;
      padding-right: 26px; } }

.itinerary-detail-map-slider figure {
  margin: 48px 0 5px;
  opacity: 0.3;
  transition: opacity 0.8s; }

.itinerary-detail-map-slider .content {
  text-align: left; }
  .itinerary-detail-map-slider .content .headline-6 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 8px solid #3cc1cc;
    opacity: 0;
    transition: opacity 0.8s;
    text-align: center; }
  .itinerary-detail-map-slider .content:before {
    position: absolute;
    top: 27px;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background: #e1e1e1; }
  .itinerary-detail-map-slider .content .body {
    margin-top: 4px;
    line-height: 20px; }

.itinerary-detail-map-slider .slick-current figure {
  opacity: 1; }

.itinerary-detail-map-slider .slick-current .headline-6 {
  display: block;
  opacity: 1; }

.itinerary-detail-map-slider .carousel-factory[data-arrows="sides"] .slick-next, .itinerary-detail-map-slider .carousel-factory[data-arrows="sides"] .slick-prev {
  margin-top: 1em; }

.itinerary-list .itinerary-day {
  position: relative;
  width: 100%;
  padding-right: 9.5px;
  padding-left: 9.5px;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  margin-bottom: 25px; }
  .itinerary-list .itinerary-day.half {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .itinerary-list .itinerary-day ol li {
    margin-bottom: 1em; }
  .itinerary-list .itinerary-day .headline-3 {
    margin-bottom: 20px;
    padding-bottom: 17px;
    border-bottom: 8px solid #3cc1cc; }
  .itinerary-list .itinerary-day figure {
    margin: 12px 0 0; }
  .itinerary-list .itinerary-day .headline-1 {
    margin-bottom: 0; }
  .itinerary-list .itinerary-day:not(.half) .headline-3 {
    display: none; }

@media (min-width: 768px) {
  .itinerary-list .itinerary-day {
    flex: 0 0 100%;
    max-width: 100%; }
    .itinerary-list .itinerary-day ol li {
      margin-bottom: 0; }
    .itinerary-list .itinerary-day.half {
      flex: 0 0 50%;
      max-width: 50%; }
    .itinerary-list .itinerary-day:not(.half) ol li {
      display: inline-block;
      width: 48%; } }

.itinerary-map-slider .slick-prev {
  left: calc((-20 / 640vw ) * 100); }

.itinerary-map-slider .slick-next {
  right: calc((-20 / 640vw ) * 100); }

.itinerary-map-slider .slick-prev,
.itinerary-map-slider .slick-next {
  top: calc((78 / 640vw ) * 100); }

.itinerary-map-slider .headline-5 {
  font-weight: 300; }

@media (min-width: 768px) {
  .itinerary-map-slider .headline-5 {
    font-weight: 500; } }

.itinerary-slide {
  text-align: center; }

.loader {
  z-index: 1050; }
  .loader svg {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    margin: auto;
    max-width: 100px;
    fill: #3cc1cc; }
    .loader svg .stop1 {
      stop-color: #3cc1cc; }
    .loader svg .stop2 {
      stop-color: #ffffff; }
    .loader svg.v-fixed {
      position: fixed; }
  .loader .loader-text {
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center; }

.loader-bg {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2b2b2b;
  opacity: 0.4;
  z-index: 1049; }

[data-loader] {
  display: none; }

#tab-map-view .fee-disclaimer {
  text-align: center;
  font-weight: 300;
  margin-bottom: 15px; }

.map-view .map {
  width: 100%;
  height: 400px; }

.map-view .infobox-0 {
  display: none;
  background-color: #ffffff;
  padding: 1.5em 2em;
  white-space: nowrap; }
  .map-view .infobox-0 .headline-7 {
    line-height: 1.25em; }

.map-view .infobox-1 {
  display: none;
  width: 300px;
  background-color: #ffffff;
  padding: 0;
  text-align: left;
  z-index: 1050; }
  .map-view .infobox-1 .close {
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 1; }
    .map-view .infobox-1 .close .icon-svg {
      width: 12px;
      height: 12px; }
    .map-view .infobox-1 .close svg {
      fill: #2b2b2b; }
  .map-view .infobox-1 .row {
    height: 100%;
    margin: 0; }
    .map-view .infobox-1 .row .col {
      padding: 0; }
  .map-view .infobox-1 .content {
    padding: 22px 20px 30px 20px;
    text-align: center; }
    .map-view .infobox-1 .content .headline {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((36 / 640vw ) * 100);
      line-height: calc((37 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.55em;
      display: inline-block;
      margin: 0;
      line-height: 1.25em; }
      @media (min-width: 640px) {
        .map-view .infobox-1 .content .headline {
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 37px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .map-view .infobox-1 .content .headline {
          font-size: calc((20 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .map-view .infobox-1 .content .headline {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .map-view .infobox-1 .content p {
      margin: 5px 0 0 0; }
  .map-view .infobox-1 picture {
    /*			@include image-fit();*/
    display: block;
    width: 100%;
    height: 100%;
    /*			padding-bottom: 65%;*/
    /*			@media screen and (min-width: 0\0) { // ie11
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
					font-family: inherit;
				}
			}
			@supports (-ms-ime-align: auto) { // edge
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
					font-family: inherit;
				}
			}*/ }
    .map-view .infobox-1 picture img {
      width: 100%; }

.map-view .infobox-2 {
  display: none;
  width: 300px;
  background-color: #ffffff;
  padding: 0;
  text-align: left;
  z-index: 1050; }
  .map-view .infobox-2 .row {
    height: 100%;
    margin-right: 0; }
  .map-view .infobox-2 .close {
    width: 40px;
    height: 40px;
    opacity: 1; }
    .map-view .infobox-2 .close .icon-svg {
      width: 12px;
      height: 12px; }
    .map-view .infobox-2 .close svg {
      fill: #2b2b2b; }
  .map-view .infobox-2 .content {
    padding: 20px 20px 20px 0;
    text-align: left; }
    .map-view .infobox-2 .content a {
      text-decoration: underline; }
    .map-view .infobox-2 .content .headline {
      margin: 0 0 0.5em 0;
      line-height: 1.25em; }
    .map-view .infobox-2 .content p {
      margin: 0; }
  .map-view .infobox-2 picture {
    /*			@include image-fit();*/
    display: inline-block;
    width: 100%;
    height: 100%;
    /*			padding-bottom: 100%;*/
    /*			@media screen and (min-width: 0\0) { // ie11
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}
			@supports (-ms-ime-align: auto) { // edge
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}*/ }
    .map-view .infobox-2 picture img {
      width: 100%; }

.map-view .infobox-3 {
  display: none;
  width: 420px;
  background-color: transparent;
  padding: 0;
  text-align: left;
  z-index: 1050; }
  .map-view .infobox-3 .content-box {
    padding: 24px;
    background-color: #ffffff;
    width: 300px;
    margin-left: 60px; }
  .map-view .infobox-3 .row {
    height: 100%;
    margin-right: 0; }
  .map-view .infobox-3 .close {
    width: 40px;
    height: 40px;
    opacity: 1;
    margin: 0 60px 0 0; }
    .map-view .infobox-3 .close .icon-svg {
      width: 12px;
      height: 12px; }
    .map-view .infobox-3 .close svg {
      fill: #2b2b2b; }
  .map-view .infobox-3 .content {
    text-align: left; }
    .map-view .infobox-3 .content .headline {
      margin: 0 0 0.5em 0;
      line-height: 1em;
      letter-spacing: 2px; }
      .map-view .infobox-3 .content .headline a {
        text-decoration: none; }
    .map-view .infobox-3 .content .headline-5 {
      margin: 10px 0 0; }
  .map-view .infobox-3 .pinButton {
    background-color: #ffffff;
    position: absolute;
    display: block;
    border-radius: 100%;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    padding: 0;
    border: 1px solid #c8c8c8;
    top: 2.5vw; }
    .map-view .infobox-3 .pinButton svg {
      fill: black; }
    .map-view .infobox-3 .pinButton:hover svg, .map-view .infobox-3 .pinButton:focus svg {
      fill: #007697; }
  .map-view .infobox-3 .prevPin {
    left: -0.1vw;
    margin-left: 2px; }
  .map-view .infobox-3 picture {
    /*			@include image-fit();*/
    display: inline-block;
    width: 100%;
    height: 100%;
    /*			padding-bottom: 100%;*/
    /*			@media screen and (min-width: 0\0) { // ie11
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}
			@supports (-ms-ime-align: auto) { // edge
				position: relative;
				padding-bottom: 0;

				img {
					position: relative;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
					transform: none;
				}
			}*/ }
    .map-view .infobox-3 picture img {
      width: 100%; }

.map-view .infobox-overlay {
  position: fixed;
  display: none;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: #ffffff;
  z-index: 1050; }
  .map-view .infobox-overlay picture {
    display: block;
    width: 100%;
    height: 100%; }
    .map-view .infobox-overlay picture img {
      width: 100%; }
  .map-view .infobox-overlay .status {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0.5em 3em;
    text-align: center;
    background-color: #f7f7f7; }
  .map-view .infobox-overlay .content {
    position: relative;
    height: 100%;
    padding: 1em 0 calc((84 / 640vw ) * 100) 0; }
    .map-view .infobox-overlay .content .headline {
      margin: 0; }
    .map-view .infobox-overlay .content .trip-advisor {
      position: absolute;
      bottom: 0.5em; }
  .map-view .infobox-overlay .close {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    opacity: 1;
    width: calc((40 / 640vw ) * 100);
    height: calc((40 / 640vw ) * 100);
    margin: 0 0.5em;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #7c7c7c;
    transform: translateY(-50%); }
    .map-view .infobox-overlay .close .icon-svg {
      width: calc((16 / 640vw ) * 100);
      height: calc((16 / 640vw ) * 100); }
      .map-view .infobox-overlay .close .icon-svg svg {
        fill: #2b2b2b; }
  .map-view .infobox-overlay footer {
    position: relative;
    background-color: #f7f7f7; }
    .map-view .infobox-overlay footer .price {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((36 / 640vw ) * 100);
      line-height: calc((37 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.55em;
      display: inline-flex;
      height: 100%;
      margin: 0 0.5em 0 0;
      align-items: center; }
      @media (min-width: 640px) {
        .map-view .infobox-overlay footer .price {
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 37px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .map-view .infobox-overlay footer .price {
          font-size: calc((20 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .map-view .infobox-overlay footer .price {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .map-view .infobox-overlay footer .btn {
      margin: 0 auto; }
    .map-view .infobox-overlay footer .avail-closed-msg {
      padding: 5px 10px;
      font-size: 14px;
      background-color: #c6c6c6;
      text-align: center;
      margin-top: 2.5rem; }
    .map-view .infobox-overlay footer .avail-msg {
      font-weight: 500;
      padding: 5px 10px;
      font-size: 14px;
      background-color: #c6c6c6;
      text-align: center;
      margin-top: 2.5rem; }
    .map-view .infobox-overlay footer .avail-closed-msg {
      padding: 5px 10px;
      font-size: 14px;
      background-color: #c6c6c6;
      text-align: center;
      margin-top: 2.5rem; }

.map-view .map .infobox-0, .map-view .map .infobox-1, .map-view .map .infobox-2, .map-view .map .infobox-3, .map-view .map .infobox-overlay {
  display: inline-block; }

@media (max-width: 767.98px) {
  .map-view .infobox-overlay {
    border: 0; } }

@media (min-width: 768px) {
  .map-view .infobox-overlay {
    position: absolute;
    min-height: 200px;
    margin: 45px 60px; }
    .map-view .infobox-overlay .status {
      position: absolute;
      width: 100%;
      padding: 0.5em 1em;
      text-align: left;
      background-color: #f7f7f7;
      transform: translateY(-100%); }
    .map-view .infobox-overlay .badge-descriptive {
      display: table; }
    .map-view .infobox-overlay .content {
      padding: 0; }
    .map-view .infobox-overlay .close {
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      opacity: 1;
      width: 40px;
      height: 40px;
      margin: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      transform: none; }
      .map-view .infobox-overlay .close .icon-svg {
        width: 12px;
        height: 12px; }
        .map-view .infobox-overlay .close .icon-svg svg {
          fill: #000000; }
    .map-view .infobox-overlay footer {
      position: relative;
      border-left: 1px solid #e1e1e1;
      padding-bottom: 120px;
      background-color: transparent; }
      .map-view .infobox-overlay footer .price {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((36 / 640vw ) * 100);
        line-height: calc((37 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        margin-bottom: 0.55em;
        display: inline-block;
        margin: 0 0 1em 0;
        line-height: calc((68 / 640vw ) * 100); } }
      @media (min-width: 768px) and (min-width: 640px) {
        .map-view .infobox-overlay footer .price {
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 37px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) and (min-width: 768px) {
        .map-view .infobox-overlay footer .price {
          font-size: calc((20 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 768px) and (min-width: 640px) {
    .map-view .infobox-overlay footer .price {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

@media (min-width: 768px) {
      .map-view .infobox-overlay footer .trip-advisor {
        position: absolute;
        bottom: 54px; }
        .map-view .infobox-overlay footer .trip-advisor .headline-7 {
          line-height: 1.25em; }
      .map-view .infobox-overlay footer .btn {
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        width: calc(100% - 19px);
        margin: 0 auto; } }

@media (min-width: 1024px) {
  .map-view .infobox-overlay footer {
    padding-bottom: 120px; } }

.infoBox {
  overflow: hidden !important;
  max-width: 80vw; }
  .infoBox .content-box {
    max-width: calc(80vw - 120px); }

.maplessFooter {
  text-align: center; }

.micro-destination {
  margin: 0 calc((18 / 640vw ) * 100);
  width: 100%;
  max-width: calc((155 / 640vw ) * 100); }
  .micro-destination picture {
    position: relative;
    overflow: hidden;
    width: calc((170 / 640vw ) * 100);
    height: calc((170 / 640vw ) * 100);
    margin: 0 auto; }
    .micro-destination picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }
  .micro-destination figcaption {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((32 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    text-align: center; }
    @media (min-width: 640px) {
      .micro-destination figcaption {
        font-size: 32px;
        font-size: 2rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .micro-destination figcaption {
        font-size: calc((16 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .micro-destination figcaption {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  @media (min-width: 640px) {
    .micro-destination {
      margin: 0 18px;
      width: 100%;
      max-width: 180px; }
      .micro-destination picture {
        width: 170px;
        height: 170px; } }
  @media (min-width: 768px) {
    .micro-destination {
      margin: 0 10px;
      width: auto;
      max-width: none; }
      .micro-destination picture {
        width: 144px;
        height: 144px; } }

.mobile-accordion {
  padding-bottom: calc((15 / 640vw ) * 100); }
  .mobile-accordion a[data-toggle] {
    display: block;
    position: relative;
    padding-right: calc((50 / 640vw ) * 100); }
    .mobile-accordion a[data-toggle]:hover {
      color: #2b2b2b; }
    .mobile-accordion a[data-toggle].collapsed .icon-svg.hidden-md-up {
      transform: rotate(0) translateY(-50%); }
    .mobile-accordion a[data-toggle] .icon-svg.hidden-md-up {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
      right: calc((10 / 640vw ) * 100);
      width: calc((24 / 640vw ) * 100);
      height: calc((24 / 640vw ) * 100);
      transform: rotate(-180deg) translateY(50%);
      transition: transform 0.5s; }
  @media (min-width: 768px) {
    .mobile-accordion {
      padding-bottom: 0; }
      .mobile-accordion .collapse,
      .mobile-accordion .collapsing {
        display: block;
        position: relative; }
      .mobile-accordion a[data-toggle] {
        pointer-events: none; } }

.must-do-list {
  margin-bottom: 5em;
  z-index: 1; }
  .must-do-list .headline-2 {
    margin-bottom: 0;
    padding-bottom: 2.5rem;
    background: #ffffff; }
  @media (min-width: 768px) {
    .must-do-list {
      position: relative;
      overflow: hidden; }
      .must-do-list:before, .must-do-list:after {
        position: absolute;
        display: none;
        width: 140px;
        height: 8000px;
        content: '';
        background-color: #f7f7f7;
        transform: rotate(22deg);
        z-index: -1; }
      .must-do-list:after {
        transform: rotate(-22deg); } }
  @media (min-width: 768px) and (min-width: 768px) {
    .must-do-list:before, .must-do-list:after {
      display: block;
      top: -3960px;
      right: auto;
      left: 45.6%;
      width: calc((140 / 1024vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 1024px) {
    .must-do-list:before, .must-do-list:after {
      width: 140px; } }

.must-do-listing {
  position: relative;
  padding: 1em 0 5em 0; }
  .must-do-listing .number {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    margin: 0.25em 0 0 1.5em; }
  .must-do-listing figure {
    display: inline-block;
    margin-bottom: 2em; }
    .must-do-listing figure picture {
      position: relative;
      overflow: hidden;
      width: calc((300 / 640vw ) * 100);
      height: calc((300 / 640vw ) * 100); }
      .must-do-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .must-do-listing .content {
    vertical-align: top;
    padding: 0 calc((50 / 640vw ) * 100); }
  .must-do-listing footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .must-do-listing footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .must-do-listing footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .must-do-listing footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .must-do-listing footer a {
      text-decoration: underline; }
  @media (min-width: 640px) {
    .must-do-listing figure picture {
      width: 300px;
      height: 300px; } }
  @media (min-width: 768px) {
    .must-do-listing {
      padding: 1.25em calc((100 / 1024vw ) * 100);
      text-align: left; }
      .must-do-listing .number {
        margin-left: calc((130 / 1024vw ) * 100); }
      .must-do-listing figure {
        display: inline-block;
        margin: 0 0 0 calc((70 / 1024vw ) * 100); }
        .must-do-listing figure picture {
          width: calc((192 / 1024vw ) * 100);
          height: calc((192 / 1024vw ) * 100);
          padding-right: calc((15 / 1024vw ) * 100); }
      .must-do-listing .content {
        display: inline-block;
        width: 60%;
        margin: 0.25em 0 0 1.5em;
        padding: 0; } }
  @media (min-width: 1024px) {
    .must-do-listing {
      padding: 1.25em 100px 2.5em 100px; }
      .must-do-listing .number {
        margin-left: 130px; }
      .must-do-listing figure {
        margin-left: 70px; }
        .must-do-listing figure picture {
          width: 192px;
          height: 192px; } }

.nav-tabs {
  max-width: 560px;
  max-height: calc((98 / 640vw ) * 100);
  padding: calc((16 / 640vw ) * 100) 0;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  transition: max-height 0.5s;
  overflow: hidden;
  font-size: .875rem;
  line-height: 24px;
  letter-spacing: 0; }
  .nav-tabs.open {
    max-height: calc((310 / 640vw ) * 100);
    overflow: auto; }
    .nav-tabs.open .nav-item .icon-svg {
      transform: rotate(-180deg) translateY(50%); }
    .nav-tabs.open .nav-item:not(.nav-item-active) {
      display: block; }
  .nav-tabs.disabled {
    pointer-events: none; }
  .nav-tabs .nav-item {
    position: relative;
    width: 100%;
    margin-bottom: 0; }
    .nav-tabs .nav-item label {
      display: block;
      width: 80%;
      margin: auto; }
    .nav-tabs .nav-item .icon-svg {
      position: absolute;
      top: 50%;
      right: 15px;
      width: calc((24 / 640vw ) * 100);
      height: calc((24 / 640vw ) * 100);
      transform: rotate(0) translateY(-50%);
      transition: transform 0.5s;
      z-index: 2; }
    .nav-tabs .nav-item:not(.nav-item-active) .icon-svg {
      display: none; }
  .nav-tabs .nav-link {
    position: relative;
    display: inline-block;
    padding: calc((12 / 640vw ) * 100) 1em;
    border: 0;
    font-weight: 400;
    cursor: pointer; }
    .nav-tabs .nav-link.active {
      color: inherit; }
    .nav-tabs .nav-link span {
      font-weight: 300; }
  @media (max-width: 767.98px) {
    .nav-tabs {
      margin: 0 auto; }
      .nav-tabs .nav-item-active {
        order: -1; } }
  @media (min-width: 640px) {
    .nav-tabs {
      max-height: 98px;
      padding: 16px 0; }
      .nav-tabs .nav-link {
        padding: 12px 1em;
        width: 100%; } }
  @media (min-width: 768px) {
    .nav-tabs {
      max-width: none;
      padding: 0; }
      .nav-tabs .nav-item-active .nav-link {
        font-weight: 500; }
        .nav-tabs .nav-item-active .nav-link:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 6px;
          content: '';
          background: #3cc1cc; }
      .nav-tabs .nav-item {
        width: auto;
        flex: 1; }
        .nav-tabs .nav-item label {
          width: auto; }
      .nav-tabs .nav-link {
        display: block;
        padding: 12px 1em;
        font-weight: 300;
        width: 100%; } }

.navbar-toggler {
  position: absolute;
  top: auto;
  right: 0;
  width: calc((92 / 640vw ) * 100);
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border: 0;
  z-index: 2; }
  .navbar-toggler .navbar-toggler-icon {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 40%;
    height: calc((5 / 640vw ) * 100);
    background-image: none;
    background-color: #007697;
    transition: background 0s 0.1s; }
    .navbar-toggler .navbar-toggler-icon:before {
      top: calc((-12 / 640vw ) * 100);
      /* autoprefixer: off */
      transition-property: top, transform; }
    .navbar-toggler .navbar-toggler-icon:after {
      bottom: calc((-12 / 640vw ) * 100);
      /* autoprefixer: off */
      transition-property: bottom, transform; }
    .navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: calc((5 / 640vw ) * 100);
      transition-duration: 0.1s, 0.1s;
      transition-delay: 0.1s, 0s;
      background: #007697;
      content: ""; }
  .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background: transparent; }
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before {
      top: 0;
      transform: rotate(45deg); }
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
      bottom: 0;
      transform: rotate(-45deg); }
    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before, .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
      transition-delay: 0s, 0.1s; }
  .is-stuck .navbar-toggler {
    width: calc((92 / 640vw ) * 100);
    height: 100%;
    margin: 0; }
  @media (min-width: 640px) {
    .navbar-toggler .navbar-toggler-icon {
      width: 35%;
      height: 5px; }
      .navbar-toggler .navbar-toggler-icon:before {
        top: -12px; }
      .navbar-toggler .navbar-toggler-icon:after {
        bottom: -12px; }
      .navbar-toggler .navbar-toggler-icon:before, .navbar-toggler .navbar-toggler-icon:after {
        height: 5px; } }
  @media (min-width: 768px) {
    .navbar-toggler .navbar-toggler-icon {
      width: 30%; } }

.numbered-article ol.numbered {
  counter-reset: items;
  list-style: none; }
  .numbered-article ol.numbered > li {
    margin-bottom: 30px; }
    .numbered-article ol.numbered > li .number {
      display: block;
      counter-increment: items; }
      .numbered-article ol.numbered > li .number:before {
        float: right;
        font-size: calc((60 / 640vw ) * 100);
        line-height: 1;
        content: "0" counter(items); }
    .numbered-article ol.numbered > li:nth-child(n+10) .number:before {
      content: counter(items); }

.numbered-article h2 {
  margin: 70px 0 26px;
  font-size: calc((54 / 640vw ) * 100);
  text-align: center; }
  .numbered-article h2:first-of-type {
    margin-top: 30px; }

.numbered-article img {
  width: 100%; }

.numbered-article .content {
  margin-top: 15px; }

.numbered-article .title {
  font-weight: 500;
  font-size: calc((32 / 640vw ) * 100); }

.numbered-article p {
  font-size: calc((28 / 640vw ) * 100);
  font-weight: 300;
  line-height: 1.43; }

@media (min-width: 640px) {
  .numbered-article h2 {
    font-size: 3.375rem; }
  .numbered-article .title {
    font-size: 2rem; }
  .numbered-article p {
    font-size: 1.75rem; } }

@media (min-width: 768px) {
  .numbered-article h2 {
    font-size: 2.25rem; }
  .numbered-article .title {
    font-size: 1rem; }
  .numbered-article p {
    font-size: 0.875rem;
    line-height: 1.71; }
  .numbered-article img {
    max-width: 389px; }
  .numbered-article .content {
    margin: 0;
    padding-left: 28px; } }

.offer-detail .property-grid .card-property .content {
  padding-bottom: 100px; }

.offer-detail.accordion button[data-toggle="collapse"] {
  font-family: Roboto;
  font-weight: inherit;
  letter-spacing: inherit;
  color: inherit;
  width: 100%; }

.offer-detail-search {
  position: relative; }
  .offer-detail-search .form-actions {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%);
    right: calc((16 / 640vw ) * 100);
    pointer-events: none;
    background-color: white; }
    .offer-detail-search .form-actions .icon-svg {
      width: calc((34 / 640vw ) * 100);
      height: calc((34 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .offer-detail-search .form-actions {
      right: 4px; }
      .offer-detail-search .form-actions .icon-svg {
        width: 20px;
        height: 20px; } }

.offer-summary .offer-summary-content {
  border: 1px solid #e1e1e1;
  padding: 25px 50px; }
  @media (max-width: 767.98px) {
    .offer-summary .offer-summary-content {
      margin: 10px;
      padding: 25px 20px; } }
  .offer-summary .offer-summary-content .price-container {
    margin-top: -50px; }
    .offer-summary .offer-summary-content .price-container .price-area {
      background: white;
      padding: 0px 10px; }
      .offer-summary .offer-summary-content .price-container .price-area .original-price {
        font-size: calc((24 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-family: Oswald, sans-serif;
        font-weight: 500;
        font-size: 1.25rem;
        color: #757575;
        text-decoration: line-through; }
        @media (min-width: 640px) {
          .offer-summary .offer-summary-content .price-container .price-area .original-price {
            font-size: 24px;
            font-size: 1.5rem;
            line-height: 32px;
            letter-spacing: 0px; } }
      .offer-summary .offer-summary-content .price-container .price-area .offer-price {
        font-size: calc((40 / 640vw ) * 100);
        line-height: calc((38 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-family: Oswald, sans-serif;
        font-weight: 500;
        font-size: 2rem;
        text-decoration: none; }
        @media (min-width: 640px) {
          .offer-summary .offer-summary-content .price-container .price-area .offer-price {
            font-size: 40px;
            font-size: 2.5rem;
            line-height: 38px;
            letter-spacing: 0px; } }
      .offer-summary .offer-summary-content .price-container .price-area .price-text {
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((14 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        display: inline-block;
        width: 30px;
        margin-left: 4px;
        word-break: break-word;
        text-align: left; }
        @media (min-width: 640px) {
          .offer-summary .offer-summary-content .price-container .price-area .price-text {
            font-size: 12px;
            font-size: 0.75rem;
            line-height: 14px;
            letter-spacing: 0.5px; } }
      .offer-summary .offer-summary-content .price-container .price-area .offer-promo-text {
        letter-spacing: 1.67px; }
  .offer-summary .offer-summary-content .figure {
    margin-bottom: 0px; }

.offer-list {
  text-align: center; }
  @media (min-width: 768px) {
    .offer-list {
      margin-bottom: 5em; }
      .offer-list .carousel-factory .carousel {
        width: 100%;
        padding: 0 50px; } }

.offer-listing, .itinerary-slide, .property-map-slide {
  position: relative;
  width: 100%;
  padding-right: 9.5px;
  padding-left: 9.5px;
  width: calc((512 / 640vw ) * 100);
  text-align: left; }
  .offer-listing .badge-image, .itinerary-slide .badge-image, .property-map-slide .badge-image {
    margin: 0 4px; }
  .offer-listing figure, .itinerary-slide figure, .property-map-slide figure {
    padding: 4px 0 0; }
    .offer-listing figure picture, .itinerary-slide figure picture, .property-map-slide figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding-bottom: 66.666%; }
      .offer-listing figure picture img, .itinerary-slide figure picture img, .property-map-slide figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .offer-listing .btn-favorite, .itinerary-slide .btn-favorite, .property-map-slide .btn-favorite {
    right: 3px; }
  @media (min-width: 640px) {
    .offer-listing, .itinerary-slide, .property-map-slide {
      width: 512px; } }
  @media (min-width: 768px) {
    .offer-listing, .itinerary-slide, .property-map-slide {
      position: relative;
      width: 100%;
      padding-right: 9.5px;
      padding-left: 9.5px;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      margin: 0 0 2.5em 0; }
      .offer-listing .btn-favorite, .itinerary-slide .btn-favorite, .property-map-slide .btn-favorite {
        right: 7px; } }

.offer-property-search .fee-disclaimer {
  text-align: center;
  font-weight: 300; }
  @media (min-width: 768px) {
    .offer-property-search .fee-disclaimer {
      text-align: right; } }

@media (min-width: 768px) {
  .offer-property-search .js-property-load-container {
    justify-content: center; } }

.offer-property-search label {
  font-size: .875rem;
  font-weight: 500;
  margin-bottom: 0; }

.offer-property-search .filters [aria-disabled="true"],
.offer-property-search .filters [disabled] {
  color: #636c72;
  background-color: #eceeef; }

.offer-property-search .filters .js-search-resorts .form-actions {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
  right: calc((16 / 640vw ) * 100);
  pointer-events: none;
  background-color: white;
  top: 40%; }
  .offer-property-search .filters .js-search-resorts .form-actions .icon-svg {
    width: calc((34 / 640vw ) * 100);
    height: calc((34 / 640vw ) * 100); }

.offer-property-search .filters .js-search-resorts [disabled] + .form-actions {
  color: #636c72;
  background-color: #eceeef; }

@media (min-width: 768px) {
  .offer-property-search .filters .js-search-resorts .form-actions {
    right: 20px;
    top: 40%; }
    .offer-property-search .filters .js-search-resorts .form-actions .icon-svg {
      width: 20px;
      height: 20px; } }

.offer-property-search .filters option {
  font-weight: 500; }

.offer-property-search .filters .calendar-flyout {
  margin-bottom: 16px; }
  .offer-property-search .filters .calendar-flyout .enter-dates-results {
    border: 1px solid black;
    width: 100%;
    height: 13.75vw; }
    @media (min-width: 640px) {
      .offer-property-search .filters .calendar-flyout .enter-dates-results {
        height: 88px; } }
    @media (min-width: 768px) {
      .offer-property-search .filters .calendar-flyout .enter-dates-results {
        height: 48px; } }
    .offer-property-search .filters .calendar-flyout .enter-dates-results .enter-dates-result {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      margin: 0; }
      .offer-property-search .filters .calendar-flyout .enter-dates-results .enter-dates-result:focus {
        border-color: #0078d7;
        border-width: 2px;
        border-style: solid; }
      .offer-property-search .filters .calendar-flyout .enter-dates-results .enter-dates-result .cf-button {
        display: inline-block;
        width: 75%;
        text-align: left;
        padding-left: 18px; }
        .offer-property-search .filters .calendar-flyout .enter-dates-results .enter-dates-result .cf-button .result-dates {
          font-size: 4.375vw; }
          @media (min-width: 768px) {
            .offer-property-search .filters .calendar-flyout .enter-dates-results .enter-dates-result .cf-button .result-dates {
              font-size: .875rem; } }
      .offer-property-search .filters .calendar-flyout .enter-dates-results .enter-dates-result .cf-svg {
        display: inline-block;
        width: 20%;
        margin-right: 0px;
        text-align: right; }

.offer-property-search .filters div .js-select-amenities,
.offer-property-search .filters div .js-select-destinations,
.offer-property-search .filters div .js-select-rooms {
  background-color: #ffffff;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #636c72;
  line-height: 27px;
  height: 13.75vw;
  padding: 0; }
  .offer-property-search .filters div .js-select-amenities + div,
  .offer-property-search .filters div .js-select-destinations + div,
  .offer-property-search .filters div .js-select-rooms + div {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    box-shadow: 4px 4px 10px -4px rgba(0, 0, 0, 0.5); }
  @media (min-width: 640px) {
    .offer-property-search .filters div .js-select-amenities,
    .offer-property-search .filters div .js-select-destinations,
    .offer-property-search .filters div .js-select-rooms {
      height: 88px; } }
  @media (min-width: 768px) {
    .offer-property-search .filters div .js-select-amenities,
    .offer-property-search .filters div .js-select-destinations,
    .offer-property-search .filters div .js-select-rooms {
      height: 48px; } }
  .offer-property-search .filters div .js-select-amenities button,
  .offer-property-search .filters div .js-select-destinations button,
  .offer-property-search .filters div .js-select-rooms button {
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 4.375vw;
    color: black;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: .375rem 1.75rem .375rem .75rem; }
    @media (min-width: 640px) {
      .offer-property-search .filters div .js-select-amenities button,
      .offer-property-search .filters div .js-select-destinations button,
      .offer-property-search .filters div .js-select-rooms button {
        font-size: 1.75rem; } }
    @media (min-width: 768px) {
      .offer-property-search .filters div .js-select-amenities button,
      .offer-property-search .filters div .js-select-destinations button,
      .offer-property-search .filters div .js-select-rooms button {
        font-size: .875rem;
        padding: 0 1.25rem; } }

.offer-property-search .filters .card {
  margin-bottom: 16px; }
  @media (min-width: 768px) {
    .offer-property-search .filters .card {
      margin-bottom: 0px; } }
  .offer-property-search .filters .card .collapse,
  .offer-property-search .filters .card .collapsing {
    top: 100%;
    position: absolute;
    z-index: 900;
    background-color: #fff;
    width: 100%; }
  .offer-property-search .filters .card .card-header button .icon-svg {
    transform: rotate(0deg);
    right: 20px;
    height: 2.5vw;
    width: 2.5vw; }
    @media (min-width: 768px) {
      .offer-property-search .filters .card .card-header button .icon-svg {
        right: 14px;
        height: 12px;
        width: 12px; } }
  .offer-property-search .filters .card .card-header button.collapsed .icon-svg {
    transform: rotate(180deg); }
  .offer-property-search .filters .card .card-header .badge {
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-radius: 50%;
    margin: 0 2em; }
  .offer-property-search .filters .card .card-block {
    padding: 1em 1em 0em 1em;
    border: none; }
    .offer-property-search .filters .card .card-block .list-group {
      margin: 0; }
      @media (min-width: 768px) {
        .offer-property-search .filters .card .card-block .list-group {
          margin-bottom: 1em; } }
      .offer-property-search .filters .card .card-block .list-group .list-group-item {
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
        border-right: 0; }
        .offer-property-search .filters .card .card-block .list-group .list-group-item:first-child {
          border-top: 0; }
        @media (min-width: 768px) {
          .offer-property-search .filters .card .card-block .list-group .list-group-item:last-child {
            border-bottom: 0; } }
    .offer-property-search .filters .card .card-block .custom-control {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0; }
      .offer-property-search .filters .card .card-block .custom-control .logo {
        height: 2em; }
      .offer-property-search .filters .card .card-block .custom-control > .icon-svg {
        width: 2em;
        height: 2em;
        margin: 0 .5em 0 0;
        vertical-align: middle; }
      .offer-property-search .filters .card .card-block .custom-control .custom-control-indicator {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
        left: auto; }
      .offer-property-search .filters .card .card-block .custom-control .custom-control-description {
        display: inline-block;
        width: 65%;
        line-height: 1.25em;
        margin-top: 0.25em;
        vertical-align: middle; }
        .offer-property-search .filters .card .card-block .custom-control .custom-control-description small {
          line-height: inherit; }
  .offer-property-search .filters .card #filter-amenities {
    position: absolute; }
    .offer-property-search .filters .card #filter-amenities .collapsing .collapse {
      width: 400%;
      max-width: 950px; }
    .offer-property-search .filters .card #filter-amenities .card-footer {
      text-align: center;
      background-color: #f7f7f9;
      padding-bottom: 12px;
      padding-top: 12px !important;
      border-top: none; }
      @media (min-width: 768px) {
        .offer-property-search .filters .card #filter-amenities .card-footer {
          padding-top: 0px; } }

.offer-property-search .filters .js-rooms-card .card-footer {
  display: flex;
  align-items: center;
  flex-direction: column; }

.offer-property-search .filters .js-rooms-card .btn-transparent {
  padding: inherit !important;
  background-color: transparent; }

.offer-property-search .filters .js-rooms-card .btn {
  margin-left: 0; }

.offer-property-search .ops-select-sort-dropdown {
  color: #2b2b2b;
  font-weight: 400;
  margin-bottom: 0px;
  padding-left: 15px;
  font-size: 4.375vw; }
  @media (min-width: 640px) {
    .offer-property-search .ops-select-sort-dropdown {
      font-size: 1.75rem; } }
  .offer-property-search .ops-select-sort-dropdown option {
    font-family: Roboto, sans-serif;
    padding-left: 15px; }

.offer-property-search .form-control,
.offer-property-search .custom-select,
.offer-property-search .js-select-amenities,
.offer-property-search .js-select-destinations,
.offer-property-search .js-select-rooms {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eicon-arrow-down%3C/title%3E%3Cpath d='M0.133 9.067c0-0.533 0.267-1.067 0.667-1.467 0.8-0.8 2.133-0.8 2.933 0l12.267 12.4 12.267-12.4c0.8-0.8 2.133-0.8 2.933 0s0.8 2.133 0 2.933l-13.733 13.867c-0.4 0.4-0.933 0.667-1.467 0.667s-1.067-0.267-1.467-0.667l-13.733-13.867c-0.4-0.4-0.667-0.933-0.667-1.467z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center right 10px; }

.offer-property-search .js-select-amenities,
.offer-property-search .js-select-destinations,
.offer-property-search .js-select-rooms {
  border: 1px solid #000; }

.offer-property-search .js-amenities-card,
.offer-property-search .js-destinations-card,
.offer-property-search .js-rooms-card {
  border: none; }

.offer-property-search .sort-btn-row .sort-btn-col label {
  font-weight: 400; }

.offer-property-search .sort-btn-row .sort-btn-col .btn .icon-svg {
  color: #616161;
  fill: #616161;
  margin-left: 10px; }

.offer-property-search .btn-transparent {
  background-color: transparent;
  color: #2b2b2b;
  min-width: 0px;
  margin: 0px;
  padding: 0px 0px 0px 5px; }

.offer-property-search .card-header {
  padding: 0 1.25rem; }

.offer-property-search .property-grid .property-grid-item {
  text-align: center; }
  @media (min-width: 768px) {
    .offer-property-search .property-grid .property-grid-item {
      display: inline-block;
      width: 60%;
      margin: 4.39453vw 1.17188vw 0; } }
  @media (min-width: 1024px) {
    .offer-property-search .property-grid .property-grid-item {
      display: inline-block;
      width: 48%;
      margin: 10px auto 5px auto; } }
  .offer-property-search .property-grid .property-grid-item .property-box {
    padding: 15px;
    display: block;
    height: auto; }
    @media (min-width: 768px) {
      .offer-property-search .property-grid .property-grid-item .property-box {
        display: flex; } }
  .offer-property-search .property-grid .property-grid-item .picture-box {
    overflow: hidden; }
    @media (min-width: 768px) {
      .offer-property-search .property-grid .property-grid-item .picture-box {
        width: 225px;
        height: 180px; } }
  .offer-property-search .property-grid .property-grid-item .picture-box picture {
    width: 100%;
    height: auto; }
    .offer-property-search .property-grid .property-grid-item .picture-box picture img {
      top: auto;
      left: auto;
      position: relative;
      transform: none; }
      @media (min-width: 768px) {
        .offer-property-search .property-grid .property-grid-item .picture-box picture img {
          width: 225px;
          height: 180px; } }

.offer-property-search .property-grid .property-grid-item .property-box .property-info {
  bottom: 0px;
  width: 100%;
  margin: 16px 0 0 0;
  position: relative;
  text-align: center; }
  @media (min-width: 768px) {
    .offer-property-search .property-grid .property-grid-item .property-box .property-info {
      margin: 8px 0px 8px 16px;
      height: auto;
      text-align: left; } }
  .offer-property-search .property-grid .property-grid-item .property-box .property-info .neighborhood {
    font-weight: 300; }
  .offer-property-search .property-grid .property-grid-item .property-box .property-info .category {
    text-align: center;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .offer-property-search .property-grid .property-grid-item .property-box .property-info .category {
        text-align: left; } }
  .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area {
    padding: 0px;
    margin-bottom: 20px;
    margin-top: 10px; }
    @media (min-width: 768px) {
      .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area {
        position: absolute;
        margin-bottom: 0;
        bottom: 0;
        line-height: normal; } }
    .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-container {
      text-align: center; }
      @media (min-width: 768px) {
        .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-container {
          text-align: left;
          display: inline-block; } }
    .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-load {
      height: 40px;
      width: 40px; }
      .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-load .loading {
        font-size: 4rem;
        line-height: 0px;
        letter-spacing: -7px; }
      .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-load .loading span {
        animation-name: blink;
        animation-duration: 0.9s;
        animation-iteration-count: infinite;
        animation-fill-mode: both; }
      .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-load .loading span:nth-child(2) {
        animation-delay: .1s; }
      .offer-property-search .property-grid .property-grid-item .property-box .property-info .price-area .price-load .loading span:nth-child(3) {
        animation-delay: .2s; }

.zoom-enabled .property-grid .property-grid-item .grid-view .property-box {
  padding-bottom: 10px; }

.other-activities-listing {
  border: 0; }

.other-rooms-listing {
  margin-bottom: 0;
  padding: 0 calc((20 / 640vw ) * 100);
  text-align: center; }
  .other-rooms-listing .content {
    padding-left: 5px;
    text-align: left; }
  .other-rooms-listing ul.other-room-list {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    list-style: none;
    padding-left: 0; }
    @media (min-width: 640px) {
      .other-rooms-listing ul.other-room-list {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .other-rooms-listing ul.other-room-list {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .other-rooms-listing ul.other-room-list {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .other-rooms-listing ul.other-room-list li {
      display: inline-block; }
    .other-rooms-listing ul.other-room-list a {
      display: inline-block;
      margin: 0 10px; }
  @media (min-width: 768px) {
    .other-rooms-listing {
      padding: 0;
      text-align: left; }
      .other-rooms-listing figure {
        margin-bottom: 3px; }
      .other-rooms-listing .headline-5 {
        margin-bottom: 0; }
      .other-rooms-listing .content {
        padding-left: 0; }
      .other-rooms-listing footer a {
        margin-left: 0; } }

.our-favorites, .user-travel-log-list {
  /*
	.container {
		border-bottom: 13px solid $color-aqua;
	}
	*/ }
  .our-favorites .slick-track, .user-travel-log-list .slick-track {
    display: flex;
    flex-flow: row; }
  .our-favorites article, .user-travel-log-list article {
    position: relative;
    width: 100%;
    padding-right: 9.5px;
    padding-left: 9.5px;
    width: calc((512 / 640vw ) * 100);
    max-width: calc((512 / 640vw ) * 100);
    height: auto;
    margin: calc((110 / 640vw ) * 100) calc((20 / 640vw ) * 100) 0 0; }
  @media (min-width: 640px) {
    .our-favorites article, .user-travel-log-list article {
      margin-top: 110px;
      width: 512px; } }
  @media (min-width: 768px) {
    .our-favorites .slick-track, .user-travel-log-list .slick-track {
      display: block; }
    .our-favorites article, .user-travel-log-list article {
      position: relative;
      width: 100%;
      padding-right: 9.5px;
      padding-left: 9.5px;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      margin: 80px 0 0; }
      .our-favorites article .btn-overlay, .user-travel-log-list article .btn-overlay {
        margin: 0 0.25em; } }

.our-favorites-listing {
  padding: 0 35px 47px;
  height: 100%;
  border: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column; }
  .our-favorites-listing figure {
    margin-top: calc((-90 / 640vw ) * 100); }
    .our-favorites-listing figure picture {
      position: relative;
      overflow: hidden;
      width: calc((180 / 640vw ) * 100);
      height: calc((180 / 640vw ) * 100);
      margin: 0 auto; }
      .our-favorites-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .our-favorites-listing .headline-5 {
    margin-top: calc((25 / 640vw ) * 100); }
  .our-favorites-listing a {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin: auto 0 0;
    text-decoration: underline; }
    @media (min-width: 640px) {
      .our-favorites-listing a {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .our-favorites-listing a {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .our-favorites-listing a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .our-favorites-listing a:hover {
      color: #007697; }
  @media (min-width: 768px) {
    .our-favorites-listing figure {
      margin-top: calc((-60 / 1024vw ) * 100); }
      .our-favorites-listing figure picture {
        width: calc((120 / 1024vw ) * 100);
        height: calc((120 / 1024vw ) * 100); }
    .our-favorites-listing .headline-5 {
      margin-top: 0; } }
  @media (min-width: 768px) {
    .our-favorites-listing figure {
      margin-top: -60px; }
      .our-favorites-listing figure picture {
        width: 120px;
        height: 120px; } }

.package-list {
  margin-bottom: 5em;
  text-align: center; }

.package-listing {
  width: calc((512 / 640vw ) * 100);
  margin: 0 0 2.5em 0;
  text-align: left; }
  .package-listing figure picture {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-bottom: 105%; }
    .package-listing figure picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }
    .package-listing figure picture img {
      border-bottom: calc((10 / 640vw ) * 100) solid #3cc1cc; }
  @media (min-width: 640px) {
    .package-listing {
      width: 512px; }
      .package-listing figure picture img {
        border-width: 10px; } }
  @media (min-width: 768px) {
    .package-listing {
      width: auto; }
      .package-listing .btn-overlay {
        margin: 0 0.25em; }
      .package-listing figure picture img {
        border-width: 6px; } }

.page-helpful {
  text-align: center; }
  .page-helpful .container, .page-helpful .form-factory {
    padding: calc((42 / 640vw ) * 100) 0 calc((40 / 640vw ) * 100) 0;
    background-color: #f7f7f7; }
  .page-helpful .btn-tooltip {
    display: inline-block;
    margin-right: calc((14 / 640vw ) * 100);
    padding: 0;
    vertical-align: text-bottom; }
    .page-helpful .btn-tooltip .icon-svg {
      width: calc((26 / 640vw ) * 100);
      height: calc((26 / 640vw ) * 100); }
    .page-helpful .btn-tooltip:hover .icon-svg {
      fill: #007697; }
  .page-helpful label {
    display: inline-block;
    margin-bottom: 0.75em;
    line-height: 1.19em;
    font-weight: 500;
    font-size: calc((32 / 640vw ) * 100); }
  .page-helpful .btn-container {
    display: block; }
    .page-helpful .btn-container .icon-svg {
      width: calc((26 / 640vw ) * 100);
      height: calc((26 / 640vw ) * 100);
      margin-right: 10px;
      vertical-align: middle; }
      .page-helpful .btn-container .icon-svg:last-child {
        display: none; }
    .page-helpful .btn-container .btn.selected .icon-svg:first-of-type {
      display: none; }
    .page-helpful .btn-container .btn.selected .icon-svg:last-of-type {
      display: inline-block; }
  @media (min-width: 640px) {
    .page-helpful .container, .page-helpful .form-factory {
      padding: 42px 0 40px 0; }
    .page-helpful .btn-tooltip {
      margin-right: 14px; }
      .page-helpful .btn-tooltip .icon-svg {
        width: 26px;
        height: 26px; }
    .page-helpful .btn-container .icon-svg {
      width: 19px;
      height: 19px; }
    .page-helpful label {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .page-helpful .container, .page-helpful .form-factory {
      padding: 13px 0 11px 0; }
    .page-helpful label {
      margin-right: 10px;
      line-height: 1.5em;
      font-size: 1rem; }
    .page-helpful .btn-tooltip {
      margin-right: 10px;
      vertical-align: middle; }
      .page-helpful .btn-tooltip .icon-svg {
        width: 25px;
        height: 25px; }
    .page-helpful .btn-container {
      display: inline-block; } }

.parametric-search {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 3em; }
  .parametric-search [data-view="applied"] {
    display: none; }
  .parametric-search .loader svg {
    z-index: 1050; }
  .parametric-search .loader-bg {
    z-index: 1049; }
  .parametric-search form {
    position: relative;
    width: 100%; }
  .parametric-search .form-group-number {
    display: inline-block;
    width: 50%;
    float: left; }
    .parametric-search .form-group-number label {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((32 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.5em;
      font-weight: 200; }
      @media (min-width: 640px) {
        .parametric-search .form-group-number label {
          font-size: 32px;
          font-size: 2rem;
          line-height: 32px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .parametric-search .form-group-number label {
          font-size: calc((16 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .parametric-search .form-group-number label {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  .parametric-search .filters {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    display: none;
    background-color: #ffffff;
    z-index: 1000; }
    .parametric-search .filters > .row > .col {
      flex-basis: 100%; }
    .parametric-search .filters .btn-transparent {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      font-size: calc((26 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      margin: 0;
      line-height: initial;
      padding-right: 1em;
      padding-left: 1em;
      text-decoration: underline;
      color: #616161; }
      @media (min-width: 640px) {
        .parametric-search .filters .btn-transparent {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 640px) {
        .parametric-search .filters .btn-transparent {
          font-size: 26px;
          font-size: 1.625rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .parametric-search .filters .btn-transparent {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100);
          font-size: calc((12 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .parametric-search .filters .btn-transparent {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .parametric-search .filters .btn-transparent {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .parametric-search .filters .btn-transparent .icon-svg {
        fill: #2b2b2b; }
    .parametric-search .filters .card.level-1 .card-header button[data-toggle="collapse"] {
      text-align: left;
      font-weight: inherit;
      font-family: inherit;
      color: inherit;
      padding: 0; }
    .parametric-search .filters .card .card-block .custom-control {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0; }
      .parametric-search .filters .card .card-block .custom-control .logo {
        height: 2em; }
      .parametric-search .filters .card .card-block .custom-control > .icon-svg {
        width: 2em;
        height: 2em;
        margin: 0 2em 0 0;
        vertical-align: middle; }
      .parametric-search .filters .card .card-block .custom-control .custom-control-indicator {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
        left: auto; }
      .parametric-search .filters .card .card-block .custom-control .custom-control-description {
        display: inline-block;
        width: 65%;
        line-height: 1.25em;
        margin-top: 0.25em;
        vertical-align: middle; }
        .parametric-search .filters .card .card-block .custom-control .custom-control-description small {
          line-height: inherit; }
    .parametric-search .filters .card .card-block .fieldset-label {
      padding-top: .75rem;
      padding-bottom: .75rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .parametric-search .filters .card .card-block .fieldset-label:last-of-type {
        border-bottom: none; }
    .parametric-search .filters .card .card-block .list-group {
      margin: 0 1.5em 1em 1.5em; }
      .parametric-search .filters .card .card-block .list-group .list-group-item {
        padding-left: 0;
        padding-right: 0;
        border-left: 0;
        border-right: 0;
        page-break-inside: avoid; }
        .parametric-search .filters .card .card-block .list-group .list-group-item:first-child {
          border-top: 0; }
    .parametric-search .filters .card .card-footer {
      border: 0;
      background-color: transparent; }
  .parametric-search .card .card-header {
    position: relative;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    background-color: #ffffff;
    border: 0; }
    .parametric-search .card .card-header > button {
      width: 100%;
      text-align: left;
      padding-left: 0; }
      .parametric-search .card .card-header > button .rating .rating-dots .icon-svg, .parametric-search .card .card-header > button .rating .rating-circles .icon-svg {
        margin-right: 0; }
        .parametric-search .card .card-header > button .rating .rating-dots .icon-svg svg, .parametric-search .card .card-header > button .rating .rating-circles .icon-svg svg {
          fill: #007697; }
      .parametric-search .card .card-header > button .headline .applied-value {
        font-weight: 200;
        padding-left: 0.5em;
        color: #2b2b2b; }
      .parametric-search .card .card-header > button .icon-svg {
        transform: rotate(0deg);
        transition: transform 0.3s;
        width: calc((24 / 640vw ) * 100);
        height: calc((24 / 640vw ) * 100);
        margin-right: 1.5em; }
      .parametric-search .card .card-header > button.collapsed .icon-svg {
        transform: rotate(180deg); }
    .parametric-search .card .card-header .badge {
      width: calc((45 / 640vw ) * 100);
      height: calc((45 / 640vw ) * 100);
      margin-right: 1.1em;
      border-radius: 50%;
      line-height: 1.35em;
      vertical-align: middle;
      font-size: calc((24 / 640vw ) * 100); }
  .parametric-search .card[aria-disabled="true"] .card-header {
    opacity: 0.5; }
  .parametric-search .btn-transparent {
    min-width: 0;
    margin: 0;
    padding-right: 0.25em;
    padding-left: 0.25em;
    color: #616161; }
    .parametric-search .btn-transparent .icon-svg svg {
      fill: #afafaf; }
    .parametric-search .btn-transparent.active {
      color: #2b2b2b; }
      .parametric-search .btn-transparent.active .icon-svg svg {
        fill: #2b2b2b; }
  .parametric-search ul.list-sort-options {
    margin: 0.5em 0 0 0;
    padding: 0 1.5em;
    background-color: #ffffff; }
    .parametric-search ul.list-sort-options li {
      padding: 0.75em;
      border-left: 0;
      border-right: 0; }
      .parametric-search ul.list-sort-options li .custom-control {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0; }
        .parametric-search ul.list-sort-options li .custom-control > .icon-svg {
          width: 2em;
          height: 2em;
          margin: 0 2em 0 0;
          vertical-align: middle; }
        .parametric-search ul.list-sort-options li .custom-control .custom-control-indicator {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
          left: auto; }
        .parametric-search ul.list-sort-options li .custom-control .custom-control-description {
          display: inline-block;
          width: 65%;
          line-height: 1.25em;
          margin-top: 0.25em;
          vertical-align: middle; }
          .parametric-search ul.list-sort-options li .custom-control .custom-control-description small {
            line-height: inherit; }
      .parametric-search ul.list-sort-options li:first-child {
        border-top: 0;
        padding-top: 0.5em; }
      .parametric-search ul.list-sort-options li:last-child {
        border-bottom: 0;
        padding-bottom: 2em; }
  .parametric-search .noUi-horizontal {
    height: 3px;
    box-shadow: none;
    border: none; }
  .parametric-search .noUi-target {
    background: #e1e1e1; }
  .parametric-search .noUi-connect {
    background-color: #007697; }
  .parametric-search .noUi-handle {
    border: 3px solid #007697;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: none;
    width: calc((48 / 640vw ) * 100);
    height: calc((48 / 640vw ) * 100);
    top: calc((-20 / 640vw ) * 100); }
    .parametric-search .noUi-handle::before, .parametric-search .noUi-handle::after {
      content: none; }
  .parametric-search .results {
    position: relative; }
    .parametric-search .results .results-header {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0.75em;
      z-index: 50; }
      .parametric-search .results .results-header .collapse footer .btn-transparent {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        font-size: calc((26 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        margin: 0;
        line-height: initial;
        padding-right: 1em;
        padding-left: 1em;
        text-decoration: underline;
        color: #2b2b2b; }
        @media (min-width: 640px) {
          .parametric-search .results .results-header .collapse footer .btn-transparent {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 640px) {
          .parametric-search .results .results-header .collapse footer .btn-transparent {
            font-size: 26px;
            font-size: 1.625rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .parametric-search .results .results-header .collapse footer .btn-transparent {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100);
            font-size: calc((12 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .parametric-search .results .results-header .collapse footer .btn-transparent {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .parametric-search .results .results-header .collapse footer .btn-transparent {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .parametric-search .results .results-header-count {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 1em;
      text-align: center;
      background-color: #ffffff; }
    .parametric-search .results .grid-view {
      padding: 1em; }
      .parametric-search .results .grid-view label {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        margin-bottom: 1rem;
        font-weight: 500; }
        @media (min-width: 640px) {
          .parametric-search .results .grid-view label {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .parametric-search .results .grid-view label {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .parametric-search .results .grid-view label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .parametric-search .results .grid-view .trip-advisor {
        margin-bottom: 1em; }
        .parametric-search .results .grid-view .trip-advisor .logo {
          max-height: 3em; }
      .parametric-search .results .grid-view .category, .parametric-search .results .grid-view .phone {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        margin-bottom: 1rem; }
        @media (min-width: 640px) {
          .parametric-search .results .grid-view .category, .parametric-search .results .grid-view .phone {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .parametric-search .results .grid-view .category, .parametric-search .results .grid-view .phone {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .parametric-search .results .grid-view .category, .parametric-search .results .grid-view .phone {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .parametric-search .results .grid-view .property-grid-item {
        position: relative;
        width: 100%;
        padding-right: 9.5px;
        padding-left: 9.5px;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: calc((60 / 640vw ) * 100); }
        .parametric-search .results .grid-view .property-grid-item .list-badges {
          margin-top: 0.75em; }
          .parametric-search .results .grid-view .property-grid-item .list-badges .badge-descriptive {
            display: table; }
        .parametric-search .results .grid-view .property-grid-item .headline-7 {
          line-height: 1.25em; }
        .parametric-search .results .grid-view .property-grid-item footer {
          position: relative;
          bottom: 0;
          width: 100%;
          height: auto;
          text-align: right;
          background-color: #f7f7f7;
          border-bottom: 2px solid #dedede; }
          .parametric-search .results .grid-view .property-grid-item footer .btn {
            margin: 0;
            padding-left: 0;
            padding-right: 0; }
          .parametric-search .results .grid-view .property-grid-item footer .price {
            display: block;
            font-weight: 500;
            text-transform: none;
            font-size: calc((36 / 640vw ) * 100);
            line-height: calc((37 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100);
            margin-bottom: 0.55em;
            display: inline-flex;
            height: 100%;
            align-items: center;
            margin: 0 1em; }
            @media (min-width: 640px) {
              .parametric-search .results .grid-view .property-grid-item footer .price {
                font-size: 36px;
                font-size: 2.25rem;
                line-height: 37px;
                letter-spacing: 0.5px; } }
            @media (min-width: 768px) {
              .parametric-search .results .grid-view .property-grid-item footer .price {
                font-size: calc((20 / 640vw ) * 100);
                line-height: calc((24 / 640vw ) * 100);
                letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .parametric-search .results .grid-view .property-grid-item footer .price {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
          .parametric-search .results .grid-view .property-grid-item footer .avail-msg {
            font-weight: 500;
            padding: 5px 10px;
            font-size: 14px;
            background-color: #c6c6c6;
            text-align: center; }
          .parametric-search .results .grid-view .property-grid-item footer .avail-closed-msg {
            padding: 5px 10px;
            font-size: 14px;
            background-color: #c6c6c6;
            text-align: center; }
        .parametric-search .results .grid-view .property-grid-item ~ .row {
          display: block; }
    .parametric-search .results .map-view {
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 150%; }
      .parametric-search .results .map-view .map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .parametric-search .results .map-view .legend {
        position: absolute;
        top: calc((100 / 640vw ) * 100);
        right: 0;
        left: 0;
        height: auto;
        margin: 0.75em;
        padding: 0;
        background-color: #ffffff;
        text-align: center;
        pointer-events: none; }
        .parametric-search .results .map-view .legend ul.list-inline {
          display: inline-block;
          margin: 0 auto; }
          .parametric-search .results .map-view .legend ul.list-inline li {
            display: block;
            font-weight: 500;
            text-transform: none;
            font-size: calc((26 / 640vw ) * 100);
            line-height: calc((32 / 640vw ) * 100);
            letter-spacing: calc((0.55 / 640vw ) * 100);
            margin-bottom: 0.5em;
            display: inline-block;
            margin: 1em 0.25em 1em 0;
            white-space: nowrap; }
            @media (min-width: 640px) {
              .parametric-search .results .map-view .legend ul.list-inline li {
                font-size: 26px;
                font-size: 1.625rem;
                line-height: 32px;
                letter-spacing: 0.55px; } }
            @media (min-width: 768px) {
              .parametric-search .results .map-view .legend ul.list-inline li {
                font-size: calc((12 / 640vw ) * 100);
                line-height: calc((24 / 640vw ) * 100);
                letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .parametric-search .results .map-view .legend ul.list-inline li {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
            .parametric-search .results .map-view .legend ul.list-inline li .icon-svg {
              display: inline-block !important;
              margin: 0 0.25em 0 0;
              width: calc((28 / 640vw ) * 100);
              height: calc((28 / 640vw ) * 100); }
    .parametric-search .results[data-view="grid"] {
      padding-top: calc((120 / 640vw ) * 100); }
      .parametric-search .results[data-view="grid"] .results-header-count {
        width: 90%;
        margin: 0 auto;
        border-top: 1px solid #e1e1e1; }
      .parametric-search .results[data-view="grid"] .map-view {
        display: none; }
      .parametric-search .results[data-view="grid"] .grid-view {
        display: block; }
        .parametric-search .results[data-view="grid"] .grid-view .property-grid-item ul.list-badges, .parametric-search .results[data-view="grid"] .grid-view .property-grid-item .category, .parametric-search .results[data-view="grid"] .grid-view .property-grid-item .phone {
          display: block; }
    .parametric-search .results[data-view="map"] {
      background-color: #f7f7f7; }
      .parametric-search .results[data-view="map"] .results-header-count {
        position: absolute;
        bottom: 0;
        z-index: 50;
        transform: translateY(100%); }
      .parametric-search .results[data-view="map"] .map-view {
        display: block; }
      .parametric-search .results[data-view="map"] .grid-view {
        display: none; }
  @media (min-width: 640px) {
    .parametric-search .card .card-header > button .icon-svg {
      width: 24px;
      height: 24px; }
    .parametric-search .card .card-header .badge {
      width: 45px;
      height: 45px;
      font-size: 24px; }
    .parametric-search .results .map-view .legend {
      top: 100px; }
    .parametric-search .results[data-view="grid"] {
      padding-top: 120px; }
    .parametric-search .noUi-horizontal .noUi-handle {
      width: 48px;
      height: 48px;
      top: -20px; } }
  @media (max-width: 767.98px) {
    .parametric-search .collapse[class^="absolute-"], .parametric-search .collapsing[class^="absolute-"] {
      position: static; }
    .parametric-search .results .results-header .nav {
      padding: 0.75em 0; }
      .parametric-search .results .results-header .nav .nav-item .btn-transparent {
        padding: 0 0.25em; }
        .parametric-search .results .results-header .nav .nav-item .btn-transparent .icon-svg {
          width: calc((28 / 640vw ) * 100);
          height: calc((28 / 640vw ) * 100); }
      .parametric-search .results .results-header .nav.nav-left .nav-item {
        border-right: 1px solid #e1e1e1; }
        .parametric-search .results .results-header .nav.nav-left .nav-item:last-child {
          border: 0; }
      .parametric-search .results .results-header .nav.nav-right {
        position: relative; }
        .parametric-search .results .results-header .nav.nav-right .nav-item:first-child {
          display: none; }
        .parametric-search .results .results-header .nav.nav-right .nav-item:last-child {
          display: list-item; }
        .parametric-search .results .results-header .nav.nav-right:after {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-top-color: #ffffff;
          border-width: calc((12 / 640vw ) * 100);
          margin-left: calc((-12 / 640vw ) * 100); }
    .parametric-search .results .grid-view .property-grid-item .content .headline {
      margin-right: 40px; }
    .parametric-search .results .grid-view .property-grid-item .content .btn-overlay {
      margin: 0px;
      width: 6.25vw;
      height: 6.25vw; }
      .parametric-search .results .grid-view .property-grid-item .content .btn-overlay .icon-svg svg {
        fill: #2b2b2b; }
    .parametric-search .results .grid-view .property-grid-item footer .btn {
      padding: calc((22 / 640vw ) * 100) calc((44 / 640vw ) * 100) calc((25 / 640vw ) * 100); }
    .parametric-search .results[data-view="grid"] .results-header .nav.nav-right .nav-item {
      border-left: 1px solid #e1e1e1; }
      .parametric-search .results[data-view="grid"] .results-header .nav.nav-right .nav-item:first-child {
        display: list-item; }
      .parametric-search .results[data-view="grid"] .results-header .nav.nav-right .nav-item:last-child {
        display: none; } }
  @media (min-width: 768px) {
    .parametric-search .form-group-number {
      display: inline-block;
      width: auto;
      float: none;
      border-right: 0; }
    .parametric-search .filters {
      position: relative;
      display: block;
      width: 100%;
      z-index: 900; }
      .parametric-search .filters > .row > .col {
        flex-basis: auto;
        ms-flex-preferred-size: auto;
        width: auto; }
      .parametric-search .filters .card {
        border-left: 0; }
        .parametric-search .filters .card .collapsing, .parametric-search .filters .card .collapse {
          position: absolute;
          top: 100%;
          min-width: 350px;
          background-color: #ffffff;
          border: 1px solid #e1e1e1;
          z-index: 900; }
          .parametric-search .filters .card .collapsing.multi-col-2, .parametric-search .filters .card .collapse.multi-col-2 {
            min-width: 450%; }
          .parametric-search .filters .card .collapsing.multi-col-3, .parametric-search .filters .card .collapse.multi-col-3 {
            /*						min-width: 600%*/
            width: 300%;
            max-width: 950px; }
        .parametric-search .filters .card .collapse {
          height: auto;
          overflow-y: scroll; }
        .parametric-search .filters .card .card-header {
          white-space: nowrap; }
          .parametric-search .filters .card .card-header > button .headline .applied-value {
            padding-left: 0;
            font-weight: 500;
            color: inherit; }
          .parametric-search .filters .card .card-header > button .icon-svg {
            right: 12px;
            width: 12px;
            height: 12px;
            margin-right: 0.5em; }
          .parametric-search .filters .card .card-header .badge {
            width: 20px;
            height: 20px;
            font-size: 12px; }
          .parametric-search .filters .card .card-header .rating {
            margin: 0; }
            .parametric-search .filters .card .card-header .rating .icon-svg {
              right: auto; }
          .parametric-search .filters .card .card-header .headline {
            width: calc(100% - 30px);
            overflow: hidden;
            text-overflow: ellipsis; }
        .parametric-search .filters .card .card-block {
          padding: 2em 2em 1em 2em; }
          .parametric-search .filters .card .card-block [class^="col"] {
            padding: 0 1em; }
          .parametric-search .filters .card .card-block .list-group {
            margin: 0 0 1em 0; }
            .parametric-search .filters .card .card-block .list-group .list-group-item {
              padding-left: 0;
              padding-right: 0;
              border-left: 0;
              border-right: 0; }
              .parametric-search .filters .card .card-block .list-group .list-group-item:first-child {
                border-top: 0; }
              .parametric-search .filters .card .card-block .list-group .list-group-item:last-child {
                border-bottom: 0; }
          .parametric-search .filters .card .card-block .custom-control > .icon-svg {
            margin: 5px 15px 0 0; }
        .parametric-search .filters .card .card-footer {
          padding: 0.5em 0 2.5em 0;
          text-align: center; }
        .parametric-search .filters .card.level-1 {
          border-bottom: 0; }
          .parametric-search .filters .card.level-1 .collapsing, .parametric-search .filters .card.level-1 .collapse {
            min-width: 150%; }
          .parametric-search .filters .card.level-1 .card-header {
            padding: 2rem 1.25rem;
            background-color: #f7f7f7; }
            .parametric-search .filters .card.level-1 .card-header .icon-svg {
              right: 20px;
              width: 20px;
              height: 20px; }
            .parametric-search .filters .card.level-1 .card-header .headline {
              font-weight: 300; }
              .parametric-search .filters .card.level-1 .card-header .headline strong, .parametric-search .filters .card.level-1 .card-header .headline .applied-value {
                font-weight: 500; }
    .parametric-search .noUi-horizontal .noUi-handle {
      width: 24px;
      height: 24px;
      top: -10px; }
    .parametric-search .results {
      position: relative;
      top: 0;
      bottom: 0;
      width: 100%;
      padding-bottom: 34%;
      overflow-y: scroll; }
      .parametric-search .results .grid-view {
        position: absolute;
        display: inline-block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 43.8%;
        padding: 2em;
        overflow-y: scroll; }
        .parametric-search .results .grid-view .property-grid-item {
          position: relative;
          width: 100%;
          padding-right: 9.5px;
          padding-left: 9.5px;
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 22px;
          padding-bottom: 48px; }
          .parametric-search .results .grid-view .property-grid-item > .row:first-child {
            height: 100%; }
            .parametric-search .results .grid-view .property-grid-item > .row:first-child > [class^="col"] {
              flex: 0 0 100%;
              max-width: 100%; }
          .parametric-search .results .grid-view .property-grid-item ul.list-badges, .parametric-search .results .grid-view .property-grid-item .phone {
            display: none; }
          .parametric-search .results .grid-view .property-grid-item figure picture {
            display: block;
            width: 100%;
            height: 100%; }
          .parametric-search .results .grid-view .property-grid-item .content {
            flex: 0 0 100%;
            max-width: 100%; }
            .parametric-search .results .grid-view .property-grid-item .content .headline {
              display: inline-block;
              margin: 0 0 0.75em 0; }
            .parametric-search .results .grid-view .property-grid-item .content .btn-overlay {
              position: relative;
              float: right;
              margin-top: -0.75em; }
              .parametric-search .results .grid-view .property-grid-item .content .btn-overlay .icon-svg svg {
                fill: #2b2b2b; }
          .parametric-search .results .grid-view .property-grid-item .trip-advisor {
            position: relative; }
            .parametric-search .results .grid-view .property-grid-item .trip-advisor .logo {
              max-height: 27px; }
          .parametric-search .results .grid-view .property-grid-item footer {
            position: absolute;
            top: auto;
            left: 9.5px;
            bottom: 0;
            width: calc(100% - 19px);
            height: auto;
            text-align: right;
            background-color: #f7f7f7;
            border-bottom: 2px solid #dedede; }
            .parametric-search .results .grid-view .property-grid-item footer .btn {
              height: 46px; }
            .parametric-search .results .grid-view .property-grid-item footer .price {
              line-height: 44px; }
            .parametric-search .results .grid-view .property-grid-item footer small {
              font-weight: 300; }
      .parametric-search .results .map-view {
        position: absolute;
        display: inline-block;
        float: right;
        right: 0;
        bottom: 0;
        width: 56.2%;
        height: 100%;
        padding-bottom: 0; }
        .parametric-search .results .map-view .legend {
          position: absolute;
          top: 0;
          right: 0;
          bottom: auto;
          left: auto;
          display: inline-block;
          height: 50px;
          margin: 20px 35px 0 0;
          padding: 12px 20px 12px;
          background-color: #ffffff;
          pointer-events: none; }
          .parametric-search .results .map-view .legend ul.list-inline li {
            margin: 0 1em 0 0; }
            .parametric-search .results .map-view .legend ul.list-inline li .icon-svg {
              width: 24px;
              height: 24px; }
      .parametric-search .results[data-view="map"] {
        background-color: #ffffff; }
        .parametric-search .results[data-view="map"] .grid-view {
          display: block; }
        .parametric-search .results[data-view="map"] .map-view {
          display: block; }
      .parametric-search .results[data-view="grid"] .grid-view {
        display: block;
        width: 100%; }
        .parametric-search .results[data-view="grid"] .grid-view .property-grid-item {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 40px; }
          .parametric-search .results[data-view="grid"] .grid-view .property-grid-item > .row:first-child > [class^="col"] {
            position: relative;
            width: 100%;
            padding-right: 9.5px;
            padding-left: 9.5px;
            flex: 0 0 50%;
            max-width: 50%; }
          .parametric-search .results[data-view="grid"] .grid-view .property-grid-item .content {
            padding-bottom: calc((80 / 1024vw ) * 100); }
            .parametric-search .results[data-view="grid"] .grid-view .property-grid-item .content .headline {
              margin: 0; }
            .parametric-search .results[data-view="grid"] .grid-view .property-grid-item .content .trip-advisor {
              position: absolute;
              bottom: 0; }
      .parametric-search .results[data-view="grid"] .map-view {
        display: none; }
    .parametric-search .fee-disclaimer {
      font-weight: 300;
      font-size: 0.875rem; } }
  @media (min-width: 1024px) {
    .parametric-search .filters .card .collapsing.multi-col-3, .parametric-search .filters .card .collapse.multi-col-3 {
      width: 400%; }
    .parametric-search .results[data-view="map"] .grid-view .property-grid-item {
      flex: 0 0 50%;
      max-width: 50%; }
    .parametric-search .results[data-view="grid"] .grid-view .property-grid-item {
      flex: 0 0 33.333333%;
      max-width: 33.333333%; }
      .parametric-search .results[data-view="grid"] .grid-view .property-grid-item .content {
        padding-bottom: 80px; } }
  @media (min-width: 1500px) {
    .parametric-search .filters .card.level-1 .collapsing, .parametric-search .filters .card.level-1 .collapse {
      min-width: 100%; }
      .parametric-search .filters .card.level-1 .collapsing.multi-col-3, .parametric-search .filters .card.level-1 .collapse.multi-col-3 {
        width: 400%; } }

@media (max-width: 767.98px) {
  .zoom-enabled .parametric-search .filters {
    overflow: hidden; } }

.personal-guidance {
  position: relative;
  height: calc((994 / 640vw ) * 100);
  color: #ffffff; }
  .personal-guidance p {
    color: #ffffff; }
  .personal-guidance .feature {
    position: absolute;
    top: 0;
    left: auto;
    right: auto;
    bottom: 0;
    width: 100%;
    height: 100%; }
    .personal-guidance .feature .container > .content, .personal-guidance .feature .form-factory > .content {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 calc((20 / 640vw ) * 100); }
    .personal-guidance .feature .background img {
      left: calc((-32 / 640vw ) * 100);
      min-width: 116%;
      transform: translateY(-50%);
      transition: left 5s linear; }
    .personal-guidance .feature .background img.pan {
      left: 0; }
  .personal-guidance header {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    margin: calc((115 / 640vw ) * 100) auto 0; }
    .personal-guidance header .progress {
      width: calc(100% - 2px);
      height: calc((4 / 640vw ) * 100);
      border: 0;
      margin: calc((-10 / 640vw ) * 100) auto 0; }
      .personal-guidance header .progress .progress-bar {
        background-color: #3cc1cc; }
    .personal-guidance header .nav-tabs {
      position: relative;
      display: flex;
      width: calc((240 / 640vw ) * 100);
      margin: 0 auto;
      padding: 0;
      border: 0;
      justify-content: space-between;
      overflow: visible; }
      .personal-guidance header .nav-tabs .nav-item {
        width: calc((16 / 640vw ) * 100);
        height: calc((16 / 640vw ) * 100);
        padding: 0;
        flex: 0; }
        .personal-guidance header .nav-tabs .nav-item .nav-link {
          padding: 0;
          margin: 0;
          display: block;
          width: calc((16 / 640vw ) * 100);
          height: calc((16 / 640vw ) * 100);
          background-color: #ffffff;
          border-radius: 50%;
          pointer-events: none; }
          .personal-guidance header .nav-tabs .nav-item .nav-link.active {
            background-color: #3cc1cc;
            pointer-events: auto; }
        .personal-guidance header .nav-tabs .nav-item.completed .nav-link {
          background-color: #3cc1cc;
          pointer-events: auto; }
  .personal-guidance .close {
    display: none; }
    .personal-guidance .close .icon-svg {
      width: calc((32 / 640vw ) * 100);
      height: calc((32 / 640vw ) * 100); }
      .personal-guidance .close .icon-svg svg {
        fill: #ffffff; }
  .personal-guidance .container-circle {
    position: relative;
    width: calc((480 / 640vw ) * 100);
    height: calc((480 / 640vw ) * 100);
    margin: calc((65 / 640vw ) * 100) auto calc((45 / 640vw ) * 100);
    overflow: hidden; }
    .personal-guidance .container-circle .clipped-container {
      width: 50%;
      height: 100%;
      right: 0%;
      position: absolute;
      overflow: hidden;
      transform-origin: left center; }
    .personal-guidance .container-circle .halfcircle {
      box-sizing: border-box;
      height: 100%;
      right: 0%;
      position: absolute;
      border: solid calc((10 / 640vw ) * 100) transparent;
      border-radius: 50%; }
      .personal-guidance .container-circle .halfcircle.clipped {
        width: 200%;
        transform: rotate(-45deg); }
      .personal-guidance .container-circle .halfcircle.fixed {
        width: 100%;
        transform: rotate(135deg);
        opacity: 0; }

@keyframes clippingrotate {
  0% {
    transform: rotate(0deg); }
  49.5% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(180deg); } }

@keyframes rotation {
  0% {
    transform: rotate(-45deg); }
  100% {
    transform: rotate(135deg); } }

@keyframes showfixed {
  0% {
    opacity: 0; }
  49.5% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }
    .personal-guidance .container-circle.animated .clipped-container {
      animation: clippingrotate 1s 1;
      transform: rotate(180deg); }
    .personal-guidance .container-circle.animated .halfcircle {
      border-top-color: #3cc1cc;
      border-left-color: #3cc1cc; }
      .personal-guidance .container-circle.animated .halfcircle.clipped {
        transform: rotate(135deg);
        animation: rotation 0.5s linear 2; }
      .personal-guidance .container-circle.animated .halfcircle.fixed {
        opacity: 1;
        animation: showfixed 1s 1; }
    .personal-guidance .container-circle:after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      border-radius: 50%;
      border: calc((10 / 640vw ) * 100) solid #ffffff;
      z-index: -1; }
    .personal-guidance .container-circle .content {
      padding: calc((40 / 640vw ) * 100); }
  .personal-guidance fieldset {
    padding: 40px 0; }
  .personal-guidance .form-check-inline {
    max-width: 30%;
    margin: 0 calc((10 / 640vw ) * 100) calc((40 / 640vw ) * 100) calc((10 / 640vw ) * 100);
    vertical-align: top; }
    .personal-guidance .form-check-inline .form-check-label {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((32 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.5em;
      padding: 0;
      animation: fadein 2s; }
      @media (min-width: 640px) {
        .personal-guidance .form-check-inline .form-check-label {
          font-size: 32px;
          font-size: 2rem;
          line-height: 32px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .personal-guidance .form-check-inline .form-check-label {
          font-size: calc((16 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .personal-guidance .form-check-inline .form-check-label {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
      .personal-guidance .form-check-inline .form-check-label picture {
        position: relative;
        overflow: hidden;
        display: block;
        width: calc((160 / 640vw ) * 100);
        height: calc((160 / 640vw ) * 100);
        margin: 0 calc((5 / 640vw ) * 100) calc((20 / 640vw ) * 100);
        pointer-events: none; }
        .personal-guidance .form-check-inline .form-check-label picture img {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          width: initial;
          min-width: 100%;
          height: inherit;
          object-fit: cover;
          font-family: "object-fit: cover; object-position: center;"; }
      .personal-guidance .form-check-inline .form-check-label span {
        display: inline-block;
        width: 95%; }
    .personal-guidance .form-check-inline .form-check-input {
      opacity: 0; }
      .personal-guidance .form-check-inline .form-check-input:checked ~ picture:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        border: calc((10 / 640vw ) * 100) solid #ffffff;
        border-radius: 50%;
        content: ' ';
        z-index: 1; }
  .personal-guidance .range {
    position: relative;
    margin-bottom: calc((180 / 640vw ) * 100);
    padding: 0; }
    .personal-guidance .range .range-container {
      position: relative;
      display: inline-block;
      width: 100%;
      margin: calc((90 / 640vw ) * 100) 0 0 0; }
      .personal-guidance .range .range-container input[type='range'] {
        width: 100%;
        margin: calc((250 / 640vw ) * 100) 0 calc((50 / 640vw ) * 100) 0;
        padding: 0;
        border: 0;
        -webkit-appearance: none; }
        .personal-guidance .range .range-container input[type='range']::-webkit-slider-runnable-track {
          width: 100%;
          height: calc((10 / 640vw ) * 100);
          box-shadow: none;
          background: #ffffff;
          border: 0;
          border-radius: 0;
          cursor: pointer; }
        .personal-guidance .range .range-container input[type='range']::-webkit-slider-thumb {
          width: calc((48 / 640vw ) * 100);
          height: calc((48 / 640vw ) * 100);
          margin-top: calc((-20 / 640vw ) * 100);
          box-shadow: none;
          border: 0;
          border-radius: 50%;
          background: #3cc1cc;
          cursor: pointer;
          -webkit-appearance: none; }
        .personal-guidance .range .range-container input[type='range']:focus::-webkit-slider-runnable-track {
          background: #ffffff; }
        .personal-guidance .range .range-container input[type='range']::-moz-range-track {
          width: 100%;
          height: calc((10 / 640vw ) * 100);
          cursor: pointer;
          box-shadow: none;
          background: #ffffff;
          border-radius: 0;
          border: 0 solid #000000; }
        .personal-guidance .range .range-container input[type='range']::-moz-range-thumb {
          width: calc((48 / 640vw ) * 100);
          height: calc((48 / 640vw ) * 100);
          box-shadow: none;
          border: 0;
          border-radius: 50%;
          background: #3cc1cc;
          cursor: pointer; }
        .personal-guidance .range .range-container input[type='range']::-moz-focus-outer {
          border: 0; }
        .personal-guidance .range .range-container input[type='range']::-ms-track {
          width: 100%;
          height: calc((10 / 640vw ) * 100);
          cursor: pointer;
          background: transparent;
          border-color: transparent;
          color: transparent;
          border-width: calc((19 / 640vw ) * 100) 0; }
        .personal-guidance .range .range-container input[type='range']::-ms-fill-lower {
          background: #ffffff; }
        .personal-guidance .range .range-container input[type='range']::-ms-fill-upper {
          background: #ffffff; }
        .personal-guidance .range .range-container input[type='range']::-ms-thumb {
          width: calc((48 / 640vw ) * 100);
          height: calc((48 / 640vw ) * 100);
          margin: 0;
          border-radius: 50%;
          background: #3cc1cc;
          box-shadow: none;
          border: 0;
          cursor: pointer; }
        .personal-guidance .range .range-container input[type='range']:focus::-ms-fill-lower {
          background: #ffffff; }
        .personal-guidance .range .range-container input[type='range']:focus::-ms-fill-upper {
          background: #ffffff; }
      .personal-guidance .range .range-container output {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        width: calc((160 / 640vw ) * 100);
        height: calc((160 / 640vw ) * 100);
        line-height: calc((140 / 640vw ) * 100);
        font-family: "Oswald", sans-serif;
        font-size: calc((50 / 640vw ) * 100);
        color: #ffffff;
        text-align: center;
        border: calc((10 / 640vw ) * 100) solid #ffffff;
        border-radius: 50%;
        pointer-events: none;
        letter-spacing: 0.1em;
        text-indent: 0.1em; }
    .personal-guidance .range .btn-increase, .personal-guidance .range .btn-decrease {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((56 / 640vw ) * 100);
      line-height: calc((72 / 640vw ) * 100);
      letter-spacing: calc((5 / 640vw ) * 100);
      margin-bottom: 0.75em;
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
      font-weight: 500;
      position: absolute;
      bottom: calc((-50 / 640vw ) * 100);
      display: inline-block;
      margin: 0;
      padding: 0 0.25em;
      min-width: 0;
      width: 25%; }
      @media (min-width: 640px) {
        .personal-guidance .range .btn-increase, .personal-guidance .range .btn-decrease {
          font-size: 56px;
          font-size: 3.5rem;
          line-height: 72px;
          letter-spacing: 5px; } }
      @media (min-width: 768px) {
        .personal-guidance .range .btn-increase, .personal-guidance .range .btn-decrease {
          font-size: calc((40 / 640vw ) * 100);
          line-height: calc((56 / 640vw ) * 100);
          letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .personal-guidance .range .btn-increase, .personal-guidance .range .btn-decrease {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 56px;
      letter-spacing: 5.25px; } }
      .personal-guidance .range .btn-increase mark, .personal-guidance .range .btn-decrease mark {
        padding: 0 0.25em 0 0.45em;
        background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
        box-decoration-break: clone; }
        @media screen and (min-width: 0\0) {
          .personal-guidance .range .btn-increase mark, .personal-guidance .range .btn-decrease mark {
            padding: 0; } }
        @supports (-ms-ime-align: auto) {
          .personal-guidance .range .btn-increase mark, .personal-guidance .range .btn-decrease mark {
            padding: 0; } }
    .personal-guidance .range .btn-decrease {
      left: 0;
      text-align: left; }
    .personal-guidance .range .btn-increase {
      right: 0;
      text-align: right; }
  .personal-guidance footer {
    margin-bottom: 0;
    padding: 0 calc((30 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .personal-guidance {
      height: 994px; }
      .personal-guidance .feature .container > .content, .personal-guidance .feature .form-factory > .content {
        padding: 0 20px; }
      .personal-guidance header {
        margin: 115px auto 0; }
        .personal-guidance header .nav-tabs {
          position: relative;
          width: 245px; }
          .personal-guidance header .nav-tabs .nav-item {
            width: 16px;
            height: 16px; }
            .personal-guidance header .nav-tabs .nav-item .nav-link {
              width: 16px;
              height: 16px; }
        .personal-guidance header .progress {
          width: 240px;
          height: 4px;
          margin: -10px auto 0; }
      .personal-guidance .close .icon-svg {
        width: 32px;
        height: 32px; }
      .personal-guidance .container-circle {
        width: 480px;
        height: 480px;
        margin: 65px auto 45px; }
        .personal-guidance .container-circle:after {
          border-width: 10px; }
        .personal-guidance .container-circle .content {
          padding: 40px; }
        .personal-guidance .container-circle .halfcircle {
          border-width: 10px; }
      .personal-guidance .form-check-inline {
        margin: 0 10px 40px 10px; }
        .personal-guidance .form-check-inline .form-check-input .form-check-label picture {
          width: 160px;
          height: 160px;
          margin: 0 5px 20px; }
        .personal-guidance .form-check-inline .form-check-input:checked ~ picture:before {
          border-width: 10px; }
      .personal-guidance .range {
        margin-bottom: 180px; }
        .personal-guidance .range .range-container {
          margin: 90px 0 0 0; }
          .personal-guidance .range .range-container input[type='range'] {
            margin: 250px 0 50px 0; }
            .personal-guidance .range .range-container input[type='range']::-webkit-slider-runnable-track {
              height: 10px; }
            .personal-guidance .range .range-container input[type='range']::-webkit-slider-thumb {
              width: 48px;
              height: 48px;
              margin-top: -20px; }
            .personal-guidance .range .range-container input[type='range']::-moz-range-track {
              height: 10px; }
            .personal-guidance .range .range-container input[type='range']::-moz-range-thumb {
              width: 48px;
              height: 48px; }
            .personal-guidance .range .range-container input[type='range']::-ms-track {
              height: 10px;
              border-width: 19px 0; }
            .personal-guidance .range .range-container input[type='range']::-ms-thumb {
              width: 48px;
              height: 48px; }
          .personal-guidance .range .range-container output {
            width: 160px;
            height: 160px;
            line-height: 140px;
            border-width: 10px;
            font-size: 50px; }
      .personal-guidance footer {
        padding: 0 30px; } }
  @media (max-width: 767.98px) {
    .personal-guidance.in-progress {
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 1050; }
      .personal-guidance.in-progress .feature {
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: auto;
        height: 100%;
        overflow-y: scroll; }
        .personal-guidance.in-progress .feature .container, .personal-guidance.in-progress .feature .form-factory {
          overflow-y: scroll; }
          .personal-guidance.in-progress .feature .container > .content, .personal-guidance.in-progress .feature .form-factory > .content {
            margin-top: calc((172 / 640vw ) * 100); }
        .personal-guidance.in-progress .feature .close {
          position: absolute;
          display: block;
          top: 36px;
          right: 36px;
          fill: #ffffff;
          opacity: 1;
          z-index: 999; }
      .personal-guidance.in-progress footer {
        margin-bottom: calc((36 / 640vw ) * 100); }
    .personal-guidance footer .btn {
      width: 100%;
      display: block; }
      .personal-guidance footer .btn + .btn {
        margin-left: 0; } }
  @media (min-width: 768px) {
    .personal-guidance {
      height: calc((930 / 1024vw ) * 100); }
      .personal-guidance .feature .container > .content, .personal-guidance .feature .form-factory > .content {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        margin-top: calc((240 / 1024vw ) * 100);
        padding: 0; }
      .personal-guidance .feature .background img {
        left: -122px;
        transition: left 22s linear; }
      .personal-guidance header {
        margin: calc((180 / 1024vw ) * 100) auto 0; }
        .personal-guidance header .nav-tabs {
          width: 180px;
          margin: 0 auto;
          height: 12px; }
          .personal-guidance header .nav-tabs .nav-item .nav-link {
            display: block;
            width: 12px;
            height: 12px;
            background-color: #ffffff;
            border-radius: 50%; }
        .personal-guidance header .progress {
          width: calc(100% - 2px);
          height: 2px;
          margin-top: -6px;
          border: 0; }
      .personal-guidance .container-circle {
        margin-top: calc((-70 / 1024vw ) * 100); }
      .personal-guidance .form-check-inline {
        margin: 0 8px 40px 8px; }
        .personal-guidance .form-check-inline .form-check-label picture {
          width: calc((170 / 1024vw ) * 100);
          height: calc((170 / 1024vw ) * 100);
          margin: 0 auto 20px; }
        .personal-guidance .form-check-inline .form-check-label span {
          width: auto; }
      .personal-guidance .range {
        margin-bottom: 0; }
        .personal-guidance .range .range-container {
          max-width: 480px;
          margin: calc((100 / 1024vw ) * 100) 0 calc((140 / 1024vw ) * 100);
          vertical-align: middle; }
          .personal-guidance .range .range-container input[type='range'] {
            margin: 0; }
            .personal-guidance .range .range-container input[type='range']::-webkit-slider-runnable-track {
              height: calc((10 / 1024vw ) * 100); }
            .personal-guidance .range .range-container input[type='range']::-webkit-slider-thumb {
              width: calc((80 / 1024vw ) * 100);
              height: calc((80 / 1024vw ) * 100);
              margin-top: calc((-35 / 1024vw ) * 100); }
            .personal-guidance .range .range-container input[type='range']::-moz-range-track {
              height: calc((10 / 1024vw ) * 100); }
            .personal-guidance .range .range-container input[type='range']::-moz-range-thumb {
              width: calc((80 / 1024vw ) * 100);
              height: calc((80 / 1024vw ) * 100); }
            .personal-guidance .range .range-container input[type='range']::-ms-track {
              height: calc((10 / 1024vw ) * 100);
              border-width: 40px; }
            .personal-guidance .range .range-container input[type='range']::-ms-thumb {
              width: 80px;
              height: 80px;
              margin-top: 5px; }
          .personal-guidance .range .range-container output {
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc((80 / 1024vw ) * 100);
            height: calc((80 / 1024vw ) * 100);
            font-size: calc((28 / 1024vw ) * 100);
            line-height: calc((80 / 1024vw ) * 100);
            border-width: 0; }
            .personal-guidance .range .range-container output.one {
              position: absolute;
              top: 50%;
              right: auto;
              bottom: auto;
              left: 0;
              transform: translateY(-50%); }
            .personal-guidance .range .range-container output.three {
              position: absolute;
              top: 50%;
              right: 0;
              bottom: auto;
              left: auto;
              transform: translateY(-50%); }
        .personal-guidance .range .btn-increase, .personal-guidance .range .btn-decrease {
          position: relative;
          top: 0;
          width: 15%;
          margin: calc((100 / 1024vw ) * 100) 0 calc((140 / 1024vw ) * 100);
          padding: 0.5em 0.75em;
          vertical-align: middle; }
        .personal-guidance .range .btn-decrease {
          text-align: right; }
        .personal-guidance .range .btn-increase {
          text-align: left; } }
  @media (min-width: 1024px) {
    .personal-guidance {
      height: 930px; }
      .personal-guidance header {
        margin: 180px auto 0; }
      .personal-guidance .feature .container > .content, .personal-guidance .feature .form-factory > .content {
        margin-top: 240px; }
      .personal-guidance .container-circle {
        margin-top: -70px; }
      .personal-guidance .form-check-inline .form-check-label picture {
        width: 170px;
        height: 170px; }
      .personal-guidance .range .range-container {
        max-width: 640px;
        margin: 100px 0 140px 0; }
        .personal-guidance .range .range-container input[type='range']::-webkit-slider-runnable-track {
          height: 10px; }
        .personal-guidance .range .range-container input[type='range']::-webkit-slider-thumb {
          width: 80px;
          height: 80px;
          margin-top: -35px; }
        .personal-guidance .range .range-container input[type='range']::-webkit-slider-runnable-track {
          height: 10px; }
        .personal-guidance .range .range-container input[type='range']::-moz-range-track {
          height: 10px; }
        .personal-guidance .range .range-container input[type='range']::-moz-range-thumb {
          width: 80px;
          height: 80px; }
        .personal-guidance .range .range-container input[type='range']::-ms-track {
          height: 10px;
          border-width: 40px 0; }
        .personal-guidance .range .range-container input[type='range']::-ms-thumb {
          width: 80px;
          height: 80px;
          margin-top: 5px; }
        .personal-guidance .range .range-container output {
          width: 80px;
          height: 80px;
          font-size: 28px;
          line-height: 80px; }
      .personal-guidance .range .btn-increase, .personal-guidance .range .btn-decrease {
        margin: 100px 0 148px; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.personal-guidance-result {
  margin-top: calc((-370 / 640vw ) * 100); }
  .personal-guidance-result figure {
    display: inline-block;
    width: calc((457 / 640vw ) * 100);
    height: calc((457 / 640vw ) * 100);
    margin: 0 auto 2em; }
    .personal-guidance-result figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%; }
      .personal-guidance-result figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .personal-guidance-result .btn {
    margin: 0.5em 0 1em; }
  .personal-guidance-result footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    font-size: 1.75rem;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e1e1e1; }
    @media (min-width: 640px) {
      .personal-guidance-result footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .personal-guidance-result footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .personal-guidance-result footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .personal-guidance-result footer .icon-svg {
      width: 1.2em;
      height: 1.2em;
      margin-left: 5px;
      fill: #007697; }
  @media (min-width: 640px) {
    .personal-guidance-result {
      margin-top: -370px; }
      .personal-guidance-result figure {
        width: 457px;
        height: 457px; } }
  @media (min-width: 768px) {
    .personal-guidance-result {
      margin-top: calc((-340 / 1024vw ) * 100); }
      .personal-guidance-result figure {
        width: calc((457 / 1024vw ) * 100);
        height: calc((457 / 1024vw ) * 100); }
      .personal-guidance-result footer {
        font-size: 1rem; } }
  @media (min-width: 1024px) {
    .personal-guidance-result {
      margin-top: -340px; }
      .personal-guidance-result figure {
        width: 457px;
        height: 457px; } }

.personal-guidance-result-detail footer .btn {
  display: block;
  margin: 1em; }

.personal-guidance-result-detail footer .btn-restart {
  text-decoration: underline; }
  .personal-guidance-result-detail footer .btn-restart .icon-svg {
    width: calc((40 / 640vw ) * 100);
    height: calc((40 / 640vw ) * 100); }
    .personal-guidance-result-detail footer .btn-restart .icon-svg svg {
      fill: #007697; }

@media (min-width: 640px) {
  .personal-guidance-result-detail footer .btn-restart .icon-svg {
    width: 40px;
    height: 40px; } }

@media (min-width: 768px) {
  .personal-guidance-result-detail footer .btn {
    display: inline-block;
    margin: 1em 0.5em; }
  .personal-guidance-result-detail footer .btn-restart .icon-svg {
    width: calc((20 / 640vw ) * 100);
    height: calc((20 / 640vw ) * 100); }
    .personal-guidance-result-detail footer .btn-restart .icon-svg svg {
      fill: #007697; } }

@media (min-width: 1024px) {
  .personal-guidance-result-detail footer .btn-restart .icon-svg {
    width: 20px;
    height: 20px; } }

.personal-guidance-activity {
  display: flex;
  flex-wrap: wrap;
  margin-right: -9.5px;
  margin-left: -9.5px;
  margin: 0 0 calc((85 / 640vw ) * 100) 0;
  justify-content: center; }
  .personal-guidance-activity figure {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0 calc((45 / 640vw ) * 100) calc((35 / 640vw ) * 100) calc((45 / 640vw ) * 100); }
    .personal-guidance-activity figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%; }
      .personal-guidance-activity figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .personal-guidance-activity .content {
    position: relative;
    width: 100%;
    padding-right: 9.5px;
    padding-left: 9.5px;
    display: block;
    text-align: center;
    margin-right: calc((50 / 640vw ) * 100);
    margin-left: calc((50 / 640vw ) * 100); }
    .personal-guidance-activity .content footer {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem; }
      @media (min-width: 640px) {
        .personal-guidance-activity .content footer {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .personal-guidance-activity .content footer {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .personal-guidance-activity .content footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .personal-guidance-activity.layout-1 figure picture {
    padding-bottom: 75%; }
  .personal-guidance-activity.layout-2 figure {
    width: calc((400 / 640vw ) * 100); }
    .personal-guidance-activity.layout-2 figure picture {
      padding-bottom: 100%; }
  .personal-guidance-activity.layout-3 figure picture {
    padding-bottom: 50%; }
  .personal-guidance-activity.layout-4 figure {
    width: calc((400 / 640vw ) * 100); }
    .personal-guidance-activity.layout-4 figure picture {
      padding-bottom: 100%; }
      .personal-guidance-activity.layout-4 figure picture.accent {
        position: absolute;
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
        width: calc((200 / 640vw ) * 100);
        height: calc((200 / 640vw ) * 100);
        right: calc((-100 / 640vw ) * 100);
        margin-bottom: calc((-100 / 640vw ) * 100);
        padding: 0; }
  .personal-guidance-activity.layout-5 figure picture {
    padding-bottom: 60%; }
  .personal-guidance-activity.layout-6 figure picture {
    padding-bottom: 75%; }
  .personal-guidance-activity:last-child {
    margin-bottom: calc((160 / 1024vw ) * 100); }
  @media (min-width: 640px) {
    .personal-guidance-activity {
      margin: 0 0 85px 0; }
      .personal-guidance-activity figure {
        margin: 0 45px 35px 45px; }
      .personal-guidance-activity .content {
        margin-right: 50px;
        margin-left: 50px; } }
  @media (min-width: 768px) {
    .personal-guidance-activity figure {
      margin: 0;
      padding: 0; }
    .personal-guidance-activity .content {
      display: inline-block;
      margin-right: calc((40 / 1024vw ) * 100);
      margin-left: calc((40 / 1024vw ) * 100);
      text-align: left;
      align-self: center; }
    .personal-guidance-activity.layout-1 figure {
      width: calc((390 / 1024vw ) * 100); }
      .personal-guidance-activity.layout-1 figure picture {
        padding-bottom: 75%; }
    .personal-guidance-activity.layout-1 .content {
      width: 50%;
      max-width: calc((360 / 1024vw ) * 100); }
    .personal-guidance-activity.layout-2 figure {
      left: calc((420 / 1024vw ) * 100);
      width: calc((245 / 1024vw ) * 100); }
      .personal-guidance-activity.layout-2 figure picture {
        padding-bottom: 100%; }
    .personal-guidance-activity.layout-2 .content {
      right: calc((245 / 1024vw ) * 100);
      width: calc((420 / 1024vw ) * 100);
      text-align: right; }
    .personal-guidance-activity.layout-3 figure {
      flex: 0 0 50%;
      max-width: 50%; }
      .personal-guidance-activity.layout-3 figure picture {
        padding-bottom: 50%; }
    .personal-guidance-activity.layout-3 .content {
      flex: 0 0 50%;
      max-width: 50%;
      max-width: calc((360 / 1024vw ) * 100);
      text-align: left; }
    .personal-guidance-activity.layout-4 figure {
      width: calc((245 / 1024vw ) * 100); }
      .personal-guidance-activity.layout-4 figure picture {
        padding-bottom: 100%; }
        .personal-guidance-activity.layout-4 figure picture.accent {
          position: absolute;
          top: auto;
          right: 0;
          bottom: 0;
          left: auto;
          top: calc((-100 / 1024vw ) * 100);
          right: calc((-100 / 1024vw ) * 100);
          width: calc((200 / 1024vw ) * 100);
          height: calc((200 / 1024vw ) * 100); }
    .personal-guidance-activity.layout-4 .content {
      width: 70%;
      max-width: calc((500 / 1024vw ) * 100);
      text-align: left; }
    .personal-guidance-activity.layout-5 figure {
      width: calc((390 / 1024vw ) * 100); }
      .personal-guidance-activity.layout-5 figure picture {
        padding-bottom: 60%; }
    .personal-guidance-activity.layout-5 .content {
      width: 50%;
      max-width: calc((480 / 1024vw ) * 100);
      text-align: right; }
    .personal-guidance-activity.layout-6 figure {
      width: calc((390 / 1024vw ) * 100); }
      .personal-guidance-activity.layout-6 figure picture {
        padding-bottom: 75%; }
    .personal-guidance-activity.layout-6 .content {
      width: 50%;
      max-width: calc((480 / 1024vw ) * 100);
      text-align: right; }
    .personal-guidance-activity:last-child {
      margin-bottom: calc((160 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .personal-guidance-activity {
      margin: 0 0 80px 0; }
      .personal-guidance-activity .content {
        margin-right: 40px;
        margin-left: 40px; }
      .personal-guidance-activity.layout-1 figure {
        width: 390px; }
        .personal-guidance-activity.layout-1 figure picture {
          padding-bottom: 75%; }
      .personal-guidance-activity.layout-1 .content {
        width: 50%;
        max-width: 360px; }
      .personal-guidance-activity.layout-2 figure {
        left: 500px;
        width: 245px; }
      .personal-guidance-activity.layout-2 .content {
        right: 245px;
        width: 420px; }
      .personal-guidance-activity.layout-3 .content {
        max-width: 360px; }
      .personal-guidance-activity.layout-4 figure {
        width: 245px; }
        .personal-guidance-activity.layout-4 figure picture.accent {
          position: absolute;
          top: auto;
          right: 0;
          bottom: 0;
          left: auto;
          top: 100px;
          right: -100px;
          width: 200px;
          height: 200px; }
      .personal-guidance-activity.layout-4 .content {
        max-width: 500px; }
      .personal-guidance-activity.layout-5 figure {
        left: 540px;
        width: 390px; }
      .personal-guidance-activity.layout-5 .content {
        right: 330px;
        width: 400px; }
      .personal-guidance-activity.layout-6 figure {
        width: 390px;
        left: 540px; }
        .personal-guidance-activity.layout-6 figure picture {
          padding-bottom: 75%; }
      .personal-guidance-activity.layout-6 .content {
        right: 330px;
        width: 400px; }
      .personal-guidance-activity:last-child {
        margin-bottom: 160px; } }

.personality-focus figure {
  float: left;
  width: 51%;
  padding: 0 20px; }

@media (min-width: 768px) {
  .personality-focus figure {
    width: 42%;
    margin-right: 24px; } }

.pov .container header, .pov .form-factory header {
  position: relative;
  display: inline-block;
  margin-bottom: 25px; }
  .pov .container header picture, .pov .form-factory header picture {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: calc((250 / 640vw ) * 100);
    height: calc((250 / 640vw ) * 100);
    margin: 0 auto;
    overflow: hidden; }
    .pov .container header picture img, .pov .form-factory header picture img {
      width: 100%;
      height: 100%; }
  .pov .container header:before, .pov .form-factory header:before {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((56 / 640vw ) * 100);
    line-height: calc((72 / 640vw ) * 100);
    letter-spacing: calc((5 / 640vw ) * 100);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    position: absolute;
    top: calc((-125 / 640vw ) * 100);
    left: calc((-160 / 640vw ) * 100);
    color: #2b2b2b;
    font-size: calc((100 / 640vw ) * 100);
    content: "“";
    speak: none; }
    @media (min-width: 640px) {
      .pov .container header:before, .pov .form-factory header:before {
        font-size: 56px;
        font-size: 3.5rem;
        line-height: 72px;
        letter-spacing: 5px; } }
    @media (min-width: 768px) {
      .pov .container header:before, .pov .form-factory header:before {
        font-size: calc((40 / 640vw ) * 100);
        line-height: calc((56 / 640vw ) * 100);
        letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .pov .container header:before, .pov .form-factory header:before {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 56px;
      letter-spacing: 5.25px; } }
    .pov .container header:before mark, .pov .form-factory header:before mark {
      padding: 0 0.25em 0 0.45em;
      background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
      box-decoration-break: clone; }
      @media screen and (min-width: 0\0) {
        .pov .container header:before mark, .pov .form-factory header:before mark {
          padding: 0; } }
      @supports (-ms-ime-align: auto) {
        .pov .container header:before mark, .pov .form-factory header:before mark {
          padding: 0; } }

.pov .container.container-inset, .pov .container-inset.form-factory {
  padding-top: calc((160 / 640vw ) * 100); }
  .pov .container.container-inset header, .pov .container-inset.form-factory header {
    position: absolute;
    margin-bottom: 0; }
  .pov .container.container-inset .body, .pov .container-inset.form-factory .body {
    padding: 0 calc((17 / 640vw ) * 100); }

@media (min-width: 640px) {
  .pov .container header picture, .pov .form-factory header picture {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    overflow: hidden; }
  .pov .container header:before, .pov .form-factory header:before {
    top: -125px;
    left: -160px;
    font-size: 100px; }
  .pov .container.container-inset, .pov .container-inset.form-factory {
    padding-top: 160px; }
    .pov .container.container-inset .body, .pov .container-inset.form-factory .body {
      padding: 0 17px; } }

@media (min-width: 768px) {
  .pov .container header picture, .pov .form-factory header picture {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 133px;
    height: 133px;
    margin: 0 auto;
    overflow: hidden; }
  .pov .container header:before, .pov .form-factory header:before {
    top: -75px;
    left: -100px;
    font-size: 80px; }
  .pov .container.container-inset, .pov .container-inset.form-factory {
    padding-top: 100px; } }

.press-listing {
  height: 100%;
  text-align: left; }
  .press-listing footer {
    margin-top: auto; }
  .press-listing .lazyloaded {
    transition: none; }

.press-navbar {
  margin-top: calc((50 / 640vw ) * 100); }
  .press-navbar ul {
    border-top: 1px solid #e1e1e1; }
  .press-navbar li a {
    position: relative;
    margin-bottom: 0;
    padding: calc((30 / 640vw ) * 100); }
  .press-navbar .icon-svg {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }
  @media (min-width: 768px) {
    .press-navbar {
      margin-top: 25px; }
      .press-navbar li a {
        padding: 15px 30px 15px 0; } }

.price-card {
  max-width: 498px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 20px;
  border-color: #c7c7c7;
  border-bottom: 8px solid #3cc1cc;
  flex-wrap: wrap;
  scroll-margin-top: 200px; }
  .price-card .headline-1 {
    margin: calc((-40 / 640vw ) * 100) auto 0;
    padding: 0 15px;
    background-color: #ffffff;
    letter-spacing: 0; }
  .price-card .headline-3 {
    display: none;
    margin-top: 28px; }
  .price-card .headline-5 {
    display: none; }
  .price-card .headline-7 {
    margin-bottom: 15px; }
  .price-card a:not([data-action="book"]) {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    display: none;
    margin-bottom: calc((25 / 640vw ) * 100);
    text-decoration: underline; }
    @media (min-width: 640px) {
      .price-card a:not([data-action="book"]) {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .price-card a:not([data-action="book"]) {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .price-card a:not([data-action="book"]) {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .price-card a:not([data-action="book"]):hover {
      color: #007697; }
  .price-card .avail-msg {
    font-weight: 500;
    padding: 10px;
    font-size: 14px;
    background-color: #c6c6c6;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 224px;
    margin: auto; }
  .price-card .avail-closed-msg {
    padding: 10px;
    font-size: 14px;
    background-color: #c6c6c6;
    text-align: center;
    max-width: 224px;
    margin: auto; }
  .price-card .js-avail-buttons .btn {
    margin-top: 1rem; }
  .price-card ul {
    margin: calc((20 / 640vw ) * 100) auto;
    padding: 0 calc((20 / 640vw ) * 100); }
    .price-card ul li {
      margin-bottom: calc((15 / 640vw ) * 100); }
      .price-card ul li:last-child {
        margin: 4px; }
    .price-card ul .icon-svg {
      margin-right: 5px; }
  .price-card.sold-out .headline-1,
  .price-card.sold-out .headline-7,
  .price-card.sold-out .js-avail-closed-msg {
    display: none; }
  .price-card.sold-out .headline-3,
  .price-card.sold-out .headline-5,
  .price-card.sold-out a {
    display: block; }
  .price-card.sold-out ul {
    opacity: 0.25; }
  @media (min-width: 768px) {
    .price-card {
      max-width: none;
      margin-top: 0;
      border-bottom: 6px solid #3cc1cc;
      scroll-margin-top: 170px; }
      .price-card .headline-1 {
        margin-top: -26px;
        min-width: 110px;
        line-height: 50px; } }
    @media screen and (min-width: 768px) and (min-width: 0\0) {
      .price-card .headline-1 {
        width: 160px; } }
  @media (min-width: 768px) {
        @supports (-ms-ime-align: auto) {
          .price-card .headline-1 {
            width: 160px; } }
      .price-card a:not([data-action="book"]) {
        margin-bottom: 25px; }
      .price-card ul {
        margin: 0 auto;
        padding: 0 20px; }
        .price-card ul li {
          margin-bottom: 10px; }
      .price-card.sold-out ul li {
        margin-bottom: 4px; }
      .price-card .text-muted {
        line-height: 0.6; } }
  @media (min-width: 1024px) {
    .price-card {
      scroll-margin-top: 120px; } }

.price-card {
  max-width: 498px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 20px;
  border-color: #c7c7c7;
  border-bottom: 8px solid #3cc1cc;
  flex-wrap: wrap;
  scroll-margin-top: 200px; }
  .price-card .headline-1 {
    margin: calc((-40 / 640vw ) * 100) auto 0;
    padding: 0 15px;
    background-color: #ffffff;
    letter-spacing: 0; }
  .price-card .headline-3 {
    display: none;
    margin-top: 28px; }
  .price-card .headline-5 {
    display: none; }
  .price-card .headline-7 {
    margin-bottom: 15px; }
  .price-card a:not([data-action="book"]) {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    display: none;
    margin-bottom: calc((25 / 640vw ) * 100);
    text-decoration: underline; }
    @media (min-width: 640px) {
      .price-card a:not([data-action="book"]) {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .price-card a:not([data-action="book"]) {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .price-card a:not([data-action="book"]) {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .price-card a:not([data-action="book"]):hover {
      color: #007697; }
  .price-card .avail-msg {
    font-weight: 500;
    padding: 10px;
    font-size: 14px;
    background-color: #c6c6c6;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 224px;
    margin: auto; }
  .price-card .avail-closed-msg {
    padding: 10px;
    font-size: 14px;
    background-color: #c6c6c6;
    text-align: center;
    max-width: 224px;
    margin: auto; }
  .price-card .js-avail-buttons .btn {
    margin-top: 1rem; }
  .price-card ul {
    margin: calc((20 / 640vw ) * 100) auto;
    padding: 0 calc((20 / 640vw ) * 100); }
    .price-card ul li {
      margin-bottom: calc((15 / 640vw ) * 100); }
      .price-card ul li:last-child {
        margin: 4px; }
    .price-card ul .icon-svg {
      margin-right: 5px; }
  .price-card.sold-out .headline-1,
  .price-card.sold-out .headline-7,
  .price-card.sold-out .js-avail-closed-msg {
    display: none; }
  .price-card.sold-out .headline-3,
  .price-card.sold-out .headline-5,
  .price-card.sold-out a {
    display: block; }
  .price-card.sold-out ul {
    opacity: 0.25; }
  @media (min-width: 768px) {
    .price-card {
      max-width: none;
      margin-top: 0;
      border-bottom: 6px solid #3cc1cc;
      scroll-margin-top: 170px; }
      .price-card .headline-1 {
        margin-top: -26px;
        min-width: 110px;
        line-height: 50px; } }
    @media screen and (min-width: 768px) and (min-width: 0\0) {
      .price-card .headline-1 {
        width: 160px; } }
  @media (min-width: 768px) {
        @supports (-ms-ime-align: auto) {
          .price-card .headline-1 {
            width: 160px; } }
      .price-card a:not([data-action="book"]) {
        margin-bottom: 25px; }
      .price-card ul {
        margin: 0 auto;
        padding: 0 20px; }
        .price-card ul li {
          margin-bottom: 10px; }
      .price-card.sold-out ul li {
        margin-bottom: 4px; }
      .price-card .text-muted {
        line-height: 0.6; } }
  @media (min-width: 1024px) {
    .price-card {
      scroll-margin-top: 120px; } }

.promo-colored {
  padding: calc((30 / 640vw ) * 100);
  border: 3px solid #007697;
  background-color: rgba(191, 241, 255, 0.25); }
  .promo-colored h1 {
    margin-bottom: calc((40 / 640vw ) * 100); }
  .promo-colored figure {
    margin-bottom: calc((25 / 640vw ) * 100); }
  .promo-colored img {
    max-width: calc((235 / 640vw ) * 100); }
  .promo-colored .btn {
    width: 100%;
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .promo-colored {
      padding: 72px 15px; }
      .promo-colored h1 {
        margin-bottom: 20px; }
      .promo-colored figure {
        margin-bottom: 10px; }
      .promo-colored img {
        max-width: 155px; }
      .promo-colored .btn {
        width: auto;
        min-width: 200px; } }

.promo-guidance, .promo-press {
  position: relative;
  padding: calc((165 / 640vw ) * 100) calc((30 / 640vw ) * 100);
  overflow: hidden;
  border: 0;
  background: none; }
  .promo-guidance.small, .small.promo-press {
    padding: calc((45 / 640vw ) * 100) calc((30 / 640vw ) * 100); }
  .promo-guidance .headline-2, .promo-press .headline-2 {
    position: relative;
    padding: 0 calc((40 / 640vw ) * 100);
    margin-bottom: calc((20 / 640vw ) * 100); }
  .promo-guidance h1, .promo-press h1 {
    position: relative;
    padding: 0 calc((40 / 640vw ) * 100);
    margin-bottom: calc((20 / 640vw ) * 100); }
  .promo-guidance figure, .promo-press figure {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .promo-guidance picture, .promo-press picture {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%; }
    .promo-guidance picture img, .promo-press picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 101%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center;'; }
  .promo-guidance .btn, .promo-press .btn {
    position: relative; }
  @media (min-width: 768px) {
    .promo-guidance, .promo-press {
      padding: 82px 15px; }
      .promo-guidance.small, .small.promo-press {
        padding: 52px 15px; }
      .promo-guidance h1, .promo-press h1 {
        padding: 0;
        margin-bottom: 10px; }
      .promo-guidance .headline-2, .promo-press .headline-2 {
        padding: 0;
        margin-bottom: 10px; } }

.promo-press {
  overflow: visible;
  margin: 2rem auto;
  background-color: #f2fbfc; }
  .promo-press.small {
    padding: 0 15px; }
  .promo-press .content {
    position: relative;
    padding: 40px 0;
    z-index: 1; }

.promo-simple {
  margin: calc((35 / 640vw ) * 100) calc((48 / 640vw ) * 100) calc((60 / 640vw ) * 100);
  padding: 0 calc((15 / 640vw ) * 100) calc((63 / 640vw ) * 100);
  border: 1px solid #e2e2e2; }
  .promo-simple a {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    text-decoration: underline; }
    @media (min-width: 640px) {
      .promo-simple a {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .promo-simple a {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .promo-simple a {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .promo-simple a:hover, .promo-simple a:focus {
      color: #007697;
      text-decoration: underline; }
  .promo-simple h1,
  .promo-simple p {
    margin-bottom: calc((15 / 640vw ) * 100); }
  .promo-simple figure {
    margin: calc((-22 / 640vw ) * 100) 0 calc((25 / 640vw ) * 100); }
  .promo-simple picture {
    position: relative;
    display: block;
    width: calc((250 / 640vw ) * 100);
    height: calc((250 / 640vw ) * 100); }
  .promo-simple img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 101%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover; object-position: center;'; }
  @media (min-width: 768px) {
    .promo-simple {
      margin: 0 0 2em 0;
      padding: 0 15px 20px; }
      .promo-simple h1,
      .promo-simple p {
        margin-bottom: 5px; }
      .promo-simple figure {
        margin: -6px 0 10px; }
      .promo-simple picture {
        width: 128px;
        height: 128px; } }

.promo-border {
  padding: calc((36 / 640vw ) * 100) calc((75 / 640vw ) * 100);
  border: 1px solid #c7c7c7;
  text-align: center; }
  .promo-border .icon-svg {
    display: block;
    width: calc((64 / 640vw ) * 100);
    height: calc((64 / 640vw ) * 100);
    margin: 0 auto calc((29 / 640vw ) * 100);
    vertical-align: -6px; }
  .promo-border .headline-3 {
    margin: calc((7 / 640vw ) * 100) 0 calc((29 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .promo-border {
      padding: 36px 38px;
      text-align: left; }
      .promo-border .headline-3 {
        margin: 7px 0 0; }
      .promo-border .icon-svg {
        float: left;
        width: 34px;
        height: 34px;
        margin: 0 25px 0 0; } }

.promo-assist .icon-svg {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  width: calc((59 / 640vw ) * 100);
  height: calc((59 / 640vw ) * 100);
  margin-top: calc((-30 / 640vw ) * 100); }

@media (min-width: 640px) {
  .promo-assist .icon-svg {
    width: 59px;
    height: 59px;
    margin-top: -30px; } }

@media (min-width: 768px) {
  .promo-assist .icon-svg {
    width: 39px;
    height: 39px;
    margin-top: -20px; } }

.promo-price {
  max-width: 498px;
  width: 100%;
  margin: calc((100 / 640vw ) * 100) auto 0;
  padding: 0 calc((35 / 640vw ) * 100) calc((40 / 640vw ) * 100);
  border: 1px solid #c7c7c7;
  border-bottom: 8px solid #3cc1cc;
  text-align: center; }
  .promo-price .icon-svg {
    width: calc((202 / 640vw ) * 100);
    height: calc((202 / 640vw ) * 100);
    margin: calc((-100 / 640vw ) * 100) auto calc((20 / 640vw ) * 100);
    padding: 0 calc((15 / 640vw ) * 100);
    background-color: #ffffff; }
    .promo-price .icon-svg svg {
      padding: 0 18px; }
  .promo-price .headline-5 {
    margin-bottom: calc((50 / 640vw ) * 100); }
    .promo-price .headline-5:last-child {
      margin-bottom: calc((15 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .promo-price {
      max-width: none;
      margin-top: 0;
      padding: 0 40px 20px;
      border-bottom: 6px solid #3cc1cc; }
      .promo-price .icon-svg {
        width: 145px;
        height: 145px;
        margin: -72px auto 15px;
        padding: 0 15px; }
        .promo-price .icon-svg svg {
          padding: 0 18px; }
      .promo-price .headline-5 {
        margin-bottom: 33px; }
        .promo-price .headline-5:last-child {
          margin-bottom: 20px; } }

.promo-grid {
  position: relative;
  margin: calc((110 / 640vw ) * 100) 0 calc((100 / 640vw ) * 100);
  padding: calc((252 / 640vw ) * 100) calc((46 / 640vw ) * 100) calc((62 / 640vw ) * 100);
  border: 1px solid #e1e1e1;
  text-align: center; }
  .promo-grid .col {
    position: static; }
  .promo-grid .image-1 {
    position: absolute;
    top: calc((-90 / 640vw ) * 100);
    left: calc((120 / 640vw ) * 100); }
    .promo-grid .image-1 picture {
      position: relative;
      overflow: hidden;
      width: calc((184 / 640vw ) * 100);
      height: calc((184 / 640vw ) * 100); }
      .promo-grid .image-1 picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .promo-grid .image-2 {
    position: absolute;
    top: calc((-53 / 640vw ) * 100);
    left: calc((267 / 640vw ) * 100); }
    .promo-grid .image-2 picture {
      position: relative;
      overflow: hidden;
      width: calc((248 / 640vw ) * 100);
      height: calc((248 / 640vw ) * 100); }
      .promo-grid .image-2 picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .promo-grid .headline-3 figure {
    display: inline-block;
    max-width: calc((77 / 640vw ) * 100);
    vertical-align: calc((4 / 640vw ) * 100);
    margin-bottom: 0; }
  .promo-grid p {
    margin-bottom: calc((30 / 640vw ) * 100); }
  .promo-grid.no-border {
    border: 0; }
  .promo-grid.reversed .image-1 {
    top: calc((-90 / 640vw ) * 100);
    left: calc((120 / 640vw ) * 100); }
  .promo-grid.reversed .image-2 {
    top: calc((-53 / 640vw ) * 100);
    left: calc((267 / 640vw ) * 100); }
  .promo-grid.reversed .content {
    margin-top: calc((204 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .promo-grid {
      margin: 0 0 80px;
      padding: 35px 10px 15px; }
      .promo-grid .image-1 {
        top: 100px;
        bottom: auto;
        left: 22px; }
        .promo-grid .image-1 picture {
          width: 105px;
          height: 105px; }
      .promo-grid .image-2 {
        top: -26px;
        left: 65px; }
        .promo-grid .image-2 picture {
          width: 141px;
          height: 141px; }
      .promo-grid .headline-3 figure {
        max-width: 57px;
        vertical-align: 3px; }
      .promo-grid p {
        margin-bottom: 4px; }
      .promo-grid .content {
        padding-left: 30px; }
      .promo-grid.reversed .image-1 {
        top: -34px;
        bottom: auto;
        left: 23px; }
      .promo-grid.reversed .image-2 {
        top: -16px;
        left: 85px; }
      .promo-grid.reversed .content {
        margin-top: 0; } }
  @media (min-width: 1024px) {
    .promo-grid .image-1 {
      left: 42px; }
    .promo-grid .image-2 {
      left: 85px; } }

.property-grid .fee-disclaimer {
  text-align: center;
  font-weight: 300;
  margin-bottom: -30px; }

.property-grid .slick-dots li {
  display: inline-block; }
  .property-grid .slick-dots li button {
    display: block; }

.property-grid .property-grid-item {
  position: relative;
  display: block;
  width: 100%;
  margin: 60px 0 0;
  text-align: left; }
  .property-grid .property-grid-item > .row {
    height: 100%; }
  .property-grid .property-grid-item picture {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    height: calc((200 / 640vw ) * 100);
    margin-bottom: 1.5em;
    vertical-align: top; }
    .property-grid .property-grid-item picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }
  .property-grid .property-grid-item .content {
    position: relative;
    display: inline-block;
    width: calc((325 / 640vw ) * 100);
    margin-left: 15px;
    vertical-align: top; }
    .property-grid .property-grid-item .content .headline-5 {
      width: calc(100% - 1.3em);
      margin: 0 0 0.25em 0; }
    .property-grid .property-grid-item .content .category {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      display: block;
      margin: 0 0 8px 0; }
      @media (min-width: 640px) {
        .property-grid .property-grid-item .content .category {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .property-grid .property-grid-item .content .category {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .property-grid .property-grid-item .content .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .property-grid .property-grid-item .content .list-unstyled {
      margin: 0 0 0.25em 0; }
    .property-grid .property-grid-item .content .trip-advisor {
      margin-bottom: 10px; }
      .property-grid .property-grid-item .content .trip-advisor h2 {
        margin-bottom: 0.5em; }
      .property-grid .property-grid-item .content .trip-advisor .logo {
        display: inline-block;
        width: calc((50 / 640vw ) * 100); }
      .property-grid .property-grid-item .content .trip-advisor small {
        font-weight: 300; }
  .property-grid .property-grid-item .btn-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    top: 11px;
    right: 12px;
    width: calc((48 / 640vw ) * 100);
    height: calc((48 / 640vw ) * 100);
    margin: 0.25em;
    padding: 0;
    z-index: 100; }
    .property-grid .property-grid-item .btn-overlay .icon-svg {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px; }
      .property-grid .property-grid-item .btn-overlay .icon-svg svg {
        fill: #2b2b2b; }
      .property-grid .property-grid-item .btn-overlay .icon-svg.heart-filled svg {
        fill: #007697; }
    .property-grid .property-grid-item .btn-overlay:hover svg {
      fill: #3cc1cc; }
  .property-grid .property-grid-item footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc((70 / 640vw ) * 100);
    background-color: #f7f7f7;
    border-bottom: 2px solid #dedede; }
    .property-grid .property-grid-item footer .price {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((36 / 640vw ) * 100);
      line-height: calc((37 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.55em;
      display: inline-block;
      float: right;
      margin: 0 1em;
      line-height: calc((70 / 640vw ) * 100); }
      @media (min-width: 640px) {
        .property-grid .property-grid-item footer .price {
          font-size: 36px;
          font-size: 2.25rem;
          line-height: 37px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .property-grid .property-grid-item footer .price {
          font-size: calc((20 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .property-grid .property-grid-item footer .price {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .property-grid .property-grid-item footer .price-load {
      height: 40px;
      width: 40px; }
      .property-grid .property-grid-item footer .price-load .loading {
        font-size: 4rem;
        line-height: 0px;
        letter-spacing: -7px; }
      .property-grid .property-grid-item footer .price-load .loading span {
        animation-name: blink;
        animation-duration: 0.9s;
        animation-iteration-count: infinite;
        animation-fill-mode: both; }
      .property-grid .property-grid-item footer .price-load .loading span:nth-child(2) {
        animation-delay: .1s; }
      .property-grid .property-grid-item footer .price-load .loading span:nth-child(3) {
        animation-delay: .2s; }

@keyframes blink {
  0% {
    opacity: .2; }
  80% {
    opacity: 1; }
  100% {
    opacity: .2; } }
    .property-grid .property-grid-item footer small {
      font-weight: 300; }
    .property-grid .property-grid-item footer .avail-msg {
      font-size: .75rem;
      letter-spacing: .5px;
      font-weight: 500;
      text-align: center;
      padding: 10px;
      background-color: #c6c6c6; }
    .property-grid .property-grid-item footer .avail-closed-msg {
      font-size: .75rem;
      letter-spacing: .5px;
      text-align: center;
      padding: 10px;
      background-color: #c6c6c6; }
  .property-grid .property-grid-item .property-box {
    display: flex;
    position: relative;
    height: 100%;
    margin: 0 20px;
    padding: 25px 25px 33px;
    border: solid 1px #c7c7c7;
    flex-wrap: nowrap; }
    .property-grid .property-grid-item .property-box .btn.d-block {
      margin-top: -1px; }
      .property-grid .property-grid-item .property-box .btn.d-block[disabled], .property-grid .property-grid-item .property-box .btn.d-block.disabled {
        background-color: #c6c6c6;
        color: #525252; }
    .property-grid .property-grid-item .property-box picture {
      margin-bottom: 0; }
    .property-grid .property-grid-item .property-box .rating-count a {
      font-size: 12px; }
    .property-grid .property-grid-item .property-box .content {
      width: 50%; }
      .property-grid .property-grid-item .property-box .content .trip-advisor {
        margin-top: 8px;
        align-self: flex-start;
        margin-left: 0; }
    .property-grid .property-grid-item .property-box .category {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      gap: 5px; }
      @media (min-width: 768px) {
        .property-grid .property-grid-item .property-box .category {
          justify-content: left; } }
    .property-grid .property-grid-item .property-box .price-area {
      display: block;
      background: white;
      padding: 0 14px; }
      .property-grid .property-grid-item .property-box .price-area .booking-text {
        display: inline-block;
        width: 33.0625vw;
        font-size: 4.0625vw;
        line-height: 5.0625vw;
        margin-top: 0.0625vw;
        font-weight: bold;
        text-align: center; }
      .property-grid .property-grid-item .property-box .price-area .original-price {
        text-decoration: line-through;
        color: #757575;
        font-size: 36px;
        line-height: 40px;
        vertical-align: middle;
        margin-right: 7px; }
      .property-grid .property-grid-item .property-box .price-area .offer-price {
        margin-right: 5px;
        font-size: 10.0625vw;
        line-height: 40px;
        vertical-align: middle;
        text-decoration: none; }
      .property-grid .property-grid-item .property-box .price-area .price-text {
        display: inline-block;
        width: 11.0625vw;
        margin-left: 0.0625vw;
        font-size: 4.0625vw;
        line-height: 4.0625vw;
        letter-spacing: .5px;
        word-break: break-word;
        text-align: left;
        vertical-align: middle; }
    .property-grid .property-grid-item .property-box footer {
      width: auto;
      bottom: -5vw;
      right: 10px;
      border-bottom: 0;
      background: none; }
    @media (min-width: 768px) {
      .property-grid .property-grid-item .property-box {
        margin: 0;
        padding: 15px 15px 40px; }
        .property-grid .property-grid-item .property-box .price-area .booking-text {
          margin-top: 0;
          width: 144px;
          font-size: 14px;
          line-height: 16px; }
        .property-grid .property-grid-item .property-box .price-area .original-price {
          font-size: 22px;
          line-height: 24px; }
        .property-grid .property-grid-item .property-box .price-area .offer-price {
          font-size: 30px;
          line-height: 24px; }
        .property-grid .property-grid-item .property-box .price-area .price-text {
          width: 30px;
          font-size: 12px;
          line-height: 14px; }
        .property-grid .property-grid-item .property-box footer {
          bottom: -28px; } }
    .property-grid .property-grid-item .property-box .slick-dots li {
      display: none; }
      .property-grid .property-grid-item .property-box .slick-dots li button {
        display: none; }

.property-grid .slick-track,
.property-grid .slick-list {
  display: flex;
  flex: 1 0 auto; }
  .property-grid .slick-track .carousel-slide > .row,
  .property-grid .slick-list .carousel-slide > .row {
    height: 100%; }

.property-grid .carousel-slide {
  padding: 0; }
  .property-grid .carousel-slide .property-grid-item {
    margin-bottom: 30px; }
    .property-grid .carousel-slide .property-grid-item .rating-count {
      display: block; }
  @media (min-width: 640px) {
    .property-grid .carousel-slide .property-grid-item {
      margin-bottom: 0; } }
  @media (min-width: 1024px) {
    .property-grid .carousel-slide .travelLogRow {
      margin-right: 0;
      margin-left: 0; } }

@media (min-width: 640px) {
  .property-grid .property-grid-item {
    margin: 45px 20px 0 20px; }
    .property-grid .property-grid-item picture {
      width: 100%;
      height: 200px; }
    .property-grid .property-grid-item .content {
      width: 325px;
      margin-left: calc((15 / 640vw ) * 100); }
      .property-grid .property-grid-item .content .trip-advisor .logo {
        width: 50px; }
    .property-grid .property-grid-item .btn-overlay {
      width: 48px;
      height: 48px;
      margin: 0;
      padding: 0; }
      .property-grid .property-grid-item .btn-overlay .icon-svg {
        width: 28px;
        height: 28px; }
    .property-grid .property-grid-item footer {
      height: 70px; }
      .property-grid .property-grid-item footer .price {
        line-height: 70px; } }

@media (min-width: 768px) {
  .property-grid .property-grid-item {
    display: inline-block;
    width: calc((470 / 1024vw ) * 100);
    margin: calc((45 / 1024vw ) * 100) calc((12 / 1024vw ) * 100) 0; }
    .property-grid .property-grid-item picture {
      width: calc((225 / 1024vw ) * 100);
      height: calc((180 / 1024vw ) * 100); }
    .property-grid .property-grid-item .content {
      position: relative;
      height: 100%;
      width: calc((220 / 1024vw ) * 100);
      margin-left: calc((15 / 1024vw ) * 100);
      vertical-align: top;
      display: inline-flex;
      flex-direction: column; }
      .property-grid .property-grid-item .content .headline-5 {
        margin: 0; }
      .property-grid .property-grid-item .content .trip-advisor {
        margin-top: auto;
        margin-left: 0;
        align-self: flex-end; }
        .property-grid .property-grid-item .content .trip-advisor h2 {
          margin: 0; }
        .property-grid .property-grid-item .content .trip-advisor .logo {
          width: calc((35 / 1024vw ) * 100);
          margin: 0; }
    .property-grid .property-grid-item .btn-overlay {
      top: 2px;
      right: 4px;
      margin: 0;
      padding: 0; }
      .property-grid .property-grid-item .btn-overlay .icon-svg {
        width: 36px;
        height: 36px; }
    .property-grid .property-grid-item footer {
      height: 40px; }
      .property-grid .property-grid-item footer .price {
        margin: 0 calc((15 / 1024vw ) * 100);
        line-height: 40px; }
  .property-grid .carousel-slide {
    padding: 0 0 calc((20 / 1024vw ) * 100) 0; }
    .property-grid .carousel-slide .property-grid-item {
      width: calc((400 / 1024vw ) * 100); }
      .property-grid .carousel-slide .property-grid-item picture {
        width: calc((190 / 1024vw ) * 100);
        height: calc((150 / 1024vw ) * 100); }
      .property-grid .carousel-slide .property-grid-item .content {
        width: calc((180 / 1024vw ) * 100); } }

@media (min-width: 1024px) {
  .property-grid .property-grid-item {
    display: inline-block;
    width: 470px;
    margin: 45px 5px 0; }
    .property-grid .property-grid-item picture {
      width: 225px;
      height: 180px; }
    .property-grid .property-grid-item .content {
      height: 100%;
      width: 220px;
      margin-left: 15px; }
      .property-grid .property-grid-item .content .headline-5 {
        margin: 0; }
      .property-grid .property-grid-item .content .trip-advisor .logo {
        width: 35px; }
    .property-grid .property-grid-item .btn-overlay {
      margin: 0;
      padding: 0; }
      .property-grid .property-grid-item .btn-overlay .icon-svg {
        width: 28px;
        height: 28px; }
    .property-grid .property-grid-item footer {
      height: 40px; }
      .property-grid .property-grid-item footer .price {
        margin: 0 15px;
        line-height: 40px; }
  .property-grid .carousel-slide {
    padding: 0 0 20px 0; }
    .property-grid .carousel-slide .property-grid-item {
      width: 400px; }
      .property-grid .carousel-slide .property-grid-item picture {
        width: 190px;
        height: 150px; }
      .property-grid .carousel-slide .property-grid-item .content {
        width: 180px; } }

@media (max-width: 1023.98px) {
  .zoom-enabled .property-grid .property-grid-item .property-box .content {
    position: static; }
    .zoom-enabled .property-grid .property-grid-item .property-box .content .trip-advisor {
      position: absolute;
      bottom: 10px;
      width: 200px;
      margin-left: -120px;
      margin-bottom: 30px; } }

.property-landing-sub-pages {
  margin: 3rem 0;
  padding: 3rem 0; }
  .property-landing-sub-pages figure {
    margin: 0; }
    .property-landing-sub-pages figure picture {
      display: block; }
  .property-landing-sub-pages p {
    padding: 0 20px; }
  @media (max-width: 767.98px) {
    .property-landing-sub-pages .headline-4 {
      margin-top: .55em; }
    .property-landing-sub-pages .btn-sm, .property-landing-sub-pages .btn-group-sm > .btn {
      display: block;
      padding: 0;
      text-decoration: underline;
      background-color: transparent;
      color: #2b2b2b;
      font-weight: 300; } }

.property-landing-slider {
  margin-bottom: calc((60 / 640vw ) * 100);
  padding: 0px 24px;
  text-align: left;
  z-index: 1; }
  .property-landing-slider .fee-disclaimer {
    width: 100%;
    font-weight: 300;
    text-align: center; }
  .property-landing-slider .slick-slider .slick-track,
  .property-landing-slider .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    transition-delay: 10ms; }
  .property-landing-slider .slick-slide figcaption {
    opacity: 0;
    padding-bottom: 12px; }
  .property-landing-slider figure {
    position: relative;
    margin: 0; }
    .property-landing-slider figure picture {
      display: block; }
  .property-landing-slider figcaption {
    margin-top: 0.5em;
    transition: opacity 1s; }
    .property-landing-slider figcaption .headline-5 {
      display: inline-block;
      margin-right: 10px; }
    .property-landing-slider figcaption .headline-6 {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem; }
      @media (min-width: 640px) {
        .property-landing-slider figcaption .headline-6 {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .property-landing-slider figcaption .headline-6 {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .property-landing-slider figcaption .headline-6 {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .property-landing-slider figcaption ul.list-inline li {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin: 0; }
      @media (min-width: 640px) {
        .property-landing-slider figcaption ul.list-inline li {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .property-landing-slider figcaption ul.list-inline li {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .property-landing-slider figcaption ul.list-inline li {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .property-landing-slider .slick-center figcaption {
    opacity: 1; }
  @media (min-width: 640px) {
    .property-landing-slider .fee-disclaimer {
      position: relative; } }
  @media (min-width: 768px) {
    .property-landing-slider {
      margin-bottom: 0;
      padding: 0;
      width: 100%; }
      .property-landing-slider .fee-disclaimer {
        position: absolute;
        bottom: 10px; }
      .property-landing-slider figure {
        margin: 0; }
      .property-landing-slider figcaption {
        padding-bottom: 0; } }

.property-map-slide {
  text-align: center; }

.property-map-slider article {
  position: relative;
  padding-bottom: calc((35 / 640vw ) * 100);
  text-align: center;
  overflow: hidden; }
  .property-map-slider article figure {
    position: relative;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .property-map-slider article figure img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }
    .property-map-slider article figure picture {
      height: 100%; }
  .property-map-slider article .content {
    width: 100%;
    text-align: center; }
    .property-map-slider article .content .headline-3 {
      width: 80%;
      margin: 0 auto;
      text-align: center; }

.property-map-slider .layout-1 .image-1 {
  display: none;
  top: calc((40 / 640vw ) * 100);
  left: calc((31 / 640vw ) * 100);
  width: calc((296 / 640vw ) * 100);
  height: calc((296 / 640vw ) * 100); }

.property-map-slider .layout-1 .image-2 {
  display: none;
  top: calc((10 / 640vw ) * 100);
  left: calc((342 / 640vw ) * 100);
  width: calc((192 / 640vw ) * 100);
  height: calc((192 / 640vw ) * 100); }

.property-map-slider .layout-1 .image-3 {
  display: none;
  top: calc((218 / 640vw ) * 100);
  left: calc((342 / 640vw ) * 100);
  width: calc((192 / 640vw ) * 100);
  height: calc((192 / 640vw ) * 100); }

.property-map-slider .figure-container {
  position: relative;
  height: calc((410 / 640vw ) * 100);
  margin-top: 1rem; }
  .property-map-slider .figure-container .image-1,
  .property-map-slider .figure-container .image-2,
  .property-map-slider .figure-container .image-3 {
    display: block; }

.property-map-slider .slick-prev {
  left: 0; }

.property-map-slider .slick-next {
  right: 0; }

.property-map-slider .slick-prev,
.property-map-slider .slick-next {
  top: calc((78 / 640vw ) * 100); }

@media (min-width: 768px) {
  .property-map-slider .carousel-factory {
    padding: 0; }
  .property-map-slider h1 {
    margin-bottom: 0.2rem; }
  .property-map-slider .figure-container {
    display: block; }
  .property-map-slider .layout-1 {
    height: calc((480 / 1024vw ) * 100);
    padding: 0 60px; }
    .property-map-slider .layout-1 .content {
      position: absolute;
      top: calc((20vw / 1024vw ) * 100);
      left: calc((378vw / 1024vw ) * 100);
      width: calc((307vw / 1024vw ) * 100);
      height: calc((186vw / 1024vw ) * 100);
      margin-top: 0;
      padding-top: 20px; }
    .property-map-slider .layout-1 .image-1 {
      display: block;
      top: calc((32vw / 1024vw ) * 100);
      left: calc((55vw / 1024vw ) * 100);
      width: calc((306vw / 1024vw ) * 100);
      height: calc((306vw / 1024vw ) * 100); }
    .property-map-slider .layout-1 .image-2 {
      display: block;
      top: calc((0 / 1024vw ) * 100);
      left: calc((0 / 1024vw ) * 100);
      width: calc((143vw / 1024vw ) * 100);
      height: calc((143vw / 1024vw ) * 100); }
    .property-map-slider .layout-1 .image-3 {
      display: block;
      top: calc((0 / 1024vw ) * 100);
      left: calc((161vw / 1024vw ) * 100);
      width: calc((143vw / 1024vw ) * 100);
      height: calc((143vw / 1024vw ) * 100); }
    .property-map-slider .layout-1 .image-2,
    .property-map-slider .layout-1 .image-3 {
      display: none; }
    .property-map-slider .layout-1 .figure-container .image-1 {
      display: none; }
    .property-map-slider .layout-1 .figure-container .image-2,
    .property-map-slider .layout-1 .figure-container .image-3 {
      display: block; } }

@media (min-width: 1024px) {
  .property-map-slider .layout-1 {
    height: 470px; }
    .property-map-slider .layout-1 .content {
      top: 20px;
      left: 378px;
      width: 307px;
      height: 186px; }
    .property-map-slider .layout-1 .image-1 {
      top: 32px;
      left: 55px;
      width: 306px;
      height: 306px; }
    .property-map-slider .layout-1 .image-2 {
      top: 0;
      left: 0;
      width: 143px;
      height: 143px; }
    .property-map-slider .layout-1 .image-3 {
      top: 0;
      left: 161px;
      width: 143px;
      height: 143px; } }

.property-navbar {
  background-color: #ffffff;
  width: 100%; }
  .property-navbar .btn-reservation {
    display: none; }
  .property-navbar .dropdown ul.dropdown-menu:not(.search-results) {
    border: 0; }
    .property-navbar .dropdown ul.dropdown-menu:not(.search-results).dropdown-item:active {
      background-color: #ffffff; }
  .property-navbar .property-navbar-collapse {
    max-height: 100vh;
    overflow-y: auto; }
  .property-navbar ul.property-navbar-nav li.dropdown a {
    display: flex;
    align-items: center;
    z-index: 1; }
    .property-navbar ul.property-navbar-nav li.dropdown a .icon-svg {
      top: auto !important;
      transform: rotate(90deg);
      transition: all 0.1s ease; }
    .property-navbar ul.property-navbar-nav li.dropdown a[aria-expanded="true"] .icon-svg {
      transform: rotate(-90deg); }
  .property-navbar .navbar-brand:not(.property-navbar-brand) {
    display: none !important; }
  .property-navbar.active .navbar-brand {
    display: none !important; }
    .property-navbar.active .navbar-brand:not(.property-navbar-brand) {
      display: flex !important; }
  @media (max-width: 1023.98px) {
    .property-navbar {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1001; }
      .property-navbar .menu-wrapper {
        height: 100%;
        padding-bottom: 12%;
        overflow: auto; }
      .property-navbar .close {
        margin: calc((28 / 640vw ) * 100) calc((28 / 640vw ) * 100) 0 0;
        font-size: 7.5vw;
        opacity: 1; }
      .property-navbar figure {
        min-height: calc((25 / 640vw ) * 100);
        max-width: calc((285 / 640vw ) * 100);
        margin: calc((45 / 640vw ) * 100) auto calc((35 / 640vw ) * 100); }
        .property-navbar figure a {
          border-bottom: 0; }
      .property-navbar .navbar-collapse a:not(.btn), .property-navbar .navbar-collapse .navbar-a {
        display: block;
        position: relative;
        padding: calc((40 / 640vw ) * 100) calc((50 / 640vw ) * 100);
        font-weight: 500;
        line-height: 3.4375vw; }
        .property-navbar .navbar-collapse a:not(.btn).collapsed .icon-svg, .property-navbar .navbar-collapse .navbar-a.collapsed .icon-svg {
          transform: rotate(0) translateY(-50%); }
        .property-navbar .navbar-collapse a:not(.btn) .icon-svg, .property-navbar .navbar-collapse .navbar-a .icon-svg {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
          right: calc((35 / 640vw ) * 100);
          width: calc((24 / 640vw ) * 100);
          height: calc((24 / 640vw ) * 100);
          transform: rotate(-180deg) translateY(50%);
          transition: transform 0.1s; }
      .property-navbar ul {
        margin-bottom: 0; }
        .property-navbar ul.list-separated.list-inline li {
          display: block; }
          .property-navbar ul.list-separated.list-inline li:before {
            display: none; }
      .property-navbar .dropdown-menu:not(.search-results) {
        display: block;
        position: relative;
        float: none;
        margin: 0 0 1em 0;
        min-width: 0; }
        .property-navbar .dropdown-menu:not(.search-results) .dropdown-item {
          padding: 0; }
          .property-navbar .dropdown-menu:not(.search-results) .dropdown-item a {
            font-weight: 300 !important;
            padding-left: 2em; }
        .property-navbar .dropdown-menu:not(.search-results).collapse {
          display: none; }
        .property-navbar .dropdown-menu:not(.search-results).collapse.show {
          display: block;
          z-index: 0; } }
  @media (min-width: 1024px) {
    .property-navbar {
      width: 100%;
      z-index: 998;
      max-width: 960.0px;
      min-height: 57px;
      margin-bottom: 65px;
      padding: 23px 35px;
      align-items: stretch !important;
      /*&.fixed-top.collapse:not(.resizing) {
			display: flex;
			position: fixed;
			top: 0;
			bottom: auto;
			right: 0;
			left: 0;
			height: 113px;
			z-index: 1002;
			transform: none;
			border-top: 1px solid $color-grey-border;
			border-bottom: 1px solid $color-grey-border;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			max-width: none;

			.menu-wrapper {
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: flex-end;
			}

			.headline {
				display: none !important;
			}

			// TODO: need to fix display override
			figure.d-lg-none {
				display: block !important;
				margin-bottom: 0;
				margin-left: 50px;
				margin-top: 2em;

				picture {
					max-width: 136px;
					padding: 0.75em;
				}
			}

			.btn-reservation {
				display: block;
				top: -1px;
				right: 0;
				margin: 0;
				padding-top: 57px;
			}

			.property-top-nav & {
				border: 0;
				position: initial;
				margin: 0;
				transition: height 0.1s;

				.btn-reservation {
					position: relative;
					top: -7px;
					padding: 57px 20px 53px;
				}
			}

			.property-top-nav.is-stuck & {
				height: 72px;

				.btn-reservation {
					top: 0;
					padding: 16px 20px 17px;
				}

				figure picture img {
					max-height: 70px;
				}
			}
		}*/ }
      .property-navbar ul.property-navbar-nav {
        display: flex;
        flex-grow: 1; }
        .property-navbar ul.property-navbar-nav li a .icon-svg {
          transform: rotate(0deg) !important; }
        .property-navbar ul.property-navbar-nav li.show a .icon-svg {
          transform: rotate(180deg) !important; }
      .property-navbar .navbar-collapse {
        display: none !important; }
      .property-navbar .btn-reservation {
        font-size: calc((17 / 640vw ) * 100);
        line-height: calc((18 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        width: 8rem;
        margin-top: 13px;
        padding: 43px 20px;
        background-color: #b8146a;
        white-space: normal; } }
    @media (min-width: 1024px) and (min-width: 640px) {
      .property-navbar .btn-reservation {
        font-size: 17px;
        font-size: 1.0625rem;
        line-height: 18px;
        letter-spacing: 0px; } }
  @media (min-width: 1024px) {
      .property-navbar .dropdown {
        position: relative !important; }
        .property-navbar .dropdown a {
          z-index: 1; }
          .property-navbar .dropdown a[aria-expanded="false"] .icon-svg {
            transform: rotate(0) translateY(-50%); }
        .property-navbar .dropdown ul.dropdown-menu:not(.search-results) {
          top: auto;
          left: 0;
          margin-top: 0;
          color: inherit;
          font-size: inherit;
          padding: 0.25em;
          white-space: normal;
          transition: all 0.1s ease;
          z-index: 0; }
          .property-navbar .dropdown ul.dropdown-menu:not(.search-results) li {
            padding: 0;
            font-weight: 300;
            border: 0; }
            .property-navbar .dropdown ul.dropdown-menu:not(.search-results) li:before {
              display: none; }
            .property-navbar .dropdown ul.dropdown-menu:not(.search-results) li a {
              display: block;
              color: #2b2b2b;
              padding: 3px 18px;
              line-height: 1.5em;
              font-weight: 300 !important; }
            .property-navbar .dropdown ul.dropdown-menu:not(.search-results) li.dropdown-item:active {
              background-color: #ffffff; }
        .property-navbar .dropdown .icon-svg {
          right: 5px;
          width: 9px;
          height: 9px;
          transform: rotate(-180deg) translateY(50%);
          transition: transform 0.1s; }
      .property-navbar.collapse {
        display: block; }
      .property-navbar .headline {
        display: inline-block;
        margin: 0; }
      .property-navbar ul.list-inline {
        display: inline-block;
        margin: auto;
        margin-top: 3rem;
        font-weight: 500;
        white-space: nowrap; }
        .property-navbar ul.list-inline li.list-inline-item {
          margin-right: 0; }
          .property-navbar ul.list-inline li.list-inline-item a {
            padding: 0.5em 1em; }
          .property-navbar ul.list-inline li.list-inline-item:before {
            margin: 0 0.25em; }
          .property-navbar ul.list-inline li.list-inline-item.active > a {
            font-weight: 600; }
          .property-navbar ul.list-inline li.list-inline-item:nth-child(2):before {
            display: none; }
          .property-navbar ul.list-inline li.list-inline-item.dropdown {
            position: relative; }
            .property-navbar ul.list-inline li.list-inline-item.dropdown a {
              margin-right: 0.75em; }
            .property-navbar ul.list-inline li.list-inline-item.dropdown.show .icon-svg {
              transform: rotate(-180deg); }
      .property-navbar.fixed-top:not(.resizing) {
        min-height: 57px;
        padding: 0; } }

.btn-property-navbar {
  position: relative;
  top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  color: #2b2b2b;
  margin-bottom: 0; }
  .btn-property-navbar .icon-svg {
    margin: 0 -0.5em 0 0.5em;
    fill: #2b2b2b;
    transition: transform 0.1s ease;
    width: 1em;
    height: 1em;
    transform: rotate(90deg); }
  .btn-property-navbar:not(.collapsed) .icon-svg {
    transform: rotate(-90deg); }
  .btn-property-navbar:hover .icon-svg, .btn-property-navbar:focus .icon-svg {
    fill: #ffffff; }
  @media (min-width: 640px) {
    .btn-property-navbar {
      font-size: 1.5em; } }

.property-top-nav.is-stuck {
  padding-top: 0; }
  .property-top-nav.is-stuck figure.d-lg-none {
    margin-top: 0 !important; }
  .property-top-nav.is-stuck ul.list-inline {
    margin-top: auto !important; }
  .property-top-nav.is-stuck .global-breadcrumb .menu-wrapper {
    display: none; }

.property-landing-overview {
  text-align: center; }
  .property-landing-overview .collapsing {
    height: calc((280 / 640vw ) * 100); }
  .property-landing-overview .content {
    margin-bottom: calc((40 / 640vw ) * 100); }
    .property-landing-overview .content .collapsible-btn {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem;
      text-decoration: underline; }
      @media (min-width: 640px) {
        .property-landing-overview .content .collapsible-btn {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .property-landing-overview .content .collapsible-btn {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .property-landing-overview .content .collapsible-btn {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .property-landing-overview .content footer .list-unstyled {
      margin: calc((35 / 640vw ) * 100) 0 calc((50 / 640vw ) * 100); }
  .property-landing-overview .image-footer {
    height: calc((564 / 640vw ) * 100);
    position: relative; }
  .property-landing-overview figure {
    position: absolute;
    margin: 0; }
    .property-landing-overview figure picture {
      position: relative;
      overflow: hidden;
      display: block;
      height: 100%; }
      .property-landing-overview figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
      .property-landing-overview figure picture img.lazyloading {
        opacity: 1; }
    .property-landing-overview figure .btn {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%); }
  .property-landing-overview .image-1 {
    left: calc((220 / 640vw ) * 100);
    width: calc((344 / 640vw ) * 100);
    height: calc((344 / 640vw ) * 100); }
  .property-landing-overview .image-2 {
    top: calc((226 / 640vw ) * 100);
    left: calc((64 / 640vw ) * 100);
    width: calc((143 / 640vw ) * 100);
    height: calc((143 / 640vw ) * 100); }
  .property-landing-overview .image-3 {
    top: calc((358 / 640vw ) * 100);
    left: calc((220 / 640vw ) * 100);
    width: calc((246 / 640vw ) * 100);
    height: calc((159 / 640vw ) * 100); }
  .property-landing-overview .image-4 {
    top: calc((70 / 640vw ) * 100);
    left: calc((64 / 640vw ) * 100);
    width: calc((143 / 640vw ) * 100);
    height: calc((143 / 640vw ) * 100); }
  .property-landing-overview .trip-advisor {
    border-top: 1px solid #e1e1e1;
    padding-top: 45px; }
    .property-landing-overview .trip-advisor p {
      margin-bottom: 0; }
    .property-landing-overview .trip-advisor picture {
      display: block;
      width: 42%;
      margin: 0 auto calc((30 / 640vw ) * 100); }
  @media (max-width: 767.98px) {
    .property-landing-overview .content {
      margin-bottom: 40px; }
      .property-landing-overview .content .collapsible:after {
        display: none; } }
  @media (max-width: 1023.98px) {
    .property-landing-overview .content ul.list-separated li:before {
      content: none; } }
  @media (min-width: 768px) {
    .property-landing-overview article {
      min-height: calc((389 / 1024vw ) * 100); }
    .property-landing-overview .collapsing {
      height: 168px; }
    .property-landing-overview .content {
      margin-bottom: 10px;
      padding-right: 80px; }
      .property-landing-overview .content footer .list-unstyled {
        margin: 10px 0; }
      .property-landing-overview .content .collapsible-btn {
        margin: 10px 0;
        padding: 0; }
    .property-landing-overview .image-footer {
      height: calc((147 / 1024vw ) * 100); }
    .property-landing-overview figure .btn .icon-svg {
      width: 5em;
      height: 5em; }
    .property-landing-overview .image-1 {
      top: 0;
      left: calc((9.5 / 1024vw ) * 100);
      width: calc((389 / 1024vw ) * 100);
      height: calc((389 / 1024vw ) * 100);
      padding-right: calc((8 / 1024vw ) * 100); }
    .property-landing-overview .image-2 {
      top: 0;
      left: 0;
      width: calc((147 / 1024vw ) * 100);
      height: calc((147 / 1024vw ) * 100); }
    .property-landing-overview .image-3 {
      top: 0;
      left: calc((164 / 1024vw ) * 100);
      width: calc((227 / 1024vw ) * 100);
      height: calc((147 / 1024vw ) * 100); }
    .property-landing-overview .image-4 {
      top: calc((-48 / 1024vw ) * 100);
      left: calc((408 / 1024vw ) * 100);
      width: calc((143 / 1024vw ) * 100);
      height: calc((143 / 1024vw ) * 100); }
    .property-landing-overview .trip-advisor {
      margin-top: 30px;
      padding-top: 20px; }
      .property-landing-overview .trip-advisor .headline-6 {
        display: inline-block;
        margin-right: 8px; }
      .property-landing-overview .trip-advisor .logo {
        height: 26px; }
      .property-landing-overview .trip-advisor .rating-count {
        font-size: 0.75rem;
        margin-right: 2px; }
      .property-landing-overview .trip-advisor p {
        display: inline-block;
        margin-right: 10px; }
      .property-landing-overview .trip-advisor picture {
        display: inline-block;
        margin-bottom: 0;
        width: auto;
        max-width: 128px; } }
  @media (min-width: 1024px) {
    .property-landing-overview article {
      min-height: 389px; }
    .property-landing-overview .image-footer {
      height: 147px; }
    .property-landing-overview .image-1 {
      left: 9.5px;
      width: 389px;
      height: 389px;
      padding-right: 8px; }
    .property-landing-overview .image-2 {
      width: 147px;
      height: 147px; }
    .property-landing-overview .image-3 {
      left: 164px;
      width: 227px;
      height: 147px; }
    .property-landing-overview .image-4 {
      top: -48px;
      left: 408px;
      width: 143px;
      height: 143px; } }

.property-toggle {
  text-align: center; }
  .property-toggle ul.list-inline li a {
    text-transform: lowercase; }
    .property-toggle ul.list-inline li a .icon-svg svg {
      fill: #d4d4d4; }
    .property-toggle ul.list-inline li a picture {
      opacity: 0.3; }
    .property-toggle ul.list-inline li a.active {
      font-weight: 500;
      color: #000000; }
      .property-toggle ul.list-inline li a.active .icon-svg svg {
        fill: #2b2b2b; }
      .property-toggle ul.list-inline li a.active picture {
        opacity: 1; }

.reservation-flyout .loader-bg {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.reservation-flyout .loader-text {
  font-weight: 400;
  font-size: 1rem;
  display: block;
  width: 60%;
  margin-top: 16px;
  line-height: 1.5;
  text-align: center; }

.reservation-flyout .modal-header {
  position: relative;
  padding: calc((32 / 640vw ) * 100) calc((35 / 640vw ) * 100) calc((32 / 640vw ) * 100) calc((45 / 640vw ) * 100); }
  .reservation-flyout .modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    margin: calc((40 / 640vw ) * 100);
    opacity: 1; }
    .reservation-flyout .modal-header .close .icon-svg {
      width: calc((32 / 640vw ) * 100);
      height: calc((32 / 640vw ) * 100); }

.reservation-flyout .modal-body, .reservation-flyout .modal-footer {
  padding: 0 calc((40 / 640vw ) * 100); }

.reservation-flyout form {
  height: 100%;
  overflow: visible; }
  .reservation-flyout form label {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em; }
    @media (min-width: 640px) {
      .reservation-flyout form label {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .reservation-flyout form label {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .reservation-flyout form label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  .reservation-flyout form fieldset {
    margin: 0 0 20px 0;
    padding: 0 0 0 calc((30 / 640vw ) * 100);
    border-left: calc((11 / 640vw ) * 100) solid #3cc1cc; }

.reservation-flyout .form-actions {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  width: 50px;
  display: inline-block;
  height: 100%;
  z-index: 2200; }
  .reservation-flyout .form-actions button {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    bottom: calc((22 / 640vw ) * 100);
    width: calc((48 / 640vw ) * 100);
    height: calc((48 / 640vw ) * 100);
    vertical-align: middle; }
    .reservation-flyout .form-actions button.btn-search {
      right: calc((96 / 640vw ) * 100);
      pointer-events: none; }
      .reservation-flyout .form-actions button.btn-search .icon-svg svg {
        fill: #000000; }
    .reservation-flyout .form-actions button.btn-close {
      display: none;
      right: 0;
      padding: 2px;
      border-radius: 100%;
      background-color: #007697; }
      .reservation-flyout .form-actions button.btn-close .icon-svg {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40%;
        height: 40%; }
        .reservation-flyout .form-actions button.btn-close .icon-svg svg {
          fill: #ffffff; }

.reservation-flyout .form-group {
  position: relative; }

.reservation-flyout .form-group-number {
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 0; }
  .reservation-flyout .form-group-number:last-of-type {
    border-bottom: none; }
  .reservation-flyout .form-group-number label {
    margin: 0;
    width: auto; }
  .reservation-flyout .form-group-number .input-group-number {
    margin: 0;
    padding: 0;
    width: auto; }
    .reservation-flyout .form-group-number .input-group-number button .icon-svg {
      border: 1px solid black;
      border-radius: 50%;
      width: 16px;
      height: 16px; }
    .reservation-flyout .form-group-number .input-group-number input[type="number"] {
      width: 1.5em;
      font-size: 1rem;
      letter-spacing: 0; }

.reservation-flyout .form-control[readonly] {
  position: initial;
  opacity: 1;
  width: 100%;
  background-color: inherit; }

.reservation-flyout .form-check {
  padding-left: 1.25rem; }

.reservation-flyout .btn-transparent {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  font-size: calc((26 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  display: inline-block;
  margin-bottom: 1rem;
  text-decoration: underline; }
  @media (min-width: 640px) {
    .reservation-flyout .btn-transparent {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 640px) {
    .reservation-flyout .btn-transparent {
      font-size: 26px;
      font-size: 1.625rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .reservation-flyout .btn-transparent {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-size: calc((12 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-flyout .btn-transparent {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-flyout .btn-transparent {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .reservation-flyout .btn-transparent.btn-back {
    text-decoration: none;
    padding: 0;
    margin: 0 0 calc((40 / 640vw ) * 100) 0; }
    .reservation-flyout .btn-transparent.btn-back .icon-svg {
      width: calc((28 / 640vw ) * 100);
      height: calc((28 / 640vw ) * 100);
      vertical-align: middle;
      margin: -1px 0 0 0; }
  .reservation-flyout .btn-transparent.btn-edit {
    display: none;
    float: right; }

.reservation-flyout ul.search-results, .reservation-flyout .faq ul.faq-search, .faq .reservation-flyout ul.faq-search {
  padding: 0; }
  .reservation-flyout ul.search-results li, .reservation-flyout .faq ul.faq-search li, .faq .reservation-flyout ul.faq-search li {
    list-style: none;
    display: block; }

.reservation-flyout ul.list-options {
  padding: 0; }
  .reservation-flyout ul.list-options li.form-check {
    margin: 1.25em 0 0 0; }
  .reservation-flyout ul.list-options .form-check-input {
    opacity: 0; }
    .reservation-flyout ul.list-options .form-check-input:focus + span {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color; }
    .reservation-flyout ul.list-options .form-check-input:checked + span {
      font-weight: bold; }
  .reservation-flyout ul.list-options button {
    padding: 0;
    font-weight: 300;
    color: #2b2b2b;
    text-transform: capitalize; }
    .reservation-flyout ul.list-options button:hover {
      color: #007697; }
    .reservation-flyout ul.list-options button span {
      pointer-events: none; }
  .reservation-flyout ul.list-options .form-check-label {
    margin: 0;
    padding: 0;
    outline: 0 !important; }
    .reservation-flyout ul.list-options .form-check-label:hover, .reservation-flyout ul.list-options .form-check-label:focus {
      color: #007697; }
  .reservation-flyout ul.list-options label,
  .reservation-flyout ul.list-options [data-label-parent] {
    font-weight: 300; }

.reservation-flyout ul.promo-codes-applied {
  margin: 1em 0;
  padding: 0; }
  .reservation-flyout ul.promo-codes-applied li {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((26 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.55 / 640vw ) * 100);
    margin-bottom: 0.5em;
    margin: 0.5em 0;
    padding: 0.75em 1.25em;
    background-color: #f7f7f7;
    width: 110%; }
    @media (min-width: 640px) {
      .reservation-flyout ul.promo-codes-applied li {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 32px;
        letter-spacing: 0.55px; } }
    @media (min-width: 768px) {
      .reservation-flyout ul.promo-codes-applied li {
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .reservation-flyout ul.promo-codes-applied li {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .reservation-flyout ul.promo-codes-applied li .btn-remove {
      float: right;
      margin-top: -4px;
      padding: 0;
      text-align: right;
      background-color: transparent; }
      .reservation-flyout ul.promo-codes-applied li .btn-remove .btn-label {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        font-size: calc((26 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        margin: 0;
        line-height: 1em;
        text-transform: none;
        color: #2b2b2b;
        text-decoration: underline; }
        @media (min-width: 640px) {
          .reservation-flyout ul.promo-codes-applied li .btn-remove .btn-label {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 640px) {
          .reservation-flyout ul.promo-codes-applied li .btn-remove .btn-label {
            font-size: 26px;
            font-size: 1.625rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .reservation-flyout ul.promo-codes-applied li .btn-remove .btn-label {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100);
            font-size: calc((12 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-flyout ul.promo-codes-applied li .btn-remove .btn-label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-flyout ul.promo-codes-applied li .btn-remove .btn-label {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .reservation-flyout ul.promo-codes-applied li .btn-remove .icon-svg {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        border-radius: 100%;
        background-color: #007697; }
        .reservation-flyout ul.promo-codes-applied li .btn-remove .icon-svg svg {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          fill: #ffffff;
          width: 8px;
          height: 8px; }

.reservation-flyout .flatpickr-calendar .rate {
  display: inline-block;
  font-size: calc((15 / 640vw ) * 100);
  color: #2b2b2b;
  letter-spacing: 0.2px;
  font-weight: 300;
  pointer-events: none; }

.reservation-flyout .flatpickr-calendar .flatpickr-prev-month svg,
.reservation-flyout .flatpickr-calendar .flatpickr-next-month svg {
  height: 14px; }

.reservation-flyout .flatpickr-calendar .flatpickr-day {
  max-width: 14.2857%;
  white-space: nowrap; }

.reservation-flyout .reservation-dropdown {
  position: absolute;
  border: 1px solid #636c72;
  margin-top: -1px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100%;
  width: auto;
  max-height: 20em;
  z-index: 1000; }
  .reservation-flyout .reservation-dropdown .form-check-label {
    line-height: 36px;
    border-top: 1px solid #000000; }
  .reservation-flyout .reservation-dropdown .custom-checkbox {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0.75em 1em;
    font-weight: normal;
    line-height: 1.5em;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer; }
    .reservation-flyout .reservation-dropdown .custom-checkbox:last-of-type {
      border-bottom: none; }
    .reservation-flyout .reservation-dropdown .custom-checkbox .custom-control-indicator {
      position: relative;
      flex-shrink: 0;
      margin-right: 0.5em; }
  .reservation-flyout .reservation-dropdown .flatpickr-calendar {
    margin: 0;
    border: 0; }
  .reservation-flyout .reservation-dropdown .reservation-dropdown-actions {
    display: flex;
    justify-content: center;
    margin: 1em auto; }

.reservation-flyout #mar-search-results-container {
  max-height: 25em; }
  .reservation-flyout #mar-search-results-container .destination-header {
    display: flex;
    align-items: center;
    margin: 0.5em 0 0;
    padding: 0.75em 1em; }
  .reservation-flyout #mar-search-results-container .destination-search-label {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((36 / 640vw ) * 100);
    line-height: calc((48 / 640vw ) * 100);
    letter-spacing: calc((4 / 640vw ) * 100);
    margin-bottom: 0.5em;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    margin: 0 0.5em 0 0; }
    @media (min-width: 640px) {
      .reservation-flyout #mar-search-results-container .destination-search-label {
        font-size: 36px;
        font-size: 2.25rem;
        line-height: 48px;
        letter-spacing: 4px; } }
    @media (min-width: 768px) {
      .reservation-flyout #mar-search-results-container .destination-search-label {
        font-size: calc((22 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((2 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .reservation-flyout #mar-search-results-container .destination-search-label {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 32px;
      letter-spacing: 2px; } }
  .reservation-flyout #mar-search-results-container .destination-search-count {
    white-space: nowrap; }
  .reservation-flyout #mar-search-results-container button {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0.75em 1.75em;
    font-weight: 400;
    text-align: left;
    color: #2b2b2b;
    cursor: pointer; }
    @media (min-width: 640px) {
      .reservation-flyout #mar-search-results-container button {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .reservation-flyout #mar-search-results-container button {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .reservation-flyout #mar-search-results-container button {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .reservation-flyout #mar-search-results-container button:hover, .reservation-flyout #mar-search-results-container button.active {
      background-color: #f7f7f7;
      font-weight: 500; }

.reservation-flyout .dates-unavailable-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1051;
  display: flex;
  align-items: center;
  justify-content: center; }
  .reservation-flyout .dates-unavailable-container .dates-unavailable-dialog {
    position: relative;
    width: 250px;
    background: white;
    border: 1px solid #2b2b2b;
    text-align: center;
    padding: 24px;
    font-size: 14px; }
    .reservation-flyout .dates-unavailable-container .dates-unavailable-dialog .dates-unavailable-msg {
      font-weight: bold;
      padding-bottom: 6px; }
    .reservation-flyout .dates-unavailable-container .dates-unavailable-dialog .btn-close {
      position: absolute;
      top: 0;
      right: 0; }
      .reservation-flyout .dates-unavailable-container .dates-unavailable-dialog .btn-close .icon-svg {
        height: 12px;
        width: 12px; }

.reservation-flyout #special-rate-input {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.reservation-flyout .modal-footer {
  justify-content: flex-start;
  font-family: 'Oswald';
  text-transform: uppercase;
  flex-direction: column; }

.reservation-flyout.active {
  width: 100%;
  margin-right: 0;
  -webkit-overflow-scrolling: touch; }

@media (min-width: 640px) {
  .reservation-flyout .modal-header {
    padding: 32px 35px 32px 45px; }
    .reservation-flyout .modal-header .close {
      margin: 40px; }
      .reservation-flyout .modal-header .close .icon-svg {
        width: 32px;
        height: 32px; }
  .reservation-flyout .modal-body, .reservation-flyout .modal-footer {
    padding: 0 40px; }
  .reservation-flyout form fieldset {
    padding: 0 0 0 30px;
    border-left-width: 11px; }
  .reservation-flyout .form-actions button {
    width: 48px;
    height: 48px;
    bottom: 22px; }
    .reservation-flyout .form-actions button.btn-search {
      right: 96px; }
  .reservation-flyout ul.promo-codes-applied li .btn-remove {
    margin: 0; }
  .reservation-flyout .flatpickr-calendar .rate {
    font-size: 14px; } }

@media (min-width: 768px) {
  .reservation-flyout .loader-text {
    margin-top: 0; }
  .reservation-flyout .flatpickr-months {
    height: 33px; }
  .reservation-flyout.active {
    width: 560px; }
  .reservation-flyout .container, .reservation-flyout .form-factory {
    width: 560px;
    min-width: 560px;
    height: 100%; }
  .reservation-flyout .modal-dialog {
    max-width: 800px; }
  .reservation-flyout .modal-header {
    padding: 28px 35px 28px 45px; }
    .reservation-flyout .modal-header .close {
      margin: 28px; }
      .reservation-flyout .modal-header .close .icon-svg {
        width: 22px;
        height: 22px; }
  .reservation-flyout form {
    padding: 20px 40px; }
    .reservation-flyout form fieldset {
      padding: 0 0 0 20px;
      border-left-width: 5px; }
  .reservation-flyout .form-actions button {
    width: 32px;
    height: 32px;
    bottom: 8px; }
    .reservation-flyout .form-actions button.btn-search {
      right: 10px; }
  .reservation-flyout .btn-transparent.btn-back {
    margin: 0 0 8px 0; }
    .reservation-flyout .btn-transparent.btn-back .icon-svg {
      width: 10px;
      height: 10px; }
  .reservation-flyout .scroll-container {
    height: 175px;
    overflow-y: scroll; }
  .reservation-flyout ul.list-options {
    padding: 0; }
    .reservation-flyout ul.list-options li.form-check {
      margin: 0 0 0.25em 0; }
  .reservation-flyout .flatpickr-calendar {
    margin-left: 12px; }
    .reservation-flyout .flatpickr-calendar .rate {
      width: 100%;
      font-size: 10px; }
  .reservation-flyout .modal-footer {
    flex-direction: row; }
    .reservation-flyout .modal-footer > div {
      margin: 0; } }

.reservation-information .feature {
  height: calc((316 / 640vw ) * 100); }

.reservation-information .container-inset {
  max-width: 800px; }

.reservation-information .headline-5 {
  margin-bottom: calc((40 / 640vw ) * 100); }
  .reservation-information .headline-5 span {
    font-weight: 300; }

.reservation-information .card {
  margin-bottom: calc((39 / 640vw ) * 100);
  padding: calc((40 / 640vw ) * 100) calc((70 / 640vw ) * 100) calc((20 / 640vw ) * 100); }
  .reservation-information .card .icon-svg {
    width: calc((36 / 640vw ) * 100);
    height: calc((36 / 640vw ) * 100);
    margin: 0 auto 5px; }
  .reservation-information .card .info {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin: auto;
    padding: 25px 0; }
    @media (min-width: 640px) {
      .reservation-information .card .info {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .reservation-information .card .info {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-information .card .info {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .reservation-information .card .info .divider {
      display: block; }
  .reservation-information .card footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    padding-top: 12px; }
    @media (min-width: 640px) {
      .reservation-information .card footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .reservation-information .card footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-information .card footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .reservation-information .card footer ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .reservation-information .card footer ul li {
        display: inline-block;
        margin-right: 2px; }
        .reservation-information .card footer ul li + li:before {
          display: inline-block;
          width: 1px;
          height: 0.8em;
          margin: 0 calc((15 / 640vw ) * 100);
          content: "";
          background-color: #797979;
          vertical-align: -1px; }
    .reservation-information .card footer p {
      margin-bottom: 0; }
    .reservation-information .card footer span {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem; }
      @media (min-width: 640px) {
        .reservation-information .card footer span {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .reservation-information .card footer span {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-information .card footer span {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .reservation-information .card footer .icon-svg {
      width: calc((24 / 640vw ) * 100);
      height: calc((24 / 640vw ) * 100);
      margin-bottom: 0;
      vertical-align: -1px; }

.reservation-information a.headline-5 {
  text-decoration: underline; }

.reservation-information .btn {
  margin-top: 20px; }

@media (min-width: 640px) {
  .reservation-information .feature {
    height: 316px; } }

@media (min-width: 768px) {
  .reservation-information .feature {
    height: calc((300 / 1024vw ) * 100); }
  .reservation-information .headline-5 {
    margin-bottom: 20px; }
  .reservation-information .container-inset {
    width: 100%;
    margin-top: -132px; }
  .reservation-information .info {
    padding: 0; }
    .reservation-information .info span {
      display: block; }
  .reservation-information .card {
    margin: 0;
    padding: 40px 15px 10px;
    height: 100%; }
    .reservation-information .card .icon-svg {
      width: 20px;
      height: 20px; }
    .reservation-information .card footer .icon-svg {
      width: 12px;
      height: 12px;
      margin-right: 5px; }
    .reservation-information .card footer ul li + li:before {
      margin: 0 8px; }
  .reservation-information a.headline-5 {
    margin-top: 20px; } }

@media (min-width: 1024px) {
  .reservation-information .feature {
    height: 300px; } }

.reservation-promos .card {
  display: block;
  margin-bottom: calc((20 / 640vw ) * 100);
  padding: 20px 34px;
  min-height: calc((160 / 640vw ) * 100);
  border: 0;
  background-color: #f7f7f7; }

.reservation-promos .collapse,
.reservation-promos .collapsing {
  position: absolute;
  left: 0;
  z-index: 1; }

.reservation-promos .card-header {
  padding: 0;
  text-align: left;
  background: none;
  border-bottom: 0; }
  .reservation-promos .card-header a[data-toggle] {
    display: block;
    position: relative;
    padding-right: calc((50 / 640vw ) * 100); }
    .reservation-promos .card-header a[data-toggle]:hover {
      color: #000000; }
    .reservation-promos .card-header a[data-toggle].collapsed .icon-svg.hidden-md-up {
      transform: rotate(0) translateY(-50%); }
    .reservation-promos .card-header a[data-toggle]:not(.collapsed) .sub-headline span {
      display: none; }
    .reservation-promos .card-header a[data-toggle] .icon-svg.hidden-md-up {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
      width: calc((24 / 640vw ) * 100);
      height: calc((24 / 640vw ) * 100);
      transform: rotate(-180deg) translateY(50%);
      transition: transform 0.5s; }

.reservation-promos .card-block {
  margin: calc((20 / 640vw ) * 100) 0;
  padding: 0 34px 40px;
  background-color: #f7f7f7; }

.reservation-promos .headline-5 {
  margin-bottom: 0; }
  .reservation-promos .headline-5 .icon-svg {
    margin-right: 10px; }

.reservation-promos .sub-headline {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  margin-bottom: 1rem; }
  @media (min-width: 640px) {
    .reservation-promos .sub-headline {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .reservation-promos .sub-headline {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .reservation-promos .sub-headline {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .reservation-promos .sub-headline.hidden-md-up {
    display: block;
    margin-bottom: calc((-20 / 640vw ) * 100); }

.reservation-promos p {
  margin-bottom: calc((30 / 640vw ) * 100); }

@media (min-width: 640px) {
  .reservation-promos .card {
    min-height: 160px; } }

@media (min-width: 768px) {
  .reservation-promos .card {
    height: 100%;
    padding: 42px 50px;
    margin-bottom: 0; }
  .reservation-promos .collapse,
  .reservation-promos .collapsing {
    display: block;
    position: relative; }
  .reservation-promos .card-header {
    pointer-events: none;
    text-align: center; }
    .reservation-promos .card-header a[data-toggle] {
      padding-right: 0; }
  .reservation-promos .card-block {
    margin: 20px 0 0;
    padding: 0; }
  .reservation-promos .sub-headline {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    margin-bottom: 20px; } }
  @media (min-width: 768px) and (min-width: 640px) {
    .reservation-promos .sub-headline {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 32px;
      letter-spacing: 0.5px; } }
  @media (min-width: 768px) and (min-width: 768px) {
    .reservation-promos .sub-headline {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 768px) and (min-width: 640px) {
    .reservation-promos .sub-headline {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }

@media (min-width: 768px) {
  .reservation-promos p {
    margin-bottom: 20px; } }

.rooms-listing {
  padding: calc((40vw / 640vw ) * 100) calc((20vw / 640vw ) * 100) calc((30vw / 640vw ) * 100);
  border-bottom: 1px solid #e1e1e1;
  text-align: center; }
  .rooms-listing .carousel-factory {
    margin: 0 auto 20px;
    width: 100%; }
    .rooms-listing .carousel-factory[data-arrows="sides"] .carousel {
      width: 100%; }
    .rooms-listing .carousel-factory .slick-prev,
    .rooms-listing .carousel-factory .slick-next {
      width: 30px;
      height: 30px; }
      .rooms-listing .carousel-factory .slick-prev .icon-svg,
      .rooms-listing .carousel-factory .slick-next .icon-svg {
        width: 50%;
        height: 50%; }
    .rooms-listing .carousel-factory .slick-prev {
      margin-left: 12px; }
    .rooms-listing .carousel-factory .slick-next {
      margin-right: 12px; }
    .rooms-listing .carousel-factory .carousel-indicators {
      margin-top: 30px; }
  .rooms-listing figure {
    position: relative;
    padding: 4px;
    margin: 0;
    overflow: hidden; }
  .rooms-listing .content {
    position: relative;
    padding: 0;
    vertical-align: top; }
    .rooms-listing .content ul.list-separated {
      margin: 25px 0 28px 0;
      font-weight: 500; }
    .rooms-listing .content .lg-text {
      font-size: 32px; }
    .rooms-listing .content .icon-svg {
      width: calc((25 / 640vw ) * 100);
      height: calc((25 / 640vw ) * 100);
      margin-right: calc((7 / 640vw ) * 100);
      vertical-align: -3px; }
  .rooms-listing:last-child {
    border: 0; }
  @media (min-width: 640px) {
    .rooms-listing {
      padding: 40px 20px 30px; } }
  @media (max-width: 1023.98px) {
    .rooms-listing .content ul.list-separated li:first-child {
      display: block; }
    .rooms-listing .content ul.list-separated li:nth-child(2):before {
      content: none; } }
  @media (min-width: 768px) {
    .rooms-listing {
      padding: calc((40 / 1024vw ) * 100) 0 calc((30 / 1024vw ) * 100);
      text-align: left; }
      .rooms-listing .carousel-factory {
        margin-bottom: 0; }
        .rooms-listing .carousel-factory .carousel-indicators {
          margin-top: 0;
          height: 0; }
        .rooms-listing .carousel-factory .slick-prev,
        .rooms-listing .carousel-factory .slick-next {
          margin-top: 0; }
      .rooms-listing .content {
        padding: calc((20 / 1024vw ) * 100) 0 0 calc((10 / 1024vw ) * 100); }
        .rooms-listing .content .icon-svg {
          width: 12px;
          height: 12px;
          margin-right: 7px;
          vertical-align: -2px; }
      .rooms-listing:last-child {
        margin-bottom: calc((30 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .rooms-listing {
      padding: 40px 0 30px; }
      .rooms-listing .content {
        padding: 20px 0 0 10px; }
      .rooms-listing:last-child {
        margin-bottom: 30px; } }
  .rooms-listing .roomRateFees {
    line-height: 1.25;
    margin-top: .75rem;
    font-size: 12px;
    color: #666; }

.search {
  transition: none !important; }
  .search form {
    width: 100%; }
    .search form .form-group {
      position: relative;
      margin: calc((50 / 640vw ) * 100) calc((72 / 640vw ) * 100) calc((50 / 640vw ) * 100) calc((36 / 640vw ) * 100);
      background-color: transparent;
      z-index: 900; }
    .search form .form-actions {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
      display: inline-block;
      margin-right: calc((30 / 640vw ) * 100);
      z-index: 900; }
      .search form .form-actions .icon-svg {
        width: 1.5em; }
      .search form .form-actions .btn-search, .search form .form-actions .btn-close {
        position: relative;
        margin: 1em;
        vertical-align: middle;
        flex-shrink: 0; }
        .search form .form-actions .btn-search .icon-svg, .search form .form-actions .btn-close .icon-svg {
          position: absolute;
          top: 50%;
          right: auto;
          bottom: auto;
          left: 50%;
          transform: translate(-50%, -50%); }
      .search form .form-actions .btn-search .icon-svg {
        width: 85%;
        height: 85%;
        fill: #000000; }
      .search form .form-actions .btn-close {
        padding: 1em;
        border-radius: 100%;
        background-color: #007697; }
        .search form .form-actions .btn-close .icon-svg {
          width: 40%;
          height: 40%;
          fill: #ffffff; }
  .search .search-input {
    width: 100%;
    padding: 0 1.75em;
    font-size: calc((28 / 640vw ) * 100);
    font-weight: 300;
    border: none; }
  .search ul.search-results, .search .faq ul.faq-search, .faq .search ul.faq-search {
    margin: 20px 30px;
    right: 0;
    left: 0;
    border: 0; }
    .search ul.search-results li, .search .faq ul.faq-search li, .faq .search ul.faq-search li {
      padding: 0; }
      .search ul.search-results li .dropdown-item, .search .faq ul.faq-search li .dropdown-item, .faq .search ul.faq-search li .dropdown-item {
        font-size: calc((28 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-weight: 300;
        color: #2b2b2b;
        display: inline-block;
        width: auto;
        padding: 1em;
        margin: 0;
        color: #2b2b2b; }
        @media (min-width: 640px) {
          .search ul.search-results li .dropdown-item, .search .faq ul.faq-search li .dropdown-item, .faq .search ul.faq-search li .dropdown-item {
            font-size: 28px;
            font-size: 1.75rem;
            line-height: 40px;
            letter-spacing: 0px; } }
        @media (min-width: 1024px) {
          .search ul.search-results li .dropdown-item, .search .faq ul.faq-search li .dropdown-item, .faq .search ul.faq-search li .dropdown-item {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .search ul.search-results li .dropdown-item, .search .faq ul.faq-search li .dropdown-item, .faq .search ul.faq-search li .dropdown-item {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
        .search ul.search-results li .dropdown-item:hover, .search .faq ul.faq-search li .dropdown-item:hover, .faq .search ul.faq-search li .dropdown-item:hover, .search ul.search-results li .dropdown-item:focus, .search .faq ul.faq-search li .dropdown-item:focus, .faq .search ul.faq-search li .dropdown-item:focus {
          text-decoration: underline;
          background-color: transparent; }
  .search .search-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 1;
    z-index: 0; }
  .search.active {
    top: 0;
    width: 100%;
    height: 100vh; }
    .search.active form .form-group {
      margin: calc((50 / 640vw ) * 100) calc((96 / 640vw ) * 100) calc((50 / 640vw ) * 100) calc((46 / 640vw ) * 100); }
    .search.active form .form-actions .btn-close {
      display: inline-block; }
    .search.active form .form-actions .btn-search {
      margin-right: 0; }
  @media (min-width: 640px) {
    .search form .form-group {
      margin: 50px 72px 0 72px; }
    .search .search-input {
      height: 88px;
      font-size: 28px; } }
  @media (min-width: 1024px) {
    .search {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 0);
      background: #ffffff;
      z-index: 1050; }
      .search form {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #ffffff;
        z-index: 1; }
        .search form .form-group {
          width: 100%;
          height: 100%;
          margin: 0; }
        .search form .form-actions {
          position: absolute;
          top: 50%;
          right: 0;
          bottom: auto;
          left: auto;
          transform: translateY(-50%);
          display: inline-block;
          margin-right: 10%; }
          .search form .form-actions .btn-close, .search form .form-actions .btn-search {
            width: 42px;
            height: 42px; }
      .search .search-input {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((50 / 640vw ) * 100);
        line-height: calc((63 / 640vw ) * 100);
        letter-spacing: calc((4.5 / 640vw ) * 100);
        margin-bottom: 0.75em;
        text-transform: uppercase;
        font-family: "Oswald", sans-serif;
        font-weight: 500;
        position: absolute;
        top: 0;
        left: 0;
        width: 75%;
        height: 100%;
        padding: 0 0 0 10%;
        font-size: 44px;
        text-transform: uppercase;
        border: 0;
        z-index: 1000; } }
    @media (min-width: 1024px) and (min-width: 640px) {
      .search .search-input {
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 63px;
        letter-spacing: 4.5px; } }
    @media (min-width: 1024px) and (min-width: 768px) {
      .search .search-input {
        font-size: calc((32 / 640vw ) * 100);
        line-height: calc((40 / 640vw ) * 100);
        letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 768px) and (min-width: 640px) {
    .search .search-input {
      font-size: 32px;
      font-size: 2rem;
      line-height: 40px;
      letter-spacing: 5.25px; } }
  @media (min-width: 1024px) {
        .search .search-input mark {
          padding: 0 0.25em 0 0.45em;
          background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
          box-decoration-break: clone; } }
      @media screen and (min-width: 1024px) and (min-width: 0\0) {
        .search .search-input mark {
          padding: 0; } }
  @media (min-width: 1024px) {
          @supports (-ms-ime-align: auto) {
            .search .search-input mark {
              padding: 0; } }
      .search ul.search-results, .search .faq ul.faq-search, .faq .search ul.faq-search {
        width: 100%;
        margin: 0;
        padding: 0 0 55px 9%;
        background-color: #ffffff; }
        .search ul.search-results li .dropdown-item, .search .faq ul.faq-search li .dropdown-item, .faq .search ul.faq-search li .dropdown-item {
          font-size: 14px;
          line-height: 24px; }
      .search .search-backdrop {
        position: fixed;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000000;
        opacity: 0.5;
        z-index: 0; } }

.search-results form, .faq .faq-search form {
  position: relative; }

.search-results label, .faq .faq-search label {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((40 / 640vw ) * 100);
  letter-spacing: calc((0 / 640vw ) * 100);
  font-weight: 300;
  color: #2b2b2b;
  display: block;
  margin: 0; }
  @media (min-width: 640px) {
    .search-results label, .faq .faq-search label {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 40px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) {
    .search-results label, .faq .faq-search label {
      font-size: calc((14 / 640vw ) * 100);
      line-height: calc((24 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .search-results label, .faq .faq-search label {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }

.search-results input[type="search"], .faq .faq-search input[type="search"] {
  display: block;
  font-weight: 500;
  text-transform: none;
  font-size: calc((50 / 640vw ) * 100);
  line-height: calc((63 / 640vw ) * 100);
  letter-spacing: calc((4.5 / 640vw ) * 100);
  margin-bottom: 0.75em;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  position: relative;
  padding: 0 50px 0 0;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #2b2b2b; }
  @media (min-width: 640px) {
    .search-results input[type="search"], .faq .faq-search input[type="search"] {
      font-size: 50px;
      font-size: 3.125rem;
      line-height: 63px;
      letter-spacing: 4.5px; } }
  @media (min-width: 768px) {
    .search-results input[type="search"], .faq .faq-search input[type="search"] {
      font-size: calc((32 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .search-results input[type="search"], .faq .faq-search input[type="search"] {
      font-size: 32px;
      font-size: 2rem;
      line-height: 40px;
      letter-spacing: 5.25px; } }
  .search-results input[type="search"] mark, .faq .faq-search input[type="search"] mark {
    padding: 0 0.25em 0 0.45em;
    background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
    box-decoration-break: clone; }
    @media screen and (min-width: 0\0) {
      .search-results input[type="search"] mark, .faq .faq-search input[type="search"] mark {
        padding: 0; } }
    @supports (-ms-ime-align: auto) {
      .search-results input[type="search"] mark, .faq .faq-search input[type="search"] mark {
        padding: 0; } }
  .search-results input[type="search"]::placeholder, .faq .faq-search input[type="search"]::placeholder {
    color: #2b2b2b; }

.search-results .btn-search, .faq .faq-search .btn-search {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
  right: 4px;
  vertical-align: middle;
  pointer-events: none; }
  .search-results .btn-search .icon-svg, .faq .faq-search .btn-search .icon-svg {
    width: calc((38 / 640vw ) * 100);
    height: calc((38 / 640vw ) * 100); }

.search-results .search-results-item, .faq .faq-search .search-results-item {
  padding: 20px 0 15px;
  border-bottom: 1px solid #e1e1e1; }
  .search-results .search-results-item:first-of-type, .faq .faq-search .search-results-item:first-of-type {
    border-top: 1px solid #e1e1e1; }
  .search-results .search-results-item figure, .faq .faq-search .search-results-item figure {
    margin-bottom: 0; }
  .search-results .search-results-item p, .faq .faq-search .search-results-item p {
    font-size: .875rem;
    line-height: 24px;
    letter-spacing: 0; }
    .search-results .search-results-item p:last-of-type, .faq .faq-search .search-results-item p:last-of-type {
      margin-bottom: 0; }
  .search-results .search-results-item .blockquote, .faq .faq-search .search-results-item .blockquote {
    margin: calc((10 / 640vw ) * 100) calc((28 / 640vw ) * 100) calc((15 / 640vw ) * 100); }

.search-results .no-results, .faq .faq-search .no-results {
  border: 1px solid #e1e1e1;
  padding: 100px 50px;
  text-align: center; }

@media (min-width: 768px) {
  .search-results .btn-search, .faq .faq-search .btn-search {
    width: 36px;
    height: 36px; }
    .search-results .btn-search .icon-svg, .faq .faq-search .btn-search .icon-svg {
      width: 24px;
      height: 24px; }
  .search-results .search-results-item .blockquote, .faq .faq-search .search-results-item .blockquote {
    margin: 10px 0 15px; } }

.signup {
  position: relative;
  margin: 0 0 0;
  padding: 50px 15px 40px 15px;
  color: #ffffff;
  background-color: #005e79;
  background-size: cover;
  background-position: center;
  text-align: center; }
  .signup h1, .signup h2, .signup h3, .signup h4, .signup h5, .signup h6 {
    margin: 0 0 0.75em 0;
    padding: 0; }
  .signup p {
    margin: 0;
    line-height: 1.25em; }
  .signup form {
    margin: 20px 0; }
    .signup form input, .signup form .btn {
      border: 0; }
  .signup:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    background-color: #1a84a1;
    content: ''; }
  @media (min-width: 768px) {
    .signup {
      padding: 24px 32px; }
      .signup h1, .signup h2, .signup h3, .signup h4, .signup h5, .signup h6 {
        float: left;
        margin: 0 1.5em 0 0;
        line-height: 40px; }
      .signup p {
        float: left;
        line-height: 40px; } }

.tabbed-accordion {
  padding: 0 20px; }
  .tabbed-accordion .nav-tabs {
    display: block;
    max-height: none;
    border: 0;
    overflow: visible; }
    .tabbed-accordion .nav-tabs .nav-link {
      width: 95%;
      margin: 0;
      padding: 20px 1em;
      background-color: #ffffff;
      border-bottom: 1px solid #e1e1e1;
      border-left: 7px solid #ffffff;
      color: #000000; }
      .tabbed-accordion .nav-tabs .nav-link:hover {
        border-bottom: 1px solid #e1e1e1; }
    .tabbed-accordion .nav-tabs .tab.active .nav-link,
    .tabbed-accordion .nav-tabs .tab.is-active .nav-link {
      width: auto;
      margin: -1px -1px 0 0;
      border-top: 1px solid #c7c7c7;
      border-bottom: 1px solid #c7c7c7;
      border-left: 7px solid #3cc1cc; }
      .tabbed-accordion .nav-tabs .tab.active .nav-link:hover,
      .tabbed-accordion .nav-tabs .tab.is-active .nav-link:hover {
        border-bottom: 1px solid #c7c7c7; }
  .tabbed-accordion .tab-content {
    position: relative;
    margin-top: -1px;
    min-height: 100%; }
    .tabbed-accordion .tab-content .list-bulleted {
      padding: 0 30px 26px 12px; }
    .tabbed-accordion .tab-content > .active.collapse {
      display: none; }
      .tabbed-accordion .tab-content > .active.collapse.show {
        display: block; }
    .tabbed-accordion .tab-content > .tab-pane.show,
    .tabbed-accordion .tab-content .tab-pane.collapsing {
      display: block; }
    .tabbed-accordion .tab-content .fade {
      opacity: 1; }
    .tabbed-accordion .tab-content .headline-3 {
      position: relative;
      padding: 35px 50px 18px 0;
      border-top: 8px solid #3cc1cc; }
      .tabbed-accordion .tab-content .headline-3.collapsed .icon-svg {
        transform: rotate(0) translateY(-50%); }
      .tabbed-accordion .tab-content .headline-3 .icon-svg {
        position: absolute;
        top: 60%;
        right: 15px;
        width: calc((24 / 640vw ) * 100);
        height: calc((24 / 640vw ) * 100);
        transform: rotate(-180deg) translateY(50%);
        transition: transform 0.5s;
        z-index: 2; }
  .tabbed-accordion footer {
    padding-bottom: 26px; }
    .tabbed-accordion footer ul {
      margin-bottom: 0; }
    .tabbed-accordion footer li {
      margin-bottom: 40px;
      padding: 0 20px;
      font-weight: 500;
      text-align: center; }
      .tabbed-accordion footer li span {
        display: block;
        margin: 0 auto 20px;
        width: calc((71 / 640vw ) * 100);
        height: calc((71 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .tabbed-accordion {
      padding: 0; }
      .tabbed-accordion .nav-tabs {
        position: relative;
        z-index: 1; }
      .tabbed-accordion .tab-content > .active.collapse {
        display: none; }
      .tabbed-accordion .tab-content {
        padding: 30px 30px 15px;
        border: 1px solid #c7c7c7; }
        .tabbed-accordion .tab-content .list-bulleted {
          padding-bottom: 0;
          line-height: 30px; }
        .tabbed-accordion .tab-content > .active.collapse {
          display: block; }
        .tabbed-accordion .tab-content > .tab-pane:not(.in) {
          display: none; }
      .tabbed-accordion footer {
        padding-bottom: 0; }
        .tabbed-accordion footer li {
          margin-bottom: 14px;
          padding-right: 6px;
          padding-left: 6px; }
          .tabbed-accordion footer li span {
            display: inline-block;
            margin: 0 10px 0 0;
            width: 32px;
            height: 32px; } }

.tandc-modal .modal-header {
  text-align: center;
  padding-bottom: 0; }

.tandc-modal .modal-content {
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem; }

.tandc-modal .modal-footer {
  justify-content: center;
  padding: 22px 0px; }

.timeline-item {
  position: relative;
  padding-top: calc((66 / 640vw ) * 100); }
  .timeline-item:before, .timeline-item:last-of-type:after {
    width: 1px;
    height: calc((55 / 640vw ) * 100);
    background-color: #c1c1c1;
    content: ''; }
  .timeline-item:first-of-type .headline-3:before, .timeline-item:last-of-type .headline-3:before {
    width: calc((25 / 640vw ) * 100);
    height: calc((25 / 640vw ) * 100);
    background-color: #ffffff;
    border: 1.5px solid #c1c1c1;
    border-radius: 50%;
    content: '';
    z-index: 1; }
  .timeline-item:before {
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%); }
  .timeline-item:first-of-type {
    margin-top: calc((20 / 640vw ) * 100); }
    .timeline-item:first-of-type .headline-3:before {
      position: absolute;
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      top: calc((-22 / 640vw ) * 100); }
  .timeline-item:last-of-type {
    padding-bottom: calc((56 / 640vw ) * 100); }
    .timeline-item:last-of-type .headline-3:before {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc((-22 / 640vw ) * 100); }
    .timeline-item:last-of-type:after {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
  .timeline-item .collapsible-btn {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    text-decoration: underline; }
    @media (min-width: 640px) {
      .timeline-item .collapsible-btn {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .timeline-item .collapsible-btn {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .timeline-item .collapsible-btn {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 768px) {
    .timeline-item {
      padding-top: 36px; }
      .timeline-item:before, .timeline-item:last-of-type:after {
        height: 31px; }
      .timeline-item:first-of-type .headline-3:before, .timeline-item:last-of-type .headline-3:before {
        width: 13px;
        height: 13px; }
      .timeline-item:before {
        position: absolute;
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%); }
      .timeline-item:first-of-type {
        margin-top: 0; }
        .timeline-item:first-of-type .headline-3:before {
          top: -8px; }
      .timeline-item:last-of-type {
        margin-bottom: 20px;
        padding-bottom: 28px; }
        .timeline-item:last-of-type .headline-3:before {
          bottom: -8px; } }

.feature ~ .travel-log-details {
  margin-top: calc((-52 / 640vw ) * 100); }
  @media (min-width: 768px) {
    .feature ~ .travel-log-details {
      margin-top: -34px; } }

.travel-log-details {
  margin-top: calc((52 / 640vw ) * 100);
  text-align: center; }
  .travel-log-details.completed .form-hint {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s; }
  .travel-log-details.completed .form-thank-you {
    height: calc((76 / 640vw ) * 100);
    padding-bottom: 10px;
    opacity: 1;
    animation: reveal 2s 5s;
    animation-fill-mode: forwards; }
  .travel-log-details .form-thank-you {
    height: 0;
    margin: 0;
    opacity: 0; }

@keyframes reveal {
  0% {
    opacity: 1; }
  100% {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0; } }
  .travel-log-details .container.container-inset, .travel-log-details .container-inset.form-factory {
    padding: calc((30 / 640vw ) * 100) calc((15 / 640vw ) * 100); }
  .travel-log-details .edit-menu [data-toggle='collapse'] {
    font-size: 6.25rem;
    position: relative;
    top: 7px;
    width: 60px;
    height: 30px;
    margin: 0;
    padding: 0;
    float: right;
    line-height: 0;
    letter-spacing: -9px;
    transform: rotate(-90deg);
    transition: transform 0.6s; }
    .travel-log-details .edit-menu [data-toggle='collapse']:focus, .travel-log-details .edit-menu [data-toggle='collapse']:hover {
      color: #2b2b2b; }
    .travel-log-details .edit-menu [data-toggle='collapse'] span {
      position: absolute;
      top: -17px;
      display: block;
      width: 20px;
      height: 20px; }
    .travel-log-details .edit-menu [data-toggle='collapse'].collapsed {
      transform: rotate(0); }
  .travel-log-details .edit-menu .shareButton {
    right: 40px; }
  .travel-log-details .edit-menu .collapse button,
  .travel-log-details .edit-menu .collapsing button {
    margin-bottom: 5px; }
  .travel-log-details .edit-menu .collapse,
  .travel-log-details .edit-menu .collapsing {
    position: relative;
    top: 28px;
    right: 14px;
    margin-bottom: 8px;
    clear: both; }
  .travel-log-details select.form-control {
    padding-top: calc((38 / 640vw ) * 100);
    font-weight: 400;
    color: #2b2b2b; }
  .travel-log-details #travel-log-rename-form {
    display: none; }
    .travel-log-details #travel-log-rename-form button[data-cancel] {
      margin-top: 20px;
      font-weight: 400; }
  .travel-log-details .calendar-collapse {
    position: absolute;
    top: calc((85 / 640vw ) * 100);
    left: calc((-33 / 640vw ) * 100);
    width: calc((600 / 640vw ) * 100); }
    .travel-log-details .calendar-collapse.show, .travel-log-details .calendar-collapse.collapsing {
      z-index: 1; }
  .travel-log-details .check-in-toggler,
  .travel-log-details .check-out-toggler {
    font-size: 1.75rem;
    display: block;
    margin-bottom: calc((14 / 640vw ) * 100);
    padding: calc((42 / 640vw ) * 100) calc((33 / 640vw ) * 100);
    height: calc((88 / 640vw ) * 100);
    line-height: calc((30 / 640vw ) * 100);
    letter-spacing: 0;
    border: 1px solid #2b2b2b;
    text-align: left; }
    .travel-log-details .check-in-toggler .date,
    .travel-log-details .check-out-toggler .date {
      display: block;
      font-weight: 400; }
    .travel-log-details .check-in-toggler .label,
    .travel-log-details .check-out-toggler .label {
      top: calc((28 / 640vw ) * 100); }
      .travel-log-details .check-in-toggler .label.active,
      .travel-log-details .check-out-toggler .label.active {
        top: calc((13 / 640vw ) * 100); }
    .travel-log-details .check-in-toggler .icon-svg,
    .travel-log-details .check-out-toggler .icon-svg {
      top: 50%;
      transform: rotate(-180deg) translateY(50%);
      transition: transform 0.4s; }
    .travel-log-details .check-in-toggler.collapsed .icon-svg,
    .travel-log-details .check-out-toggler.collapsed .icon-svg {
      transform: rotate(0deg) translateY(-50%); }
    .travel-log-details .check-in-toggler:not(.collapsed),
    .travel-log-details .check-out-toggler:not(.collapsed) {
      position: relative;
      z-index: 2;
      border-bottom: 1px solid #ffffff; }
  .travel-log-details .flatpickr-calendar.inline {
    padding: calc((32 / 640vw ) * 100);
    border: 1px solid #2b2b2b; }
  .travel-log-details .flatpickr-day {
    margin: 0;
    padding: calc((10 / 640vw ) * 100);
    height: auto; }
    .travel-log-details .flatpickr-day.selected {
      border: 1px solid #2b2b2b; }
  @media (min-width: 640px) {
    .travel-log-details .check-in-toggler,
    .travel-log-details .check-out-toggler {
      height: 88px; } }
  @media (min-width: 768px) {
    .travel-log-details {
      margin-top: 34px; }
      .travel-log-details .container.container-inset, .travel-log-details .container-inset.form-factory {
        padding: 24px 30px; }
      .travel-log-details.completed .form-thank-you {
        height: 30px; }
      .travel-log-details .edit-menu [data-toggle='collapse'] {
        right: 16px;
        width: auto;
        height: auto;
        font-size: 46px;
        letter-spacing: -4px; }
        .travel-log-details .edit-menu [data-toggle='collapse'] span {
          top: -6px;
          right: 0; }
      .travel-log-details .edit-menu .collapse,
      .travel-log-details .edit-menu .collapsing {
        position: absolute;
        top: 43px;
        right: 0; }
      .travel-log-details #travel-log-rename-form button[data-cancel] {
        margin-top: 0; }
      .travel-log-details .custom-select-label .label {
        font-size: 0.875rem;
        position: absolute;
        top: 14px;
        left: 19px;
        transition: all 0.4s; }
        .travel-log-details .custom-select-label .label.active {
          font-size: 12px;
          top: 5px;
          font-weight: 500; }
      .travel-log-details select.form-control {
        padding-top: 14px;
        font-weight: 300; }
      .travel-log-details .check-in-toggler,
      .travel-log-details .check-out-toggler {
        font-size: 0.875rem;
        padding: 0.375rem 1.75rem 0.375rem 18px;
        height: 48px;
        line-height: 30px; }
        .travel-log-details .check-in-toggler .label,
        .travel-log-details .check-out-toggler .label {
          top: 15px;
          line-height: 17px; }
        .travel-log-details .check-in-toggler .icon-svg,
        .travel-log-details .check-out-toggler .icon-svg {
          top: 24px; }
        .travel-log-details .check-in-toggler .date,
        .travel-log-details .check-out-toggler .date {
          margin-top: 8px;
          font-weight: 300; }
        .travel-log-details .check-in-toggler:not(.collapsed),
        .travel-log-details .check-out-toggler:not(.collapsed) {
          height: 60px;
          margin-bottom: -16px;
          transition: height 0.4s; }
      .travel-log-details .flatpickr-calendar.inline {
        padding: 0; }
      .travel-log-details .flatpickr-day {
        padding: 0; }
      .travel-log-details .calendar-collapse {
        top: 57px;
        width: 350px; }
        .travel-log-details .calendar-collapse#check-out-calendar {
          right: 10px;
          left: auto; } }
  .travel-log-details .headline-1 {
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-all;
    word-wrap: break-word; }

.travel-log-list {
  margin-bottom: 3em; }
  .travel-log-list .no-results {
    margin: 1.5rem 3.3rem 2rem;
    padding: 30px;
    border: 1px solid #c9c9c9;
    text-align: center; }
    .travel-log-list .no-results button {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 0;
      text-decoration: underline; }
      @media (min-width: 640px) {
        .travel-log-list .no-results button {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .travel-log-list .no-results button {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .travel-log-list .no-results button {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .travel-log-list .no-results .icon-svg {
      position: absolute;
      top: calc((-30 / 640vw ) * 100);
      left: 50%;
      width: calc((66 / 640vw ) * 100);
      height: calc((66 / 640vw ) * 100);
      background-color: white;
      transform: translateX(-50%); }
      .travel-log-list .no-results .icon-svg svg {
        padding: calc((10 / 640vw ) * 100); }
  .travel-log-list a[data-toggle='modal'] {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem;
    text-decoration: underline; }
    @media (min-width: 640px) {
      .travel-log-list a[data-toggle='modal'] {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .travel-log-list a[data-toggle='modal'] {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .travel-log-list a[data-toggle='modal'] {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .travel-log-list a[data-toggle='modal']:hover {
      color: #007697; }
  @media (min-width: 640px) {
    .travel-log-list .no-results .icon-svg {
      width: 66px;
      height: 66px; }
      .travel-log-list .no-results .icon-svg svg {
        padding: 10px; } }
  @media (min-width: 768px) {
    .travel-log-list {
      padding: 0 50px; }
      .travel-log-list .no-results {
        width: 95%;
        margin: 14px auto 32px;
        padding: 30px 15px 15px; }
        .travel-log-list .no-results .icon-svg {
          top: -23px;
          width: 49px;
          height: 49px; }
        .travel-log-list .no-results p {
          margin: 0; } }

.travel-log-listing {
  margin: 1.5rem 3.3rem 2rem;
  padding: 20px;
  border: 1px solid #c9c9c9;
  text-align: center; }
  .travel-log-listing figure {
    margin-bottom: 2em; }
    .travel-log-listing figure picture {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding-bottom: 37.42%; }
      .travel-log-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .travel-log-listing picture,
  .travel-log-listing img {
    width: 100%; }
  .travel-log-listing .btn {
    max-width: 100%;
    white-space: pre-wrap; }
  @media (min-width: 768px) {
    .travel-log-listing {
      width: 95%;
      margin: 0 auto 32px; }
      .travel-log-listing figure {
        margin: 0; }
        .travel-log-listing figure picture {
          padding-bottom: 40.40%; } }
  .travel-log-listing .headline-3 {
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-all;
    word-wrap: break-word; }

.travel-log-flyout {
  position: relative;
  padding: 0 calc((40 / 640vw ) * 100) calc((5 / 640vw ) * 100);
  text-align: center; }
  .travel-log-flyout .description {
    margin-bottom: calc((20 / 640vw ) * 100); }
  .travel-log-flyout .form-group {
    margin: calc((40 / 640vw ) * 100) auto calc((20 / 640vw ) * 100); }
    .travel-log-flyout .form-group .btn {
      padding-right: calc((20 / 640vw ) * 100);
      padding-left: calc((20 / 640vw ) * 100); }
  .travel-log-flyout .btn-cancel {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 0;
    text-decoration: underline; }
    @media (min-width: 640px) {
      .travel-log-flyout .btn-cancel {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .travel-log-flyout .btn-cancel {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .travel-log-flyout .btn-cancel {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .travel-log-flyout ul {
    margin: 0 auto calc((45 / 640vw ) * 100);
    padding: 0;
    list-style: none;
    border-top: 1px solid #efefef; }
    .travel-log-flyout ul li {
      position: relative;
      padding: calc((22 / 640vw ) * 100) calc((60 / 640vw ) * 100); }
      .travel-log-flyout ul li .btn-overlay {
        margin: 0;
        height: 100%; }
        .travel-log-flyout ul li .btn-overlay .icon-svg {
          width: calc((38 / 640vw ) * 100);
          height: calc((38 / 640vw ) * 100); }
          .travel-log-flyout ul li .btn-overlay .icon-svg svg:first-child, .travel-log-flyout ul li .btn-overlay .icon-svg svg:first-child svg {
            fill: #000000; }
          .travel-log-flyout ul li .btn-overlay .icon-svg svg:last-child, .travel-log-flyout ul li .btn-overlay .icon-svg svg:last-child svg {
            fill: #007697; }
  .travel-log-flyout .headline-5 {
    display: inline-block;
    margin: 0px 20px 0px 0px;
    padding-top: 5px;
    overflow-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-wrap: break-word; }
  .travel-log-flyout .btn-create-log {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 0;
    text-decoration: underline;
    font-weight: 500; }
    @media (min-width: 640px) {
      .travel-log-flyout .btn-create-log {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .travel-log-flyout .btn-create-log {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .travel-log-flyout .btn-create-log {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .travel-log-flyout .btn-create-log:hover {
      color: #007697; }
  .travel-log-flyout footer {
    margin-bottom: calc((54 / 640vw ) * 100); }
    .travel-log-flyout footer button {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin: 0;
      text-decoration: underline; }
      @media (min-width: 640px) {
        .travel-log-flyout footer button {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .travel-log-flyout footer button {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .travel-log-flyout footer button {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .travel-log-flyout footer button:hover {
        color: #007697; }
  .travel-log-flyout .hide {
    display: none; }
  .travel-log-flyout .show {
    display: block; }
  .travel-log-flyout form {
    display: none;
    margin-bottom: calc((45 / 640vw ) * 100); }
  .travel-log-flyout[data-form-view='true'] form {
    display: block; }
  .travel-log-flyout[data-form-view='true'] footer {
    display: none; }
  .travel-log-flyout.form-view-only form {
    display: block; }
  @media (min-width: 640px) {
    .travel-log-flyout ul li .btn-overlay .icon-svg {
      width: 38px;
      height: 38px; } }
  @media (min-width: 768px) {
    .travel-log-flyout {
      padding: 0 40px 2px; }
      .travel-log-flyout .description {
        margin-bottom: 0.5rem; }
      .travel-log-flyout .form-group {
        width: 386px;
        margin: 0.5rem auto; }
      .travel-log-flyout ul {
        width: 470px;
        margin: 0 auto 2rem; }
        .travel-log-flyout ul li {
          padding: 10px 15px; }
          .travel-log-flyout ul li .btn-overlay .icon-svg {
            width: 24px;
            height: 24px; }
      .travel-log-flyout form {
        margin-bottom: 2rem; }
      .travel-log-flyout footer {
        margin-bottom: 40px; } }

.trip-listing {
  margin-bottom: 4em; }
  .trip-listing > .container, .trip-listing > .form-factory {
    border-bottom: 13px solid #3cc1cc; }

.other-experiences-listing {
  margin-bottom: calc((42 / 640vw ) * 100); }
  .other-experiences-listing figure {
    margin: calc((15 / 640vw ) * 100) 0 calc((40 / 640vw ) * 100); }
    .other-experiences-listing figure picture {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: calc((396 / 640vw ) * 100);
      height: calc((396 / 640vw ) * 100);
      margin: 0; }
      .other-experiences-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .other-experiences-listing .btn-overlay {
    right: calc((40 / 640vw ) * 100); }
    .other-experiences-listing .btn-overlay .icon-svg svg {
      fill: #000000; }
  .other-experiences-listing .headline-4 {
    margin-bottom: calc((20 / 640vw ) * 100); }
  .other-experiences-listing .location,
  .other-experiences-listing time {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin: 0;
    font-weight: 400; }
    @media (min-width: 640px) {
      .other-experiences-listing .location,
      .other-experiences-listing time {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .other-experiences-listing .location,
      .other-experiences-listing time {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .other-experiences-listing .location,
    .other-experiences-listing time {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 640px) {
    .other-experiences-listing figure picture {
      width: 396px;
      height: 396px; } }
  @media (min-width: 768px) {
    .other-experiences-listing {
      margin-bottom: 80px; }
      .other-experiences-listing figure {
        margin: 15px 0 10px; }
        .other-experiences-listing figure picture {
          width: 192px;
          height: 192px; }
      .other-experiences-listing .btn-overlay {
        right: 0; }
      .other-experiences-listing .headline-4 {
        margin-bottom: 5px; }
      .other-experiences-listing .location,
      .other-experiences-listing time {
        display: block; } }

.user-profile header {
  margin-bottom: 20px; }
  .user-profile header .feature {
    height: calc((250 / 640vw ) * 100); }
  .user-profile header .btn-profile {
    width: calc((250 / 640vw ) * 100);
    height: calc((250 / 640vw ) * 100);
    padding: 0;
    background-color: #3cc1cc;
    border-radius: 50%; }
    .user-profile header .btn-profile > .icon-svg {
      width: calc((250 / 640vw ) * 100);
      height: calc((250 / 640vw ) * 100);
      border-radius: 50%;
      overflow: hidden; }
      .user-profile header .btn-profile > .icon-svg svg {
        margin-top: 5%;
        padding-top: 25%;
        fill: #f7f7f7; }
    .user-profile header .btn-profile .overlay {
      position: absolute;
      display: none;
      top: 0;
      width: calc((250 / 640vw ) * 100);
      height: calc((250 / 640vw ) * 100);
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.75); }
      .user-profile header .btn-profile .overlay .content {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
        line-height: 1em;
        color: #ffffff; }
      .user-profile header .btn-profile .overlay .icon-svg {
        display: block;
        width: calc((40 / 640vw ) * 100);
        height: calc((40 / 640vw ) * 100);
        margin: 0 auto 0.75em auto; }
        .user-profile header .btn-profile .overlay .icon-svg svg {
          fill: #ffffff; }
    .user-profile header .btn-profile:hover .overlay {
      display: block; }
  .user-profile header .container-inset-feature picture {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: calc((250 / 640vw ) * 100);
    height: calc((250 / 640vw ) * 100); }
    .user-profile header .container-inset-feature picture img {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      width: initial;
      min-width: 100%;
      height: inherit;
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;"; }
  .user-profile header .headline {
    text-transform: none;
    margin: calc((125 / 640vw ) * 100) 0 25px 0; }

.user-profile .nav-tabs {
  margin-bottom: 60px; }

.user-profile .user-profile-accordion {
  margin-bottom: 120px; }
  .user-profile .user-profile-accordion .card {
    margin-bottom: 40px; }
    .user-profile .user-profile-accordion .card .card-header {
      padding: 30px 35px 30px 40px;
      background-color: transparent;
      border: 0; }
      .user-profile .user-profile-accordion .card .card-header .headline .icon-svg {
        display: block;
        width: 32px;
        height: 32px;
        margin: -4px 8px 0.75em 0; }
        .user-profile .user-profile-accordion .card .card-header .headline .icon-svg svg {
          fill: #000000; }
      .user-profile .user-profile-accordion .card .card-header .card-controls {
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        margin: 20px; }
        .user-profile .user-profile-accordion .card .card-header .card-controls button {
          font-size: calc((28 / 640vw ) * 100);
          line-height: calc((40 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100);
          font-weight: 300;
          color: #2b2b2b;
          position: relative;
          height: 48px;
          margin-bottom: 1rem;
          vertical-align: middle; }
          @media (min-width: 640px) {
            .user-profile .user-profile-accordion .card .card-header .card-controls button {
              font-size: 28px;
              font-size: 1.75rem;
              line-height: 40px;
              letter-spacing: 0px; } }
          @media (min-width: 1024px) {
            .user-profile .user-profile-accordion .card .card-header .card-controls button {
              font-size: calc((14 / 640vw ) * 100);
              line-height: calc((24 / 640vw ) * 100);
              letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .user-profile .user-profile-accordion .card .card-header .card-controls button {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
          .user-profile .user-profile-accordion .card .card-header .card-controls button .icon-svg {
            width: 24px;
            height: 24px;
            margin: -2px 8px 0 0;
            transform: rotate(0deg);
            transition: transform 0.3s; }
            .user-profile .user-profile-accordion .card .card-header .card-controls button .icon-svg svg {
              fill: #000000; }
          .user-profile .user-profile-accordion .card .card-header .card-controls button.collapsed .icon-svg {
            transform: rotate(180deg); }
    .user-profile .user-profile-accordion .card .card-block {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      margin-bottom: 1rem;
      padding: 0 40px 0 40px;
      text-align: center; }
      @media (min-width: 640px) {
        .user-profile .user-profile-accordion .card .card-block {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .user-profile .user-profile-accordion .card .card-block {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .user-profile .user-profile-accordion .card .card-block {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .user-profile .user-profile-accordion .card .card-block .headline {
        margin: 0 0 0.25em 0; }
      .user-profile .user-profile-accordion .card .card-block ul.list-unstyled {
        display: flex;
        flex-wrap: wrap;
        margin-right: -9.5px;
        margin-left: -9.5px;
        margin: 6px 0 0 0; }
        .user-profile .user-profile-accordion .card .card-block ul.list-unstyled li {
          position: relative;
          width: 100%;
          padding-right: 9.5px;
          padding-left: 9.5px;
          flex: 0 0 100%;
          max-width: 100%;
          margin: 15px 0; }
        .user-profile .user-profile-accordion .card .card-block ul.list-unstyled .form-check-input {
          margin-right: 8px; }
      .user-profile .user-profile-accordion .card .card-block .btn-transparent {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((30 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100);
        margin-bottom: 0.5em;
        margin-left: -6px;
        text-decoration: underline; }
        @media (min-width: 640px) {
          .user-profile .user-profile-accordion .card .card-block .btn-transparent {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 32px;
            letter-spacing: 0.5px; } }
        @media (min-width: 768px) {
          .user-profile .user-profile-accordion .card .card-block .btn-transparent {
            font-size: calc((14 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .user-profile .user-profile-accordion .card .card-block .btn-transparent {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
    .user-profile .user-profile-accordion .card [data-view], .user-profile .user-profile-accordion .card [data-action="edit"] {
      display: none; }
    .user-profile .user-profile-accordion .card label {
      display: block; }
      .user-profile .user-profile-accordion .card label.custom-select-label {
        margin-bottom: 0.5rem; }
        .user-profile .user-profile-accordion .card label.custom-select-label span:not(.icon-svg) {
          position: relative;
          z-index: 1; }
        .user-profile .user-profile-accordion .card label.custom-select-label .icon-svg {
          top: 50%; }
        .user-profile .user-profile-accordion .card label.custom-select-label select {
          position: absolute;
          top: 0;
          left: 0;
          padding-top: 15px;
          padding-left: 14px;
          color: #2b2b2b; }
    .user-profile .user-profile-accordion .card .card-footer {
      background-color: transparent;
      margin-bottom: 3em;
      border: 0;
      text-align: center; }
    .user-profile .user-profile-accordion .card .btn-remove {
      text-decoration: underline;
      padding-left: 0px; }

.user-profile .form-control[readonly] {
  background-color: transparent; }

.user-profile .form-control-static {
  display: inline-block;
  line-height: inherit;
  padding-top: 0; }

.user-profile label[aria-disabled="true"] {
  display: none; }

.user-profile .custom-control .custom-control-indicator-read-only {
  position: absolute;
  top: 0;
  left: 0;
  display: none; }
  .user-profile .custom-control .custom-control-indicator-read-only .icon-svg {
    width: 1.5em;
    height: 1.5em;
    margin: -1px 0.35em 0 0;
    vertical-align: middle; }
    .user-profile .custom-control .custom-control-indicator-read-only .icon-svg svg {
      fill: #c6c6c6; }
      .user-profile .custom-control .custom-control-indicator-read-only .icon-svg svg:first-child {
        display: inline-block; }
      .user-profile .custom-control .custom-control-indicator-read-only .icon-svg svg:last-child {
        display: none; }

.user-profile .custom-control .custom-control-input:checked ~ .custom-control-indicator-read-only .icon-svg svg {
  fill: #007697; }
  .user-profile .custom-control .custom-control-input:checked ~ .custom-control-indicator-read-only .icon-svg svg:first-child {
    display: none; }
  .user-profile .custom-control .custom-control-input:checked ~ .custom-control-indicator-read-only .icon-svg svg:last-child {
    display: inline-block; }

.user-profile .custom-control[aria-disabled="true"] {
  pointer-events: none; }
  .user-profile .custom-control[aria-disabled="true"] .custom-control-indicator {
    display: none; }
  .user-profile .custom-control[aria-disabled="true"] .custom-control-indicator-read-only {
    display: block; }

@media (min-width: 640px) {
  .user-profile header .feature {
    height: 250px; }
  .user-profile header .btn-profile {
    width: 250px;
    height: 250px; }
    .user-profile header .btn-profile .icon-svg {
      width: 250px;
      height: 250px; }
    .user-profile header .btn-profile .overlay {
      width: 250px;
      height: 250px; }
      .user-profile header .btn-profile .overlay .icon-svg {
        width: 40px;
        height: 40px; }
  .user-profile header .container-inset-feature picture {
    width: 250px;
    height: 250px; } }

@media (min-width: 768px) {
  .user-profile header .feature {
    height: calc((200 / 1024vw ) * 100); }
  .user-profile header .btn-profile {
    width: calc((160 / 1024vw ) * 100);
    height: calc((160 / 1024vw ) * 100); }
    .user-profile header .btn-profile .icon-svg {
      width: calc((160 / 1024vw ) * 100);
      height: calc((160 / 1024vw ) * 100); }
    .user-profile header .btn-profile .overlay {
      width: calc((160 / 1024vw ) * 100);
      height: calc((160 / 1024vw ) * 100); }
      .user-profile header .btn-profile .overlay .icon-svg {
        width: calc((25 / 1024vw ) * 100);
        height: calc((25 / 1024vw ) * 100); }
  .user-profile header .container-inset-feature picture {
    width: calc((160 / 1024vw ) * 100);
    height: calc((160 / 1024vw ) * 100); }
  .user-profile header .headline {
    text-transform: none;
    margin-top: 70px; }
  .user-profile .user-profile-accordion .card .card-header .headline .icon-svg {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-bottom: 0; }
  .user-profile .user-profile-accordion .card .card-block {
    padding: 0 80px 0 80px; }
    .user-profile .user-profile-accordion .card .card-block ul.list-unstyled li {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      margin: 0 0 0.25em 0; }
  .user-profile .user-profile-accordion .card .card-footer .btn {
    min-width: 165px; } }

@media (min-width: 1024px) {
  .user-profile header .feature {
    height: 200px; }
  .user-profile header .btn-profile {
    width: 160px;
    height: 160px; }
    .user-profile header .btn-profile .icon-svg {
      width: 160px;
      height: 160px; }
    .user-profile header .btn-profile .overlay {
      width: 160px;
      height: 160px; }
      .user-profile header .btn-profile .overlay .icon-svg {
        width: 24px;
        height: 24px; }
  .user-profile header .container-inset-feature picture {
    width: 160px;
    height: 160px; } }

.user-travel-log-list article {
  margin: 0 calc((20 / 640vw ) * 100) 0 0; }

@media (min-width: 768px) {
  .user-travel-log-list .carousel {
    padding: 0 50px; }
  .user-travel-log-list article {
    margin: 0; } }

.user-travel-log-listing {
  border: 0; }
  .user-travel-log-listing:after {
    display: none; }
  .user-travel-log-listing .btn-overlay {
    margin: 0.25em; }
    .user-travel-log-listing .btn-overlay .icon-svg svg {
      fill: #ffffff; }
  .user-travel-log-listing figure {
    position: relative;
    width: 100%; }
  .user-travel-log-listing .content {
    padding: 0;
    flex-direction: row; }
  .user-travel-log-listing .headline-5 {
    width: 65%;
    padding-right: 20px; }
  .user-travel-log-listing .category {
    margin-top: 0; }
  @media (max-width: 767.98px) {
    .user-travel-log-listing .content {
      width: 100%; }
    .user-travel-log-listing .headline-5 {
      width: 100%; } }

ul.utility-nav {
  margin: calc((40 / 640vw ) * 100) 0;
  text-align: center;
  z-index: 3; }
  ul.utility-nav li {
    display: inline-block;
    border: 0; }
    ul.utility-nav li:not(.d-none) + li:before {
      display: inline-block;
      width: 1px;
      height: 1.5em;
      margin: 0 1em 0 0.25em;
      content: '';
      background-color: #2b2b2b;
      vertical-align: text-bottom;
      opacity: 0.46; }
    ul.utility-nav li a {
      display: inline-block;
      margin: 0.125rem;
      color: #2b2b2b;
      letter-spacing: 0.1px;
      font-weight: 300;
      font-size: calc((28 / 640vw ) * 100); }
      ul.utility-nav li a:hover, ul.utility-nav li a:focus {
        text-decoration: none;
        color: #007697; }
        ul.utility-nav li a:hover .icon-svg svg, ul.utility-nav li a:focus .icon-svg svg {
          fill: #007697; }
      ul.utility-nav li a .icon-svg {
        display: inline-block;
        width: 0.9em;
        height: 0.9em;
        margin: 0 0.25em; }
  .property-top-nav.is-stuck ul.utility-nav {
    opacity: 0; }
  @media (min-width: 640px) {
    ul.utility-nav {
      margin: 40px 0; }
      ul.utility-nav li a {
        font-size: 28px; } }
  @media (min-width: 1024px) {
    ul.utility-nav {
      position: absolute;
      top: 0;
      right: 150px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      opacity: 1;
      transition: all 0.3s ease-out;
      z-index: 1; }
      ul.utility-nav li {
        display: inline-block; }
        ul.utility-nav li:not(.d-none) + li:before {
          display: none; }
        ul.utility-nav li a {
          padding: 0.25em 0.5em;
          font-size: 12px;
          font-weight: 300;
          line-height: 2.25em; }
          ul.utility-nav li a .icon-svg {
            margin-top: -2px;
            width: 12px;
            height: 12px; } }

@media (min-width: 1024px) {
  div.utility-nav {
    position: absolute;
    top: 0;
    right: 150px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    opacity: 1;
    transition: all 0.3s ease-out;
    z-index: 1; }
    div.utility-nav a {
      padding: 0.25em 0.5em;
      font-size: 12px;
      font-weight: 300;
      line-height: 2.25em;
      margin: .125rem;
      display: inline-block; }
      div.utility-nav a .icon-svg {
        margin-top: -2px;
        width: 12px;
        height: 12px; } }

.vacation-experience-list {
  padding: calc((40 / 640vw ) * 100) 0 0 0; }
  .vacation-experience-list h2 {
    margin-bottom: calc((50 / 640vw ) * 100);
    padding: 0 calc((20 / 640vw ) * 100); }
  @media (min-width: 640px) {
    .vacation-experience-list {
      padding: 40px 0 0 0; } }
  @media (min-width: 768px) {
    .vacation-experience-list h2 {
      margin-bottom: 1em;
      padding: 0; } }

.vacation-experience-listing {
  position: relative;
  width: 100%;
  padding-right: 9.5px;
  padding-left: 9.5px; }
  .vacation-experience-listing figure {
    display: inline-block;
    width: 100%; }
    .vacation-experience-listing figure picture {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      padding-bottom: 125%; }
      .vacation-experience-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  @media (min-width: 768px) {
    .vacation-experience-listing {
      width: 20%; }
      .vacation-experience-listing figure {
        display: inline-block;
        width: 100%; }
        .vacation-experience-listing figure picture {
          padding-bottom: 129.775%; } }

.vacation-inspiration {
  padding: calc((92 / 640vw ) * 100) 0 calc((30 / 640vw ) * 100) 0;
  text-align: center; }
  .vacation-inspiration a {
    display: block;
    color: inherit;
    text-decoration: none; }
  .vacation-inspiration ul {
    padding: 0;
    list-style: none; }
    .vacation-inspiration ul li {
      display: block;
      text-align: left; }
  .vacation-inspiration .background {
    top: 0px;
    position: absolute;
    width: 100%;
    height: initial;
    min-height: 100%;
    overflow: hidden; }
    .vacation-inspiration .background picture img, .vacation-inspiration .background video, .vacation-inspiration .background iframe {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 101%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center;'; }
    .vacation-inspiration .background picture {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%; }
    .vacation-inspiration .background video, .vacation-inspiration .background iframe {
      display: block; }
    @media screen and (min-width: 0\0) {
      .vacation-inspiration .background video {
        height: auto; } }
    @supports (-ms-ime-align: auto) {
      .vacation-inspiration .background video {
        height: auto; } }
  .vacation-inspiration article .category {
    display: block;
    font-weight: 500;
    text-transform: none;
    font-size: calc((30 / 640vw ) * 100);
    line-height: calc((32 / 640vw ) * 100);
    letter-spacing: calc((0.5 / 640vw ) * 100);
    margin-bottom: 0.5em;
    display: block;
    width: 100%;
    padding-top: 0.5em;
    text-transform: lowercase;
    border-top-style: solid;
    border-top-width: calc((10 / 640vw ) * 100);
    border-color: #000000; }
    @media (min-width: 640px) {
      .vacation-inspiration article .category {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 32px;
        letter-spacing: 0.5px; } }
    @media (min-width: 768px) {
      .vacation-inspiration article .category {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .vacation-inspiration article .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
  .vacation-inspiration article .content {
    display: inline-block;
    width: calc((250 / 640vw ) * 100);
    margin-top: calc((10 / 640vw ) * 100);
    vertical-align: top; }
  .vacation-inspiration article figure {
    display: inline-block;
    margin: 0 calc((42 / 640vw ) * 100) calc((54 / 640vw ) * 100) calc((15 / 640vw ) * 100); }
    .vacation-inspiration article figure picture {
      position: relative;
      overflow: hidden;
      display: inline-block;
      width: calc((240 / 640vw ) * 100);
      height: calc((240 / 640vw ) * 100);
      vertical-align: top; }
      .vacation-inspiration article figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
  .vacation-inspiration article .vacation-inspiration-footer {
    margin: calc((16 / 640vw ) * 100) 0 calc((54 / 640vw ) * 100) 0; }
    .vacation-inspiration article .vacation-inspiration-footer .link {
      font-size: calc((28 / 640vw ) * 100);
      line-height: calc((40 / 640vw ) * 100);
      letter-spacing: calc((0 / 640vw ) * 100);
      font-weight: 300;
      color: #2b2b2b;
      display: inline-block;
      margin-bottom: 1rem;
      text-decoration: none;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      cursor: pointer; }
      @media (min-width: 640px) {
        .vacation-inspiration article .vacation-inspiration-footer .link {
          font-size: 28px;
          font-size: 1.75rem;
          line-height: 40px;
          letter-spacing: 0px; } }
      @media (min-width: 1024px) {
        .vacation-inspiration article .vacation-inspiration-footer .link {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .vacation-inspiration article .vacation-inspiration-footer .link {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
      .vacation-inspiration article .vacation-inspiration-footer .link:hover, .vacation-inspiration article .vacation-inspiration-footer .link:focus {
        font-weight: 400; }
  .vacation-inspiration .color-theme-teal img {
    background-color: #3cc1cc; }
  .vacation-inspiration .color-theme-teal .category, .vacation-inspiration .color-theme-teal .vacation-inspiration-footer .link {
    border-color: #3cc1cc; }
  .vacation-inspiration .color-theme-purple img {
    background-color: #7765a1; }
  .vacation-inspiration .color-theme-purple .category, .vacation-inspiration .color-theme-purple .vacation-inspiration-footer .link {
    border-color: #7765a1; }
  .vacation-inspiration .color-theme-green img {
    background-color: #87cca1; }
  .vacation-inspiration .color-theme-green .category, .vacation-inspiration .color-theme-green .vacation-inspiration-footer .link {
    border-color: #87cca1; }
  @media (min-width: 640px) {
    .vacation-inspiration {
      padding: 92px 0 30px 0; }
      .vacation-inspiration article .category {
        border-top-width: 10px; }
      .vacation-inspiration article .content {
        margin-top: 10px; }
      .vacation-inspiration article figure {
        margin: 0 42px 54px 15px; }
      .vacation-inspiration article .vacation-inspiration-footer {
        margin: 16px 0 54px 0; } }
  @media (min-width: 768px) {
    .vacation-inspiration {
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;
      padding: 92px 0 30px 0; }
      .vacation-inspiration:after {
        position: absolute;
        top: 0;
        left: 0;
        border-left: 180vw solid #f7f7f7;
        border-bottom: 360px solid transparent;
        content: "";
        z-index: -1; }
      .vacation-inspiration ul li {
        display: inline-block;
        width: 300px; }
      .vacation-inspiration article figure {
        display: block;
        margin: 0 auto 36px auto; }
        .vacation-inspiration article figure picture {
          position: relative;
          display: block;
          width: calc((274 / 1024vw ) * 100);
          height: calc((274 / 1024vw ) * 100); }
          .vacation-inspiration article figure picture img {
            width: auto;
            max-height: 100%; }
      .vacation-inspiration article .content {
        width: 100%; }
      .vacation-inspiration article .category {
        width: 90%;
        padding-top: 15px; }
      .vacation-inspiration article .vacation-inspiration-footer {
        margin: 15px 0 15px 0; }
        .vacation-inspiration article .vacation-inspiration-footer .link {
          border-bottom-width: 1px; } }
  @media (min-width: 1024px) {
    .vacation-inspiration article figure picture {
      width: 274px;
      height: 274px; } }

.vacation-inspiration-list {
  padding: 0 0 calc((40 / 640vw ) * 100) 0;
  text-align: center; }
  .vacation-inspiration-list .btn-more {
    margin-top: calc((60 / 640vw ) * 100); }
  .vacation-inspiration-list .container .row .col-md-4:last-child .vacation-inspiration-listing, .vacation-inspiration-list .form-factory .row .col-md-4:last-child .vacation-inspiration-listing {
    border: 0; }
  @media (min-width: 640px) {
    .vacation-inspiration-list {
      padding: 0 0 40px 0; }
      .vacation-inspiration-list .btn-more {
        margin-top: 60px; } }
  @media (min-width: 768px) {
    .vacation-inspiration-list .btn-more {
      margin-top: 40px; } }

.vacation-inspiration-listing {
  position: relative;
  display: flex;
  margin: 0;
  padding: calc((32 / 640vw ) * 100) 0 calc((36 / 640vw ) * 100) 0;
  flex-direction: row;
  border-bottom: 1px solid #e1e1e1; }
  .vacation-inspiration-listing .btn-overlay {
    margin: 0.75em 0.25em; }
    .vacation-inspiration-listing .btn-overlay .icon-svg svg {
      fill: #000000; }
  .vacation-inspiration-listing .d-flex {
    width: 100%; }
  .vacation-inspiration-listing figure {
    display: inline-block;
    width: 40%;
    margin: 0; }
    .vacation-inspiration-listing figure a {
      display: block; }
    .vacation-inspiration-listing figure picture {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      height: 100%;
      padding-bottom: 66.6667%; }
      .vacation-inspiration-listing figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
      .vacation-inspiration-listing figure picture img {
        opacity: 1; }
  .vacation-inspiration-listing .content {
    display: flex;
    max-width: 50%;
    padding: 0 50px 0 14px;
    flex-direction: column;
    text-align: left; }
  .vacation-inspiration-listing .category {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin: auto 0 0 0; }
    @media (min-width: 640px) {
      .vacation-inspiration-listing .category {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .vacation-inspiration-listing .category {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .vacation-inspiration-listing .category {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 640px) {
    .vacation-inspiration-listing {
      padding: 32px 0 36px 0; } }
  @media (min-width: 768px) {
    .vacation-inspiration-listing {
      display: block;
      padding: 0;
      margin-bottom: 2em;
      border: 0; }
      .vacation-inspiration-listing:after {
        display: none; }
      .vacation-inspiration-listing .btn-overlay {
        margin: 0; }
        .vacation-inspiration-listing .btn-overlay .icon-svg svg {
          fill: #ffffff; }
      .vacation-inspiration-listing figure {
        margin-bottom: 10px;
        width: 100%; }
        .vacation-inspiration-listing figure picture {
          width: 100%;
          height: 100%;
          padding-bottom: 66.45%; }
      .vacation-inspiration-listing .content {
        max-width: none;
        padding: 0;
        flex-direction: row; }
      .vacation-inspiration-listing .headline-5 {
        width: 70%;
        padding-right: 20px; }
      .vacation-inspiration-listing .category {
        margin: 0 0 0 auto; } }

.vacations-in-progress {
  text-align: center;
  margin: calc((8 / 640vw ) * 100) 0; }
  .vacations-in-progress .figure-group {
    padding: calc((12 / 640vw ) * 100) calc((6 / 640vw ) * 100); }
  .vacations-in-progress figure {
    position: relative;
    display: block;
    float: left;
    margin: calc((6 / 640vw ) * 100); }
    .vacations-in-progress figure picture {
      position: relative;
      overflow: hidden;
      display: block;
      width: calc((294 / 640vw ) * 100);
      height: calc((294 / 640vw ) * 100);
      margin: 0; }
      .vacations-in-progress figure picture img {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: initial;
        min-width: 100%;
        height: inherit;
        object-fit: cover;
        font-family: "object-fit: cover; object-position: center;"; }
    .vacations-in-progress figure figcaption [data-profile-photo] {
      display: flex;
      width: 48px;
      height: 48px; }
    .vacations-in-progress figure .icon-overlay {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      width: calc((28 / 640vw ) * 100);
      height: calc((28 / 640vw ) * 100);
      margin-right: calc((12 / 640vw ) * 100);
      margin-bottom: calc((12 / 640vw ) * 100);
      z-index: 50; }
      .vacations-in-progress figure .icon-overlay svg {
        fill: #ffffff; }
  .vacations-in-progress .carousel-indicators {
    margin-top: calc((35 / 640vw ) * 100); }
  .vacations-in-progress .slick-dots li {
    display: inline-block; }
    .vacations-in-progress .slick-dots li button {
      display: block; }
  .vacations-in-progress .slick-prev, .vacations-in-progress .slick-next {
    top: 2.5vw;
    left: 0;
    visibility: hidden; }
  .vacations-in-progress footer .headline {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 1em 0; }
  .vacations-in-progress footer .btn {
    display: block;
    width: 60%;
    margin-right: auto;
    margin-left: auto; }
  .vacations-in-progress footer .olapic-widget-rendered {
    margin-left: auto;
    margin-top: 0.75em; }
    .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((30 / 640vw ) * 100);
      line-height: calc((32 / 640vw ) * 100);
      letter-spacing: calc((0.5 / 640vw ) * 100);
      margin-bottom: 0.5em;
      display: inline-block;
      white-space: nowrap;
      min-width: calc((180 / 640vw ) * 100);
      margin-bottom: 1rem;
      padding: 5px;
      border-width: 2px;
      background-color: #007697;
      color: #ffffff;
      line-height: 1.25em;
      text-transform: lowercase;
      cursor: default;
      background-color: #ffffff;
      color: #007697;
      border-color: #007697;
      background: #ffffff;
      border-radius: 0;
      padding-left: 10px;
      padding-right: 10px;
      text-shadow: none; }
      @media (min-width: 640px) {
        .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 32px;
          letter-spacing: 0.5px; } }
      @media (min-width: 768px) {
        .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
          font-size: calc((14 / 640vw ) * 100);
          line-height: calc((24 / 640vw ) * 100);
          letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
      @supports not (-ms-high-contrast: none) {
        .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
          padding: calc((22 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((25 / 640vw ) * 100); } }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-sm, .vacations-in-progress footer .olapic-widget-rendered .btn-group-sm > a.btn.btn-default {
        display: block;
        font-weight: 500;
        text-transform: none;
        font-size: calc((26 / 640vw ) * 100);
        line-height: calc((32 / 640vw ) * 100);
        letter-spacing: calc((0.55 / 640vw ) * 100);
        margin-bottom: 0.5em;
        display: inline-block;
        padding: calc((17 / 640vw ) * 100) calc((64 / 640vw ) * 100) calc((19 / 640vw ) * 100);
        line-height: 1.25em;
        text-transform: lowercase; }
        @media (min-width: 640px) {
          .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-sm, .vacations-in-progress footer .olapic-widget-rendered .btn-group-sm > a.btn.btn-default {
            font-size: 26px;
            font-size: 1.625rem;
            line-height: 32px;
            letter-spacing: 0.55px; } }
        @media (min-width: 768px) {
          .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-sm, .vacations-in-progress footer .olapic-widget-rendered .btn-group-sm > a.btn.btn-default {
            font-size: calc((12 / 640vw ) * 100);
            line-height: calc((24 / 640vw ) * 100);
            letter-spacing: calc((0.5 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-sm, .vacations-in-progress footer .olapic-widget-rendered .btn-group-sm > a.btn.btn-default {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0.5px; } }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default:hover, .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default:focus {
        color: #ffffff;
        background-color: #031b21; }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default .icon-svg {
        fill: #ffffff; }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-outline:focus {
        background-color: #031b21;
        color: #ffffff; }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-outline.btn-primary:focus {
        background-color: #b8146a;
        color: #ffffff; }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-secondary {
        border-color: transparent; }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default:hover {
        background-color: #007697;
        color: #ffffff; }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-primary {
        border-color: #b8146a;
        color: #b8146a; }
        .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-primary:hover {
          background-color: #b8146a;
          color: #ffffff; }
      @media (min-width: 640px) {
        .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
          min-width: 180px;
          margin-bottom: 0;
          padding: 22px 64px 25px;
          line-height: 1.25em; }
          .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-sm, .vacations-in-progress footer .olapic-widget-rendered .btn-group-sm > a.btn.btn-default {
            padding: 16px 64px 20px;
            line-height: 1.25em; } }
      @media (min-width: 768px) {
        .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
          min-width: 100px;
          padding: 13px 32px 14px;
          line-height: 1.25em; }
          .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default.btn-sm, .vacations-in-progress footer .olapic-widget-rendered .btn-group-sm > a.btn.btn-default {
            padding: 11px 32px 10px;
            line-height: 1.25em; } }
      .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default:hover {
        color: #ffffff;
        background: #007697; }
  .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    font-size: calc((26 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    margin-top: 0.5em; }
    @media (min-width: 640px) {
      .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 640px) {
      .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100);
        font-size: calc((12 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
      font-size: 12px;
      font-size: 0.75rem;
      line-height: 24px;
      letter-spacing: 0px; } }
    .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) .icon-svg {
      width: calc((96 / 640vw ) * 100);
      height: calc((96 / 640vw ) * 100);
      margin-left: 0.5em; }
      .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) .icon-svg svg {
        fill: #000000; }
    .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) a {
      text-decoration: none; }
      .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) a:hover, .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) a:focus {
        color: inherit; }
  @media (min-width: 640px) {
    .vacations-in-progress figure .icon-overlay {
      width: 28px;
      height: 28px;
      margin-right: 12px;
      margin-bottom: 12px; }
    .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) .icon-svg {
      width: 96px;
      height: 96px; } }
  @media (min-width: 768px) {
    .vacations-in-progress {
      margin-bottom: 100px; }
      .vacations-in-progress .figure-group {
        display: block;
        padding: 0; }
        .vacations-in-progress .figure-group:nth-child(odd) picture {
          width: calc((197.5 / 640vw ) * 100);
          height: calc((197.5 / 640vw ) * 100); }
        .vacations-in-progress .figure-group:nth-child(even) picture {
          width: calc((94 / 640vw ) * 100);
          height: calc((94 / 640vw ) * 100); }
      .vacations-in-progress figure {
        float: none;
        margin: 0 calc((7.5 / 1024vw ) * 100) calc((15 / 1024vw ) * 100); }
        .vacations-in-progress figure picture {
          display: block; }
      .vacations-in-progress .carousel-indicators {
        display: none;
        margin-top: 14px; }
      .vacations-in-progress .slick-dots li {
        display: none; }
      .vacations-in-progress .slick-prev, .vacations-in-progress .slick-next {
        position: relative;
        display: inline-block;
        top: 0;
        margin: 27px 22px;
        visibility: visible; }
      .vacations-in-progress footer {
        position: relative; }
        .vacations-in-progress footer .headline {
          display: inline-block;
          width: auto;
          margin: 0 1em 0 0;
          line-height: 48px; }
        .vacations-in-progress footer .btn {
          display: inline-block;
          width: auto; }
          .vacations-in-progress footer .btn + .btn {
            margin-top: 0;
            margin-left: 0.75em; }
        .vacations-in-progress footer .olapic-widget-rendered {
          display: inline-block;
          margin-top: 0;
          margin-left: 0.75em; }
          .vacations-in-progress footer .olapic-widget-rendered a.btn.btn-default {
            padding-left: 32px;
            padding-right: 32px; }
        .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
          position: absolute;
          top: calc((-130 / 1024vw ) * 100);
          right: calc((20 / 1024vw ) * 100); }
          .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) .icon-svg {
            width: calc((54 / 1024vw ) * 100);
            height: calc((54 / 1024vw ) * 100); } }
  @media (min-width: 1024px) {
    .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) {
      top: -130px;
      right: 20px; }
      .vacations-in-progress footer .olapic:not(.olapic-widget-rendered) .icon-svg {
        width: 54px;
        height: 54px; } }

.what-people-are-saying {
  height: calc((1050 / 640vw ) * 100);
  overflow: hidden; }
  .what-people-are-saying .slick-track {
    display: flex; }
  .what-people-are-saying .slick-slide {
    height: auto; }
  .what-people-are-saying .col, .what-people-are-saying .slick-initialized .slick-slide {
    display: flex; }
  .what-people-are-saying .carousel-card, .what-people-are-saying .a-list-status-item, .what-people-are-saying .experiences-detail-cards .experiences-card, .experiences-detail-cards .what-people-are-saying .experiences-card {
    padding: calc((44 / 640vw ) * 100) calc((60 / 640vw ) * 100) calc((18 / 640vw ) * 100); }
    .what-people-are-saying .carousel-card figure img, .what-people-are-saying .a-list-status-item figure img, .what-people-are-saying .experiences-detail-cards .experiences-card figure img, .experiences-detail-cards .what-people-are-saying .experiences-card figure img {
      margin: auto;
      width: auto;
      max-width: 70%; }
    .what-people-are-saying .carousel-card .flex-container, .what-people-are-saying .a-list-status-item .flex-container, .what-people-are-saying .experiences-detail-cards .experiences-card .flex-container, .experiences-detail-cards .what-people-are-saying .experiences-card .flex-container {
      width: 100%; }
  .what-people-are-saying .center-and-pt {
    text-align: center;
    padding-top: 3em !important; }
  .what-people-are-saying .icon-quote {
    position: relative;
    display: inline-block;
    width: calc((114 / 640vw ) * 100);
    height: calc((114 / 640vw ) * 100);
    background-color: #007697;
    border-radius: 50%; }
    .what-people-are-saying .icon-quote:before {
      display: block;
      font-weight: 500;
      text-transform: none;
      font-size: calc((56 / 640vw ) * 100);
      line-height: calc((72 / 640vw ) * 100);
      letter-spacing: calc((5 / 640vw ) * 100);
      margin-bottom: 0.75em;
      text-transform: uppercase;
      font-family: "Oswald", sans-serif;
      font-weight: 500;
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 5.625rem;
      padding: calc((40 / 640vw ) * 100) 0 0 calc((6 / 640vw ) * 100);
      color: #ffffff;
      content: "“"; }
      @media (min-width: 640px) {
        .what-people-are-saying .icon-quote:before {
          font-size: 56px;
          font-size: 3.5rem;
          line-height: 72px;
          letter-spacing: 5px; } }
      @media (min-width: 768px) {
        .what-people-are-saying .icon-quote:before {
          font-size: calc((40 / 640vw ) * 100);
          line-height: calc((56 / 640vw ) * 100);
          letter-spacing: calc((5.25 / 640vw ) * 100); } }
  @media (min-width: 768px) and (min-width: 640px) {
    .what-people-are-saying .icon-quote:before {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 56px;
      letter-spacing: 5.25px; } }
      .what-people-are-saying .icon-quote:before mark {
        padding: 0 0.25em 0 0.45em;
        background-image: linear-gradient(transparent, transparent 15%, #bff1ff 15%, #bff1ff 75%, transparent 75%);
        box-decoration-break: clone; }
        @media screen and (min-width: 0\0) {
          .what-people-are-saying .icon-quote:before mark {
            padding: 0; } }
        @supports (-ms-ime-align: auto) {
          .what-people-are-saying .icon-quote:before mark {
            padding: 0; } }
  .what-people-are-saying .headline-5 {
    margin-bottom: calc((18 / 640vw ) * 100);
    line-height: calc((43 / 640vw ) * 100); }
  .what-people-are-saying figure {
    margin: 0 0 calc((26 / 640vw ) * 100) 0; }
    .what-people-are-saying figure picture, .what-people-are-saying figure .icon-quote {
      margin: 0 auto;
      align-self: center; }
      .what-people-are-saying figure picture img, .what-people-are-saying figure .icon-quote img {
        max-height: 125px; }
    .what-people-are-saying figure picture {
      width: 100%; }
  .what-people-are-saying footer {
    font-size: calc((28 / 640vw ) * 100);
    line-height: calc((40 / 640vw ) * 100);
    letter-spacing: calc((0 / 640vw ) * 100);
    font-weight: 300;
    color: #2b2b2b;
    margin-bottom: 1rem; }
    @media (min-width: 640px) {
      .what-people-are-saying footer {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 40px;
        letter-spacing: 0px; } }
    @media (min-width: 1024px) {
      .what-people-are-saying footer {
        font-size: calc((14 / 640vw ) * 100);
        line-height: calc((24 / 640vw ) * 100);
        letter-spacing: calc((0 / 640vw ) * 100); } }
  @media (min-width: 1024px) and (min-width: 640px) {
    .what-people-are-saying footer {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 24px;
      letter-spacing: 0px; } }
  .what-people-are-saying .trip-advisor .logo {
    display: inline-block; }
  @media (min-width: 640px) {
    .what-people-are-saying .icon-quote {
      width: 114px;
      height: 114px; }
    .what-people-are-saying .headline-5 {
      margin-bottom: 18px;
      line-height: 43px; } }
  @media (min-width: 768px) {
    .what-people-are-saying {
      height: calc((800 / 1024vw ) * 100);
      overflow: visible; }
      .what-people-are-saying .carousel-card, .what-people-are-saying .a-list-status-item, .what-people-are-saying .experiences-detail-cards .experiences-card, .experiences-detail-cards .what-people-are-saying .experiences-card {
        padding: calc((50 / 1024vw ) * 100) 30px calc((30 / 1024vw ) * 100); }
      .what-people-are-saying .icon-quote {
        width: 66px;
        height: 66px; }
        .what-people-are-saying .icon-quote:before {
          font-size: 3.75rem;
          padding: 25px 0 0 6px; }
      .what-people-are-saying .headline-5 {
        line-height: 27px; }
      .what-people-are-saying figure {
        display: flex;
        margin: 0 0 26px 0;
        height: 75px; }
        .what-people-are-saying figure picture img {
          max-height: 75px; }
      .what-people-are-saying .trip-advisor .logo {
        height: 22px; }
      .what-people-are-saying .trip-advisor .rating-count {
        font-size: 0.75rem; } }
  @media (min-width: 1024px) {
    .what-people-are-saying {
      height: 650px; }
      .what-people-are-saying .carousel-card, .what-people-are-saying .a-list-status-item, .what-people-are-saying .experiences-detail-cards .experiences-card, .experiences-detail-cards .what-people-are-saying .experiences-card {
        padding: 50px 30px 37px; } }

/* Slider */
/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 46px;
  width: 46px;
  border-radius: 100%;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: 1px solid #c8c8c8; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: #2b2b2b;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/**
 * Variables declared here can be overridden by consuming applications, with
 * the help of the `!default` flag.
 *
 * @example
 *     // overriding $hoverColor
 *     $hoverColor: rgba(red, 0.05);
 *
 *     // overriding image path
 *     $flagsImagePath: "images/";
 *
 *     // import the scss file after the overrides
 *     @import "bower_component/intl-tel-input/src/css/intlTelInput";
 */
.intl-tel-input {
  position: relative;
  display: inline-block; }
  .intl-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .intl-tel-input .hide {
    display: none; }
  .intl-tel-input .v-hide {
    visibility: hidden; }
  .intl-tel-input input, .intl-tel-input input[type=text], .intl-tel-input input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0; }
  .intl-tel-input .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px; }
  .intl-tel-input .selected-flag {
    z-index: 1;
    position: relative;
    width: 36px;
    height: 100%;
    padding: 0 0 0 8px; }
    .intl-tel-input .selected-flag .iti-flag {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; }
    .intl-tel-input .selected-flag .iti-arrow {
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: 6px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555; }
      .intl-tel-input .selected-flag .iti-arrow.up {
        border-top: none;
        border-bottom: 4px solid #555; }
  .intl-tel-input .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #CCC;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll; }
    .intl-tel-input .country-list.dropup {
      bottom: 100%;
      margin-bottom: -1px; }
    .intl-tel-input .country-list .flag-box {
      display: inline-block;
      width: 20px; }
    @media (max-width: 500px) {
      .intl-tel-input .country-list {
        white-space: normal; } }
    .intl-tel-input .country-list .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #CCC; }
    .intl-tel-input .country-list .country {
      padding: 5px 10px; }
      .intl-tel-input .country-list .country .dial-code {
        color: #999; }
    .intl-tel-input .country-list .country.highlight {
      background-color: rgba(0, 0, 0, 0.05); }
    .intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name, .intl-tel-input .country-list .dial-code {
      vertical-align: middle; }
    .intl-tel-input .country-list .flag-box, .intl-tel-input .country-list .country-name {
      margin-right: 6px; }
  .intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel], .intl-tel-input.separate-dial-code input, .intl-tel-input.separate-dial-code input[type=text], .intl-tel-input.separate-dial-code input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0; }
  .intl-tel-input.allow-dropdown .flag-container, .intl-tel-input.separate-dial-code .flag-container {
    right: auto;
    left: 0; }
  .intl-tel-input.allow-dropdown .selected-flag, .intl-tel-input.separate-dial-code .selected-flag {
    width: 46px; }
  .intl-tel-input.allow-dropdown .flag-container:hover {
    cursor: pointer; }
    .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
      background-color: rgba(0, 0, 0, 0.05); }
  .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover {
    cursor: default; }
    .intl-tel-input.allow-dropdown input[disabled] + .flag-container:hover .selected-flag, .intl-tel-input.allow-dropdown input[readonly] + .flag-container:hover .selected-flag {
      background-color: transparent; }
  .intl-tel-input.separate-dial-code .selected-flag {
    background-color: rgba(0, 0, 0, 0.05);
    display: table; }
  .intl-tel-input.separate-dial-code .selected-dial-code {
    display: table-cell;
    vertical-align: middle;
    padding-left: 28px; }
  .intl-tel-input.separate-dial-code.iti-sdc-2 input, .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-2 input[type=tel] {
    padding-left: 66px; }
  .intl-tel-input.separate-dial-code.iti-sdc-2 .selected-flag {
    width: 60px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 input[type=tel] {
    padding-left: 76px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-2 .selected-flag {
    width: 70px; }
  .intl-tel-input.separate-dial-code.iti-sdc-3 input, .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-3 input[type=tel] {
    padding-left: 74px; }
  .intl-tel-input.separate-dial-code.iti-sdc-3 .selected-flag {
    width: 68px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
    padding-left: 84px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 .selected-flag {
    width: 78px; }
  .intl-tel-input.separate-dial-code.iti-sdc-4 input, .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-4 input[type=tel] {
    padding-left: 82px; }
  .intl-tel-input.separate-dial-code.iti-sdc-4 .selected-flag {
    width: 76px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type=tel] {
    padding-left: 92px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
    width: 86px; }
  .intl-tel-input.separate-dial-code.iti-sdc-5 input, .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.iti-sdc-5 input[type=tel] {
    padding-left: 90px; }
  .intl-tel-input.separate-dial-code.iti-sdc-5 .selected-flag {
    width: 84px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
    padding-left: 100px; }
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 .selected-flag {
    width: 94px; }
  .intl-tel-input.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px; }
    .intl-tel-input.iti-container:hover {
      cursor: pointer; }

.iti-mobile .intl-tel-input.iti-container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }

.iti-mobile .intl-tel-input .country-list {
  max-height: 100%;
  width: 100%; }
  .iti-mobile .intl-tel-input .country-list .country {
    padding: 10px 10px;
    line-height: 1.5em; }

.iti-flag {
  width: 20px; }
  .iti-flag.be {
    width: 18px; }
  .iti-flag.ch {
    width: 15px; }
  .iti-flag.mc {
    width: 19px; }
  .iti-flag.ne {
    width: 18px; }
  .iti-flag.np {
    width: 13px; }
  .iti-flag.va {
    width: 15px; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
      background-size: 5630px 15px; } }
  .iti-flag.ac {
    height: 10px;
    background-position: 0px 0px; }
  .iti-flag.ad {
    height: 14px;
    background-position: -22px 0px; }
  .iti-flag.ae {
    height: 10px;
    background-position: -44px 0px; }
  .iti-flag.af {
    height: 14px;
    background-position: -66px 0px; }
  .iti-flag.ag {
    height: 14px;
    background-position: -88px 0px; }
  .iti-flag.ai {
    height: 10px;
    background-position: -110px 0px; }
  .iti-flag.al {
    height: 15px;
    background-position: -132px 0px; }
  .iti-flag.am {
    height: 10px;
    background-position: -154px 0px; }
  .iti-flag.ao {
    height: 14px;
    background-position: -176px 0px; }
  .iti-flag.aq {
    height: 14px;
    background-position: -198px 0px; }
  .iti-flag.ar {
    height: 13px;
    background-position: -220px 0px; }
  .iti-flag.as {
    height: 10px;
    background-position: -242px 0px; }
  .iti-flag.at {
    height: 14px;
    background-position: -264px 0px; }
  .iti-flag.au {
    height: 10px;
    background-position: -286px 0px; }
  .iti-flag.aw {
    height: 14px;
    background-position: -308px 0px; }
  .iti-flag.ax {
    height: 13px;
    background-position: -330px 0px; }
  .iti-flag.az {
    height: 10px;
    background-position: -352px 0px; }
  .iti-flag.ba {
    height: 10px;
    background-position: -374px 0px; }
  .iti-flag.bb {
    height: 14px;
    background-position: -396px 0px; }
  .iti-flag.bd {
    height: 12px;
    background-position: -418px 0px; }
  .iti-flag.be {
    height: 15px;
    background-position: -440px 0px; }
  .iti-flag.bf {
    height: 14px;
    background-position: -460px 0px; }
  .iti-flag.bg {
    height: 12px;
    background-position: -482px 0px; }
  .iti-flag.bh {
    height: 12px;
    background-position: -504px 0px; }
  .iti-flag.bi {
    height: 12px;
    background-position: -526px 0px; }
  .iti-flag.bj {
    height: 14px;
    background-position: -548px 0px; }
  .iti-flag.bl {
    height: 14px;
    background-position: -570px 0px; }
  .iti-flag.bm {
    height: 10px;
    background-position: -592px 0px; }
  .iti-flag.bn {
    height: 10px;
    background-position: -614px 0px; }
  .iti-flag.bo {
    height: 14px;
    background-position: -636px 0px; }
  .iti-flag.bq {
    height: 14px;
    background-position: -658px 0px; }
  .iti-flag.br {
    height: 14px;
    background-position: -680px 0px; }
  .iti-flag.bs {
    height: 10px;
    background-position: -702px 0px; }
  .iti-flag.bt {
    height: 14px;
    background-position: -724px 0px; }
  .iti-flag.bv {
    height: 15px;
    background-position: -746px 0px; }
  .iti-flag.bw {
    height: 14px;
    background-position: -768px 0px; }
  .iti-flag.by {
    height: 10px;
    background-position: -790px 0px; }
  .iti-flag.bz {
    height: 14px;
    background-position: -812px 0px; }
  .iti-flag.ca {
    height: 10px;
    background-position: -834px 0px; }
  .iti-flag.cc {
    height: 10px;
    background-position: -856px 0px; }
  .iti-flag.cd {
    height: 15px;
    background-position: -878px 0px; }
  .iti-flag.cf {
    height: 14px;
    background-position: -900px 0px; }
  .iti-flag.cg {
    height: 14px;
    background-position: -922px 0px; }
  .iti-flag.ch {
    height: 15px;
    background-position: -944px 0px; }
  .iti-flag.ci {
    height: 14px;
    background-position: -961px 0px; }
  .iti-flag.ck {
    height: 10px;
    background-position: -983px 0px; }
  .iti-flag.cl {
    height: 14px;
    background-position: -1005px 0px; }
  .iti-flag.cm {
    height: 14px;
    background-position: -1027px 0px; }
  .iti-flag.cn {
    height: 14px;
    background-position: -1049px 0px; }
  .iti-flag.co {
    height: 14px;
    background-position: -1071px 0px; }
  .iti-flag.cp {
    height: 14px;
    background-position: -1093px 0px; }
  .iti-flag.cr {
    height: 12px;
    background-position: -1115px 0px; }
  .iti-flag.cu {
    height: 10px;
    background-position: -1137px 0px; }
  .iti-flag.cv {
    height: 12px;
    background-position: -1159px 0px; }
  .iti-flag.cw {
    height: 14px;
    background-position: -1181px 0px; }
  .iti-flag.cx {
    height: 10px;
    background-position: -1203px 0px; }
  .iti-flag.cy {
    height: 13px;
    background-position: -1225px 0px; }
  .iti-flag.cz {
    height: 14px;
    background-position: -1247px 0px; }
  .iti-flag.de {
    height: 12px;
    background-position: -1269px 0px; }
  .iti-flag.dg {
    height: 10px;
    background-position: -1291px 0px; }
  .iti-flag.dj {
    height: 14px;
    background-position: -1313px 0px; }
  .iti-flag.dk {
    height: 15px;
    background-position: -1335px 0px; }
  .iti-flag.dm {
    height: 10px;
    background-position: -1357px 0px; }
  .iti-flag.do {
    height: 13px;
    background-position: -1379px 0px; }
  .iti-flag.dz {
    height: 14px;
    background-position: -1401px 0px; }
  .iti-flag.ea {
    height: 14px;
    background-position: -1423px 0px; }
  .iti-flag.ec {
    height: 14px;
    background-position: -1445px 0px; }
  .iti-flag.ee {
    height: 13px;
    background-position: -1467px 0px; }
  .iti-flag.eg {
    height: 14px;
    background-position: -1489px 0px; }
  .iti-flag.eh {
    height: 10px;
    background-position: -1511px 0px; }
  .iti-flag.er {
    height: 10px;
    background-position: -1533px 0px; }
  .iti-flag.es {
    height: 14px;
    background-position: -1555px 0px; }
  .iti-flag.et {
    height: 10px;
    background-position: -1577px 0px; }
  .iti-flag.eu {
    height: 14px;
    background-position: -1599px 0px; }
  .iti-flag.fi {
    height: 12px;
    background-position: -1621px 0px; }
  .iti-flag.fj {
    height: 10px;
    background-position: -1643px 0px; }
  .iti-flag.fk {
    height: 10px;
    background-position: -1665px 0px; }
  .iti-flag.fm {
    height: 11px;
    background-position: -1687px 0px; }
  .iti-flag.fo {
    height: 15px;
    background-position: -1709px 0px; }
  .iti-flag.fr {
    height: 14px;
    background-position: -1731px 0px; }
  .iti-flag.ga {
    height: 15px;
    background-position: -1753px 0px; }
  .iti-flag.gb {
    height: 10px;
    background-position: -1775px 0px; }
  .iti-flag.gd {
    height: 12px;
    background-position: -1797px 0px; }
  .iti-flag.ge {
    height: 14px;
    background-position: -1819px 0px; }
  .iti-flag.gf {
    height: 14px;
    background-position: -1841px 0px; }
  .iti-flag.gg {
    height: 14px;
    background-position: -1863px 0px; }
  .iti-flag.gh {
    height: 14px;
    background-position: -1885px 0px; }
  .iti-flag.gi {
    height: 10px;
    background-position: -1907px 0px; }
  .iti-flag.gl {
    height: 14px;
    background-position: -1929px 0px; }
  .iti-flag.gm {
    height: 14px;
    background-position: -1951px 0px; }
  .iti-flag.gn {
    height: 14px;
    background-position: -1973px 0px; }
  .iti-flag.gp {
    height: 14px;
    background-position: -1995px 0px; }
  .iti-flag.gq {
    height: 14px;
    background-position: -2017px 0px; }
  .iti-flag.gr {
    height: 14px;
    background-position: -2039px 0px; }
  .iti-flag.gs {
    height: 10px;
    background-position: -2061px 0px; }
  .iti-flag.gt {
    height: 13px;
    background-position: -2083px 0px; }
  .iti-flag.gu {
    height: 11px;
    background-position: -2105px 0px; }
  .iti-flag.gw {
    height: 10px;
    background-position: -2127px 0px; }
  .iti-flag.gy {
    height: 12px;
    background-position: -2149px 0px; }
  .iti-flag.hk {
    height: 14px;
    background-position: -2171px 0px; }
  .iti-flag.hm {
    height: 10px;
    background-position: -2193px 0px; }
  .iti-flag.hn {
    height: 10px;
    background-position: -2215px 0px; }
  .iti-flag.hr {
    height: 10px;
    background-position: -2237px 0px; }
  .iti-flag.ht {
    height: 12px;
    background-position: -2259px 0px; }
  .iti-flag.hu {
    height: 10px;
    background-position: -2281px 0px; }
  .iti-flag.ic {
    height: 14px;
    background-position: -2303px 0px; }
  .iti-flag.id {
    height: 14px;
    background-position: -2325px 0px; }
  .iti-flag.ie {
    height: 10px;
    background-position: -2347px 0px; }
  .iti-flag.il {
    height: 15px;
    background-position: -2369px 0px; }
  .iti-flag.im {
    height: 10px;
    background-position: -2391px 0px; }
  .iti-flag.in {
    height: 14px;
    background-position: -2413px 0px; }
  .iti-flag.io {
    height: 10px;
    background-position: -2435px 0px; }
  .iti-flag.iq {
    height: 14px;
    background-position: -2457px 0px; }
  .iti-flag.ir {
    height: 12px;
    background-position: -2479px 0px; }
  .iti-flag.is {
    height: 15px;
    background-position: -2501px 0px; }
  .iti-flag.it {
    height: 14px;
    background-position: -2523px 0px; }
  .iti-flag.je {
    height: 12px;
    background-position: -2545px 0px; }
  .iti-flag.jm {
    height: 10px;
    background-position: -2567px 0px; }
  .iti-flag.jo {
    height: 10px;
    background-position: -2589px 0px; }
  .iti-flag.jp {
    height: 14px;
    background-position: -2611px 0px; }
  .iti-flag.ke {
    height: 14px;
    background-position: -2633px 0px; }
  .iti-flag.kg {
    height: 12px;
    background-position: -2655px 0px; }
  .iti-flag.kh {
    height: 13px;
    background-position: -2677px 0px; }
  .iti-flag.ki {
    height: 10px;
    background-position: -2699px 0px; }
  .iti-flag.km {
    height: 12px;
    background-position: -2721px 0px; }
  .iti-flag.kn {
    height: 14px;
    background-position: -2743px 0px; }
  .iti-flag.kp {
    height: 10px;
    background-position: -2765px 0px; }
  .iti-flag.kr {
    height: 14px;
    background-position: -2787px 0px; }
  .iti-flag.kw {
    height: 10px;
    background-position: -2809px 0px; }
  .iti-flag.ky {
    height: 10px;
    background-position: -2831px 0px; }
  .iti-flag.kz {
    height: 10px;
    background-position: -2853px 0px; }
  .iti-flag.la {
    height: 14px;
    background-position: -2875px 0px; }
  .iti-flag.lb {
    height: 14px;
    background-position: -2897px 0px; }
  .iti-flag.lc {
    height: 10px;
    background-position: -2919px 0px; }
  .iti-flag.li {
    height: 12px;
    background-position: -2941px 0px; }
  .iti-flag.lk {
    height: 10px;
    background-position: -2963px 0px; }
  .iti-flag.lr {
    height: 11px;
    background-position: -2985px 0px; }
  .iti-flag.ls {
    height: 14px;
    background-position: -3007px 0px; }
  .iti-flag.lt {
    height: 12px;
    background-position: -3029px 0px; }
  .iti-flag.lu {
    height: 12px;
    background-position: -3051px 0px; }
  .iti-flag.lv {
    height: 10px;
    background-position: -3073px 0px; }
  .iti-flag.ly {
    height: 10px;
    background-position: -3095px 0px; }
  .iti-flag.ma {
    height: 14px;
    background-position: -3117px 0px; }
  .iti-flag.mc {
    height: 15px;
    background-position: -3139px 0px; }
  .iti-flag.md {
    height: 10px;
    background-position: -3160px 0px; }
  .iti-flag.me {
    height: 10px;
    background-position: -3182px 0px; }
  .iti-flag.mf {
    height: 14px;
    background-position: -3204px 0px; }
  .iti-flag.mg {
    height: 14px;
    background-position: -3226px 0px; }
  .iti-flag.mh {
    height: 11px;
    background-position: -3248px 0px; }
  .iti-flag.mk {
    height: 10px;
    background-position: -3270px 0px; }
  .iti-flag.ml {
    height: 14px;
    background-position: -3292px 0px; }
  .iti-flag.mm {
    height: 14px;
    background-position: -3314px 0px; }
  .iti-flag.mn {
    height: 10px;
    background-position: -3336px 0px; }
  .iti-flag.mo {
    height: 14px;
    background-position: -3358px 0px; }
  .iti-flag.mp {
    height: 10px;
    background-position: -3380px 0px; }
  .iti-flag.mq {
    height: 14px;
    background-position: -3402px 0px; }
  .iti-flag.mr {
    height: 14px;
    background-position: -3424px 0px; }
  .iti-flag.ms {
    height: 10px;
    background-position: -3446px 0px; }
  .iti-flag.mt {
    height: 14px;
    background-position: -3468px 0px; }
  .iti-flag.mu {
    height: 14px;
    background-position: -3490px 0px; }
  .iti-flag.mv {
    height: 14px;
    background-position: -3512px 0px; }
  .iti-flag.mw {
    height: 14px;
    background-position: -3534px 0px; }
  .iti-flag.mx {
    height: 12px;
    background-position: -3556px 0px; }
  .iti-flag.my {
    height: 10px;
    background-position: -3578px 0px; }
  .iti-flag.mz {
    height: 14px;
    background-position: -3600px 0px; }
  .iti-flag.na {
    height: 14px;
    background-position: -3622px 0px; }
  .iti-flag.nc {
    height: 10px;
    background-position: -3644px 0px; }
  .iti-flag.ne {
    height: 15px;
    background-position: -3666px 0px; }
  .iti-flag.nf {
    height: 10px;
    background-position: -3686px 0px; }
  .iti-flag.ng {
    height: 10px;
    background-position: -3708px 0px; }
  .iti-flag.ni {
    height: 12px;
    background-position: -3730px 0px; }
  .iti-flag.nl {
    height: 14px;
    background-position: -3752px 0px; }
  .iti-flag.no {
    height: 15px;
    background-position: -3774px 0px; }
  .iti-flag.np {
    height: 15px;
    background-position: -3796px 0px; }
  .iti-flag.nr {
    height: 10px;
    background-position: -3811px 0px; }
  .iti-flag.nu {
    height: 10px;
    background-position: -3833px 0px; }
  .iti-flag.nz {
    height: 10px;
    background-position: -3855px 0px; }
  .iti-flag.om {
    height: 10px;
    background-position: -3877px 0px; }
  .iti-flag.pa {
    height: 14px;
    background-position: -3899px 0px; }
  .iti-flag.pe {
    height: 14px;
    background-position: -3921px 0px; }
  .iti-flag.pf {
    height: 14px;
    background-position: -3943px 0px; }
  .iti-flag.pg {
    height: 15px;
    background-position: -3965px 0px; }
  .iti-flag.ph {
    height: 10px;
    background-position: -3987px 0px; }
  .iti-flag.pk {
    height: 14px;
    background-position: -4009px 0px; }
  .iti-flag.pl {
    height: 13px;
    background-position: -4031px 0px; }
  .iti-flag.pm {
    height: 14px;
    background-position: -4053px 0px; }
  .iti-flag.pn {
    height: 10px;
    background-position: -4075px 0px; }
  .iti-flag.pr {
    height: 14px;
    background-position: -4097px 0px; }
  .iti-flag.ps {
    height: 10px;
    background-position: -4119px 0px; }
  .iti-flag.pt {
    height: 14px;
    background-position: -4141px 0px; }
  .iti-flag.pw {
    height: 13px;
    background-position: -4163px 0px; }
  .iti-flag.py {
    height: 11px;
    background-position: -4185px 0px; }
  .iti-flag.qa {
    height: 8px;
    background-position: -4207px 0px; }
  .iti-flag.re {
    height: 14px;
    background-position: -4229px 0px; }
  .iti-flag.ro {
    height: 14px;
    background-position: -4251px 0px; }
  .iti-flag.rs {
    height: 14px;
    background-position: -4273px 0px; }
  .iti-flag.ru {
    height: 14px;
    background-position: -4295px 0px; }
  .iti-flag.rw {
    height: 14px;
    background-position: -4317px 0px; }
  .iti-flag.sa {
    height: 14px;
    background-position: -4339px 0px; }
  .iti-flag.sb {
    height: 10px;
    background-position: -4361px 0px; }
  .iti-flag.sc {
    height: 10px;
    background-position: -4383px 0px; }
  .iti-flag.sd {
    height: 10px;
    background-position: -4405px 0px; }
  .iti-flag.se {
    height: 13px;
    background-position: -4427px 0px; }
  .iti-flag.sg {
    height: 14px;
    background-position: -4449px 0px; }
  .iti-flag.sh {
    height: 10px;
    background-position: -4471px 0px; }
  .iti-flag.si {
    height: 10px;
    background-position: -4493px 0px; }
  .iti-flag.sj {
    height: 15px;
    background-position: -4515px 0px; }
  .iti-flag.sk {
    height: 14px;
    background-position: -4537px 0px; }
  .iti-flag.sl {
    height: 14px;
    background-position: -4559px 0px; }
  .iti-flag.sm {
    height: 15px;
    background-position: -4581px 0px; }
  .iti-flag.sn {
    height: 14px;
    background-position: -4603px 0px; }
  .iti-flag.so {
    height: 14px;
    background-position: -4625px 0px; }
  .iti-flag.sr {
    height: 14px;
    background-position: -4647px 0px; }
  .iti-flag.ss {
    height: 10px;
    background-position: -4669px 0px; }
  .iti-flag.st {
    height: 10px;
    background-position: -4691px 0px; }
  .iti-flag.sv {
    height: 12px;
    background-position: -4713px 0px; }
  .iti-flag.sx {
    height: 14px;
    background-position: -4735px 0px; }
  .iti-flag.sy {
    height: 14px;
    background-position: -4757px 0px; }
  .iti-flag.sz {
    height: 14px;
    background-position: -4779px 0px; }
  .iti-flag.ta {
    height: 10px;
    background-position: -4801px 0px; }
  .iti-flag.tc {
    height: 10px;
    background-position: -4823px 0px; }
  .iti-flag.td {
    height: 14px;
    background-position: -4845px 0px; }
  .iti-flag.tf {
    height: 14px;
    background-position: -4867px 0px; }
  .iti-flag.tg {
    height: 13px;
    background-position: -4889px 0px; }
  .iti-flag.th {
    height: 14px;
    background-position: -4911px 0px; }
  .iti-flag.tj {
    height: 10px;
    background-position: -4933px 0px; }
  .iti-flag.tk {
    height: 10px;
    background-position: -4955px 0px; }
  .iti-flag.tl {
    height: 10px;
    background-position: -4977px 0px; }
  .iti-flag.tm {
    height: 14px;
    background-position: -4999px 0px; }
  .iti-flag.tn {
    height: 14px;
    background-position: -5021px 0px; }
  .iti-flag.to {
    height: 10px;
    background-position: -5043px 0px; }
  .iti-flag.tr {
    height: 14px;
    background-position: -5065px 0px; }
  .iti-flag.tt {
    height: 12px;
    background-position: -5087px 0px; }
  .iti-flag.tv {
    height: 10px;
    background-position: -5109px 0px; }
  .iti-flag.tw {
    height: 14px;
    background-position: -5131px 0px; }
  .iti-flag.tz {
    height: 14px;
    background-position: -5153px 0px; }
  .iti-flag.ua {
    height: 14px;
    background-position: -5175px 0px; }
  .iti-flag.ug {
    height: 14px;
    background-position: -5197px 0px; }
  .iti-flag.um {
    height: 11px;
    background-position: -5219px 0px; }
  .iti-flag.us {
    height: 11px;
    background-position: -5241px 0px; }
  .iti-flag.uy {
    height: 14px;
    background-position: -5263px 0px; }
  .iti-flag.uz {
    height: 10px;
    background-position: -5285px 0px; }
  .iti-flag.va {
    height: 15px;
    background-position: -5307px 0px; }
  .iti-flag.vc {
    height: 14px;
    background-position: -5324px 0px; }
  .iti-flag.ve {
    height: 14px;
    background-position: -5346px 0px; }
  .iti-flag.vg {
    height: 10px;
    background-position: -5368px 0px; }
  .iti-flag.vi {
    height: 14px;
    background-position: -5390px 0px; }
  .iti-flag.vn {
    height: 14px;
    background-position: -5412px 0px; }
  .iti-flag.vu {
    height: 12px;
    background-position: -5434px 0px; }
  .iti-flag.wf {
    height: 14px;
    background-position: -5456px 0px; }
  .iti-flag.ws {
    height: 10px;
    background-position: -5478px 0px; }
  .iti-flag.xk {
    height: 15px;
    background-position: -5500px 0px; }
  .iti-flag.ye {
    height: 14px;
    background-position: -5522px 0px; }
  .iti-flag.yt {
    height: 14px;
    background-position: -5544px 0px; }
  .iti-flag.za {
    height: 14px;
    background-position: -5566px 0px; }
  .iti-flag.zm {
    height: 14px;
    background-position: -5588px 0px; }
  .iti-flag.zw {
    height: 10px;
    background-position: -5610px 0px; }

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .iti-flag {
      background-image: url("../img/flags@2x.png"); } }

.iti-flag.np {
  background-color: transparent; }

@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px); }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes plyr-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease; }
  .plyr video,
  .plyr audio {
    border-radius: inherit;
    height: auto;
    vertical-align: middle;
    width: 100%; }
  .plyr button {
    font: inherit;
    line-height: inherit;
    width: auto; }
  .plyr:focus {
    outline: 0; }

.plyr--full-ui {
  box-sizing: border-box; }
  .plyr--full-ui *,
  .plyr--full-ui *::after,
  .plyr--full-ui *::before {
    box-sizing: inherit; }

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation; }

.plyr__badge {
  background: #4a5764;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px; }

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%; }
  .plyr__captions .plyr__caption {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    box-decoration-break: clone;
    line-height: 185%;
    padding: 0.2em 0.5em;
    white-space: pre-wrap; }
    .plyr__captions .plyr__caption div {
      display: inline; }
  .plyr__captions span:empty {
    display: none; }
  @media (min-width: 480px) {
    .plyr__captions {
      font-size: 16px;
      padding: 20px; } }
  @media (min-width: 768px) {
    .plyr__captions {
      font-size: 18px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px); }

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease; }
  .plyr__control svg {
    display: block;
    fill: currentColor;
    height: 18px;
    pointer-events: none;
    width: 18px; }
  .plyr__control:focus {
    outline: 0; }
  .plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }

a.plyr__control {
  text-decoration: none; }
  a.plyr__control::after, a.plyr__control::before {
    display: none; }

.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none; }

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded='true'] {
  background: #00b3ff;
  color: #fff; }

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded='true'] {
  background: #00b3ff;
  color: #fff; }

.plyr__control--overlaid {
  background: rgba(0, 179, 255, 0.8);
  border: 0;
  border-radius: 100%;
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }
  .plyr__control--overlaid svg {
    left: 2px;
    position: relative; }
  .plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
    background: #00b3ff; }

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden; }

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block; }

.plyr--full-ui ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center; }
  .plyr__controls .plyr__progress__container {
    flex: 1;
    min-width: 0; }
  .plyr__controls .plyr__controls__item {
    margin-left: 2.5px; }
    .plyr__controls .plyr__controls__item:first-child {
      margin-left: 0;
      margin-right: auto; }
    .plyr__controls .plyr__controls__item.plyr__progress__container {
      padding-left: 2.5px; }
    .plyr__controls .plyr__controls__item.plyr__time {
      padding: 0 5px; }
    .plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child,
    .plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
      padding-left: 0; }
    .plyr__controls .plyr__controls__item.plyr__volume {
      padding-right: 5px; }
    .plyr__controls .plyr__controls__item.plyr__volume:first-child {
      padding-right: 0; }
  .plyr__controls:empty {
    display: none; }

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4a5764;
  padding: 10px; }

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3; }
  @media (min-width: 480px) {
    .plyr--video .plyr__controls {
      padding: 35px 10px 10px; } }

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%); }

.plyr [data-plyr='captions'],
.plyr [data-plyr='pip'],
.plyr [data-plyr='airplay'],
.plyr [data-plyr='fullscreen'] {
  display: none; }

.plyr--captions-enabled [data-plyr='captions'],
.plyr--pip-supported [data-plyr='pip'],
.plyr--airplay-supported [data-plyr='airplay'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
  display: inline-block; }

.plyr__menu {
  display: flex;
  position: relative; }
  .plyr__menu .plyr__control svg {
    transition: transform 0.3s ease; }
  .plyr__menu .plyr__control[aria-expanded='true'] svg {
    transform: rotate(90deg); }
  .plyr__menu .plyr__control[aria-expanded='true'] .plyr__tooltip {
    display: none; }
  .plyr__menu__container {
    animation: plyr-popup 0.2s ease;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    bottom: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    color: #4a5764;
    font-size: 16px;
    margin-bottom: 10px;
    position: absolute;
    right: -3px;
    text-align: left;
    white-space: nowrap;
    z-index: 3; }
    .plyr__menu__container > div {
      overflow: hidden;
      transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1); }
    .plyr__menu__container::after {
      border: 4px solid transparent;
      border-top-color: rgba(255, 255, 255, 0.9);
      content: '';
      height: 0;
      position: absolute;
      right: 15px;
      top: 100%;
      width: 0; }
    .plyr__menu__container [role='menu'] {
      padding: 7px; }
    .plyr__menu__container [role='menuitem'],
    .plyr__menu__container [role='menuitemradio'] {
      margin-top: 2px; }
      .plyr__menu__container [role='menuitem']:first-child,
      .plyr__menu__container [role='menuitemradio']:first-child {
        margin-top: 0; }
    .plyr__menu__container .plyr__control {
      align-items: center;
      color: #4a5764;
      display: flex;
      font-size: 14px;
      padding: 4px 11px;
      user-select: none;
      width: 100%; }
      .plyr__menu__container .plyr__control > span {
        align-items: inherit;
        display: flex;
        width: 100%; }
      .plyr__menu__container .plyr__control::after {
        border: 4px solid transparent;
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .plyr__menu__container .plyr__control--forward {
        padding-right: 28px; }
        .plyr__menu__container .plyr__control--forward::after {
          border-left-color: rgba(74, 87, 100, 0.8);
          right: 5px; }
        .plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
          border-left-color: currentColor; }
      .plyr__menu__container .plyr__control--back {
        font-weight: 500;
        margin: 7px;
        margin-bottom: 3px;
        padding-left: 28px;
        position: relative;
        width: calc(100% - 14px); }
        .plyr__menu__container .plyr__control--back::after {
          border-right-color: rgba(74, 87, 100, 0.8);
          left: 7px; }
        .plyr__menu__container .plyr__control--back::before {
          background: #c1c9d1;
          box-shadow: 0 1px 0 #fff;
          content: '';
          height: 1px;
          left: 0;
          margin-top: 4px;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 100%; }
        .plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
          border-right-color: currentColor; }
    .plyr__menu__container .plyr__control[role='menuitemradio'] {
      padding-left: 7px; }
      .plyr__menu__container .plyr__control[role='menuitemradio']::before, .plyr__menu__container .plyr__control[role='menuitemradio']::after {
        border-radius: 100%; }
      .plyr__menu__container .plyr__control[role='menuitemradio']::before {
        background: rgba(0, 0, 0, 0.1);
        content: '';
        display: block;
        flex-shrink: 0;
        height: 16px;
        margin-right: 10px;
        transition: all 0.3s ease;
        width: 16px; }
      .plyr__menu__container .plyr__control[role='menuitemradio']::after {
        background: #fff;
        border: 0;
        height: 6px;
        left: 12px;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%) scale(0);
        transition: transform 0.3s ease, opacity 0.3s ease;
        width: 6px; }
      .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::before {
        background: #00b3ff; }
      .plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::after {
        opacity: 1;
        transform: translateY(-50%) scale(1); }
      .plyr__menu__container .plyr__control[role='menuitemradio'].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role='menuitemradio']:hover::before {
        background: rgba(0, 0, 0, 0.1); }
    .plyr__menu__container .plyr__menu__value {
      align-items: center;
      display: flex;
      margin-left: auto;
      margin-right: -5px;
      overflow: hidden;
      padding-left: 25px;
      pointer-events: none; }

.plyr--full-ui input[type='range'] {
  -webkit-appearance: none;
  /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 26px;
  color: #00b3ff;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%; }
  .plyr--full-ui input[type='range']::-webkit-slider-runnable-track {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none;
    background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%)); }
  .plyr--full-ui input[type='range']::-webkit-slider-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    height: 13px;
    position: relative;
    transition: all 0.2s ease;
    width: 13px;
    -webkit-appearance: none;
    /* stylelint-disable-line */
    margin-top: -4px; }
  .plyr--full-ui input[type='range']::-moz-range-track {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none; }
  .plyr--full-ui input[type='range']::-moz-range-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    height: 13px;
    position: relative;
    transition: all 0.2s ease;
    width: 13px; }
  .plyr--full-ui input[type='range']::-moz-range-progress {
    background: currentColor;
    border-radius: 2.5px;
    height: 5px; }
  .plyr--full-ui input[type='range']::-ms-track {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none;
    color: transparent; }
  .plyr--full-ui input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none; }
  .plyr--full-ui input[type='range']::-ms-fill-lower {
    background: transparent;
    border: 0;
    border-radius: 2.5px;
    height: 5px;
    transition: box-shadow 0.3s ease;
    user-select: none;
    background: currentColor; }
  .plyr--full-ui input[type='range']::-ms-thumb {
    background: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
    height: 13px;
    position: relative;
    transition: all 0.2s ease;
    width: 13px;
    margin-top: 0; }
  .plyr--full-ui input[type='range']::-ms-tooltip {
    display: none; }
  .plyr--full-ui input[type='range']:focus {
    outline: 0; }
  .plyr--full-ui input[type='range']::-moz-focus-outer {
    border: 0; }
  .plyr--full-ui input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }
  .plyr--full-ui input[type='range'].plyr__tab-focus::-moz-range-track {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }
  .plyr--full-ui input[type='range'].plyr__tab-focus::-ms-track {
    box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
    outline: 0; }

.plyr--full-ui.plyr--video input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type='range']::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type='range']::-ms-track {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--full-ui.plyr--video input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--video input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5); }

.plyr--full-ui.plyr--audio input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr--full-ui.plyr--audio input[type='range']::-moz-range-track {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr--full-ui.plyr--audio input[type='range']::-ms-track {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr--full-ui.plyr--audio input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr--full-ui.plyr--audio input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1); }

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1; }

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1; }

.plyr__time {
  font-size: 14px; }

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px; }

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none; } }

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5764;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2; }
  .plyr__tooltip::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255, 255, 255, 0.9);
    bottom: -4px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2; }

.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1); }

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%; }
  .plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
  .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
    left: 16px; }

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%; }
  .plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
    left: auto;
    right: 16px;
    transform: translateX(50%); }

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1); }

.plyr--video {
  background: #000;
  overflow: hidden; }
  .plyr--video.plyr--menu-open {
    overflow: visible; }

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0; }

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%; }

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%; }

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%); }

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative; }
  .plyr__progress input[type='range'], .plyr__progress__buffer {
    margin-left: -6.5px;
    margin-right: -6.5px;
    width: calc(100% + 13px); }
  .plyr__progress input[type='range'] {
    position: relative;
    z-index: 2; }
  .plyr__progress .plyr__tooltip {
    font-size: 14px;
    left: 0; }

.plyr__progress__buffer {
  -webkit-appearance: none;
  /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%; }
  .plyr__progress__buffer::-webkit-progress-bar {
    background: transparent; }
  .plyr__progress__buffer::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: 5px;
    transition: width 0.2s ease; }
  .plyr__progress__buffer::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: 5px;
    transition: width 0.2s ease; }
  .plyr__progress__buffer::-ms-fill {
    border-radius: 100px;
    transition: width 0.2s ease; }

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 201, 209, 0.66); }

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 41, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 41, 47, 0.6) 50%, rgba(35, 41, 47, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 201, 209, 0.66); }

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative; }
  .plyr__volume input[type='range'] {
    margin-left: 5px;
    position: relative;
    z-index: 2; }
  @media (min-width: 480px) {
    .plyr__volume {
      max-width: 90px; } }
  @media (min-width: 768px) {
    .plyr__volume {
      max-width: 110px; } }

.plyr--is-ios .plyr__volume {
  display: none !important; }

.plyr--is-ios.plyr--vimeo [data-plyr='mute'] {
  display: none !important; }

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:fullscreen video {
    height: 100%; }
  .plyr:fullscreen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:fullscreen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:fullscreen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:fullscreen .plyr__captions {
      font-size: 21px; } }

/* stylelint-disable-next-line */
.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:-webkit-full-screen video {
    height: 100%; }
  .plyr:-webkit-full-screen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:-webkit-full-screen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:-webkit-full-screen .plyr__captions {
      font-size: 21px; } }

/* stylelint-disable-next-line */
.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:-moz-full-screen video {
    height: 100%; }
  .plyr:-moz-full-screen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:-moz-full-screen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:-moz-full-screen .plyr__captions {
      font-size: 21px; } }

/* stylelint-disable-next-line */
.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%; }
  .plyr:-ms-fullscreen video {
    height: 100%; }
  .plyr:-ms-fullscreen .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr:-ms-fullscreen.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr:-ms-fullscreen .plyr__captions {
      font-size: 21px; } }

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000; }
  .plyr--fullscreen-fallback video {
    height: 100%; }
  .plyr--fullscreen-fallback .plyr__video-wrapper {
    height: 100%;
    position: static; }
  .plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
    display: block; }
    .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
      display: none; }
  .plyr--fullscreen-fallback.plyr--hide-controls {
    cursor: none; }
  @media (min-width: 1024px) {
    .plyr--fullscreen-fallback .plyr__captions {
      font-size: 21px; } }

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }
  .plyr__ads > div,
  .plyr__ads > div iframe {
    height: 100%;
    position: absolute;
    width: 100%; }
  .plyr__ads::after {
    background: rgba(35, 41, 47, 0.8);
    border-radius: 2px;
    bottom: 10px;
    color: #fff;
    content: attr(data-badge-text);
    font-size: 11px;
    padding: 2px 6px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    z-index: 3; }
  .plyr__ads::after:empty {
    display: none; }

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3; }

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2; }
  .plyr__preview-thumb--is-shown {
    opacity: 1;
    transform: translate(0, 0) scale(1); }
  .plyr__preview-thumb::before {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(255, 255, 255, 0.9);
    bottom: -4px;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
    z-index: 2; }
  .plyr__preview-thumb__image-container {
    background: #c1c9d1;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    z-index: 0; }
    .plyr__preview-thumb__image-container img {
      height: 100%;
      left: 0;
      max-height: none;
      max-width: none;
      position: absolute;
      top: 0;
      width: 100%; }
  .plyr__preview-thumb__time-container {
    bottom: 6px;
    left: 0;
    position: absolute;
    right: 0;
    white-space: nowrap;
    z-index: 3; }
    .plyr__preview-thumb__time-container span {
      background-color: rgba(0, 0, 0, 0.55);
      border-radius: 2px;
      color: #fff;
      font-size: 14px;
      padding: 3px 6px; }

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1; }
  .plyr__preview-scrubbing--is-shown {
    opacity: 1; }
  .plyr__preview-scrubbing img {
    height: 100%;
    left: 0;
    max-height: none;
    max-width: none;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%; }

.plyr--no-transition {
  transition: none !important; }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.plyr [hidden] {
  display: none !important; }

.icon-flag-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.icon-flag {
  position: relative;
  display: inline-block;
  width: 1.333333em;
  line-height: 1em;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }
  .icon-flag:before {
    content: '\00a0'; }

.icon-flag-au {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-au.svg); }

.icon-flag-ca {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-ca.svg); }

.icon-flag-cn {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-cn.svg); }

.icon-flag-es {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-es.svg); }

.icon-flag-jp {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-jp.svg); }

.icon-flag-kr {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-kr.svg); }

.icon-flag-mx {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-mx.svg); }

.icon-flag-nz {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-nz.svg); }

.icon-flag-us {
  background-image: url(/modules/ilg-aah-jahia-assets/img/icons/country-flags/flag-us.svg); }

.flatpickr-calendar {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  animation: none;
  direction: ltr;
  font-size: 14px;
  line-height: 24px;
  border-radius: 0;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-color: #ffffff; }

.flatpickr-months {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: none; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.hasWeeks {
  width: auto; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }

.flatpickr-month {
  margin: 0 0 5px 0;
  height: 40px;
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden; }

.flatpickr-prev-month,
.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  line-height: 16px;
  height: 35px;
  padding: 10px calc(3.57% - 1.5px);
  z-index: 3; }

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative; }

.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  /*
           /*rtl:begin:ignore*/
  /*
           */
  left: 0;
  /*
           /*rtl:end:ignore*/
  /*
           */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  /*
           /*rtl:begin:ignore*/
  /*
           */
  right: 0;
  /*
           /*rtl:end:ignore*/
  /*
           */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #007697; }

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 20px;
  margin: 8px 0 0 0; }

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit; }

.flatpickr-prev-month {
  position: relative;
  top: -1px;
  left: 0;
  right: auto; }
  .flatpickr-prev-month ~ .flatpickr-month {
    width: 100%; }

.flatpickr-next-month {
  position: relative;
  top: -1px;
  left: auto;
  right: 0; }

.flatpickr-next-month, .flatpickr-prev-month {
  padding: 11px 19px; }
  .flatpickr-next-month svg, .flatpickr-prev-month svg {
    margin: 0; }
  .flatpickr-next-month.flatpickr-disabled, .flatpickr-prev-month.flatpickr-disabled {
    visibility: hidden; }

.numInputWrapper {
  position: relative;
  height: auto; }
  .numInputWrapper input, .numInputWrapper span {
    display: inline-block; }
  .numInputWrapper input {
    width: 100%; }
  .numInputWrapper span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 10px;
    line-height: 50%;
    cursor: pointer;
    border: 1px solid rgba(57, 57, 57, 0.05);
    box-sizing: border-box; }
    .numInputWrapper span:hover {
      background: rgba(0, 0, 0, 0.1); }
    .numInputWrapper span:active {
      background: rgba(0, 0, 0, 0.2); }
    .numInputWrapper span:after {
      display: block;
      content: "";
      position: absolute;
      top: 33%; }
    .numInputWrapper span.arrowUp {
      top: 5px;
      border-bottom: 0; }
      .numInputWrapper span.arrowUp:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid rgba(57, 57, 57, 0.6); }
    .numInputWrapper span.arrowDown {
      top: 12px; }
      .numInputWrapper span.arrowDown:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgba(57, 57, 57, 0.6); }
    .numInputWrapper span svg {
      width: inherit;
      height: auto; }
      .numInputWrapper span svg path {
        fill: rgba(0, 0, 0, 0.5); }
  .numInputWrapper:hover {
    background: rgba(0, 0, 0, 0.05); }
    .numInputWrapper:hover span {
      opacity: 1;
      background: #e3e3e3; }

.flatpickr-current-month {
  font-size: calc((28 / 640vw ) * 100);
  line-height: calc((48 / 640vw ) * 100);
  letter-spacing: calc((0.3 / 640vw ) * 100);
  position: absolute;
  top: 50%;
  left: 12.5%;
  display: flex;
  font-weight: 500;
  margin: 0;
  color: inherit;
  width: 75%;
  padding: 0;
  height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%); }
  @media (min-width: 640px) {
    .flatpickr-current-month {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 48px;
      letter-spacing: 0.3px; } }

.flatpickr-current-month.slideLeft {
  transform: translate3d(-100%, 0px, 0px);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideLeftNew {
  transform: translate3d(100%, 0px, 0px);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideRight {
  transform: translate3d(100%, 0px, 0px);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideRightNew {
  transform: translate3d(0, 0, 0px);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  line-height: 42px;
  color: inherit;
  display: inline-block;
  padding: 0; }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
  margin-left: 7px; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0;
  display: inline-block;
  font-size: 14px;
  font-family: inherit;
  line-height: inherit;
  height: 24px;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -moz-appearance: textfield;
  -webkit-appearance: textfield; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-weekdaycontainer {
  width: 100%;
  display: flex; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 56px;
  margin-bottom: 4px;
  padding: 0;
  display: flex;
  align-items: center; }

span.flatpickr-weekday {
  font-size: calc((26 / 640vw ) * 100);
  font-weight: 500;
  margin: 0;
  cursor: default;
  background: transparent;
  line-height: 1;
  text-align: center;
  display: block;
  flex: 1; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%; }

.dayContainer {
  padding: 1px;
  outline: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  opacity: 1; }
  .dayContainer .min-stay,
  .dayContainer .flatpickr-day.prevMonthDay.min-stay,
  .dayContainer .flatpickr-day.nextMonthDay.min-stay {
    background-color: #0080A3;
    color: #ffffff !important; }
    .dayContainer .min-stay .rate,
    .dayContainer .flatpickr-day.prevMonthDay.min-stay .rate,
    .dayContainer .flatpickr-day.nextMonthDay.min-stay .rate {
      color: inherit; }
  .dayContainer .unavailable,
  .dayContainer .flatpickr-day.prevMonthDay.min-stay.unavailable,
  .dayContainer .flatpickr-day.nextMonthDay.min-stay.unavailable {
    background-color: #e7e7e7;
    color: #2b2b2b !important; }

.flatpickr-calendar.animate .dayContainer.slideLeft {
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  transform: translate3d(-100%, 0px, 0px); }

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideRight {
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transform: translate3d(100%, 0px, 0px); }

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-day {
  font-weight: 500;
  line-height: normal;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  margin: 0.125em 0;
  padding: 0;
  background: none;
  border: 0;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: none;
  height: auto;
  text-align: center;
  z-index: 1;
  /*&.nextMonthDay.hidden,
	&.prevMonthDay.hidden {
		visibility: hidden;
	}*/ }
  .flatpickr-day.nextMonthDay, .flatpickr-day.prevMonthDay {
    visibility: hidden; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.inRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background-color: #bff1ff;
  color: #2b2b2b !important;
  box-shadow: none;
  border-color: transparent; }

.flatpickr-day.inRange {
  background-color: rgba(191, 241, 255, 0.5);
  border-radius: 0; }

.flatpickr-day.startRange + .flatpickr-day.inRange:before,
.flatpickr-day.startRange + .flatpickr-day.endRange:before,
.flatpickr-day.endRange:before {
  content: '';
  background-color: rgba(191, 241, 255, 0.5);
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: -1; }

.flatpickr-day.endRange:before {
  left: 0; }

.flatpickr-day.startRange + .flatpickr-day.inRange:before {
  left: -50%; }

.flatpickr-day.startRange, .flatpickr-day.endRange {
  z-index: 2; }

.flatpickr-day.startRange + .flatpickr-day.endRange {
  z-index: 1; }
  .flatpickr-day.startRange + .flatpickr-day.endRange:before {
    width: 100%;
    left: -50%; }

.flatpickr-day.startRange.flatpickr-day.endRange:before {
  content: none; }

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  pointer-events: none; }

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.flatpickr-disabled {
  color: #707070 !important;
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-disabled.unavailable .slash,
.flatpickr-disabled.unavailable .rate,
.flatpickr-day.flatpickr-disabled .slash,
.flatpickr-day.flatpickr-disabled .rate {
  display: none; }

.flatpickr-disabled.unavailable.min-stay,
.flatpickr-day.flatpickr-disabled.min-stay {
  background-color: white;
  color: rgba(57, 57, 57, 0.3); }

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-weekwrapper {
  display: inline-block;
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none; }

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
  width: 100%; }

.flatpickr-time {
  display: flex;
  text-align: center;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f0f0f0; }

.flatpickr-input[readonly] {
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
  left: -99999px;
  position: absolute; }

.flatpickr-monthDropdown-months {
  background: none;
  font-size: 14px;
  border: 0;
  margin-top: 0; }

ul.flatpickr-key {
  column-count: 2;
  list-style: none;
  margin: 0.5em 1em;
  padding: 0; }
  ul.flatpickr-key li {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0.5em 0.5em 0.5em; }
    ul.flatpickr-key li .key-marker {
      width: 1em;
      height: 1em;
      margin: 0.25em;
      display: inline-block;
      border-radius: 50%;
      flex-shrink: 0;
      aspect-ratio: 1; }
    ul.flatpickr-key li .key-text {
      font-size: 0.75em;
      line-height: 1.25em;
      margin: 0.25em; }
    ul.flatpickr-key li.key-selected .key-marker {
      background-color: #bff1ff; }
    ul.flatpickr-key li.key-available .key-marker {
      border: 1px solid #707070; }
    ul.flatpickr-key li.key-not-available .key-marker {
      background-color: #e7e7e7;
      border: 1px solid #e7e7e7;
      position: relative; }
    ul.flatpickr-key li.key-minstay .key-marker {
      background-color: #0080a2; }

@media (min-width: 768px) {
  ul.flatpickr-key {
    margin: 0.5em 3.5em; }
    ul.flatpickr-key li .key-text {
      font-size: 0.85em; }
  .flatpickr-calendar {
    min-width: 400px;
    padding: 1em 1.5em; }
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    max-height: 640px; }
  .flatpickr-month {
    height: 32px; }
  .flatpickr-current-month {
    padding: 0;
    font-size: 14px;
    line-height: 1.25em; }
  .flatpickr-current-month span.cur-month {
    line-height: 34px; }
  .flatpickr-prev-month svg,
  .flatpickr-next-month svg {
    width: 14px;
    margin: 0; }
  .flatpickr-weekdays {
    height: 30px; }
  span.flatpickr-weekday {
    font-size: 12px; }
  .flatpickr-day {
    font-size: 14px; }
  .flatpickr-day.selected.startRange:after,
  .flatpickr-day.startRange.startRange:after,
  .flatpickr-day.endRange.startRange:after {
    font-size: 8px; }
  .flatpickr-day.selected.endRange:after,
  .flatpickr-day.startRange.endRange:after,
  .flatpickr-day.endRange.endRange:after {
    font-size: 8px; }
  .numInputWrapper span.arrowUp {
    top: 3px; }
  .numInputWrapper span.arrowDown {
    top: 10px; } }

.user-is-tabbing .flatpickr-day:focus, .user-is-tabbing .flatpickr-day:active {
  outline: 1px dotted #212121;
  outline: auto 5px -webkit-focus-ring-color !important; }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fpSlideLeft {
  from {
    transform: translate3d(0px, 0px, 0px); }
  to {
    transform: translate3d(-100%, 0px, 0px); } }

@keyframes fpSlideLeftNew {
  from {
    transform: translate3d(100%, 0px, 0px); }
  to {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes fpSlideRight {
  from {
    transform: translate3d(0, 0, 0px); }
  to {
    transform: translate3d(100%, 0px, 0px); } }

@keyframes fpSlideRightNew {
  from {
    transform: translate3d(-100%, 0, 0px); }
  to {
    transform: translate3d(0, 0, 0px); } }

@keyframes fpFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fpFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.modal .modal-dialog.calendar-flyout .container .left-picker + .flatpickr-calendar .flatpickr-next-month, .modal .modal-dialog.calendar-flyout .form-factory .left-picker + .flatpickr-calendar .flatpickr-next-month {
  visibility: visible !important; }

.modal .modal-dialog.calendar-flyout .flatpickr-calendar {
  width: 100% !important; }

.modal .modal-dialog.calendar-flyout .flatpickr-wrapper {
  width: 100%;
  padding-top: 20px; }

.modal .modal-dialog.calendar-flyout .flatpickr-month {
  width: 100%;
  display: flex;
  align-items: center; }

.modal .modal-dialog.calendar-flyout .flatpickr-current-month {
  padding: 0; }

.modal .modal-dialog.calendar-flyout .flatpickr-weekdays {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: none;
  border: 0; }

.modal .modal-dialog.calendar-flyout .flatpickr-weekdaycontainer {
  max-width: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 11px 0; }

.modal .modal-dialog.calendar-flyout .flatpickr-days {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100% !important;
  margin-bottom: 30px;
  padding: 0; }

.modal .modal-dialog.calendar-flyout .dayContainer {
  max-width: none; }

.modal .modal-dialog.calendar-flyout .flatpickr-prev-month ~ .flatpickr-month + .flatpickr-month {
  left: auto;
  right: 0; }

@media (min-width: 768px) {
  .modal .modal-dialog.calendar-flyout .flatpickr-wrapper {
    padding: 20px 14px 14px; } }

[aria-disabled="true"] {
  pointer-events: none; }
