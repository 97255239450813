.modal {
	.modal-dialog {
		margin: 0 auto;

		&.calendar-flyout {
			max-width: 900px;

			.modal-header {
				position: relative;
				z-index: 1;
			}

			.modal-content {
				min-height: 100vh;
			}
		}
	}

	&#hotel-flight-modal {
		.modal-dialog,
		.modal-content,
		.modal-body {
			height: 100%;
		}
	}

	.hotel-flight {
		border: 0;
		height: 87%;
	}

	.modal-header {
		display: block;
		margin-top: px-vw(40);

		.close {
			padding: 0.25em;
			margin: 0;
		}
	}

	.modal-title {
		@include headline(4);
		padding: 20px 0px;
	}

	.close {
		position: absolute;
		top: px-vw(34);
		right: px-vw(40);
		opacity: 1;
		font-size: 1.3rem;

		.icon-svg {
			width: px-vw(33);
			height: px-vw(33);
		}
	}

	video {
		width: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover; object-position: center;';
	}

	// Specific Modals
	&.modal-video {
		.modal-body {
			margin-top: 15%;
			padding: 0;
		}

		.modal-content {
			background: none;
		}

		.close {
			top: 18px;
			right: 15px;

			.icon-svg {
				width: 1.7em;
				height: 1.7em;
				fill: $color-white;
				background-color: $color-aqua-dark;
				border-radius: 50%;
				transition: background-color 0.3s;
			}

			svg {
				padding: 10px;
			}

			&:hover {
				.icon-svg {
					background-color: darken($color-aqua-dark, 10%);
				}
			}
		}
	}

	&.modal-olapic, &.modal-black {

		.modal-content, .modal-header, .modal-body, .modal-footer {
			background-color: $color-black;
		}

		figure {
			float: none;
			width: px-vw(400);
			margin: 0 auto;

			picture {
				@include image-fit();
				width: px-vw(400);
				height: px-vw(400);
			}

			figcaption {
				margin-top: 1em;
				text-align: left;
				color: $color-white;
				p {
					color: $color-white;
				}

				[data-profile-photo] {
					display: flex;
					width: 48px;
					height: 48px;
				}

			}

			.olapic {
				@include body-text("small");
				margin-bottom: 1rem;

				.icon-svg {
					width: 48px;
					height: 48px;
					margin-left: 0.5em;

					svg {
						fill: $color-white;
					}
				}

				a {
					text-decoration: none;

					&:hover, &:focus {
						color: inherit;
					}
				}
			}
		}

		.slick-prev {
			@include absolute(4);
		}
		.slick-next {
			@include absolute(6);
		}
		.slick-prev, .slick-next {
			background-color: transparent;
			border: 0;

			.icon-svg {
				width: 48px;
				height: 48px;
			}

			svg {
				fill: $color-white;
			}

			&:hover, &:focus {
				background-color: transparent;
				border: 0;

				svg {
					fill: $color-grey-light;
				}
			}
		}

		.close {

			color: $color-white;
			.icon-svg {
				width: 24px;
				height: 24px;
				margin: 12px;
				fill: $color-white;
			}
		}

	}

	&.modal-travel-log {
		.modal-title {
			@include headline(3);
		}
	}

	&.modal-upload {

		.btn-upload {
			position: relative;
			margin-bottom: 1em;
			overflow: hidden;

			input[type="file"] {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: 0;
				padding: 0;
				opacity: 0;
			}
		}

	}

	&.modal-carousel-gallery {
		text-align: center;

		.modal-body {
			padding: px-vw(15) 0 0;
		}

		.d-block-track {
			width: 100% !important; // sass-lint:disable-line no-important

			.slick-slide {
				display: block !important; // sass-lint:disable-line no-important
				width: 100% !important; // sass-lint:disable-line no-important
			}
		}

		.slick-initialized .slick-slide {
			display: table-cell;
		}

		.slick-slide {
			position: relative;
			float: none;
			vertical-align: middle;
		}

		.carousel-factory {
			&[data-arrows="bottom"] {
				padding: 0;

				.carousel {
					margin: 0 auto px-vw(30);
				}

				.slick-arrow {
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}

		.carousel-indicators {
			opacity: 0;
		}

		// img {
		// 	max-width: 480px;
		// }

		&.wide {
			img {
				width: 100%;
				max-width: 100%;
			}
		}

		.slick-arrow.slick-hidden {
			display: none;
		}
	}

	&.modal-social {
		.at-resp-share-element.at-mobile .at-label {
			display: inline-block;
		}
	}

	.additional-info {
		line-height: 1.1;
		font-size: 0.8rem;
		font-style: italic;
		margin-top: 25px;
	}

	.sign-up-success-close {
		text-align: center;
		margin-top: 35px;

		.btn {
			font-weight: bold;
			cursor: pointer;
		}
	}

	@include media-breakpoint-down(sm) {
		.modal-dialog {
			max-width: none;
		}
	}


	@include media-breakpoint-up(md) {
		&#hotel-flight-modal {
			.modal-dialog {
				height: auto;
			}
		}

		.hotel-flight {
			height: 476px;
		}

		.modal-dialog {
			margin: 30px auto;

			&.calendar-flyout {
				transform: translateY(-50%);
				top: 50%;
				margin: 0 auto;

				.modal-content {
					min-height: 0;
				}

				.loader-bg {
					top: 0;
				}
			}
		}

		.modal-header {
			margin-top: 0;
			padding: 26px;
		}

		.modal-body {
			padding: 15px;
		}

		.close {
			top: 17px;
			right: 17px;

			.icon-svg {
				width: 1em;
				height: 1em;
			}
		}

		&.modal-video {
			.modal-body {
				margin-top: 0;
			}

			.close {
				top: 19px;
				right: -37px;
			}
		}

		&.modal-olapic, &.modal-black {
			.slick-prev {
				margin-left: -15px;
			}
			.slick-next {
				margin-right: -15px;
			}


			figure {
				width: 500px;
				margin: 0 auto;

				picture {
					width: 500px;
					height: 500px;
					margin: 0 auto;
				}
			}
		}

		&.modal-carousel-gallery {
			.modal-body {
				padding: 15px 0 50px;
			}

			.carousel-factory {
				&[data-arrows="bottom"] {
					.carousel {
						margin: 0 auto 50px;
					}

					.slick-arrow {
						bottom: -20px;
						margin-top: 25px;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&.modal-olapic, &.modal-black {
			.slick-prev {
				margin-left: 20px;
			}
			.slick-next {
				margin-right: 20px;
			}
		}
	}
}

.modal-backdrop.show {
	opacity: 0.6;
}

[data-toggle="modal"] {
	img.lazyloading {
		opacity: 1;
	}
}

