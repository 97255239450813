/* Primary Colors */
$color-aqua: 					#3cc1cc; @include color-support($color-aqua, 'aqua');
$color-aqua-dark: 			#007697; @include color-support($color-aqua-dark, 'aqua-dark');
$color-aqua-dark-hover: 	#063e4e;
$color-blue-navy: 			#445464; //@include color-support($color-blue-navy, 'blue-navy');
$color-magenta: 				#b8146a; //@include color-support($color-magenta, 'magenta');
$color-black: 					#000000; @include color-support($color-black, 'black');
$color-black-fake: 			#2b2b2b; @include color-support($color-black-fake, 'black-fake');
$color-blue-light: 			#bff1ff; //@include color-support($color-blue-light, 'blue-light');
$color-grey-light: 			#f7f7f7; @include color-support($color-grey-light, 'grey-light');
$color-white: 					#ffffff; @include color-support($color-white, 'white');
$color-grey-light-2:  		#bababa; @include color-support($color-grey-light-2, 'grey-light-2');
$color-grey-light-3:  		#c7c7c7; @include color-support($color-grey-light-3, 'grey-light-3');
$color-blue-button: 		#f0f7f9; @include color-support($color-blue-button, 'color-blue-button');
$color-blue-text: 			#005a75; @include color-support($color-blue-text, 'color-blue-text');

/* Secondary Colors */
$color-orange: 				#f68d2d;	//@include color-support($color-orange, 'orange');
$color-purple: 				#7765a1; //@include color-support($color-purple, 'purple');
$color-green: 					#87cca1; //@include color-support($color-green, 'green');
$color-red: 					#d45358; @include color-support($color-red, 'red');
$color-tan: 					#d4ccbd; //@include color-support($color-tan, 'tan');
$color-brown: 					#786e64; //@include color-support($color-brown, 'brown');
$color-blue-medium: 			#1590bf; //@include color-support($color-blue-medium, 'blue-medium');
$color-green-medium: 		#7a9d3e; //@include color-support($color-green-medium, 'green-medium');
$color-teal: 					#209a8b; //@include color-support($color-teal, 'teal');

/* Additional Colors */
$color-yellow: 				#e89d45;
$color-lilac: 					#bcabe4;
$color-facebook: 				#39579a;
$color-google: 				#bd3418;
$color-trip-advisor:			#18b187;
$color-error:					#ce3a3f;

$color-grey: 					#7c7c7c;
$color-grey-border:			#e1e1e1;
$color-grey-body: 			#efefef;
$color-grey-strikethrough:	#757575;

/* Category Colors */
$color-adventure:				$color-aqua;
$color-culinary:				$color-purple;
$color-destinations:			$color-green;
$color-romance:				$color-red;
$color-business: 				$color-tan;
$color-family:					$color-green-medium;
$color-budget:					$color-teal;
$color-culture:				$color-orange;
$color-round-ups:				$color-brown;
$color-personality:			$color-blue-medium;


/* Generate Shades/Tints */
@for $i from 0 through 500 {
	$step: 10;
	@if $i % $step == 0 {
		.#{back-color-grey}-#{500+($i)} {
			background-color: tint($color-grey, $i/5);
		}
		.#{back-color-grey}-#{500-($i)} {
			background-color: shade($color-grey, $i/5);
		}
	}
}


