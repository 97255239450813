.form-factory {
	@extend .container;
	display: block;
	margin-bottom: 3rem;

	[vc-recaptcha] > div {
		margin: auto;
	}

	.control-label {
    	padding: 0;
		position: relative;
		display: block !important;
		width: auto;
		height: auto;
		text-align: left;
	}

	.input-group {
		min-width: 18rem;
	}

	.datepicker-popup-position {
		margin-bottom: 0;
	}

	.btn {
		margin-left: 0;
	}

	.left15 {
		margin-left: 0;
	}

	ff-content-display {
		text-align: center;
	}

	form {
		@include make-row();
		scroll-margin-top: 158px;
		justify-content: center;

		> div {
			min-width: 50%;
		}

		> fieldset {
			@include make-col-ready();
			@include make-col(10);

			> .row {
				justify-content: center;

				> [class*="col-"] {
					padding-right: 0;
					padding-left: 0;
				}

				> .col-sm-4 {
					@include make-col(12);
				}

				> .col-sm-12 {
					.col-sm-6 {
						@include make-col(12);
					}
				}
			}
		}
	}

	select.form-control {
		-webkit-appearance: none;
		-moz-appearance: none;

		~ div.arrow {
			@include font-size(26);
			position: absolute;
			top: 10px;
			right: 10px;
			line-height: 1;
			color: $color-grey;
			pointer-events: none;
		}
	}

	ff-builder-input {
		select.form-control ~ div.arrow {
			display: none;
		}
	}

	.fa-calendar:before {
		// @include font-size(45);
		font-size: 1.6rem;
		position: absolute;
		top: px-vw(22);
		right: px-vw(10);
		content: '\01F4C5';
		font-style: normal;
		line-height: 1;
		color: $color-grey;
		pointer-events: none;
	}

	.help-block.ng-scope {
		@include font-size(14);
		display: block;
		margin: -5px 0 20px;
		color: $color-error;
	}

	.ng-invalid.ng-valid-parse {
		border-color: $color-error;
	}

	.input-group-btn {
		position: absolute;
		display: block;
		top: 1px;
		right: 12px;
		z-index: 10;

		button {
			min-width: 1px;
			height: 30px;
			padding: 15px 18px;
			background-color: transparent;

			&:hover {
				background-color: transparent;
			}
		}

		.icon-svg {
			right: 3px;
			height: 20px;
			fill: $color-black-fake;

			svg {
				width: 12px;
				height: 12px;
			}
		}
	}

	.uib-datepicker-popup {
		margin-top: px-vw(40);
		padding-top: 0;

		.uib-daypicker {
			width: 100%;
		}

		th {
			small {
				font-weight: 600;
			}
		}

		.text-muted {
			opacity: 0.5;
		}

		em {
			font-weight: 300;
			opacity: 0.5;
		}

		.uib-button-bar {
			margin-top: 14px;
			text-align: center;

			.ng-binding {
				@include button();
				width: auto;
				padding: 10px 20px;
				border-color: transparent;

				&.btn-info {
					@include button("stroke");
					margin-right: 5px;
				}

				&.btn-danger {
					@include button("stroke");
				}
			}
		}

		button {
			&.btn-sm {
				width: 42px;
				min-width: 1px;
				padding: 5px;
				background-color: transparent;
				color: $color-black-fake;

				&:hover {
					color: $color-aqua;
					border-bottom: 1px solid $color-black-fake;
				}

				&.uib-left,
				&.uib-right {
					color: $color-black-fake;

					i:before {
						font-style: normal;
					}

					&:hover {
						border-color: transparent;
					}
				}

				&.uib-left {
					i:before {
						content: '<';
					}
				}

				&.uib-right {
					i:before {
						content: '>';
					}
				}
			}

			&.uib-title {
				width: 100%;
				background-color: $color-grey-light;
				text-transform: capitalize;

				strong {
					font-weight: 600;
				}

				&:hover {
					border-color: transparent;
					color: $color-black-fake;
				}
			}

			&.active {
				border-bottom: 1px solid $color-black-fake;
			}
		}
	}

	.datePickerMin {
		.form-control {
			margin-bottom: 0.7rem;
		}
	}

	&.group-travel-form {
		// Thank You Message styling
		strong[ng-bind-html="vm.currentForm.afterSubmissionText"] {
			@include headline(4);
		}

		.control-label {
			@include body-text();
			@include make-col(12);
			display: flex;
			margin-bottom: 0;
			padding-bottom: 6px;
		}
	}

	&.press-sign-up,
	&.a-list-sign-up {
		position: static;

		fieldset {
			@include make-col(12);
			position: static;

			ff-input {
				width: 100%;

				.col-sm-10 {
					padding: 0;
				}

				// Captcha
				~ .row {
					position: absolute;
					bottom: 21px;
					left: 0;
					width: 100%;
					margin: 0;

					[vc-recaptcha] > div {
						margin: auto;
					}
				}
			}
		}

		// Thank You Message styling
		strong[ng-bind-html="vm.currentForm.afterSubmissionText"],
		strong[ng-bind-html="vm.currentForm.afterSubmissionText"] p {
			font-weight: 300;

			&, samp, .small {
				font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif;
				font-size: .875rem;
				line-height: 24px;
				letter-spacing: 0;
				text-transform: initial;
				font-weight: 300;
			}

			strong {
				font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif;
				font-size: .875rem;
				line-height: 24px;
				letter-spacing: 0;
				text-transform: initial;
				font-weight: 500;
			}
		}
	}


	@include media-breakpoint-up(sm) {
		&.press-sign-up {
			fieldset {
				ff-input {
					width: 65%;
				}
			}
		}

		.form-control,
		select.form-control {
			min-width: 18rem;
		}
	}

	@include media-breakpoint-up(md) {
		form {
			scroll-margin-top: 158px;

			> fieldset {
				@include make-col(9);

				> .row {
					> .col-sm-4 {
						@include make-col(4);
						margin-bottom: 42px;
						padding-bottom: 30px;
						border-bottom: 1px solid $color-grey-border;
					}
				}
			}
		}

		.fa-calendar:before {
			top: 14px;
			right: 14px;
			font-size: 18px;
		}

		.custom-select-label {
			margin-bottom: .7rem;
		}

		.uib-datepicker-popup {
			margin-top: 0;
		}

		&.press-sign-up,
		&.a-list-sign-up {
			fieldset {
				@include make-col(6);

				ff-input {
					width: 100%;

					// Captcha
					~ .row {
						transform: scale(.8);
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		form {
			scroll-margin-top: 182px;
		}
	}
}
