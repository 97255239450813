.accordion {
	.card {
		border-width: 1px 0 1px 0;

		.card-header {
			position: relative;
			background-color: transparent;
			border: 0;

			.collapsed {
				.icon-svg {
					transform: rotate(0) translateY(-50%);
				}
			}

			.icon-svg {
				@include absolute(6);
				margin: 0 1em 0 0;
				transform: rotate(-180deg) translateY(50%);
				transition: transform 0.5s;

				svg {
					fill: $color-black-fake;
				}
			}
		}
		.card-block {
			border: 0;
		}

		& + .card {
			border-top: 0;
		}
	}

	#terms-header button[data-toggle="collapse"] {
		font-family: Roboto;
		font-weight: inherit;
		letter-spacing: inherit;
		color: inherit;
		width: 100%;
	}
}
