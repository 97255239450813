$header-sticky-height-mobile: 91;
$header-sticky-height-desktop: 72;

.property-navbar {
	background-color: $color-white;
	width: 100%;

	.btn-reservation {
		display: none;
	}

	.dropdown {
		ul.dropdown-menu:not(.search-results) {
			border: 0;

			&.dropdown-item:active {
				background-color: $color-white;
			}
		}
	}

	.property-navbar-collapse {
		max-height: 100vh;
		overflow-y: auto;
	}

	ul.property-navbar-nav {
		li.dropdown {
			a {
				display: flex;
				align-items: center;
				z-index: 1;

				.icon-svg {
					top: auto !important;
					transform: rotate(90deg);
					transition: all 0.1s ease;
				}

				&[aria-expanded="true"] {
					.icon-svg {
						transform: rotate(-90deg);
					}
				}
			}

		}
	}

	.navbar-brand {
		&:not(.property-navbar-brand) {
			display: none !important;
		}
	}

	&.active {
		.navbar-brand {
			display: none !important;

			&:not(.property-navbar-brand) {
				display: flex !important;
			}
		}
	}


	@include media-breakpoint-down(md) {

		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 1001;

		.menu-wrapper {
			height: 100%;
			padding-bottom: 12%;
			overflow: auto;
		}

		.close {
			margin: px-vw(28) px-vw(28) 0 0;
			font-size: 7.5vw;
			opacity: 1;
		}

		figure {
			min-height: px-vw(25);
			max-width: px-vw(285);
			margin: px-vw(45) auto px-vw(35);

			a {
				border-bottom: 0;
			}
		}

		.navbar-collapse {
			a:not(.btn), .navbar-a {
				display: block;
				position: relative;
				padding: px-vw(40) px-vw(50);
				font-weight: 500;
				line-height: 3.4375vw;

				&.collapsed {
					.icon-svg {
						transform: rotate(0) translateY(-50%);
					}
				}

				.icon-svg {
					@include absolute(6);
					right: px-vw(35);
					width: px-vw(24);
					height: px-vw(24);
					transform: rotate(-180deg) translateY(50%);
					transition: transform 0.1s;
				}
			}
		}

		ul {
			margin-bottom: 0;

			&.list-separated.list-inline {
				li {
					display: block;

					&:before {
						display: none;
					}
				}
			}
		}

		.dropdown-menu:not(.search-results) {
			display: block;
			position: relative;
			float: none;
			margin: 0 0 1em 0;
			min-width: 0;

			.dropdown-item {
				padding: 0;

				a {
					font-weight: 300 !important;
					padding-left: 2em;
				}
			}

			&.collapse {
				display: none;
			}

			&.collapse.show {
				display: block;
				z-index: 0;
			}
		}
	}


	@include media-breakpoint-up(lg) {

		width: 100%;
		z-index: 998;
		max-width: #{map-get($container-max-widths, 'lg')};
		min-height: 57px;
		margin-bottom: 65px;
		padding: 23px 35px;
		align-items: stretch !important;

		ul.property-navbar-nav {
			display: flex;
			flex-grow: 1;

			li {
				a {
					.icon-svg {
						transform: rotate(0deg) !important;
					}
				}

				&.show {
					a {
						.icon-svg {
							transform: rotate(180deg) !important;
						}
					}
				}
			}
		}

		.navbar-collapse {
			display: none !important;
		}

		.btn-reservation {
			@include text(17, 18);
			width: 8rem;
			margin-top: 13px;
			padding: 43px 20px;
			background-color: $color-magenta;
			white-space: normal;
		}

		.dropdown {
			position: relative !important;

			a {
				z-index: 1;

				&[aria-expanded="false"] {
					.icon-svg {
						transform: rotate(0) translateY(-50%);
					}
				}
			}

			ul.dropdown-menu:not(.search-results) {
				top: auto;
				left: 0;
				margin-top: 0;
				color: inherit;
				font-size: inherit;
				padding: 0.25em;
				white-space: normal;
				transition: all 0.1s ease;
				z-index: 0;

				li {
					padding: 0;
					font-weight: 300;
					border: 0;

					&:before {
						display: none;
					}

					a {
						display: block;
						color: $color-black-fake;
						padding: 3px 18px;
						line-height: 1.5em;
						font-weight: 300 !important;
					}

					&.dropdown-item:active {
						background-color: $color-white;
					}
				}
			}

			.icon-svg {
				right: 5px;
				width: 9px;
				height: 9px;
				transform: rotate(-180deg) translateY(50%);
				transition: transform 0.1s;
			}
		}

		/*&.fixed-top.collapse:not(.resizing) {
			display: flex;
			position: fixed;
			top: 0;
			bottom: auto;
			right: 0;
			left: 0;
			height: 113px;
			z-index: 1002;
			transform: none;
			border-top: 1px solid $color-grey-border;
			border-bottom: 1px solid $color-grey-border;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			max-width: none;

			.menu-wrapper {
				position: relative;
				width: 100%;
				height: 100%;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: flex-end;
			}

			.headline {
				display: none !important;
			}

			// TODO: need to fix display override
			figure.d-lg-none {
				display: block !important;
				margin-bottom: 0;
				margin-left: 50px;
				margin-top: 2em;

				picture {
					max-width: 136px;
					padding: 0.75em;
				}
			}

			.btn-reservation {
				display: block;
				top: -1px;
				right: 0;
				margin: 0;
				padding-top: 57px;
			}

			.property-top-nav & {
				border: 0;
				position: initial;
				margin: 0;
				transition: height 0.1s;

				.btn-reservation {
					position: relative;
					top: -7px;
					padding: 57px 20px 53px;
				}
			}

			.property-top-nav.is-stuck & {
				height: 72px;

				.btn-reservation {
					top: 0;
					padding: 16px 20px 17px;
				}

				figure picture img {
					max-height: 70px;
				}
			}
		}*/

		&.collapse {
			display: block;
		}

		.headline {
			display: inline-block;
			margin: 0;
		}

		ul.list-inline {
			display: inline-block;
			margin: auto;
			margin-top: 3rem;
			font-weight: 500;
			white-space: nowrap;

			li.list-inline-item {
				margin-right: 0;

				a {
					padding: 0.5em 1em;
				}

				&:before {
					margin: 0 0.25em;
				}

				&.active {
					> a {
						font-weight: 600;
					}
				}

				&:nth-child(2):before {
					display: none;
				}

				&.dropdown {
					position: relative;
					a {
						margin-right: 0.75em;
					}

					&.show {
						.icon-svg {
							transform: rotate(-180deg);
						}
					}
				}
			}
		}

		&.fixed-top:not(.resizing) {
			min-height: 57px;
			padding: 0;
		}
	}
}

.btn-property-navbar {
	position: relative;
	top: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: $color-white;
	color: $color-black-fake;
	margin-bottom: 0;

	.icon-svg {
		margin: 0 -0.5em 0 0.5em;
		fill: $color-black-fake;
		transition: transform 0.1s ease;
		width: 1em;
		height: 1em;
		transform: rotate(90deg);
	}

	&:not(.collapsed) {
		.icon-svg {
			transform: rotate(-90deg);
		}
	}

	&:hover, &:focus {
		.icon-svg {
			fill: $color-white;
		}
	}

	@include media-breakpoint-up(sm) {

		font-size: 1.5em;
	}

	@include media-breakpoint-up(md) {

	}
}

// TODO: the following is temporary fixes until layout can be addressed
.property-top-nav {

	&.is-stuck {
		padding-top: 0;

		figure.d-lg-none {
			margin-top: 0 !important;
		}

		ul.list-inline {
			margin-top: auto !important;
		}

		.global-breadcrumb {
			.menu-wrapper {
				display: none;
			}
		}
	}
}
