.user-profile {

	header {

		margin-bottom: 20px;

		.feature {
			height: px-vw(250);
		}

		.btn-profile {
			width: px-vw(250);
			height: px-vw(250);
			padding: 0;
			background-color: $color-aqua;
			border-radius: 50%;

			> .icon-svg {
				width: px-vw(250);
				height: px-vw(250);
				border-radius: 50%;
				overflow: hidden;

				svg {
					margin-top: 5%;
					padding-top: 25%;
					fill: $color-grey-light;
				}
			}

			.overlay {
				position: absolute;
				display: none;
				top: 0;
				width: px-vw(250);
				height: px-vw(250);
				border-radius: 50%;
				background-color: transparentize($color-black, 0.25);

				.content {
					@include absolute(5);
					width: 75%;
					line-height: 1em;
					color: $color-white;
				}

				.icon-svg {
					display: block;
					width: px-vw(40);
					height: px-vw(40);
					margin: 0 auto 0.75em auto;

					svg {
						fill: $color-white;
					}
				}
			}

			&:hover {
				.overlay {
					display: block;
				}
			}
		}

		.container-inset-feature {
			picture {
				@include image-fit();
				display: inline-block;
				width: px-vw(250);
				height: px-vw(250);
			}
		}

		.headline {
			text-transform: none;
			margin: px-vw(125) 0 25px 0;
		}
	}

	.nav-tabs {
		margin-bottom: 60px;
	}

	.user-profile-accordion {

		margin-bottom: 120px;

		.card {
			margin-bottom: 40px;

			.card-header {
				padding: 30px 35px 30px 40px;
				background-color: transparent;
				border: 0;

				.headline {
					.icon-svg {
						display: block;
						width: 32px;
						height: 32px;
						margin: -4px 8px 0.75em 0;

						svg {
							fill: $color-black;
						}
					}
				}

				.card-controls {
					@include absolute(3);
					margin: 20px;

					button {
						@include body-text();
						position: relative;
						height: 48px;
						margin-bottom: 1rem;
						vertical-align: middle;

						.icon-svg {
							width: 24px;
							height: 24px;
							margin: -2px 8px 0 0;
							transform: rotate(0deg);
							transition: transform 0.3s;

							svg { fill: $color-black; }
						}

						&.collapsed {
							.icon-svg {
								transform: rotate(180deg);
							}
						}
					}
				}
			}

			.card-block {
				@include body-text();
				margin-bottom: 1rem;
				padding: 0 40px 0 40px;
				text-align: center;

				.headline {
					margin: 0 0 0.25em 0;
				}

				ul.list-unstyled {
					@include make-row();
					margin: 6px 0 0 0;

					li {
						@include make-col-ready();
						@include make-col(12);
						margin: 15px 0;
					}

					.form-check-input {
						margin-right: 8px;
					}
				}

				.btn-transparent {
					@include headline(6);
					margin-left: -6px;
					text-decoration: underline;
				}
			}

			[data-view], [data-action="edit"] {
				display: none;
			}

			label {
				display: block;

				&.custom-select-label {
					margin-bottom: 0.5rem;

					span:not(.icon-svg) {
						position: relative;
						z-index: 1;
					}

					.icon-svg {
						top: 50%;
					}

					select {
						position: absolute;
						top: 0;
						left: 0;
						padding-top: 15px;
						padding-left: 14px;
						color: $color-black-fake;
					}
				}
			}

			.card-footer {
				background-color: transparent;
				margin-bottom: 3em;
				border: 0;
				text-align: center;
			}

			.btn-remove {
				text-decoration: underline;
				padding-left: 0px;
			}
		}
	}

	.form-control[readonly] {
		background-color: transparent;
	}

	.form-control-static {
		display: inline-block;
		line-height: inherit;
		padding-top: 0;
	}

	label {
		&[aria-disabled="true"] {
			display: none;
		}
	}

	.custom-control  {

		.custom-control-indicator-read-only {
			position: absolute;
			top: 0;
			left: 0;
			display: none;

			.icon-svg {
				width: 1.5em;
				height: 1.5em;
				margin: -1px 0.35em 0 0;
				vertical-align: middle;

				svg {
					fill: #c6c6c6;
					&:first-child {
						display: inline-block;
					}
					&:last-child {
						display: none;
					}
				}
			}
		}

		.custom-control-input {
			&:checked {
				~ .custom-control-indicator-read-only {
					.icon-svg {
						svg {
							fill: $color-aqua-dark;
							&:first-child {
								display: none;
							}
							&:last-child {
								display: inline-block;
							}
						}
					}
				}
			}
		}

		&[aria-disabled="true"] {
			pointer-events: none;
			.custom-control-indicator { display: none; }
			.custom-control-indicator-read-only { display: block;}
		}
	}

	@include media-breakpoint-up(sm) {

		header {

			.feature {
				height: 250px;
			}

			.btn-profile {
				width: 250px;
				height: 250px;

				.icon-svg {
					width: 250px;
					height: 250px;
				}

				.overlay {
					width: 250px;
					height: 250px;

					.icon-svg {
						width: 40px;
						height: 40px;
					}
				}
			}

			.container-inset-feature {
				picture {
					width: 250px;
					height: 250px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		header {

			.feature {
				height: px-vw(200, lg)
			}

			.btn-profile {
				width: px-vw(160, lg);
				height: px-vw(160, lg);

				.icon-svg {
					width: px-vw(160, lg);
					height: px-vw(160, lg);
				}

				.overlay {
					width: px-vw(160, lg);
					height: px-vw(160, lg);

					.icon-svg {
						width: px-vw(25, lg);
						height: px-vw(25, lg);
					}
				}

			}

			.container-inset-feature {
				picture {
					width: px-vw(160, lg);
					height: px-vw(160, lg);
				}
			}

			.headline {
				text-transform: none;
				margin-top: 70px;
			}
		}

		.user-profile-accordion {
			.card {
				.card-header {
					.headline {
						.icon-svg {
							width: 24px;
							height: 24px;
							display: inline-block;
							margin-bottom: 0;
						}
					}
				}
				.card-block {
					padding: 0 80px 0 80px;
					ul.list-unstyled {
						li {
							@include make-col(4);
							margin: 0 0 0.25em 0;
						}
					}
				}
				.card-footer {
					.btn {
						min-width: 165px;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {

		header {

			.feature {
				height: 200px;
			}

			.btn-profile {
				width: 160px;
				height: 160px;

				.icon-svg {
					width: 160px;
					height: 160px;
				}

				.overlay {
					width: 160px;
					height: 160px;

					.icon-svg {
						width: 24px;
						height: 24px;
					}
				}
			}

			.container-inset-feature {
				picture {
					width: 160px;
					height: 160px;
				}
			}
		}

	}

}
