.signup {
	position: relative;
	margin: 0 0 0;
	padding: 50px 15px 40px 15px;
	color: $color-white;
	background-color: shade($color-aqua-dark, 20);
	background-size: cover;
	background-position: center;
	text-align: center;

	#{headings(1, 6)} {
		margin: 0 0 0.75em 0;
		padding: 0;
	}

	p {
		margin: 0;
		line-height: 1.25em;
	}

	form {
		margin: 20px 0;
		input, .btn {
			border: 0;
		}
	}

	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.8;
		background-color: tint($color-aqua-dark, 10);
		content: '';
	}

	@include media-breakpoint-up(sm) {
		//fix-size any scaling font/spacing sizes here
	}

	@include media-breakpoint-up(md) {

		padding: 24px 32px;

		#{headings(1, 6)} {
			float: left;
			margin: 0 1.5em 0 0;
			line-height: 40px;
		}

		p {
			float: left;
			line-height: 40px;
		}
	}
}


