.user-travel-log-list {
	@extend .our-favorites;

	article {
		margin: 0 px-vw(20) 0 0;
	}

	@include media-breakpoint-up(md) {
		.carousel {
			padding: 0 50px;
		}

		article {
			margin: 0;
		}
	}
}