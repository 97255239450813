.contact-footer {

	margin-bottom: px-vw(60);
	padding-top: px-vw(80);
	border-top: 1px solid #c7c7c7;

	.row {
		margin-right: 0;
		margin-left: 0;
	}

	figure {
		margin: px-vw(89) 0 0;
		line-height: 0;

		picture {
			@include image-fit();
			width: 100%;
			height: px-vw(400);
			margin: 0;
		}
	}

	.headline-2 {
		margin-bottom: px-vw(18);
	}

	.headline-5 {
		@include font-size(32);
		margin: 15px 0 30px;
		line-height: 1.9;
	}

	.headline-6 {
		font-weight: 300;
	}

	@include media-breakpoint-up(sm) {

		margin-bottom: 60px;

		figure {
			picture {
				height: 400px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		padding-top: 0;
		border-bottom: 1px solid #c7c7c7;

		figure {
			margin: -1px 0;

			picture {
				height: 433px;
			}
		}

		.headline-2 {
			margin-bottom: 0;
		}

		.headline-5 {
			@include font-size(16);
			margin-bottom: 20px;
		}
	}
}
