.island-slider {
	position: relative;

	.container-inset {
		padding-right: 0;
		padding-left: 0;
	}

	.carousel-factory {
		width: 100%;
		margin: 0 auto;

		.carousel {
			margin: 0 auto;
		}
	}

	%layout {
		.content {
			left: 0;
			margin-top: px-vw(500);
			margin-left: 0;
		}
		.image-1 {
			top: px-vw(84);
			left: 0;
			width: px-vw(296);
			height: px-vw(366);
		}
		.image-2 {
			top: px-vw(174);
			left: px-vw(308);
			width: px-vw(295);
			height: px-vw(202);
		}
		.accent {
			top: px-vw(304);
			left: px-vw(348);
			width: px-vw(140);
			height: px-vw(140);
			filter: drop-shadow(-12px 3px 14px rgba(0,0,0,0.3));
		}
	}

	article {
		position: relative;
		width: 100%;
		text-align: center;
		overflow: hidden;

		figure {
			position: absolute;
			margin: 0;

			a {
				display: block;
				height: 100%;
				margin-bottom: 0.5em;
			}
			
			picture {
				@include image-fit();
				display: block;
				height: 100%;

				img.lazyloading {
					opacity: 1;
				}
			}

			figcaption {
				@include headline(6);
				text-align: left;
			}
		}

		.content {
			padding: 0 px-vw(20);

			h1 { @include headline(3); }

			.category {
				@include body-text();
				@include absolute(2);
				display: inline-block;
				margin-bottom: 1rem;
				font-weight: 500;
				text-transform: uppercase;
				color: inherit;
			}
		}

		.accent {
			@include absolute();
			pointer-events: none;
			img {
				max-width: 100%;
				height: auto;
			}
		}

		&.layout-1, &.layout-2 {
			@extend %layout;
		}

	}

	@include media-breakpoint-up(sm) {


	}

	@include media-breakpoint-down(sm) {

		.slick-prev { left: px-vw(120); }
		.slick-next { right: px-vw(120); }

	}

	@include media-breakpoint-up(md) {

		%layout-md {
			.image-1 {
				top: px-vw(34, lg);
				left: px-vw(25, lg);
				width: px-vw(307, lg);
				height: px-vw(322, lg);
			}
			.image-2 {
				top: px-vw(34, lg);
				left: px-vw(350, lg);
				width: px-vw(307, lg);
				height: px-vw(210, lg);
			}
			.content {
				top: px-vw(234, lg);
				left: px-vw(350, lg);
				width: px-vw(320, lg);
				margin: 0;
				padding: 0;
				text-align: left;
			}
			.accent {
				top: 0;
				right: 0;
				left: auto;
				width: px-vw(110, lg);
				height: px-vw(110, lg);
			}
		}

		.carousel-factory {
			width: px-vw(755, lg);

			.carousel-tabs {
				margin: 30px 0 20px 0;
			}
		}

		article {

			min-height: 500px;
			margin: 0 auto;

			.content {
				position: absolute;
				margin: 0;
			}
			
			&.layout-1 {
				@extend %layout-md;
				.content {
					top: px-vw(260, lg);
				}
				.image-2 {
					top: px-vw(34, lg);
				}
			}

			&.layout-2 {
				@extend %layout-md;
				.content {
					top: px-vw(234, lg);
				}
				.image-2 {
					top: 0;
				}
			}
		}



	}

	@include media-breakpoint-up(lg) {

		%layout-lg {

			.image-1 {
				top: 34px;
				left: 25px;
				width: 307px;
				height: 322px;
			}
			.image-2 {
				top: 34px;
				left: 350px;
				width: 307px;
				height: 210px;
			}
			.content {
				top: 0;
				left: 350px;
				width: 320px;
			}
			.accent {
				top: 0;
				width: 110px;
				height: 110px;
			}
		}

		.carousel-factory {

			width: 755px;

		}


		article {

			&.layout-1 {
				@extend %layout-lg;
				.content {
					top: 260px;
				}
			}

			&.layout-2 {
				@extend %layout-lg;
				.content {
					top: 234px;
				}
				.image-2 {
					top: 0;
				}
			}
		}


	}
}
