.icon-flag-background {
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
}

.icon-flag {
	position: relative;
	display: inline-block;
	width: (4 / 3) * 1em;
	line-height: 1em;
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
	&:before {
		content: '\00a0';
	}
}

@mixin flag-icon($country) {
	.icon-flag-#{$country} {
		background-image: url(#{$flag-icon-css-path}/flag-#{$country}.svg);
	}
}
