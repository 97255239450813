.global-breadcrumb {

	// TODO: revisit to avoid extensive !important use

	@include text(14, 24, 0);
	display: none;
	position: absolute;
	top: 0;
	font-weight: 300;
	color: $color-black-fake;
	z-index: $aah-zindex-fixed + 1;

	.menu-wrapper {
		position: relative;
		margin: 0 auto 0 50px;
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;

		& > ul {
			& > li {
				display: inline-block;
			}
		}

		ul {
			li {
				
				> button, > a {
					padding: 0.25em 0.5em;
				}

				> button {
					display: flex;
					align-items: center;
					font-weight: 500;

					.icon-svg {
						width: 0.65em;
						height: 0.65em;
						margin-left: 0.5em;
						transition: transform 0.2s;
					}

					&[aria-expanded="true"] {
						.icon-svg {
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
	}

	.collapse, .collapsing {
		transition: all 0.1s;
		position: absolute;
		top: 4vh;
	}

	.global-breadcrumb-nav {
		bottom: 0;
		left: 0;
		width: 25vw;
		max-width: 27em;
		min-height: 50vh;
		padding: 1.25rem;
		background-color: $color-white;
		z-index: $aah-zindex-modal;
		box-shadow: 0.25rem 0.25rem 1rem transparentize($color-black-fake, 0.95);
		overflow: visible;

		.close {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0.25em;
			padding: 0.75em;

			.icon-svg {
				width: 0.75em;
				height: 0.75em;
			}
		}

		.hotel-logo {
			max-width: 20em;
			margin: 1em auto;
			padding: 2em;
		}

		ul.navbar-nav {
			display: flex;
			flex-direction: column;
			margin: 0 0 2em 0 !important;

			> li {
				width: 100%;
				flex-direction: column;

				a:not(.btn), .navbar-a {
					text-align: left !important;
				}

				.navbar-dropdown-toggle {
					justify-content: space-between;

					.icon-svg {
						width: 0.85em !important;
						height: 0.85em !important;
						transform: none !important;
					}
				}

				&.dropdown {
					position: static !important;
				}

				// TODO: need to fix markup to avoid this
				&:nth-child(2) {
					display: none;
				}
			}
		}

		.navbar-panel {
			padding: 1rem 1.5em !important;
			transition: none !important;
			display: none !important;
			top: 0 !important;
			margin-top: 7em !important;

			&.show {
				display: flex !important;
				position: absolute !important;
				left: 100% !important;
				width: 24vw !important;

				&.navbar-panel-full {
					width: 68vw !important;
				}
			}

			.navbar-panel-nav {
				width: 100%;
				margin: 0 !important;
			}
		}

		.navbar-panel-container {
			max-height: none !important;
			padding: 1em 0.75em !important;
		}

		.btn-back {
			display: none !important;
		}

		.navbar-panel-action {
			display: none;
		}

		.navbar-action {
			height: auto !important;
			margin: 1em !important;

			.btn {
				padding: 1em !important;
				width: 100% !important;
			}
		}

	}

	.is-stuck & {
		.menu-wrapper {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {

		display: block;

	}
}
