.offer-detail {
	.property-grid {
		.card-property {
			.content {
				padding-bottom: 100px;
			}
		}
	}

	&.accordion {
		button[data-toggle="collapse"] {
			font-family: Roboto;
			font-weight: inherit;
			letter-spacing: inherit;
			color: inherit;
			width: 100%;
		}
	}
}


.offer-detail-search {
	position: relative;

	.form-actions {
		@include absolute(6);
		right: px-vw(16);
		pointer-events: none;
		background-color: white;

		.icon-svg {
			width: px-vw(34);
			height: px-vw(34);
		}
	}

	@include media-breakpoint-up(md) {
		.form-actions {
			right: 4px;

			.icon-svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.offer-summary {
	.offer-summary-content {
		border: 1px solid $color-grey-border;
		padding: 25px 50px;

		@include media-breakpoint-down(sm) {
			margin: 10px;
			padding: 25px 20px;
		}

		.price-container {
			margin-top: -50px;

			.price-area {
				background: white;
				padding: 0px 10px;

				.original-price {
					@include text(24, 32, 0);
					font-family: Oswald, sans-serif;
					font-weight: 500;
					font-size: 1.25rem;
					color: $color-grey-strikethrough;
					text-decoration: line-through;
				}

				.offer-price {
					@include text(40, 38, 0);
					font-family: Oswald, sans-serif;
					font-weight: 500;
					font-size: 2rem;
					text-decoration: none;
				}

				.price-text {
					@include text(12, 14, 0.5);
					display: inline-block;
					width: 30px;
					margin-left: 4px;
					word-break: break-word;
					text-align: left;
				}

				.offer-promo-text {
					letter-spacing: 1.67px;
				}
			}
		}

		.figure {
			margin-bottom: 0px;
		}
	}
}
