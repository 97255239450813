
html, body {
	position: relative;
	max-width: 100vw;
}

body.modal-open {
	padding-right: 0 !important;
}

#main { // sass-lint:disable-line no-ids
	position: relative;
	top: 0;	// Top & Left is to fix an issue with the width going crazy when the nav is open.
	left: 0;
	padding-top: px-vw(108);
}

//fixes position on named anchors used for jumping to section on page
[data-page-jump], .page-jump {
	position: absolute;
	margin-top: px-vw(($mobile-sticky-height + $mobile-padding) * -1) //matches nav height with padding
}

img {
	max-width: 100%;
	height: auto;
}

picture {
	img {
		margin: 0 auto;
	}
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 1.5s;
}

.section-feature {
	position: relative;
	text-align: center;
	transform: translateY(-50%);
}

.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 1.5s;

	@media screen and (min-width: 0\0) { // ie11
		transition: none;
	}

	@supports (-ms-ime-align: auto) { // edge
		transition: none;
	}
}

.inset {
	svg {
		fill: black;
	}
}

.row {
	margin-right: -6px;
	margin-left: -6px;

	@include media-breakpoint-up(md) {
		margin-right: -9.5px;
		margin-left: -9.5px;
	}
}

@include media-breakpoint-down(sm) {
	.row > .col,
	.row > [class*="col-"] {
		padding-right: 6px;
		padding-left: 6px;
	}
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.row.no-gutters {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.container {
	position: relative;
	padding-right: 6px;
	padding-left: 6px;

	@include media-breakpoint-up(md) {
		padding-right: 9.5px;
		padding-left: 9.5px;
	}

	&.container-inset {
		position: relative;
		margin-top: px-vw(-50);
		padding-top: px-vw(40);
		//padding-right: px-vw(40);
		padding-bottom: px-vw(20);
		//padding-left: px-vw(40);
		background-color: $color-white;
		z-index: auto;

		&:before {
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			content: '';
			border-top: px-vw(13) solid $color-aqua;
		}

		.container-inset-feature {
			@include absolute(2);
			margin-top: 6.5px; // 1/2 border-width of inset
			transform: translate(-50%, -50%);
			z-index: $aah-zindex-container-inset-feature;

			// todo: create 'drop'
			// position: relative;
			//	margin-top: px-vw(-100);
		}
	}

	.collapse-container {
		position: absolute;
		top: px-vw(13);
		left: 50%;
		width: 100%;
		padding-top: px-vw(70);
		background-color: $color-white;
		box-shadow: 0 4px 38px 15px rgba(0, 0, 0, 0.05);
		transform: translateX(-50%);
		z-index: $aah-zindex-collapse-container;

		.collapse-header {
			.btn-close {
				position: absolute;
				top: px-vw(20);
				right: px-vw(20);

				.icon-svg {
					width: px-vw(35);
					height: px-vw(35);
				}
			}

			.collapse-title {
				@include headline(3);
				margin: px-vw(20) 0 px-vw(50) 0;
			}
		}

		&.reverse-collapse {
			top: auto;
			bottom: 100%;
		}
	}
}

.logo-farm {
	padding-bottom: 30px;
	.row {
		margin: auto;
		padding: 0 0 20px;

		.col-md-12, .col-4, .col-md-2 {
			margin: auto;
		}
	}

	ul {
		list-style: none;
	}

	.primary-logo {
		img, span {
			max-height: 80px;
		}
	}
	img, span {
		margin: auto;
		display: block;
		max-height: 60px;
		width: auto;
		height: auto;
	}
}

ul.nav-list {
	overflow-x: scroll;
	flex-wrap: nowrap;

	li {
		white-space: nowrap;

		a {
			@include body-text();
			display: inline-block;
			margin-bottom: 1rem;
			text-transform: uppercase;
			color: $color-black-fake;

			&:hover, &:focus {
				color: $color-aqua-dark;
			}
		}

		& + li:before {
			display: inline-block;
			width: 1px;
			height: 1em;
			margin: 0 0.4em;
			content: '';
			background-color: $color-grey-body;
			vertical-align: text-bottom;
		}
	}
}

@include media-breakpoint-up(sm) {

	[data-page-jump], .page-jump {
		position: absolute;
		margin-top: ($mobile-sticky-height + $mobile-padding) * -1px; //matches nav height with padding
	}

	.container {
		&.container-inset {
			margin-top: -50px;
			padding-top: 40px;
			//padding-right: 40px;
			padding-bottom: 20px;
			//padding-left: 40px;

			&:before {
				border-top: 13px solid $color-aqua;
			}

			.container-inset-feature {
				// todo: create 'drop'
				// margin-top: -100px;
			}
		}

		.collapse-container {
			.collapse-header {
				.btn-close {
					top: 20px;
					right: 20px;

					.icon-svg {
						width: 35px;
						height: 35px;
					}
				}
				.collapse-title {
					margin: 0 0 px-vw(30, 'lg') 0;
				}
			}
		}

	}
}

@include media-breakpoint-down(sm) {
	.container {
		&.container-inset {
			margin-top: -20px;
			//width: 94%;
			z-index: 9;
		}
	}
}

@include media-breakpoint-up(md) {

	html, body {
		height: auto;
	}

	#main { // sass-lint:disable-line no-ids
		padding-top: 108px;
	}

	ul.nav-list {
		overflow-x: visible;
	}

	.container {
		&.container-inset {
			margin-top: -34px;
			padding-top: 40px;
			//padding-right: 40px;
			padding-bottom: 20px;
			//padding-left: 40px;
			z-index: 9;

			.container-inset-feature {
				@include absolute(2);
				margin-top: 6.5px; // 1/2 border-width of inset
				transform: translate(-50%, -50%);
			}
		}

		.collapse-container {
			top: 13px;
			width: 70%;
			max-width: 673px;
			padding-top: 60px;

			.collapse-header {

				.btn-close {
					top: 15px;
					right: 15px;

					.icon-svg {
						width: 1.3em;
						height: 1.3em;
					}
				}

				.collapse-title {
					margin: 0 0 30px 0;
				}
			}


		}
	}
}

@include media-breakpoint-up(lg) {

	.header {
		background-color: $color-white;
	}

	#main { // sass-lint:disable-line no-ids
		padding-top: $desktop-padding * 1px;
		background-color: $color-white;

		.has-property-nav & {
			padding-top: 138px;
		}

		.header.has-old-nav.position-static + & {
			padding-top: 0;
		}
	}

	[data-page-jump], .page-jump {
		position: absolute;
		margin-top: ($desktop-sticky-height + $desktop-padding) * -1px; //matches nav height with padding
	}

}

/* Absolute placement classes */
.absolute-1 { @include absolute(1); }
.absolute-2 { @include absolute(2); }
.absolute-3 { @include absolute(3); }
.absolute-4 { @include absolute(4); }
.absolute-5 { @include absolute(5); }
.absolute-6 { @include absolute(6); }
.absolute-7 { @include absolute(7); }
.absolute-8 { @include absolute(8); }
.absolute-9 { @include absolute(9); }

hr {
	&.hr-1 { border-top-width: 1px; }
	&.hr-2 { border-top-width: 2px; }
	&.hr-3 { border-top-width: 3px; }
	&.hr-4 { border-top-width: 4px; }
	&.hr-5 { border-top-width: 5px; }
}

.multi-col-2-md-up {
	@include media-breakpoint-up(md) {
		column-count: 2;
		column-gap: 2em;
	}
}

.LPMcontainer {
	z-index: 1039 !important;
}

.position-relative {
	position: relative;
}

.OneLinkShow {
	display: none;
}
[lang="ja"] .OneLinkShow {
	display: block;
}

.fee-disclaimer {
	font-weight: 300;
	font-size: 0.875rem;

	a {
		text-decoration: underline;
	}
}

.room-detail-footnote {
	scroll-margin-top: 180px;
}

@include media-breakpoint-up(md) {
	.room-detail-footnote {
		scroll-margin-top: 150px;
	}
}

@include media-breakpoint-up(lg) {
	.room-detail-footnote {
		scroll-margin-top: 100px;
	}
}
