//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
//@import url('https://fonts.googleapis.com/css?family=Oswald:500');

/* roboto-300 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Roboto Light'), local('Roboto-Light'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-300.woff2') format('woff2'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-300.woff') format('woff'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-300.ttf') format('truetype'),
}
/* roboto-regular - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto'), local('Roboto-Regular'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-regular.woff2') format('woff2'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-regular.woff') format('woff'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-regular.ttf') format('truetype'),
}
/* roboto-500 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Roboto Medium'), local('Roboto-Medium'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-500.woff2') format('woff2'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-500.woff') format('woff'),
	url('#{$aah-asset-path}/fonts/roboto-v18-latin-500.ttf') format('truetype'),
}
/* oswald-500 - latin */
@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Oswald Medium'), local('Oswald-Medium'),
	url('#{$aah-asset-path}/fonts/Oswald-Medium.woff2') format('woff2'),
	url('#{$aah-asset-path}/fonts/Oswald-Medium.ttf') format('truetype'),
}

.font-oswald {
	font-family: "Oswald", sans-serif;
	font-weight: 500;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) .btn:focus,
body:not(.user-is-tabbing) button:active:focus,
body:not(.user-is-tabbing) .btn:active:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) figure:focus,
body a:focus-within {
	outline: none;
	box-shadow: none !important;
}

body.user-is-tabbing button:focus,
body.user-is-tabbing .btn:focus,
body.user-is-tabbing button:active:focus,
body.user-is-tabbing .btn:active:focus,
body.user-is-tabbing input:focus,
body.user-is-tabbing select:focus,
body.user-is-tabbing textarea:focus,
body.user-is-tabbing .flatpickr-day:focus,
body.user-is-tabbing a:not(.custom-focus):focus,
body.user-is-tabbing .custom-focus:focus .focus-el,
body.user-is-tabbing figure:focus {
	outline-width: 2px !important;
	outline-style: solid !important;
	outline-color: Highlight !important;
	outline: auto 5px -webkit-focus-ring-color !important;
}

body.user-is-tabbing .nav-link:focus {
	outline: auto 5px #1c4f81 !important;
}

button, input, optgroup, select, textarea {
	font-family: 'Roboto', sans-serif;
}

a[href^="tel"] {
	color: inherit;
	text-decoration: underline;
}

p, ul, dl, .body {
	@include text(28, 40, 0);
	font-weight: 300;
	color: $color-black-fake;

	strong {
		font-weight: 400;
		letter-spacing: 0.5px;
	}

	&.small, small {
		@include text(26, 40);
		font-weight: 300;
	}
}

p a {
	text-decoration: underline;
	color: inherit;

	&:hover, &:focus {
		color: $color-aqua-dark;
		text-decoration: underline;
	}
	&[href^="tel"] {
		text-decoration: none;
		&:hover, &:focus {
			color: inherit;
		}
	}
}

a[target="_blank"] {
	&:after {
		content: "";
		display: inline-block;
		width: 1em;
		height: 1em;
		background-color: currentColor;
		background-size: contain;
		mask-image: url('#{$aah-asset-path}/img/icon-external-tab.svg');
		mask-size: cover;
		margin-left: 5px;
		vertical-align: middle;
	}
}

figure {
	picture {
		display: block;
		margin: 0 auto;

	}
	figcaption {
		margin-top: 0.5em;
	}
}

mark {
	@include highlight();
	background-color: transparent !important;
}

.headline-1 { @include headline(1); }
.headline-2 { @include headline(2); }
.headline-3 { @include headline(3); }
.headline-4 { @include headline(4); }
.headline-5 { @include headline(5); }
.headline-6 { @include headline(6); }
.headline-7 { @include headline(7); }

.number {
	display: inline-block;
	letter-spacing: 0 !important;
}

.blockquote {
	position: relative;
	padding-left: 22px;
	border-left: px-vw(18) solid $color-aqua;

	&.border-top {
		padding: px-vw(18) 0;
		border-left: 0;
	}

	&.double-quote {
		padding: px-vw(40) px-vw(60) px-vw(22);

		&:before {
			@include absolute(4);
			@include headline(1);
			top: px-vw(84);
			margin-bottom: 0;
			font-size: px-vw(100);
			content: "“";
		}
	}

	@include media-breakpoint-up(sm) {
		&.double-quote {
			&:before {
				font-size: 100px;
			}
		}
	}


	@include media-breakpoint-up(md) {
		border-width: 8px;

		&.border-top {
			padding: 25px 0 35px;
		}

		&.double-quote {
			padding: 22px 0 30px 90px;

			&:before {
				top: 90px;
				font-size: 158px;
			}
		}
	}
	p {
		font-size: 1.375rem;
		line-height: 32px;
		letter-spacing: 2px;
	}
}

a.support-number {
	color: $color-aqua-dark;
	text-decoration: underline !important;
}

@include media-breakpoint-up(md) {

	a[href^="tel"] {
		text-decoration: none;
	}

	p, ul, dl, .body {
		@include text(14, 24);

		strong {
			font-weight: 500;
		}

		&.small, small {
			@include text(12, 24);
		}
	}

	figure {
		figcaption {
			margin-top: 0.75em;
		}
	}

}


dl.qa {
	dt {
		@include headline(5);
	}

	dt,
	dd {
		display: table;
		width: 100%;

		.qa-label {
			display: table-cell;
			width: px-vw(42);
			padding-right: 10px;
		}
	}

	@include media-breakpoint-up(md) {
		dt,
		dd {
			.qa-label {
				width: 22px;
				max-width: 22px;
			}
		}
	}
}


@for $i from 75 through 200 {
	$step: 5;
	@if $i % $step == 0 {
		.#{line-height}-#{($i)} {
			line-height: #{($i) + '%'};
		}
	}
}

.letter-spacing-0 {
	letter-spacing: 0;
}

@media not all and (min-resolution: .001dpcm)
{ @supports (-webkit-appearance: none) {
	.zoom-enabled,
	.zoom-enabled * {
		@include media-breakpoint-down(md) {
			font-size: 1vw !important;
			line-height: 4vw !important;
		}
	}
}}
