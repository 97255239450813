.travel-log-list {
	margin-bottom: 3em;

	.no-results {
		margin: 1.5rem 3.3rem 2rem;

		padding: 30px;
		border: 1px solid lighten($color-grey, 30%);
		text-align: center;

		button {
			@include body-text();
			margin-bottom: 0;
			text-decoration: underline;
		}

		.icon-svg {
			position: absolute;
			top: px-vw(-30);
			left: 50%;
			width: px-vw(66);
			height: px-vw(66);
			background-color: white;
			transform: translateX(-50%);

			svg {
				padding: px-vw(10);
			}
		}
	}

	a[data-toggle='modal'] {
		@include body-text();
		margin-bottom: 1rem;
		text-decoration: underline;

		&:hover {
			color: $color-aqua-dark;
		}
	}

	@include media-breakpoint-up(sm) {

		.no-results {
			.icon-svg {
				width: 66px;
				height: 66px;

				svg {
					padding: 10px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		padding: 0 50px;

		.no-results {
			width: 95%;
			margin: 14px auto 32px;
			padding: 30px 15px 15px;

			.icon-svg {
				top: -23px;
				width: 49px;
				height: 49px;
			}

			p {
				margin:  0;
			}
		}
	}
}
