.vacation-inspiration {

	padding: px-vw(92) 0 px-vw(30) 0;
	text-align: center;

	a {
		display: block;
		color: inherit;
		text-decoration: none;
	}

	ul {
		padding: 0;
		list-style: none;

		li {
			display: block;
			text-align: left;
		}
	}

	.background {
		top: 0px;
		position: absolute;
		width: 100%;
		height: initial;
		min-height: 100%;
		overflow: hidden;

		picture img, video, iframe {
			@include absolute(5);
			//width: initial;
			min-width: 101%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover; object-position: center;'; // object-fit-images polyfill
		}

		picture {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;

			img {  }
		}

		video, iframe {
			display: block;
		}

		// ie11 + edge video fix
		@media screen and (min-width: 0\0) {
			video {
				height: auto;
			}
		}
		@supports (-ms-ime-align: auto) { // edge
			video {
				height: auto;
			}
		}
	}

	article {

		.category {
			@include headline(6);
			display: block;
			width: 100%;
			padding-top: 0.5em;
			text-transform: lowercase;
			border-top-style: solid;
			border-top-width: px-vw(10);
			border-color: $color-black;
		}

		.content {
			display: inline-block;
			width: px-vw(250);
			margin-top: px-vw(10);
			vertical-align: top;
		}

		figure {
			display: inline-block;
			margin: 0 px-vw(42) px-vw(54) px-vw(15);

			picture {
				@include image-fit();
				display: inline-block;
				width: px-vw(240);
				height: px-vw(240);
				vertical-align: top;
			}
		}

		.vacation-inspiration-footer {
			margin: px-vw(16) 0 px-vw(54) 0;

			.link {
				@include body-text();
				display: inline-block;
				margin-bottom: 1rem;
				text-decoration: none;
				border-bottom-width: 2px;
				border-bottom-style: solid;
				cursor: pointer;

				&:hover, &:focus {
					font-weight: 400;
				}
			}
		}
	}

	.color-theme-teal {
		img { background-color: $color-aqua; }
		.category, .vacation-inspiration-footer .link { border-color: $color-aqua; }
	}

	.color-theme-purple {
		img { background-color: $color-purple; }
		.category, .vacation-inspiration-footer .link { border-color: $color-purple; }
	}

	.color-theme-green {
		img { background-color: $color-green; }
		.category, .vacation-inspiration-footer .link { border-color: $color-green; }
	}

	@include media-breakpoint-up(sm) {
		//fix-size any scaling font/spacing sizes here

		padding: 92px 0 30px 0;

		article {

			.category {
				border-top-width: 10px;
			}

			.content {
				margin-top: 10px;
			}

			figure {
				margin: 0 42px 54px 15px;
			}

			.vacation-inspiration-footer {
				margin: 16px 0 54px 0;
			}
		}
	}

	@include media-breakpoint-up(md) {

		@include background-shape("angle");

		margin-bottom: 30px;
		padding: 92px 0 30px 0;
		
		ul {
			li {
				display: inline-block;
				width: 300px;
			}
		}

		article {

			figure {
				display: block;
				margin: 0 auto 36px auto;

				picture {
					position: relative;
					display: block;
					width: px-vw(274, lg);
					height: px-vw(274, lg);

					img {
						width: auto;
						max-height: 100%;
					}
				}
			}

			.content {
				width: 100%;
			}

			.category {
				width: 90%;
				padding-top: 15px;
			}

			.vacation-inspiration-footer {
				margin: 15px 0 15px 0;

				.link {
					border-bottom-width: 1px;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {

		article {
			figure {
				picture {
					width: 274px;
					height: 274px;
				}
			}
		}

	}

}

