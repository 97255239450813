.tooltip {
	&.show {
		opacity: 1;
	}

	&.bs-tooltip-right .arrow {
		margin-top: -3px;

		&:before {
			border-width: 7.5px 0.4rem 7.5px 0;
		}
	}
}

.tooltip-inner {
	color: black;
	text-align: center;
	background-color: white;
	border: 1px solid rgba(0,0,0,.2);
	max-width: 276px;
	padding: .5rem .75rem;

	@include media-breakpoint-up(md) {
		z-index: $aah-zindex-parametric;
	}
}
