// sass-lint:disable mixins-before-declarations

.carousel-card {

	width: 100%;
	padding: px-vw(80) 0 px-vw(30) 0;
	background-color: $color-white;
	border: 1px solid $color-grey-border;
	border-bottom: px-vw(14) solid $color-aqua;

	@include media-breakpoint-up(sm) {

		padding: 80px 0 30px 0;
		border-bottom-width: 14px;

	}

	@include media-breakpoint-up(md) {

		margin-bottom: 1em;
		padding: px-vw(40, 'lg') 0 px-vw(20, 'lg') 0;
		border-bottom-width: 8px;

	}

	@include media-breakpoint-up(lg) {

		padding: 40px 0 20px 0;

	}
}
