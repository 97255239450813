.travel-log-listing {

	margin: 1.5rem 3.3rem 2rem;
	padding: 20px;
	border: 1px solid lighten($color-grey, 30%);
	text-align: center;

	figure {
		margin-bottom: 2em;

		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
			padding-bottom: 37.42%;
		}
	}

	picture,
	img {
		width: 100%;
	}
	
	.btn {
		max-width: 100%;
		white-space: pre-wrap;
	}

	@include media-breakpoint-up(md) {

		width: 95%;
		margin: 0 auto 32px;

		figure {
			margin: 0;

			picture {
				padding-bottom: 40.40%;
			}
		}
	}

	.headline-3 {
		overflow-wrap: break-word;
		word-break: break-word;
		-ms-word-break: break-all;
		word-wrap: break-word;
	}
}
