.amenities-listing {
	padding: 0 12px;

	figure {
		picture {
			max-width: px-vw(306);
		}
	}

	@include media-breakpoint-up(md) {
		figure {
			picture {
				max-width: px-vw(306, lg);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		figure {
			picture {
				max-width: 306px;
			}
		}
	}
}