.press-listing {
	height: 100%;
	text-align: left;

	footer {
		margin-top: auto;
	}

	.lazyloaded {
		transition: none;
	}
}
