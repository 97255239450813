.travel-log-flyout {

	position: relative;
	padding: 0 px-vw(40) px-vw(5);
	text-align: center;

	.description {
		margin-bottom: px-vw(20);
	}

	.form-group {
		margin: px-vw(40) auto px-vw(20);

		.btn {
			padding-right: px-vw(20);
			padding-left: px-vw(20);
		}
	}

	.btn-cancel {
		@include body-text();
		margin-bottom: 0;
		text-decoration: underline;
	}

	ul {
		margin: 0 auto px-vw(45);
		padding: 0;
		list-style: none;
		border-top: 1px solid $color-grey-body;

		li {
			position: relative;
			padding: px-vw(22) px-vw(60);

			.btn-overlay {
				margin: 0;
				height: 100%;

				.icon-svg {
					width: px-vw(38);
					height: px-vw(38);

					svg  {
						// additional &:first-child svg needed for Firefox svg rendering
						&:first-child, &:first-child svg {
							fill: $color-black;
						}
						&:last-child, &:last-child svg {
							fill: $color-aqua-dark;
						}
					}
				}
			}
		}

	}

	.headline-5 {
		display: inline-block;
		margin: 0px 20px 0px 0px;
		padding-top: 5px;
		overflow-wrap: break-word;
		word-break: break-word;
		-ms-word-break: break-all;
		word-break: break-all;
		word-wrap: break-word;
	}

	.btn-create-log {
		@include body-text();
		margin-bottom: 0;
		text-decoration: underline;
		font-weight: 500;

		&:hover {
			color: $color-aqua-dark;
		}
	}

	footer {
		margin-bottom: px-vw(54);

		button {
			@include body-text();
			margin: 0;
			text-decoration: underline;

			&:hover {
				color: $color-aqua-dark;
			}
		}
	}

	.hide {
		display: none;
	}

	.show {
		display: block;
	}

	// Hidden by default
	form {
		display: none;
		margin-bottom: px-vw(45);
	}

	// Shown on Form View
	&[data-form-view='true'] {
		form {
			display: block;
		}

		footer {
			display: none;
		}
	}

	&.form-view-only {
		form {
			display: block;
		}
	}

	@include media-breakpoint-up(sm) {

		ul {
			li {
				.btn-overlay {
					.icon-svg {
						width: 38px;
						height: 38px;
					}
				}
			}
		}

	}

	@include media-breakpoint-up(md) {

		padding: 0 40px 2px;

		.description {
			margin-bottom: 0.5rem;
		}

		.form-group {
			width: 386px;
			margin: 0.5rem auto;
		}

		ul {
			width: 470px;
			margin: 0 auto 2rem;

			li {
				padding: 10px 15px;

				.btn-overlay {
					.icon-svg {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		form {
			margin-bottom: 2rem;
		}

		footer {
			margin-bottom: 40px;
		}
	}
}
