.personal-guidance-result {

	margin-top: px-vw(-370);

	figure {
		display: inline-block;
		width: px-vw(457);
		height: px-vw(457);
		margin: 0 auto 2em;

		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
		}
	}

	.btn {
		margin: 0.5em 0 1em;
	}

	footer {
		@include body-text();
		@include font-size(28);
		margin-bottom: 1rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid $color-grey-border;

		.icon-svg {
			width: 1.2em;
			height: 1.2em;
			margin-left: 5px;
			fill: $color-aqua-dark;
		}
	}

	@include media-breakpoint-up(sm) {

		margin-top: -370px;

		figure {
			width: 457px;
			height: 457px;
		}
	}

	@include media-breakpoint-up(md) {

		margin-top: px-vw(-340, lg);

		figure {
			width: px-vw(457, lg);
			height: px-vw(457, lg);
		}

		footer {
			@include font-size(16);
		}
	}

	@include media-breakpoint-up(lg) {

		margin-top: -340px;

		figure {
			width: 457px;
			height: 457px;
		}

	}
}
