.reservation-flyout {
	.loader-bg {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.loader-text {
		font-weight: 400;
		font-size: 1rem;
		display: block;
		width: 60%;
		margin-top: 16px;
		line-height: 1.5;
		text-align: center;
	}

	.modal-header {
		position: relative;
		padding: px-vw(32) px-vw(35) px-vw(32) px-vw(45);

		.close {
			@include absolute(3);
			margin: px-vw(40);
			opacity: 1;

			.icon-svg {
				width: px-vw(32);
				height: px-vw(32);
			}
		}
	}

	.modal-body, .modal-footer {
		padding: 0 px-vw(40);
	}

	form {
		height: 100%;
		overflow: visible;

		label { @include headline(6); }

		fieldset {
			margin: 0 0 20px 0;
			padding: 0 0 0 px-vw(30);
			border-left: px-vw(11) solid $color-aqua;
		}
	}

	.form-actions {
		@include absolute(9);
		width: 50px;
		display: inline-block;
		height: 100%;
		z-index: 2200;

		button {
			@include absolute(9);
			bottom: px-vw(22);
			width: px-vw(48);
			height: px-vw(48);
			vertical-align: middle;

			&.btn-search {
				right: px-vw(96);
				pointer-events: none;

				.icon-svg {
					svg { fill: $color-black; }
				}
			}

			&.btn-close {
				display: none;
				right: 0;
				padding: 2px;
				border-radius: 100%;
				background-color: $color-aqua-dark;

				.icon-svg {
					@include absolute(5);
					width: 40%;
					height: 40%;
					svg { fill: $color-white; }
				}
			}
		}

	}

	.form-group {
		position: relative;
	}

	.form-group-number {
		border-left: none;
		border-right: none;
		border-bottom: 1px solid $color-grey-border;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
		margin-bottom: 0;

		&:last-of-type {
			border-bottom: none;
		}

		label {
			margin: 0;
			width: auto;
		}

		.input-group-number {
			margin: 0;
			padding: 0;
			width: auto;

			button .icon-svg {
				border: 1px solid black;
				border-radius: 50%;
				width: 16px; height: 16px;
			}

			input[type="number"] {
				width: 1.5em;
				font-size: 1rem;
				letter-spacing: 0;
			}
		}
	}

	.form-control[readonly] {
		position: initial;
		opacity: 1;
		width: 100%;
		// height: auto;
		background-color: inherit;
	}

	.form-check {
		padding-left: 1.25rem;
	}

	.btn-transparent {
		@include body-text("small");
		display: inline-block;
		margin-bottom: 1rem;
		text-decoration: underline;

		&.btn-back {
			text-decoration: none;
			padding: 0;
			margin: 0 0 px-vw(40) 0;

			.icon-svg {
				width: px-vw(28);
				height: px-vw(28);
				vertical-align: middle;
				margin: -1px 0 0 0;
			}
		}

		&.btn-edit {
			display: none;
			float: right;
		}
	}

	ul.search-results {
		padding: 0;

		li {
			list-style: none;
			display: block;
		}
	}

	ul.list-options {
		padding: 0;

		li.form-check {
			margin: 1.25em 0 0 0;
		}

		.form-check-input {
			opacity: 0;

			&:focus + span {
				outline: 1px dotted #212121;
				outline: 5px auto -webkit-focus-ring-color;
			}

			&:checked + span {
				font-weight: bold;
			}
		}

		button {
			padding: 0;
			font-weight: 300;
			color: $color-black-fake;
			text-transform: capitalize;

			&:hover {
				color: $color-aqua-dark;
			}

			span {
				pointer-events: none;
			}
		}

		.form-check-label {
			margin: 0;
			padding: 0;
			outline: 0 !important;

			&:hover, &:focus {
				color: $color-aqua-dark;
			}
		}

		label,
		[data-label-parent] {
			font-weight: 300;
		}
	}

	ul.promo-codes-applied {
		margin: 1em 0;
		padding: 0;

		li {
			@include headline(7);
			margin: 0.5em 0;
			padding: 0.75em 1.25em;
			background-color: $color-grey-light;
			width: 110%;

			.btn-remove {
				float: right;
				margin-top: -4px;
				padding: 0;
				text-align: right;
				background-color: transparent;

				.btn-label {
					@include body-text("small");
					margin: 0;
					line-height: 1em;
					text-transform: none;
					color: $color-black-fake;
					text-decoration: underline;
				}

				.icon-svg {
					width: 20px;
					height: 20px;
					margin-left: 4px;
					border-radius: 100%;
					background-color: $color-aqua-dark;

					svg {
						@include absolute(5);
						fill: $color-white;
						width: 8px;
						height: 8px;
					}
				}
			}
		}
	}

	.flatpickr-calendar {

		.rate {
			display: inline-block;
			font-size: px-vw(15);
			color: $color-black-fake;
			letter-spacing: 0.2px;
			font-weight: 300;
			pointer-events: none;
		}

		.flatpickr-prev-month,
		.flatpickr-next-month {
			svg {
				height: 14px;
			}
		}

		.flatpickr-day {
			max-width: 14.2857%;
			white-space: nowrap
		}
	}

	.reservation-dropdown {
		position: absolute;
		border: 1px solid $gray-light;
		margin-top: -1px;
		background-color: $color-white;
		overflow-y: auto;
		overflow-x: hidden;
		min-width: 100%;
		width: auto;
		max-height: 20em;
		z-index: $aah-zindex-dropdown;

		.form-check-label {
			line-height: 36px;
			border-top: 1px solid $color-black;
		}

		.custom-checkbox {
			display: flex;
			align-items: flex-start;
			margin: 0;
			padding: 0.75em 1em;
			font-weight: normal;
			line-height: 1.5em;
			border-bottom: 1px solid $color-grey-border;
			cursor: pointer;

			&:last-of-type {
				border-bottom: none;
			}

			.custom-control-indicator {
				position: relative;
				flex-shrink: 0;
				margin-right: 0.5em;
			}
		}

		.flatpickr-calendar {
			margin: 0;
			border: 0;
		}

		.reservation-dropdown-actions {
			display: flex;
			justify-content: center;
			margin: 1em auto;
		}
	}

	#mar-search-results-container {
		max-height: 25em;

		.destination-header {
			display: flex;
			align-items: center;
			margin: 0.5em 0 0;
			padding: 0.75em 1em;
		}

		.destination-search-label {
			@include headline(3);
			margin: 0 0.5em 0 0;
		}

		.destination-search-count {
			white-space: nowrap;
		}

		button {
			@include headline(6);
			display: block;
			width: 100%;
			margin: 0;
			padding: 0.75em 1.75em;
			font-weight: 400;
			text-align: left;
			color: $color-black-fake;
			cursor: pointer;

			&:hover,
			&.active {
				background-color: $color-grey-light;
				font-weight: 500;
			}
		}
	}

	.dates-unavailable-container {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: $aah-zindex-modal + 1;
		display: flex;
		align-items: center;
		justify-content: center;

		.dates-unavailable-dialog {
			position: relative;
			width: 250px;
			background: white;
			border: 1px solid $color-black-fake;
			text-align: center;
			padding: 24px;
			font-size: 14px;

			.dates-unavailable-msg {
				font-weight: bold;
				padding-bottom: 6px;
			}

			.btn-close {
				position: absolute;
				top: 0;
				right: 0;

				.icon-svg {
					height: 12px;
					width: 12px;
				}
			}
		}
	}

	#special-rate-input {
		text-align: left;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.modal-footer {
		justify-content: flex-start;
		font-family: 'Oswald';
		text-transform: uppercase;
		flex-direction: column;
	}

	&.active {
		width: 100%;
		margin-right: 0;
		-webkit-overflow-scrolling: touch;
	}

	@include media-breakpoint-up(sm) {

		.modal-header {
			padding: 32px 35px 32px 45px;

			.close {
				margin: 40px;

				.icon-svg {
					width: 32px;
					height: 32px;
				}
			}
		}

		.modal-body, .modal-footer {
			padding: 0 40px;
		}

		form {
			fieldset {
				padding: 0 0 0 30px;
				border-left-width: 11px; }
		}

		.form-actions {

			button {
				width: 48px;
				height: 48px;
				bottom: 22px;

				&.btn-search { right: 96px; }
			}
		}

		ul.promo-codes-applied {
			li {
				.btn-remove {
					margin: 0;
				}
			}
		}

		.flatpickr-calendar {
			.rate {
				font-size: 14px;
			}
		}
	}

	@include media-breakpoint-up(md) {

		.loader-text {
			margin-top: 0;
		}

		.flatpickr-months {
			height: 33px;
		}

		&.active {
			width: 560px;
		}

		.container {
			width: 560px;
			min-width: 560px;
			height: 100%;
		}

		.modal-dialog {
			max-width: 800px;
		}

		.modal-header {

			padding: 28px 35px 28px 45px;

			.close {
				margin: 28px;

				.icon-svg {
					width: 22px;
					height: 22px;
				}
			}
		}

		form {
			padding: 20px 40px;

			fieldset {
				padding: 0 0 0 20px;
				border-left-width: 5px;
			}
		}

		.form-actions {
			button {
				width: 32px;
				height: 32px;
				bottom: 8px;

				&.btn-search { right: 10px; }
			}
		}

		.btn-transparent {
			&.btn-back {
				margin: 0 0 8px 0;
				.icon-svg {
					width: 10px;
					height: 10px;
				}
			}
		}

		.scroll-container {
			height: 175px;
			overflow-y: scroll;
		}

		ul.list-options {
			padding: 0;

			li.form-check {
				margin: 0 0 0.25em 0;
			}
		}

		.flatpickr-calendar {
			margin-left: 12px;

			.rate {
				width: 100%;
				font-size: 10px;
			}
		}

		.modal-footer {
			flex-direction: row;

			&>div {
				margin: 0;
			}
		}
	}
}
