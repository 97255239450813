.offer-list {
	text-align: center;
	
	@include media-breakpoint-up(md) {
		margin-bottom: 5em;
		.carousel-factory {
			.carousel {
				width: 100%;
				padding: 0 50px;
			}
		}
		
	}
}
