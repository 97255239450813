$rating-circle-size-desktop: 20px;
$rating-circle-size-desktop-sm: 16px;
$rating-circle-size-mobile: 24px;

.rating {
	position: relative;
	display: inline-block;
	margin: 0;
	padding-right: 2px;
	overflow: hidden;
	vertical-align: middle;

	.rating-dots {
		@include absolute();
		width: 50%;
		overflow: hidden;
	}

	.rating-circles, .rating-dots {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;

		.icon-svg {
			width: px-vw($rating-circle-size-mobile);
			height: px-vw($rating-circle-size-mobile);
			margin-right: -1px;
			flex-shrink: 0;

			svg { fill: $color-trip-advisor; }
		}
	}

	&.rating-05 .rating-dots { width: 10%; }
	&.rating-10 .rating-dots { width: 20%; }
	&.rating-15 .rating-dots { width: 30%; }
	&.rating-20 .rating-dots { width: 40%; }
	&.rating-25 .rating-dots { width: 50%; }
	&.rating-30 .rating-dots { width: 60%; }
	&.rating-35 .rating-dots { width: 70%; }
	&.rating-40 .rating-dots { width: 80%; }
	&.rating-45 .rating-dots { width: 90%; }
	&.rating-50 .rating-dots { width: 100%; }


	@include media-breakpoint-up(sm) {

		.rating-circles, .rating-dots {
			.icon-svg {
				width: $rating-circle-size-mobile;
				height: $rating-circle-size-mobile;
			}
		}

	}

	@include media-breakpoint-up(md) {

		.rating-circles, .rating-dots {
			.icon-svg {
				width: px-vw($rating-circle-size-desktop, lg);
				height: px-vw($rating-circle-size-desktop, lg);
			}
		}

		&.rating-sm {
			.rating-circles, .rating-dots {
				.icon-svg {
					width: px-vw($rating-circle-size-desktop-sm, lg);
					height: px-vw($rating-circle-size-desktop-sm, lg);
				}
			}
		}

	}

	@include media-breakpoint-up(lg) {

		.rating-circles, .rating-dots {
			.icon-svg {
				width: $rating-circle-size-desktop;
				height: $rating-circle-size-desktop;
			}
		}

		&.rating-sm {
			.rating-circles, .rating-dots {
				.icon-svg {
					width: $rating-circle-size-desktop-sm;
					height: $rating-circle-size-desktop-sm;
				}
			}
		}

	}
}

.rating-count {
	@include body-text();
	display: inline-block;
	margin: 0;
	vertical-align: middle;

	a {
		text-decoration: underline;
		white-space: nowrap;
	}
}

