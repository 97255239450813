.property-landing-overview {

	text-align: center;

	.collapsing {
		height: px-vw(280);
	}

	.content {
		margin-bottom: px-vw(40);

		.collapsible-btn {
			@include body-text();
			margin-bottom: 1rem;
			text-decoration: underline;
		}

		footer {
			.list-unstyled {
				margin: px-vw(35) 0 px-vw(50);
			}
		}
	}

	.image-footer {
		height: px-vw(564);
		position: relative;
	}

	figure {
		position: absolute;
		margin: 0;

		picture {
			@include image-fit();
			display: block;
			height: 100%;

			img.lazyloading {
				opacity: 1;
			}
		}

		.btn {
			@include absolute(5);
		}
	}

	.image-1 {
		left: px-vw(220);
		width: px-vw(344);
		height: px-vw(344);
	}

	.image-2 {
		top: px-vw(226);
		left: px-vw(64);
		width: px-vw(143);
		height: px-vw(143);
	}

	.image-3 {
		top: px-vw(358);
		left: px-vw(220);
		width: px-vw(246);
		height: px-vw(159);
	}

	.image-4 {
		top: px-vw(70);
		left: px-vw(64);
		width: px-vw(143);
		height: px-vw(143);
	}

	.trip-advisor {
		border-top: 1px solid $color-grey-border;
		padding-top: 45px;

		p {
			margin-bottom: 0;
		}

		picture {
			display: block;
			width: 42%;
			margin: 0 auto px-vw(30);
		}

	}

	@include media-breakpoint-down(sm) {

		.content {
			margin-bottom: 40px;

			.collapsible {
				&:after {
					display: none;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {

		.content {
			ul.list-separated {
				li {
					&:before {
						content: none;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		article {
			min-height: px-vw(389, lg);
		}

		.collapsing {
			height: 168px;
		}

		.content {
			margin-bottom: 10px;
			padding-right: 80px;

			.list-unstyled {}

			footer {
				.list-unstyled {
					margin: 10px 0;
				}
			}

			.collapsible-btn {
				margin: 10px 0;
				padding: 0;
			}
		}

		.image-footer {
			height: px-vw(147, lg);
		}

		figure {
			.btn .icon-svg {
				width: 5em; 
				height: 5em;
			}
		}

		.image-1 {
			top: 0;
			left: px-vw(9.5, lg);
			width: px-vw(389, lg);
			height: px-vw(389, lg);
			padding-right: px-vw(8, lg);
		}

		.image-2 {
			top: 0;
			left: 0;
			width: px-vw(147, lg);
			height: px-vw(147, lg);
		}

		.image-3 {
			top: 0;
			left: px-vw(164, lg);
			width: px-vw(227, lg);
			height: px-vw(147, lg);
		}

		.image-4 {
			top: px-vw(-48, lg);
			left: px-vw(408, lg);
			width: px-vw(143, lg);
			height: px-vw(143, lg);
		}

		.trip-advisor {
			margin-top: 30px;
			padding-top: 20px;

			.headline-6 {
				display: inline-block;
				margin-right: 8px;
			}

			.logo {
				height: 26px;
			}

			.rating-count {
				@include font-size(12);
				margin-right: 2px;
			}

			p {
				display: inline-block;
				margin-right: 10px;
			}

			picture {
				display: inline-block;
				margin-bottom: 0;
				width: auto;
				max-width: 128px;
			}
		}
	}

	@include media-breakpoint-up(lg) {

		article {
			min-height: 389px;
		}

		.image-footer {
			height: 147px;
		}

		.image-1 {
			left: 9.5px;
			width: 389px;
			height: 389px;
			padding-right: 8px;
		}

		.image-2 {
			width: 147px;
			height: 147px;
		}

		.image-3 {
			left: 164px;
			width: 227px;
			height: 147px;
		}

		.image-4 {
			top: -48px;
			left: 408px;
			width: 143px;
			height: 143px;
		}
	}
}
