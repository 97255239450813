.vacation-inspiration-listing {

	position: relative;
	display: flex;
	margin: 0;
	padding: px-vw(32) 0 px-vw(36) 0;
	flex-direction: row;
	border-bottom: 1px solid $color-grey-border;

	.btn-overlay {
		margin: 0.75em 0.25em;

		.icon-svg {
			svg {
				fill: $color-black;
			}
		}
	}

	.d-flex {
		width: 100%;
	}

	figure {
		display: inline-block;
		width: 40%;
		margin: 0;

		a {
			display: block;
		}

		picture {
			@include image-fit();
			display: block;
			width: 100%;
			height: 100%;
			padding-bottom: 66.6667%;

			img {
				opacity: 1;
			}
		}
	}

	.content {
		display: flex;
		max-width: 50%;
		padding: 0 50px 0 14px;
		flex-direction: column;
		text-align: left;
	}

	.category {
		@include body-text();
		margin: auto 0 0 0;
	}

	@include media-breakpoint-up(sm) {
		padding: 32px 0 36px 0;
	}

	@include media-breakpoint-up(md) {

		display: block;
		padding: 0;
		margin-bottom: 2em;
		border: 0;

		&:after {
			display: none;
		}

		.btn-overlay {
			margin: 0;

			.icon-svg {
				svg {
					fill: $color-white;
				}
			}
		}

		figure {
			margin-bottom: 10px;
			width: 100%;

			picture {
				width: 100%;
				height: 100%;
				padding-bottom: 66.45%;

			}
		}

		.content {
			max-width: none;
			padding: 0;
			flex-direction: row;
		}

		.headline-5 {
			width: 70%;
			padding-right: 20px;
		}

		.category {
			margin: 0 0 0 auto;
		}
	}

	@include media-breakpoint-up(lg) {


	}
}
