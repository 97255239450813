.navbar-toggler {

	position: absolute;
	top: auto;
	right: 0;
	width: px-vw(92);
	height: 100%;
	margin: 0;
	padding: 0;
	background: $color-white;
	border: 0;
	z-index: 2;

	.navbar-toggler-icon {
		@include absolute(5);
		display: block;
		width: 40%;
		height: px-vw(5);
		background-image: none;
		background-color: $color-aqua-dark;
		transition: background 0s 0.1s;

		&:before {
			top: px-vw(-12);
			/* autoprefixer: off */
			transition-property: top, transform;
		}

		&:after {
			bottom: px-vw(-12);
			/* autoprefixer: off */
			transition-property: bottom, transform;
		}

		&:before,
		&:after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height: px-vw(5);
			transition-duration: 0.1s, 0.1s;
			transition-delay: 0.1s, 0s;
			background: $color-aqua-dark;
			content: "";
		}
	}

	&:not(.collapsed) {
		.navbar-toggler-icon {
			background: transparent;

			&:before {
				top: 0;
				transform: rotate(45deg);
			}

			&:after {
				bottom: 0;
				transform: rotate(-45deg);
			}

			&:before,
			&:after {
				transition-delay: 0s, 0.1s;
			}
		}
	}

	.is-stuck & {
		width: px-vw(92);
		height: 100%;
		margin: 0;
	}


	@include media-breakpoint-up(sm) {

		.navbar-toggler-icon {
			width: 35%;
			height: 5px;

			&:before {
				top: -12px;
			}

			&:after {
				bottom: -12px;
			}

			&:before,
			&:after {
				height: 5px;
			}
		}
	}


	@include media-breakpoint-up(md) {

		.navbar-toggler-icon {
			width: 30%;
		}
	}
}
