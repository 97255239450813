button {
	background: transparent;
	border: 0;
	border-radius: 0;
	cursor: pointer;

	svg {
		pointer-events: none;
	}

	&:disabled {
		cursor: default;
	}
}

.btn {
	@include button();
	&.btn-secondary { @include button("white"); }
	&.btn-outline, &.btn-outline-primary { @include button("stroke"); }
	&.btn-transparent {
		background-color: transparent;
		//transition: all 0.2s ease;
		&:hover {
			background-color: transparent;
			//transform: scale(1.1);
		}
	}
	&.disabled, &:disabled, &[aria-disabled="true"] { @include button("disabled"); }

	&.btn-round {
		position: relative;
		width: px-vw(76);
		min-width: 0;
		height: px-vw(76);
		padding: 0.75em;
		border-radius: 50%;
		transition: all 0.2s ease;
		margin-bottom: 0;

		.icon-svg {
			@include absolute(5);
			width: px-vw(36);
			height: px-vw(36);
		}

		&:hover {
			transform: scale(1.1);
		}
	}

	&.btn-play {
		@include absolute(5);
		background-color: transparent;

		.icon-svg {
			width: px-vw(117);
			height: px-vw(117);
		}

		&:hover {
			background-color: transparent;
		}

		@include media-breakpoint-up(md) {
			.icon-svg {
				width: 7.5em;
				height: 7.5em;
			}
		}
	}

	& + .btn {
		margin-left: 0.75em;
	}

	&:focus {
		box-shadow: none;
	}

	&.btn-accent {
		background-color: $color-magenta;
		&:hover, &:focus {
			color: darken($color-magenta, 10%);
			background-color: $color-white;
			border-color: darken($color-magenta, 10%);
		}
		&.disabled, &:disabled, &[aria-disabled="true"] { @include button("disabled"); }
	}

	&.btn-facebook {
		background-color: $color-facebook;
		&:hover, &:focus {
			background-color: darken($color-facebook, 10%);
			color: $color-white;
		}
	}

	&.btn-google {
		background-color: $color-google;
		&:hover, &:focus {
			background-color: darken($color-google, 10%);
			color: $color-white;
		}
	}

	&.btn-grey {
		background-color: $color-grey-light-2;
		&:hover, &:focus {
			background-color: darken($color-grey-light-2, 10%);
			color: $color-black;
		}
	}
}

.btn-overlay {
	@include absolute(3);
	top: 0;
	width: px-vw(48);
	min-width: 0;
	height: px-vw(48);
	margin: 0.25em 0.5em;
	padding: 0;
	background-color: transparent;
	z-index: $aah-zindex-button-overlay;

	.icon-svg {
		@include absolute(5);
		width: px-vw(40);
		height: px-vw(40);

		svg {
			fill: $color-white;
		}
	}

	&:hover, &:focus {
		background-color: transparent;
		svg {
			fill: $color-aqua;
		}
	}

	// Custom Checkboxes
	.form-check-input {
		opacity: 0;
		pointer-events: none;

		~ .icon-svg svg:first-child {
			display: block;
		}

		~ .icon-svg svg:last-child {
			display: none;
		}

		&:checked {
			~ .icon-svg svg:first-child {
				display: none;
			}

			~ .icon-svg svg:last-child {
				display: block;
			}
		}
	}
}

.skip-link {
	&:focus {
		position: fixed;
		padding: 1em;
		background-color: $color-white;
		font-size: 0.875rem;
		z-index: $aah-zindex-popover;
	}
}

@include media-breakpoint-up(sm) {

	.btn {
		&.btn-round {
			width: 76px;
			height: 76px;

			.icon-svg {
				width: 36px;
				height: 36px;
			}
		}

		&.btn-dropdown {
			font-size: 28px;

			& + .dropdown-menu {
				font-size: 28px;
			}
		}
	}

	.btn-overlay {
		width: 48px;
		min-width: 0;
		height: 48px;
		padding: 0;

		.icon-svg {
			width: 40px;
			height: 40px;
		}
	}
}

@include media-breakpoint-up(md) {

	.btn {

		&.btn-round {
			width: 44px;
			height: 44px;
			padding: 0.75em;
			border-radius: 50%;

			.icon-svg {
				width: 22px;
				height: 22px;
			}
		}

		&.btn-dropdown {
			background-color: transparent;
			border: 0;
			font-size: 14px;
			font-weight: 500;

			& + .dropdown-menu {
				.dropdown-item {
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}

	.btn-overlay {
		top: 0;
		min-width: 0;
		margin: 0;
		padding: 0;

		.icon-svg {
			width: 24px;
			height: 24px;
		}
	}
}
