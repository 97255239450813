.property-landing-sub-pages {

	margin: 3rem 0;
	padding: 3rem 0;


	figure {
		margin: 0;

		picture {
			display: block;
		}
	}

	p {
		padding: 0 20px;
	}

	@include media-breakpoint-down(sm) {

		.headline-4 {
			margin-top: .55em;
		}

		.btn-sm {
			display: block;
			padding: 0;
			text-decoration: underline;
			background-color: transparent;
			color: $color-black-fake;
			font-weight: 300;
		}

	}

	@include media-breakpoint-up(md) {

	}
}
