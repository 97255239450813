.error-page {
	img {
		display: block;
		width: 33%;
		margin: auto;
		margin-top: 35px;
		@include media-breakpoint-down(md) {
			width: 75%;
			margin-top: 70px;
		}
	}
	.login-header {
		text-align: center;
		margin-bottom: 40px;
		margin-top: 35px;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.bodycontainer {
		.modal-content {
			margin-top: 70px;
			@include media-breakpoint-down(md) {
				margin-top: 50px;
			}
		}
		.modal-body {
			display: block;
			margin: auto;
			@include media-breakpoint-up(md) {
				border: 1px solid #0a0a0a;
				width: 40%;
			}
			button {
				margin-top: 25px;
			}
		}
		.custom-control-description {
			@include media-breakpoint-down(md) {
				white-space: nowrap;
				font-size: 3.75vw;
			}
		}
	}
	#sign-in-label {
		text-align: center;
		margin-bottom: 40px;
	}
	button.btn.mb-2 {
		width: 100%;
	}
	.forgot-password {
		font-size: small;
		@include media-breakpoint-down(md) {
			font-size: 3vw;
			padding-top: 4px;
		}
	}
	.account-modal .modal-footer {
		display: none;
	}
}
