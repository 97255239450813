.itinerary-detail-slider {

	margin-bottom: px-vw(40);

	.infobox-2 {
		display: none !important; // sass-lint:disable-line no-important
	}

	.headline-3 {
		.icon-svg {
			width: px-vw(20);
			height: px-vw(20);
			margin-right: px-vw(6);
			vertical-align: px-vw(3);
		}
	}

	h3 {
		letter-spacing: 1px;
	}

	.headline-5 {
		margin-top: px-vw(35);
		padding-left: 6px;
	}

	.btn,
	.btn.btn-outline {
		padding-left: px-vw(30);
		padding-right: px-vw(30);
	}

	.content {
		text-align: left;
	}


	@include media-breakpoint-up(md) {

		margin-bottom: 0;

		.headline-3 {
			.icon-svg {
				width: 12px;
				height: 12px;
				margin-right: 6px;
				vertical-align: 2px;
			}
		}

		.headline-5 {
			margin-top: 30px;
		}

		.btn,
		.btn.btn-outline {
			padding-left: 8px;
			padding-right: 8px;
		}
	}


	@include media-breakpoint-up(lg) {

		.btn,
		.btn.btn-outline {
			padding-left: 26px;
			padding-right: 26px;
		}
	}

}
