.destination-intro {
	
	.collapsible {
		&.show {
			height: auto;
		}

		&.collapsing {
			height: px-vw(198);
		}
	}

	.collapsible-btn {
		@include body-text();
		margin-bottom: 1rem;
	}

	.collage {
		position: relative;
		padding-bottom: px-vw(400);
	}

	figure {
		position: absolute;
		margin: 0;

		.btn {
			@include absolute(5);
		}

		picture {
			@include image-fit();
			display: block;
			height: 100%;
		}
	}

	.image-1 {
		top: px-vw(50);
		left: px-vw(70);
		width: px-vw(242);
		height: px-vw(174);	
	}

	.image-2 {
		right: px-vw(70);
		left: auto;
		width: px-vw(244);
		height: px-vw(341);
	}

	.image-3 {
		display: block;
		clear: right;
		float: right;
		width: px-vw(142);
		height: px-vw(142);
		margin: 0 0 px-vw(10) px-vw(10);
	}

	.accent {
		top: px-vw(220);
		left: px-vw(180);
		width: px-vw(160);
		height: px-vw(160);
		filter: drop-shadow(-12px 3px 14px rgba(0,0,0,0.3));
	}

	.content {
		padding: 0 35px;
		text-align: center;
		
		figure {
			position: relative;
		}
	}

	@include media-breakpoint-up(md) {
		min-height: 416px;
		
		.collapsible {
			&:after {
				display: none;
			}

			&.collapsing {
				height: 334px;
			}
		}

		.collapsible-btn {
			margin: 10px 0 0;
			padding: 0;
			text-decoration: underline;
		}

		.collage {
			padding-bottom: 0;
		}

		.btn .icon-svg {
			width: 5em; 
			height: 5em;
		}

		.image-1 {
			top: px-vw(130, lg);
			left: px-vw(25, lg);
			width: px-vw(200, lg);
			height: px-vw(144, lg);
		}

		.image-2 {
			top: px-vw(15, lg);
			right: auto;
			left: px-vw(245, lg);
			width: px-vw(223, lg);
			height: px-vw(307, lg);
		}

		.image-3 {
			width: px-vw(142, lg);
			height: px-vw(142, lg);
		}	

		.accent {
			top: px-vw(275, lg);
			left: px-vw(120, lg);
			width: px-vw(140, lg);
			height: px-vw(140, lg);
		}

		.content {
			padding: 0 80px 0 0;
			text-align: left;
		}

		/*
		.img-spacer {
			margin-top: 152px;
		}
		*/
		
	}

	@include media-breakpoint-up(lg) {
		
		.image-1 {
			top: 130px;
			left: 25px;
			width: 200px;
			height: 144px;
		}

		.image-2 {
			top: 15px;
			left: 245px;
			width: 223px;
			height: 307px;
		}

		.image-3 {
			width: 142px;
			height: 142px;
		}

		.accent {
			top: 275px;
			left: 120px;
			width: 140px;
			height: 140px;
		}
	}
}
