.vacation-experience-listing {

	@include make-col-ready();

	figure {
		display: inline-block;
		width: 100%;

		picture {
			@include image-fit();
			display: block;
			width: 100%;
			height: 100%;
			padding-bottom: 125%;
		}
	}


	@include media-breakpoint-up(md) {

		width: 20%;

		figure {
			display: inline-block;
			width: 100%;

			picture {
				padding-bottom: 129.775%;
			}
		}

	}
}
