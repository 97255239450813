.booking-slider {

	.carousel {
		margin-right: 0;
		margin-left: 0;
	}

	article {

		position: relative;
		text-align: center;

		figure {
			@include absolute();
			overflow: hidden;

			picture {
				@include image-fit();
				width: 100%;
				height: 100%;
			}
		}

		.accent {
			@include absolute();
			pointer-events: none;
		}

		.content {
			width: 100%;
			text-align: center;
			margin-bottom: 900px;

			.headline-3 {
				padding: 0 px-vw(78);
			}

			.content-footer {
				@include body-text();
				margin-bottom: 1rem;
				text-decoration: underline;
			}
		}

		@supports not (-ms-high-contrast: none) {
			.content {
				margin-bottom: px-vw(645);
			}
		}
	}

	.layout-1 {

		.image-1 {
			top: px-vw(206);
			left: px-vw(70);
			width: px-vw(294);
			height: px-vw(294);
		}

		.image-2 {
			top: px-vw(179);
			left: px-vw(376);
			width: px-vw(192);
			height: px-vw(192);
		}

		.image-3 {
			top: px-vw(383);
			left: px-vw(376);
			width: px-vw(192);
			height: px-vw(192);
		}

		.image-4 {
			top: px-vw(512);
			left: px-vw(70);
			width: px-vw(295);
			height: px-vw(202);
		}

		.accent {
			top: px-vw(599);
			left: px-vw(388);
			width: px-vw(164);
			height: px-vw(164);
		}
	}

	.slick-arrow {
		top: px-vw(25);
	}

	.slick-prev {
		left: px-vw(20);
	}

	.slick-next {
		right: px-vw(20);
	}

	.carousel-indicators {
		display: none;
	}

	@include media-breakpoint-up(sm) {

	}

	@include media-breakpoint-up(md) {

		.carousel-indicators {
			position: relative;
			display: block;
			height: 20px;
			margin: 20px 0;
		}

		.slick-dots li {
			display: inline-block;

			button {
				display: block;
			}
		}

		article {
			overflow: hidden;

			.content {
				.headline-3 {
					padding: 0;
				}
			}
		}

		.layout-1 {
			height: px-vw(500, lg);

			.image-1 {
				top: 0;
				left: px-vw(15px, lg);
				width: px-vw(470px, lg);
				height: px-vw(470px, lg);
			}

			.image-2 {
				top: px-vw(348px, lg);
				left: px-vw(505px, lg);
				width: px-vw(144px, lg);
				height: px-vw(144px, lg);
			}

			.image-3 {
				top: px-vw(348px, lg);
				left: px-vw(665px, lg);
				width: px-vw(144px, lg);
				height: px-vw(144px, lg);
			}

			.image-4 {
				top: px-vw(120px, lg);
				left: px-vw(505px, lg);
				width: px-vw(307px, lg);
				height: px-vw(210px, lg);
			}

			.accent {
				top: px-vw(90px, lg);
				left: auto;
				right: 0;
				width: px-vw(96px, lg);
				height: px-vw(96px, lg);
			}

			.content {
				position: absolute;
				left: px-vw(495px, lg);
				width: px-vw(307px, lg);
				height: px-vw(250px, lg);
				margin: 0;
				padding-top: px-vw(25px, lg);

				.headline-3 {
					margin: 0;
				}
			}
		}

	}

	@include media-breakpoint-up(lg) {

		.layout-1 {
			height: 500px;

			.image-1 {
				left: 30px;
				width: 470px;
				height: 470px;
			}

			.image-2 {
				top: 348px;
				left: 520px;
				width: 144px;
				height: 144px;
			}

			.image-3 {
				top: 348px;
				left: 680px;
				width: 144px;
				height: 144px;
			}

			.image-4 {
				top: 120px;
				left: 520px;
				width: 305px;
				height: 210px;
			}

			.accent {
				top: 90px;
				left: 755px;
				width: 96px;
				height: 96px;
			}

			.content {
				left: 520px;
				width: 305px;
				height: 250px;
				padding-top: 25px;
			}
		}

	}
}
