.back-shape-none { @include background-shape("none"); }
.back-shape-caret { @include background-shape("caret"); }
.back-shape-angle { @include background-shape("angle"); }
.back-shape-rectangle { @include background-shape("rectangle"); }



@each $bp in map-keys($grid-breakpoints) {
	.back-shape-#{$bp}-up-caret {
		@include media-breakpoint-up($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-#{$bp}-up-angle {
		@include media-breakpoint-up($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-#{$bp}-up-rectangle {
		@include media-breakpoint-up($bp) {
			@include background-shape("rectangle");
		}
	}
	/* not currently used
	.back-shape-#{$bp}-down-caret {
		@include media-breakpoint-down($bp) {
			@include background-shape("caret");
		}
	}
	.back-shape-#{$bp}-down-angle {
		@include media-breakpoint-down($bp) {
			@include background-shape("angle");
		}
	}
	.back-shape-#{$bp}-down-rectangle {
		@include media-breakpoint-down($bp) {
			@include background-shape("rectangle");
		}
	}
	*/
}
