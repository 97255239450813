.must-do-listing {
	
	position: relative;
	padding: 1em 0 5em 0;

	.number {
		@include absolute(1);
		margin: 0.25em 0 0 1.5em;
	}

	figure {
		display: inline-block;
		margin-bottom: 2em;
		picture {
			@include image-fit();
			width: px-vw(300);
			height: px-vw(300);
		}
	}

	.content {
		vertical-align: top;
		padding: 0 px-vw(50);
	}

	footer {
		@include body-text();
		margin-bottom: 1rem;

		a {
			text-decoration: underline;
		}
	}

	@include media-breakpoint-up(sm) {
		figure {
			picture {
				width: 300px;
				height: 300px;
			}
		}
	}

	@include media-breakpoint-up(md) {

		padding: 1.25em px-vw(100, 'lg');
		text-align: left;

		.number {
			margin-left: px-vw(130, 'lg');
		}

		figure {
			display: inline-block;
			margin: 0 0 0 px-vw(70, 'lg');

			picture {
				width: px-vw(192, 'lg');
				height: px-vw(192, 'lg');
				padding-right: px-vw(15, 'lg');
			}	
		}

		.content {
			display: inline-block;
			width: 60%;
			margin: 0.25em 0 0 1.5em;
			padding: 0;
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 1.25em 100px 2.5em 100px;

		.number {
			margin-left: 130px;
		}

		figure {
			margin-left: 70px;

			picture {
				width: 192px;
				height: 192px;
			}	
		}
	}
	
}
