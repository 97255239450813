.personal-guidance-result-detail {

	footer {

		.btn {
			display: block;
			margin: 1em;
		}

		.btn-restart {
			text-decoration: underline;
			.icon-svg {
				width: px-vw(40);
				height: px-vw(40);
				svg {
					fill: $color-aqua-dark;
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {

		footer {
			.btn-restart {
				.icon-svg {
					width: 40px;
					height: 40px;
				}
			}
		}

	}

	@include media-breakpoint-up(md) {

		footer {
			.btn {
				display: inline-block;
				margin: 1em 0.5em;
			}

			.btn-restart {
				.icon-svg {
					width: px-vw(20);
					height: px-vw(20);
					svg {
						fill: $color-aqua-dark;
					}
				}
			}

		}

	}

	@include media-breakpoint-up(lg) {

		footer {
			.btn-restart {
				.icon-svg {
					width: 20px;
					height: 20px;
				}
			}
		}

	}

}
