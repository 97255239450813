.badge {
	display: inline-block;
	margin: 0 0.5em;
}

.badge-image {
	position: absolute;
	top: 0;
	left: 0;
	margin-left: -2px;
	padding: 3px 10px;
	font-size: px-vw(26);
	font-weight: 500;
	letter-spacing: 0.4px;
	color: $color-black;
	background-color: $color-blue-light;
	pointer-events: none;
	z-index: $aah-zindex-button-overlay;
}

.badge-descriptive {
	@include headline(7);
	display: inline-block;
	min-width: 72px;
	margin: 0 0.5em 0.5em 0;
	padding: 0.25em 0.85em;
	border: px-vw(1.5) solid $color-grey;
	border-radius: 2em;
	color: $color-black;
	background-color: $color-white;
	text-align: center;
	text-transform: lowercase;
	transition: all 0.1s ease;

	&.color-stylish 		{ color: $color-blue-text; background-color: $color-blue-button; border-color: #fff; }
	&.color-family 			{ border-color: $color-orange; }
	&.color-budget 			{ border-color: $color-purple; }
	&.color-distinctive 	{ border-color: $color-green-medium; }
	&:hover 				{ color: $color-black; }
}

.badge-weather {
	@include absolute(1);
	@include headline(3);
	@include text(unset, unset, 0);
	display: inline-block;
	max-width: 5em;
	margin: px-vw(28) px-vw(19);
	padding: 0.25em 1em 0.25em 1.2em;
	background-color: $color-white;
	border-radius: 2em;
	top: 13vw;

	&:after {
		@include absolute(4);
		display: inline-block;
		margin-left: 0.45em;
		content: '•';
		line-height: 1em;
		font-size: 25px;
		color: $color-aqua;
		speak: none;
	}

	@include media-breakpoint-up(md) {
		margin: 25px 35px;
		top: 0;

		.has-property-nav & {
			top: 60px;
		}
	}
}

.has-property-nav .feature .btn-container {
	@include media-breakpoint-up(md) {
		top: 60px;
	}
}

@include media-breakpoint-up(sm) {

	.badge-image {
		font-size: 26px;
	}

	.badge-descriptive {
		border-width: 1.5px;
	}

}

@include media-breakpoint-up(md) {

	.badge-image {
		font-size: 12px;
	}

	.badge-descriptive {
		margin: 0;
		padding: 0 0.85em;
		border-width: 1px;
	}

}
