.footer {
	padding: px-vw(65) 0 px-vw(75) 0;
	background-image: url('#{$aah-asset-path}/img/pattern-footer.svg');
	background-repeat: repeat-x;
	background-position: bottom;
	background-size: 325%;
	border-top: 1px solid $color-grey-body;
	overflow: hidden;

	a {
		color: $gray-dark;
		text-decoration: none;
		&:hover, &:focus {
			color: $color-aqua-dark;
		}
	}

	nav {
		ul {
			margin: 0 0 30px 0;
			padding: 0;
			text-align: center;
			list-style: none;

			li {
				float: left;
				margin: 0 0 3em 0;
				width: 100%;

				a {
					font-size: px-vw(32);
					font-weight: 500;
					line-height: 1em;

					&:hover, &:focus {
						color: $color-aqua-dark;
					}
				}
				
			}
		}
	}

	p {
		margin: 0 0 0.5em 0;
		padding: 0 2.5em;
		font-size: px-vw(28);
		text-align: center;
		color: #2b2b2b;
		line-height: 1.46em;
		font-weight: 300;

		strong {
			font-size: px-vw(32);
			font-weight: 500;
			color: $color-black;
		}

		a {
			text-decoration: none;
			&:hover, &:focus {
				color: $color-aqua-dark;
			}
		}

	}

	.copyright {
		margin: 2.5em 2em 1em 2em;
		font-size: px-vw(28);
		font-weight: 300;
		text-align: center;
	}

	.country-selector {
		display: inline-block;
		margin: 0 auto 30px auto;

		.icon-flag {
			margin-right: 0.5em;
		}
	}

	.social-icons {
		margin: px-vw(50) 0 px-vw(45) 0;
		text-align: center;

		ul.list-social {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				margin: 0 px-vw(20) 0 0;
				vertical-align: top;

				a {
					display: block;
					width: px-vw(65);
					height: px-vw(65);
					padding: px-vw(12);
					background-color: #455565;
					border-radius: 100%;

					.icon-svg {
						fill: $color-white;
						vertical-align: top;

						svg {
							pointer-events: none;
						}
					}

					&:hover {
						background-color: $color-aqua-dark;
					}

					&::after {
						content: none;
					}
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		//fix-size any scaling font/spacing sizes here
		padding: 65px 0 75px 0;

		nav {
			ul {
				li {
					a {
						font-size: 32px;
					}
				}
			}
		}

		p {
			font-size: 28px;
			strong {
				font-size: 32px;
			}
		}

		.social-icons {
			margin: 50px 0 45px 0;

			ul.list-social {
				li {
					margin: 0 20px 0 0;
					a {
						width: 65px;
						height: 65px;
						padding: 12px;
					}
				}
			}
		}

		.copyright {
			margin: 2.5em 0 1em 0;
			font-size: 28px;
		}
	}

	@include media-breakpoint-up(md) {
		padding: 45px 0 75px 0;
		background-size: auto;

		nav {
			ul {
				@include clearfix;

				li {
					float: left;
					margin: 0 0 1em 0;
					padding: 0 1em;
					line-height: 1.1em;

					a {
						font-size: 14px;
					}

				}
			}
		}

		p {
			font-size: 14px;
			strong {
				font-size: 14px;
			}
		}

		.social-icons {
			ul.list-social {
				li {
					a {
						width: 36px;
						height: 36px;
						padding: 8px;
					}
				}
			}
		}

		.copyright {
			margin: 0;
			font-size: 12px;
		}
	}

	@include media-breakpoint-up(lg) {

		nav {
			ul {
				display: flex;
				flex-wrap: wrap;
				text-align: left;

				li {
					flex: 1 1 25%;

					&:nth-last-child(1):nth-child(odd) {
						text-align: left;
					}
				}
			}
		}

		p {
			padding: 0;
			text-align: left;
		}

		.social-icons {
			margin: 0;
			text-align: left;

			ul.list-social {
				margin: 0 0 30px 0;
				padding: 0;
				list-style: none;

				li {
					display: inline-block;
					margin: 0 10px 0 0;
					vertical-align: top;
				}
			}
		}

		.country-selector {
			margin: 0 0 0 -8px;
		}

		.copyright {
			margin: 0;
			padding-top: 0.75em;
			text-align: left;
		}
	}
}

.maplessFooter {
	@include media-breakpoint-down(md) {
		.headline-5 {
			line-height: 9vw;
		}
	}
}
