.our-favorites-listing {
	padding: 0 35px 47px;
	height: 100%;
	border: 1px solid $color-grey-border;
	display: flex;
	flex-direction: column;

	figure {
		margin-top: px-vw(-90);

		picture {
			@include image-fit();
			width: px-vw(180);
			height: px-vw(180);
			margin: 0 auto;
		}
	}

	.headline-5 {
		margin-top: px-vw(25);
	}

	a {
		@include body-text();
		margin: auto 0 0;
		text-decoration: underline;

		&:hover {
			color: $color-aqua-dark;
		}
	}

	@include media-breakpoint-up(md) {
		figure {
			margin-top: px-vw(-60, 'lg');

			picture {
				width: px-vw(120, 'lg');
				height: px-vw(120, 'lg');
			}
		}

		.headline-5 {
			margin-top: 0;
		}
	}

	@include media-breakpoint-up(md) {
		figure {
			margin-top: -60px;

			picture {
				width: 120px;
				height: 120px;
			}
		}
	}
}
