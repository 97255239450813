.a-list-landing-details {

	.slick-track {
		display: flex;
	}

	.slick-slide {
		height: auto;

		img {
			display: inline;
		}
	}

	.col, .slick-initialized .slick-slide {
		display: flex; // needed for safari issue - fill height
	}

	.a-list-status-item {
		@extend .carousel-card;

		.stars {
			margin: px-vw(40) 0;

			.icon-svg {
				width: px-vw(24);
				height: px-vw(24);
				margin: auto;

				svg {
					fill: $color-black;
				}
			}
		}

		ul.list-unstyled {
			margin-top: px-vw(10);

			li {
				margin: 0 0 px-vw(30) 0;
			}
		}
	}

	footer {
		.headline {
			margin: px-vw(60) 0 px-vw(40) 0;
		}

		.btn {
			width: 100%;
		}
	}

	@include media-breakpoint-up(sm) {
		.a-list-status-item {
			.stars {
				margin: 40px 0;

				.icon-svg {
					width: 24px;
					height: 24px;
					margin: 0 8px;
				}
			}

			ul.list-unstyled {
				margin-top: px-vw(10);

				li {
					margin: 0 0 30px 0;
				}
			}

		}
	}

	@include media-breakpoint-up(md) {
		.a-list-status-item {
			.stars {
				margin: 20px 0;

				.icon-svg {
					width: 14px;
					height: 14px;
					margin: 0;
				}
			}

			ul.list-unstyled {
				margin-top: 10px;

				li {
					margin: 0 0 20px 0;
				}
			}
		}

		footer {
			.headline {
				margin: 20px 0 40px 0;
			}

			.btn {
				width: auto;
			}
		}

 	}
}
