.a-list-landing-intro {

	padding-bottom: px-vw(80);

	ul.list-unstyled {
		display: inline-block;
		margin: px-vw(30) auto px-vw(50px) auto;
		text-align: left;

		li {
			display: flex;
			margin-bottom: px-vw(40);

			.headline {
				display: inline-block;
				padding-top: px-vw(16);
			}

			.icon {
				display: flex;
				width: px-vw(64);
				height: px-vw(64);
				margin-right: px-vw(36);
			}
		}
	}

	.btn {
		width: 100%;
	}

	@include media-breakpoint-up(sm) {

		padding-bottom: 80px;

		ul.list-unstyled {

			margin: 30px auto 50px auto;

			li {
				margin-bottom: 40px;

				.icon {
					width: 64px;
					height: 64px;
					margin-right: 36px;
				}

				.headline {
					padding-top: 16px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		padding-bottom: px-vw(68, 'lg');

		ul.list-unstyled {
			display: block;
			margin: px-vw(40, 'lg') 0;
			text-align: center;

			li {
				display: inline-block;
				margin: 0 px-vw(20, 'lg');
				text-align: center;

				.headline {
					display: block;
				}

				.icon {
					display: block;
					width: px-vw(42, 'lg');
					height: px-vw(42, 'lg');
					margin: 0 auto;
				}
			}
		}

		.btn {
			width: auto;
		}
	}

	@include media-breakpoint-up(lg) {

		padding-bottom: 68px;

		ul.list-unstyled {

			margin: 40px 0;

			li {
				margin: 0 40px;

				.icon {
					width: 42px;
					height: 42px;
				}
			}
		}
	}

}
