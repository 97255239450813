.destination-slider {

	position: relative;
	padding-top: px-vw(20);

	.figure-container {
		position: relative;
		display: none;
	}

	article {
		position: relative;
		width: 100%;
		text-align: center;

		figure {
			position: absolute;
			margin: 0;

			a {
				display: block;
				height: 100%;
				margin-bottom: 0.5em;
			}
			picture {
				@include image-fit();
				display: block;
				height: 100%;
			}

			figcaption {
				@include headline(6);
				text-align: left;
			}
		}

		.content {
			padding: 0 px-vw(20);

			.category {
				@include body-text();
				@include absolute(2);
				display: inline-block;
				margin-bottom: 1rem;
				font-weight: 500;
				text-transform: uppercase;
				color: inherit;
			}

			.headline-3 {
				margin-bottom: px-vw(10);
			}
		}

		.accent {
			@include absolute();
			pointer-events: none;
			z-index: 1;
			filter: drop-shadow(-12px 3px 14px rgba(0, 0, 0, 0.3));

			img {
				max-width: 100%;
				height: auto;
			}
		}

		&.layout-1, &.layout-2, &.layout-3 {
			.content {
				width: 100%;
				height: 100%;
				margin-top: px-vw(675);
			}
			.image-1 {
				top: px-vw(64);
				left: px-vw(20);
				width: px-vw(396);
				height: px-vw(282);
			}
			.image-2 {
				top: px-vw(160);
				left: px-vw(428);
				width: px-vw(192);
				height: px-vw(192);
			}
			.image-3 {
				top:  px-vw(356);
				left: px-vw(223);
				width: px-vw(192);
				height: px-vw(192);
			}
			.image-4 {
				top:  px-vw(356);
				left: px-vw(70);
				width: px-vw(141);
				height: px-vw(141);
			}
			.accent {
				top:  px-vw(438);
				left: px-vw(412);
				width: px-vw(228);
				height: px-vw(236);
			}
		}		
	}

	.slick-arrow {
		top: px-vw(120);
	}

	.slick-prev {
		left: px-vw(120);
	}

	.slick-next {
		right: px-vw(120);
	}

	@include media-breakpoint-up(sm) {

		article {
			.content {
				padding: 0 20px;
			}
		}

		.slick-arrow {
			top: 10%;
		}
	
		.slick-prev {
			left: 120px;
		}
	
		.slick-next {
			right: 120px;
		}
	}

	@include media-breakpoint-up(md) {

		padding-top: 20px;

		.figure-container {
			display: block;
		}

		.carousel-tabs {
			margin-top: 20px;

			a {
				margin-top: 3px;
				// padding: 13px 20px;
				color: #2b2b2b;
				font-size: 14px;
				font-weight: 300;
				// border-top: 1px solid #c8c8c8;
				text-decoration: none;
				text-transform: uppercase;

				&.slick-current {
					margin-top: 0;
					padding: 11px 22px 13px 22px;
					font-weight: 500;
					border-top: 6px solid $color-aqua;
				}
			}
		}

		.carousel-tabs {
			.slick-list.draggable {
				position: relative;

				&:after {
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					width: 100%;
					height: 54px;
					content: '';
					box-shadow: 28px 0 25px -10px white inset, -28px 0 25px -10px white inset;
					pointer-events: none;
				}
			}
		}

		.slick-prev {
			@include absolute(4);
		}
		
		.slick-next {
			@include absolute(6);
		}

		article {
			height: px-vw(700, lg);
			text-align: left;

			.content {
				position: absolute;
				margin-top: 0;
				padding: px-vw(20, lg) 0 0 0;
				text-align: left;

				.category {
					position: relative;
					left: auto;
					margin: 0 0 0.5em 0;
					transform: none;
					font-weight: 300;
				}

				.headline-3 {
					margin-bottom: 0;
				}
			}

			&.layout-1 {
				.content {
					left: px-vw(442, lg);
					width: px-vw(390, lg);
					height: px-vw(250, lg);
					margin-top: 0;
				}
				.image-1 {
					top: 0;
					left: px-vw(35, lg);
					width: px-vw(389, lg);
					height: px-vw(276, lg);
				}
				.image-2 {
					top: px-vw(293, lg);
					left: px-vw(198, lg);
					width: px-vw(226, lg);
					height: px-vw(226, lg);
				}

				.image-3 {
					top: px-vw(55, lg);
					left: px-vw(0, lg);
					width: px-vw(226, lg);
					height: px-vw(226, lg);
				}

				.image-4 {
					top: px-vw(9, lg);
					left: px-vw(245, lg);
					width: px-vw(144, lg);
					height: px-vw(144, lg);
				}

				.accent {
					top: px-vw(247, lg);
					left: 0;
					width: px-vw(200, lg);
					height: px-vw(200, lg);
				}

				.image-3,
				.image-4 {
					display: none;
				}

				.figure-container {
					.image-1,
					.image-2 {
						display: none;
					}

					.image-3,
					.image-4 {
						display: block;
					}
				}
			}
			&.layout-2 {
				.content {
					left: px-vw(35, lg);
					width: px-vw(370, lg);
					height: px-vw(250, lg);
					margin-top: 0;
				}
				.image-1 {
					top: 0;
					left: px-vw(444, lg);
					width: px-vw(389, lg);
					height: px-vw(276, lg);
				}
				.image-2 {
					top: px-vw(79, lg);
					left: px-vw(0, lg);
					width: px-vw(226, lg);
					height: px-vw(226, lg);
				}
				.image-3 {
					top: px-vw(79, lg);
					left: px-vw(246, lg);
					width: px-vw(226, lg);
					height: px-vw(226, lg);
				}
				.image-4 {
					top: px-vw(293, lg);
					left: px-vw(527, lg);
					width: px-vw(144, lg);
					height: px-vw(144, lg);
				}
				.accent {
					top: px-vw(190, lg);
					left: px-vw(255, lg);
					width: px-vw(200, lg);
					height: px-vw(200, lg);
				}

				.image-2,
				.image-3 {
					display: none;
				}

				.figure-container {
					.image-1,
					.image-4 {
						display: none;
					}

					.image-2,
					.image-3 {
						display: block;
					}
				}
			}
			&.layout-3 {
				.content {
					top: px-vw(284px, lg);
					left: px-vw(444px, lg);
					width: px-vw(390px, lg);
					height: px-vw(250px, lg);
					margin-top: 0;
				}
				.image-1 {
					top: 0;
					left: px-vw(35px, lg);
					width: px-vw(389px, lg);
					height: px-vw(276px, lg);
				}
				.image-2 {
					top: px-vw(293px, lg);
					left: px-vw(198px, lg);
					width: px-vw(226px, lg);
					height: px-vw(226px, lg);
				}
				.image-3 {
					top: px-vw(116px, lg);
					left: px-vw(689px, lg);
					width: px-vw(144px, lg);
					height: px-vw(144px, lg);
				}
				.image-4 {
					top: 0;
					left: px-vw(444px, lg);
					width: px-vw(226px, lg);
					height: px-vw(226px, lg);
				}
				.accent {
					top: px-vw(325px, lg);
					left: px-vw(65px, lg);
					width: px-vw(200px, lg);
					height: px-vw(200px, lg);
				}

				.figure-container {
					display: none;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {

		article {
			width: 100%;
			height: 620px;

			.content {
				padding-top: 15px;
			}

			&.layout-1 {
				.content {
					left: 442px;
					width: 390px;
					height: 250px;
				}
				.image-1 {
					left: 35px;
					width: 389px;
					height: 276px;
				}
				.image-2 {
					top: 293px;
					left: 198px;
					width: 226px;
					height: 226px;
				}
				.image-3 {
					top: 55px;
					left: 0;
					width: 226px;
					height: 226px;
				}
				.image-4 {
					top: 9px;
					left: 245px;
					width: 144px;
					height: 144px;
				}
				.accent {
					top: 247px;
					left: 0;
					width: 200px;
					height: 200px;
				}
			}
			&.layout-2 {
				.content {
					left: 35px;
					width: 370px;
					height: 250px;
				}
				.image-1 {
					top: 0;
					left: 444px;
					width: 389px;
					height: 276px;
				}
				.image-2 {
					top: 79px;
					left: 0;
					width: 226px;
					height: 226px;
				}
				.image-3 {
					top: 79px;
					left: 246px;
					width: 226px;
					height: 226px;
				}
				.image-4 {
					top: 293px;
					left: 527px;
					width: 144px;
					height: 144px;
				}
				.accent {
					top: 190px;
					left: 255px;
					width: 200px;
					height: 200px;
				}
			}
			&.layout-3 {
				.content {
					top: 284px;
					left: 444px;
					width: 390px;
					height: 250px;
					margin-top: 0;
				}
				.image-1 {
					left: 35px;
					width: 389px;
					height: 276px;
				}
				.image-2 {
					top: 293px;
					left: 198px;
					width: 226px;
					height: 226px;
				}
				.image-3 {
					top: 116px;
					left: 689px;
					width: 144px;
					height: 144px;
				}
				.image-4 {
					top: 0;
					left: 444px;
					width: 226px;
					height: 226px;
				}
				.accent {
					top: 325px;
					left: 65px;
					width: 200px;
					height: 200px;
				}
			}

		}
	}
}
