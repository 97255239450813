

.featured-activity {
	padding: 1em 0.75em 1em;
	text-align: center;

	.number {
		@include absolute(1);
		left: 10px;
		margin: 0.25em 0 0 0.25em;
	}

	figure {
		margin: 0 auto px-vw(80) auto;
		text-align: center;

		picture {
			@include image-fit();
			display: inline-block;
			width: px-vw(300);
			height: px-vw(300);
			margin: 0;
		}
	}

	.category {
		@include body-text();
		@include text(unset, unset, 0.5px);
		margin-bottom: 1rem;
		font-weight: 300;
	}

	@include media-breakpoint-up(md) {
		text-align: left;

		.number {
			left: 0;
		}

		figure {
			margin: 0 auto px-vw(20, 'lg') auto;

			picture {
				width: px-vw(192, 'lg');
				height: px-vw(192, 'lg');
				
			}
		}

		.category {
			margin-bottom: 0.25em;
		}
	}

	@include media-breakpoint-up(lg) {

		figure {
			margin: 0 auto 20px auto;
			picture {
				width: 192px;
				height: 192px;
				
			}
		}
	}
}

.destination-grouping {
	padding: 44px 0;
	text-align: center;
	border-top: 1px solid $color-grey-body;

	figure {
		padding: 0 35px;
	}

	.collapsible {
		p {
			padding: 0 35px;
		}

		figure {
			padding: 0;
		}
	}

	.collapsing {
		height: px-vw(110);
	}
  
	@include media-breakpoint-up(md) {
		padding: 75px 0;
		text-align: left;

		figure {
			padding: 0;
		}

		.collapsible {
			p {
				padding: 0;
			}
		}
	}
}


