.tandc-modal {
	.modal-header {
		text-align: center;		
        padding-bottom: 0;
	}
    .modal-content {
        padding-top: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .modal-footer {
        justify-content: center;
        padding: 22px 0px;
    }
}
