// sass-lint:disable mixins-before-declarations

@mixin border() {
	border-width: 0;
	border-style: solid;
	border-color: $color-grey-border;
}
.b-1 {
	@include border();
	border-width: 1px;
}
.bt-1 {
	@include border();
	border-top-width: 1px;
}
.bb-1 {
	@include border();
	border-bottom-width: 1px;
}
.bl-1 {
	@include border();
	border-left-width: 1px;
}
.br-1 {
	@include border();
	border-right-width: 1px;
}
.by-1 {
	@include border();
	border-top-width: 1px;
	border-bottom-width: 1px;
}
.bx-1 {
	@include border();
	border-right-width: 1px;
	border-left-width: 1px;
}


@include media-breakpoint-up(md) {

	.b-md-0 {
		border-width: 0;
	}
	.bt-md-0 {
		border-top-width: 0;
	}
	.bb-md-0 {
		border-bottom-width: 0;
	}
	.bl-md-0 {
		border-left-width: 0;
	}
	.br-md-0 {
		border-right-width: 0;
	}
	.by-md-0 {
		border-top-width: 0;
		border-bottom-width: 0;
	}
	.bx-md-0 {
		border-right-width: 0;
		border-left-width: 0;
	}
}



.border-primary {
	border-color: $color-aqua !important; // sass-lint:disable-line no-important
}



.border-top {
	border-top-style: solid;
	border-top-color: $color-aqua;
	border-top-width: px-vw(8);
	@include media-breakpoint-up(sm) {
		border-top-width: 8px;
	}
	@include media-breakpoint-up(md) {
		border-top-width: px-vw(6, lg)
	}
	@include media-breakpoint-up(lg) {
		border-top-width: 6px;
	}
}

.border-bottom {
	border-bottom-style: solid;
	border-bottom-color: $color-aqua;
	border-bottom-width: px-vw(8);
	@include media-breakpoint-up(sm) {
		border-bottom-width: 8px;
	}
	@include media-breakpoint-up(md) {
		border-bottom-width: px-vw(6, lg)
	}
	@include media-breakpoint-up(lg) {
		border-bottom-width: 6px;
	}
}
