.dropdown {

	.btn-dropdown {
		margin-right: 2px;
		background-color: transparent;
		border: 0;
		font-size: px-vw(28);
		font-weight: 300;
		color: $color-black-fake;

		& + .dropdown-menu {
			width: 100%;
			min-width: 0;
			margin: 1px 0 0 -1px;

			.dropdown-item {
				padding: 3px 1em 3px 0.5em;
				font-size: px-vw(28);
				font-weight: 300;
			}
		}
	}
	.dropdown-for-carousel {
		top: auto !important;
	}

	@include media-breakpoint-up(sm) {

		.btn-dropdown {
			font-size: 28px;

			& + .dropdown-menu {
				.dropdown-item {
					font-size: 28px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {

		.btn-dropdown {
			font-size: 14px;
			font-weight: 500;

			& + .dropdown-menu {
				.dropdown-item {
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}
}
