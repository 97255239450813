.itinerary-detail-map-slider {

	figure {
		margin: 48px 0 5px;
		opacity: 0.3;
		transition: opacity 0.8s;
	}

	.content {
		text-align: left;

		.headline-6 {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			border-bottom: 8px solid $color-aqua;
			opacity: 0;
			transition: opacity 0.8s;
			text-align: center;
		}

		&:before {
			position: absolute;
			top: 27px;
			left: 0;
			width: 100%;
			height: 1px;
			content: '';
			background: $color-grey-border;
		}

		.body {
			margin-top: 4px;
			line-height: 20px;
		}
	}

	.slick-current {
		figure {
			opacity: 1;
		}

		.headline-6 {
			display: block;
			opacity: 1;
		}
	}

	.carousel-factory[data-arrows="sides"] {
		.slick-next, .slick-prev {
			margin-top: 1em;
		}
	}
}
