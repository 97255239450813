.experiences-listing {
	position: relative;
	left: 0;
	margin-bottom: px-vw(80);
	text-align: left;
	opacity: 1;
	transition: left .3s ease-in-out, opacity .3s ease-in-out;

	&.hidden {
		display: none;
		left: 100%;
		height: 0;
		margin: 0;
		opacity: 0;
		overflow: hidden;
	}

	figure {
		display: inline-block;
		width: 100%;
		margin-right: px-vw(23);

		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}
	
	.headline-3 {
		margin-bottom: px-vw(40);
		padding: 0 0 px-vw(15) px-vw(26);
		border-bottom: px-vw(15) solid $color-aqua;
	}
	
	.content {
		position: relative;
		display: inline-block;
		padding: 0 0 0 px-vw(20);
		vertical-align: top;

		.headline-5 {
			margin-bottom: px-vw(15);
			padding-right: 48px;
		}

		.btn-overlay {
			top: -10px;
			margin: 0;

			.icon-svg {
				svg {
					fill: $color-black;
				}
			}
		}

		p {
			margin: px-vw(15) 0;
		}

		.location,
		time {
			@include body-text();
			display: block;
			margin: 0;
			font-weight: 400;
		}

		a {
			@include body-text();
			margin-bottom: 1rem;
			text-decoration: underline;

			&:hover {
				color: $color-aqua-dark;
			}
		}
	}

	@include media-breakpoint-up(sm) {

		margin-bottom: 80px;

		.headline-3 {
			border-width: 15px;
		}

		.content {
			padding: 0 0 0 20px;
		}
	}

	@include media-breakpoint-up(md) {
		margin-bottom: px-vw(80, lg);

		&.hidden {
			display: block;
		}

		figure {
			margin: 0 px-vw(32, lg) 0 0;
		}

		.headline-3 {
			margin-bottom: 22px;
			padding: 0 0 4px 0;
			border-width: px-vw(6, lg);
		}

		.content {

			padding: 0 0 0 px-vw(20, lg);

			.headline-5 {
				@include headline(4);
				margin-bottom: 8px;
			}

			.btn-overlay {
				top: 52px;
				right: -15px;
			}

			p {
				margin: 5px 0;
			}

			.location,
			time {
				display: inline-block;
				margin: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {

		margin-bottom: 80px;

		figure {
			margin: 0 32px 0 0;
		}

		.headline-3 {
			border-width: 6px;
		}

		.content {
			padding: 0 0 0 20px;
		}

	}
}

.experience-list {
	overflow: hidden;
	
	@include media-breakpoint-up(md) {
		overflow: hidden;
	}
}
