.favorites-listing {
	position: relative;
	display: flex;
	margin: 0 0 1.8em;
	padding-bottom: 0.8em;
	flex-direction: row;
	border-bottom: 2px solid $color-grey-border;

	.btn-overlay {
		.icon-svg {
			svg {
				fill: $color-aqua-dark;
			}
		}
	}

	figure {
		display: inline-block;
		width: 40%;

		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
			padding-bottom: 66.6667%;
		}
	}

	.content {
		display: flex;
		padding: 0 50px 0 14px;
		flex-direction: column;
		text-align: left;
	}

	.category {
		@include body-text();
		margin-top: auto;
		margin-bottom: 1rem;
	}

	@include media-breakpoint-down(sm) {
		.content {
			width: 56%;
		}
	}

	@include media-breakpoint-down(md) {
		.btn-overlay {
			margin: 0;
		}
	}

	@include media-breakpoint-up(md) {
		display: block;
		margin-bottom: 2em;
		border: 0;

		.btn-overlay {
			.icon-svg {
				svg {
					fill: $color-white;
				}
			}
		}

		figure {
			width: 100%;
			margin-bottom: 10px;

			picture {
				width: 100%;
				height: 100%;
				padding-bottom: 66.45%;
			}
		}

		.content {
			padding: 0;
			flex-direction: row;
		}

		.headline-5 {
			width: 70%;
			padding-right: 20px;
		}

		.category {
			margin: 0 0 0 auto;
			text-align: right;
		}
	}
}
