.personal-guidance-activity {

	@include make-row();
	margin: 0 0 px-vw(85) 0;
	justify-content: center;

	figure {
		position: relative;
		display: inline-block;
		width: 100%;
		height: 100%;
		margin: 0 px-vw(45) px-vw(35) px-vw(45);

		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
		}
	}

	.content {
		@include make-col-ready();
		display: block;
		text-align: center;
		margin-right: px-vw(50);
		margin-left: px-vw(50);

		footer {
			@include body-text();
			margin-bottom: 1rem;
		}
	}

	&.layout-1 {
		figure {
			picture { padding-bottom: 75%; }
		}
	}
	&.layout-2 {
		figure {
			width: px-vw(400);
			picture { padding-bottom: 100%; }
		}
		.content {}
	}
	&.layout-3 {
		figure {
			picture { padding-bottom: 50%; }
		}
	}
	&.layout-4 {
		figure {
			width: px-vw(400);
			picture { padding-bottom: 100%;
				&.accent {
					@include absolute(9);
					width: px-vw(200);
					height: px-vw(200);
					right: px-vw(-100);
					margin-bottom: px-vw(-100);
					padding: 0;
				}
			}
		}
	}
	&.layout-5 {
		figure {
			picture { padding-bottom: 60%; }
		}
	}
	&.layout-6 {
		figure {
			picture { padding-bottom: 75%; }
		}
	}
	&:last-child {
		margin-bottom: px-vw(160, lg);
	}


	@include media-breakpoint-up(sm) {

		margin: 0 0 85px 0;

		figure {
			margin: 0 45px 35px 45px;
		}

		.content {
			margin-right: 50px;
			margin-left: 50px;
		}

	}


	@include media-breakpoint-up(md) {

		figure {
			margin: 0;
			padding: 0;
		}

		.content {
			display: inline-block;
			margin-right: px-vw(40, lg);
			margin-left: px-vw(40, lg);
			text-align: left;
			align-self: center;
		}

		&.layout-1 {
			figure {
				width: px-vw(390, lg);
				picture { padding-bottom: 75%; }
			}
			.content {
				width: 50%;
				max-width: px-vw(360, lg);
			}
		}
		&.layout-2 {
			figure {
				left: px-vw(420, lg);
				width: px-vw(245, lg);
				picture { padding-bottom: 100%; }
			}

			.content {
				right: px-vw(245, lg);
				width: px-vw(420, lg);
				text-align: right;
			}
		}
		&.layout-3 {
			figure {
				@include make-col(6);

				picture { padding-bottom: 50%; }
			}

			.content {
				@include make-col(6);
				max-width: px-vw(360, lg);
				text-align: left;
			}
		}
		&.layout-4 {
			figure {
				width: px-vw(245, lg);

				picture {
					padding-bottom: 100%;
					&.accent {
						@include absolute(9);
						top: px-vw(-100, lg);
						right: px-vw(-100, lg);
						width: px-vw(200, lg);
						height: px-vw(200, lg);
					}
				}
			}

			.content {
				width: 70%;
				max-width: px-vw(500, lg);
				text-align: left;
			}
		}
		&.layout-5 {
			figure {
				width: px-vw(390, lg);
				picture { padding-bottom: 60%; }
			}

			.content {
				width: 50%;
				max-width: px-vw(480, lg);
				text-align: right;
			}
		}
		&.layout-6 {
			figure {
				width: px-vw(390, lg);
				picture { padding-bottom: 75%; }
			}

			.content {
				width: 50%;
				max-width: px-vw(480, lg);
				text-align: right;
			}
		}
		&:last-child {
			margin-bottom: px-vw(160, lg);
		}

	}

	@include media-breakpoint-up(lg) {

		margin: 0 0 80px 0;

		.content {
			margin-right: 40px;
			margin-left: 40px;
		}

		&.layout-1 {
			figure {
				width: 390px;
				picture { padding-bottom: 75%; }
			}
			.content {
				width: 50%;
				max-width: 360px;
			}
		}
		&.layout-2 {
			figure {
				left: 500px;
				width: 245px;
			}
			.content {
				right: 245px;
				width: 420px;
			}
		}
		&.layout-3 {
			figure {}
			.content { max-width: 360px; }
		}
		&.layout-4 {
			figure {
				width: 245px;
				picture {
					&.accent {
						@include absolute(9);
						top: 100px;
						right: -100px;
						width: 200px;
						height: 200px;
					}
				}
			}
			.content { max-width: 500px; }
		}
		&.layout-5 {
			figure {
				left: 540px;
				width: 390px;
			}
			.content {
				right: 330px;
				width: 400px;
			}
		}
		&.layout-6 {
			figure {
				width: 390px;
				left: 540px;
				picture { padding-bottom: 75%; }
			}
			.content {
				right: 330px;
				width: 400px;
			}
		}
		&:last-child {
			margin-bottom: 160px;
		}

	}

}
