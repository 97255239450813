.content-grid {
	min-height: px-vw(300);

	figure {
		position: absolute;
		margin: 0;

		picture {
			@include image-fit();
			display: block;
			height: 100%;
		}

		.btn {
			@include absolute(5);
		}
	}

	&.layout-1 {
		.image-1 {
			top: 0;
			left: 0;
			width: px-vw(345);
			height: px-vw(232);
		}
		.image-2 {
			top: 0;
			left: px-vw(358);
			width: px-vw(167);
			height: px-vw(108);
		}
		.image-3 {
			top: px-vw(120);
			left: px-vw(358);
			width: px-vw(233);
			height: px-vw(156);
		}
	}

	@include media-breakpoint-up(md) {
		min-height: px-vw(400, lg);

		&.layout-1 {
			.image-1 {
				width: px-vw(471, lg);
				height: px-vw(307, lg);
			}
			.image-2 {
				left: px-vw(490, lg);
				width: px-vw(208, lg);
				height: px-vw(131, lg);
			}
			.image-3 {
				top: px-vw(151, lg);
				left: px-vw(490, lg);
				width: px-vw(307, lg);
				height: px-vw(204, lg);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		min-height: 400px;

		&.layout-1 {
			.image-1 {
				width: 471px;
				height: 307px;
			}
			.image-2 {
				left: 490px;
				width: 208px;
				height: 131px;
			}
			.image-3 {
				top: 151px;
				left: 490px;
				width: 307px;
				height: 204px;
			}
		}
	}
}
