.other-rooms-listing {

	margin-bottom: 0;
	padding: 0 px-vw(20);
	text-align: center;

	.content {
		padding-left: 5px;
		text-align: left;
	}

	ul.other-room-list {
		@include body-text();
		margin-bottom: 1rem;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
		}

		a {
			display: inline-block;
			margin: 0 10px;
		}
	}

	@include media-breakpoint-up(md) {

		padding: 0;
		text-align: left;

		figure {
			margin-bottom: 3px;
		}

		.headline-5 {
			margin-bottom: 0;
		}

		.content {
			padding-left: 0;
		}

		footer {
			a { margin-left: 0; }
		}
	}
}
