.offer-listing {
	@include make-col-ready();
	width: px-vw(512);
	text-align: left;

	.badge-image {
		margin: 0 4px;
	}

	figure {
		padding: 4px 0 0;
		picture {
			@include image-fit();
			width: 100%;
			height: 100%;
			padding-bottom: 66.666%;
		}
	}

	.btn-favorite {
		right: 3px;
	}

	@include media-breakpoint-up(sm) {

		width: 512px;

	}

	@include media-breakpoint-up(md) {
		@include make-col-ready();
		@include make-col(4);
		margin: 0 0 2.5em 0;

		.btn-favorite {
			right: 7px;
		}
 	}
 }
