.itinerary-map-slider {
	.slick-prev {
		left: px-vw(-20);
	}

	.slick-next {
		right: px-vw(-20);
	}

	.slick-prev,
	.slick-next {
		top: px-vw(78);
	}

	.headline-5 {
		font-weight: 300;
	}

	@include media-breakpoint-up(md) {
		.headline-5 {
			font-weight: 500;
		}
	}
}
