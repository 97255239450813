.pov {

	.container {
		
		header {
			position: relative;
			display: inline-block;
			margin-bottom: 25px;

			picture {
				@include absolute(5);
				display: block;
				width: px-vw(250);
				height: px-vw(250);
				margin: 0 auto;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
			}

			&:before {
				@include headline(1);
				position: absolute;
				top: px-vw(-125);
				left: px-vw(-160);
				color: $color-black-fake;
				font-size: px-vw(100);
				content: "“";
				speak: none;
			}
		}

		&.container-inset {
			padding-top: px-vw(160);
	
			header {
				position: absolute;
				margin-bottom: 0;
			}

			.body {
				padding: 0 px-vw(17);
			}
		}
	}

	

	@include media-breakpoint-up(sm) {
		.container {
		
			header {
				picture {
					@include absolute(5);
					display: block;
					width: 250px;
					height: 250px;
					margin: 0 auto;
					overflow: hidden;
				}

				&:before {
					top: -125px;
					left: -160px;
					font-size: 100px;
				}
			}

			

			&.container-inset {
				padding-top: 160px;

				.body {
					padding: 0 17px;
				}
			}
		}
	}

	@include media-breakpoint-up(md) {
		
		.container {

			header {
				picture {
					@include absolute(5);
					display: block;
					width: 133px;
					height: 133px;
					margin: 0 auto;
					overflow: hidden;
				}

				&:before {
					top: -75px;
					left: -100px;
					font-size: 80px;
				}
			}

			&.container-inset {
				padding-top: 100px;
			}

		}
	}
}
