.property-landing-slider {
	.fee-disclaimer {
		width: 100%;
		font-weight: 300;
		text-align: center;
	}

	margin-bottom: px-vw(60);
	padding: 0px 24px;
	text-align: left;
    z-index: 1;

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		transform: translate3d(0, 0, 0);
		transition-delay: 10ms;
	}

	.slick-slide {
		figcaption {
			opacity: 0;
			padding-bottom: 12px;
		}
	}

	figure {
		position: relative;
		margin: 0;

		picture {
			display: block;
		}
	}

	figcaption {
		margin-top: 0.5em;
		transition: opacity 1s;

		.headline-5 {
			display: inline-block;
			margin-right: 10px;
		}

		.headline-6 {
			@include body-text();
			margin-bottom: 1rem;
		}

		ul.list-inline {
			li {
				@include body-text();
				margin: 0;
			}
		}
	}

	.slick-center {
		figcaption {
			opacity: 1;
		}
	}

	@include media-breakpoint-up(sm) {
		.fee-disclaimer {
			position: relative;
		}
	}

	@include media-breakpoint-up(md) {

		margin-bottom: 0;
		padding: 0;
		width: 100%;

		.fee-disclaimer {
			position: absolute;
			bottom: 10px;
		}

		figure {
			margin: 0;
		}

		figcaption {
			padding-bottom: 0;
		}
	}
}
