.other-experiences-listing {
	margin-bottom: px-vw(42);

	figure {
		margin: px-vw(15) 0 px-vw(40);

		picture {
			@include image-fit();
			display: inline-block;
			width: px-vw(396);
			height: px-vw(396);
			margin: 0;
		}
	}

	.btn-overlay {
		right: px-vw(40);

		.icon-svg {
			svg {
				fill: $color-black;
			}
		}
	}

	.headline-4 {
		margin-bottom: px-vw(20);
	}

	.location,
	time {
		@include body-text();
		margin: 0;
		font-weight: 400;
	}


	@include media-breakpoint-up(sm) {
		figure {
			picture {
				width: 396px;
				height: 396px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 80px;

		figure {
			margin: 15px 0 10px;

			picture {
				width: 192px;
				height: 192px;
			}
		}

		.btn-overlay {
			right: 0;
		}

		.headline-4 {
			margin-bottom: 5px;
		}

		.location,
		time {
			display: block;
		}
	}
}
